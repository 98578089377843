/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import toAddress from 'model/_shared/transformer/toAddress'
import withGetterSetter from 'trait/with/GetterSetter'
import fromAddress from 'model/_shared/decorator/fromAddress'
import toBoolean from 'model/_shared/transformer/toBoolean'

const descriptor = 'model::ticket::additionalParty'

export default pipe(
  modelComposition(descriptor),
  usesGetterSetter(G.TRANSFORMER, {
    address: toAddress('address'),
    informationTransferApproved: toBoolean('informationTransferApproved'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    address: fromAddress('address'),
  }),
)
