/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { reset } from 'lib/sequence/component/state/value'

/**
 * ResetOrganisationStepSearchField event handler
 *
 * Resets the search field of the organisation step.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
export default curry(async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const { organisation } = asObject(stepper[G.CHILDREN])
  const { search: searchTab } = asObject(organisation[G.CHILDREN])
  const { searchField } = asObject(searchTab[G.CHILDREN])
  reset(searchField)
})
