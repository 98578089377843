/* global G */
import { curry } from 'lib/util'
import types from 'model/contactChannels/collection/types'

/**
 * Get a list of translated contact channel types.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent|string} eventOrKey
 *
 * @return {contactChannel} contactChannels
 */
const listContactChannelTypes = (module, component, eventOrKey) => {
  const key = eventOrKey?.detail?.key || eventOrKey
  const scope = key ? Object.keys(types).filter(item => item.key === key) : Object.keys(types)

  return scope.map(item => ({
    key: item,
    value: module[G.ADAPTER][G.INTL]._t(
      `label.${item}`,
      {
        ns: 'common',
        _key: `label.${item}`,
        defaultValue: item,
      },
    ),
  }))
}

export default curry(listContactChannelTypes)
