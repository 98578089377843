/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Event Handler Get Conversations
 *
 * Lists all the conversations for the current ticket. For now, this is only the
 * {@code requesterContact}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 */
const getConversations = (module, component, event) => {
  const model = module[G.MODEL]
  const { request, requesterContactData: requesterContact } = model[G.CHILDREN]
  const requesterContactData = requesterContact[G.CACHE]
  const requestData = request[G.CACHE]

  return {
    hidden: !requestData?.length,
    data: [requesterContactData],
  }
}

export default curry(getConversations)
