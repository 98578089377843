/* global G */

const descriptor = Symbol('hook::module::create').toString()

/**
 * Create Hook
 *
 * Configures the {@link CreateButton}, which should be available in the AppBar component, by
 * attaching the onCreate event handler as a new property of the action.ui.search object.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): *[]}
 */
const create = obj => (...args) => {
  const actionUI = obj[G.STATE][G.ACTION][G.UI]
  try {
    obj[G.ADAPTER][G.ACL].create(actionUI.create?.acl)
  } catch (e) {
    actionUI.create = null
    return args
  }
  try {
    const createEvents = obj[G.EVENTS].onCreate
    const onCreate = createEvents[Object.keys(createEvents)[0]] // Using first event
    const component = obj[G.STATE][G.ACTION][G.COMPONENT]
    const onCreatePartial = onCreate(obj, component)
    actionUI.create = { ...actionUI.create, onCreate: onCreatePartial }
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }
  return args
}

export default create
