import { curry } from 'lib/util'
import getDeepestNode from 'app/_shared/events/file/node/getDeepestNode'
import hasSingularChild from 'app/_shared/events/file/node/predicates/hasSingularChild'

/**
 * Traverses the {@param eventOrOptions.node} tree and returns a target node based on the
 * incoming options.
 *
 * If {@param eventOrOptions.notifyOnSkippable} is {@code true}, {@param options.skippable}
 * (defaulting to {@link hasSingularChild}) will be executed with the current node. If the
 * predicate returns {@code true}, the node will be marked as {@code skippable}.
 *
 * If {@param eventOrOptions.skipSingleNode} is {@code true}, {@link getDeepestNode} will
 * be executed with the current node and its result will be returned. This will only have
 * an effect if {@param eventOrOptions.notifyOnSkippable} is falsy.
 *
 * If neither of the above is defined, the current node will be returned if its id is
 * equal to {@code eventOrOptions.nodeId}, otherwise the handler will recursively be
 * called with each child of the node.
 *
 * @param {Object} options                  options for the handler
 * @param {Function} options.skippable       predicate to determine whether the current node is skippable
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Event || {}} eventOrOptions      the event object that triggered this handler
 * @param {Object} [eventOrOptions.node]    the node to compare the {@param eventOrOptions.nodeId} to.
 * @param {String} [eventOrOptions.nodeId]  the id of the note to find.
 * @returns {Promise<*|undefined>}
 */
const getNode = (options, module, component, eventOrOptions) => {
  const { skippable = hasSingularChild } = options
  const { node, nodeId, skipSingleNode, notifyOnSkippable } = eventOrOptions?.detail || eventOrOptions || {}

  if (node.id === nodeId && notifyOnSkippable) {
    skippable?.(node) && (node.skippable = true)
  }

  if (node.id === nodeId && skipSingleNode && !notifyOnSkippable) {
    return getDeepestNode({ node })
  }

  if (node.id === nodeId) {
    return node
  }

  if (node?.$children) {
    for (let i = 0; i < node.$children.length; i++) {
      const found = getNode(options, module, component, { node: node.$children[i], nodeId, skipSingleNode, notifyOnSkippable })
      if (found) return found
    }
  }

  return undefined
}

/**
 * Execute {@link getNode} with incoming {@code options}.
 * @type {(function(...[*]): (*))|*}
 */
export const getNodeWith = curry(getNode)

/**
 * Set {@code options} to {@code {}} to use default values defined in {@link getNode}.
 */
export default curry((module, component, event) => getNode({}, module, component, event))
