/**
 * DocumentTree node types collection
 *
 * Contains a static list of available node types for a given document tree.
 * Acts as a mapping between types present in the incoming tree and the
 * types recognized by the {@link Tree} component.
 *
 * Our component only understands two types: {@code Directory} and {@code File}.
 * But the tree fetched by the server may have more types.
 *
 * @example
 * {
 *     key: 'Directory' // fetched type
 *     value: 'Directory' // our type
 * }
 */
export default [
  {
    key: 'Directory',
    value: 'Directory',
  },
  {
    key: 'File',
    value: 'File',
  },
  {
    key: 'Url',
    value: 'File',
  },
]
