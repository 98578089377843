/* global G */
import { curry } from 'lib/util'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'

/**
 * Redirect To Detail Or Attachment Event Handler
 *
 * Redirects either to a details page or to an attachment route depending
 * on the {@code type} of the resource at hand.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<void>}
 */
const redirectToDetailOrAttachment = async (module, component, event) => {
  const moduleState = module[G.STATE]
  const { key, item } = event?.detail || event || {}
  const { value = {} } = item || {}
  const { route } = component[G.CONFIGURATION]

  const currentAttachment = value?.attachment?.id
    ? { key: value.attachment.id, ...value.attachment.attachment }
    : null

  const documentationArgs = {
    value: {
      ...value?.attachment?.id && { attachment: currentAttachment },
      ...value?.url && { attachment: { value: { url: value.url, name: value.label } } },
      ...(value?.documentationId || value?.key) && { documentationId: value.documentationId || value.key },
    },
  }

  const args = (value?.documentType === 'documentation' && documentationArgs) || { key }

  moduleState[G.ROUTE] = routeComposition(route?.module, route.action)

  await redirectSequence(module)(args)
}

export default curry(redirectToDetailOrAttachment)
