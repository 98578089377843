/* eslint-disable no-unused-vars,no-plusplus,arrow-body-style,no-param-reassign,
object-curly-newline,no-restricted-globals,no-alert,no-unused-expressions, prefer-destructuring,
implicit-arrow-linebreak */
/* global G */
import routeComposition from 'trait/composition/route'
import { curry, getFirstItem, isNum, setKey } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import find from 'lib/sequence/component/children/find'
import { reset, set } from 'lib/sequence/component/state/value'
import { actionFn } from 'lib/sequence/module/action'
import refresh from 'lib/sequence/model/api/refresh'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import { hide, show } from 'lib/sequence/component/state/hidden'
import {
  back,
  bulk,
  detail,
  edit,
  list,
  persistChange,
  persistCheck,
  persistOption,
  persistOptions,
  redirect,
} from '@app/_shared/events'
import withModelKey from 'app/_shared/events/redirect/withModelKey'
import { acl, permission } from 'app/_shared/events/acl'
import { settings } from 'app/_shared/session'
import chain from 'app/_shared/events/util/chain'
import sublist from 'app/_shared/events/util/sublist'
import debounce from 'app/_shared/events/util/debounce'
import { add as addAttachment, apply, create, remove, undo } from 'app/_shared/events/attachment'
import newTab from 'app/_shared/events/redirect/newTab'
import search from 'app/_shared/events/search'
import infinite from 'app/_shared/events/search/infinite'
import { document, filteredOption, item, messages, reference } from 'app/_shared/events/pubsub'
import listByIds from 'app/_shared/events/list/byIds'
import listCountries from 'app/_shared/events/collection/listCountries'
import listUserCountries from 'app/_shared/events/collection/listUserCountries'
import persistNameOption from 'app/_shared/events/persistNameOption'
import createRedirect from 'app/_shared/events/appbar/createRedirect'
import searchExclude from 'app/_shared/events/searchExclude'
import searchAndRecreate from 'app/_shared/events/appbar/searchAndRecreate'
import selectAndAction from 'app/_shared/events/combined/selectAndAction'
import persistChangeAndAction from 'app/_shared/events/combined/persistChangeAndAction'
import persistCheckAndAction from 'app/_shared/events/combined/persistCheckAndAction'
import { confirm, deconfirm, jump, next } from 'app/_shared/events/step'
import link from 'app/_shared/events/link'
import itemLink from 'app/_shared/events/link/item'
import action from 'app/_shared/events/action'
import clean from 'app/_shared/events/util/clean'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'
import listTicketStatus from 'app/_shared/events/collection/listTicketStatus'
import listStatusReasons from 'app/_shared/events/collection/listStatusReasons'
import listMessageTypes from 'app/_shared/events/collection/listMessageTypes'
import listNoteTypes from 'app/_shared/events/collection/listNoteTypes'
import listOrganisationTypes from 'app/_shared/events/collection/listOrganisationTypes'
import listPrefChannels from 'app/_shared/events/collection/listPrefChannels'
import listTicketFilterStatus from 'app/_shared/events/collection/listTicketFilterStatus'
import listTicketFilterTeam from 'app/_shared/events/collection/listTicketFilterTeam'
import listTicketFilterAssignee from 'app/_shared/events/collection/listTicketFilterAssignee'
import listTicketFilterCreated from 'app/_shared/events/collection/listTicketFilterCreated'
import listSerialFieldOptions from 'app/_shared/events/collection/listSerialFieldOptions'
import loadAttachments from 'app/_shared/events/message/loadAttachments'
import menu from 'app/_shared/events/contextmenu'
import sendAndBackFromMessage from 'app/_shared/events/message/sendAndBackFromMessage'
import searchStepAndRecreate from 'app/_shared/events/searchStepAndRecreate'
import setStepTab, { CONFIRM, LIST, SEARCH } from 'app/_shared/events/setStepTab'
import soon from 'app/_shared/events/soon'
import prefillSupportedBy from 'app/_shared/events/prefillSupportedBy'
import modelReset from 'app/_shared/events/model/reset'
import resetActionComponent from 'app/_shared/events/resetActionComponent'
import modelSet from 'app/_shared/events/model/set'
import actionByKey from 'app/_shared/events/action/byKey'
import persistStore from 'app/_shared/events/combined/persistStore'
import persistTab from 'app/_shared/events/tabs/persist'
import listInvitationRoles from 'app/_shared/events/collection/listInvitationRoles'
import toRef from 'app/_shared/events/redirect/toRef'
import toAction from 'app/_shared/events/redirect/toAction'
import searchUnsortedAndRecreate from 'app/_shared/events/appbar/searchUnsortedAndRecreate'
import searchStepUnsortedAndRecreate from 'app/_shared/events/searchStepUnsortedAndRecreate'
import searchWith from 'app/_shared/events/search/with'
import setCountedLabel from 'app/_shared/events/tabs/setCountedLabel'
import {
  belongsToOneOfMyTeams,
  forwardedByOneOfMyTeams,
  userHasIdAndRoles,
} from 'app/_shared/events/search/query'
import listSoftwarePrograms from 'app/_shared/events/collection/listSoftwarePrograms'
import listRegisterOrganisationTypes
  from 'app/_shared/events/collection/listRegisterOrganisationTypes'
import listRegisterRequestTypes from 'app/_shared/events/collection/listRegisterRequestTypes'
import persistOptionAndAction from 'app/_shared/events/combined/persistOptionAndAction'
import listLanguages from 'app/_shared/events/collection/listLanguages'
import setInviteeRole from 'app/_shared/events/invite/setRole'
import { persistUnsaveChange } from 'app/_shared/events/persist/change'
import saveAndBackFromMessage from 'app/_shared/events/message/saveAndBackFromMessage'
import user from 'app/_shared/events/mention/user'
import check from 'app/_shared/events/util/check'
import listUserRoles from 'app/_shared/events/collection/listUserRoles'
import remember from 'app/_shared/events/remember'
import checkStore from 'app/ticket/event/checkStore'
import searchValidTeams from 'app/ticket/event/searchValidTeams'
import updateUnreadMessagesCount from 'app/ticket/event/updateUnreadMessagesCount'
import sendStatusMessage from 'app/ticket/event/sendStatusMessage'
import addNote, { addTimeEntry } from 'app/ticket/event/note/add'
import {
  claim,
  close,
  forward,
  postpone,
  reopen,
  resetStatusReason,
  stop,
} from 'app/ticket/event/quick'
import modelAdd from 'app/ticket/event/modelAdd'
import newNote from 'app/ticket/event/note/new'
import cancelNote from 'app/ticket/event/note/cancel'
import deleteNote from 'app/ticket/event/note/delete'
import registerAsInstalledAt from 'app/ticket/event/registerAsInstalledAt'
import newDevice from 'app/ticket/event/newDevice'
import searchDevice from 'app/ticket/event/searchDevice'
import checkAssigneeAndSetAsRead from 'app/ticket/event/checkAssigneeAndSetAsRead'
import updateTicketList from 'app/ticket/event/updateTicketList'
import { checkSubmitterValidity, setDetailUndoKey } from 'app/ticket/action/detail'
import noRequestModal from 'app/ticket/event/noRequestModal'
import updateParentOrganisations from 'app/ticket/event/updateParentOrganisations'
import multiSearchStepAndRecreate from 'app/ticket/event/multiSearchStepAndRecreate'
import resetOrganisationStepSearchField from 'app/ticket/event/resetOrganisationStepSearchField'
import showSelectedDeviceOnlyToast from 'app/ticket/event/showSelectedDeviceOnlyToast'
import clearMessageUndo from 'app/ticket/event/clearMessageUndo'
import checkRequestNumber from 'app/ticket/event/form/checkRequestNumber'
import checkAccount from 'app/ticket/event/form/checkAccount'
import searchEquipment from 'app/ticket/event/form/searchEquipment'
import {
  isComplexTimeTrackingRequired,
  isComplexTimeTrackingVisible,
  sortNotesByDate,
} from 'app/ticket/hook/note'
import { toTimeEntry } from 'app/ticket/event/note/time'
import updateTimeNotesList from 'app/ticket/event/updateTimeNotesList'
import getRequestInformation from 'app/ticket/event/getRequestInformation'
import getConversations from 'app/ticket/event/getConversations'
import toggleContactEmailValidator from 'app/ticket/event/toggleContactEmailValidator'
import redirectWithStep from 'app/ticket/event/redirectWithStep'
import searchAllOrMyTeams from 'app/ticket/event/searchAllOrMyTeams'
import showErrorIfRequestExists from 'app/ticket/event/showErrorIfRequestExists'
import checkContactOrgAgainstRequesterOrg from 'app/ticket/event/checkContactOrgAgainstRequesterOrg'
import listingByStatus from 'app/ticket/event/list/byStatus'
import cancel from 'app/ticket/event/form/cancel'
import internal from 'model/organisation/collection/internal'
import pdf from 'app/_shared/events/file/pdf'
import { disable } from 'lib/sequence/component/state/disabled'

export default {
  acl: {
    acl,
    permission,
  },
  onOpen: {
    list: list('default'),
    listing: list('short'),
    listingByStatus: listingByStatus('short'),
    listWithRefs: list(null),
    listVerbose: list('verbose'),
    listByIds: listByIds('default'),
    listMessageTypes: listMessageTypes('ticket'),
    listNoteTypes,
    listTicketStatus,
    listSoftwarePrograms,
    listStatusReasons,
    listCountries,
    listLanguages,
    listWithTicketCount: list('defaultWithTicketCount'),
    listTimeNotes: curry(async (module, component, event) => module[G.STATE]?.timeNotes || []),
    listNotes: curry(async (module, component, event) => {
      const notes = await list(null, module, component, event)
      // Sorting notes by startDate and submitTimestamp
      const sortedNotes = sortNotesByDate(notes)
      const timeNotes = sortedNotes.filter(note => isNum(note.value?.spentTime))
      setKey(timeNotes, 'timeNotes', module[G.STATE])

      return sortedNotes
    }),
    infinite: infinite(null),
    infiniteVerbose: infinite('verbose'),
    search: search(null),
    searchShort: search('short'),
    searchVerbose: search('verbose'),
    searchAllOrMyTeams,
    searchValidTeams,
    searchDevice,
    newDevice,
    noDevice: curry(async (m, c, e) => [{ value: { } }]),
    searchWithoutRequesterOrg: searchExclude('verbose', 'requesterOrg'),
    searchWithoutAdditionalParty: chain(
      searchWith(['filter']),
      searchExclude('verbose', 'additionalParty'),
    ),
    getValues: curry(async (module, component, event, values) => values || []),
    link,
    reference,
    checkAssigneeAndSetAsRead,
    item: itemLink,
    linkToDevice: toRef('item'),
    linkToRequesterOrg: curry((module, component, event) => {
      const model = module[G.MODEL]
      const { requesterOrg } = model[G.CHILDREN]
      const blocked = internal.some(o => o.key === requesterOrg[G.STATE][G.REF])

      return blocked ? null : toRef('requesterOrg', module, component, event)
    }),
    linkToRequesterContact: curry((module, component, event) => {
      const model = module[G.MODEL]
      const { requesterContact } = model[G.CHILDREN]

      checkSubmitterValidity(module)()
      const { isBlocked } = requesterContact[G.STATE][G.META]
      return isBlocked ? null : toRef('requesterContact', module, component, event)
    }),
    linkToAdditionalParty: toRef('additionalParty'),
    setTabSearch: searchWith(['query', 'filter']),
    getConversations,
  },
  onSubscription: {
    reference,
  },
  onPublication: {
    refreshMessages: messages,
    updateTicketList,
    updateTimeNotesList,
    document,
  },
  onCreate: {
    createRedirect,
    getRequestInformation,
  },
  onSearch: {
    // First event handler will be used in search bar
    searchUnsortedAndRecreate,
    searchAndRecreate,

    searchStepAndRecreate,
    searchStepUnsortedAndRecreate,

    multiSearchStepAndRecreate,
  },
  onClose: {
    redirect,
    back,
    cancelNote,
    backAndUndo: chain(
      back,
      undo,
    ),
    cancel,
  },
  onClick: {
    edit,
    detail,
    back,
    backToDetail: toAction('detail'),
    cancel,
    redirect,
    newTab,
    modelRedirect: withModelKey,
    bulk: chain(
      action, // needed to update last step's visited state
      bulk,
    ),
    create,
    remove,
    confirmStep: chain(
      confirm,
      curry(async (module, component, event) => {
        const model = module[G.MODEL]
        model[G.STATE][G.REF]
          ? await action(module, component, event)
          : await next(module, component, event)
      }),
    ),
    newOrganisation: chain(
      resetActionComponent('organisation'),
      checkStore('organisation', true),
      toAction('organisation'),
    ),
    setOrganisation: chain(
      resetActionComponent('organisation'),
      modelSet('requesterOrg'),
      checkStore('organisation', false),
      setStepTab(CONFIRM),
    ),
    addOrganisation: chain(
      modelAdd('requesterOrg'),
      updateParentOrganisations(false),
      check,
      back,
      deconfirm,
      setStepTab(CONFIRM),
    ),
    searchOrganisation: chain(
      curry(async (module, component, event) => {
        const model = module[G.MODEL]
        const { requesterOrg } = model[G.CHILDREN]
        const { name, address } = requesterOrg[G.CHILDREN]
        const { summary1, summary2 } = address[G.CHILDREN]
        const nameValue = name[G.CACHE] || ''
        const addressValue = `${summary1[G.CACHE] || ''} ${summary2[G.CACHE] || ''}`
        const term = `${nameValue} ${addressValue}`.trim()

        const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
        const { organisation: organisationStep } = find(actionComponent)
        const { search: searchTab } = find(organisationStep)
        const { searchField, organisations: organisationsList } = find(searchTab)

        const { btnUndoSearch } = asObject(searchTab[G.ACTIONS])

        show(btnUndoSearch)
        set(searchField, term)
        organisationsList[G.STATE][G.META] = { term }
      }),
      setStepTab(SEARCH, 'organisation'),
      action,
    ),
    undoSearchOrganisation: setStepTab(CONFIRM, 'organisation'),
    unsetOrganisation: chain(
      updateParentOrganisations(true),
      modelReset('requesterOrg'),
      modelReset('requesterOrgData'),
      resetActionComponent('organisation'),
      curry((module, component, event) => {
        const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
        const { organisation: organisationStep } = find(actionComponent)
        const { search: searchTab } = find(organisationStep)
        const { btnUndoSearch } = asObject(searchTab[G.ACTIONS])
        const { searchField } = find(searchTab)

        hide(btnUndoSearch)
        reset(searchField)
      }),
      setStepTab(SEARCH),
    ),
    newContact: chain(
      resetActionComponent('contact'),
      toAction('contact'),
    ),
    setContact: chain(
      checkContactOrgAgainstRequesterOrg,
      resetActionComponent('contact'),
      modelSet('requesterContact'),
      checkStore('contact', false),
      setStepTab(CONFIRM),
    ),
    addContact: chain(
      setInviteeRole('requesterContact', 'customer'),
      toggleContactEmailValidator,
      modelAdd('requesterContact'),
      check,
      back,
      deconfirm,
      setStepTab(CONFIRM),
    ),
    searchContact: chain(
      curry((module, component, event) => {
        const model = module[G.MODEL]
        const { requesterContact } = model[G.CHILDREN]
        const { name, businessRole } = requesterContact[G.CHILDREN]
        const nameValue = name[G.CACHE] || ''
        const businessRoleValue = businessRole[G.CACHE] || ''
        const term = `${nameValue} ${businessRoleValue}`.trim()

        const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
        const { contact: contactStep } = find(actionComponent)
        const { search: searchTab } = find(contactStep)
        const { searchField, list: contactsList } = find(searchTab)

        const { btnUndoSearch } = asObject(searchTab[G.ACTIONS])

        show(btnUndoSearch)
        set(searchField, term)
        contactsList[G.STATE][G.META] = { term }
      }),
      setStepTab(SEARCH, 'contact'),
      action,
    ),
    undoSearchContact: setStepTab(CONFIRM, 'contact'),
    unsetContact: chain(
      showErrorIfRequestExists,
      modelReset('requesterContact'),
      modelReset('requesterContactData'),
      resetActionComponent('contact'),
      curry((module, component, event) => {
        const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
        const { contact: contactStep } = find(actionComponent)
        const { search: searchTab } = find(contactStep)
        const { btnUndoSearch } = asObject(searchTab[G.ACTIONS])
        const { searchField } = find(searchTab)

        hide(btnUndoSearch)
        reset(searchField)
      }),
      setStepTab(SEARCH),
    ),
    setContactAndOrganisation: chain(
      showErrorIfRequestExists,
      resetActionComponent('contact'),
      modelSet('requesterContact'),
      curry(async (module, component, event) => {
        const contact = event.detail.item
        const organisation = contact.refs.organisation[0]
        const { key } = organisation
        await resetActionComponent('organisation')
        await modelSet('requesterOrg', module, component, { detail: { key, item: organisation } })
      }),
      checkStore('organisation', false),
      checkStore('contact', false),
      setStepTab(CONFIRM, 'organisation'),
      setStepTab(CONFIRM, 'contact'),
    ),
    addItem: chain(
      modelSet('item'),
      curry(async (module, component, event) => {
        const { suppressProductDevices } = settings
        await setStepTab(suppressProductDevices ? CONFIRM : LIST)(module, component, event)
      }),
    ),
    setItem: chain(
      modelSet('item'),
      setStepTab(CONFIRM),
    ),
    setItemAndInstalledAt: chain(
      modelSet('item'),
      curry(async (module, component, event) => {
        const device = event.detail.item
        const installedAt = device.refs.installedAt?.[0]
        if (installedAt) {
          const { key } = installedAt
          await modelSet('requesterOrg', module, component, { detail: { key, item: installedAt } })
          await checkStore('organisation', false)(module, component, event)
          await setStepTab(CONFIRM, 'organisation')(module, component, event)
        } else {
          resetOrganisationStepSearchField(module, component, event)
          await showSelectedDeviceOnlyToast(module, component, event)
        }
      }),
      setStepTab(CONFIRM, 'device'),
    ),
    setItemAndServiceBy: chain(
      modelSet('item'),
      curry(async (module, component, event) => {
        const device = event.detail.item
        const serviceBy = device.refs.serviceBy?.[0]
        if (serviceBy) {
          const { key } = serviceBy
          await modelSet('requesterOrg', module, component, { detail: { key, item: serviceBy } })
          await checkStore('organisation', false)(module, component, event)
          await setStepTab(CONFIRM, 'organisation')(module, component, event)
        } else {
          resetOrganisationStepSearchField(module, component, event)
          await showSelectedDeviceOnlyToast(module, component, event)
        }
      }),
      setStepTab(CONFIRM, 'device'),
    ),
    setItemOnly: chain(
      modelSet('item'),
      resetOrganisationStepSearchField,
      showSelectedDeviceOnlyToast,
      setStepTab(CONFIRM, 'device'),
    ),
    searchItem: chain(
      curry((module, component, event) => {
        const model = module[G.MODEL]
        const { serial } = model[G.CHILDREN].item[G.CHILDREN]
        const serialValue = serial[G.CACHE]

        const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
        const { device: deviceStep } = find(actionComponent)
        const { search: searchTab } = find(deviceStep)
        const { searchField, list: devicesList } = find(searchTab)

        const { btnUndoSearch } = asObject(searchTab[G.ACTIONS])

        show(btnUndoSearch)
        set(searchField, serialValue)
        devicesList[G.STATE][G.META] = { term: serialValue }
      }),
      setStepTab(SEARCH, 'device'),
      action,
    ),
    undoSearchItem: setStepTab(CONFIRM, 'device'),
    setProduct: curry(async (module, component, event) => {
      const model = module[G.MODEL]
      const product = event.detail.item
      const { item: device } = model[G.CHILDREN]
      device[G.CHILDREN].product[G.DATA].value = [product]
      device[G.DATA].name = product?.value?.name
      await refresh(device)
      await setStepTab(CONFIRM)(module, component, event)
    }),
    unsetItem: chain(
      modelReset('item'),
      modelReset('itemData'),
      modelReset('itemInstalledAt'),
      modelReset('itemServiceBy'),
      modelReset('equipment'),
      modelReset('product'),
      resetActionComponent('item'),
      curry((module, component, event) => {
        const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
        const { device: deviceStep } = find(actionComponent)
        const { search: searchTab } = find(deviceStep)
        const { btnUndoSearch } = asObject(searchTab[G.ACTIONS])
        const { searchField } = find(searchTab)

        hide(btnUndoSearch)
        reset(searchField)
      }),
      setStepTab(SEARCH),
    ),
    newParty: chain(
      resetActionComponent('party'),
      checkStore('party', true),
      toAction('party'),
    ),
    setParty: chain(
      resetActionComponent('party'),
      modelSet('additionalParty'),
      checkStore('party', false),
      setStepTab(CONFIRM),
    ),
    addParty: chain(
      modelAdd('additionalParty'),
      check,
      back,
      deconfirm,
      setStepTab(CONFIRM),
    ),
    searchParty: chain(
      curry((module, component, event) => {
        const model = module[G.MODEL]
        const { additionalParty } = model[G.CHILDREN]
        const { name, address } = additionalParty[G.CHILDREN]
        const { summary } = address[G.CHILDREN]
        const nameValue = name[G.CACHE] || ''
        const addressValue = summary[G.CACHE] || ''
        const term = `${nameValue} ${addressValue}`.trim()

        const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
        const { party: partyStep } = find(actionComponent)
        const { search: searchTab } = find(partyStep)
        const { searchField, list: partiesList } = find(searchTab)

        const { btnUndoSearch } = asObject(searchTab[G.ACTIONS])

        show(btnUndoSearch)
        set(searchField, term)
        partiesList[G.STATE][G.META] = { term }
      }),
      setStepTab(SEARCH, 'party'),
      action,
    ),
    undoSearchParty: setStepTab(CONFIRM, 'party'),
    unsetParty: chain(
      modelReset('additionalParty'),
      modelReset('additionalPartyData'),
      resetActionComponent('party'),
      curry((module, component, event) => {
        const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
        const { party: partyStep } = find(actionComponent)
        const { search: searchTab } = find(partyStep)
        const { btnUndoSearch } = asObject(searchTab[G.ACTIONS])
        const { searchField } = find(searchTab)

        reset(searchField)
        hide(btnUndoSearch)
      }),
      setStepTab(SEARCH),
    ),
    jump,
    next,
    claim,
    forward,
    stop: curry(async (module, component, event) => {
      const currentAction = module[G.STATE][G.ACTION]
      const stopAction = module[G.ACTIONS].stop

      if (settings.suppressTicketStopDialog || currentAction === stopAction) {
        await stop(module, component, event)
      } else {
        setKey(routeComposition(null, 'stop'), G.ROUTE, module[G.STATE])
        await redirectSequence(module)({ detail })
      }
    }),
    postpone,
    close,
    reopen,
    soon,
    newNote,
    addNote,
    addTimeEntry,
    timeTracking: toAction('timeTracking'),
    pdf,
    applyAndBack: chain(
      apply,
      clean,
      back,
    ),
    backAndUndo: chain(
      back,
      undo,
    ),
    sendAndBackFromMessage,
    saveAndBackFromMessage,
    backFromNote: chain(
      clean,
      back,
    ),
    toSearch: chain(
      modelReset('item'),
      setStepTab(SEARCH),
    ),
    sendNote: actionByKey('sendNote'),
    bulkForm: actionByKey('bulkForm'),
    bulkEdit: chain(
      action,
      actionByKey('bulkEdit'),
    ),
    redirectWithStep,
  },
  onDelete: {
    deleteNote,
  },
  onChange: {
    persistChange,
    persistOption,
    persistOptions,
    persistNameOption,
    persistCheck,
    persistUnsaveChange,
    selectAndAction,
    selectRememberAndAction: chain(
      persistOptions,
      remember,
      action,
    ),
    changeTeamList: curry(async (module, component, event) => {
      const { filter } = asObject(module[G.STATE][G.ACTION][G.COMPONENT][G.CHILDREN])
      const { assignee } = asObject(filter[G.CHILDREN])
      reset(assignee)
      await persistOptions(module, component, event)
      await remember(module, component, event)
      await action(module, component, event)
    }),
    changeTeamWizard: curry(async (module, component, event) => {
      const { stepper } = asObject(module[G.STATE][G.ACTION][G.COMPONENT][G.CHILDREN])
      const { finish } = stepper ? asObject(stepper[G.CHILDREN]) : {}
      const { assignee } = finish ? asObject(finish[G.CHILDREN]) : {}
      reset(assignee)
      await selectAndAction(module, component, event)
    }),
    changeTeamForward: curry(async (module, component, event) => {
      const { assignee } = find(module[G.STATE][G.ACTION][G.COMPONENT])
      set(assignee, null)
      persistOptions(module, component, event)
      await actionFn(module[G.STATE][G.ACTION])({})
      module[G.ADAPTER][G.UI].update(module)
    }),
    changeAssigneeForward: chain(
      persistOptions,
      async (module, component, event) => module[G.ADAPTER][G.UI].update(module),
    ),
    persistChangeAndAction,
    persistChangeRememberAndAction: chain(
      persistChange,
      remember,
      action,
    ),
    persistTicketDescription: chain(
      persistChange,
      curry((module, component, event) => {
        const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
        const { issue } = find(actionComponent)
        const stepState = issue[G.STATE]
        const fieldState = component[G.STATE]
        // setting step as confirmed according to the value of the description field
        stepState.completed = !!fieldState.value
        stepState.confirmed = stepState.completed
        stepState.title = fieldState.value
        module[G.ADAPTER][G.UI].update(module)
      }),
    ),
    debouncedPersistChangeAndAction: debounce(persistChangeAndAction, 300),
    persistOptionAndAction,
    persistCheckAndAction,
    persistType: chain(
      persistCheckAndAction,
      confirm,
      next,
    ),
    persistStore,
    persistStoreOrganisation: chain(
      curry(async (module, component, event) => {
        event.detail.checked && await prefillSupportedBy(module, component, event)
      }),
      persistStore,
    ),
    persistTabAndRemember: chain(
      persistTab,
      remember,
    ),
    add: addAttachment,
    addAttachment,
    checkContent: curry((module, component, event) => {
      const { text } = event.detail
      setDetailUndoKey(module, 'message', !!text)
    }),
    persistCountry: chain(
      persistOptions,
      prefillSupportedBy,
    ),
    fillSupportedByAndPersistChange: chain(
      persistChange,
      prefillSupportedBy,
    ),
    registerAsInstalledAt,
    persistChangeAndCheckRequestNumber: debounce(chain(
      persistChange,
      checkRequestNumber,
    ), 1000),
    persistChangeAndValidateAccount: debounce(chain(
      persistChange,
      checkAccount,
    ), 1000),
    persistChangeAndSearchEquipment: debounce(chain(
      persistChange,
      searchEquipment,
    ), 1000),
    getUserMentions: user,
  },
  onSend: {
    sendStatusMessage: chain(
      clearMessageUndo,
      noRequestModal,
      sendStatusMessage,
      curry(async (module, component, event) => {
        const model = module[G.MODEL]
        const { status, statusReason } = getFirstItem(model[G.CACHE])?.value || {}

        status === 50
          && statusReason === 36
          && await resetStatusReason(module, component, event)
      }),
    ),
  },
  onStar: {
    soon,
  },
  onAttach: {
    attachmentsRedirect: chain(
      noRequestModal,
      redirect,
    ),
    redirect,
    soon,
  },
  onAttachment: {
    addAttachment,
  },
  onFocus: {
    checkAssigneeAndSetAsRead,
  },
  onAttachments: {
    loadAttachments,
  },
  onContextMenu: {
    menu: menu(null),
  },
  onFeedback: {
    convertTime: toTimeEntry,
  },
  onType: {
    toggleSpentTime: curry((module, component, event) => {
      const ticket = module[G.MODEL]
      const { value } = event.detail

      const { spentTime } = find(component)
      const [currentNoteType] = listNoteTypes(module, component, value)

      if (!currentNoteType) {
        spentTime && hide(spentTime)
      }

      const spentTimeValidator = ticket[G.VALIDATOR].spentTime
      disable(spentTimeValidator, !isComplexTimeTrackingRequired(module, currentNoteType))

      return isComplexTimeTrackingVisible(module, currentNoteType)
    }),
  },
  onPage: {
    remember,
  },
  getOrganisationType: {
    listOrganisationTypes,
  },
  getTicketType: {
    listTicketTypes,
  },
  getStatus: {
    listTicketStatus,
  },
  getStatusReason: {
    listStatusReasons,
  },
  getNoteType: {
    listNoteTypes,
  },
  getSelection: {
    listTicketTypes,
    listPostponedReasonTypes: sublist([61, 62], listStatusReasons, true),
    listClosedReasonTypes: sublist([80, 89], listStatusReasons, true),
    listClosedReasonTypesRadio: sublist([80, 89], listStatusReasons),
    listCreationStatus: sublist([80, 60], listTicketStatus),
    listOrganisationTypes: curry(async (module, component, event) => {
      return sublist(
        ['customer', 'independent_contractor', 'internal', 'partner'],
        listOrganisationTypes,
      )(module, component, event).map((type) => {
        delete type.icon
        return type
      })
    }),
    listPrefChannels,
    listTicketFilterStatus,
    listTicketFilterTeam,
    listTicketFilterAssignee,
    listTicketFilterCreated,
    requesterOrg: curry((module, component, event) => {
      const model = module[G.MODEL]
      const { requesterOrg } = model[G.CHILDREN]
      return requesterOrg[G.STATE][G.BULK] && !requesterOrg[G.CACHE]._rev
        ? [requesterOrg[G.STATE][G.DATA]] : []
    }),
    listInvitationRoles,
    listRegisterOrganisationTypes,
    listRegisterRequestTypes,
    listUserCountries,
  },
  getFieldOptions: {
    listSerialFieldOptions,
  },
  item: {
    sub: item,
  },
  list: {
    sub: filteredOption('team'),
    reference,
  },
  cell: {
    sub: updateUnreadMessagesCount,
  },
  getSystemMessageTypes: {
    listMessageTypes: listMessageTypes('system'),
  },
  getLabel: {
    setOwnLabel: setCountedLabel({ ns: 'ticket', key: 'myRequests' }),
    setForwardedLabel: setCountedLabel({ ns: 'ticket', key: 'forwardedRequests' }),
    listOrganisationTypes,
    listUserRoles,
  },
  filter: {
    isOpen: curry((module, component, event) => (
      { status: { max: 79 } }
    )),
    isSolved: curry((module, component, event) => (
      { status: { min: 80 } }
    )),
    personExists: curry((module, component, event) => (
      { refexists: 'person' }
    )),
    isActive: curry((module, component, event) => ({
      status: { max: 79 },
    })),
  },
  query: {
    belongsToOneOfMyTeams,
    forwardedByOneOfMyTeams,
    userHasIdAndRoles,
  },
}
