/* global G */
import settings from '@tenant/settings'
import { pipe } from 'lib/util'
import { modelComposition } from 'trait/composition'
import { usesGetterSetter } from 'trait/uses'
import { withGetterSetter } from 'trait/with'
import toRef from 'model/_shared/transformer/toRef'
import toData from 'model/_shared/transformer/toData'
import fromValue from 'model/_shared/decorator/fromValue'
import drop from 'model/_shared/transformer/drop'
import fromCollection from 'model/_shared/decorator/fromCollection'
import fromCollectionValue from 'model/_shared/decorator/fromCollectionValue'
import toFirstKey from 'model/_shared/transformer/toFirstKey'
import toBoolean from 'model/_shared/transformer/toBoolean'
import defaultTypes from 'model/_shared/collection/ticket/type'
import statusReasons from 'model/ticket/collection/statusReason'
import validator from './validator'
import statuses from './collection/status'

const descriptor = 'model::ticket'

const types = settings?.ticketTypes || defaultTypes
const tType = { _key: 'ticket.type', ns: 'custom' }
const tStatus = { _key: 'status.ticket', ns: 'ticket' }
const tStatusReason = { _key: 'statusReason', ns: 'ticket' }

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    // value
    type: toFirstKey('type'),
    typeName: drop('typeName'),
    requesterOrgData: toData('requesterOrg', 'requesterOrgData'),
    requesterContactData: toData('requesterContact', 'requesterContactData'),
    itemData: toData('item', 'itemData', true),
    additionalPartyData: toData('additionalParty', 'additionalPartyData', true),
    submit: toData('submit'),
    description: toData('description'),
    solution: toData('solution'),
    closure: toData('closure'),
    status: toFirstKey('status'),
    statusName: drop('statusName'),
    spentTime: drop('spentTime'),
    startDate: drop('startDate'),
    note: drop('note'),
    statusReason: toFirstKey('statusReason'),
    statusReasonName: drop('statusReasonName'),
    createRequest: toBoolean('createRequest'),
    account: drop('account'),
    // refs
    submitter: toRef('submitter'),
    submitterTeam: toRef('submitterTeam'),
    team: toRef('team'),
    assignee: toRef('assignee'),
    requesterOrg: toRef('requesterOrg'),
    requesterContact: toRef('requesterContact'),
    requesterContactOrg: toRef('requesterContactOrg'),
    additionalParty: toRef('additionalParty'),
    item: toRef('item'),
    itemInstalledAt: toRef('itemInstalledAt'),
    itemServiceBy: toRef('itemServiceBy'),
    product: toRef('product'),
    equipment: toRef('equipment'),
    solvingTeam: toRef('solvingTeam'),
    request: toRef('request'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    // value
    type: fromCollection('type', types, tType),
    typeName: fromCollectionValue('type', 'typeName', types, tType),
    status: fromCollection('status', statuses, tStatus),
    statusName: fromCollectionValue('status', 'statusName', statuses, tStatus),
    statusReason: fromCollection('statusReason', statusReasons, tStatusReason),
    statusReasonName: fromCollectionValue('statusReason', 'statusReasonName', statusReasons, tStatusReason),
    // refs
    team: fromValue('team', 'team'),
    assignee: fromValue('assignee', 'assignee'),
  }),
)
