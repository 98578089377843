import { curry } from 'lib/util'

/**
 * Find Initial Node Event Handler
 *
 * Traverses the tree and finds a node with the propery {@code initialNode} set to {@code true}.
 * If it found it, it will return it.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {PlatformEvent} event         information about the event that triggered this handler
 * @returns {{initialNode}|*}
 */
const findInitialNode = (module, component, event) => {
  const { value: node } = event?.detail || event || {}

  if (node?.initialNode) return node

  if (node?.$children && node.$children.length) {
    let matchedNode
    node.$children.forEach((child) => {
      matchedNode = findInitialNode(module, component, { detail: { value: child } }) || matchedNode
    })

    return matchedNode
  }

  return null
}

export default curry(findInitialNode)
