/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Get Quick Action Event Handler
 *
 * Looks up {@param action} in the current action's UI configuration
 * and returns it if found.
 *
 * @param {string} action                 name of the action to return
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Event} event                   the event object that triggered this handler
 * @returns {*|null}
 */
const getQuickAction = (action, module, component, event) => {
  const { [G.UI]: actionUi = {} } = module[G.STATE][G.ACTION] || {}

  return actionUi?.quickActions
    ? actionUi.quickActions.find(quickAction => quickAction.key === action)
    : null
}

export default curry(getQuickAction)
