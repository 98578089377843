/* global G */
import { curry } from 'lib/util'
import invitationStatuses from 'model/person/collection/invitation/status'

/**
 * Translated and returns the list of {@link invitationStatuses}.
 * @param {Gaia.AppModule.Spec} module            the current module object composition
 * @param {Gaia.Component} component              the object composition of the component that
 *                                                triggered the event
 * @param {Gaia.PlatformEvent|string} eventOrKey  the event or the key of the status to obtain
 * @return {Array} countries
 */
const listInvitationStatuses = (module, component, eventOrKey) => {
  const key = eventOrKey?.detail?.key || eventOrKey
  const scope = key ? invitationStatuses.filter(item => item.key === key) : invitationStatuses

  return scope.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `invitation.statuses.${item.key}`,
      {
        ns: 'person',
        _key: `invitation.statuses.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }))
}

export default curry(listInvitationStatuses)
