/* global G */
import { curry } from 'lib/util'
import bulk from 'app/_shared/events/search/bulk'

/**
 * Attempts to obtain the persons that maintain the currently viewed service item.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 *
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const getMaintainers = async (module, component, event) => {
  const model = module[G.MODEL]
  const ids = model[G.CHILDREN].maintainedBy?.[G.CACHE] || []
  const { limit } = component[G.PROPS]

  try {
    return await bulk(module, component, { ids: ids.slice(0, limit) })
  } catch (e) {
    console.error(e)
  }

  return []
}
export default curry(getMaintainers)
