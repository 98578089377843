/* global G */
import { curry, isArr, setKey } from 'lib/util'

/**
 * Persists incoming value in corresponding component's state.
 *
 * Incoming value will be converted into Array
 *
 * Since there is no need to refresh the ui, it is handled on component level only.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent} event - event
 * @return {Gaia.PlatformEvent} event
 */
const persistOption = (module, component, event) => {
  const state = component[G.STATE]
  const { item } = event.detail
  state.value = item && isArr(item) && !!item.length ? item.pop().key : item.key

  // state.value could be 0 in case of status, so check against null specifically
  state.value === null && delete state.value

  setKey(true, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
  return event
}

export default curry(persistOption)
