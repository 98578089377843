/* eslint-disable no-bitwise */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { set } from 'lib/sequence/component/state/value';

/**
 * Fill event.
 *
 * Assigns random numerical values to the fields inside the container component and recreates the
 * view.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - event
 * @returns {Promise<void>}
 */
const fill = async (module, component, event) => {
  const processStartTime = performance.now()
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { container } = asObject(actionComponent[G.CHILDREN])
  const count = container[G.CHILDREN].length

  console.log(`Setting random values to ${count} fields:`)
  container[G.CHILDREN].forEach(field => set(field, `${count * Math.random() | 0}`))
  console.log(`· Processing time: ${performance.now() - processStartTime}ms`)

  const renderStartTime = performance.now()
  module[G.ADAPTER][G.UI].update(module)
  console.log(`· Rendering time: ${performance.now() - renderStartTime}ms`)
}

export default curry(fill)
