/* global G */
import { pipe } from 'lib/util'
import { modelComposition } from '@gaia/trait/composition'
import { usesGetterSetter } from 'trait/uses'
import withGetterSetter from 'trait/with/GetterSetter'
import fromSingleRef from 'model/_shared/decorator/fromSingleRef'
import toRef from 'model/_shared/transformer/toRef'
import drop from '@model/_shared/transformer/drop'
import fromUserRoles from 'model/_shared/decorator/fromUserRoles'
import toUserRoles from 'model/_shared/transformer/toUserRoles'
import fromMultipleRef from 'model/_shared/decorator/fromMultipleRef'
import dataToExistingRef from 'model/_shared/transformer/dataToExistingRef'
import roles from 'model/account/validator/roles'
import { notEmptyAttribute } from 'model/_shared/validator'
import toPasswordGenerated from 'model/account/transformer/toPasswordGenerated'
import userExists from 'model/_shared/validator/userExists'

const descriptor = 'model::account::user'

const Model = pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, {
    username: userExists,
    roles,
    organisation: notEmptyAttribute,
  }),
  usesGetterSetter(G.TRANSFORMER, {
    person: toRef('person'),
    organisation: toRef('organisation'),
    roles: toUserRoles('roles'),
    team: dataToExistingRef('team'),
    passwordGenerated: toPasswordGenerated('passwordGenerated'),
    change: drop('change'),
    username: drop('username'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    person: fromSingleRef('person', 'person'), // this is used in intial read -> map, in user/profile!!!
    organisation: fromSingleRef('organisation', 'organisation'), // this is used in intial read -> map, in user/profile!!!
    team: fromMultipleRef('team', 'team'),
    roles: fromUserRoles('roles'),
  }),
)

export default Model
