/* global G */
import { curry } from 'lib/util'
import options from 'model/_shared/collection/options'

/**
 * Returns the translated list of generic options.
 *
 * @param {Gaia.AppModule.Spec} module    the current module object composition
 * @param {Gaia.Component} component      the object composition of the component that triggered the
 *                                        event
 * @param {Gaia.PlatformEvent} eventOrKey the event object
 * @return {Array} countries
 */
const listGenericOptions = (module, component, eventOrKey) => {
  const key = eventOrKey?.detail?.key || eventOrKey
  const scope = key ? options.filter(item => item.key === key) : options

  return scope.map(item => ({
    key: item.key,
    value: module[G.ADAPTER][G.INTL]._t(
      `options.${item.key}`,
      { ns: 'generic', _key: `options.${item.key}`, defaultValue: item.value },
    ),
  }))
}

export default curry(listGenericOptions)
