/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import listTicketForwardTeam from 'app/_shared/events/collection/listTicketForwardTeam'

/**
 * Maps translated labels to team entries.
 *
 * @param teams
 * @param labels
 * @returns {*}
 * @private
 */
const _mapLabels = (teams, labels) => teams.map((team) => {
  const matchedLabel = labels.find(label => team.match.includes(label.key))
  // eslint-disable-next-line no-param-reassign
  matchedLabel && (team.label = matchedLabel.value)
  return team
})

/**
 * Adds {@code hasRanking = true} to {@param teams} entries if their
 * score fits a certain range.
 *
 * @param teams
 * @returns {*}
 * @private
 */
const _mapRankings = teams => teams.map(
  team => (team.score > 0 && team.score < 90 ? { ...team, hasRanking: true } : team),
)
/**
 * Event Handler search valid teams
 *
 * Retrieves a rank-sorted list of teams the
 * current ticket can be forwarded to.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @return {Promise<void>}
 */
const searchValidTeams = async (module, component, event) => {
  const model = module[G.MODEL]
  const { version } = model[G.PROPS]
  const { api: { type: api } } = component[G.PROPS]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  /**
     * Getting {@code ticketId} in case we come from
     * ticket forward dialog.
     */
  const ticketId = model[G.STATE][G.REF]

  /**
     * Getting {@code ticketType} from step component
     * in case we come from ticket creation wizard.
     */
  const { stepper = { [G.CHILDREN]: {} } } = asObject(actionComponent[G.CHILDREN])
  const { step3 = { [G.CHILDREN]: {} } } = asObject(stepper[G.CHILDREN])
  const { type = {} } = asObject(step3[G.CHILDREN])
  const ticketType = type[G.STATE]?.value

  /**
   * Getting {@code product} from ticket model
   * in case we come from ticket creation wizard.
   */
  const { item = {} } = asObject(model[G.CHILDREN])
  const { key: product = null } = item[G.CACHE]?.refs?.product?.[0] || {}

  /**
     * Conditionally building {@code params} object
     * depending on what data we have.
     */
  const params = {
    ...ticketId ? { ticketId } : {},
    ...ticketType ? { ticketType } : {},
    ...product ? { product } : {},
  }
  const url = `/api/v${version}/${api}/forward`

  try {
    const remoteAdapter = model[G.ADAPTER][G.HTTP]
    const result = await remoteAdapter.post({ url, params })
    const teamLabels = listTicketForwardTeam(module, component, event)
    const labelledTeams = _mapLabels(result.value, teamLabels)

    return _mapRankings(labelledTeams)
  } catch {
    console.error('search:: tried and failed getting remote resources', url)
  }

  return []
}

export default curry(searchValidTeams)
