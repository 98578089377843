/* global G */
import { modelComposition } from '@gaia/trait/composition'
import { pipe } from 'lib/util'
import { withGetterSetter } from 'trait/with'
import { usesGetterSetter } from 'trait/uses'
import validator from './validator'

const descriptor = 'model::support'

const Model = pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
)

export default Model
