/* global G */
import { curry } from 'lib/util'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'

/**
 * Executes {@link redirect}, passing it {@code module[G.MODEL][G.STATE][G.REF]}. This is useful
 * for redirecting to an action in another module that needs the current model key.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event} event                     event
 * @returns {Promise<void>}
 */
const withModelKey = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const key = module[G.MODEL][G.STATE]?.[G.REF]

  const moduleState = module[G.STATE]
  const { route } = component[G.CONFIGURATION]
  moduleState[G.ROUTE] = routeComposition(route.module, route.action)

  await redirectSequence(module)({ key })
}

export default curry(withModelKey)
