/* eslint-disable no-plusplus,no-unused-vars,max-len */
/* global G */
import { curry, deleteKey } from 'lib/util'
import { _group } from 'app/_shared/events/attachment'

/**
 * Clean up the residual event listeners and transient properties.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Object[]} attachments        the message attachments
 * @param {Function} handler            event handler for each attachment
 * @private
 */
const _cleanup = (module, component, attachments, handler) => {
  const eventAdapter = module[G.ADAPTER][G.EVENTS]
  for (let i = 0; i < attachments.length; i++) {
    deleteKey('uploaded', attachments[i])
    eventAdapter.remove(eventAdapter.type(G.ATTACHMENT, G.READ, attachments[i].key), handler)

    // Removing the local attachments we just successfully uploaded. So the user see's an empty
    // dropzone again and can upload something else.
    eventAdapter.dispatch(
      eventAdapter.type(G.ATTACHMENT, G.REMOVE, _group(component)),
      { [G.DATA]: attachments[i] },
    )
  }
}

/**
 * Attempts to activate a draft message (and all of its attachments) by emitting a
 * {@code G.MESSAGE, G.ACTIVATE} event either after all attachments have successfully
 * been uploaded, or, in case there are no attachments, immediately.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component      the current action's main component
 * @param {Gaia.Model.Message} draftMessage the message to activate
 * @param {Object[]} [attachments]          optional attachments for the message
 * @param {Function<void>} callback         callback to execute after the message is active
 * @returns {Promise<void>}
 */
const activateDraft = async (module, component, draftMessage, attachments, callback) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  if (attachments.length) {
    for (let i = 0; i < attachments.length; i++) {
      const handler = ({ detail }) => {
        /**
         * The {@code G.ATTACHMENT, G.READ, key} event handler is the correct one to listen to.
         * It will get fired when the server returns a response after uploading an attachment.
         * However, it also gets fired before that. So in order to catch the correct event
         * we check for {@code status === 201}.
         */
        // eslint-disable-next-line no-param-reassign
        if (detail.key === attachments[i].key && detail.status === 201) attachments[i].uploaded = true

        if (!attachments.find(att => !att.uploaded)) {
          _cleanup(module, component, attachments, handler)
          eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))
          eventBus.dispatch(eventBus.type(G.MESSAGE, G.ACTIVATE), {
            [G.DATA]: draftMessage,
            [G.FN]: callback,
          })
        }
      }
      eventBus.add(eventBus.type(G.ATTACHMENT, G.READ, attachments[i].key), handler)
    }
  } else {
    eventBus.dispatch(eventBus.type(G.MESSAGE, G.ACTIVATE), {
      [G.DATA]: draftMessage,
      [G.FN]: callback,
    })
  }
}

export default curry(activateDraft)
