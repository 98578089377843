/* global G */
import { curry } from 'lib/util'
import persistCheck from 'app/_shared/events/persist/check'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Event Handler Persist Check and Action
 *
 * It executes persistCheck event handler,
 * followed by module action sequence actionWithHooks()
 *
 * It used in cases, where you want to reapply changes to UI, based on checkbox state
 *
 * TODO: should be possible to replace it with:
 *   export default chain(
 *    persistCheck,
 *    action,
 *   )
 *   But it seems that chain awaiting for persistCheck prevents the component's state from being
 *   updated. Should be further analysed.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>}
 */
const persistCheckAndAction = async (module, component, event) => {
  try {
    persistCheck(module, component, event)
    // call same action, see side effects
    await actionWithHooks(module[G.STATE][G.ACTION])([])
  } catch (e) {
    console.error(e)
  }
}

export default curry(persistCheckAndAction)
