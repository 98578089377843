/* global G */
import { curry } from 'lib/util'
import { settings } from 'app/_shared/session'

/**
 * Has Filter Event Handler
 *
 * Will check if node at hand has the filters specified in {@param filters} set and whether they
 * include the value of each of the {@param filter}s. If a filter is specified, but not present
 * in the node, or vice versa, the node will be skipped.
 *
 * @param {string} model      sub model to use
 * @param {string[]} filters  applicable filters to check against
 * @returns {(function(*, *, *): (boolean|*))|*}
 */
const hasFilter = (model, filters) => (module, component, eventOrOptions) => {
  // Checking if the node at hand has every filter specified that is also specified
  // in {@code types}. If not, skip the node. Here, {@code filters} are the filters
  // specified in the tenant config.
  if (filters.some(type => (eventOrOptions?.filter ? !eventOrOptions?.filter?.[type] : true))) {
    return true
  }

  // Getting the filters from the equipment model
  const { filter: equipmentFilters = {} } = module[G.MODEL][G.CHILDREN]?.[model]?.[G.CACHE] || {}

  return filters.reduce((acc, key) => {
    // If acc is false, it means a previous check failed, in that case, we don't need
    // to run anything, we aready know we should skip this node
    if (!acc) return acc

    // Here {@code equipmentFilters} are the filters specified in the equipment model,
    // these are the filters we need to check the node against.
    const equipmentFilter = equipmentFilters?.[key]
    const nodeFilter = eventOrOptions?.filter[key]

    // Either the equipment or the node doesn't have this specific filter set. Skip it.
    if (!equipmentFilter || !nodeFilter) return true

    // The filter may be {@code ''}, in that case, also skip it
    if (nodeFilter.includes('')) return true

    // Debug logs
    // const isMatch = nodeFilter.includes(equipmentFilter)
    // if (!isMatch) console.log('skipping node', eventOrOptions.name, nodeFilter, equipmentFilter)

    // Filters are set on both side, let's compare them and return whether
    // the specified filter is present in the node
    return nodeFilter.includes(equipmentFilter)
  }, true)
}

/**
 * Higher order function that takes {@param types} and determines the applicable filters from the
 * tenant config. If we curry the whole thing, like {@code export default curry(types, m, c, e)},
 * we need to look into the tenant settings for each node we call this filter with later. That's
 * unnecessary. We only need to have this information once for all nodes.
 *
 * This function will return a curried function with the relevant filters already present.
 *
 * @param {string} model      sub model to use
 * @param {string[]} filters  list of filters
 * @returns {(function(...[*]): (*))|*}
 */
export default (model, filters) => {
  const applicableFilters = filters.reduce((acc, key) => {
    const isApplicable = settings?.documentationTreeFilter?.filter?.find(filter => filter[key])
    if (!isApplicable) {
      console.warn(
        `Documentation tree filter "${key}" specified in business logic, \
         but not present in tenant settings. Will skip.`,
      )
    }
    return isApplicable ? [...acc, key] : acc
  }, [])

  return curry(hasFilter(model, applicableFilters))
}
