/* eslint-disable no-unused-expressions */
/* global G */
import { curry } from '@gaia/util'

const descriptor = 'model::feedback::transformer::toGeneric'

/**
 * To Generic Transformer
 *
 * Moves all properties in generic child to main model, respecting value and refs types.
 *
 * @type {(function(...[*]): (*))|*}
 */
const fn = curry((key, obj, data) => {
  console.warn(descriptor)

  const _data = data

  try {
    const item = obj[G.CHILDREN][key]
    _data.value = { ...data.value, ...item[G.STATE][G.DATA]?.value }
    _data.refs = { ...data.refs, ...item[G.STATE][G.DATA]?.refs }

    delete _data.value[key]
  } catch (e) {
    console.error(descriptor, e)
  }

  return _data
})

export default fn
