/* eslint-disable no-unused-expressions,no-unused-vars,max-len */
/* global G */
import { bulk, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import sequenceComponentState from 'lib/sequence/component/state'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { get } from 'lib/sequence/component/state/value'
import { disable } from 'lib/sequence/component/state/disabled'
import session, { settings } from 'app/_shared/session'
import types from 'model/_shared/collection/ticket/type'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'

const {
  set: setRequired,
  unset: setNotRequired,
} = sequenceComponentState('required')

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Sets the state of the current action's device-related steps according to the currently selected
 * ticket type.
 *
 * @param {Gaia.AppModule.Spec} module          the current module composition object
 * @param {Object<Boolean>} flags               type specific visibility flags. For further information
 *                                              about each flag, see the type documentation {@link types}.
 * @param {boolean} [flags.deviceInput]         show device specific steps
 * @param {boolean} [flags.deviceIdentify]      force device identification
 * @param {boolean} [flags.softwareInput]       show software related fields
 * @param {boolean} [flags.fieldServiceInput]   show field service related fields
 */
const displaySteps = (module, flags) => {
  const model = module[G.MODEL]
  const { item } = model[G.CHILDREN]
  const ticketHasItem = !!item[G.CACHE]

  const components = find(module[G.STATE][G.ACTION][G.COMPONENT])
  const { device, party, softwareInfo, fieldServiceInfo } = components
  const { search: searchDevice } = asObject(device?.[G.CHILDREN] || [])
  const { btnSkipDevice } = asObject(searchDevice?.[G.ACTIONS] || [])

  const { deviceInput, deviceIdentify, additionalPartyInput } = flags
  const { softwareInput, fieldServiceInput } = flags

  deviceInput
    ? setRequired(device)
      && btnSkipDevice && hide(btnSkipDevice)
    : setNotRequired(device)
      && btnSkipDevice && show(btnSkipDevice)

  // We hide the device and party steps accordingly, except in case the corresponding model
  // attributes have a set value
  device && (deviceInput === false && !ticketHasItem ? hide(device) : show(device))

  // No need to check if it's truthy, will work even if it's undefined.
  setKey(deviceIdentify, 'deviceIdentify', searchDevice[G.STATE])

  additionalPartyInput === false
    && hide(party)

  softwareInput && !settings.suppressSoftwareInfo
    ? bulkShow(softwareInfo)
    : bulkHide(softwareInfo)

  fieldServiceInput && !settings.suppressFieldServiceInfo
    ? bulkShow(fieldServiceInfo)
    : bulkHide(fieldServiceInfo)
}

/**
 * Returns the stepFlags object set in the {@param component}'s configuration only if the current
 * user has any of the roles stated in the adminRole property of the same configuration.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component} component
 * @returns {Object|boolean}
 */
const getRoleStepFlags = (module, component) => {
  const { roles } = session(module).context()
  const { adminRole = [], adminProps: { stepFlags = {} } = {} } = component[G.CONFIGURATION]

  return roles.some(role => adminRole.includes(role)) && stepFlags
}

/**
 * Type selection step.
 *
 * Sets the step as completed as soon as a type is selected and modifies the state of various
 * components according to the properties of the currently selected type.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const stepState = component[G.STATE]
  const { type: selection, labelTypeCannotChange } = asObject(component[G.CHILDREN])
  const selectionKey = get(selection)
  const [type] = await listTicketTypes(module, component, selectionKey || -1)
  const roleStepFlags = getRoleStepFlags(module, selection)
  // displaying steps according to the currently selected type
  displaySteps(module, {
    ...roleStepFlags || { // administrator's configuration should override the steps' visibility...
      deviceInput: type?.deviceInput,
      deviceIdentify: type?.deviceIdentify,
      additionalPartyInput: type?.additionalPartyInput,
    }, // ...but issue fields' visibility must remain dependent on the type
    softwareInput: type?.softwareInput,
    fieldServiceInput: type?.fieldServiceInput,
  })
  // setting step as complete accordingly
  stepState.completed = !!type
  stepState.title = type?.value

  model[G.STATE][G.REF]
    ? disable(selection)
      && show(labelTypeCannotChange)
    : hide(labelTypeCannotChange)

  return args
}
