/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import { showBlockingDialog } from 'app/_shared/events/dialog'

/**
 * Save Set Attachment Handler
 *
 * Returns the attachment set by {@link setAttachmentAndUpdateQuickAction} alongside a
 * {link showBlockingDialog} for the quick action to execute. This will cause the attachment
 * to be saved in the downloads folder, optionally showing the dialog if the file already
 * exists.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 * @returns {{dialog: ((function(Gaia.AppModule.Spec, Gaia.Component.Spec, (DialogOptions|Event|{detail?: Object})): Promise<*>)|*), attachment}|null}
 */
const saveSetAttachment = (module, component, event) => {
  const actionState = module[G.STATE][G.ACTION][G.STATE]
  return actionState?.attachment
    ? {
      attachment: actionState.attachment,
      dialog: showBlockingDialog,
    }
    : null
}

export default curry(saveSetAttachment)
