/* global G */
import { setData } from 'lib/sequence/model/api/set'
import find from 'lib/sequence/component/children/find'
import { asyncPipeSpread, isNum } from 'lib/util'
import { isAction } from 'app/_shared/action/util'

/**
 * Deduces the required missing properties after validation and sets them as the model's data.
 *
 * @param {Gaia.Component.Spec} module current module
 * @returns {*}
 */
const composeOrganisation = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const validationError = model[G.STATE][G.ERROR]
  const data = {}

  if (isAction(module, 'new')) {
    // Defaulting to 50 if no status is set (0 is a valid status as well!)
    const statusData = model[G.DATA]?.status
    model[G.DATA].status = !isNum(statusData) ? 50 : statusData
  }

  // if the organisation has no type and/or parent, it means that the user has filled the requester
  // form, which has fewer fields
  !model[G.DATA].type && (data.type = 'customer')

  // it may have been set in the module state depending on whether we execute the confirm action
  // as an InstanceAdmin / MasterDataAdministrator
  module[G.STATE]?.verified && (data.parent = null)
  !validationError && setData(model, data)

  return [components, ...args]
}

/**
 * Sets the {@param module}'s state as error if any of the model's sub-models or the model itself
 * has error state.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const updateModuleState = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const validationError = model[G.STATE][G.ERROR]
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = !!validationError

  return args
}

/**
 * Organisation Action Submit
 *
 * @param {Gaia.Component.Spec} module current module
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  composeOrganisation(module)(),
  updateModuleState(module)(),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
