import modelMapDataSequence from 'lib/sequence/model/api/map'
import validateSequence from 'lib/sequence/model/validate'
import createFn from './create'
import readFn from './read'
import updateFn from './update'
import deleteFn from './delete'
import listFn from './list'

const api = obj => ({
  map: modelMapDataSequence(obj),
  validate: validateSequence(obj),
  create: createFn(obj),
  read: readFn(obj),
  update: updateFn(obj),
  delete: deleteFn(obj),
  list: listFn(obj),
})

export default api
