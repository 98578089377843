/* global G */
import { setKey } from 'lib/util'
import session from 'app/_shared/session'

/**
 *
 * @param module
 * @returns {function(): function(...[*]=): *}
 */
const profile = module => () => (...args) => setKey(
  session(module).user.key(),
  G.REF,
  module[G.MODEL][G.STATE],
) && args

export default profile
