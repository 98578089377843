export default [
  {
    key: 'none',
    value: 'No assignee',
    icon: 'remove',
  },
  {
    key: 'any',
    value: 'All assignees',
    icon: 'supervisor_account',
  },
  {
    key: 'me',
    value: 'Me',
    icon: 'face',
  },
]
