/* global G */
import { asyncPipeSpread, bulk, def, getFirstItem, isArr, setKey } from 'lib/util'
import { empty } from 'lib/util/object'
import find from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { get, set } from 'lib/sequence/component/state/value'
import { disable } from 'lib/sequence/component/state/disabled'
import { checked } from 'lib/sequence/component/state/checked'
import listStatus from 'app/_shared/events/collection/listStatus'
import roles from 'model/account/collection/roles'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Presets the users' status.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @return {function(*, *, ...[*]): Promise<*[]>}
 */
const presetStatus = module => async (components, userRole, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { status: statusField } = components
  const status = getFirstItem(listStatus(module, actionComponent, get(statusField)))

  status && set(statusField, status)
  return [components, ...args]
}

/**
 * Presets the users' login name.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @return {function(*, *, ...[*]): Promise<*[]>}
 */
const presetLoginName = module => async (components, userRole, ...args) => {
  const model = module[G.MODEL]
  const { username } = components

  // org.couch.db.user:john@doe.com -> john@doe.com
  set(username, model[G.STATE][G.REF].split(':')[1])
  disable(username)

  return [components, ...args]
}

/**
 * Presets the {@code passwordGenerated} field.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @returns {function(*, *, ...[*]): Promise<*[]>}
 */
const presetPasswordGenerated = module => async (components, userRole, ...args) => {
  // model
  const model = module[G.MODEL]
  const { version } = model[G.PROPS]
  const username = model[G.STATE][G.REF].split(':')[1]

  // component
  const { passwordGenerated } = components

  try {
    const url = `/api/v${version}/public/accountExists/${username}`
    const result = await model[G.ADAPTER][G.HTTP].get({ url })

    result?.passwordGenerated ? show(passwordGenerated) : hide(passwordGenerated)
  } catch (e) {
    console.error(e)
  }

  return [components, ...args]
}

/**
 * Determines whether the teams section should be shown.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @return {function(*, *, ...[*]): Promise<*[]>}
 */
const displayTeams = module => async (components, userRole, ...args) => {
  const { team, teamDivider, roles: rolesField } = components

  const rolesValue = get(rolesField)
  const currentRoles = isArr(rolesValue)
    ? rolesValue
    : Object.values(rolesValue)

  /**
   * Check all user roles to see if one of them has {@code hasTeams} set to {@code true}. This will
   * include roles the user already has when opening the drawer as well as newly added/customized
   * roles.
   */
  const hasTeamRoles = currentRoles.reduce(
    (acc, key) => roles.find(x => x.key === key.role)?.hasTeams || acc, false,
  )

  hasTeamRoles
    ? bulkShow(team, teamDivider)
    : bulkHide(team, teamDivider)

  return [components, ...args]
}

/**
 * Determines if the toBeValidated section should be shown.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayToBeValidated = module => async (components, ...args) => {
  // model
  const model = module[G.MODEL]
  const { person } = model[G.CHILDREN]
  const { toBeValidated: toBeValidatedModel } = person[G.CHILDREN]
  const toBeValidatedCache = toBeValidatedModel?.[G.CACHE] || null
  const personState = person[G.STATE]

  // component
  const { component, toBeValidated } = components
  const { verify } = find(component[G.ACTIONS][0])

  // Only showing toBeValidated section if it has content
  def(toBeValidatedCache) && !empty(toBeValidatedCache)
    ? bulkShow(toBeValidated, verify)
    : bulkHide(toBeValidated, verify)

  // Saving the checkbox value into the model state so that it can be picked up be the transformer
  setKey(checked(verify), 'verified', personState)

  return [components, ...args]
}

/**
 * Shows the password resend button.
 *
 * @param module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayPasswordReset = module => async (components, ...args) => {
  // component
  const { btnResendRecovery } = components

  show(btnResendRecovery)

  return [components, ...args]
}

/**
 * User Admin Action Edit
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @return {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  presetStatus(module),
  presetLoginName(module),
  presetPasswordGenerated(module),
  displayTeams(module),
  displayToBeValidated(module),
  displayPasswordReset(module),
)(find(component), ...args)
