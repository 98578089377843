/* global G */
import { set } from 'lib/sequence/component/state/value'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { setSessionStateData } from 'app/guest/action'

/**
 * Prepares the view by binding the corresponding model values.
 *
 * @returns {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]
  const username = args[0].username || sessionState.username
  const { passwordGenerated } = sessionState

  const {
    username: usernameLabel,
    btnResendRecovery,
    btnResendLink,
  } = sequenceComponentFindProxy(component)

  setSessionStateData(module, { username })
  set(usernameLabel, username)

  passwordGenerated
    ? show(btnResendLink) && hide(btnResendRecovery)
    : show(btnResendRecovery) && hide(btnResendLink)

  return args
}
