/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Instruct the cart adapter to set the incoming cart as the active one.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 * @returns {Promise<void>}
 */
const setCartAsActive = async (module, component, event) => {
  const { [G.EVENTS]: eventBus } = module[G.ADAPTER]

  const model = module[G.MODEL]
  const newCart = model[G.CACHE]

  const data = await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.CART, G.DONE), ({ detail }) => {
      const { [G.DATA]: currentCart } = detail
      resolve(currentCart)
    }, { once: true })

    eventBus.dispatch(eventBus.type(G.CART, G.ACTIVATE), { [G.DATA]: {
      newCart,
    } })
  })

  return data ?? null
}

export default curry(setCartAsActive)
