/* global G */
import { curry } from 'lib/util'
import { settings } from 'app/_shared/session'

/**
 * Is Product Event Handler
 *
 * This event handler is a predicate, and should be called by a {@code filter} event
 * handler executed by {@link Explorer}.
 *
 * Checks if the current node's ({@param eventOrOptions}) {@code nodeType} property is
 * equal {@code number} property of the curent {@code module[G.MODEL]}.
 *
 * The mapping between {@code number} and {@code nodeType} is specific in the tenants
 * {@code documentationTreeFilter.product} setting. If this setting is not defined,
 * it will just return {@code true}, skipping the node.
 *
 * Additionally, if the node at hand fulfills the check, it will set the {@code initialNode}
 * to {@code true} on the node, marking it as the node to be expanded once the {@link Tree}
 * component renders. There should only be one {@code initialNode}, therefore it
 * resets the property (if set) on every node it encounters (before checking).
 *
 * @param {Object[]} productGroups  the group of products to check against
 * @param model
 * @returns {boolean}
 */
const isProduct = (productGroups, model = null) => (module, component, eventOrOptions) => {
  const node = eventOrOptions || {}
  const productNumber = model
    ? module[G.MODEL][G.CHILDREN]?.[model]?.[G.CHILDREN]?.number?.[G.CACHE]
    : module[G.MODEL][G.CHILDREN]?.[G.CHILDREN]?.number?.[G.CACHE]
  const targetGroup = productGroups.find(group => group.number === productNumber)

  // In case this node has been mark as the initialNode by another predicate, let's
  // reset it here. Only the last predicate's decision on this matter should count.
  node?.initialNode && (node.initialNode = undefined)

  // If this node is not of the type we want to check, let's ignore it (leave it in
  // the tree)
  if (node?.nodeType !== targetGroup?.nodeType) return true

  const isMatch = node?.nodeType === targetGroup.nodeType && node?.name === targetGroup?.name

  // Mark this node as the initial node if it matches.
  isMatch && (node.initialNode = true)

  return isMatch
}

/**
 * Higher order function that determines the applicable filters from the tenant config. If we
 * curry the whole thing, like {@code export default curry(m, c, e)}, we need to look into
 * the tenant settings for each node we call this filter with later. That's unnecessary.
 * We only need to have this information once for all nodes.
 *
 * This function will return a curried function with the relevant filters already present.
 *
 * @param {string} [model] sub-model to use, if falsy, the root model will be used
 *
 * @returns {(function(...[*]): (*))|*}
 */
export default (model) => {
  const productGroups = settings?.documentationTreeFilter?.product

  if (!productGroups) {
    console.warn(
      // eslint-disable-next-line no-multi-str
      'Documentation tree product type specified in business logic, \
         but not present in tenant settings. Will skip.',
    )

    // If the filter is not present in the tenant config, just return a noop function
    // that returns {@code true}
    return () => true
  }

  return curry(isProduct(productGroups, model))
}
