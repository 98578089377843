/* global G */
import { curry } from 'lib/util'
import validate from 'lib/sequence/model/validate'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { get } from 'lib/sequence/component/state/value'
import sequenceComponentFind from 'lib/sequence/component/children/find'

/**
 * Validate Redirect Event Handler
 *
 * It executes module action validateLogin()
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - event
 * @return {Promise<void>} void
 */
/**
 * Validate Account Event Handler
 *
 * Validates the account field of the user section
 * @type {Gaia.AppModule.EventHandler}.
 */
export default curry(async (module, component, event) => {
  const { route } = component[G.CONFIGURATION]
  const model = module[G.MODEL]
  const moduleState = module[G.STATE]
  const { form, username, password } = sequenceComponentFind(module[G.STATE][G.ACTION][G.COMPONENT])
  const passwordHidden = hidden(password)

  hide(password)
  await validate(model)(form)
  !passwordHidden && show(password)

  // Validating the form will hit the accountExists validator, which will request the server to
  // return whether the user has a password. So we can only do this after validating.
  const { passwordGenerated } = model[G.STATE]
  const modelError = model[G.STATE][G.ERROR]
  // In this case, we don't want to consider 404 an errors
  const notFoundError = modelError?.[G.REF]?.username === 404

  if (!modelError || notFoundError) {
    moduleState[G.ERROR] = false
    moduleState[G.ROUTE] = routeComposition(route.module && route.module, route.action)

    await redirectSequence(module)({
      username: get(username),
      error: modelError,
      passwordGenerated,
    })
  } else {
    module[G.ADAPTER][G.UI].update(module)
  }
})
