/* eslint-disable no-unused-vars,no-param-reassign */
/* global G, PLATFORM */
import { curry } from 'lib/util'
import session, { settings } from 'app/_shared/session'
import { redirect } from 'app/_shared/events'
import openExternalPortal from 'app/_shared/events/redirect/openExternalPortal'

/**
 * Shows a dialog informing the user that the requested operation has to be done elsewhere.
 *
 * @param {Gaia.AppModule.Spec} module
 * @return {Promise<void>}
 */
const showExternalPortalDialog = async (module) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]
  const intl = module[G.ADAPTER][G.INTL]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    width: 'sm',
    title: await intl._t(
      'title.user.change',
      {
        ns: 'user',
        _key: 'title.user.change',
        defaultValue: 'Change password',
      },
    ),
    text: await intl._t(
      'guest.text.externalPortal',
      {
        ns: 'custom',
        _key: 'guest.text.externalPortal',
        defaultValue: '',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: await intl._t(
          'guest.button.externalPortal',
          {
            ns: 'custom',
            _key: 'guest.button.externalPortal',
            defaultValue: 'Ok',
          },
        ),
      },
      cancel: {
        key: 'cancel',
        value: await intl._t(
          'button.cancel',
          {
            ns: 'common',
            _key: 'button.cancel',
            defaultValue: 'Cancel',
          },
        ),
      },
    },
    okHandler: async () => await openExternalPortal(module, null, null),
  })
}

/**
 * Redirect Local User Event Handler
 *
 * Redirects to the component's default route or, if the last validated account has the
 * remoteAuthentication flag and the externalPortalUrl setting is set, displays a dialog informing
 * the user that the requested operation has to be done elsewhere.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
const redirectLocalUser = async (module, component, event) => {
  const user = session(module).user.ref()

  const { remoteAuthentication } = user.value

  if (remoteAuthentication && settings.externalPortalUrl) {
    await showExternalPortalDialog(module)
  } else {
    await redirect(module, component, event)
  }
}

export default curry(redirectLocalUser)
