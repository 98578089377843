/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Instructs the Cart adapter to change a singular article from the current cart.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 * @returns {Promise<void>}
 */
const changeCartPosition = async (module, component, event) => {
  const { [G.EVENTS]: eventBus } = module[G.ADAPTER]

  const { position = {} } = event?.detail || event || {}
  const { key, name, ...data } = position

  const newCart = await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.CART, G.DONE), ({ detail }) => {
      const { [G.DATA]: currentCart } = detail
      resolve(currentCart)
    }, { once: true })

    eventBus.dispatch(eventBus.type(G.CART, G.UPDATE), { [G.DATA]: {
      ...key
        ? { key, data }
        : { name, data },
    } })
  })

  if (newCart) {
    await actionWithHooks(module[G.STATE][G.ACTION])([])
  }
}

export default curry(changeCartPosition)
