/* global G */
import { modelComposition } from '@gaia/trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import withGetterSetter from 'trait/with/GetterSetter'
import dataToExistingRef from 'model/_shared/transformer/dataToExistingRef'
import toProvides from 'model/organisation/transformer/toProvides'

const descriptor = 'model::organisation::support'

const Model = pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.TRANSFORMER, {
    provides: toProvides('provides', 'countries'),
    countries: dataToExistingRef('countries'),
  }),
  withGetterSetter(G.DECORATOR),
)

export default Model
