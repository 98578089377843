/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import cancelOnError from 'lib/sequence/model/cancelOnError';
import { apply as applyAttachments, get as getAttachments } from 'app/_shared/events/attachment'
import { setKey } from 'lib/util';
import redirect from 'lib/hook/router/redirect';

const descriptor = 'module::request::hook::submitInitialAttachmentMessages'

/**
 * Submits attachments as messages if there are any and then
 * redirects to the target action. We can't use the redirect
 * hook in {@code submitHooks.after}, because we have to
 * wait for the attachments to be uploaded. Therefore
 * run the redirect in the callback directly.
 *
 * @param obj
 * @returns {function(...[*]): Promise<*[]>}
 */
const submitInitialAttachmentMessageAndRedirect = obj => async (...args) => {
  const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]
  cancelOnError(descriptor, obj)

  const model = obj[G.MODEL]
  const { api, version } = model[G.PROPS]
  const requestId = model[G.STATE][G.REF]
  const attachments = await getAttachments(obj, actionComponent, null)
  const attachmentsCount = attachments.length

  if (attachmentsCount === 0) {
    /**
     * If we don't have any attachments, just redirect.
     */
    await redirect(obj)(...args)
  } else {
    /**
     * If we have attachments, we need to attach them to the
     * birthMessage. So we need its ID, in order to get it,
     * let's get all messages for request (will only be 2),
     * and filter for type === 'birth_message'. Then
     * apply the attachments to it.
     *
     * @Note: We can't use ?limit=1&descending=true/false,
     * because both reply- and birthMessage have excatly
     * the same timestamp, the returned message could
     * be both!
     */
    const messageUrl = `/api/v${version}/${api}/${requestId}/listing/message`
    let existingMessages
    try {
      existingMessages = (
        await model[G.ADAPTER][G.HTTP].get({ url: messageUrl })
      ).value
    } catch (e) {
      console.error(e)
    }

    const birthMessage = existingMessages.find(message => message.value.type === 'birth_message')
    setKey(birthMessage.key, G.REF, actionComponent[G.STATE])
    applyAttachments(obj, actionComponent, null)
    await redirect(obj)(...args)
  }

  return args
}

export default submitInitialAttachmentMessageAndRedirect
