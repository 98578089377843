/* eslint-disable no-param-reassign,no-unused-vars */
/* global G */
import ui from 'lib/hook/ui/update'
import search from 'app/_shared/hook/appbar/search'
import create from 'app/_shared/hook/appbar/create'
import clear from 'app/_shared/hook/model/clear'
import reset from 'app/_shared/hook/component/reset'
import read from 'lib/hook/model/read'
import confirm from 'lib/hook/router/confirm'
import check from 'app/_shared/hook/check'
import bulk from 'lib/hook/model/bulk'
import modelReset from 'lib/hook/model/reset'
import validate from 'lib/hook/model/validate'
import submit from 'lib/hook/model/submit'
import { confirmation, end } from 'lib/hook/transaction'
import back from 'app/_shared/hook/redirect/back'
import recall from 'app/_shared/hook/component/recall'
import updateCache from 'app/_shared/hook/model/updateCache'

const _ticketListHooks = {
  before: [
    search,
    recall,
  ],
  after: [
    ui,
  ],
}

const _listHooks = {
  before: [
    search,
    create,
    recall,
  ],
  after: [
    ui,
  ],
}

const _invitationHooks = {
  before: [],
  after: [
    search,
    ui,
  ],
}

const _editHooks = {
  before: [
    clear,
    reset,
    read,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _bulkHooks = {
  after: [
    ui,
    check,
    bulk,
    back,
  ],
}

const _createHooks = {
  before: [
    modelReset,
    reset,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _submitHooks = {
  before: [
    validate,
  ],
  after: [
    ui,
    check,
    submit,
    updateCache,
    // confirmation('success'),
    back,
    end,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    tickets: _ticketListHooks,
    requests: _ticketListHooks,
    users: _listHooks,
    usersNew: _listHooks,
    userEdit: _editHooks,
    userBulk: _bulkHooks,
    userCreate: _createHooks,
    invitations: _invitationHooks,
    teams: _listHooks,
    teamEdit: _editHooks,
    teamCreate: _createHooks,
    teamSubmit: _submitHooks,
  },
}

export default hooks
