/* global G */
import { curry } from '@gaia/util'
import persistOptions from 'app/_shared/events/persist/options'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Event Handler Click and Action
 *
 * It converts value into an object structure, expected by the persistOptions() event handler
 * ie, it creates an object { key: * }
 * It executes persistOptions event handler,
 * followed by module action sequence actionWithHooks()
 *
 * It used in cases, where you want to reapply changes to UI, based on provided value
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @param {object} event                information about the event that triggered this handler
 * @param {object} [event.detail]       custom event's properties
 * @param {string} [event.detail.key]   model ref to set
 * @return {Promise<void>} void
 */
const valueAndAction = async (module, component, event) => {
  try {
    const { key } = event.detail
    const value = { key }
    persistOptions(module, component, event)
    // call same action, see side effects
    await actionWithHooks(module[G.STATE][G.ACTION])(value)
  } catch (e) {
    console.error(e)
  }
}

export default curry(valueAndAction)
