/* global G */
import { curry } from 'lib/util'
import { _url } from 'app/_shared/events/link/index'

/**
 * Link With Ref Event Handler
 *
 * Works similar to {@link link}, but creates a link to the specific {@code ref}.
 *
 * @param {string} ref                  ref to link to
 * @param {Gaia.AppModule.Spec} module  app module
 * @param {Gaia.Component.Spec} component  action component
 * @param {Gaia.PlatformEvent} event    event
 */
const linkWithRef = (ref, module, component, event) => {
  const state = component[G.STATE]

  const url = _url(module, component, event)

  const key = state?.refs?.[ref]?.[0]?.key
        || state?.refs?.[ref]?.key
        || state?.item?.refs?.[ref]?.[0]?.key
        || state?.item?.refs?.[ref]?.key

  return key
    ? `${url}/${key}`
    : null
}

export default curry(linkWithRef)
