/* global G */
import { curry } from 'lib/util'
import routeComposition from '@gaia/trait/composition/route'

/**
 * Attempts to open an element in a new tab. For that, the target's configuration should
 * contain a route and its key should be passed inside the event's detail.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - event
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const newTab = async (module, component, event) => {
  try {
    const { key } = event.detail
    const { route } = component[G.CONFIGURATION]
    module[G.ADAPTER][G.ROUTER].newTab(routeComposition(route.module, route.action, key))
  } catch (e) {
    console.error(e)
  }
}
export default curry(newTab)
