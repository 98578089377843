import hasSingularChild from 'app/_shared/events/file/node/predicates/hasSingularChild'

/**
 * Traverses the tree, starting at {@param node}, for as long as {@param predicate}
 * returns a truthy value for the current node. If it returns a falsy value, the
 * {@param node} will be returned.
 *
 * @param {Object} node         node to start traversal from
 * @param {Function} predicate  function to check if node is skippable
 * @returns {*|{$children}}
 */
const getDeepestNode = ({ node, predicate = hasSingularChild }) => {
  const nextNode = predicate(node)

  if (nextNode) {
    return getDeepestNode({ node: nextNode, predicate })
  }

  return node
}

export default getDeepestNode
