/* eslint-disable no-param-reassign */
/* global G */

/**
 * Clears all enumerable properties from {@param object}.
 * @param {object} object
 */
const clear = object => Object.keys(object).reduce((acc, key) => {
  delete acc[key]
  return acc
}, object)

/**
 * Clears the state of {@param component} and calls itself for each one of its children.
 * @param {Gaia.Component.Spec} component
 * @return {Gaia.Component.Spec}
 */
const reset = (component) => {
  clear(component[G.STATE])
  component[G.CHILDREN]?.forEach(reset)
  return component
}

export default reset
