/* global G */
const fn = obj => async (uiState, key) => {
  const re = /^-?[0-9]+$/
  const { options } = obj[G.CONFIGURATION].validator[key]
  const { error } = options

  // eslint-disable-next-line no-unused-expressions
  re.test(uiState.value) || throw TypeError(error)

  // otherwise, field is valid
  return [uiState, key]
}

export default fn
