/* eslint-disable no-unused-expressions,object-curly-newline,arrow-body-style */
/* global G */
import map from 'lib/sequence/model/api/map'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { get, set } from 'lib/sequence/component/state/value'
import { asyncPipeSpread, asyncPipeSpreadIf, getFirstItem, setKey } from 'lib/util'
import session, { settings } from 'app/_shared/session'
import formatValue from 'app/_shared/component/formatValue'
import { empty } from 'lib/util/object'
import { getReadableDate } from 'lib/util/date'
import setAsRead from 'app/_shared/events/message/setAsRead'
import find from 'lib/sequence/component/children/find'
import internal from 'model/organisation/collection/internal'

/**
 * Determines if the ticket is closed ({@code status === 80}), but can be reopened by the user.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {(function(): (boolean|undefined))|*}
 */
const _isClosedButCanReopen = module => () => {
  const model = module[G.MODEL]
  const { acl: modelAcl } = model[G.CACHE]
  const status = getFirstItem(model[G.CACHE])?.value?.status || -1

  try {
    module[G.ADAPTER][G.ACL].model(modelAcl, [30])

    return status === 80
  } catch (e) {
    return false
  }
}

/**
 * Sets the G.UNDO (dirty) flag for the detail action.
 *
 * As we only have a single dirtiness flag (G.UNDO) for each action, but we have to consider the
 * dirtiness of both notes & the message field in the ticket detail action, this function sets an
 * object with a property ({@param namespace}) for each one of those states that is dirty. Non-dirty
 * {@param namespace}s are removed from the object and, if there are no properties left, the
 * (G.UNDO) flag is finally set to null.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @param {string} namespace            the name of a state to keep track of
 * @param {boolean} dirty               whether {@param namespace} is in dirty state
 */
export const setDetailUndoKey = (module, namespace, dirty = true) => {
  const detailAction = module[G.ACTIONS].detail
  const detailState = detailAction[G.STATE]

  if (dirty) {
    detailState[G.UNDO] = { [namespace]: true, ...detailState[G.UNDO] }
  } else if (detailState[G.UNDO]) {
    delete detailState[G.UNDO][namespace]
    empty(detailState[G.UNDO]) && (detailState[G.UNDO] = null)
  }
}

/**
 * Returns {@param true} if the ticket has status 50 and the user is the ticket's assignee.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {boolean}
 */
const canEditTicket = (module) => {
  const model = module[G.MODEL]
  const { status, assignee } = model[G.CHILDREN]

  return (status[G.CACHE]?.[0]?.key === 50 || status[G.CACHE] === 50)
      && assignee[G.CACHE]?.[0]?.key === session(module).user.key()
}

/**
 * Returns {@code true} if the current user has any of the roles defined in the {@param component}'s
 * aclContextRole configuration.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @param {Gaia.Component} component    a component whose aclContextRole configuration is to be
 *                                      taken into account
 * @returns {boolean}
 */
const hasRequiredRole = (module, component) => {
  const { aclContextRole = [] } = component[G.CONFIGURATION]
  const { roles = [] } = session(module).context()
  return roles.some(value => aclContextRole.includes(value))
}

/**
 * Toggles the ticket's edit mode.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): *[]}
 */
const toggleEditMode = module => (components, ...args) => {
  const { editBtn, messageField } = components
  const canEdit = canEditTicket(module)

  canEdit || hasRequiredRole(module, editBtn)
    ? show(editBtn)
    : hide(editBtn)

  canEdit
    ? show(messageField)
    : hide(messageField)

  return [components, ...args]
}

/**
 * Initializes the notes pane by setting the user id as its current value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const initNotes = module => async (components, ...args) => {
  const { note } = components
  const userId = session(module).user.key()
  !get(note) && set(note, { userId })
  return [components, ...args]
}

/**
 * Initializes the message field according to the current ticket's state and toggles its display
 * depending on whether the ticket has a related request.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const initMessageField = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { type, status, request } = model[G.CHILDREN]
  const { messageField } = components
  setKey(status[G.CACHE]?.[0]?.key, 'currentStatus', messageField[G.STATE])
  setKey(type[G.CACHE], 'currentType', messageField[G.STATE])
  // Setting the message text in case there is one in the module state.
  module[G.STATE].text && set(messageField, { text: module[G.STATE].text })
  // There is no accompanying request for this ticket, so let's not show message field at all
  !request[G.CACHE] && hide(messageField)
  return [components, ...args]
}

/**
 * Initializes the messages pane by setting the user and ticket ids as its current value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const initMessages = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { message } = components
  const sourceId = model[G.STATE][G.REF]
  const userId = session(module).user.key()
  !get(message) && set(message, { sourceId, userId })
  return [components, ...args]
}

/**
 * Maps the ticket's description to the view.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapDescription = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { descriptionBox } = components
  map(model)(descriptionBox)
  return [components, ...args]
}

/**
 * Maps the ticket's additional party to the view and toggles the display of its information labels.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapAdditionalParty = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact, additionalParty, additionalPartyData } = model[G.CHILDREN]
  const { additionalPartyBox, additionalPartyInfo } = components
  const { waitingForApproval, notInMasterData, blocked } = find(additionalPartyBox)
  const {
    waitingForApproval: pendingValidation,
    blocked: isBlocked,
  } = requesterContact[G.STATE][G.META]

  additionalPartyData[G.CACHE]
    ? show(additionalPartyBox)
      && map(model)(additionalPartyInfo)
    : hide(additionalPartyBox)

  !pendingValidation && !isBlocked && !additionalParty[G.STATE][G.REF]
    ? show(notInMasterData)
    : hide(notInMasterData)

  pendingValidation && !isBlocked
    ? show(waitingForApproval)
    : hide(waitingForApproval)

  isBlocked
    ? show(blocked)
    : hide(blocked)

  return [components, ...args]
}

/**
 * Maps the ticket's item to the view and toggles the display its information labels.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapItem = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { item, itemData, equipment } = model[G.CHILDREN]
  const { itemBox, itemInfo } = components
  const { serial, name, noEquipment, notRelated, notInMasterData } = find(itemBox)

  const itemRef = item[G.STATE][G.REF]
  const equipmentRef = equipment[G.STATE][G.REF]

  itemData[G.CACHE]
    ? show(itemBox)
      && map(model)(itemInfo)
    : hide(itemBox)

  const serialValue = get(serial)
  const nameValue = get(name)
  settings.swapDeviceSerialAndName
    && set(name, serialValue)
    && set(serial, nameValue)

  !itemRef && !equipmentRef
    ? show(notInMasterData)
    : hide(notInMasterData)

  itemRef && !equipmentRef
    ? show(noEquipment)
    : hide(noEquipment)

  !itemRef && equipmentRef
    ? show(notRelated)
    : hide(notRelated)

  return [components, ...args]
}

/**
 * Toggles and formats the display of the preferred channel by replacing the prefChannelName
 * wildcard with the actual value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const initPrefChannel = module => async (components, ...args) => {
  const { prefChannelName: prefChannelField } = components
  const prefChannelName = get(prefChannelField)
  prefChannelName
    ? await formatValue(module, prefChannelField, { prefChannelName }) && show(prefChannelField)
    : hide(prefChannelField)
  return [components, ...args]
}

/**
 * Maps the ticket's requester to the view and toggles the display its information labels.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapRequester = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg, requesterContact } = model[G.CHILDREN]
  const { requesterInfo, requesterContactData } = components
  const {
    waitingForApproval,
    pendingInvitation,
    notInMasterData,
    blocked,
  } = find(requesterContactData)

  const pendingInvite = requesterContact[G.CACHE]?.[0]?.value?.invite
  const { pendingValidation, isBlocked } = requesterContact[G.STATE][G.META]

  map(model)(requesterInfo)
  map(model)(requesterContactData)

  !pendingValidation
    && !pendingInvite
    && !isBlocked
    && (!requesterOrg[G.STATE][G.REF] || !requesterContact[G.STATE][G.REF])
    ? show(notInMasterData)
    : hide(notInMasterData)

  pendingValidation && !pendingInvite && !isBlocked
    ? show(waitingForApproval)
    : hide(waitingForApproval)

  pendingInvite && !isBlocked
    ? show(pendingInvitation)
    : hide(pendingInvitation)

  isBlocked
    ? show(blocked)
    : hide(blocked)

  return [components, ...args]
}

/**
 * If the model's requesterContact doesn't have a ref, they may still have to be validated. In that
 * case, this function sends a request to obtain the current state of requesterContact from the
 * server and stores inside the model's state whether its validation is still pending.
 *
 * This function also checks requesterOrg and requesterContactOrg. If any of them have
 * 'ORGANISATION:BA' as key, then requesterContact is considered to be blocked.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<{ pendingValidation: boolean, isBlocked: boolean }>}
 */
export const checkSubmitterValidity = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg, requesterContact, requesterContactOrg, submitter } = model[G.CHILDREN]
  const { version } = model[G.PROPS]
  const validity = {}

  if (!requesterContact[G.STATE][G.REF]) {
    try {
      const [userId] = submitter[G.CACHE]
      const [, userName] = userId.split(':')
      const url = `/api/v${version}/validation/pending/${userName}`
      const options = { middleware: ({ persistence }) => [persistence] }
      const { waitingForApproval } = await model[G.ADAPTER][G.HTTP].get({ url }, options)
      validity.pendingValidation = waitingForApproval
    } catch (e) {
      console.error(e)
    }
  }

  validity.isBlocked = internal.some((organisation) => {
    return organisation.key === requesterContactOrg[G.STATE][G.REF]
      || organisation.key === requesterOrg[G.STATE][G.REF]
  })

  setKey(validity, G.META, requesterContact[G.STATE])

  return [components, ...args]
}

/**
 * Maps the ticket's assignee to the view.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapAssignee = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { assigneeInfo, assignee } = components
  map(model)(assigneeInfo)
  map(model)(assignee)
  return [components, ...args]
}

/**
 * Maps the ticket's status to the view. It also makes the postpone date readable and hides the
 * status reason and postpone date labels if they don't have any value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapStatus = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { statusBox, statusInfo, statusReason, postponeDate } = components

  map(model)(statusBox)
  map(model)(statusInfo)

  get(statusReason)
    ? show(statusReason)
    : hide(statusReason)

  get(postponeDate)
    ? show(postponeDate)
    : hide(postponeDate)

  const readablePostponeDate = getReadableDate(get(postponeDate), false, true)
  setKey({ postponeDate: readablePostponeDate }, 'token', postponeDate[G.STATE])

  return [components, ...args]
}

/**
 * Formats the string that displays the ticket's submit timestamp by replacing its timestamp
 * wildcard with the model value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const formatSubmitTimestamp = module => async (components, ...args) => {
  const { timestamp } = components
  // replacing prop value tokens with actual status value
  await formatValue(module, timestamp, { timestamp: get(timestamp) })
  return [components, ...args]
}

/**
 * Maps the ticket's type to the view.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapType = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { typeInfo } = components
  map(model)(typeInfo)
  return [components, ...args]
}

/**
 * Clears the BULK flag from the model state, currently used by the edit action to initialize the
 * wizard with the ticket's data.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(...[*]): Promise<*[]>}
 */
const clearEditFlag = module => async (...args) => {
  setKey(false, G.BULK, module[G.MODEL][G.STATE])
  return args
}

/**
 * Sets the tickets messages as read if the ticket is closed but can be reopened by the current
 * user. It will not actually reopen the ticket.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const setMessagesAsRead = module => component => async (components, ...args) => {
  await setAsRead(module, component, null)

  return [components, ...args]
}

/**
 * Ticket detail action.
 *
 * Maps the model to the view and prepares its components to be shown according to some values.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => (...args) => asyncPipeSpread(
  clearEditFlag(module),
  mapType(module),
  formatSubmitTimestamp(module),
  mapStatus(module),
  mapAssignee(module),
  checkSubmitterValidity(module),
  asyncPipeSpreadIf(_isClosedButCanReopen(module))(
    setMessagesAsRead(module)(component),
  ),
  mapRequester(module),
  initPrefChannel(module),
  mapItem(module),
  mapAdditionalParty(module),
  mapDescription(module),
  initMessages(module),
  initMessageField(module),
  initNotes(module),
  toggleEditMode(module),
)(find(component), ...args)
