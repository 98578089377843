/* global G */
import { curry } from 'lib/util'
import searchWith from 'app/_shared/events/search/with'
import { infiniteMixed } from 'app/_shared/events/search/mixed'

/**
 * Get Favorites With Filter Event Handler
 *
 * Searches for favorite documents whose IDs are listed in the local favorites.
 * Applies a filter and optionally a search term to each search depending on
 * what type of favorites are wanted.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<{data: (*[]|*)}>}
 */
const getFavoritesWithFilter = async (module, component, event) => {
  const { value: term } = module[G.STATE][G.ACTION][G.COMPONENT][G.STATE]
  const { favorites } = module[G.ADAPTER][G.SESSION][G.STATE][G.SETTINGS]
  const favoriteIds = favorites.map(favorite => favorite.key)

  const { type } = component[G.EVENTS].filter(module, component, event)

  const hasTerm = term?.length > 0 || false

  const withEvent = { ...event, detail: { ids: favoriteIds, type: !hasTerm ? type : undefined } }
  const { query } = await searchWith(['query'], module, component, withEvent)

  const searchEvent = { ...event,
    detail: {
      query,
      ...hasTerm && {
        term,
        filter: { type },
      },
    } }
  const result = await infiniteMixed(module, component, searchEvent)

  return {
    ...result,
    data: !result?.data ? [] : result.data.map((document) => {
      const foundFavorite = favorites.find(favorite => favorite.key === document.key)
      return {
        ...document,
        value: {
          ...document.value,
          documentType: foundFavorite.type,
          description: foundFavorite.description,
        },
      }
    }),
  }
}

export default curry(getFavoritesWithFilter)
