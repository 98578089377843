/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import redirectSequence from '@gaia/sequence/module/adapter/router/redirect'
import routeComposition from 'trait/composition/route'

/**
 * Get Support
 *
 * Event Handler, forcing redirect to preconfigured route.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 */
// eslint-disable-next-line no-unused-vars
const getSupport = async (module, component, event) => {
  const moduleState = module[G.STATE]

  // todo: devise mechanism for auto casting routes during configuration/content provision
  // we convert configured route to required structure
  const { route } = component[G.CONFIGURATION]

  moduleState[G.ROUTE] = routeComposition(route.module, route.action)

  // we don't want to cancel redirect, even if current module has errors
  moduleState[G.ERROR] = null

  const { username } = asObject(module[G.STATE][G.ACTION][G.COMPONENT][G.CHILDREN])
  const { value } = username[G.STATE]
  const data = { username: value }
  redirectSequence(module)(data)
}

export default curry(getSupport)
