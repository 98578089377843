import { persistChange } from '@app/_shared/events'
import generate from './event/generate'
import fill from './event/fill'
import modify from './event/modify'

export default {
  onClick: {
    generate,
    fill,
    modify,
  },
  onChange: {
    persistChange,
  },
}
