/* global G */
import resetComponent from 'app/_shared/component/reset'
import { deleteKey } from 'lib/util'

/**
 * Reset Component Hook
 *
 * Clears the state of the current action's component and that of all its children, recursively.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): *[]}
 */
const reset = obj => (...args) => {
  const actionState = obj[G.STATE][G.ACTION][G.STATE]
  const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]

  deleteKey(G.UNDO, actionState)
  resetComponent(actionComponent)

  return args
}

export default reset
