import notEmptyAttribute from '@model/_shared/validator/notEmptyAttribute'
import userExists from '@model/_shared/validator/userExists'

export const validators = {
  firstName: notEmptyAttribute,
  lastName: notEmptyAttribute,
  type: notEmptyAttribute,
  organisation: notEmptyAttribute,
  statusReason: notEmptyAttribute,
  postponeDate: notEmptyAttribute,
  spentTime: notEmptyAttribute,
  startDate: notEmptyAttribute,
  note: notEmptyAttribute,
  team: notEmptyAttribute,
  account: userExists,
}

export {
  validators as default,
  notEmptyAttribute,
  userExists,
}
