/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

/**
 * Instructs the Cart adapter to remove all articles from the current cart.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 * @returns {Promise<void>}
 */
const removePosition = async (module, component, event) => {
  const { [G.EVENTS]: eventBus } = module[G.ADAPTER]

  const { list } = sequenceComponentFindProxy(module[G.STATE][G.ACTION][G.COMPONENT])

  const data = await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.CART, G.DONE), ({ detail }) => {
      const { [G.DATA]: currentCart } = detail
      resolve(currentCart)
    }, { once: true })

    eventBus.dispatch(eventBus.type(G.CART, G.REMOVE), { [G.DATA]: {
      all: true,
    } })
  })

  if (data) {
    list[G.STATE].reload = {}

    await actionWithHooks(module[G.STATE][G.ACTION])([])
  }
}

export default curry(removePosition)
