/**
 * Attachment filter collection.
 *
 * Contains a static list of available attachment filter criteria to use throughout the application.
 */
export default [
  {
    key: 'size',
    value: 'Maximum attachment size: {{size}}',
  },
  {
    key: 'groupSize',
    value: 'Maximum combined attachment size: {{groupSize}}',
  },
  {
    key: 'count',
    value: 'Maximum number of attachments: {{count}}',
  },
  {
    key: 'types',
    value: 'Attachment types: {{count}}',
  },
]
