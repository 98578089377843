/* eslint-disable max-len,default-param-last */
/* global G */
import { curry, setKey } from 'lib/util'
import searchWith from 'app/_shared/events/search/with'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Event Handler Search With and Recreate
 *
 * Calls the defined {@code properties}' event handlers, passing through the given search term
 * and sets the lists' {@code G.META} object ot its result.
 *
 * Recreates the view afterwards. This behaviour is similar to {@link searchAndRecreate}. Other
 * parts of the chain that may alter additional search properties (filter, query, ...) will be
 * disregarded by this. If they shouldn't, use {@link searchWithAndAction}.
 *
 * @param {Boolean} recreate            whether or not to recreate the current view or execute the
 *                                      given action again
 * @param {String[]} properties         list of event handler names to execute and add to the search
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 *
 * @return {Promise<void>}
 */
const searchWithAndRecreate = async (recreate, properties, module, component, event) => {
  try {
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

    /**
     * Get every component from our actionComponent's children that either
     * - has a {@code key} of {@code list} (default case)
     * - or a property {@code list = true} and is not hidden
     * This makes searching work with multiple lists on the same level
     * (and therefore with non-unique keys) due to acl config
     */
    const lists = actionComponent[G.CHILDREN].filter(
      list => list._name === 'list'
        || (!list[G.STATE].hidden && list[G.PROPS].list),
    )
    const { term } = event.detail

    const transformedEvent = { ...event, detail: { ...event.detail, value: event.detail.term } }
    const additionalProperties = await searchWith(properties, module, component, transformedEvent)

    lists.forEach((list) => {
      /**
       * This case differs from the usual {@link searchAndRecreate} because we are not mutating the
       * {@code term} directly, but rather applying different properties (filter, query, ...) to the
       * search. However, we can't be sure if this is the only place where we apply the properties
       * from. Maybe we have different list filters that need to refresh the list as well (with the
       * given search term!).
       * To facilitate that, we'll save the current search term in the list state as well, so that
       * whoever needs to apply additional filters can pick it up and add it.
       */
      !recreate && setKey(term, 'term', list[G.STATE])
      setKey({ ...additionalProperties }, G.META, list[G.STATE])
      setKey(undefined, G.NEXT, list[G.STATE])

      term
        ? setKey(true, 'fromSearch', list[G.PROPS])
        : setKey(false, 'fromSearch', list[G.PROPS])
    })

    recreate
    // temporary solution for suspended memoized list component
    // recreate ui, instead of update ui
      ? await module[G.ADAPTER][G.UI].create(module)
      : await actionWithHooks(module[G.STATE][G.ACTION])([])
  } catch (e) {
    console.error(e)
  }
}

/**
 * Event Handler Search With and Action
 *
 * Calls the defined {@code properties}' event handlers, passing through the given search term
 * and sets the lists' {@code G.META} object ot its result.
 *
 * Executes the current action afterwards. Does not recreate the view. This should be used if the
 * additional search properties (filter, query, ...) may be set by the action logic or an external
 * event handler. Otherwise, use {@link searchWithAndRecreate}.
 *
 * @type {(function(...[*]): (*))|*}
 */
export const searchWithAndAction = curry((
  properties, module, component, event,
) => searchWithAndRecreate(false, properties, module, component, event))

export default curry((
  properties, module, component, event,
) => searchWithAndRecreate(true, properties, module, component, event))
