/* global G */
import { pipe } from 'lib/util'
import { modelComposition } from '@gaia/trait/composition'
import { usesGetterSetter } from '@gaia/trait/uses'
import concat from 'model/_shared/transformer/concat'
import withGetterSetter from 'trait/with/GetterSetter'
import toContactChannels from 'model/_shared/transformer/toContactChannels'
import fromContactChannels from 'model/_shared/decorator/fromContactChannels'
import fromSingleRef from 'model/_shared/decorator/fromSingleRef'
import toRef from 'model/_shared/transformer/toRef'
import concatIfEmpty from 'model/_shared/decorator/concatIfEmpty'
import toFirstKey from 'model/_shared/transformer/toFirstKey'
import drop from 'model/_shared/transformer/drop'
import fromCollectionValue from 'model/_shared/decorator/fromCollectionValue'
import toStatus from 'model/_shared/transformer/toStatus'
import toToBeValidated from 'model/_shared/transformer/toToBeValidated'
import languages from './collection/language'

const descriptor = 'model::person'

const tLanguage = { _key: 'language', ns: 'generic' }

export default pipe(
  modelComposition(descriptor),
  usesGetterSetter(G.TRANSFORMER, {
    name: concat('name', ['firstName', 'lastName']),
    lang: toFirstKey('lang'),
    langName: drop('langName'),
    contactChannels: toContactChannels('contactChannels'),
    organisation: toRef('organisation'),
    status: toStatus('status'),
    duplicateOf: toRef('duplicateOf'),
    toBeValidated: toToBeValidated('toBeValidated'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    name: concatIfEmpty('name', ['firstName', 'lastName']),
    langName: fromCollectionValue('lang', 'langName', languages, tLanguage),
    contactChannels: fromContactChannels('contactChannels'),
    organisation: fromSingleRef('organisation', 'organisation'),
  }),
)
