import notEmptyAttribute from '@model/_shared/validator/notEmptyAttribute'
import isInteger from '@model/_shared/validator/isInteger';

export const validators = {
  name: notEmptyAttribute,
  text: notEmptyAttribute,
  status: notEmptyAttribute,
  count: isInteger,
  messageType: notEmptyAttribute,
}

export {
  validators as default,
  notEmptyAttribute,
  isInteger,
}
