/* eslint-disable implicit-arrow-linebreak,indent,no-unused-expressions */
import { curry, isArr } from 'lib/util'

/**
 * OnKey Util Event Handler.
 *
 * Calls {@param eventHandler} if the event that triggered this handler was fired by pressing the
 * key with code {@param key}.
 *
 * @param {string|string[]} key           the code of the pressed key/s
 * @param {function} eventHandler         the event handler to call if enter was pressed
 * @returns {Gaia.AppModule.EventHandler}
 */
export default (key, eventHandler) => curry(async (module, component, event) => {
  const isTriggered = isArr(key) ? key.includes(event.key) : event.key === key
  isTriggered && await eventHandler(module, component, event)

  return isTriggered
})
