/* global G */
import { curry } from 'lib/util'
import decisions from 'model/_shared/collection/decision'

/**
 * Returns the translated list of decision options.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent|string} eventOrKey
 * @return {Array} countries
 */
const listDecisionOptions = (module, component, eventOrKey) => {
  const key = eventOrKey?.detail?.key || eventOrKey
  const scope = key ? decisions.filter(item => item.key === key) : decisions

  return scope.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `decisions.${item.key}`,
      {
        ns: 'common',
        _key: `decisions.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }))
}

export default curry(listDecisionOptions)
