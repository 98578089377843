/**
 * Status Collection.
 *
 * Contains static list of available status, which can be used for different purposes, like
 * filling lists, allowing options selection, etc.
 */
export default [
  {
    key: 0,
    icon: 'fiber_manual_record',
    iconInOption: false,
    value: 'Draft',
    color: 'warning',
  },
  {
    key: 50,
    icon: 'fiber_manual_record',
    iconInOption: false,
    value: 'Active',
    color: 'secondary',
  },
  {
    key: 80,
    icon: 'fiber_manual_record',
    iconInOption: false,
    value: 'Obsolete',
    color: '',
  },
  {
    key: 90,
    icon: 'fiber_manual_record',
    iconInOption: false,
    value: 'Archived',
    color: 'error',
  },
]
