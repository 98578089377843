/* global G */
import { curry } from 'lib/util'
import types from './type'

/**
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent|string} eventOrKey
 * @return {Array} countries
 */
const listOrganisationTypes = (module, component, eventOrKey) => {
  const key = eventOrKey?.detail?.key || eventOrKey
  const scope = key ? types.filter(item => item.key === key) : types

  return scope.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `organisation.type.${item.key}`,
      {
        ns: 'custom',
        _key: `organisation.type.${item.key}`,
        defaultValue: item.value,
        context: module[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT],
      },
    ),
  }))
}

export default curry(listOrganisationTypes)
