import noop from '@gaia/util/noop/action'
import detail from './action/detail'
import edit from './action/edit'
import submit from './action/submit'
import index from './action/index'
import create from './action/create'

export default {
  index,
  new: create,
  edit,
  detail,
  tickets: noop,
  requests: noop,
  persons: noop,
  serviceItems: noop,
  organisations: noop,
  submit,
}
