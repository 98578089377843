/* global G */
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import { canHookInto } from 'trait/can'
import appModuleComposition from 'trait/composition/appmodule'
import actions from './actions'
import events from './events'
import hooks from './hooks'

const { ACTIONS, EVENTS } = G

const descriptor = 'module::user'

/**
 * Guest Support Module
 *
 * Having provided a valid email,
 * a user or guest encountered problems while trying to log in or register.
 * Any remedy as password resets or other having failed, the user requires assistance,
 * Usually the user's email will be known when the assistance is required.
 *
 * @param {Gaia.AppModule.Spec} obj - configuration object
 * @return {Gaia.AppModule.Spec} obj - object composition
 */
const composition = pipe(
  appModuleComposition(descriptor),
  usesGetterSetter(ACTIONS, actions),
  usesGetterSetter(EVENTS, events),
  canHookInto(ACTIONS, hooks),
)

export default composition
