/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import modelSet from 'app/_shared/events/model/set'
import setStepTab, { CONFIRM } from 'app/_shared/events/setStepTab'
import find from 'lib/sequence/component/children/find'
import sequenceComponentState from 'lib/sequence/component/state'

const {
  set: complete,
} = sequenceComponentState('completed')

const {
  set: confirm,
} = sequenceComponentState('confirmed')

/**
 * Sets the requesterContact's organisation as the value of requesterOrg and sets the organisation
 * step state as completed and confirmed.
 *
 * @type {(function(...[*]): (*))|*}
 */
const updateRequesterOrg = curry(async (module, component, item) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { organisation } = find(actionComponent)

  await modelSet('requesterOrg', module, component, { detail: { key: item.key, item } })
  await setStepTab(CONFIRM, 'organisation')(module, actionComponent, null)

  complete(organisation)
  confirm(organisation)
})

/**
 * Shows a dialog asking the user for confirmation and returns a promise wrapping a boolean whose
 * value is {@code true} only if the user confirmed it.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {Promise<boolean>}          {@code true} only if the user confirmed the dialog
 */
const showDialog = module => new Promise((resolve, reject) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: module[G.ADAPTER][G.INTL]._t(
      'dialog.warning.requesterOrgChange.title',
      {
        ns: 'ticket',
        _key: 'dialog.warning.requesterOrgChange.title',
        defaultValue: 'Contact change',
      },
    ),
    text: module[G.ADAPTER][G.INTL]._t(
      'dialog.warning.requesterOrgChange.text',
      {
        ns: 'ticket',
        _key: 'dialog.warning.requesterOrgChange.text',
        defaultValue: 'Do you want to set/update the customer/partner with contact\'s data?',
      },
    ),
    children: {
      cancel: {
        key: 'cancel',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.noCancel',
          {
            ns: 'common',
            _key: 'button.noCancel',
            defaultValue: 'No, cancel',
          },
        ),
      },
      ok: {
        key: 'ok',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.yesUpdate',
          {
            ns: 'common',
            _key: 'button.yesUpdate',
            defaultValue: 'Yes, update',
          },
        ),
      },
    },
    okHandler: () => resolve(true),
    cancelHandler: () => resolve(false),
  })
})

/**
 * If the current requesterOrg is different from the requesterContact's organisation, shows a dialog
 * asking the user if they want to overwrite the former with the latter. If there is no
 * requesterOrg, it automatically sets the requesterContact's organisation as its value.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
export default curry(async (module, component, event) => {
  const model = module[G.MODEL]
  const { requesterOrg } = model[G.CHILDREN]
  const { item: selectedContact } = event?.detail || {}
  const { organisation: [organisation] } = selectedContact.refs
  const requesterOrgKey = requesterOrg[G.STATE][G.REF]
  const selectedOrgKey = organisation.key

  console.log({ requesterOrgKey, selectedOrgKey })

  if (requesterOrgKey) {
    if (requesterOrgKey !== selectedOrgKey) {
      await showDialog(module) && await updateRequesterOrg(module, component, organisation)
    }
  } else {
    await updateRequesterOrg(module, component, organisation)
  }
})
