/* global G */
import { bulk, curry, setKey } from 'lib/util'
import validate from 'lib/sequence/model/validate'
import findDevice from 'app/_shared/events/device/find'
import { get } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { detail } from 'app/_shared/events'
import { actionWithHooks } from 'lib/sequence/module/action'
import reset from 'lib/sequence/model/api/reset'
import setStepTab, { CONFIRM } from 'app/_shared/events/setStepTab'
import refresh from 'lib/sequence/model/api/refresh'
import PlatformEvent from 'lib/util/event'
import session from 'app/_shared/session'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import sequenceComponentState from 'lib/sequence/component/state'
import undefinedModelLabel from 'app/_shared/events/device/undefinedModelLabel'

const {
  set: confirm,
  unset: unconfirm,
} = sequenceComponentState('confirmed')

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Shows a dialog informing the user that a device was found.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {function} okHandler          a function to be executed if the user clicks 'ok'
 */
const showDeviceAlreadyMaintainedDialog = (module, okHandler) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: module[G.ADAPTER][G.INTL]._t(
      'dialog.deviceAlreadyMaintained.title',
      {
        ns: 'device',
        _key: 'dialog.deviceAlreadyMaintained.title',
        defaultValue: 'Device already maintained',
      },
    ),
    text: module[G.ADAPTER][G.INTL]._t(
      'dialog.deviceAlreadyMaintained.text',
      {
        ns: 'device',
        _key: 'dialog.deviceAlreadyMaintained.text',
        defaultValue: 'You already maintain the machine with this serial number.',
      },
    ),
    children: {
      cancel: {
        key: 'cancel',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.cancel',
          {
            ns: 'common',
            _key: 'button.cancel',
            defaultValue: 'Cancel',
          },
        ),
      },
      ok: {
        key: 'ok',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.goToDevice',
          {
            ns: 'device',
            _key: 'button.goToDevice',
            defaultValue: 'Go to device',
          },
        ),
      },
    },
    okHandler,
  })
}

/**
 * Validates the serial field and uses {@link findDevice} to attempt to find a product, while
 * handling the different possibilities:
 * - if a product is found (no matter if already installed) and not already maintained
 * by the user, we show the confirm tab
 * - if a product is found (not matter if already installed) and already maintained
 * by the user, we show a warning
 * - if no product is found, we mock a service item and offer help
 *
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    the event object that triggered this handler
 * @return {Promise<void>} void
 */
const searchProduct = async (module, component, event) => {
  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const {
    form,
    serial,
    btnMore,
    btnNoSerial,
    existingHelp,
    location,
  } = sequenceComponentFind(actionComponent)

  // hiding help buttons
  bulkHide(btnNoSerial, existingHelp, btnMore)
  // clearing currently stored data
  reset(model)
  // clearing model's error state
  setKey(null, G.ERROR, model[G.STATE])
  // validating model against form
  await validate(model)(form)

  if (!model[G.STATE][G.ERROR]) {
    const serialValue = get(serial)
    const { person } = session(module)
    const result = await findDevice(module, serial, serialValue)
    const isMaintainedByUser = result.serviceItem?.refs?.maintainedBy?.some(
      maintainer => maintainer === person.key(),
    )

    // We have SI, already maintained by me
    result.serviceItem
      && isMaintainedByUser
      && showDeviceAlreadyMaintainedDialog(module, async () => {
        const customEvent = new CustomEvent('click', { detail: result.serviceItem })
        await detail(module, component, new PlatformEvent(customEvent))
      })

    // We have SI, but not maintained by me
    result.serviceItem
      && !isMaintainedByUser
      && await refresh(model, {
        ...result.serviceItem._rev && { _rev: result.serviceItem._rev },
        key: result.serviceItem?.key,
        value: result.serviceItem.value,
        refs: result.serviceItem.refs,
      })
    // If we have an id, save it to the models G.DATA, so that we include it in the payload
    // later and therefore update the item
    && result.serviceItem.key
    && setKey(result.serviceItem.key, '_id', model[G.DATA])

    // No serviceItem found
    !result.serviceItem
      && await refresh(model, { value: {
        serial: serialValue,
        name: result.name || await undefinedModelLabel(module),
      } })

    // Skip the 'location' step if serviceItem is already installed somewhere
    result.serviceItem?.refs?.installedAt
      ? confirm(location)
      : unconfirm(location)

    // Show confirm tab if we have product and user is not maintainer, otherwise offer help
    result.product
      ? !isMaintainedByUser
          && await setStepTab(CONFIRM)(module, component, event) // setting step's 'confirm' tab
      : bulkShow(existingHelp, btnMore) // if no product is found, we show the help buttons
  } else {
    show(btnNoSerial)
  }
  actionWithHooks(module[G.STATE][G.ACTION])([])
}
export default curry(searchProduct)
