/**
 * Concatenates {@param query}s values if they are not empty strings and joins them together
 * resulting in a query string to be sent to the server.
 *
 * @param {Object} query
 * @returns {string}
 */
const concatQueryParams = query => Object
  .values(query)
  .filter(param => param.length > 0)
  .join(' ')

export default concatQueryParams
