/* global G */
import { asyncPipeSpread, bulk, getFirstItem, setKey } from 'lib/util'
import seqModelRead from 'lib/sequence/model/api/read'
import find from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { enable, disable, disabled } from 'lib/sequence/component/state/disabled'
import { set } from 'lib/sequence/component/state/value'
import listStatus from 'app/_shared/events/collection/listStatus'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Presets the organisation field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const presetOrganisation = module => component => async (children, ...args) => {
  const { organisation } = args[0] || {}
  const model = module[G.MODEL]

  // Set the organisation for the new user if we get passed one
  if (organisation) {
    model[G.CHILDREN].organisation[G.CACHE] = organisation[G.CACHE]
    await seqModelRead(model[G.CHILDREN].organisation)(component)
    const { organisation: organisationField } = asObject(component[G.CHILDREN])
    set(organisationField, model[G.CHILDREN].organisation[G.CACHE])
  }

  return [children, ...args]
}

/**
 * Determines whether the invite section should be shown
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayInviteForm = module => async (children, ...args) => {
  const model = module[G.MODEL]
  const { contactChannels } = model[G.CHILDREN]
  const { email: emailField } = contactChannels[G.CHILDREN]
  const emailAttributeValidators = emailField[G.VALIDATOR]

  // attribute validator
  const notEmptyValidator = emailAttributeValidators.find(validator => validator._name === 'notEmpty')

  const {
    component,
    email,
    invite,
    inviteForm,
    inviteInfo,
  } = children
  const { toBeInvited } = find(component[G.ACTIONS][0])

  toBeInvited[G.STATE].checked
  // Activating additional validators on email field if checkbox is clicked
    ? bulkShow(inviteForm)
      && enable(notEmptyValidator)
    : bulkHide(inviteForm)
      && disable(notEmptyValidator)
      // Resetting error message on unchecking
      && setKey(false, 'error', email[G.STATE])
      && setKey(null, 'helperText', email[G.STATE])

  bulkHide(invite, inviteInfo)

  return [children, ...args]
}

/**
 * Preset the {@code status} field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetStatus = module => async (children, ...args) => {
  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { status: statusField } = children

  const status = getFirstItem(listStatus(module, actionComponent, { detail: { key: 50 } }))

  // Only set the field if it's editable
  !hidden(statusField)
  && !disabled(statusField)
  && set(statusField, status)

  return args
}

/**
 * Person Contact Action
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  presetOrganisation(module)(component),
  displayInviteForm(module),
  presetStatus(module),
)(find(component), ...args)
