/* eslint-disable no-nested-ternary */
/* global G */
import { curry, getFirstItem, isArr, isObj } from 'lib/util'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import find from 'lib/sequence/component/children/find'
import { get, set } from 'lib/sequence/component/state/value'
import { persistOptions } from 'app/_shared/events'

const changeAtOrgsAndPersistOptions = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const { item } = event?.detail || event || {}

  if (item) {
    const newOrg = item?.value?.name

    // component
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { roles, rolesList, organisation, newAtOrganisation } = find(actionComponent)
    const oldOrganisationKey = getFirstItem(get(organisation))?.key || null
    const oldRoles = get(roles)

    const currentRoles = !oldRoles ? [] : isArr(oldRoles) ? oldRoles : Object.values(oldRoles)
    const canChangeRoles = currentRoles?.some(x => x?.atOrg === oldOrganisationKey)

    let confirmed = false
    if (canChangeRoles) {
      confirmed = await showBlockingDialog(module, component, {
        title: {
          ns: 'admin',
          key: 'dialog.changeOrg.title',
          defaultValue: 'Change organization',
        },
        text: {
          ns: 'admin',
          key: 'dialog.changeOrg.text',
          defaultValue: 'Do you want to change the user organisation to {{type}} in roles?',
          type: newOrg,
        },
      })
    }

    if (confirmed) {
      // Replacing the new user organisation in all roles that had the old organisation if the user
      // confirmed the dialog.
      const newRoles = isArr(oldRoles)
        ? oldRoles.map(role => (role?.atOrg === oldOrganisationKey
          ? { ...role, atOrg: item?.key }
          : role))
        : Object.keys(oldRoles).reduce((acc, key) => ({
          ...acc,
          [key]: { ...oldRoles[key],
            ...oldRoles[key].atOrg === oldOrganisationKey && { atOrg: item?.key } },
        }), {})

      // Setting the new roles
      set(roles, newRoles)

      // In case the user has added a new role/org below the list of roles, we need to change the
      // org there as well
      const newAtOrg = isObj(newRoles) ? newRoles?.new : false
      newAtOrg?.atOrg === item?.key && set(newAtOrganisation, item)
      rolesList[G.STATE].update = {}
    }
  }

  persistOptions(module, component, event)
  module[G.ADAPTER][G.UI].update(module)
}

export default curry(changeAtOrgsAndPersistOptions)
