/* global G */
import { modelComposition } from '@gaia/trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import { withGetterSetter } from 'trait/with'
import validator from './validator'

// const descriptor = 'model::contact_info' // if submodel, this has to be overridden..
const descriptor = 'model::contactChannels::enum' // if submodel, this has to be overridden..
// withDescriptor for the rescue.
// i need specific descriptor for models and model attributes

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
)
