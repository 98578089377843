/* global G */
import { curry } from 'lib/util'
import attachment from 'model/_shared/collection/attachment'

/**
 * List Attachment Filters Event handler
 *
 * Expects a structure like
 * @example
 * {
 *     size: 100,
 *     types: "jpeg, png"
 *     ...
 * }
 * in {@param eventOrOptions} or {@param eventOrOptions.detail}. Will only get the translations
 * of the properties defined in the structure.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent|string} eventOrOptions
 * @return {Array} countries
 */
const listAttachmentFilters = (module, component, eventOrOptions) => attachment.filter(
  i => eventOrOptions?.detail?.[i.key] || eventOrOptions?.[i.key],
).map((item) => {
  const option = eventOrOptions?.detail?.[item.key] || eventOrOptions?.[item.key]
  return {
    ...item,
    value: module[G.ADAPTER][G.INTL].translate(
      `dialog.error.attachment.${item.key}`,
      {
        ns: 'common',
        ...option && { [item.key]: option },
        _key: `dialog.error.attachment.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }
})

export default curry(listAttachmentFilters)
