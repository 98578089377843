/* global G */
import { curry } from 'lib/util'
import getQuickAction from 'app/_shared/events/appbar/quickActions/get'

/**
 * Set Attachment And Update Quick Action Handler
 *
 * Temporarily stores the given attachment in the action state, so that the
 * {@link saveSetAttachment} handler knows what attachment to download.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {PlatformEvent} event         information about the event that triggered this handler
 */
const setAttachmentAndUpdateQuickAction = (module, component, event) => {
  if (event?.detail?.uri) {
    const actionState = module[G.STATE][G.ACTION][G.STATE]
    const downloadAction = getQuickAction('download', module, component, event)

    if (downloadAction) {
      downloadAction.disabled = false
      actionState.attachment = event.detail

      module[G.ADAPTER][G.UI].update(module)
    }
  }
}

export default curry(setAttachmentAndUpdateQuickAction)
