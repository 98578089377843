/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Submit Modal Event Handler
 *
 * Calls the {@param action} action with the current document. Passes the
 * {@code documentationId} (or the attachment key as a fallback) to the action
 * so that it can be added to the payload.
 *
 * @param {string} action                 the action to call
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event that triggered this handler
 * @returns {Promise<void>}
 */
const submit = async (action, module, component, event) => {
  const { payload, options = null } = event?.detail || event || {}

  const args = options ? [payload, options] : [payload]

  await actionWithHooks(module[G.ACTIONS]?.[action])(...args)
}

export const submitModalWith = curry(submit)

export default curry((module, component, event) => submit('modalSubmit', module, component, event))
