/* global G */
import { asyncPipeSpread, bulk } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'
import { get, set } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Maps various model properties to the action component.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapProperties = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]

  // component
  const { mainSection, recipientSection, commentSection, title } = children

  const bulkMap = bulk(map(model))
  bulkMap(mainSection, recipientSection, commentSection, title)

  return [children, ...args]
}

/**
 * Determines if the address should be displayed.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayAddress = module => async (children, ...args) => {
  const { [G.INTL]: intlAdapter } = module[G.ADAPTER]

  // component
  const { address } = children
  const addressState = address[G.STATE]

  const intlAddress = intlAdapter.address(addressState?.value)

  addressState?.value
    ? set(address, intlAddress)
    : hide(address)

  return [children, ...args]
}

/**
 * Determines if the contact information should be displayed.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayContactInfo = module => async (children, ...args) => {
  // component
  const { contactInfo } = children

  !get(contactInfo) && hide(contactInfo)

  return [children, ...args]
}

/**
 * Determines if the request details section should be displayed.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayRequestDetails = module => async (children, ...args) => {
  // component
  const { contactInfo, address, name, recipientSection } = children

  !get(address)
  && !get(name)
  && !get(contactInfo)
  && hide(recipientSection)

  return [children, ...args]
}

/**
 * Determines if the comment section should be displayed.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayComment = module => async (children, ...args) => {
  // component
  const { comment, commentSection } = children

  !get(comment) && hide(commentSection)

  return [children, ...args]
}

/**
 * Determines what actions should be displayed.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayActions = module => async (children, ...args) => {
// model
  const model = module[G.MODEL]
  const { status } = model[G.CHILDREN]
  const currentStatus = status[G.CACHE] || 0

  // component
  const { btnEdit, btnCart, btnTransfer, btnPdf } = children

  currentStatus === 80
    ? bulkShow(btnTransfer, btnPdf)
    : bulkHide(btnTransfer, btnPdf)

  currentStatus === 0 || currentStatus === 80
    ? bulkShow(btnEdit)
    : bulkHide(btnEdit)

  currentStatus === 50
    ? show(btnCart)
    : hide(btnCart)

  return args
}

/**
 * Cart Detail Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  mapProperties(module),
  displayAddress(module),
  displayContactInfo(module),
  displayRequestDetails(module),
  displayComment(module),
  displayActions(module),
)(sequenceComponentFind(component), ...args)
