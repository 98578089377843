/* global G */
import find from 'lib/sequence/component/children/find'
import { asyncPipeSpread, setKey } from 'lib/util'
import { reset } from 'lib/sequence/component/state/value'
import { uncheck } from 'lib/sequence/component/state/checked'

/**
 * Resets {@param component} recursively.
 *
 * @param {Gaia.Component.Spec} component   the current action's main component
 */
const bulkReset = (component) => {
  reset(component)
  component[G.STATE]?.checked && uncheck(component)
  component[G.CHILDREN]?.length && component[G.CHILDREN].forEach((child) => { bulkReset(child) })
}

/**
 * Resets feedback specific fields.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const resetFields = module => async (components, ...args) => {
  const { feedback, feedbackModal } = components

  feedback && bulkReset(feedback)
  feedbackModal && setKey(false, 'open', feedbackModal[G.STATE])

  return args
}

/**
 * Feedback Success Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  resetFields(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
