/* global G */
import formatValue from 'app/_shared/component/formatValue'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

/**
 * Replaces the {@code username} token with the actual
 * username in the translation string.
 *
 * @param {Gaia.AppModule.Spec} module - application module
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const { username } = module[G.MODEL][G.DATA]

  const { msgResetMD } = sequenceComponentFindProxy(component)

  await formatValue(module, msgResetMD, { account: username }, 'text')

  return args
}
