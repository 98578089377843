/* global G */
import { curry } from '@gaia/util'

const descriptor = 'model::transformer::drop'

/**
 * Transformer Drop
 *
 * deletes property, identified by key, from payload
 *
 * @param key - identifier
 * @param obj - data model
 * @param data - data aggregator, expected obj[G.STATE][G.DATA]
 * @return {*}
 */
const drop = (key, obj, data) => {
  const _data = data
  try {
    const item = obj[G.CHILDREN][key]
    const { type } = item[G.PROPS]
    _data?.[type]?.[key] && delete _data[type][key]
  } catch (e) {
    console.error(descriptor, e)
  }
  return _data
}

export default curry(drop)
