/* global G */
import { curry } from '@gaia/util'

/**
 * To Info (Model) Transformer
 *
 * transforms input into an multiple referenced objects
 *
 * @param ref - identifier
 * @param obj - data model
 * @param data - data aggregator, expected obj[G.STATE][G.DATA]
 * @return {*}
 */
const toInfo = (ref, obj, data) => {
  const _data = data
  try {
    const item = obj[G.CHILDREN][ref]
    const { type } = item[G.PROPS]
    _data[type][ref] = Object.keys(item[G.CHILDREN]).reduce((acc, key) => {
      // eslint-disable-next-line no-unused-expressions
      !item[G.PROPS].types.includes(key) && throw ReferenceError(`${key} is not part of enum`)

      const child = item[G.CHILDREN][key]
      const { value } = child[G.DATA]
      // lacks update functionality
      const entry = {
        // key: '',
        value: {
          key,
          value,
        },
      }

      value && acc.push(entry)
      return acc
    }, [])
  } catch (e) {
    console.error(e)
  }
  return _data
}

export default curry(toInfo)
