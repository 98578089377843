/* global G */
import { curry, deleteKey, isStr, setKey } from 'lib/util'
import refresh from 'lib/sequence/model/api/refresh'

/**
 * Clears the error state of {@param model} and all its submodels
 * @param {Gaia.Model.Spec} model   a model object
 */
const clearModelError = (model) => {
  model[G.STATE] && deleteKey(G.ERROR, model[G.STATE])
  model[G.CHILDREN] && Object.values(model[G.CHILDREN]).forEach(clearModelError)
}

/**
 * Event Handler Model Attribute Set.
 *
 * Sets an existing model as sub-model of the module's main model.
 *
 * @param {string} name       the name of the child model
 * @param {object} module     the ticket module composition object
 * @param {object} component  the current action's main component
 * @param {object} eventOrKey the event object that triggered this handler
 * @return {Promise<void>}
 */
const set = curry(async (name, module, component, eventOrKey) => {
  try {
    const key = eventOrKey.detail?.key || (isStr(eventOrKey) ? eventOrKey : null)
    const data = eventOrKey.detail?.item || null
    const model = module[G.MODEL]
    const child = model[G.CHILDREN][name]
    // propagating child's cache from key and data
    clearModelError(child)
    await refresh(child, { ...data, key })
    child[G.STATE][G.BULK] = false
    // setting action's dirty flag
    setKey(true, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
  } catch (e) {
    console.error(e)
  }
})

export default set
