import noop from '@gaia/util/noop/action'
import submit from '@app/person/action/submit'
import detail from '@app/person/action/detail'
import create from '@app/person/action/new'
import edit from '@app/person/action/edit'
import index from '@app/person/action/index'
import contact from '@app/person/action/invite/contact'
import colleague from '@app/_shared/action/invite/colleague'

export default {
  index,
  new: create,
  edit,
  submit,
  newContact: contact,
  newColleague: colleague,
  detail,
  tickets: noop,
  requests: noop,
  serviceItems: noop,
}
