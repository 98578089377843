/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util';
import toRef from 'model/_shared/transformer/toRef';
import { usesGetterSetter } from '@gaia/trait/uses'
import withGetterSetter from 'trait/with/GetterSetter';
import drop from 'model/_shared/transformer/drop';
import toFirstKey from 'model/_shared/transformer/toFirstKey';
import fromMessageType from 'model/_shared/decorator/fromMessageType'
import fromMessageTypeValue from 'model/_shared/decorator/fromMessageTypeValue'
import { all as allMessageTypes } from 'model/_shared/collection/messageType'
import validator from './validator'

const descriptor = 'model::textTemplate'

const tMessageType = { _key: 'message.type', ns: 'ticket' }

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    organisation: toRef('organisation'),
    messageType: toFirstKey('messageType'),
    messageTypeShow: drop('messageTypeShow'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    messageType: fromMessageType('messageType', allMessageTypes, tMessageType),
    messageTypeShow: fromMessageTypeValue('messageType', 'messageTypeShow', allMessageTypes, tMessageType),
  }),
)
