/* global G */
import ui from 'lib/hook/ui/update'
import redirect from 'lib/hook/router/redirect'
import confirm from 'lib/hook/router/confirm'

const _baseHooks = {
  after: [
    ui,
  ],
}

const _confirmHooks = {
  after: [
    ui,
    confirm,
  ],
}

const _redirectHooks = {
  before: [],
  after: [
    ui,
    redirect,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _confirmHooks,
    submit: _redirectHooks,
    sent: _baseHooks,
  },
}

export default hooks
