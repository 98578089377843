import { curry } from 'lib/util'
import session from 'app/_shared/session'
import listCountries from 'app/_shared/events/collection/listCountries'

/**
 * Returns the translated country of the current user's organisation.
 *
 * @param {Gaia.AppModule.Spec} module      the current module object composition
 * @param {Gaia.Component} component        the object composition of the component that triggered
 *                                          the event handler
 * @param {Gaia.PlatformEvent|string} event the event or the key of the country to obtain
 * @return {object[]} countries
 */
// eslint-disable-next-line no-unused-vars
const listUserCountries = async (module, component, event) => {
  try {
    const organisation = session(module).organisation?.ref()
    const iso = organisation?.value.address.country
    return iso ? listCountries(module, component, iso) : []
  } catch (ignored) {
    return []
  }
}

export default curry(listUserCountries)
