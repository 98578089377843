/* eslint-disable no-unused-vars,no-param-reassign */
/* global G */
import find from 'lib/sequence/component/children/find'
import { set } from 'lib/sequence/component/state/value'
import { toTimeEntry } from 'app/ticket/event/note/time'

/**
 * Time Tracking action.
 *
 * Displays a list of notes that have a corresponding time tracking entry and a summary.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const { summary } = find(component)
  const { timeNotes = [] } = module[G.STATE]

  const summaryValue = timeNotes.reduce((acc, key) => acc + (key?.value?.spentTime || 0), 0)
  set(summary, toTimeEntry(module, component, { value: String(summaryValue / 60) }))

  return args
}
