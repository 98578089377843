/* global G */
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import asObject from 'lib/sequence/component/children/asObject'
import { get } from 'lib/sequence/component/state/value'
import validateRedirect from './validateRedirect'

const re = /[!?/.@#$%^&*|\-_]/
const min = 8
/**
 * completeRegister Action
 *
 * Parses the id and token from the email link
 * and creates the corresponding PUT requests to the server,
 * depending on the response, redirects to the appropriate action
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {Promise<void>}
 */
const _completeRegister = async (module) => {
  const model = module[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const { version, api } = model[G.PROPS]
  const [id, token] = module[G.ADAPTER][G.SESSION][G.STATE][G.REF].split('/')

  const url = `/api/v${version}/public/register/${id}`

  const moduleState = module[G.STATE]
  const { password: field } = asObject(moduleState[G.ACTION][G.COMPONENT][G.CHILDREN])
  const passwordFieldStateValue = get(field)
  const fieldState = field[G.STATE]

  if (!re.test(passwordFieldStateValue) || !(passwordFieldStateValue.length >= min)) {
    fieldState.error = true
    fieldState.helperText = 'Requirements not fulfilled'
    throw TypeError('Requirements not fulfilled')
  }

  const params = {
    token,
    password: passwordFieldStateValue,
  }

  await httpAdapter.put({ url, params })
    .then(async (response) => {
      const moduleState = module[G.STATE]
      moduleState[G.ROUTE] = {
        [G.ACTION]: 'registerSuccess',
      }
      await redirectSequence(module)({})
    })
    .catch(async (error) => {
      const moduleState = module[G.STATE]
      moduleState[G.ROUTE] = {
        [G.ACTION]: 'registerError',
      }
      await redirectSequence(module)({})
    })
}

/**
  * Completes the registration process
  *
  * @param {Gaia.AppModule.Spec} module  the current module composition object
  * @returns {function(): function(...[*]): Promise<*>}  the call's arguments
  */
export default module => () => async (...args) => {
  const result = validateRedirect(module)()(...args)
  const modelStateError = module[G.MODEL][G.STATE][G.ERROR]
  !modelStateError && await _completeRegister(module)

  return result
}
