/* global G */
import { pipe } from 'lib/util'
import appModuleComposition from 'trait/composition/appmodule'
import { canHookInto } from 'trait/can'
import { usesGetterSetter } from 'trait/uses'
import actions from '@app/serviceItem/actions'
import events from '@app/serviceItem/events'
import hooks from '@app/serviceItem/hooks'

const descriptor = 'module::serviceItem'

/**
 * ServiceItem Module Composition
 *
 * the module displays a list of service items
 * allows adding new service items
 * allows editing existing service items
 *
 * @param {Gaia.AppModule.Spec} obj - configuration object
 * @return {Gaia.Module} obj - object composition
 */
const composition = pipe(
  appModuleComposition(descriptor),
  canHookInto(G.ACTIONS, hooks),
  usesGetterSetter(G.ACTIONS, actions),
  usesGetterSetter(G.EVENTS, events),
)

export default composition
