/* eslint-disable max-len */
/* global G */
import find from 'lib/sequence/component/children/find'
import { asyncPipeSpread } from 'lib/util'

/**
 * @typedef Gaia.AppModule.ActionPartial
 *
 * @description Defines parts of the behavior of an action, usually related to the same context.
 * @param {Gaia.AppModule.Spec} module  current module composition
 * @return {function(Proxy<T>, ...[*]): Promise<[Proxy<T>, ...*]>}
 */

/**
 * Prepends a {@link find} Proxy of the current action component to {@param args} and
 * executes {@link asyncPipeSpread} with {@param partials}.
 *
 * @param {...Gaia.AppModule.ActionPartial} partials
 * @returns {function(*): function(*): function(...[*]): Promise<*>}
 */
export const asyncPipeAction = (...partials) => module => component => async (...args) => await asyncPipeSpread(
  ...partials.map(partial => partial(module)),
)(find(component || module[G.STATE][G.ACTION][G.COMPONENT]), ...args)

/**
 * Sets the passed route as the next route.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition
 * @return {(function(*): void)|*}
 */
const setRoute = module => (route) => {
  const moduleState = module[G.STATE]
  // setting default state
  moduleState[G.ERROR] = false
  moduleState[G.ROUTE] = route.module ? {
    [G.ACTION]: route.action,
    [G.MODULE]: route.module,
  } : {
    [G.ACTION]: route.action,
  }
}

/**
 * Cancels the current redirect.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition
 * @return {boolean}
 */
const cancelRedirect = (module) => {
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = true
  moduleState[G.ROUTE] = null
  return true
}

/**
 * Checks whether {@param module[G.STATE][G.ACTION]} is equal to {@param action}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition
 * @param {string} action               the name of the action to check against
 * @returns {boolean}
 */
const isAction = (module, action) => module[G.STATE][G.ACTION]._name === module[G.ACTIONS]?.[action]._name

const util = {
  setRoute,
  cancelRedirect,
  isAction,
}

export {
  util as default,
  setRoute,
  cancelRedirect,
  isAction,
}
