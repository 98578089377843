/* global G */
import { curry, setKey } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Check Email event handler
 *
 * Checks if the email exists if it's not the same as
 * the user's current email.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component} component - ui component
 * @param {Event} event - event
 * @return {Promise<void>} void
 */
const checkEmail = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  // model
  const { person } = model[G.CHILDREN]
  const { contactChannels } = person[G.CHILDREN]
  const { email } = contactChannels[G.CHILDREN]
  const notExistsValidator = contactChannels[G.CONFIGURATION].validator?.email
  setKey(false, 'disabled', notExistsValidator.options)

  // component
  const { person: personField } = asObject(actionComponent[G.CHILDREN])
  const { contactChannels: contactChannelsField } = asObject(personField[G.CHILDREN])
  const { email: emailField } = asObject(contactChannelsField[G.CHILDREN])

  // Only disable this validator if the email hasn't changed
  if (emailField[G.STATE].value === email[G.CACHE]) {
    setKey(true, 'disabled', notExistsValidator.options)
  }
}

export default curry(checkEmail)
