/* global G */
import find from 'lib/sequence/component/children/find'
import { settings } from 'app/_shared/session'

/**
 * Looks for a list component found at the end of {@param keysPath}, searches its itemProps for an
 * item with key 'serviceItem' and modifies its inverted option so that it has the same value as
 * {@link settings.swapDeviceSerialAndName}.
 *
 * TODO: the session helper should be moved to lib, so that we can access the settings from the
 *        components themselves and we won't need this function.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {...string} [keysPath]    key path to the list component to modify
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const swapListSerialAndName = (module, ...keysPath) => async (components, ...args) => {
  const keys = keysPath?.length ? keysPath : ['list']

  const { component: list } = keys.reduce((acc, key) => find(acc[key]), components)

  if (list) {
    const { structure } = list[G.PROPS]
    const deviceItemProps = structure.find(itemProps => itemProps.options.key === 'serviceItem')

    if (deviceItemProps) {
      deviceItemProps.options.inverted = settings.swapDeviceSerialAndName
    }
  }

  return [components, ...args]
}

export default swapListSerialAndName
