/* eslint-disable no-unused-vars */
/* global G, PLATFORM */
import { bulk, curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { reset } from 'lib/sequence/component/state/value'
import { settings } from 'app/_shared/session'

/**
 * Reset Form Event Handler
 *
 * Resets the login form's components to their initial state.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component} component - action component
 * @param {Event} event - event
 * @return {Promise<void>} void
 */
const resetForm = (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { password, btnContinue, btnLogin } = sequenceComponentFindProxy(actionComponent)
  const bulkHide = bulk(hide)

  if (PLATFORM === 'web' && !settings.singleStepLogin) {
    reset(password)
    show(btnContinue)
    bulkHide(password, btnLogin)
    module[G.ADAPTER][G.UI].update(module)
  }
}

export default curry(resetForm)
