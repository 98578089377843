/* global G */
import { curry } from '@gaia/util'
import { getUpdatedPayload } from '@gaia/sequence/model/api/update'

const descriptor = 'model::transformer::fromComponent'

/**
 * Reference Object Constructor
 *
 * Creates reference object structure
 *
 * @param {Gaia.Model.Spec.state} value - model state
 * @return {{key: (*|string)}}
 * @private
 */
// const _toRef = value => ({ key: value[G.REF] || '', ...value[G.DATA] })
const _toRef = value => ({ ...value[G.DATA] })

/**
 * see @gaia/sequence/model/api/update sequenceModelUpdateFn()
 *
 * @param prev
 * @param next
 * @return {{_rev: _toUpdatedRef.props._rev, _id: _toUpdatedRef.props.key}}
 * @private
 */
// const _toUpdatedRef = (prev, next) => {
//   const { _rev, key: _id } = prev
//   return {
//     _id,
//     _rev,
//     ...next[G.DATA],
//   }
// }

/**
 * From Sub-Model To Single Ref
 *
 * It uses [provisioned]{@link create} model's state data
 * It converts the data into a reference structure, ie { key: '', value: * }
 * It wraps it with an array, as per reference structure
 * It sets the value of data object and returns new data object
 *
 * Relation Example - Organisation 1 : 1 Address
 *
 * @param key - key, expected to be provisioned in composition
 * @param obj - model, provided by sequence call
 * @param data - data, provided by sequence call
 * @return {*}
 */
const fn = (key, obj, data) => {
  const _data = data
  try {
    const item = obj[G.CHILDREN][key]
    const { type } = item[G.PROPS]
    // console.clear()
    // console.log('transformer::fromComponent')
    // console.log(data)
    // console.log(key, type)
    // console.log(item[G.CACHE])
    // console.log(item[G.DATA])
    // console.log(item[G.STATE])
    // console.log('----obj')
    // console.log(obj[G.CACHE])
    // console.log(obj[G.DATA])
    // console.log(obj[G.STATE])

    // set results of create() & getValue() as a single ref
    _data[type][key] = item[G.CACHE]
      ? [getUpdatedPayload(item[G.CACHE][0], item[G.STATE])]
      : [_toRef(item[G.STATE])]

    // const objData = obj[G.DATA]
    // objData[key] = _data[type][key] // todo: do we need this setter?
  } catch (e) {
    console.warn(descriptor, obj._name, obj[G.CHILDREN][key]._name, e.message)
  }
  return _data
}

export default curry(fn)
