import { curry } from 'lib/util'
import { getNodeWith } from 'app/_shared/events/file/node/getNode'
import hasCorrectSkipDepth from 'app/_shared/events/file/node/predicates/hasCorrectSkipDepth'
import getEquipmentAndRedirect from 'app/equipmentInformation/events/tree/getEquipmentAndRedirect'

/**
 * Traverses the tree up and returns the first node that is of type {@code product}.
 *
 * @param {Object} node
 * @returns {*|undefined}
 * @private
 */
const _getParentProductNode = (node) => {
  if (node?.nodeType === 'product') {
    return node
  }

  return node?.parent ? _getParentProductNode(node?.parent) : undefined
}

/**
 * Get Node Or Redirect To Detail Event Handler
 *
 * Selects the correct node to display based on its depth and returns it, if it's not a leaf.
 * If it is, executes {@link getEquipmentAndRedirect}.
 *
 * Optionally, if the node is skippable, executes {@link _getParentProductNode} to get the
 * product the node belongs to and returns its icon along with it.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           information about the event that triggered this handler
 * @returns {Promise<{[p: string]: *}|*>}
 */
const getNodeOrRedirectToDetail = async (module, component, event) => {
  const node = getNodeWith({ skippable: hasCorrectSkipDepth }, module, component, event)

  if (node?.$children && node.$children[0].type === 'File') {
    await getEquipmentAndRedirect(module, component, { ...event, detail: { node } })
  }

  if (node.skippable) {
    const productNode = _getParentProductNode(node)
    if (productNode && productNode?.icon) {
      return { ...node, icon: productNode.icon }
    }
  }

  return node
}

export default curry(getNodeOrRedirectToDetail)
