/* eslint-disable no-unused-vars */
/* global G */
import { curry, setKey } from 'lib/util'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import routeComposition from 'trait/composition/route'

/**
 * Redirects to {@param component}'s {@code route.one} route if
 * {@param key} is not null, if it is,  it redirects to
 * {@code route.other} route.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 information about the event that triggered this handler
 * @param {String|null} [key]           ID of the item to redirect, if {@code null}, it performs
 *                                      a normal redirect
 * @return {Promise<void>}
 */
const toDetailsOrIndex = async (module, component, event, key = null) => {
  event.preventDefault()
  event.stopPropagation()

  const moduleState = module[G.STATE]
  const { routes } = component[G.CONFIGURATION]

  moduleState[G.ROUTE] = key
    ? routeComposition(routes.one.module || null, routes.one.action)
    : routeComposition(routes.other.module || null, routes.other.action)

  try {
    if (!key) {
      await redirectSequence(module)({})
    } else {
      setKey(key, G.REF, module[G.MODEL][G.STATE])
      await redirectSequence(module)({ key })
    }
  } catch (e) {
    console.error(e)
  }
  return event
}

export default curry(toDetailsOrIndex)
