import send from './send'
import edit from './edit'
import remove from './remove'
import target from './target'

const message = {
  send,
  edit,
  remove,
}

export {
  message as default,
  send,
  edit,
  remove,
  target,
}
