// /* global G */
import { curry } from 'lib/util'


// could be used with callback in the near future
const defaultValue = (key, value, obj, data) => {
  const _data = data
  // const item = obj[G.CHILDREN][key]
  // const { type } = item[G.PROPS]

  return _data
}

export default curry(defaultValue)
