import { checked } from 'lib/sequence/component/state/checked'
import { hide, show } from 'lib/sequence/component/state/hidden'

/**
 * Toggles the display of the device's organisation form depending on whether the sameLocation
 * checkbox is checked.
 *
 * @param {object} components the find component sequence initialized with the action's component
 * @param {object[]} args     the action's arguments
 */
const toggleDeviceInstalledAt = async (components, ...args) => {
  const { sameLocation, deviceInstalledAt } = components

  checked(sameLocation)
    ? hide(deviceInstalledAt)
    : show(deviceInstalledAt)

  return [components, ...args]
}

export default toggleDeviceInstalledAt
