/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Persists {@param component}'s selected active tab.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
const persistTab = async (module, component, event) => {
  const state = component[G.STATE]
  state.activeTab = event.detail.activeTab
}

export default curry(persistTab)
