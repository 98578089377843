/* eslint-disable no-unused-vars */
/* global G */
import { set } from 'app/_shared/component/token'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

/**
 * Created Action Logic
 *
 * Inject the request number as a token into the confirmation text.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const requestNumber = model[G.CACHE].value.number

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { confirmText } = sequenceComponentFindProxy(actionComponent)

  set(confirmText, { requestNumber })

  return args
}
