/* global G */

import { setKey } from 'lib/util'

/**
 * Back Hook
 *
 * Clears the module's UNDO state and calls the native adapter's back method.
 *
 * @param {object} obj  the current module composition object
 * @return {function(...[*]): Promise<*[]>}
 */
const back = obj => async (...args) => {
  setKey(null, G.UNDO, obj[G.STATE][G.ACTION][G.STATE])
  await obj[G.ADAPTER][G.ROUTER].back()
  return args
}

export default back
