/* eslint-disable no-unused-expressions */
/* global G */
import { setKey } from 'lib/util'
import { settings } from 'app/_shared/session'

/**
 * Checks whether a given user account exists and throws an
 * error if the returned {@G.STATE} is found in the
 * {@code error} object of the validator's config.
 *
 * @param {Gaia.Model} obj                  the model to validate
 * @returns {function(*, *): Promise<*[]>}
 */
export default obj => async (uiState, key) => {
  const { version } = obj[G.PROPS]
  const { options = {} } = obj[G.CONFIGURATION].validator[key]
  const { error = {} } = options
  const { value: username } = uiState
  let code = 200

  if (username) {
    let result

    try {
      const url = `/api/v${version}/public/accountExists/${username}`
      result = await obj[G.ADAPTER][G.HTTP].get({ url })

      if (result.status === 'inactive') {
        code = 403
      }

      /**
       * If the user exists, but doesn't have a password.
       */
      if (result.passwordGenerated) {
        setKey(true, 'passwordGenerated', obj[G.STATE])
      }

      /**
       * If the user must be authenticated by an external server.
       */
      setKey(!!result.remoteAuthentication, 'remoteAuthentication', obj[G.STATE])
    } catch (e) {
      /**
       * If the account doesn't exist, the http code will be 404. This will throw an exception
       * on the httpAdapter, which we don't want to propagate if we check for
       * non-existence. In that case, catch the error and set {@code result[G.STATE]}
       * to the status code, so that we can check for it in the validator's config.
       */
      code = e.code
    }

    if (settings.suppressUserExistenceCheck && code === 404) {
      code = 200
    }

    /**
     * At this stage we have a code, either 200, 403 or 403. Check if we should throw an error
     * for that specific code and if so, throw it, making sure to include the actual error
     * code because the model validation needs it to select the correct translation
     * options.
     */
    const message = error[code]

    if (message) {
      const typeError = new TypeError(message)
      typeError.code = code

      throw typeError
    }
  }

  // otherwise, field is valid
  return [uiState, key]
}
