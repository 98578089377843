import { curry } from 'lib/util'

/**
 * Clean Tree Event Handler
 *
 * Traverses the tree at {@param eventOrOptions} and removes empty directories.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Event|Object} eventOrOptions   the event object that triggered this handler
 * @returns {undefined|{$children}|*}
 */
const cleanTree = (module, component, eventOrOptions) => {
  const { value: node } = eventOrOptions?.detail || eventOrOptions || {}

  if (node?.$children && node?.$children.length) {
    node.$children = node.$children.filter(child => cleanTree(module, component, { value: child }))
  }

  if (node.type === 'Directory' && (!node.$children || node?.$children.length === 0)) {
    return undefined
  }

  return node
}

/**
 * Filter Tree Fn Event Handler
 *
 * Traverses the given tree and calls {@param filter} which each node, it will discard the node,
 * if {@param filter} returns {@code false}, otherwise it will keep it.
 *
 * @param {Function} filter               predicate to check node against
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Event|Object} eventOrOptions   the event object that triggered this handler
 * @returns {undefined|Object}
 */
const filterTreeFn = (filter, module, component, eventOrOptions) => {
  const { value: node } = eventOrOptions?.detail || eventOrOptions || {}

  if (node?.$children?.length) {
    node.$children = node.$children.filter(
      child => filterTreeFn(filter, module, component, { value: child }),
    )
  }

  if (!filter(module, component, node)) {
    return undefined
  }

  return node
}

/**
 * Filter Tree Event Handler
 *
 * Iterates through all functions listed in {@param filters} and calls {@link filterTree}
 * with each of them as the first arg, passes the result of the last iteration as the
 * last arg (or the incoming {@param event} in case of first iteration).
 *
 * @param {Function[]} filters            predicate to check node against
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Event} event                   the event object that triggered this handler
 * @returns {*}
 */
const filterTree = (filters, module, component, event) => filters.reduce(
  (acc, key) => filterTreeFn(
    key, module, component, acc?.detail ? acc : { detail: { value: acc } },
  ), event,
)

/**
 * Filter Tree And Cleanup Event Handler
 *
 * Calls {@link filterTree} with the tree at hand and cleans it up afterward.
 *
 * @type {(function(...[*]): (*))|*}
 */
export const filterTreeAndCleanup = curry((filters, module, component, event) => {
  const filteredTree = filterTree(filters, module, component, event)
  return cleanTree(module, component, { value: filteredTree })
})

/**
 * Calls {@link filterTree} with the tree at hand.
 *
 * @type {(function(...[*]): (*))|*}
 */
export default curry(filterTree)
