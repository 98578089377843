/* global G */
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import validate from 'lib/sequence/model/validate'
import create from 'lib/sequence/model/api/create'
import modelTransform from 'lib/sequence/model/transformer'
import reset from 'lib/sequence/model/api/reset'
import { setKey } from 'lib/util'

/**
 * Instructs the cart adapter to persist the current cart after validating it.
 * Resets the current model afterward to force a refetch.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
const saveCart = module => cartData => async (children, ...args) => {
  // model
  const model = module[G.MODEL]

  const { [G.EVENTS]: eventBus } = module[G.ADAPTER]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { mainContainer, commentData } = sequenceComponentFindProxy(actionComponent)

  // There is no validation, but we need this in order to get the payload
  await validate(model, mainContainer)
  await validate(model, commentData)

  // Mimicking submit hook
  create(model)
  modelTransform(model)

  // Getting the payload
  const { [G.DATA]: data } = model[G.STATE]

  const done = await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.CART, G.DONE), ({ detail }) => {
      const { [G.DATA]: currentCart } = detail
      resolve(currentCart)
    }, { once: true })

    eventBus.dispatch(eventBus.type(G.CART, G.UPDATE), {
      [G.DATA]: { data: { ...data,
        value: {
          ...data.value,
          ...cartData,
        } } },
    })
  })

  // We just POSTed the cart, either by finishing it (status === 80) or by simply saving its current
  // state before the user X'ed out of the cart wizard. Either way, we will perform 'back' here in
  // the after hooks, so we need to reset the local modal so that if we happen to land on a cart
  // detail page, we will refetch it instead of showing an old state.
  const cartKey = model[G.STATE][G.REF]

  if (done !== undefined) {
    await reset(model, { clear: true })
    setKey(cartKey, G.REF, model[G.STATE])
  }

  return args
}

export default saveCart
