/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import session from 'app/_shared/session'
import settingsInit from 'lib/sequence/app/init/settings'

/**
 * Performs a call to store {@param lastContext} in the user's settings.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {string} lastContext          the last selected context
 * @returns {Promise<*>}
 */
const saveContext = async (module, lastContext) => {
  const settingsAdapter = module[G.ADAPTER][G.SETTINGS]
  const { lastContext: savedContext = null } = settingsAdapter[G.DATA] || {}

  if (savedContext !== lastContext) {
    return await settingsAdapter[G.API].set({ key: 'lastContext', value: lastContext })
  }

  return true
}

/**
 * Context Event Handler
 *
 * Sets the {@param event}'s detail's key as the name of the current application context.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component} component - ui component
 * @param {Event} event - event
 * @return {Promise<object[]>} void
 */
const setContext = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()
  // obtaining context key from event's detail
  const { key: contextName } = event.detail
  // setting selected application context
  module[G.ADAPTER][G.ROUTER].context(contextName)
  // setting user acls' context
  session(module).context(contextName)
  // Init settings adapter
  await settingsInit(module)()
  // storing the selected context server-side
  await saveContext(module, contextName)

  await actionWithHooks(module[G.ACTIONS].access)([])
}

export default curry(setContext)
