/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { empty } from 'lib/util/object'
import { actionFn } from 'lib/sequence/module/action'

/**
 * Persists {@param target} as a partial value to the {@param source}'s {@code state.value.new}
 * object. It's meant to be used to add a property of a new combined value (e.g. "Customer@ORG:1").
 *
 * if {@param source} is {@code null}, {@param component} state will be mutated.
 *
 * @param {String} [source]             the element to mutate the state of
 * @param {String} target               the property of the object to set
 * @param {Gaia.AppModule.Spec} module  app module
 * @param {Gaia.Component} component    ui component
 * @param {Event} event                 event
 * @return {Promise<void>}              void
 */
const newPartialOption = async (source, target, module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { [source]: element = component } = sequenceComponentFindProxy(actionComponent)

  const state = element[G.STATE]

  // TODO: Future use cases: What if state.value is not decorated (an array)?
  // What if we need to add more than one item?
  state.value = {
    ...state.value,
    new: {
      ...state.value?.new || {},
      ...event.detail?.item?.key && { [target]: event.detail.item.key },
    },
  }

  // option was cleared
  if (!event.detail?.item) delete state.value.new[target]

  // Both options are cleared, let's delete the entire object so the validator doesn't catch it
  if (empty(state.value.new)) delete state.value.new

  // Reload action
  await actionFn(module[G.STATE][G.ACTION])()
  module[G.ADAPTER][G.UI].update(module)
}

export default curry(newPartialOption)
