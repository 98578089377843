/* eslint-disable arrow-body-style,no-param-reassign */
/* global G */
import { v4 as uuidV4 } from 'uuid'
import { asyncPipeSpread, deleteKey, setKey } from 'lib/util'
import validate from 'lib/sequence/model/validate'
import reset from 'lib/sequence/model/api/reset'
import { setData } from 'lib/sequence/model/api/set'
import find from 'lib/sequence/component/children/find'
import { hidden } from 'lib/sequence/component/state/hidden'
import session, { settings } from 'app/_shared/session'
import { get } from 'lib/sequence/component/state/value'
import sequenceComponentState from 'lib/sequence/component/state'
import { disable, enable } from 'lib/sequence/component/state/disabled'

const {
  set: setError,
} = sequenceComponentState('error')

const {
  set: setHelperText,
} = sequenceComponentState('helperText')

/**
 * Helper function used to simplify the calls used to obtain translated strings.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {string} [ns]                 optional namespace name to be used for all calls
 * @return {(options: object) => Promise<string>}
 */
export const translator = (module, ns) => async (options) => {
  return await module[G.ADAPTER][G.INTL]._t(options._key, { ...options, ns })
}

/**
 * Sets the {@param module}'s state as error if any of the model's submodels has error state.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function}
 */
export const updateModuleState = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const moduleState = module[G.STATE]
  const children = Object.values(model[G.CHILDREN])
  const models = [model, ...children]

  moduleState[G.ERROR] = Object.values(models).some(child => child[G.STATE]?.[G.ERROR])

  return [components, ...args]
}

/**
 * Checks that either the account or the phone fields have a value, otherwise, alters their state in
 * order for them to inform about such requirement and sets the model's error state to {@code true}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function}
 */
export const checkAccountOrPhone = module => () => async (components, ...args) => {
  const translate = translator(module, 'user')
  const modelState = module[G.MODEL][G.STATE]
  const { account, phone } = components

  if (!get(phone) && !get(account)) {
    setHelperText(phone, await translate({
      _key: 'label.phoneOrEmailRequired',
      defaultValue: 'One of phone or email is required',
    }))
    setError(phone)

    if (!modelState[G.ERROR]?.account) {
      setHelperText(account, await translate({
        _key: 'label.emailOrPhoneRequired',
        defaultValue: 'One of email or phone is required',
      }))
      setError(account, true)
    }
    setKey(modelState[G.ERROR] || true, G.ERROR, modelState)
  }

  return [components, ...args]
}

/**
 * If the requestTypeForm and the requestForm are visible, validates them against the model. If the
 * validation results to be valid, adds the attachments and sets the current user as the submitter.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function}
 */
const validateDescription = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const modelState = model[G.STATE]
  const { description, submitter } = model[G.CHILDREN]
  const { requestForm, description: descriptionForm } = components

  deleteKey(G.REF, submitter[G.STATE])

  if (!hidden(requestForm)) {
    await validate(description)(descriptionForm)

    if (!modelState[G.ERROR] && !description[G.STATE][G.ERROR]) {
      const userKey = session(module).user.key()
      // setting attachments as base64 data to the model and validating request form
      // setting ids to request and its attributes
      // setKey(uuidV4(), G.REF, modelState)
      setKey(userKey, G.REF, submitter[G.STATE])
      // setting request to be placed at the root of the bulk request's payload
      // setKey(true, G.BULK, modelState)
      // setting initial model status
      setData(model, { status: 10, statusReason: -1 })
    }
  }

  return [components, ...args]
}

/**
 * Validates the device's form against the model's item if its state doesn't contain an error. If
 * the deviceInstalledAt form is visible, it also validates it against the model's itemInstalledAt,
 * otherwise, if the device form is visible, sets the model's requesterOrg as the model's
 * itemInstalledAt.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function}
 */
const validateItem = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg, item, itemData, equipment, product } = model[G.CHILDREN]
  const { itemInstalledAt, itemServiceBy } = model[G.CHILDREN]
  const { deviceForm, device, deviceInstalledAt } = components
  const requesterOrgRef = requesterOrg[G.STATE][G.REF]

  if (!hidden(deviceForm)) {
    const previousItemError = item[G.STATE][G.ERROR]
    !previousItemError && await validate(item)(device)
    item[G.STATE][G.ERROR] ||= previousItemError

    reset(itemInstalledAt)

    // if device location isn't organisation's address
    if (!hidden(deviceInstalledAt)) {
      // validating device's organisation
      await validate(itemInstalledAt)(deviceInstalledAt)
      // if a user was found
      model[G.STATE][G.USER]
        // setting device's organisation to be placed at the root of the bulk request's payload
        && setKey(true, G.BULK, itemInstalledAt[G.STATE])
        // setting new id for device's organisation
        && setKey(uuidV4(), G.REF, itemInstalledAt[G.STATE])
      setData(itemInstalledAt, { parent: [requesterOrgRef], status: 50, type: 'customer' })
    } else if (!hidden(device)) {
      // setting organisation's id for device's organisation
      setKey(requesterOrgRef, G.REF, itemInstalledAt[G.STATE])
    }
  } else {
    deleteKey(G.ERROR, item[G.STATE])
    reset(item)
    reset(itemData)
    reset(equipment)
    reset(product)
    reset(itemInstalledAt)
    reset(itemServiceBy)
  }

  return [components, ...args]
}

/**
 * Validates the type form against the model's type.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function}
 */
const validateRequestType = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const modelState = model[G.STATE]
  const { type } = model[G.CHILDREN]
  const { requestTypeForm, requestType: requestTypeRadio, requestTypeSelect } = components
  const requestType = requestTypeSelect && settings.registerRequestTypesDropdown
    ? requestTypeSelect
    : requestTypeRadio
  const previousError = modelState[G.ERROR]

  // if we reset it, we loose the item's information
  // reset(request)
  deleteKey(G.REF, modelState)
  setKey({}, G.STATE, type)

  if (!hidden(requestTypeForm)) {
    await validate(model)(requestType)
    setKey(modelState[G.ERROR], G.ERROR, type[G.STATE])
    setKey(previousError, G.ERROR, modelState)
  }

  return [components, ...args]
}

/**
 * Assigns the model's requesterOrg as the organisation of the model's requesterContact if none of
 * them is in error state.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function}
 */
const assignRequesterContactOrg = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg, requesterContact, requesterContactOrg } = model[G.CHILDREN]
  const { organisation: contactOrg } = requesterContact[G.CHILDREN]

  reset(contactOrg)

  !requesterContact[G.STATE][G.ERROR] && !requesterOrg[G.STATE][G.ERROR]
    && setKey(requesterOrg[G.STATE][G.REF], G.REF, requesterContactOrg[G.STATE])
    && setKey(requesterOrg[G.STATE][G.REF], G.REF, contactOrg[G.STATE])

  return [components, ...args]
}

/**
 * Validates the organisation form against the model's requesterOrg.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function}
 */
export const validateRequesterOrg = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg } = model[G.CHILDREN]
  const requesterOrgState = requesterOrg[G.STATE]
  const { organisationType, organisationForm, organisation } = components

  deleteKey(G.ERROR, requesterOrgState)

  if (!hidden(organisationForm)) {
    reset(requesterOrg)

    await validate(requesterOrg)(organisation)
    const validationError = requesterOrgState[G.ERROR]
    await validate(requesterOrg)(organisationType)
    requesterOrgState[G.ERROR] ||= validationError

    !requesterOrgState[G.ERROR]
      && setData(requesterOrg, { status: 50 })
  }

  return [components, ...args]
}

/**
 * Validates the person form against the model's requesterContact.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function}
 */
export const validateRequesterContact = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact } = model[G.CHILDREN]
  const requesterContactState = requesterContact[G.STATE]
  const { contactChannels } = requesterContact[G.CHILDREN]
  const contactChannelsState = contactChannels[G.STATE]
  const { personForm, person } = components

  deleteKey(G.ERROR, requesterContactState)
  deleteKey(G.ERROR, contactChannelsState)

  if (!hidden(personForm)) {
    reset(requesterContact)

    await validate(requesterContact)(person)
    await validate(contactChannels)(person)

    requesterContactState[G.ERROR] ||= contactChannelsState[G.ERROR]

    !requesterContactState[G.ERROR]
      && setData(requesterContact, { status: 50 })
      && setData(contactChannels, { email: model[G.DATA].account })
  }

  return [components, ...args]
}

/**
 * Validates the account field against the model's account if its state doesn't contain an error.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function}
 */
export const validateAccount = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const modelState = model[G.STATE]
  const validator = model[G.VALIDATOR].account
  const { user: userForm } = components

  if (!modelState[G.ERROR]?.account) {
    disable(validator)
    await validate(model)(userForm)
    enable(validator)
  }

  return [components, ...args]
}

/**
 * Validates visible forms and deduces the data used to create the bulk request's payload.
 *
 * This is a pure business logic action, it has no UI.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 */
export default module => () => async (...args) => asyncPipeSpread(
  validateAccount(module)(),
  validateRequesterContact(module)(),
  validateRequesterOrg(module)(),
  assignRequesterContactOrg(module)(),
  validateRequestType(module)(),
  validateItem(module)(),
  validateDescription(module)(),
  checkAccountOrPhone(module)(),
  updateModuleState(module)(),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
