/* global G */
import { curry } from 'lib/util'
import statusReason from 'model/ticket/collection/statusReason'

/**
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent|string} eventOrKey
 * @return {Array} countries
 */
const listStatusReasons = (module, component, eventOrKey) => {
  const key = eventOrKey?.detail?.key || eventOrKey
  const scope = key ? statusReason.filter(item => item.key === key) : statusReason

  return scope.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `statusReason.${item.key}`,
      {
        ns: 'ticket',
        _key: `statusReason.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }))
}

export default curry(listStatusReasons)
