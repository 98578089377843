import { curry } from 'lib/util'
import count from 'app/_shared/events/search/count'
import search from 'app/_shared/events/search'

/**
 * Get the count of viewable entity and the entity itself
 * in case the count is 1.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 *
 * @return {Object}                     the count and optionally a location
 */
const countFn = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const items = await count(null, module, component, event)

  if (items.total.count !== 1) return { total: { count: items.total.count } }
  const item = await search(null, module, component, event)

  return {
    total: { count: items.total.count },
    item: {
      title: item[0]?.value?.name,
      key: item[0]?.key,
    },
  }
}

export default curry(countFn)
