/* eslint-disable no-plusplus */
import { curry } from 'lib/util'

/**
 * Returns a sublist of {@param list} that contains all elements whose key is found in
 * {@param keys}. The order of the returned elements is defined also by the order of {@param keys}.
 *
 * @param {string[]|number[]} keys  an  array containing the ids of the elements returned from
 *                                  {@param list} to be returned
 * @param {function} list           a function that returns a list of elements
 * @param {boolean} [includeBlank]  whether to add an empty option as the first element
 * @returns {*}
 */
const sublist = (keys, list, includeBlank) => curry((module, component, event) => {
  const status = list(module, component, event)
  const blankOption = includeBlank ? [{ key: '' }] : []
  let x = 0

  return blankOption.concat(status.filter(item => keys.includes(item.key)).sort((a, b) => {
    const key = keys[x++]
    const bKeyCheck = b.key === key ? 1 : 0
    return a.key === key ? -1 : bKeyCheck
  }))
})

export default sublist
