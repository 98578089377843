/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread, getFirstItem, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { setData } from 'lib/sequence/model/api/set'

/**
 * Sets the {@param module}'s state as error if any of the model's sub-models or the model itself
 * has error state.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const updateModuleState = module => async (components, ...args) => {
  const [model] = args

  const moduleState = module[G.STATE]
  const { generic } = model[G.CHILDREN]

  const modelError = model[G.STATE][G.ERROR]
  const genericError = generic[G.STATE][G.ERROR]

  moduleState[G.ERROR] = modelError || genericError

  return [components, ...args]
}

/**
 * Sets generic fields based on validation.
 * Uses {@code documentationId} in args passed from redirect handler
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setGenericFields = module => async (components, ...args) => {
  const [model, documentationId] = args
  const { generic, document } = model[G.CHILDREN]
  const { statisticalData, submitter } = generic[G.CHILDREN]

  const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]
  const person = getFirstItem(sessionState[G.META]?.refs?.person) || {}
  const personKey = person?.key

  setKey(personKey, G.REF, submitter[G.STATE])

  if (!module[G.STATE][G.ERROR]) {
    setData(generic, {
      status: 10,
      submitTimestamp: new Date().toISOString(),
    })
    setData(statisticalData, {
      type: model[G.CONFIGURATION]?.options?.type || 'generic',
      lang: sessionState[G.LANGUAGE],
    })
    document[G.STATE] = { [G.REF]: documentationId || null }
  }

  return args
}

/**
 * Feedback Submit Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  updateModuleState(module),
  setGenericFields(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
