/* global G */
import { curry } from 'lib/util'
import reset from 'app/_shared/events/model/reset'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

/**
 * Instructs the Cart adapter to delete the current cart.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 * @returns {Promise<boolean>}
 */
const discardCart = async (module, component, event) => {
  const { [G.EVENTS]: eventBus } = module[G.ADAPTER]

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { cart: cartComponent } = sequenceComponentFindProxy(actionComponent)

  const confirmed = await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.CART, G.DONE), ({ detail }) => {
      const { [G.DATA]: cart } = detail
      if (cart === null) resolve(true)
    }, { once: true })
    eventBus.dispatch(eventBus.type(G.CART, G.DELETE))
  })

  if (confirmed) {
    await reset(null)(module, component, event)
    cartComponent[G.STATE] = {} // Resetting the cart state, so activeStep gets set again.

    return true
  }

  return false
}

export default curry(discardCart)
