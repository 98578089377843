/* eslint-disable no-param-reassign */
/* global G */
import { curry } from 'lib/util'
import search from 'app/_shared/events/search'

/**
 * Event Handler Search Exclude
 *
 * Performs a {@link search} and attempts to exclude the element from the result that has the same
 * id as the model's attribute with name {@param excludedModelName}. If {@param excludedModelName}
 * is not set, the model's id will be used.
 *
 * @param {string|null} [searchKey='default'] listing type, ie short, verbose, etc
 * @param {string} [excludedModelName]        the name of the model's attribute to attempt to
 *                                            exclude from the result
 */
const searchExclude = (searchKey, excludedModelName) => curry(async (module, component, event) => {
  const model = module[G.MODEL]
  const child = excludedModelName ? model[G.CHILDREN][excludedModelName] : model
  const result = await search(searchKey, module, component, event)
  const key = child[G.STATE][G.REF]
  return key ? result.filter(obj => obj.key !== key) : result
})

export default searchExclude
