/* eslint-disable implicit-arrow-linebreak,indent,no-unused-expressions */
/* global PLATFORM */
import { asyncPipeSpread, isArr } from 'lib/util'

/**
 * Platform Hook
 *
 * Executes hooks assigned to the property of {@param platforms} that has the same name as the
 * current platform.
 *
 * @example
 * const _indexHooks = {
 *   after: [
 *    ui,
 *    platform({
 *      web: [
 *        recreate,
 *      ],
 *    }),
 *  ]
 * }
 *
 * @param {object} platforms an object consisting of properties with platform names
 *                           each with a reference to the event handler that should be
 *                           executed on that platform.
 * @returns {function(*): function(...[*]): Promise<*|*[]>}
 */
export default platforms => obj => async (...args) => {
  const hooks = platforms[PLATFORM]
  const hooksList = isArr(hooks) ? hooks : [hooks]
  return hooks ? await asyncPipeSpread(...hooksList.map(hook => hook(obj)))(...args) : args
}
