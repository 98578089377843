/* global G */
import { asyncPipeSpread } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { get, reset, set } from 'lib/sequence/component/state/value'
import asObject from 'lib/sequence/component/children/asObject'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import { isAction } from 'app/_shared/action/util'
import listUserRoles from 'app/_shared/events/collection/listUserRoles'

/**
 * Applies the selected filters and adds them to the {@code G.META} object of the lists' state.
 * It will after that trigger a remount of the list so that the new search is executed.
 *
 * A currently typed in search term in the field will also be regarded.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const applyAdminFilters = module => () => async (components, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const { list, filter } = components
  const { role, atOrganisation } = asObject(filter[G.CHILDREN])

  if (isAction(module, 'usersNew')) {
    const requesterRole = listUserRoles(module, actionComponent, { detail: { key: 'Requester' } })
    set(role, requesterRole)
    disable(role)
  }

  const roleField = get(role)

  if (roleField?.length) {
    // We can't search for "*@ORG:1", server will crash. Therefore, we need role in order to
    // search for atOrganisation
    enable(atOrganisation)
  } else {
    disable(atOrganisation)
    reset(atOrganisation)
  }

  const meta = list[G.STATE][G.META] || {}
  const currentSearchTerm = list[G.STATE]?.term || null

  const {
    query: queryHandler,
    filter: filterHandler,
  } = actionComponent[G.EVENTS]
  const query = queryHandler({ detail: { value: currentSearchTerm } })
  const filters = filterHandler(null)

  list[G.STATE][G.META] = { ...meta, query, filter: filters }

  // temporary solution for suspended memoized list component
  // recreate ui, instead of update ui
  await module[G.ADAPTER][G.UI].create(module)

  return [components, ...args]
}

/**
 * Shows a filterable list of users.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 */
export default module => () => async (...args) => asyncPipeSpread(
  applyAdminFilters(module)(),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
