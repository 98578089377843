import notEmptyAttribute from '@model/_shared/validator/notEmptyAttribute'
import isInteger from '@model/_shared/validator/isInteger'

export const validators = {
  name: notEmptyAttribute,
  organisation: notEmptyAttribute,
  sortOrder: isInteger,
  ticketType: notEmptyAttribute,
  timezone: notEmptyAttribute,
  fromTime: notEmptyAttribute,
  toTime: notEmptyAttribute,
  defaultRole: notEmptyAttribute,
  status: notEmptyAttribute,
}

export {
  validators as default,
  notEmptyAttribute,
  isInteger,
}
