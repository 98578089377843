/**
 *  Software Collection.
 *
 * Contains static list of available software programs, which can be used for ticket
 * and request wizard.
 */
export default [
  {
    key: 'MediConnect',
    value: 'MediConnect',
  },
  {
    key: 'MediHost',
    value: 'MediHost',
  },
  {
    key: 'MediView',
    value: 'MediView',
  },
  {
    key: 'MediControl',
    value: 'MediControl',
  },
  {
    key: 'other',
    value: 'Other',
  },
]
