/* global G */
import { curry } from 'lib/util'

/**
 * ShowSelectedDeviceOnlyToast event handler
 *
 * Dispatches an event in order to display a toast informing the user that a device has been
 * selected, but that they must still select an organisation.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
export default curry(async (module, component, event) => {
  const _key = 'text.selectedDeviceOnly'
  module[G.ADAPTER][G.EVENTS].dispatch(module[G.ADAPTER][G.EVENTS].type(G.DATA, G.UPDATE), {
    message: await module[G.ADAPTER][G.INTL]._t(_key, {
      ns: 'device',
      _key,
      defaultValue: 'Device has been selected, please add an organization!',
    }),
    severity: 'warning',
    variant: 'filled',
    duration: 10000,
    close: true,
  })
})
