/* global G */
import { curry, isArr } from 'lib/util'

/**
 * If {@param item} is not an array, it adds it to one; if it is undefined, it returns an empty
 * array.
 *
 * @param {any} item
 * @returns {array}
 */
const wrap = item => (item ? (isArr(item) && item) || [item] : [])

/**
 * Recursively nests a property for each part of the {@param component}'s path in the action's
 * cache. Then, stores the {@param event}'s detail as a {@code _cache} object in the deepest object
 * created inside cache with the component's path. It results in an object tree that represents the
 * paths of component's keys to the components whose state was altered.
 *
 * @type {Gaia.AppModule.EventHandler}  event handler
 */
const remember = async (module, component, event) => {
  const actionData = module[G.STATE][G.ACTION][G.DATA]
  const componentProps = component[G.PROPS]
  const path = componentProps[G.ROUTE]
  const { limit } = componentProps
  const { detail } = event
  const details = { ...detail.item ? { value: wrap(detail.item) } : detail }
  const props = { ...details } // breaking references
  const timestamp = new Date()

  // we don't want to cache list data that only contains a single page
  const shouldCache = !props.data || (props.data.length > limit)

  const target = path.reduce((acc, item) => {
    acc[item] = { ...acc[item] }
    return acc[item]
  }, actionData)

  shouldCache
    ? target._cache = { props, timestamp }
    : delete target._cache
}

export default curry(remember)
