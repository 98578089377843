/* global G */
import { curry } from '@gaia/util'

const descriptor = 'model::transformer::fromChildRef'

/**
 * Model Attribute Value Getter from Referenced Entity
 *
 * Reads key value from complex structure, based on parameters
 *
 * It assumes a ref relation of 1-N.
 *
 * todo: this puts CACHE directly into payload, no modifications!
 *
 * @param {Gaia.Model.Spec} model - data model
 * @param options - configuration options
 */
// const fromChildRef = (ref, type, key, position, obj, data) => {
const fromChildRef = (key, obj, data) => {
  const _data = data
  try {
    // console.clear()
    console.log(descriptor, obj._name, key, data)
    // console.log('transformer::fromChildRef obj', obj)
    // console.log('transformer::fromChildRef data', data)
    const item = obj[G.CHILDREN][key]
    const { type } = item[G.PROPS]

    _data[type][key] = item[G.CACHE]
  } catch (e) {
    console.warn(descriptor, e.message)
  }
  return _data
}

export default curry(fromChildRef)
