/* eslint-disable no-unused-vars */
import { curry } from 'lib/util'
import concatQueryParams from 'app/_shared/events/search/query/util'

/**
 * Returns a search query that filters by multiple IDs and an optional type.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event|object} event              information about the event that triggered this handler
 * @return {`${string|string}`}
 */
const hasMultipleIdsAndType = (module, component, event) => {
  const { type, ids } = event?.detail || event || {}
  const params = {
    ...type && { type: `metatype:${type}` },
    ids: `${type ? 'AND ' : ''}id:(${ids.map(id => `"${id}"`).join(' ')})`,
  }

  return concatQueryParams(params)
}

export default curry(hasMultipleIdsAndType)
