/* global G */
import { modelComposition } from '@gaia/trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import withGetterSetter from 'trait/with/GetterSetter'
import toContactChannels from 'model/_shared/transformer/toContactChannels'
import fromContactChannels from 'model/_shared/decorator/fromContactChannels'
import fromAddress from 'model/_shared/decorator/fromAddress'
import toAddress from 'model/_shared/transformer/toAddress'
import toRef from 'model/_shared/transformer/toRef'
import toFirstKey from 'model/_shared/transformer/toFirstKey'
import toToBeValidated from 'model/_shared/transformer/toToBeValidated'
import toStatus from 'model/_shared/transformer/toStatus'
import toBoolean from 'model/_shared/transformer/toBoolean'
import toSupport from 'model/organisation/transformer/toSupport'
import validator from './validator'

const descriptor = 'model::organisation'

const Model = pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    contactChannels: toContactChannels('contactChannels'),
    address: toAddress('address'),
    parent: toRef('parent'),
    supportedBy: toRef('supportedBy'),
    type: toFirstKey('type'),
    toBeValidated: toToBeValidated('toBeValidated'),
    duplicateOf: toRef('duplicateOf'),
    status: toStatus('status'),
    support: toSupport('support'),
    mergeToDuplicateTarget: toBoolean('mergeToDuplicateTarget'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    contactChannels: fromContactChannels('contactChannels'),
    address: fromAddress('address'),
  }),
)

export default Model
