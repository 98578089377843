/* global G */
import { curry } from 'lib/util'
import team from 'model/ticket/collection/filter/team'

/**
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent} event - event
 * @return {Array} countries
 */
// eslint-disable-next-line no-unused-vars
const listTicketFilterTeam = (module, component, event) => team.map(item => ({
  ...item,
  value: module[G.ADAPTER][G.INTL]._t(
    `filter.team.${item.key}`,
    { ns: 'ticket', _key: `filter.team.${item.key}`, defaultValue: item.value },
  ),
}))

export default curry(listTicketFilterTeam)
