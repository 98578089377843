/* global G */
import { setKey } from 'lib/util'
import { setData } from 'lib/sequence/model/api/set'

/**
 * inviteColleague Action
 *
 * @param {Gaia.AppModule.Spec} module
 *
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
const colleague = module => () => async (...args) => {
  const model = module[G.MODEL]
  const { contactChannels } = model[G.CHILDREN]
  const { email } = contactChannels[G.CHILDREN]

  /**
   * We need to set at least one property on the invite model. Otherwise, it won't be included in
   * the payload. {@code status} is needed in any case so let's set it here.
   */
  setData(model, { invite: { status: 'to_be_invited' } })

  // TODO: Simplify after GAIA-1048
  const notExistsValidator = contactChannels[G.CONFIGURATION].validator?.email
  const notEmptyValidator = email[G.CONFIGURATION]?.validator?.find(x => x.type === 'notEmpty')

  // Enabling additional validators for email field.
  setKey(false, 'disabled', notExistsValidator.options)
  setKey(false, 'disabled', notEmptyValidator.options)

  return args
}

export default colleague
