/* global G */
import { pipe } from 'lib/util'
import appModuleComposition from 'trait/composition/appmodule'
import { usesGetterSetter } from 'trait/uses'
import { canHookInto } from 'trait/can'
import actions from './actions'
import hooks from './hooks'
import events from './events'

const descriptor = 'module::ticket'

const composition = pipe(
  appModuleComposition(descriptor),
  usesGetterSetter(G.ACTIONS, actions),
  usesGetterSetter(G.EVENTS, events),
  canHookInto(G.ACTIONS, hooks),
)

export default composition
