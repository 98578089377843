/* global G */
import transform from 'lib/sequence/model/transformer'
import create from 'lib/sequence/model/api/create'

/**
 * Team Action Submit Contact
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
const submitContact = module => () => async (...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const model = module[G.MODEL]
  const validationError = model[G.STATE][G.ERROR]
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = !!validationError

  if (validationError) return args

  /**
   * Correctly set {@code [G.STATE][G.DATA]} in model.
   */
  create(model)
  transform(model)

  try {
    const { api: { version, type } } = actionComponent[G.PROPS]

    const httpAdapter = model[G.ADAPTER][G.HTTP]
    const modelData = model[G.STATE][G.DATA]

    const inviteUrl = `/api/v${version}/${type}`
    const params = {
      value: {
        contactChannels: modelData.value.contactChannels,
        invite: {
          status: 'to_be_invited',
          ctx: {
            team: modelData.key,
            role: modelData.value?.defaultRole,
          },
        },
      },
    }
    await httpAdapter.post({ url: inviteUrl, params })
  } catch (e) {
    console.error(e)
  }

  return args
}

export default submitContact
