/* global G */
import { curry, setKey } from '@gaia/util'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'

/**
 * Redirects to the current module's action with name {@param name}. It also tries to set
 * {@param event}'s detail key as the current model's key.
 *
 * @type {Gaia.AppModule.EventHandler}
 * @param {string} name             the name of the action to redirect to
 */
export default curry(async (name, module, component, event) => {
  try {
    const { key } = event.detail
    const moduleState = module[G.STATE]
    moduleState[G.ROUTE] = routeComposition(null, name)
    key && setKey(key, G.REF, module[G.MODEL][G.STATE])
    await redirectSequence(module)(event.detail)
  } catch (e) {
    console.error(e)
  }
  return event
})
