/* eslint-disable no-unused-vars */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import map from 'lib/sequence/model/api/map'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { get, reset } from 'lib/sequence/component/state/value'
import { checked, uncheck } from 'lib/sequence/component/state/checked'
import { translate } from 'app/ticket/action/step/device'
import { asyncPipeSpread, asyncPipeSpreadIf } from 'lib/util'
import { CONFIRM } from 'app/_shared/events/setStepTab'
import find from 'lib/sequence/component/children/find'
import sequenceComponentState from 'lib/sequence/component/state'
import { displayLabels } from './organisation'

const {
  get: activeTab,
} = sequenceComponentState('activeTab')

/**
 * Returns the IDs of the organisations that have to be offered to the user as suggestion, according
 * to the previously selected items.
 *
 * @param {Gaia.Model} model  the current ticket model
 * @returns {string[]}        an array containing the IDs of the objects to suggest
 */
const getSuggestionIds = (model) => {
  const ids = []
  const { requesterOrg, item } = model[G.CHILDREN]
  const { supportedBy } = requesterOrg[G.CHILDREN]
  const { installedAt, serviceBy } = item[G.CHILDREN]
  const requesterOrgId = requesterOrg[G.STATE][G.REF]
  const supportedById = supportedBy[G.STATE]?.[G.REF]
  const installedAtId = installedAt[G.STATE]?.[G.REF]
  const serviceById = serviceBy[G.STATE]?.[G.REF]

  installedAtId && ids.push(installedAtId)
  serviceById && ids.push(serviceById)
  supportedById && ids.push(supportedById)

  return ids.reduce((acc, id) => {
    (!requesterOrgId || id !== requesterOrgId) && !acc.includes(id) && acc.push(id)
    return acc
  }, [])
}

/**
 * Returns whether the checkbox "Register as installed at" from the Device step is
 * checked.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {boolean}                         whether the device is marked to be registered at the
 *                                            selected organisation
 * @private
 */
const _checkedRegisterAtRequesterOrg = (module) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { device } = find(actionComponent)
  const { registerInstalledAt } = find(device)
  return !!checked(registerInstalledAt)
}

/**
 * Toggles the display of the list of search results or the list of suggestions depending on whether
 * the search field has any value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayLists = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { searchField, list, suggestions } = components

  if (!get(searchField)) {
    const ids = getSuggestionIds(model)
    suggestions[G.STATE][G.REF] = ids
    ids.length ? show(suggestions) : hide(suggestions)
    hide(list)
  } else {
    hide(suggestions)
    show(list)
  }

  return [components, ...args]
}

/**
 * Unchecks the informationTransferApproved field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const uncheckInformationTransferApprovedField = module => async (components, ...args) => {
  const { informationTransferApproved } = components
  // unchecking information transfer approved checkbox
  uncheck(informationTransferApproved)

  return [components, ...args]
}

/**
 * Clears the current value of the contactDetails field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const resetContactDetailsField = module => async (components, ...args) => {
  const { contactDetails } = components
  // resetting contact details field
  reset(contactDetails)

  return [components, ...args]
}

/**
 * If the model's additionalParty attribute has a cache value, marks the step as completed and sets
 * the attribute's name and address to be shown as the step title and subtitle, otherwise sets the
 * step as non-completed and non-confirmed and clears its title and subtitle.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setStepState = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { additionalParty } = model[G.CHILDREN]
  const { component: step } = components
  const stepState = step[G.STATE]

  if (additionalParty[G.CACHE]) {
    // setting step as complete
    stepState.completed = true
    // filling step's title and subtitle with model data
    stepState.title = additionalParty[G.CHILDREN].name[G.CACHE]
    stepState.subtitle = additionalParty[G.CHILDREN].address[G.CHILDREN].summary[G.CACHE]
  } else {
    // setting step as incomplete and unconfirmed
    stepState.completed = false
    stepState.confirmed = false
    // clearing step's title and subtitle
    stepState.title = ''
    stepState.subtitle = ''
  }

  return [components, ...args]
}

/**
 * Toggles the display of informative labels according to the current model values.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
export const displayPartyLabels = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { additionalParty } = model[G.CHILDREN]
  const { labels } = components
  const { waitingForApproval, notInMasterData, blocked } = asObject(labels[G.CHILDREN])

  displayLabels(model, additionalParty, labels);

  (!hidden(waitingForApproval)
    || !hidden(blocked))
  && hide(notInMasterData)

  return [components, ...args]
}

/**
 * Checks whether there is any item set and whether it is not marked to be registered at
 * requesterOrg and, only in that case, displays a checkbox to register the item at the
 * additionalParty.
 *
 * @param module
 * @returns {(function(*, ...[*]): Promise<void>)|*}
 */
const displayRegisterInstalledAtField = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { item, additionalParty } = model[G.CHILDREN]
  const { registerInstalledAt } = components

  hide(registerInstalledAt)

  if (item[G.CACHE]) {
    const { equipment } = item[G.CHILDREN]
    const additionalPartyRef = additionalParty[G.STATE][G.REF]
    const { installedAt } = item[G.CHILDREN]
    const installedAtRef = installedAt[G.STATE][G.REF]
    const equipmentCache = equipment[G.CACHE]
    const installedAtParty = installedAtRef === additionalPartyRef
    const registerAtRequesterOrg = _checkedRegisterAtRequesterOrg(module)
    const canBeInstalledAt = additionalPartyRef && equipmentCache && !registerAtRequesterOrg
      && !installedAtParty
    const organisation = additionalParty[G.CHILDREN].name[G.CACHE]
    await translate(module, registerInstalledAt, { organisation })
    canBeInstalledAt && show(registerInstalledAt)
  }

  return [components, ...args]
}

/**
 * Maps the current model's additionalParty attribute to the step's confirm view.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapAdditionalParty = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { additionalParty } = model[G.CHILDREN]
  const { confirm } = components
  const { party } = find(confirm)

  // mapping selected model to the view
  map(additionalParty)(party)

  return [components, ...args]
}

/**
 * Additional Party identification step.
 *
 * If no additional party is selected, displays a list to search among them and a button to add a
 * new one. Otherwise, sets the step as complete and displays the selected additional party's data
 * together with buttons to edit it, to clear the selection and to go to the next step.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => await asyncPipeSpread(
  asyncPipeSpreadIf(() => activeTab(component) === CONFIRM)(
    mapAdditionalParty(module),
    displayRegisterInstalledAtField(module),
    displayPartyLabels(module),
  ),
  asyncPipeSpreadIf(() => activeTab(component) !== CONFIRM)(
    resetContactDetailsField(module),
    uncheckInformationTransferApprovedField(module),
    displayLists(module),
  ),
  setStepState(module),
)(find(component), ...args)
