/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Change Language Event Handler
 *
 * Sets the current language from {@param event}'s details.
 * @type {Gaia.AppModule.EventHandler}.
 */
export default curry(async (module, component, event) => {
  const { item: language } = event.detail
  await module[G.ADAPTER][G.INTL].set(language.key)
  module[G.ADAPTER][G.UI].create(module)
})
