/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import formatValue from 'app/_shared/component/formatValue'

export default module => component => async (...args) => {
  const { account } = module[G.MODEL][G.DATA]
  const { msgConfirmMD } = asObject(component[G.CHILDREN])

  await formatValue(module, msgConfirmMD, { account }, 'text')

  return args
}
