/* global G */
import { asyncPipeSpread, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'

/**
 * Sets the components {@code attachment} prop to the incoming value.
 *
 * @param {Gaia.Component.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setAttachmentGroup = module => component => async (children, ...args) => {
  const { detail } = args[0]

  setKey(detail, 'attachment', component[G.STATE])

  return args
}

export default module => component => async (...args) => asyncPipeSpread(
  setAttachmentGroup(module)(component),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
