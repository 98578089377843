/* global G */
import { curry } from 'lib/util'
import assignee from 'model/ticket/collection/filter/assignee'

/**
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent} event - event
 * @return {Array} countries
 */
// eslint-disable-next-line no-unused-vars
const listTicketFilterAssignee = (module, component, event) => {
  return assignee.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `filter.assignee.${item.key}`,
      { ns: 'ticket', _key: `filter.assignee.${item.key}`, defaultValue: item.value },
    ),
  }))
}

export default curry(listTicketFilterAssignee)
