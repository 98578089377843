/* global G */
import { curry, setKey } from 'lib/util'

/**
 * throws error
 *
 * @return {*}
 * @private
 */
const _throw = () => throw Error('wrong decorator usage, make sure mapping is correct')

/**
 * adds count property to component's state stack
 *
 * responds to t: { [property]: { count: 1 }} inside json5 ui config
 *
 * @param {string} target - model.attribute as target, used only for mapping on the relevant component
 * @param {string} key - model.attribute as source
 * @param {Gaia.Model.Spec} obj
 * @param {Gaia.Component.Spec} component
 */
// eslint-disable-next-line no-unused-vars
const fn = (target, key, obj, component) => {
  const source = obj[G.CHILDREN][key]

  const value = source[G.CACHE] && source[G.CACHE].count

  !value && _throw()

  setKey(value, 'count', component[G.STATE])

  return component
}

export default curry(fn)
