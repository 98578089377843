/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { actionFn } from 'lib/sequence/module/action'
import cancelOnError from 'lib/sequence/model/cancelOnError'

const descriptor = 'setStepTab'

export const SELECT = 'select'
export const SEARCH = 'search'
export const LIST = 'list'
export const CONFIRM = 'confirm'

/**
 * Sets the activeTab state of a tabbed step.
 *
 * @param {string} tab                    the mode to set to the current step's state
 * @param {string} [step]                 the step whose tab should be changed. Defaults to the
 *                                        current step
 * @return {Gaia.AppModule.EventHandler}  event handler
 */
// eslint-disable-next-line no-unused-vars
const setStepTab = (tab, step) => curry(async (module, component, event) => {
  cancelOnError(descriptor, module)
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const stepperState = stepper[G.STATE]
  const { [step || stepperState.currentStep]: stepComponent } = asObject(stepper[G.CHILDREN])
  const stepState = stepComponent[G.STATE]
  stepState.activeTab = tab
  await actionFn(module[G.STATE][G.ACTION])()
  module[G.ADAPTER][G.UI].update(module)
})

export default setStepTab
