/* global G */

/**
 *
 * @param module
 * @param component
 * @param event
 * @returns {Promise<void>}
 */
const edit = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const eventBus = module[G.ADAPTER][G.EVENTS]
  const { key, text } = event.detail

  eventBus.dispatch(
    eventBus.type(G.MESSAGE, G.UPDATE),
    {
      [G.DATA]: {
        key,
        text,
      },
    },
  )
}

export default edit
