/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Reloads the action of the active tab.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>} void
 */
const reloadTab = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { tabs } = asObject(actionComponent[G.CHILDREN])
  const tabsState = tabs[G.STATE]

  /**
   * We need {@code actionWithHooks} here instead of {@actionFn}
   * because we may need to do additional things, like
   * validation per tab.
   */
  await actionWithHooks(module[G.ACTIONS][tabsState.activeTab])([])
}

export default curry(reloadTab)
