/* global G */
import { curry } from 'lib/util'

/**
 * Concat Decorator
 *
 * Calls the decorators of all attributes listed in {@param keys}, joins all their G.CACHE values
 * with a whitespace in between and sets the result as the G.CACHE of the attribute defined by
 * {@param ref}.
 *
 * @param {string} ref          the name of the attribute to set the joined values to
 * @param {string[]} keys       the name of the attributes whose values have to be joined
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {object} component    the component that matched the attribute name during the mapping
 */
const fn = (ref, keys, obj, component) => {
  const attribute = obj[G.CHILDREN][ref]

  const values = keys.reduce((acc, key) => {
    const decorator = obj[G.DECORATOR][key]
    const item = obj[G.CHILDREN][key]
    decorator && decorator(obj, component, component[G.STATE].value)
    const cache = item[G.CACHE]
    const value = (cache && cache[0] && cache[0].value) || cache
    value && acc.push(value)
    return acc
  }, [])

  attribute[G.CACHE] = values.join(' ')
  return component
}

export default curry(fn)
