/* eslint-disable no-unused-vars,max-len */
/* global G */
import { asyncPipeSpread, asyncPipeSpreadIf, getFirstItem, setKey } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import session from 'app/_shared/session'
import { get } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import { set as setToken } from 'app/_shared/component/token'
import showDialog from 'app/_shared/events/dialog'
import { back } from 'app/_shared/events'

/**
 * Shows an error dialog depending on what the server responded.
 *
 * @param {string} type                   the type of error
 * @param {Gaia.AppModule.Spec} module    current module composition
 * @param {Gaia.Component.Spec} component action component
 * @returns {Promise<*>}
 * @private
 */
const _errorDialog = async (type, module, component) => await showDialog(module, component, {
  title: { ns: 'cart', key: `dialog.error.webshop.${type}.title` },
  text: { ns: 'cart', key: `dialog.error.webshop.${type}.text` },
  ok: true,
  cancel: false,
})

/**
 * Determines whether the {@code salesOrganisationSection} should be shown.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
const displaySalesOrganisations = module => async (children, ...args) => {
  const { user } = session(module)
  const salesOrganisations = user.ref()?.value?.rationalUser.user.combinations || ''

  const { component, salesOrganisation, salesOrganisationSection, transferring } = children
  const { btnInitTransfer } = sequenceComponentFind(component[G.ACTIONS][0])

  enable(salesOrganisation)
  enable(btnInitTransfer)
  hide(transferring)

  if (salesOrganisations.length > 1) {
    show(btnInitTransfer)
    show(salesOrganisationSection)
    !get(salesOrganisation) && disable(btnInitTransfer)
  } else {
    setKey(true, 'init', module[G.STATE])
  }

  return [children, ...args]
}

/**
 * Will hide the {@code rejectedArticlesSection}.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
const hideRejectedArticlesSection = module => async (children, ...args) => {
  const { rejectedArticlesSection } = children

  hide(rejectedArticlesSection)

  return [children, ...args]
}

/**
 * Gets the current cart from the cart adapter and sets the model to it.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
const transferToWebshop = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const { version } = model[G.PROPS]

  const { [G.EVENTS]: eventBus } = module[G.ADAPTER]

  // component
  const { component, salesOrganisation, salesOrganisationSection, transferring } = children
  const { btnInitTransfer } = sequenceComponentFind(component[G.ACTIONS][0])
  const { user } = session(module)
  const salesOrganisations = user.ref()?.value?.rationalUser.user.combinations
    .map(x => ({
      salesOrganisation: x.salesorganisation,
      customerNumber: x.customerNumber,
    }))
      || ''
  let rejectedArticles = []
  let response

  // We are just about to init a transfer to the external webshop, lets hide/disable all relevant
  // ui elements and update it right away
  show(transferring)
  hide(salesOrganisationSection)
  disable(salesOrganisation)
  disable(btnInitTransfer)
  await module[G.ADAPTER][G.UI].update(module)

  const currentCart = module[G.MODEL][G.CACHE]

  // Getting the chosen salesOrganisation
  const targetSalesOrganisation = salesOrganisations[getFirstItem(get(salesOrganisation))?.key]

  try {
  // Getting the articles
    const article = currentCart.value.positions.map(position => ({
      quantity: position.amount.toString(), number: position.key.split(':')[1],
    }))

    const params = { ...targetSalesOrganisation, article }
    const options = {
      middleware: ({
        loader,
        persistence,
        language,
        impersonation,
      }) => [
        loader,
        persistence,
        language,
        impersonation,
      ],
    }

    const url = `/api/v${version}/shop/order`
    response = await httpAdapter.post({ url, params }, options)

    if (response?.externalResponse) {
      // check for rejected article here
      rejectedArticles = response.externalResponse.articles.article
        .reduce((acc, key) => (key.status !== 1 ? [...acc, key] : acc), [])

      // saving transfer info to cart
      const transferInformation = {
        transferTimestamp: new Date().toISOString(),
        userdata: {
          rationalNumber: response.externalResponse.userdata.rationalNumber,
          salesOrganisation: response.externalResponse.userdata.salesOrganisation,
        },
        articles: response.externalResponse.articles.article,
      }
      currentCart.value.webshopHistory = currentCart?.value?.webshopHistory
        ? [transferInformation, ...currentCart.value.webshopHistory]
        : [transferInformation]

      await new Promise((resolve) => {
        eventBus.add(eventBus.type(G.CART, G.DONE, currentCart.key), ({ detail: cartAdapter }) => {
          const { [G.DATA]: cart } = cartAdapter
          resolve(cart)
        }, { once: true })

        eventBus.dispatch(eventBus.type(G.CART, G.CHANGE), { [G.DATA]: currentCart })
      })
    }
  } catch (e) {
    setKey(false, 'init', module[G.STATE])
    await _errorDialog(e.code === 503 || e.statusCode === 503 ? 'external' : 'generic', module, component)
    await back(module, component, null)
  }

  return [children, { rejectedArticles, response }, ...args]
}

/**
 * Displays a list of rejected articles if there are any.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
const displayRejectedArticlesSection = module => async (
  children, { rejectedArticles, response }, ...args
) => {
  // component
  const { rejectedArticlesSection, rejectedArticles: rejectedArticlesField } = children

  if (rejectedArticles?.length) {
    const rejectedArticlesText = rejectedArticles.reduce(
      (acc, key) => `${acc}${acc.length ? '\n' : ''}- ${key.number}`,
      '',
    )
    setToken(rejectedArticlesField, { articles: rejectedArticlesText })
    show(rejectedArticlesSection)

    await module[G.ADAPTER][G.UI].create(module)
  }

  return [children, { response }, ...args]
}

/**
 * Changes various UI elements by hiding all everything that is not needed after the transfer and
 * show everything, such as {@code finishSection} and {@code btnLink} that is needed after the
 * transfer.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
const displayFinishSection = module => async (children, { response }, ...args) => {
  // component
  const {
    component,
    salesOrganisationSection,
    finishSection,
    transferring,
  } = children
  // actions
  const {
    btnClose,
    btnCancel,
    btnInitTransfer,
    btnLink,
  } = sequenceComponentFind(component[G.ACTIONS][0])

  if (response?.loginExternalUrl) {
    hide(transferring)
    hide(btnCancel)
    hide(btnInitTransfer)
    hide(salesOrganisationSection)

    setKey(response.loginExternalUrl, 'href', btnLink[G.STATE])

    show(btnLink)
    show(btnClose)
    show(finishSection)
    await module[G.ADAPTER][G.UI].update(module)
  } else {
    show(btnCancel)
    show(salesOrganisationSection)
    show(transferring)
    show(btnInitTransfer)

    hide(btnLink)
    hide(btnClose)
    hide(finishSection)
  }

  setKey(false, 'init', module[G.STATE])

  return args
}
/**
 * Cart Webshop Action
 *
 * TODO: This is specific to RATIONAL. We need to implement a coherent interface between server
 *  and client and abstract it
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  asyncPipeSpreadIf(() => !module[G.STATE]?.init)(
    displaySalesOrganisations(module),
    hideRejectedArticlesSection(module),
  ),
  asyncPipeSpreadIf(() => module[G.STATE]?.init)(
    transferToWebshop(module),
    displayRejectedArticlesSection(module),
    displayFinishSection(module),
  ),
)(sequenceComponentFind(component), ...args)
