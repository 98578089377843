/* global G */
import { v4 as uuidV4 } from 'uuid'
import { asyncPipeSpread, deleteKey, setKey } from 'lib/util'
import validate from 'lib/sequence/model/validate'
import reset from 'lib/sequence/model/api/reset'
import { setData } from 'lib/sequence/model/api/set'
import find from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { get as getAttachments } from 'app/_shared/events/attachment'
import { settings } from 'app/_shared/session'

/**
 * Returns the file at {@param url} as base64 data.
 *
 * @param {string} url          the url to retrieve the file from
 * @returns {Promise<string>}   a promise of the base64 string
 */
const getBase64FromUrl = async (url) => {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64data = reader.result.substring(reader.result.indexOf(',') + 1)
      resolve(base64data)
    }
    reader.readAsDataURL(blob)
  })
}

/**
 * Adds the current attachments as the data of the base model's attachments property.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const addBase64Attachments = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { request } = model[G.CHILDREN]
  const { attachment } = components

  const items = await getAttachments(module, attachment, null)

  if (items?.length) {
    const attachments = await Promise.all(items.map(async item => ({
      type: 'attachment',
      value: {
        name: item.value.name,
        mimetype: item.value.type,
        size: String(item.value.size),
        data: await getBase64FromUrl(item.url),
      },
      refs: {
        parent: [request[G.STATE][G.REF]],
      },
    })))
    setData(model, { attachments })
  } else {
    setData(model, { attachments: [] })
  }

  return [components, ...args]
}

/**
 * Sets the {@param module}'s state as error if any of the model's submodels has error state.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const updateModuleState = module => () => async (components, ...args) => {
  const moduleState = module[G.STATE]
  const model = module[G.MODEL]
  const { person, organisation, request } = model[G.CHILDREN]
  const { item } = request[G.CHILDREN]

  const modelError = model[G.STATE][G.ERROR]
  const personError = person[G.STATE][G.ERROR]
  const organisationError = organisation[G.STATE][G.ERROR]
  const itemError = item[G.STATE][G.ERROR]
  const requestError = request[G.STATE][G.ERROR]

  moduleState[G.ERROR] = modelError || personError || organisationError || itemError || requestError

  return [components, ...args]
}

/**
 * Validates the "Terms of Service" and "Privacy Policy" type fields.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validateTerms = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { tos: tosForm } = components

  const previousModelError = model[G.STATE][G.ERROR]

  tosForm && await validate(model)(tosForm)

  model[G.STATE][G.ERROR] ||= previousModelError

  return [components, ...args]
}

/**
 * If the requestTypeForm and the requestForm are visible, validates them against the model's
 * request. If the validation results to be valid, adds the attachments to the request, and assigns
 * the model's person as its requesterContact, the model's organisation as its requesterContactOrg
 * and the model's account as its submitter.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validateRequest = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { account } = model[G.DATA]
  const { person, organisation, request } = model[G.CHILDREN]
  const { requesterContact, requesterContactOrg, item, submitter } = request[G.CHILDREN]
  const { requestTypeForm, requestType, requestTypeSelect, requestForm } = components
  const { request: requestFormData } = asObject(requestForm[G.CHILDREN])

  const previousError = model[G.STATE][G.ERROR] && person[G.STATE][G.ERROR]
                        && organisation[G.STATE][G.ERROR] && item[G.STATE][G.ERROR]
                        && request[G.STATE][G.ERROR]

  // if we reset it, we loose the item's information
  // reset(request)
  deleteKey(G.REF, request[G.STATE])
  deleteKey(G.REF, requesterContact[G.STATE])
  deleteKey(G.REF, requesterContactOrg[G.STATE])
  deleteKey(G.REF, submitter[G.STATE])

  if (!hidden(requestTypeForm)) {
    settings.registerRequestTypesDropdown
      ? await validate(request)(requestTypeSelect)
      : await validate(request)(requestType)

    if (!hidden(requestForm)) {
      await validate(request)(requestFormData)

      if (!previousError && !request[G.STATE][G.ERROR]) {
        // setting ids to request and its attributes
        setKey(uuidV4(), G.REF, request[G.STATE])
        // setting attachments as base64 data to the model and validating request form
        await addBase64Attachments(module)(components, ...args)
        setKey(person[G.STATE][G.REF], G.REF, requesterContact[G.STATE])
        setKey(organisation[G.STATE][G.REF], G.REF, requesterContactOrg[G.STATE])
        setKey(`org.couchdb.user:${account}`, G.REF, submitter[G.STATE])
        // setting request to be placed at the root of the bulk request's payload
        setKey(true, G.BULK, request[G.STATE])
        // setting initial model status
        setData(request, { status: 10 })
      }
    }
  }

  return [components, ...args]
}

/**
 * Validates the device's form against the model's item if its state doesn't contain an error. If
 * the deviceInstalledAt form is visible, it also validates it against the itemInstalledAt of the
 * model's request, otherwise, if the device form is visible, sets the model's organisation as the
 * itemInstalledAt of the model's request.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validateItem = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { request, organisation } = model[G.CHILDREN]
  const { item, itemInstalledAt } = request[G.CHILDREN]
  const { requestTypeForm, deviceForm, device, deviceInstalledAt } = components
  const organisationId = organisation[G.STATE][G.REF]

  if (!hidden(requestTypeForm) && !hidden(deviceForm)) {
    const previousItemError = item[G.STATE][G.ERROR]
    !previousItemError && await validate(item)(device)
    item[G.STATE][G.ERROR] ||= previousItemError

    reset(itemInstalledAt)

    // if device location isn't organisation's address
    if (!hidden(deviceInstalledAt)) {
      // validating device's organisation
      await validate(itemInstalledAt)(deviceInstalledAt)
      // setting device's organisation to be placed at the root of the bulk request's payload
      setKey(true, G.BULK, itemInstalledAt[G.STATE])
      // setting new id for device's organisation
      setKey(uuidV4(), G.REF, itemInstalledAt[G.STATE])
      setData(itemInstalledAt, { parent: [organisationId], status: 50, type: 'customer' })
    } else if (!hidden(device)) {
      // setting organisation's id for device's organisation
      setKey(organisationId, G.REF, itemInstalledAt[G.STATE])
    }
  }

  return [components, ...args]
}

/**
 * Assigns the model's organisation as the organisation of the model's person if none of them is
 * in error state.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const assignPersonOrganisation = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { person, organisation } = model[G.CHILDREN]
  const { organisation: personOrganisation } = person[G.CHILDREN]

  reset(personOrganisation)

  !person[G.STATE][G.ERROR] && !organisation[G.STATE][G.ERROR]
    && setKey(organisation[G.STATE][G.REF], G.REF, personOrganisation[G.STATE])

  return [components, ...args]
}

/**
 * Validates the organisation form against the model's organisation if its state doesn't contain an
 * error.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validateOrganisation = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { organisation } = model[G.CHILDREN]
  const organisationState = organisation[G.STATE]
  const { organisationType, organisation: organisationForm } = components

  reset(organisation)

  await validate(organisation)(organisationType)
  await validate(organisation)(organisationForm)

  !organisationState[G.ERROR]
    && setKey(uuidV4(), G.REF, organisationState)
    && setKey(true, G.BULK, organisationState)
    && setData(organisation, { status: 50 })

  return [components, ...args]
}

/**
 * Validates the person form against the model's person if its state doesn't contain an error.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validatePerson = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { person } = model[G.CHILDREN]
  const personState = person[G.STATE]
  const { contactChannels } = person[G.CHILDREN]
  const { person: personForm } = components

  reset(person)

  await validate(person)(personForm)
  await validate(contactChannels)(personForm)

  !personState[G.ERROR]
    && setKey(uuidV4(), G.REF, personState)
    && setKey(true, G.BULK, personState)
    && setData(person, { status: 50 })
    && setData(contactChannels, { email: model[G.DATA].account })

  return [components, ...args]
}

/**
 * Validates the account field against the model's account if its state doesn't contain an error.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validateAccount = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { user: userForm } = components
  await validate(model)(userForm)
  return [components, ...args]
}

/**
 * Show a generic error message at the bottom of the registration in case there was an error.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const toggleGenericError = module => () => async (components, ...args) => {
  const { msgGenericError } = components

  module[G.STATE][G.ERROR]
    ? show(msgGenericError)
    : hide(msgGenericError)

  return args
}

/**
 * Validates visible forms and deduces the data used to create the bulk request's payload.
 *
 * This is a pure business logic action, it has no UI.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 */
export default module => () => async (...args) => asyncPipeSpread(
  validateAccount(module)(),
  validatePerson(module)(),
  validateOrganisation(module)(),
  assignPersonOrganisation(module)(),
  validateItem(module)(),
  validateRequest(module)(),
  validateTerms(module)(),
  updateModuleState(module)(),
  // Needs to come after updateModuleState because it accesses the module state mutated by it
  toggleGenericError(module)(),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
