/* global G */
import { curry } from 'lib/util'
import modelSet from 'app/_shared/events/model/set'
import setStepTab, { CONFIRM } from 'app/_shared/events/setStepTab'

/**
 * Sets an item from a list as the current request's product.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<*>}
 */
const setProduct = curry(async (module, component, event) => {
  const model = module[G.MODEL]
  const { itemData, product } = model[G.CHILDREN]
  const { name: productName } = product[G.CHILDREN]
  await modelSet('product', module, component, event)
  itemData[G.CACHE].value.name = productName[G.CACHE]
  setStepTab(CONFIRM)(module, component, event)
})

export default setProduct
