/* global G */
import ui from 'lib/hook/ui/update'
import create from 'app/_shared/hook/appbar/create'
import search from 'app/_shared/hook/appbar/search'

const _baseHooks = {
  before: [],
  after: [
    ui,
  ],
}
const _searchAndCreateHooks = {
  before: [],
  after: [
    create, // configuring AppBar CreateButton
    search, // configuring AppBar SearchField
    ui,
  ],
}

const _textInputHooks = {
  before: [
    obj => (...args) => {
      console.log('before hook:', obj, args)
      return args
    },

  ],
  after: [
    ui,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    home: _baseHooks,
    textInput: _textInputHooks,
    buttons: _baseHooks,
    checkbox: _baseHooks,
    radioGroup: _baseHooks,
    select: _baseHooks,
    datePicker: _baseHooks,
    groupCheck: _baseHooks,
    textElements: _baseHooks,
    tabs: _baseHooks,
    list: _baseHooks,
    subscribedList: _baseHooks,
    widgets: _baseHooks,
    chip: _baseHooks,
    autocomplete: _baseHooks,
    email: _baseHooks,
    qrCamera: _baseHooks,
    bottomSheet: _searchAndCreateHooks,
    bottomSheetContent: _baseHooks,
    stepper: _baseHooks,
  },
}

export default hooks
