/* global G */
import { curry, setKey } from 'lib/util'
import { send, target as getTarget } from 'app/_shared/events/message'
import { changeStatus as changeTicketStatus } from 'app/_shared/events/status'
import { apply as applyAttachments, get as getAttachments } from 'app/_shared/events/attachment'
import activateDraft from 'app/_shared/events/message/activateDraft'

/**
 * Event Handler Send Status Message
 *
 * Attempts to send a new message according to the {@code type}, {@code newStatus} and {@code text}
 * properties passed inside the event's detail.
 *
 * {@code newStatus} could either be
 * - a number, or
 * - an object.
 *
 * If it is a number (e.g. {@code newStatus: 80}) the parent's (ticket or request) status will be
 * set to it.
 *
 * If it's an object, it's possible to declare the newStatus based on the parent's current status.
 * A property with the parent's current status as a KEY, and its desired new status as its VALUE
 * is expected.
 *
 * @example
 * newStatus: {
 *    '80': 60
 * }
 *
 * indicates a {@code newStatus} of {@code 60} if (and only if) the parent's current status
 * is {@code 80}.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component    the current action's main component
 * @param {Event} event                   information about the event that triggered this handler
 * @param {object} event.detail           custom event's properties
 * @param {number} event.detail.newStatus the ticket/request status to be set.
 * @param {string} event.detail.text      the message's content
 * @param {string} event.detail.type      the message's type
 * @return {Promise<void>}
 */
const sendStatusMessage = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  try {
    const {
      newStatus, newStatusReason, text, type,
    } = event.detail
    const target = getTarget(module)

    const model = module[G.MODEL]
    const { status } = model[G.CHILDREN]
    const currentStatus = status[G.CACHE]
    const { number: title } = model[G.CACHE].value
    const attachments = await getAttachments(module, component, event)
    const attachmentsCount = attachments.length

    // Set a temporary property to declare that we did this change
    model[G.STATE][G.UPDATE] = true;

    (typeof newStatus === 'object' && (currentStatus in newStatus))
      && await changeTicketStatus(module, component, newStatus[currentStatus], newStatusReason);
    (typeof newStatus === 'number')
      && await changeTicketStatus(module, component, newStatus, newStatusReason);

    (attachmentsCount || text) && await send(module, component, {
      text, target, type, title,
    }, async (item) => {
      const { key, value } = item

      setKey(null, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
      setKey(key, G.REF, component[G.STATE])
      setKey(true, G.DRAFT, component[G.STATE])

      applyAttachments(module, component, event)
      activateDraft(module, component, item, attachments, null)

      value.attachments = attachments
    }, true)
  } catch (e) {
    console.error(e)
  }
}

export default curry(sendStatusMessage)
