/* global G */
import { curry } from '@gaia/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import routeComposition from 'trait/composition/route'

/**
 * Calls action with key {@param key} while passing to it the route set in the {@param component}'s
 * configuration, expecting it to be a logic-only action that makes use of it afterward.
 *
 * @param key
 * @param module
 * @param component
 * @param event
 * @returns {Promise<void>}
 */
const actionByKey = async (key, module, component, event) => {
  event.preventDefault()
  event.stopPropagation()
  const moduleState = module[G.STATE]
  const currentRoute = moduleState[G.ROUTE]
  try {
    const { route } = component[G.CONFIGURATION]

    route
      ? moduleState[G.ROUTE] = routeComposition(route.module, route.action)
      : console.warn(`no route defined in component configuration ${component._name}`)

    await actionWithHooks(module[G.ACTIONS][key])(route)
  } catch (e) {
    console.error(e)
    moduleState[G.ROUTE] = currentRoute
  }
}

export default curry(actionByKey)
