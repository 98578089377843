import { asyncPipeSpread } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import setCartAsDone from './save'

/**
 * Cart Checkout Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  setCartAsDone(module)({ status: 80 }),
)(sequenceComponentFind(component), ...args)
