/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { empty } from 'lib/util/object'
import initDocumentation from 'app/_shared/action/partial/feedback/initDocumentation'

/**
 * Grabs information about the PDF from the incoming args and maps them to the
 * component's state.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setAttachment = module => component => async (components, ...args) => {
  const [{ value: { attachment = null, documentationId, ...restArgs } = {} } = {}] = args || []

  const { file } = components

  // Skip this, if we have no incoming values to set
  if (!attachment && empty(restArgs)) {
    return [components, ...args]
  }

  if (attachment?.value?.url || attachment?.value?.name) {
    setKey(!documentationId
      ? attachment
      : { ...attachment, documentationId },
    'attachment',
    file[G.STATE])
  }

  setKey(restArgs?.page || null, 'page', file[G.STATE])
  setKey(restArgs?.term || null, 'term', file[G.STATE])
  setKey(restArgs?.url || null, 'url', file[G.STATE])

  return [components, ...args]
}

/**
 * EquipmentInformation Pdf Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  setAttachment(module)(component),
  initDocumentation(module)(component),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
