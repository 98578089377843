/* eslint-disable no-unused-vars */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import { checked } from 'lib/sequence/component/state/checked'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { bulk } from 'lib/util'
import find from 'lib/sequence/component/children/find'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Organisation editing and creation.
 *
 * Toggles the visibility and mapping of fields depending on whether the organisation should just be
 * saved for a ticket or as an individual entity in the database and whether there is already an
 * entity selected.
 *
 * @param {object} module the ticket module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const { parent, supportedBy, buttons } = asObject(component[G.CHILDREN])
  const { store } = find(component[G.ACTIONS][0])

  const storeChecked = checked(store)
  const toggleVisibility = storeChecked ? bulkShow : bulkHide
  toggleVisibility(parent, supportedBy)

  return args
}
