export default [
  {
    key: '0',
    value: 'At any time',
  },
  // {
  //   key: '5m',
  //   value: '5 min ago',
  //   minDate: '300000',
  // },
  {
    key: '1h',
    value: '1 hour ago',
  },
  {
    key: '24h',
    value: 'Today',
  },
  {
    key: '48h',
    value: 'Yesterday',
  },
  {
    key: '7d',
    value: 'This week',
  },
]
