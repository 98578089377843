/* global G */

/**
 * Assemble the title of the ticket detail action by
 * either getting the ticket number or translating
 * its status if there is no number and add the
 * result to the component's tokens.
 * @param obj
 * @returns {function(...[*]): Promise<*[]>}
 */
const title = obj => async (...args) => {
  const name = obj[G.MODEL][G.STATE][G.REF]
  const action = obj[G.STATE][G.ACTION]

  action[G.UI].title = await obj[G.ADAPTER][G.INTL]._t(`navigation.${name}`, {
    ns: 'custom',
    _key: `navigation.${name}`,
    defaultValue: name,
  })

  return args
}

export default title
