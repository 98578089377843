/* global G */
import { curry } from 'lib/util'
import routeComposition from 'trait/composition/route'
import breadcrumbs from 'app/_shared/events/breadcrumbs/list'

/**
 * Obtains the user's default route and redirects them to it.
 *
 * @param {object} router
 * @returns {Promise}
 */
const toDefaultRoute = async (router) => {
  const { module, action } = router.defaultRoute()
  const route = routeComposition(module, action)
  return await router.moduleAction(route)
}

/**
 * Jump Breadcrumbs Event Handler
 *
 * Intended to be used by a Breadcrumbs component.
 *
 * Considers the index (position) of the element clicked:
 * - If index is 0, redirects the user to their default route
 * - Otherwise, redirects the user an amount of entries back equal to index
 *
 * @type {Gaia.AppModule.EventHandler}  event handler
 */
const jump = async (module, component, event) => {
  const router = module[G.ADAPTER][G.ROUTER]
  const { index } = event.detail
  const children = await breadcrumbs(module, component, event)
  index < children.length - 1 && (index === 0
    ? await toDefaultRoute(router)
    : router.go(index))
}

export default curry(jump)
