/* eslint-disable no-unused-expressions,max-len */
/* global G */
import { bulk } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import sequenceComponentState from 'lib/sequence/component/state'
import { show, hide } from 'lib/sequence/component/state/hidden'
import { get } from 'lib/sequence/component/state/value'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'

const {
  set: require,
  unset: notRequire,
} = sequenceComponentState('required')

const bulkShow = bulk(show)
const bulkHide = bulk(hide)
const bulkRequire = bulk(require)
const bulkNotRequire = bulk(notRequire)

/**
 * Sets the state of the current action's device-related steps according to the currently
 *
 * @param {Gaia.AppModule.Spec} module          the current module composition object
 * @param {Object<Boolean>} flags               type specific visibility flags. For further information
 *                                              about each flag, see the type documentation {@link types}.
 * @param {boolean} [flags.deviceInput]         show device specific steps
 * @param {boolean} [flags.deviceIdentify]      force device identification
 * @param {boolean} [flags.softwareInput]       show software related fields
 * @param {boolean} [flags.fieldServiceInput]   show field service related fields
 */
const displaySteps = (module, flags) => {
  const { stepper } = asObject(module[G.STATE][G.ACTION][G.COMPONENT][G.CHILDREN])
  const { device, location, issue } = asObject(stepper[G.CHILDREN])
  const { softwareInfo, fieldServiceInfo } = asObject(issue[G.CHILDREN])
  const { search: section } = asObject(device[G.CHILDREN])
  const { actions } = asObject(section[G.CHILDREN])
  const { form } = asObject(actions[G.CHILDREN])
  const { serial, options } = asObject(form[G.CHILDREN])
  const { existingHelp, btnMore } = asObject(options[G.CHILDREN])
  const serialState = serial[G.STATE]
  const { deviceInput, deviceIdentify, softwareInput, fieldServiceInput } = flags

  serialState.identify = deviceIdentify

  deviceInput
    ? bulkRequire(device, location)
    : bulkNotRequire(device, location)

  deviceInput === false
    ? bulkHide(device, location)
    : bulkShow(device, location)

  deviceIdentify
    && bulkHide(existingHelp, btnMore)

  softwareInput
    ? bulkShow(softwareInfo)
    : bulkHide(softwareInfo)

  fieldServiceInput
    ? bulkShow(fieldServiceInfo)
    : bulkHide(fieldServiceInfo)
}

/**
 * Type selection step.
 *
 * Sets the step as completed as soon as a type is selected and modifies the state of various
 * components according to the properties of the currently selected type.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const stepState = component[G.STATE]
  const { type: selection } = asObject(component[G.CHILDREN])
  const selectionKey = get(selection)
  const [type] = await listTicketTypes(module, component, selectionKey || -1)
  // displaying steps according to the currently selected type
  displaySteps(module, {
    deviceInput: type?.deviceInput,
    deviceIdentify: type?.deviceIdentify,
    softwareInput: type?.softwareInput,
    fieldServiceInput: type?.fieldServiceInput,
  })
  // set step as complete accordingly
  stepState.completed = !!type
  stepState.confirmed = stepState.completed
  stepState.title = type?.value
  return args
}
