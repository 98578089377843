/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import { get } from 'lib/sequence/component/state/value'
import { disable } from 'lib/sequence/component/state/disabled'

/**
 * Checks if {@code ticketType}, {@code timezone}, {@code fromTime} or {@code toTime} has a value
 * and if so, activates the validator for each aforementioned field.
 *
 * @param {Gaia.AppModule.Spec} module  app module
 * @param {Gaia.Component.Spec} component  action component
 * @param {Gaia.PlatformEvent} event    event
 * @return {Gaia.PlatformEvent} event
 */
const checkValidation = (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const model = module[G.MODEL]
  const { followTheSun: followTheSunModel } = model[G.CHILDREN]

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { followTheSun } = sequenceComponentFindProxy(actionComponent)
  const { ticketType, timezone, fromTime, toTime } = asObject(followTheSun[G.CHILDREN])

  const shouldEnable = get(ticketType)?.length
      || get(timezone)?.length
      || get(fromTime)
      || get(toTime)

  Object.values(followTheSunModel[G.VALIDATOR]).forEach((validator) => {
    disable(validator, !shouldEnable)
  })
}

export default curry(checkValidation)
