/* eslint-disable no-unused-expressions */
/* global G */
import { curry } from 'lib/util'
import purge from 'app/_shared/events/util/purge'

/**
 * Listens for the next hash change to happen.
 *
 * @param {Gaia.AppModule.Spec} module  the module composition object
 */
const listenHashChange = async (module) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]
  return await new Promise((resolve) => {
    eventBus.add('gaia:hashchange', event => resolve(event), { once: true, useCapture: true })
  })
}
/**
 * Go Back
 *
 * should be used whenever navigation back should occur, without persisting any changes
 *
 * default event handler for ui/button/back configuration
 * it calls native router adapter back() {@link Gaia.Adapter.Router.back}
 *
 * @example include button back in configuration
 * ```json5
 * {
 *   ...
 *   children: [
 *     {
 *       config: 'ui/button/back',
 *     },
 *     ...
 *   ],
 *   ...
 * }
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - event
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const back = async (module, component, event) => {
  const confirm = await module[G.ADAPTER][G.ROUTER].back()

  confirm
    ? await listenHashChange(module)
      && await purge(module, component, event)
    : throw Error('User cancelled confirmation dialog.')
}

export default curry(back)
