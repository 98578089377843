/* eslint-disable no-unused-vars */
/* global G */
import { curry, isStr } from 'lib/util'

/**
 * Retrieve the tree from the {@param module}'s action component's props.
 *
 * This handler can fetch tree from 4 different types or URLS:
 * - /api/v1/type/foo
 * - /api/v1/type/foo/ID
 * - /api/v1/type/foo/ID/read
 * - /api/v1/type/foo/read
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @param {boolean} event.detail.skipKey  if true, the handler will not use any key in the url
 *                                        even if {@code model[G.STATE][G.REF]} is defined
 * @returns {Promise<*>}
 */
const fetchTree = async (module, component, event) => {
  const { key: eventKey, skipKey = false } = event?.detail || event || {}
  const model = module[G.MODEL]
  const { equipment } = model[G.CHILDREN]

  const correctModel = equipment?.[G.STATE]?.[G.REF] ? equipment : model

  const { version, api: modelApi, read: modelRead } = model[G.PROPS] || {}
  const {
    api: { api = modelApi, type, read = modelRead } = {},
  } = component[G.PROPS] || {}

  const { [G.REF]: key = eventKey } = correctModel[G.STATE] || {}
  const urlKey = isStr(key) && !skipKey ? key : null

  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const url = `/api/v${version}/${type}/${api}${urlKey ? `/${urlKey}` : ''}${read ? `/${read}` : ''}`

  try {
    if (type && api) {
      const result = await httpAdapter.get({ url })

      return result?.value ? result.value : result
    }
  } catch (e) {
    console.error(e)
  }

  return {}
}

export default curry(fetchTree)
