import notEmptyAttribute from '@model/_shared/validator/notEmptyAttribute'

export const validators = {
  firstName: notEmptyAttribute,
  lastName: notEmptyAttribute,
  organisation: notEmptyAttribute,
  role: notEmptyAttribute,
}

export {
  validators as default,
  notEmptyAttribute,
}
