/* eslint-disable object-curly-newline,no-unused-vars,no-param-reassign,prefer-destructuring */
/* global G */
import find from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'
import sequenceComponentState from 'lib/sequence/component/state'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import { set } from 'lib/sequence/component/state/value'
import read from 'lib/sequence/model/api/read'
import refresh from 'lib/sequence/model/api/refresh'
import reset from 'lib/sequence/model/api/reset'
import map from 'lib/sequence/model/api/map'
import { asyncPipeSpread, setKey } from 'lib/util'
import validateStepper from 'app/_shared/component/stepper/validate'
import { CONFIRM } from 'app/_shared/events/setStepTab'
import organisation from '@app/ticket/action/step/organisation'
import contact from '@app/ticket/action/step/contact'
import type from '@app/ticket/action/step/type'
import device from '@app/ticket/action/step/device'
import party from '@app/ticket/action/step/party'
import issue from '@app/ticket/action/step/issue'
import { displayAdditionalParty, initStepper } from 'app/ticket/action/create'
import { checkSubmitterValidity } from '@app/ticket/action/detail'

const {
  set: complete,
} = sequenceComponentState('completed')

const {
  set: confirm,
} = sequenceComponentState('confirmed')

const {
  set: setTab,
} = sequenceComponentState('activeTab')

const {
  set: setActiveStep,
} = sequenceComponentState('activeStep')

const setConfirmTab = step => setTab(step, CONFIRM)

/**
 * Sets the initial step, obtained from args. If there is no step args value, it closes all steps by
 * setting the activeStep state value to -1.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, *, ...[*]): Promise<*[]>}
 */
const setStep = module => async (components, detail, ...args) => {
  const { stepper } = components

  setActiveStep(stepper, detail?.step ?? -1)

  return [components, ...args]
}

/**
 * Hides the last step's finish/continue button.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const hideFinishButton = module => async (components, ...args) => {
  const { issue: issueStep } = components
  const { btnContinue } = find(issueStep)

  hide(btnContinue)

  return [components, ...args]
}

/**
 * Displays the buttons that allow the users to search for duplicates.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displaySearchButtons = module => async (components, ...args) => {
  const { btnSearchCurrentOrganisation, btnSearchCurrentContact } = components
  const { btnSearchCurrentDevice, btnSearchCurrentParty } = components

  btnSearchCurrentOrganisation && show(btnSearchCurrentOrganisation)
  btnSearchCurrentContact && show(btnSearchCurrentContact)
  btnSearchCurrentDevice && show(btnSearchCurrentDevice)
  btnSearchCurrentParty && show(btnSearchCurrentParty)

  return [components, ...args]
}

/**
 * Disables the save button if the stepper is not valid and/or the action isn't marked as dirty.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displaySaveButton = module => async (components, ...args) => {
  const { btnSave } = components

  const valid = validateStepper(module)
  const changed = module[G.STATE][G.ACTION][G.STATE][G.UNDO]
  valid && changed ? enable(btnSave) : disable(btnSave)

  return [components, ...args]
}

/**
 * Toggles the display of informative labels depending on whether the submitter validation is still
 * pending.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(*, ...[*]): Promise<void>}
 */
const displayContactValidationLabels = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact } = model[G.CHILDREN]
  const { organisation: organisationStep, contact: contactStep, party: partyStep } = components
  const steps = [organisationStep, contactStep, partyStep]
  await checkSubmitterValidity(module)(components, ...args)

  const { pendingValidation, isBlocked } = requesterContact[G.STATE][G.META]

  const toggleWaitingForApprovalDisplay = pendingValidation && !isBlocked ? show : hide
  const toggleBlockedDisplay = isBlocked ? show : hide

  steps.filter(step => step).forEach((step) => {
    const { labels } = find(step)
    const { waitingForApproval, blocked } = find(labels)

    toggleWaitingForApprovalDisplay(waitingForApproval)
    toggleBlockedDisplay(blocked)
  })
}

/**
 * Hides the list of devices in the organisation step.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const hideDevicesList = module => async (components, ...args) => {
  const { devices } = components
  hide(devices)
  return [components, ...args]
}

/**
 * Gathers the actions for the stepper.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<[Object,*,...*[]]>}
 */
const initActions = module => async (components, ...args) => [
  { organisation, contact, type, device, party, issue }, components, ...args,
]

/**
 * Merges the cache of {@param model} and {@param modelData} into the cache of {@param model}. If
 * the same values are found in both caches, the ones in {@param modelData} are used.
 *
 * @param {Gaia.Model.Spec} model       a base model
 * @param {Gaia.Model.Spec} modelData   a model to merge into the base model
 * @returns {Promise<void>}
 */
const mergeModelCache = async (model, modelData) => {
  const modelCache = model[G.CACHE]?.[0] || model[G.CACHE] || {}
  const modelDataCache = modelData[G.CACHE]

  const cache = modelCache
  cache.value = {
    ...modelCache.value,
    ...modelDataCache,
  }

  await refresh(model, cache)
}

/**
 * Clears and rereads the root model with verbose render type.
 *
 * @param {Gaia.Model.Spec} model     the root ticket model
 * @param {Gaia.Component} component
 * @returns {Promise<void>}
 */
const readModel = async (model, component) => {
  const { read: definition } = model[G.PROPS]
  if (model[G.STATE][G.REF]) {
    model[G.PROPS].read = 'verbose'
    const key = model[G.STATE][G.REF]
    const save = model[G.STATE][G.BULK]
    reset(model)
    model[G.STATE][G.REF] = key
    model[G.STATE][G.BULK] = save
    await read(model)(component)
    model[G.PROPS].read = definition
  }
}

/**
 * Loads the root ticket model and its attributes, so that they are ready to be edited. It does so
 * only the first time the action is called by checking and setting the bulk flag on the root ticket
 * model.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const loadModels = module => async (components, ...args) => {
  const { component } = components
  const model = module[G.MODEL]
  const { organisation: organisationStep, contact: contactStep } = components
  const { device: deviceStep, party: partyStep } = components
  const { type: typeStep, issue: issueStep } = components
  const { softwareInfo, fieldServiceInfo } = find(issueStep)
  const { type: typeField } = find(typeStep)

  if (!model[G.STATE][G.BULK]) {
    setKey(true, G.BULK, model[G.STATE]) // tells the bulk hook that we are updating the base model
    await readModel(model, component)

    const { requesterOrg, requesterOrgData } = model[G.CHILDREN]
    await mergeModelCache(requesterOrg, requesterOrgData)
    complete(organisationStep)
    confirm(organisationStep)
    setConfirmTab(organisationStep)

    const { requesterContact, requesterContactData } = model[G.CHILDREN]
    await readModel(requesterContact, component)
    await mergeModelCache(requesterContact, requesterContactData)
    complete(contactStep)
    confirm(contactStep)
    setConfirmTab(contactStep)

    const { item, itemData } = model[G.CHILDREN]
    if (deviceStep && itemData[G.CACHE]) {
      await readModel(item, component)
      await mergeModelCache(item, itemData)
      complete(deviceStep)
      confirm(deviceStep)
      setConfirmTab(deviceStep)
    }

    const { additionalParty, additionalPartyData } = model[G.CHILDREN]

    if (partyStep && additionalPartyData[G.CACHE]) {
      await readModel(additionalParty, component)
      await mergeModelCache(additionalParty, additionalPartyData)
      complete(partyStep)
      confirm(partyStep)
      setConfirmTab(partyStep)
    }

    set(typeField, model[G.CHILDREN].type[G.CACHE])
    complete(typeStep)
    confirm(typeStep)

    map(model)(issueStep)
    softwareInfo && map(model[G.CHILDREN].description)(softwareInfo)
    fieldServiceInfo && map(model[G.CHILDREN].description)(fieldServiceInfo)
    complete(issueStep)
    confirm(issueStep)

    await displayContactValidationLabels(module)(components, ...args)

    await setStep(module)(components, ...args)
  }

  return [components, ...args]
}

/**
 * Edit action step actions manager.
 *
 * Executes one step action after another.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => await asyncPipeSpread(
  loadModels(module),
  hideDevicesList(module),
  displaySaveButton(module),
  displaySearchButtons(module),
  hideFinishButton(module),
  displayAdditionalParty(module),
  initActions(module),
  initStepper(module),
)(find(component), ...args)
