/* global G */
import { curry } from 'lib/util'
import redirectSequence from '@gaia/sequence/module/adapter/router/redirect'
import routeComposition from '@gaia/trait/composition/route'
import { detail } from 'app/_shared/events'

/**
 * Details or Cart Event Handler
 *
 * Redirects to the {@code cart} action if the selected cart is the current one, otherwise executes
 * the {@code detail} event handler with the given cart.
 *
 * @param {Gaia.AppModule.Spec} module        app module
 * @param {Gaia.AppModule.Action} component   action component
 * @param {Event} event                       the event that triggered this handler
 * @param {object} event.detail               custom event details
 * @param {string} event.detail.key           a model key to pass to {@link redirectSequence}
 * @return {Promise<Event>} void
 */
const detailOrCart = async (module, component, event) => {
  try {
    const { item } = event.detail
    if (item?.value?.status === 50) {
      const moduleState = module[G.STATE]
      const { activeRoute } = component[G.CONFIGURATION]
      moduleState[G.ROUTE] = routeComposition(activeRoute.module, activeRoute.action)
      await redirectSequence(module)({ detail: event.detail })
    } else {
      await detail(module, component, event)
    }
  } catch (e) {
    console.error(e)
  }
  return event
}

export default curry(detailOrCart)
