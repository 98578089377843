/* eslint-disable object-curly-newline */
/* global G */
import session from 'app/_shared/session'

/**
 * Returns a new message object.
 *
 * @typedef Gaia.Model.Message
 *
 * @param {string[]} source             the ref of the object the message is sent from
 * @param {string[]} sourceObject       the module to use when displaying this message in source
 * @param {string[]} target             the ref of the object the message is sent to
 * @param {string[]} targetObject       the module to use when displaying this message in target
 * @param {string[]} submitter          the ref of the user that sent the message
 * @param {string} text                 the text content of the message
 * @param {string} type                 the type of the message
 * @param {string|null} title           an optional message title
 * @param {boolean} [draft]             an optional draft flag
 * @returns {object}                    a new message object
 * @constructor
 */
const Message = (
  { source, sourceObject, target, targetObject, submitter, text, type, title, draft = false },
) => ({
  draft,
  value: {
    // submitTimestamp <- added server-side
    sourceObject,
    targetObject,
    type,
    text,
    ...title && { title },
  },
  refs: {
    submitter,
    source,
    ...target && { target },
  },
})

/**
 * @callback MessageSentCallback
 * @param {Gaia.Model.Message} item   the data of the newly created note
 * @returns {void}
 */

/**
 * Sends a message to be stored and remotely created by the message api.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component      the current action's main component
 * @param {string} text                     the content of the message
 * @param {string} type                     the type of the message.
 * @param {string[]} target                 the ref of the object the message is sent to
 * @param [string|null] title               an optional message title
 * @param {MessageSentCallback} [callback]  an optional function to be called once the note is
 *                                          created
 * @param {boolean} [draft]                 whether or not to create the message as a draft.
 * @returns {Promise<void>}
 */
const send = (module, component, { text, target, type = 'message', title = null }, callback, draft = false) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]
  const eventType = eventBus.type(G.MESSAGE, G.CREATE)
  const parentEntity = module._name.split(':').at(-1)
  const source = [module[G.MODEL][G.STATE][G.REF]]
  const submitter = session(module).user.key({ wrap: true })

  // eslint-disable-next-line one-var-declaration-per-line
  let sourceObject, targetObject

  switch (parentEntity) {
    case 'ticket':
      sourceObject = 'ticket'
      targetObject = target && 'request'
      break
    case 'request':
      sourceObject = 'request'
      targetObject = target && 'ticket'
      break
    default:
      break
  }

  const message = Message(
    { source, sourceObject, target, targetObject, submitter, text, type, title, draft },
  )

  eventBus.dispatch(eventType, { [G.DATA]: message, [G.FN]: callback })
}

export default send
