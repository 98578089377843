/* global G */
import { asyncPipeSpread, getFirstItem } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { set } from 'lib/sequence/component/state/value'
import { hidden } from 'lib/sequence/component/state/hidden'
import { disabled } from 'lib/sequence/component/state/disabled'
import { presetCountries, presetProvides } from 'app/organisation/action/edit'
import listStatus from 'app/_shared/events/collection/listStatus'

/**
 * Preset the {@code status} field.
 *
 * @param {Gaia.Component.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetStatus = module => async (children, ...args) => {
  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { status: statusField } = children

  const status = getFirstItem(listStatus(module, actionComponent, { detail: { key: 50 } }))

  // Only set the field if it's editable
  !hidden(statusField)
    && !disabled(statusField)
    && set(statusField, status)

  return [children, ...args]
}

export default module => component => async (...args) => asyncPipeSpread(
  presetStatus(module),
  presetProvides(module),
  presetCountries(module),
)(find(component), ...args)
