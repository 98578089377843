import { curry } from 'lib/util'
import { v4 as uuidV4 } from 'uuid'

/**
 * Set the {@code parent} property of the current node to {@code parent} and {@code id} to either
 * {@code node._id} or a concatenation of the current {@code index} and {@code node.name}
 *
 * Recursively call itself for every element of {@code node.$children}.
 *
 * @param {Gaia.AppModule.Spec} module              the current module composition object
 * @param {Gaia.Component.Spec} component              the current action's main component
 * @param {Event || {}} eventOrOptions              the event object that triggered this handler
 * @param {Object} [eventOrOptions.node]            the current node
 * @param {String[]} [eventOrOptions.breadcrumbs]   the id of the note to find.
 * @returns {*}
 */
const setTree = (module, component, eventOrOptions) => {
  const {
    node,
    parent = null,
    index = 0,
    depth = 0,
    label,
  } = eventOrOptions?.detail || eventOrOptions || {}

  // Set the index of the node
  node.index = index

  // Setting the depth
  node.depth = depth

  // Set the parent of the node
  parent && (node.parent = parent)

  !node?.parent && !node?.name && (node.name = label)

  // In case the node has no name and is not the root node, give it a dummy name
  !node?.name && node.parent && (node.name = '-')

  if (!node.id) {
    node.id = node?._id
      // Generate an id using UUID
      || (node?.name && `${node.name}-${uuidV4()}`)
      || uuidV4()
  }

  node?.$children?.length && node.$children.forEach((child, childIndex) => {
    setTree(module, component, {
      node: child,
      parent: node,
      index: childIndex,
      depth: depth + 1,
    })
  })

  return node
}

export default curry(setTree)
