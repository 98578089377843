/* eslint-disable no-unused-vars */
/* global G, PLATFORM */
import { asyncPipeSpread, bulk, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'
import { get, set } from 'lib/sequence/component/state/value'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import asObject from 'lib/sequence/component/children/asObject'

const display = x => (get(x) ? show(x) : hide(x))
const bulkDisplay = bulk(display)

const _setPropertyField = (field) => {
  const fieldValue = get(field)
  setKey(fieldValue.name, 'label', field[G.STATE])
  set(field, fieldValue.value)
}

/**
 * Maps various properties inside the property box.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapProperties = module => async (components, ...args) => {
  const model = module[G.MODEL][G.CHILDREN]?.equipment || {}
  const { properties } = model[G.CHILDREN]
  const { details } = components

  const {
    energy: energyProperty,
    voltage: voltageProperty,
    size: sizeProperty,
    type: typeProperty,
  } = asObject(details[G.CHILDREN])

  map(properties)(details)

  get(energyProperty) && _setPropertyField(energyProperty)
  get(voltageProperty) && _setPropertyField(voltageProperty)
  get(sizeProperty) && _setPropertyField(sizeProperty)
  get(typeProperty) && _setPropertyField(typeProperty)

  bulkDisplay(energyProperty, voltageProperty, sizeProperty, typeProperty)

  hidden(energyProperty)
    && hidden(voltageProperty)
    && hidden(sizeProperty)
    && hidden(typeProperty)
    && hide(details)

  return args
}

/**
 * EquipmentInformation Equipment Details Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  mapProperties(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
