/* global G */
import { curry } from 'lib/util'

/**
 * Timestamp Transformer
 *
 * TODO: it does NOT read CURRENT VALUE TO TRANSFORM IT
 *
 * @param key
 * @param obj
 * @param data
 * @return {*}
 */
const fn = (key, obj, data) => {
  // console.log('timestamp', obj[G.DATA])
  const _data = data
  const item = obj[G.CHILDREN][key]
  const { type } = item[G.PROPS]
  // const { value } = obj[G.DATA][key]
  const date = new Date()

  _data[type][key] = date.toISOString()

  return _data
}

export default curry(fn)
