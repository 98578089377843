/* global G */
import { curry, setKey } from '@gaia/util'
import session from 'app/_shared/session'

/**
 * Gets {@param component[G.PROPS].ref} from the user session
 */
export default curry((module, component) => () => {
  // set component state ref to value in session
  setKey(
    session(module)[component[G.PROPS].ref].key(),
    component[G.PROPS].ref,
    component[G.STATE],
  )

  return session(module)[component[G.PROPS].ref].key()
})
