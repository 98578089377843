import acl from 'app/_shared/events/acl/component'
import permission from 'app/_shared/events/acl/permission'
import data from 'app/_shared/events/acl/data'
import model from 'app/_shared/events/acl/model'
import cell from 'app/_shared/events/acl/cell'

const events = {
  acl,
  data,
  model,
  permission,
  cell,
}

export {
  events as default,
  acl,
  data,
  permission,
  model,
  cell,
}
