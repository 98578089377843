/* global G */
import { curry, isArr } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'

/**
 * Obtains the organisation with the id given in {@param event.detail.value} by comparing it to
 * the user's organisation and returning it in that case, or querying the server about the
 * information.
 *
 * This handler is used to get the organisation for a particular role (e.g. "ROLE@ORG:1").
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 *
 * @return {Promise<*|[{}]>}
 */
const getDeletionStatus = async (module, component, event) => {
  const index = event?.detail || event
  // model
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const { roles } = find(actionComponent)
  const currentRoles = get(roles)

  const currentRole = isArr(currentRoles)
    ? currentRoles.find(role => role.index === parseInt(index, 10))
    : Object.values(currentRoles).find(role => role.index === parseInt(index, 10))

  return currentRole?.delete || false
}

export default curry(getDeletionStatus)
