import { curry } from 'lib/util'
import count from 'app/_shared/events/search/count'
import search from 'app/_shared/events/search'
import searchWith from 'app/_shared/events/search/with'

/**
 * Get the count of viewable contacts and the contact itself
 * in case the count is 1.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 *
 * @return {Object}                     the count and optionally a colleague
 */
const countFn = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const additionalParameters = await searchWith(['filter'], module, component, event)

  const items = await count(null, module, component, additionalParameters)
  if (items.total.count !== 1) return { total: { count: items.total.count } }

  const item = await search(null, module, component, additionalParameters)

  return {
    total: { count: items.total.count },
    item: {
      title: item[0]?.value?.firstName,
      body: item[0]?.value?.lastName,
      key: item[0]?.key,
    },
  }
}

export default curry(countFn)
