/* global G */
import { disable, enable } from 'lib/sequence/component/state/disabled'

/**
 * Toggle Phone Validator Hook
 *
 * Disables the phone validator if the current model has the remoteAuthentication flag set to true;
 * otherwise, enables it.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]=)}
 */
const togglePhoneValidator = obj => (...args) => {
  const model = obj[G.MODEL]
  const { person } = model[G.CHILDREN]
  const { contactChannels } = person[G.CHILDREN]
  const { phone } = contactChannels[G.CHILDREN]
  const { remoteAuthentication } = model[G.CACHE].value

  const toggle = remoteAuthentication ? disable : enable

  phone[G.VALIDATOR].forEach(validator => toggle(validator))

  return args
}

export default togglePhoneValidator
