/* global G */
import redirect from 'lib/hook/router/redirect'
import confirm from 'lib/hook/router/confirm'
import validate from 'lib/hook/model/validate'
import seqMap from 'lib/sequence/model/api/map'
import ui from 'lib/hook/ui/update'

const _baseHooks = {
  after: [
    ui,
  ],
}

const _confirmHooks = {
  after: [
    ui,
    confirm,
  ],
}

const _redirectHooks = {
  before: [],
  after: [
    ui,
    redirect,
  ],
}

const _validateHooks = {
  before: [
    validate,
  ],
  after: [
    ui,
    redirect,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _baseHooks,
    contact: _confirmHooks,
    contactSuccess: _baseHooks,
    forgotPassword: _redirectHooks,
    confirm: _baseHooks,
    resetPassword: {
      before: [
        validate,
        // so inline hook for mapping something
        obj => (...args) => {
          seqMap(obj[G.MODEL])(obj[G.STATE][G.ACTION][G.COMPONENT])
          return args
        },
      ],
      after: [
        ui,
        redirect,
      ],
    },
    change: _confirmHooks,
    changeSuccess: _baseHooks,
    changeError: _baseHooks,
    validateRedirect: _validateHooks,
    validateLogin: _validateHooks,
    reset: _baseHooks,
    trouble: _baseHooks,
    support: _redirectHooks,
  },
}

export default hooks
