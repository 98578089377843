import { curry } from 'lib/util'
import fetchTree from 'app/_shared/events/file/tree/fetchTree'

/**
 * Get Drilldown Tree Event Handler
 *
 * Fetches the given drilldown tree, using {@link fetchTree}, and wraps it in a root node before
 * returning it.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {PlatformEvent} event         information about the event that triggered this handler
 * @returns Object
 */
const getDrilldownTree = async (module, component, event) => {
  try {
    const result = await fetchTree(module, component, event)

    return result?.tree?.length
      ? { $children: result?.tree, type: 'Directory' }
      : { $children: [], type: 'Root' }
  } catch (e) {
    return { $children: [], type: 'Root' }
  }
}

export default curry(getDrilldownTree)
