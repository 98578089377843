/* eslint-disable no-unused-vars */
import { getFirstItem } from 'lib/util'
import availableRoles from 'model/account/collection/roles'
import listUserRoles from 'app/_shared/events/collection/listUserRoles'

/**
 * Return the currently logged-in user's role.
 *
 * @param {String[]} roles                    the available user roles
 * @param {Gaia.AppModule.Spec} module        app module
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.PlatformEvent|String} [event] incoming event
 *
 * @returns {userRole|null}
 * @private
 */
const getUserRole = (roles, module, component, event) => {
  const filteredRoles = event?.detail || event || availableRoles

  /**
     * Get the user's primary role by comparing all roles against
     * our list of {@link availableRoles}.
     *
     * @example
     * [
     *     "system.active",
     *     "CustomerServiceAgent@ORGANISATION:5",
     *     "MessageReader@ORGANISATION:0"
     * ]
     *
     * will yield
     *
     * {
     *     "value": "Customer Service Agent",
     *     "key": "CustomerServiceAgent"
     * }
     */
  /** @typedef {userRole} foundRole */
  const foundRole = filteredRoles
    .find(availableRole => roles
      .map(role => role.substring(0, role.indexOf('@')))
      .includes(availableRole.key))

  return foundRole
    ? getFirstItem(listUserRoles(module, component, foundRole.key))
    : null
}
export default getUserRole
