/* eslint-disable no-unused-vars */
import { curry } from 'lib/util'
import concatQueryParams from 'app/_shared/events/search/query/util'

/**
 * Return a search query that filters the search to only contain persons that are invited.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event|object} event              information about the event that triggered this handler
 *
 * @returns {string}
 */
const personIsInvited = (module, component, event) => {
  const params = {
    type: 'metatype:person',
    term: event?.detail?.value
      ? `AND id:org.couchdb.user\\:${event.detail.value}* `
      : '',
    status: 'AND invite_status:(invited expired)',
  }

  return concatQueryParams(params)
}

export default curry(personIsInvited)
