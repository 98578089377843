/* eslint-disable no-unused-vars */
import { curry, getFirstItem, isNum } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import roles from 'model/account/collection/roles'
import concatQueryParams from 'app/_shared/events/search/query/util'

/**
 * Sets the {@code query} parameter for the current search, based on the given search term in
 * {@param event.detail.term} and the currently selected {@code role} and {@code atOrganisation}.
 * Only retrieves users that have a valid user role, so excluding system users.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event|object} event              information about the event that triggered this handler
 * @return {string}
 */
const userHasIdAndRoleAtOrg = (module, component, event) => {
  const { role, atOrganisation, status } = find(component)
  const userRoles = roles.map(userRole => `${userRole.key}@*`).join(' ')
  const selectedRole = getFirstItem(get(role))?.key || null
  const selectedAtOrganisation = getFirstItem(get(atOrganisation))?.key?.replace(':', '\\:') || null
  const selectedStatus = getFirstItem(get(status)) || null

  const params = {
    type: 'metatype:user',
    term: event?.detail?.value
      ? `AND id:org.couchdb.user\\:${event.detail.value}* `
      : '',
    role: selectedRole
      ? `AND roles:(${selectedRole}@${selectedAtOrganisation || '*'})`
      : `AND roles:(${userRoles})`,
    status: isNum(selectedStatus?.key)
      ? `AND status:(${selectedStatus?.key})`
      : '',
  }

  return concatQueryParams(params)
}

export default curry(userHasIdAndRoleAtOrg)
