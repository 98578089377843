/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread, bulk, getFirstItem, setKey } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import map from 'lib/sequence/model/api/map'
import { get, set } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'
import listUserRoles from 'app/_shared/events/collection/listUserRoles'

const display = x => (get(x) ? show(x) : hide(x))
const bulkDisplay = bulk(display)

const _getLabel = (module, t) => module[G.ADAPTER][G.INTL].translate(
  `label.${t}`,
  {
    ns: 'team',
    _key: `label.${t}`,
    defaultValue: '-',
  },
)

/**
 * Maps various properties.
 *
 * @param module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapProperties = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]

  // component
  const { teamName, teamStatus, teamDetails, teamInfo } = children

  // Mapping
  const bulkMap = bulk(map(model))
  bulkMap(teamName, teamDetails, teamStatus, teamInfo)

  return [children, ...args]
}

/**
 * Shows or hides various properties depending on whether they have a value.
 *
 * @param module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayProperties = module => async (children, ...args) => {
  // component
  const { email } = children

  bulkDisplay(email)

  return [children, ...args]
}

/**
 * Presets the {@code defaultRole} property.
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
const presetDefaultRole = module => component => async (children, ...args) => {
  // model
  const model = module[G.MODEL]

  // component
  const { defaultRole } = children

  const translatedDefaultRole = listUserRoles(
    module,
    component,
    getFirstItem(model[G.CHILDREN]?.defaultRole?.[G.CACHE]) || '',
  )

  set(defaultRole, getFirstItem(translatedDefaultRole)?.value)

  return args
}

/**
 * Presets {@code parent} and {@code name}.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetOrganisation = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const organisationCache = model[G.CHILDREN].organisation?.[G.CACHE]

  // component
  const { organisation, organisationProperty } = children
  const parentOrg = getFirstItem(organisationCache) || {}

  if (organisationCache) {
    const { name } = asObject(organisation[G.CHILDREN])
    setKey(parentOrg?.key, 'key', organisation[G.STATE])
    set(name, parentOrg?.value?.name)
  } else {
    hide(organisationProperty)
  }

  return [children, ...args]
}

/**
 * Presets the {@code notify} property.
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetNotify = module => async (children, ...args) => {
  // component
  const { notify } = children
  const notifyValue = get(notify)

  set(notify, notifyValue && Object.keys(notifyValue)
    .filter(item => notifyValue[item] === true)
    .map(item => _getLabel(module, item))
    .reduce((result, item) => (result.length ? [result, ', ', item] : [item]), []))

  bulkDisplay(notify)

  return [children, ...args]
}
/**
 * Team Detail Action
 *
 * @param {Gaia.AppModule.Spec} module
 * @return {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  mapProperties(module),
  displayProperties(module),
  presetOrganisation(module),
  presetNotify(module),
  presetDefaultRole(module)(component),
)(sequenceComponentFind(component), ...args)
