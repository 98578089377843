/* eslint-disable max-len */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Event Handler Search Step and Recreate
 *
 * Sets the event detail's value as current search term for the list of the current step and
 * recreates the module, so that it is taken into account the next time the list is rendered.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @param {Boolean} keepSorting         whether or not the sort criteria should be kept. If false,
 *                                      the criteria will be dropped if {@param event.detail.term}
 *                                      is non-nil
 * @return {Promise<void>}
 */
const searchStepAndRecreate = async (module, component, event, keepSorting = true) => {
  try {
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { stepper } = asObject(actionComponent[G.CHILDREN])
    const { currentStep } = stepper[G.STATE]
    const { [currentStep]: step } = asObject(stepper[G.CHILDREN])
    const { search } = asObject(step[G.CHILDREN])
    const { searchField, list } = asObject(search[G.CHILDREN])
    const { term, transform } = event.detail
    const sort = !term || keepSorting

    const state = searchField[G.STATE]
    state.value = term

    list[G.STATE][G.META] = { term, transform, sort }
    // temporary solution for suspended memoized list component
    // recreate ui, instead of update ui
    await module[G.ADAPTER][G.UI].create(module)
    await actionWithHooks(module[G.STATE][G.ACTION])([])
  } catch (e) {
    console.error(e)
  }
}

export default curry(searchStepAndRecreate)
