import notEmptyAttribute from '@model/_shared/validator/notEmptyAttribute'

export const validators = {
  name: notEmptyAttribute,
  serial: notEmptyAttribute,
  product: notEmptyAttribute,
  installedAt: notEmptyAttribute,
}

export {
  validators as default,
  notEmptyAttribute,
}
