/**
 * Languages Collection.
 *
 * Contains static list of available ticket's message types, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 */
export default [
  { value: 'English', key: 'en' },
  { value: 'German', key: 'de' },
  { value: 'Catalan', key: 'ca' },
  { value: 'French', key: 'fr' },
  { value: 'Spanish', key: 'es' },
  { value: 'Portuguese', key: 'pt' },
  { value: 'Turkish', key: 'tr' },
  { value: 'Italian', key: 'it' },
  { value: 'Polish', key: 'pl' },
  { value: 'Russian', key: 'ru' },
  { value: 'Chinese', key: 'zh' },
  { value: 'Japanese', key: 'ja' },
  { value: 'Czech', key: 'cs' },
  { value: 'Swedish', key: 'sv' },
]
