/* global G */
/* eslint-disable */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject';
import session from 'app/_shared/session';
import getRelativeTime from 'lib/util/date';

/**
 * Callback for removing the item from the state of the list.
 *
 * @callback removeItem
 * @param {Object[]} prevState
 * @param {Object} fetchedTicket
 */

/** @type {removeItem} */
export const removeItem = (prevState, removedItem) => {
  const newState = [...prevState]
  const existingTicket = prevState.find(ticket => ticket.key === removedItem.key)

  if (existingTicket) {
    newState.splice(newState.indexOf(existingTicket), 1);
  }

  return newState
}

/**
 * Callback for adding the item to the state of the list.
 *
 * @callback addItem
 * @param {Object[]} prevState
 * @param {Object} fetchedTicket
 */

/** @type {addItem} */
export const addItem = (prevState, fetchedItem) => {
  const newState = removeItem(prevState, fetchedItem)

  newState.unshift(fetchedItem)

  return newState
};

/**
 * Check whether the current ticket applies to the selected status filter.
 *
 * @param {Object} fetchedTicket
 * @param {String} selectedStatus
 * @returns {boolean}
 */
const filterStatus = (fetchedTicket, selectedStatus) => {
  switch (selectedStatus) {
    case '1':
      if (fetchedTicket.value.workState === 10) return true; break;
    case '2':
      if (fetchedTicket.value.workState === 20) return true; break;
    case '3':
      if (fetchedTicket.value.workState === 30) return true; break;
    default: return true;
  }

  return false
}

/**
 * Check whether the current ticket applies to the selected assignee filter.
 *
 * @param {Object} fetchedTicket
 * @param {String} selectedAssignee
 * @param {String} currentUserId
 * @returns {Boolean}
 */
const filterAssignee = (fetchedTicket, selectedAssignee, currentUserId) => {
  switch (selectedAssignee) {
    case 'me':
      if (fetchedTicket.refs.assignee?.[0]?.key === currentUserId) return true; break;
    case 'none':
      if (!fetchedTicket.refs.assignee?.length) return true; break;
    default: return true;
  }

  return false
}

/**
 * Check whether the current ticket applies to the selected dateCreated filter.
 *
 * @param {String|Date} timestamp
 * @param {String} selectedDateCreated
 * @returns {Boolean}
 */
const filterDateCreated = (timestamp, selectedDateCreated) => {
  const elapsedTime = getRelativeTime(timestamp)
  switch(selectedDateCreated) {
    case '1h':
      if (elapsedTime.hours && elapsedTime.hours <= 1) return true; break;
    case '24h':
      if (elapsedTime.hours && elapsedTime.hours <= 24) return true; break;
    case '48h':
      if (elapsedTime.days && elapsedTime.days <= 2) return true; break;
    case '7d':
      if (elapsedTime.days && elapsedTime.days <= 7) return true; break;
    default: return true;
  }

  return false
}

/**
 * Get the new ticket and return it alongside
 * a callback handling the state change.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param event                         event containing the new ticket id
 * @returns {Promise<*>}
 */
// eslint-disable-next-line no-unused-vars
const updateTicketList = async (module, component, event) => {
  const obj = module[G.MODEL]
  const { api, version } = obj[G.PROPS]
  const ticketId = event.id
  const url = `/api/v${version}/${api}/${ticketId}`

  try {
    const fetchedTicket = await obj[G.ADAPTER][G.HTTP].get({ url })
    const { filter: availableFilters } = asObject(module[G.STATE][G.ACTION][G.COMPONENT][G.CHILDREN])
    const { status, assignee, dateCreated } = asObject(availableFilters[G.CHILDREN])

    return filterStatus(fetchedTicket, status[G.STATE]?.value) &&
      filterAssignee(fetchedTicket, assignee[G.STATE]?.value?.[0]?.key, session(module).user.key()) &&
      filterDateCreated(fetchedTicket.value.submit.timestamp, dateCreated[G.STATE]?.value)
        ? { item: fetchedTicket, addItem }
        : { item: fetchedTicket, addItem: removeItem };

  } catch (e) {
    console.error(e)
  }
  return {}
}
export default curry(updateTicketList)
