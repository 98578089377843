/* eslint-disable no-param-reassign,default-param-last */
/* global G */
import { curry, isFn, isObj } from 'lib/util'
import count from 'app/_shared/events/search/count'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Translates the given label and returns it alongside the count.
 *
 * @param {Object|Number} data            the ref to count
 * @param {String} t                      translation key
 * @param {Gaia.AppModule.Spec} module    the current module object
 * @param {Gaia.Component.Spec} component action component
 * @param {Object} eventOrOptions         filter for the count search
 * @return {{badge: boolean, hidden: (string|boolean|*|boolean), label: (string|*)}}
 */
export const setLabel = curry((data, t, module, component, eventOrOptions) => {
  const { key, ns } = t

  const label = module[G.ADAPTER][G.INTL]._t(
    `label.${key}`,
    {
      ns,
      _key: `label.${key}`,
      context: module[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT],
      defaultValue: component[G.PROPS].label,
    },
  )

  const countData = (isObj(data) && data.count)
      || (isFn(data) && data(module, component, eventOrOptions))
      || data

  return {
    label: countData > 0
      ? `${label} (${countData}${data?.more ? '+' : ''})`
      : label,
    badge: false,
    count: countData,
    hidden: component[G.PROPS]?.autoHide && countData === 0,
  }
})

/**
 * Gets the count of {@param ref} and returns it as a label.
 *
 * @param {String} ref                    the ref to count
 * @param {String} t                      translation key
 * @param {Gaia.AppModule.Spec} module    the current module object
 * @param {Gaia.Component.Spec} component action component
 * @param {Object} eventOrOptions         filter for the count search
 * @returns {Promise<{label: *}|{badge: boolean, label: (string|*)}>}
 */
export const setRefLabel = curry(async (ref, t, module, component, eventOrOptions) => {
  const model = module[G.MODEL]
  const data = model[G.CHILDREN][ref]?.[G.CACHE]?.length || 0

  return setLabel(data, t, module, component, eventOrOptions)
})

/**
 * Gets the count for the current tab and returns it as a label.
 *
 * @param {String} t                      translation key
 * @param {Gaia.AppModule.Spec} module    the current module object
 * @param {Gaia.Component.Spec} component action component
 * @param {Object} eventOrOptions         filter for the count search
 * @returns {Promise<{label: *}|{badge: boolean, label: (string|*)}>}
 */
const setCountedLabel = async (t, module, component, eventOrOptions) => {
  try {
    const { list } = asObject(component[G.CHILDREN])
    const listOptions = list[G.STATE][G.META]

    /**
     * {@code listOptions} should have been set by {@code searchWith} event handler.
     */
    const result = await count(null, module, component, listOptions)
    const data = result.total?.count

    return setLabel(data, t, module, component, eventOrOptions)
  } catch (e) {
    console.error(e)
  }

  return setLabel(0, t, module, component, eventOrOptions)
}

export default curry(setCountedLabel)
