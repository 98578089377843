/* global G */
import format from 'lib/util/string'
import { translationsMap } from 'lib/util'

/**
 * Takes the {@param component}'s template prop, which has to be a string, translates
 * it, replaces any placeholder from it that has the same name as a property of the
 * {@param parameters} object and uses the resulting string as the component's state value.
 *
 * @param {Gaia.AppModule.Spec} module
 * @param {Gaia.Component} component
 * @param {object} parameters
 * @param {string} [propertyName='value']
 */
export default async (module, component, parameters, propertyName = 'value') => {
  const componentState = component[G.STATE]
  const { key, template: defaultValue } = component[G.PROPS]
  const _key = `label.${key}`
  const { module: moduleName } = module[G.CONFIGURATION]
  const ns = translationsMap[moduleName] || moduleName
  const options = { ns, _key, defaultValue, md: true }
  const string = await module[G.ADAPTER][G.INTL].translate(_key, options)
  componentState[propertyName] = format(string, parameters)
}
