/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import { persistOption } from 'app/_shared/events'

/**
 * Event Handler Persist Option and Action
 *
 * It executes persistOption event handler,
 * followed by module action sequence actionWithHooks()
 *
 * It used in cases, where you want to reapply changes to UI, based on field state
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>}
 */
const persistOptionAndAction = async (module, component, event) => {
  try {
    persistOption(module, component, event)
    // call same action, see side effects
    await actionWithHooks(module[G.STATE][G.ACTION])([])
  } catch (e) {
    console.error(e)
  }
}

export default curry(persistOptionAndAction)
