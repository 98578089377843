/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import refresh from 'lib/sequence/model/api/refresh'
import decorate from 'lib/sequence/model/decorator'
import { _getCurrentTab } from 'app/registration/action/invite'
import asObject from 'lib/sequence/component/children/asObject'
import map from 'lib/sequence/model/api/map'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'

/**
 * Get the PERSON from the server, set the model
 * and map form fields to it accordingly.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>} void
 */
const getPerson = async (module, component, event) => {
  // model
  const model = module[G.MODEL]
  const { person: personModel } = model[G.CHILDREN]
  const { contactChannels } = personModel[G.CHILDREN]

  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const { version } = model[G.PROPS]
  const { id, token } = module[G.STATE]

  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const currentTab = _getCurrentTab(actionComponent)('contact')
  const { contactInformation } = asObject(currentTab[G.CHILDREN])

  try {
    const registerUrl = `/api/v${version}/public/register/${id}`
    const params = { token }

    const response = await httpAdapter.get({ url: registerUrl, params })
    const person = response?.person

    /**
     * Fill the model's {@code G.CACHE} from the response.
     */
    await refresh(personModel, person)

    /**
     * We need to execute {@code fromContactChannels} decorator to
     * map the individual channels to each {@code G.CHILDREN}'s
     * {@code G.STATE.value}. Otherwise email and password
     * won't show up in the form.
     */
    decorate(personModel)

    /**
     * We have saturated the model with response we got from the server.
     * It now has {@code G.CACHE} set properly. Let's map stuff.
     */
    map(personModel)(contactInformation)
    map(contactChannels)(contactInformation)
  } catch (e) {
    /**
     * The token has been used before and is invalid now.
     * Just redirect to login screen.
     */
    if (e.code === 404) {
      const moduleState = module[G.STATE]
      moduleState[G.ROUTE] = { [G.ACTION]: 'registerError' }
      await redirectSequence(module)({})
    }

    console.error(e)
  }
}

export default curry(getPerson)
