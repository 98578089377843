import { curry } from 'lib/util'
import { settings } from 'app/_shared/session'

/**
 * Adds icon information to nodes based on tenant settings.
 *
 * @param {Object} options                options for the handler
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} eventOrOptions  information about the event that triggered this handler
 *
 * @returns {(*&{node})|*}
 */
const setIcons = (options, module, component, eventOrOptions) => {
  const {
    source = 'nodeType',
    target = 'name',
    mapping = settings?.documentationTreeNodeIcons,
  } = options

  const { node } = eventOrOptions

  if (!mapping?.length) return eventOrOptions

  const newNodeIcons = settings.documentationTreeNodeIcons
  const correctIcon = newNodeIcons.find(icon => icon.nodeType === node?.[source] && icon.name === node?.[target])

  if (correctIcon) node.icon = correctIcon.icon

  node?.$children && node.$children.forEach((child) => {
    setIcons(options, module, component, { node: child })
  })

  return { ...eventOrOptions, node }
}

/**
 * Execute {@link setIcons} with the incoming {@code options}.
 * @type {(function(...[*]): (*))|*}
 */
export const setIconsWith = curry(setIcons)

/**
 * Default options should be {@code nodeType} and {@code name}.
 */
export default curry((module, component, event) => setIcons({ source: 'nodeType', target: 'name' }, module, component, event))
