/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import search from 'app/_shared/events/search'

/**
 * Fetches the tickets where {@code requesterContact}
 * is the person currently being displayed.
 *
 * @param {String} type                   listing type, ie short, verbose, etc
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component    the current action's main component
 * @param {Event} event                   incoming event
 * @returns {Promise<*>}
 */
const searchRequesterTickets = async (type, module, component, event) => {
  const person = module[G.MODEL][G.STATE][G.REF]

  return search(type, module, component, {
    type: component[G.PROPS].key,
    filter: { requesterContact: person },
  })
}

export default curry(searchRequesterTickets)
