/* eslint-disable no-unused-vars */
/* global PLATFORM, G */
import remember from 'app/_shared/events/remember'
import find from 'lib/sequence/component/children/find'
import { get, set } from 'lib/sequence/component/state/value'
import { asyncPipeSpread, asyncPipeSpreadIf, isArr, isObj, setKey } from 'lib/util'
import sequenceComponentState from 'lib/sequence/component/state'
import asObject from 'lib/sequence/component/children/asObject'

const {
  set: focus,
  unset: blur,
} = sequenceComponentState('focused')

const {
  set: setSearchMode,
} = sequenceComponentState('fromSearch')

/**
 * Checks whether {@param component} has been cached yet.
 *
 * @param {Gaia.AppModule.Spec} module       current module composition
 * @param {Gaia.Component.Spec} component current component
 * @returns {boolean}
 */
const cached = (module, component) => {
  const path = component[G.PROPS][G.ROUTE]
  const actionData = module[G.STATE][G.ACTION][G.DATA]

  return !!path.reduce((acc, key) => (isObj(acc) && acc[key] ? acc[key] : false), actionData)
}

/**
 * Maps the current search term coming from the index action to the search field.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapSearchField = module => async (components, ...args) => {
  const { term } = args[0] || {}

  const { searchField } = components

  // If we are coming from the index action, we have a term, but it's not cached yet. We need to
  // cache it now so that if the user clicks on an item and then goes back, the search term is
  // still there
  !cached(module, searchField)
    && term
    && await remember(module, searchField, { detail: { value: term } })

  term && set(searchField, term)

  return [components, ...args]
}

/**
 * Maps the current search term coming from the index action to the search results label.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapTerm = module => async (components, ...args) => {
  const { term } = args[0] || {}

  const { term: termField, searchField, tabs } = components
  const { termKey, noTermKey } = termField[G.PROPS]

  const currentSearchTerm = get(searchField)
  // our results select field is an Autocomplete component, thus it being empty may mean that its
  // value is an empty array
  const hasTerm = isArr(term) ? term.length : term
  const hasCurrentTerm = isArr(currentSearchTerm) ? currentSearchTerm.length : currentSearchTerm
  const hasSearchTerm = hasCurrentTerm || hasTerm
  const termTargetLabel = hasSearchTerm ? termKey : noTermKey

  const termLabel = await module[G.ADAPTER][G.INTL].translate(
    termTargetLabel,
    {
      key: termTargetLabel,
      ...hasSearchTerm && { term: currentSearchTerm },
      ns: 'common',
      defaultValue: get(termField),
    },
  )

  set(termField, termLabel)

  // setting fromSearch prop for all lists so that they display the proper NoData component
  tabs[G.CHILDREN].forEach((child) => {
    const { list } = asObject(child[G.CHILDREN])
    setSearchMode(list, !hasSearchTerm)
  })

  return [components, ...args]
}

/**
 * Sets the search term for each tab and also resets the tabs labels.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setTabSearch = module => async (components, ...args) => {
  const { term } = args[0] || {}

  // component
  const { tabs } = components
  const tabsChildren = tabs[G.CHILDREN] // no asObject here

  const currentSearchTerm = get(components.searchField)

  try {
    // Setting the current search tab to the lists so that they render properly
    tabsChildren.forEach((tab) => {
      const tabComponents = find(tab)
      const { list } = tabComponents
      const localSearchTerm = PLATFORM === 'mobile' ? get(tabComponents.searchField) : ''
      const searchTerm = term || localSearchTerm || currentSearchTerm
      setKey({ ...list[G.STATE][G.META], term: searchTerm }, G.META, list[G.STATE])
    })
  } catch (e) {
    console.error(e)
  }

  return [components, ...args]
}

/**
 * Focuses the search field inside the current tab a single time.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @return {function(*, ...[*]): *[]}
 */
const focusSearchField = module => (components, ...args) => {
  const { tabs } = components
  const { activeTab = 0 } = tabs[G.STATE]

  const tab = tabs[G.CHILDREN].find((child, index) => (child[G.PROPS].id || index) === activeTab)

  const { searchField } = find(tab)

  focus(searchField)

  module[G.ADAPTER][G.UI].update(module)

  blur(searchField)

  return [components, ...args]
}

/**
 * EquipmentInformation Equipment Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  mapSearchField(module),
  mapTerm(module),
  setTabSearch(module),
  asyncPipeSpreadIf(() => PLATFORM === 'mobile')(
    focusSearchField(module),
  ),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
