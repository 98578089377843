/* global G */
import { setKey } from 'lib/util'

/**
 * Model Password Validator
 *
 * validates data values on model level, propagating
 * to the same scope as children validators
 *
 * It makes remote request to login end point
 *
 * @param obj - model composition
 * @param args - prolly only value, or nothing at all
 * @return {function(...[*]): *[]}
 */
const fn = obj => async (...args) => {
  try {
    const { api, version } = obj[G.PROPS]
    const params = {
      ...obj[G.DATA],
    }
    const url = `/api/v${version}/${api}/login`
    const meta = await obj[G.ADAPTER][G.HTTP].post({ url, params })
    setKey(obj[G.ADAPTER][G.SESSION] || {}, G.SESSION, obj[G.ADAPTER])
    setKey(meta, G.META, obj[G.ADAPTER][G.SESSION])
    args.push(meta)
  } catch (e) {
    console.warn(e.message)
    console.log(e)
    throw e
  }
  return args
}

export default fn
