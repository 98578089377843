/* eslint-disable no-unused-expressions,object-curly-newline */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import { enable, disable } from 'lib/sequence/component/state/disabled'
import { show, hide } from 'lib/sequence/component/state/hidden'
import { bulk } from 'lib/util'
import { set, get } from 'lib/sequence/component/state/value'
import session from 'app/_shared/session'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Qualification finalisation step.
 *
 * Sets the step as complete when there is a selected team while enabling the selection of an
 * assignee through its corresponding field. Additionally, manages the visibility of additional
 * fields according to the selected status checkboxes.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const stepState = component[G.STATE]
  const stepChildren = asObject(component[G.CHILDREN])
  const { team, assignee, status, closed: closedView, pending: pendingView } = stepChildren
  const closed = get(status) === '80'
  const pending = get(status) === '60'

  const selectedTeam = get(team)
  const userTeam = session(module).team.ref({ wrap: true })
  !selectedTeam && set(team, userTeam)

  const teamState = team[G.STATE]
  const assigneeState = assignee[G.STATE]
  // checking whether a team is selected
  const teamKey = teamState.value?.[0]?.key
  // setting step as complete accordingly
  stepState.completed = teamKey && stepState.visited
  stepState.confirmed = stepState.completed
  // configuring assignee field
  assigneeState[G.REF] = teamKey && 'team'
  model[G.CHILDREN].team[G.STATE][G.REF] = teamKey
  teamKey ? enable(assignee) : disable(assignee)
  // showing or hiding additional fields depending on the selected checkboxes
  bulkHide(closedView, pendingView)
  closed && bulkShow(closedView) && hide(pendingView)
  pending && bulkShow(pendingView) && hide(closedView)

  return args
}
