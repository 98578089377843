/* global G */
import { curry, setKey } from 'lib/util'

/**
 * Default ACL props
 *
 * For cells, it doesn't make sense to default to something like {@code hidden: true}. Because this
 * would break the list structure (missing cells). Instead of doing this, if an acl check fails
 * we will return {@code acl: false} to the cell, and let it handle what to do in that case.
 *
 * Regardless, like with other acl checks we can overwrite that with the {@code aclProps} property
 * in a cell:
 *
 * @example
 * {
 *   view: 'Element/List/Item/Cell/ServiceItem/WithSerial',
 *   aclContextRole: ['MasterDataAdministrator'],
 *   aclProps: {
 *     disabled: true
 *   },
 *   options: { ... },
 * },
 * @type {{aclProps: {acl: boolean}}}
 * @private
 */
const _defaultAclProps = {
  aclProps: { acl: false },
}

/**
 * Obtains ACL props by calling the acl's cell function and returns its result.
 *
 * @param {Gaia.AppModule.Spec} module  application module
 * @param {Gaia.Component} component    parent component
 * @param {Object} cell                 the cell to perform the acl check on
 *
 * @return {Object}                     acl props
 */
const applyACLProps = (module, component, cell) => {
  // Early return if the cell has no acl props
  if (!cell.acl && !cell.aclContextRole && !cell.aclContext) return {}

  // Mocking acl structure so that the check function can investigate it
  cell.acl && setKey(cell.acl, G.ACL, cell)
  setKey({
    ...cell.aclProps ? { aclProps: cell.aclProps } : { ..._defaultAclProps },
    ...cell.aclContextRole && { aclContextRole: cell.aclContextRole },
    ...cell.aclContext && { aclContext: cell.aclContext },
  }, G.CONFIGURATION, cell)

  return module[G.ADAPTER][G.ACL].cell(component, cell)
}

/**
 * Cell ACL Getter
 *
 * this is a mandatory event handler for lists. It allows working with protected acl property of
 * the cell which is obfuscated from the UI layer.
 *
 * @example config
 * { ..., options: { ..., events: { ..., acl: 'cell', ... }}}
 * @example module/[name]/events.js
 * import cell from 'app/_shared/acl/cell'
 * export default { ..., acl: { cell }, ...}
 *
 * @param {Gaia.AppModule.Spec} module  application module
 * @param {Gaia.Component} component    parent component
 * @param {Object} cell                 cell configuration
 *
 * @return {Object}                     acl props
 */
const acl = (module, component, cell) => applyACLProps(module, component, cell)

export default curry(acl)
