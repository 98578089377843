/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Count Favorites Event Handler
 *
 * Counts the favorites of {@param type}.
 *
 * @param {string} type                     type to count
 * @param {Gaia.AppModule.Spec} module      the current module object
 * @param {Gaia.Component.Spec} component   action component
 * @param {PlatformEvent} event             event that triggered this handler
 * @returns {*|number}
 */
const countFavorites = (type, module, component, event) => {
  const { favorites } = module[G.ADAPTER][G.SESSION][G.STATE][G.SETTINGS]
  const typeFavorites = favorites.filter(favorite => favorite.type === type)

  return typeFavorites?.length || 0
}

export default curry(countFavorites)
