/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Decreases the current active step by 1.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 * @returns {Promise<void>}
 */
const back = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { cart } = asObject(actionComponent[G.CHILDREN])
  const cartState = cart[G.STATE]
  const { activeStep = 0 } = cartState

  cartState.activeStep = activeStep - 1

  await actionWithHooks(module[G.STATE][G.ACTION])([])
}

export default curry(back)
