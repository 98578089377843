/* eslint-disable no-unused-vars,no-nested-ternary */
/* global G */
import { curry } from 'lib/util'
import { bulkMatch, rules } from 'lib/validator/isTimeEntry'

/**
 * Padding {@param x} to two digits.
 *
 * @param {Number} x    number to pad
 * @return {*}
 */
const padded = x => String(x).padStart(2, '0')

/**
 * Return a padded version of {@param x}.
 *
 * If {@param min} is {@code true}, return {@code 59} as its highest value.
 * If {@param x} is falsy, return {@code 0}.
 *
 * @param {*} x    string to strip.
 * @param {Boolean} min whether {@param x} is in minutes
 * @return {string|*}
 * @private
 */
const _formatDigit = (x, min = false) => (!x && `00${min ? 'm' : 'h'}`)
    || (min
      ? parseInt(x, 10) > 59 ? '59m' : `${padded(x)}m`
      : `${padded(x)}h`)

/**
 * Event Handler ToTimeEntry
 *
 * Converts the given input into the format {@code hh:mm) if applicable and returns it.
 *
 * NOTE: Ideally we would need a decorator for this. We could use it when we fetch the
 * note as well as when we create it. However, a decorator needs a (note) model. We
 * could add one, but the way we handle (create, delete, ...) notes (via the adapter)
 * is too different from how we would do it with a model. More effort than usage to
 * implement it at this stage.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @type {(function(...[*]): (*))|*}
 */
export const toTimeEntry = curry((module, component, event) => {
  const { value } = event?.detail || event
  const parsedValue = value.trim()

  const [match, h, separator, m] = bulkMatch(parsedValue)(rules) || []

  // no match
  if (!match) return ''

  // float
  if (separator === '.' || separator === ',') {
    return `${_formatDigit(h)} ${_formatDigit(Math.floor(60 * parseFloat(`0.${m}`)), true)}`
  }

  // int
  if (separator === undefined) {
    return `${_formatDigit(Math.floor(parseInt(match, 10) / 60))} ${_formatDigit(parseInt(match, 10) % 60, true)}`
  }

  // 12h, 12h30m, 12h30, 12h 30m, 12h 30
  return `${_formatDigit(h)} ${_formatDigit(m || '00', true)}`
})

/**
 * Event Handler FromTimeEntry
 *
 * Converts the given input into the seconds and returns it.
 *
 * NOTE: Ideally we would need a transformer for this. We could use it when we post the
 * note to the server. However, a transformer needs a (note) model. We could add one, but
 * the way we handle (create, delete, ...) notes (via the adapter) is too different from
 * how we would do it with a model. More effort than usage to implement it at this stage.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @type {(function(...[*]): (*))|*}
 */
export const fromTimeEntry = curry((module, component, eventOrOptions) => {
  const { value } = eventOrOptions?.detail || eventOrOptions
  const parsedValue = value.trim()

  const [match, h, separator, m] = bulkMatch(parsedValue)(rules) || []

  if (!match) return ''

  // float
  if (separator === '.' || separator === ',') {
    return parseInt(h, 10) * 3600 + Math.floor(3600 * parseFloat(`0.${m}`))
  }

  // int
  if (separator === undefined) {
    return parseInt(match, 10) * 60
  }

  // 12h, 12h30m, 12h30, 12h 30m, 12h 30
  return parseInt(h, 10) * 3600 + parseInt(m || 0, 10) * 60
})

export const time = {
  toTimeEntry,
  toSeconds: fromTimeEntry,
}

export {
  time as default,
}
