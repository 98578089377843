/* eslint-disable no-unused-vars */
import { curry } from 'lib/util'

/**
 * Log Event Handler
 *
 * Prints a message to the console.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 */
const emptyList = async (module, component, event) => []

export default curry(emptyList)
