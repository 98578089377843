import noop from '@gaia/util/noop/action'
import tickets from './action/tickets'
import requests from './action/requests'
import users from './action/user'
import userEdit from './action/user/edit'
import userBulk from './action/user/bulk'
import userCreate from './action/user/create'
import teamEdit from './action/team/edit'
import teamCreate from './action/team/create'

export default {
  tickets,
  requests,
  users,
  usersNew: users,
  userEdit,
  userBulk,
  userCreate,
  invitations: noop,
  teams: noop,
  teamEdit,
  teamCreate,
  teamSubmit: noop,
}
