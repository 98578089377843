/* eslint-disable no-unused-vars,no-unused-expressions */
/* global G */
import { curry } from 'lib/util'

/**
 * Shows a modal informing the user that the current ticket has no target request.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {Promise<void>}
 */
const _showModal = async (module) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: await module[G.ADAPTER][G.INTL]._t(
      'dialog.noRequest.title',
      {
        ns: 'ticket',
        _key: 'dialog.noRequest.title',
        defaultValue: 'Warning',
      },
    ),
    text: await module[G.ADAPTER][G.INTL]._t(
      'dialog.noRequest.text',
      {
        ns: 'ticket',
        _key: 'dialog.noRequest.text',
        defaultValue: 'Requester is not a user, yet. Waiting for invitation function.',
      },
    ),
    children: {
      cancel: {
        key: 'ok',
        value: await module[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
  })

  return true
}

/**
 * Checks whether the current ticket has a request as target and shows a modal informing the
 * user otherwise.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @return {Promise<void>}
 */
const noRequestModal = async (module, component, event) => {
  const model = module[G.MODEL]
  const request = model[G.CHILDREN].request[G.CACHE]

  !request
    && await _showModal(module)
    && throw Error('There is no target request that would receive the message.')
}

export default curry(noRequestModal)
