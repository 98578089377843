/* global G */
import { curry, getFirstItem, isObj, isStr } from 'lib/util'

const descriptor = 'model::transformer::toArray'

/**
 * To Array (Model) Transformer
 *
 * puts input data into an array
 *
 * @param {String} key        key of the attribute
 * @param {Boolean} nullable  whether the attribute can be nulled
 * @return {*}
 */
const toArray = (key, nullable = false) => curry((obj, data) => {
  console.warn(descriptor)

  const _data = data
  try {
    const item = obj[G.CHILDREN][key]
    const { type } = item[G.PROPS]
    const attribute = getFirstItem(obj[G.STATE][G.DATA][type][key])

    /**
     * Account for different structures:
     * - [{..., key: "foo"}]
     * - {..., key: foo}
     * - ["foo"]
     * - "foo"
     */
    _data[type][key] = [
      (isObj(attribute) && attribute?.key) || (isStr(attribute) && attribute),
    ]

    if (!attribute) {
      nullable ? _data[type][key] = null : delete _data[type][key]
    }
  } catch (e) {
    console.warn(descriptor, e)
  }

  return _data
})

export default toArray
