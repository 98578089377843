import processScannedCode from 'app/camera/events/processScannedCode'
import toggleFlashlight from 'app/camera/events/toggleFlashlight'

export default {
  onClick: {
    toggleFlashlight,
  },
  onScan: {
    processScannedCode,
  },
}
