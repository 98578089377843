/* global G */
import { asyncPipeSpread, getFirstItem, isArr, setKey } from 'lib/util'
import validate from 'lib/sequence/model/validate'
import find from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { get, reset, set } from 'lib/sequence/component/state/value'
import { v4 as uuidV4 } from 'uuid'
import { setData } from 'lib/sequence/model/api/set'
import asObject from 'lib/sequence/component/children/asObject'
import roles from 'model/account/collection/roles'
import { hasInternalParent } from 'app/organisation/action/detail'
import showDialog, { showBlockingDialog } from 'app/_shared/events/dialog'
import { isAction } from 'app/_shared/action/util'
import { disable } from 'lib/sequence/component/state/disabled'

/**
 * An error dialog informing that the user in question can't be transitioned from the
 * {@code Requester} to a full business role.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @param {Gaia.Component.Spec} component - action component
 * @returns {Promise<void>}
 * @private
 */
const _showErrorDialog = async (module, component) => {
  await showDialog(module, component, {
    title: {
      ns: 'admin',
      key: 'dialog.transitionUserError.title',
      defaultValue: 'Approval error',
    },
    text: {
      ns: 'admin',
      key: 'dialog.transitionUserError.text',
      defaultValue: 'When approving, the user needs to be validated and must have only one regular role. Please check conditions.',
    },
  })
}

/**
 * Disables validation of the phone contact channel.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const disablePhoneValidation = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { person } = model[G.CHILDREN]
  const { contactChannels } = person[G.CHILDREN]
  const { phone } = asObject(contactChannels[G.CHILDREN])

  const { phone: phoneField } = components
  const valueLength = get(phoneField)?.length

  // Disable phone number validators (isPhoneNumber should still be enabled if field has value)
  phone[G.VALIDATOR]?.forEach((validator) => {
    disable(validator, validator.type === 'isPhoneNumber' ? !valueLength : true)
  })

  return [components, ...args]
}

/**
 * Sets the {@param module}'s state as error if any of the model's sub-models or the model itself
 * has error state.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const updateModuleState = module => async (components, ...args) => {
  const moduleState = module[G.STATE]
  const model = module[G.MODEL]
  const { person } = model[G.CHILDREN]

  const { roles, msgRolesError } = components

  const modelError = model[G.STATE][G.ERROR]
  const personError = person[G.STATE][G.ERROR]

  moduleState[G.ERROR] = modelError || personError

  modelError?.roles
    ? show(msgRolesError) && set(msgRolesError, roles[G.STATE].helperText)
    : hide(msgRolesError) && reset(msgRolesError)

  return [components, ...args]
}

/**
 * Validates the account model and sets the {@code G.BULK} flag if its state doesn't contain an
 * error.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const validateAccount = module => async (components, ...args) => {
  // model
  const model = module[G.MODEL]
  const modelState = model[G.STATE]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  // component
  const { username, roles: rolesField, status, organisation } = components

  // Checks if the user changed primary role to 'blocked'
  const rolesValue = get(rolesField)
  const currentRoles = isArr(rolesValue) ? rolesValue : Object.values(rolesValue)
  const userRoles = currentRoles
    .filter(currentRole => roles.map(userRole => userRole.key).includes(currentRole.role))
  const blocked = userRoles.some(role => role.role === 'Blocked')
      && model[G.CACHE]?.value?.roles?.every(role => !role.includes('Blocked'))

  // If role changed to 'blocked', set status and parent org
  let confirmed = false
  if (blocked) {
    confirmed = await showBlockingDialog(module, actionComponent, {
      title: {
        ns: 'admin',
        key: 'dialog.blockConfirm.title',
        defaultValue: 'Confirm blocking',
      },
      text: {
        ns: 'admin',
        key: 'dialog.blockConfirm.text',
        defaultValue: 'Do you really want to block the user?',
      },
    })
  }

  const originalRoles = model[G.CACHE]?.value?.roles || []

  // Dialog confirmed
  blocked && confirmed
  && set(status, 90)
  && set(organisation, [{ key: 'ORGANISATION:BA' }])
  && set(rolesField, currentRoles.map(role => (
    role.role === 'Blocked' ? { ...role, atOrg: 'ORGANISATION:BA' } : role
  )))

  // Dialog cancelled, resetting blocked role
  blocked && !confirmed && set(rolesField, currentRoles.map(role => (
    role.role === 'Blocked'
      ? { ...role, role: originalRoles.find(x => x.includes(role.atOrg)).split('@')[0] }
      : role
  )))

  await validate(model)(actionComponent)

  // Doing this will add the _id to the payload
  !modelState[G.ERROR] && setKey(true, G.BULK, modelState)

  // We are adding a new user
  if (!modelState[G.ERROR] && !modelState[G.REF]) {
    setKey(`org.couchdb.user:${get(username)}`, G.REF, modelState)
    // This makes sure account in _users gets created
    setData(model, { account: get(username) })
  }

  return [components, blocked, ...args]
}

/**
 * Validates the person model and sets the {@code G.BULK} flag if its state doesn't contain an
 * error.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const validatePerson = module => async (components, blocked, ...args) => {
  const model = module[G.MODEL]
  const { person } = model[G.CHILDREN]
  const personState = person[G.STATE]
  const { contactChannels } = person[G.CHILDREN]
  const { email } = asObject(contactChannels[G.CHILDREN])
  const personOrgModel = person[G.CHILDREN].organisation

  // component
  const { person: personForm, organisation, username } = components

  // Adding users username (email) to the persons contact channels
  !email[G.CACHE] && setKey(get(username), 'value', email[G.DATA])

  // Adding users org to person, so that validation will add the org also to the person's refs
  setKey(getFirstItem(get(organisation))?.key, G.REF, personOrgModel[G.STATE])

  // If the user has been blocked, it has also been verified by that
  blocked && setKey(true, 'verified', person[G.STATE])

  await validate(person)(personForm)
  await validate(contactChannels)(personForm)

  !personState[G.ERROR] && setKey(true, G.BULK, personState)

  // We are creating a new person
  if (!personState[G.REF]) {
    setKey(uuidV4(), G.REF, personState)
  }

  return [components, ...args]
}

/**
 * Determines whether we should display an error dialog if the user can't be transitioned from
 * the {@code Requester} to a full business role.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*): function(*, ...[*]): Promise<void>}
 */
const displayUserErrorDialog = module => component => async (components, ...args) => {
  // In case we have a validation error, just skip this, the {@link check} hook will abort.
  if (module[G.STATE][G.ERROR] || isAction(module, 'userCreate')) return args

  // model
  const model = module[G.MODEL]
  const organisationModel = model[G.CHILDREN].organisation
  const { [G.HTTP]: httpAdapter } = model[G.ADAPTER]
  const { roles: rolesModel } = model[G.CHILDREN]

  // check if user is only requester
  const allUserRoles = rolesModel[G.CACHE].map(x => x.role)
  const businessUserRoles = allUserRoles.filter(x => roles.map(y => y.key).includes(x))
  const isOnlyRequester = businessUserRoles.length === 1 && businessUserRoles[0] === 'Requester'

  // component
  const { roles: rolesField, organisation } = components
  const organisationField = getFirstItem(get(organisation)) || {}
  const rolesValue = get(rolesField)
  const currentRoles = isArr(rolesValue) ? rolesValue : Object.values(rolesValue)
  const addedUserRoles = currentRoles
    .filter(currentRole => roles.map(userRole => userRole.key).includes(currentRole.role))
    .map(filteredRole => filteredRole.role)

  const hasPrimaryRoleChanged = isOnlyRequester
      && (addedUserRoles.some(role => role !== 'Requester' && role !== 'Blocked')
          || addedUserRoles.length > 1)

  const hasNewRoles = currentRoles.filter(
    x => roles
      .map(y => y.key)
      .includes(x.role) && x?.index === undefined, // Only get new roles
  )?.length || false

  // IA added a new role while current role is still 'Requester'
  if (isOnlyRequester && hasNewRoles) {
    await _showErrorDialog(module, component)
    throw Error('User has multiple roles')
  }

  // We need to get a fresh copy of the users org before checking if it's internal. It could be
  // that it WAS internal, but the IA published it in a new tab without refreshing this page.
  let currentOrg
  try {
    const { version, api } = organisationModel[G.PROPS]
    const url = `/api/v${version}/${api}/${organisationField?.key}`
    currentOrg = await httpAdapter.get({ url })
  } catch (e) {
    console.error(e)
  }

  // IA changed primary role but has not checked 'verify'
  if (hasPrimaryRoleChanged && hasInternalParent(currentOrg)) {
    await _showErrorDialog(module, component)
    throw Error('User\'s organisation is not published yet')
  } else {
    return args
  }
}

/**
 * Validates visible forms and deduces the data used to create the bulk request's payload.
 *
 * This is a pure business logic action, it has no UI.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 */
export default module => component => async (...args) => asyncPipeSpread(
  disablePhoneValidation(module),
  validateAccount(module),
  validatePerson(module),
  updateModuleState(module),
  displayUserErrorDialog(module)(component),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
