/* global G */
import { curry, def } from 'lib/util'

const descriptor = 'model::transformer::toContactChannels'

/**
 * To ContactChannels Transformer
 *
 * Transforms a contactChannels object to a contactChannels array.
 *
 * @example
 * const contactChannelsObject = {
 *  phone: '+49 (30) 3432622-113',
 *  officePhone: '+49 (30) 3432622-113',
 *  email: '+49 (30) 3432622-113'
 *}
 *
 * @example
 * const contactChannelsArray = [
 *   {
 *      type: '',
 *      name: 'phone',
 *      value: '+49 (30) 3432622-113'
 *   },
 *   {
 *      type: '',
 *      name: 'officePhone',
 *      value: '+34 478 98 51 45'
 *   },
 *   {
 *      type: '',
 *      name: 'email',
 *      value: 'e.zulauf@weber.de'
 *   }
 * ]
 *
 * @param {String} key          the name of the attribute to store the data to
 * @param {Boolean} nullable    whether an empty contact channel should be nulled
 *
 * @return {Object}             the payload data
 */
const toContactChannels = (key, nullable = false) => curry((obj, data) => {
  console.warn(descriptor)

  const _data = data
  const attribute = obj[G.CHILDREN][key]
  const attributeKeys = Object.keys(attribute[G.CHILDREN])

  _data.value[key] = attributeKeys.reduce((acc, attributeKey) => {
    const child = attribute[G.CHILDREN][attributeKey]
    const fallback = child[G.CACHE] || attribute[G.DATA][attributeKey]
    const value = def(child[G.DATA].value) ? child[G.DATA].value : fallback
    const { key: name, channelType: type } = child[G.CONFIGURATION].options

    value && acc.push({
      type,
      name,
      value,
    })
    return acc
  }, [])

  // Remove empty array if there are no contactChannels
  !_data.value[key].length && !nullable && delete _data.value[key]

  return _data
})

export default curry(toContactChannels)
