/* global G */
import { curry, getFirstItem } from 'lib/util'

/**
 * Get Attachment Info Event Handler
 *
 * Fetches an attachment document that belongs to a specific {@link articleId} and returns it.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           information about the event that triggered this handler
 * @returns Object
 */
const getAttachmentInfo = async (module, component, event) => {
  const { node: { articleId = null } = {} } = event?.detail || event || {}
  const httpAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]
  const { version } = module[G.MODEL][G.PROPS]

  const url = `/api/v${version}/search`
  const params = {
    type: 'attachment',
    limit: 1,
    filter: {
      parent: articleId,
    },
  }

  if (articleId) {
    try {
      const result = await httpAdapter.post({ url, params })
      const data = getFirstItem(result?.value)

      return data || { value: { name: '' } }
    } catch (e) {
      console.error(e)
    }
  }

  return {}
}

export default curry(getAttachmentInfo)
