/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { get, reset } from 'lib/sequence/component/state/value'
import map from 'lib/sequence/model/api/map'

const getId = item => item && item[0] && (item[0].key || item[0])

/**
 * Returns the IDs of the organisations that have to be offered to the user as suggestion, according
 * to the previously selected items.
 *
 * @param {Gaia.Model} model  the current ticket model
 * @returns {string[]}        an array containing the IDs of the objects to suggest
 */
const getSuggestionIds = (model) => {
  const ids = []
  const { item } = model[G.CHILDREN]
  const { installedAt, serviceBy } = item[G.CACHE].refs || {}
  const installedAtId = getId(installedAt)
  const serviceById = getId(serviceBy)

  installedAtId && ids.push(installedAtId)
  serviceById && ids.push(serviceById)

  return ids.reduce((acc, id) => {
    !acc.includes(id) && acc.push(id)
    return acc
  }, [])
}

/**
 * Service Partner identification step.
 *
 * If no service partner is selected, displays a list to search among them and a button to add a
 * new one. Otherwise, sets the step as complete and displays the selected service partner's data
 * together with buttons to edit it, to clear the confirm view and to go to the next step.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const { additionalParty, item } = model[G.CHILDREN]
  const stepState = component[G.STATE]
  const { search, confirm } = asObject(component[G.CHILDREN])
  const { searchField, list, suggestions } = asObject(search[G.CHILDREN])
  const { organisation } = asObject(confirm[G.CHILDREN])
  // if there is an additional party selected
  if (additionalParty[G.CACHE]) {
    // clear search field
    reset(searchField)
    // map selected model to the view
    map(additionalParty)(organisation)
    // set step as complete
    stepState.completed = true
    // fill step's title and subtitle with model data
    stepState.title = additionalParty[G.CHILDREN].name[G.CACHE]
    stepState.subtitle = additionalParty[G.CHILDREN].address[G.CHILDREN].summary[G.CACHE]
  } else { // If there is no additional party selected
    // set step as incomplete
    stepState.completed = false
    // clear step's title and subtitle
    stepState.title = ''
    stepState.subtitle = ''
    // hide or show the list depending on whether the search field has any value and there are
    // suggestions to show
    if (!get(searchField)) {
      if (item && item[G.CACHE]) {
        suggestions[G.STATE][G.REF] = getSuggestionIds(model)
        show(suggestions)
      }
      hide(list)
    } else {
      hide(suggestions)
      show(list)
    }
  }

  return args
}
