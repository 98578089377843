import { curry } from 'lib/util'

/**
 * Set the {@code parent} property of the current node to {@code parent} and {@code id} to either
 * {@code node._id} or a concatenation of the current {@code index} and {@code node.name}
 *
 * Recursively call itself for every element of {@code node.$children}.
 *
 * Translating labels
 * - {@code key}: name of the property to get the node type from (defaults to {@code "nodeType"})
 * - {@code source}: name of the property to get the key of the label from
 *   (defaults to {@code name})
 * - {@code target}: name of the property to save the value of the label to
 *   (defaults to {@code name})
 *
 * @example Given the following structure
 * {
 *   name: "SCC"
 *   nodeType: "product"
 * }
 *
 * and the following labels:
 *  "product": {
 *     "iCombi": "iCombi",
 *     "iVario": "iVario",
 *     "SCC": "SelfCookingCenter® / CombiMaster® Plus",
 *     "VCC": "VarioCookingCenter®"
 *   },
 *
 * with default values for {@code key}, {@code source} and {@code target}, it will get translated to
 * {
 *   name: "SelfCookingCenter® / CombiMaster® Plus",
 *   nodeType: "product",
 * }
 *
 * @example Given the following structure (and the same labels as above)
 * {
 *   name: "Foo",
 *   nodeType: "product",
 *   productType: "SCC",
 * }
 *
 * with the following values {@code source = "productType"} and {@code target = "value"},
 * it will get translated to
 * {
 *   name: "Foo",
 *   nodeType: "product",
 *   productType: "SCC",
 *   value: "SelfCookingCenter® / CombiMaster® Plus",
 * }
 *
 * @param {Object} options                          options for the handler
 * @param {Gaia.AppModule.Spec} module              the current module composition object
 * @param {Gaia.Component.Spec} component           the current action's main component
 * @param {Event || {}} eventOrOptions              the event object that triggered this handler
 * @param {Object} [eventOrOptions.node]            the current node
 * @param {String[]} [eventOrOptions.breadcrumbs]   the id of the note to find.
 * @returns {*}
 */
const setLabels = (options, module, component, eventOrOptions) => {
  const {
    key = 'nodeType',
    target = 'name',
    source = 'name',
  } = options

  const { node, labels } = eventOrOptions?.detail || eventOrOptions || {}

  const nodeKey = node?.[key]
  const nodeSource = node?.[source]
  const labelsKey = node?.labels?.[nodeKey] || labels?.[nodeKey]

  // Translating the node name if possible
  if (nodeKey && labelsKey) {
    node[target] = labelsKey?.[nodeSource]
        || node?.[target]
        || node.name
  }

  node?.$children?.length && node.$children.forEach((child) => {
    setLabels(options, module, component, { ...eventOrOptions, node: child })
  })

  return { ...eventOrOptions, node }
}

/**
 * Executes {@link setLabels} with the incoming {@code options}
 * @type {(function(...[*]): (*))|*}
 */
export const setLabelsWith = curry(setLabels)

/**
 * Default options should be {@code name} and {@code name}.
 */
export default curry((module, component, event) => setLabels({ source: 'name', target: 'name' }, module, component, event))
