/* eslint-disable no-unused-vars,no-param-reassign */
/* global G */
import find from 'lib/sequence/component/children/find'
import read from 'lib/sequence/model/api/read'
import { hide, show } from 'lib/sequence/component/state/hidden'
import reset from 'lib/sequence/model/api/reset'
import { deleteKey } from 'lib/util'

/**
 * Confirm action.
 *
 * Displays a message and the ticket number after creating a new ticket or a new note through the
 * form action.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const modelState = model[G.STATE]
  const components = find(component)
  const { info, requestNumberLabel, noteAddedLabel } = components

  await read(model)(info)

  reset(model)

  modelState[G.NOTE]
    ? show(noteAddedLabel) && hide(requestNumberLabel)
    : show(requestNumberLabel) && hide(noteAddedLabel)

  deleteKey(G.USER, model[G.STATE])
  deleteKey(G.NOTE, model[G.STATE])

  return args
}
