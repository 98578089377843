/* global G */
import { curry } from 'lib/util'

/**
 * creates href link from given component
 *
 * @example
 * {
 *  module: 'Card/Form',
 *  view: 'Component/Grid/SectionAsLink',
 *  options: {
 *    md: 12,
 *    xl: 12,
 *    events: {
 *      onOpen: 'linkTo',
 *    },
 *    target: '_blank',
 *  },
 *  route: {
 *    action: 'detail',
 *  },
 *  ...
 *  }
 *
 * @type {Gaia.AppModule.EventHandler}
 * @return {string}
 */
// eslint-disable-next-line no-unused-vars
const itemLink = (module, component, event) => component[G.STATE]

export default curry(itemLink)
