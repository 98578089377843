import noop from '@gaia/util/noop/action'
import submit from './action/submit'

export default {
  index: noop,
  new: noop,
  edit: noop,
  detail: noop,
  submit,
  remove: noop,
}
