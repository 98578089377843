/* global G */
/* eslint-disable no-unused-vars */
import { curry, setKey } from 'lib/util'
import { get, set } from 'lib/sequence/component/state/value'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Retrieves applicable supportedBy organisations based on country and organisation types, prefills
 * the supportedBy field, and updates the UI.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 *
 * @returns {Promise<void>}
 */
const prefillSupportedBy = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { supportedBy: supportedByField } = asObject(actionComponent[G.CHILDREN])

  /**
   * Getting {@code type} and {@country}.
   *
   * This handler may be called from various modules, therefore use
   * the action component instead of model for retrieving values.
   */
  const { address, type: typeField } = asObject(actionComponent[G.CHILDREN])
  const { country: countryField } = asObject(address[G.CHILDREN])
  const type = typeField[G.STATE].value || ''
  const [{ key: country = '' } = {}] = countryField[G.STATE].value || [{}]

  const { version } = module[G.MODEL][G.PROPS]
  const { api: { listing } } = supportedByField[G.PROPS]
  const params = { country, type }
  const url = `/api/v${version}/list/${listing}`
  const hasValue = !!get(supportedByField)?.[0]

  try {
    const remoteAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]
    const result = await remoteAdapter.post({ url, params })

    /**
     * Depending on what we are getting back from the server,
     * we need to set {@code value} and {@code selection}
     * in {@code supportedByField}'s state accordingly.
     */
    // No items returned
    !result.value.length
    && setKey([], 'selection', supportedByField[G.STATE])

    // One item returned
    result.value.length === 1
    && setKey(result.value, 'selection', supportedByField[G.STATE])
    && !hasValue && set(supportedByField, [result.value[0]])

    // Multiple items returned
    result.value.length > 1
    && setKey(result.value, 'selection', supportedByField[G.STATE])

    // Update UI
    module[G.ADAPTER][G.UI].update(module)
  } catch (e) {
    console.error('search:: tried and failed getting remote resources', url)
  }
}

export default curry(prefillSupportedBy)
