/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import { withGetterSetter } from 'trait/with'
import { usesGetterSetter } from 'trait/uses'
import formatDate from './decorator/formatDate'

const descriptor = 'model::ticket::submit'

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    timestamp: formatDate('timestamp', 'timestamp'),
  }),
)
