import { curry } from 'lib/util'
import searchRequests from 'app/_shared/events/search'
import searchWith from 'app/_shared/events/search/with'
import countRequests from 'app/_shared/events/search/count'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'

/**
 * Get the count of viewable requests by searching the user's
 * message threeads. In case there is only one thread,
 * get the corresponding request as well.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 *
 * @return {Object}                     the count and optionally a request
 */
const countRequestsFn = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const additionalParameters = await searchWith(['filter'], module, component, event)

  const count = await countRequests(null, module, component, additionalParameters)
  if (count.total.count !== 1) return { total: { count: count.total.count } }

  const item = await searchRequests(null, module, component, additionalParameters)
  const translatedType = listTicketTypes(module, component, item[0]?.value?.type)

  return {
    total: { count: count.total.count },
    item: {
      title: item[0]?.value?.number,
      body: translatedType[0]?.value,
      key: item[0]?.key,
    },
  }
}

export default curry(countRequestsFn)
