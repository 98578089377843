/**
 * User role type definition
 *
 * @typedef {Object} userRole
 * @property {String} value                 the readable string of the user role
 * @property {String} key                   the role key
 * @property {Boolean} hasMessages          whether this role can send/receive messages
 * @property {Boolean} [canBeMentioned]     whether this role can be mentioned using {@link Mention}
 * @property {Boolean} [canBeInvited]       whether this role is an acceptable invitation role
 * @property {Boolean} [canBePublishedTo]   whether a request can be published to this role
 *                                          if {@code true}, checkbox "Publish to customer" in
 *                                          ticket wizard will be shown
 * @property {Boolean} [canMaintainDevices] whether this role can maintain a service item. If
 *                                          {@code true}, the "add to maintained devices" checkbox
 *                                          will be shown in request/serviceItem wizard
 */

/**
 * Invitation roles collection
 *
 * Contains a list of possible roles a user can be have.
 *
 * @typedef {userRole[]} userRoles
 */
export default [
  {
    value: 'Waiting for approval',
    key: 'Requester',
    canBePublishedTo: true,
    canBeInvited: true,
    hasMessages: true,
    hasTeams: false,
  },
  {
    value: 'Verified customer',
    key: 'Customer',
    canBePublishedTo: true,
    canBeInvited: true,
    hasMessages: true,
    hasTeams: false,
  },
  {
    value: 'Blocked',
    key: 'Blocked',
    canBeMentioned: false,
    canBeInvited: false,
    hasTeams: false,
    hasMessages: false,
  },
  {
    value: 'Customer Service Agent',
    key: 'CustomerServiceAgent',
    canBeMentioned: true,
    canBeInvited: true,
    hasMessages: true,
    hasTeams: true,
  },
  {
    value: 'Customer Service Manager',
    key: 'CustomerServiceManager',
    canBeMentioned: true,
    canBeInvited: true,
    hasTeams: true,
    hasMessages: true,
  },
  {
    value: 'Customer Service Representative',
    key: 'CustomerServiceRepresentative',
    canBeMentioned: true,
    canBeInvited: true,
    hasMessages: true,
    hasTeams: true,
  },
  {
    value: 'Field Service Engineer',
    key: 'FieldServiceEngineer',
    canMaintainDevices: true,
    canBePublishedTo: true,
    canBeInvited: true,
    hasMessages: true,
    hasTeams: false,
  },
  {
    value: 'Customer Service External',
    key: 'CustomerServiceExternal',
    hasMessages: false,
    hasTeams: false,
  },
  {
    value: 'Customer Service Cooperator',
    key: 'CustomerServiceCooperator',
    canMaintainDevices: false,
    canBePublishedTo: true,
    canBeInvited: true,
    hasMessages: false,
    hasTeams: false,
  },
  {
    value: 'Sales',
    key: 'Sales',
    canBePublishedTo: true,
    canBeMentioned: true,
    canBeInvited: true,
    hasMessages: true,
    hasTeams: true,
  },
  {
    value: 'Customer Service Expert',
    key: 'CustomerServiceExpert',
    hasMessages: true,
    hasTeams: true,
  },
  {
    value: 'Master Data Administrator',
    key: 'MasterDataAdministrator',
    hasMessages: true,
    hasTeams: true,
  },
  {
    value: 'Instance Administrator',
    key: 'InstanceAdministrator',
    hasMessages: true,
  },
  {
    value: 'Technical Documentation and Parts',
    key: 'TechdocPartsReader',
    canMaintainDevices: false,
    canBePublishedTo: false,
    canBeInvited: false,
    hasMessages: false,
    hasTeams: false,
  },
  {
    value: 'Technical Documentation and Parts Admin',
    key: 'TechdocPartsAdmin',
    canMaintainDevices: false,
    canBePublishedTo: false,
    canBeInvited: false,
    hasMessages: false,
    hasTeams: false,
  },
  {
    value: 'Account In Shop',
    key: 'OrderInShop',
    canMaintainDevices: false,
    canBePublishedTo: false,
    canBeInvited: false,
    hasMessages: false,
    hasTeams: false,
  },
]
