import belongsToOneOfMyTeams from 'app/_shared/events/search/query/belongsToOneOfMyTeams'
import forwardedByOneOfMyTeams from 'app/_shared/events/search/query/forwardedByOneOfMyTeams'
import IAmSubmitterOrRecipient from 'app/_shared/events/search/query/IAmSubmitterOrRecipient'
import userHasIdAndRoles from 'app/_shared/events/search/query/userHasIdAndRoles'
import userHasIdAndRoleAtOrg from 'app/_shared/events/search/query/userHasIdAndRoleAtOrg'
import userHasIdAndIsRequester from 'app/_shared/events/search/query/userHasIdAndIsRequester'
import personIsInvited from 'app/_shared/events/search/query/personIsInvited'

const query = {
  belongsToOneOfMyTeams,
  forwardedByOneOfMyTeams,
  IAmSubmitterOrRecipient,
  userHasIdAndRoles,
  userHasIdAndRoleAtOrg,
  userHasIdAndIsRequester,
  personIsInvited,
}

export {
  query as default,
  belongsToOneOfMyTeams,
  forwardedByOneOfMyTeams,
  IAmSubmitterOrRecipient,
  userHasIdAndRoles,
  userHasIdAndRoleAtOrg,
  userHasIdAndIsRequester,
  personIsInvited,
}
