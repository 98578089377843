/* global G */
import { curry } from 'lib/util'
import toggle from 'app/_shared/events/favorite/toggle'
import getFavorite from 'app/_shared/events/favorite/get'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import getQuickAction from 'app/_shared/events/appbar/quickActions/get'

/**
 * Toggle Node Favorite Handler
 *
 * Wraps {@link toggle} and passes it the current node, extracted from the module state,
 * for the {@code component} passed in the {@code event}. If no component is defined,
 * the action component is used.
 *
 * NOTE: This handler only makes sense on mobile platform, since we trigger the favorite
 * function from the {@link AppBar}, which is outside the doc tree, and therefore need
 * to access the module state to know which node we are working with.
 *
 * @type {(function(...[*]): (*))|*}
 */
const toggleFavoriteQuickAction = async (module, component, event) => {
  const favoriteAction = getQuickAction('favorite', module, component, null)
  const handlerComponent = favoriteAction?.component || null

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { [handlerComponent]: targetComponent } = sequenceComponentFindProxy(actionComponent)
  const finalComponent = targetComponent || actionComponent

  const { node = {} } = module[G.STATE]?.[finalComponent._name] || {}

  const payload = node?.documentationId || node?.attachmentId
  const isFavorite = getFavorite(module, component, {
    ...event, detail: { value: payload },
  })

  const options = { delete: isFavorite }

  toggle(module, targetComponent || actionComponent, { detail: { payload, options } })
}

export default curry(toggleFavoriteQuickAction)
