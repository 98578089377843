/* eslint-disable no-unused-vars */
/* global G */
import { hide, show } from 'lib/sequence/component/state/hidden'
import find from 'lib/sequence/component/children/find'
import { asyncPipeSpread, bulk } from 'lib/util'
import session from 'app/_shared/session'
import map from 'lib/sequence/model/api/map'

// bulk partials
const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Displays or hides the edit buttons depending on the current ticket's status and on whether the
 * current user is the ticket's assignee.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayEditButtons = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { status, assignee } = model[G.CHILDREN]
  const { editRequesterOrg, editRequesterContact } = components

  status[G.CACHE]
  && (status[G.CACHE]?.[0]?.key === 50 || status[G.CACHE] === 50)
  && assignee[G.CACHE]
  && assignee[G.CACHE][0].key === session(module).user.key()
    ? bulkShow(editRequesterOrg, editRequesterContact)
    : bulkHide(editRequesterOrg, editRequesterContact)

  return [components, ...args]
}

/**
 * Displays or hides information about the contact depending on some of their state values.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayContactInformationLabels = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact } = model[G.CHILDREN]
  const { requesterContactData: requesterContactDataComponent } = components
  const { labels } = find(requesterContactDataComponent)
  const { notInMasterData, waitingForApproval, pendingInvitation, blocked } = find(labels)

  const requesterContactRef = requesterContact[G.STATE][G.REF]
  const pendingInvite = requesterContact[G.CACHE]?.[0]?.value?.invite
  const { pendingValidation, isBlocked } = requesterContact[G.STATE][G.META]

  pendingValidation || requesterContactRef
    ? hide(notInMasterData)
    : show(notInMasterData)

  pendingInvite && !isBlocked
    ? show(pendingInvitation)
    : hide(pendingInvitation)

  pendingValidation && !pendingInvite && !isBlocked
    ? show(waitingForApproval)
    : hide(waitingForApproval)

  isBlocked
    ? show(blocked)
    : hide(blocked)

  return [components, ...args]
}

/**
 * Displays or hides information about the organisation depending on some of their state values.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayOrganisationInformationLabels = module => (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg, requesterContact } = model[G.CHILDREN]
  const { requesterOrgData: requesterOrgDataComponent } = components
  const { labels } = find(requesterOrgDataComponent)
  const { notInMasterData, waitingForApproval, blocked } = find(labels)

  const requesterOrgRef = requesterOrg[G.STATE][G.REF]

  const { pendingValidation, isBlocked } = requesterContact[G.STATE][G.META]

  pendingValidation || requesterOrgRef
    ? hide(notInMasterData)
    : show(notInMasterData)

  pendingValidation && !requesterOrgRef && !isBlocked
    ? show(waitingForApproval)
    : hide(waitingForApproval)

  isBlocked
    ? show(blocked)
    : hide(blocked)

  return [components, ...args]
}

/**
 * Displays the organisation of the requesterContact only if it exists and differs from
 * requesterOrg.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayRequesterContactOrganisation = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg, requesterContactOrg } = model[G.CHILDREN]
  const { contactOrganisation } = components

  requesterContactOrg[G.STATE][G.REF]
  && requesterContactOrg[G.STATE][G.REF] !== requesterOrg[G.STATE][G.REF]
    ? show(contactOrganisation) && map(requesterContactOrg)(contactOrganisation)
    : hide(contactOrganisation)

  return [components, ...args]
}

export default module => component => async (...args) => asyncPipeSpread(
  displayRequesterContactOrganisation(module),
  displayOrganisationInformationLabels(module),
  displayContactInformationLabels(module),
  displayEditButtons(module),
)(find(component), ...args)
