/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import find from 'lib/sequence/component/children/find'
import { asyncPipeSpread } from 'lib/util'
import applyAdminFilters from 'app/_shared/action/partial/applyAdminFilters'

/**
 * Organisation Action Index
 *
 * @param {Gaia.Component.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  applyAdminFilters('csmList')(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
