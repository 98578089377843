/* RATIONAL's theme */

const _logo = './assets/logo/light.svg'
const _devices = './assets/widgets/devices.svg'
const _messages = './assets/widgets/messages.svg'

/**
 * If the "dark" and / or "light" keys are omitted, their value(s) will be calculated from "main",
 * according to the "tonalOffset" value.
 * If "contrastText" is omitted, its value will be calculated to contrast with "main", according to
 * the "contrastThreshold" value.
 *
 * @see https://material-ui.com/customization/palette/
 *
 * @example
 *    stateDescriptor_A: { // stateDescriptor -> warning, error, success, etc
 *     // light: will be calculated from palette.primary.main,
 *     main: '#ff4400',
 *     // dark: will be calculated from palette.primary.main,
 *     // contrastText: will be calculated to contrast with palette.primary.main
 *   },...
 *
 */
export default {
  direction: 'ltr',
  palette: {
    mode: 'light',
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    primary: {
      main: '#000000',
      dark: '#000000',
      darker: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#000000',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#E4002B',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#e8a900',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#0082be',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#00853e',
      contrastText: '#FFFFFF',
    },
    signal: {
      main: '#aa0b25',
      contrastText: '#FFFFFF',
    },
    active: {
      main: '#0097C4',
    },
    inactive: {
      main: '#575757',
    },
    pending: {
      main: '#e8a900',
    },
    text: {
      primary: '#575757',
      secondary: '#7D7E80',
      regular: '#565656',
      light: '#C8C8C8',
      inverted: '#FFFFFF',
      dark: '#3C3C3C',
      link: '#105591',
      description: '#8C8C8C',
    },
    appBar: {
      toolBar: 'rgba(200, 200, 200, .1)',
      actionBar: 'rgba(255, 255, 255, 0)',
      border: '#E6E7E8',
      text: '#202020',
    },
    sideBar: {
      background: '#FAFAFA',
      border: '#E6E7E8',
    },
    divider: '#E6E7E8',
    border: {
      main: '#CACACA',
    },
    background: {
      main: '#FFFFFF',
      light: '#F3F5F6',
      dark: '#F6F6F6',
      selected: '#F6F6F6',
      content: '#FAFAFA',
    },
  },
  logo: {
    src: _logo,
    url: `url(${_logo})`,
    backgroundSize: '80%',
  },
  widget: {
    devices: `url(${_devices})`,
    messages: `url(${_messages})`,
  },
}
