/* global G */
import { bulk, curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { actionWithHooks } from 'lib/sequence/module/action'
import sequenceComponentState from 'lib/sequence/component/state'

const { set: update } = sequenceComponentState('update')
const updateList = list => update(list, {})
const updateLists = bulk(updateList)

/**
 * Event Handler Search Step and Recreate
 *
 * Sets the event detail's value as current search term for the list of the current step and
 * recreates the module, so that it is taken into account the next time the list is rendered.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {PlatformEvent} event         information about the event that triggered this handler
 * @return {Promise<void>}
 */
const multiSearchStepAndRecreate = async (module, component, event) => {
  try {
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { stepper } = asObject(actionComponent[G.CHILDREN])
    const { currentStep } = stepper[G.STATE]
    const { [currentStep]: step } = asObject(stepper[G.CHILDREN])
    const { search } = asObject(step[G.CHILDREN])
    const searchChildren = asObject(search[G.CHILDREN])
    const { searchField, organisations, persons, devices /* , tickets */ } = searchChildren
    const { term, transform } = event.detail
    const sort = !term

    const state = searchField[G.STATE]
    state.value = term

    const meta = { term, transform, sort }

    organisations[G.STATE][G.META] = meta
    persons[G.STATE][G.META] = meta
    devices[G.STATE][G.META] = meta
    // updating lists
    // updateLists(organisations, persons, devices)
    // tickets[G.STATE][G.META] = meta
    // temporary solution for suspended memoized list component
    // recreate ui, instead of update ui
    await module[G.ADAPTER][G.UI].create(module)
    await actionWithHooks(module[G.STATE][G.ACTION])([])
  } catch (e) {
    console.error(e)
  }
}

export default curry(multiSearchStepAndRecreate)
