/* global G */
import { curry, setKey } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import setStepTab, { CONFIRM } from 'app/_shared/events/setStepTab'
import refresh from 'lib/sequence/model/api/refresh'

/**
 * Attempts to obtain the product with key {@param event.detail.key} and sets it as the product
 * property of the current model.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @param {object} event.detail         custom event details
 * @param {string} event.detail.key     a product key
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const setProduct = async (module, component, event) => {
  const { item: product } = event.detail
  const model = module[G.MODEL]
  // storing product values
  model[G.CACHE].value.name = product.value.name
  model[G.CHILDREN].product[G.DATA].value = [product]
  // refreshing model cache with its own data
  await refresh(model)
  // setting step's confirm tab
  setStepTab(CONFIRM)(module, component, event)
  // setting action's dirty flag
  setKey(true, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
  // executing current action again
  await actionWithHooks(module[G.STATE][G.ACTION])([])
}
export default curry(setProduct)
