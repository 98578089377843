import regExp from './regExp'

/**
 * PPT Validator
 * @src apps/puppeteer/src/modules/fields/validators.js
 * @type {RegExp}
 */
const re = /[!?/.@#$%^&*|\-_]/

/**
 *
 * Attribute Special Character Validator
 *
 * validates data values on model level, propagating
 * to the same scope as children validators
 *
 * @param {object} obj  the model object composition
 * @return {object}     the call's arguments
 */
export default regExp(re)
