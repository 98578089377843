/* global G */
import { curry } from 'lib/util'
import defaultNoteTypes from 'model/ticket/collection/note/type'
import { settings } from 'app/_shared/session'

/**
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent|string} eventOrKey
 * @return {Array} countries
 */
const listNoteTypes = (module, component, eventOrKey) => {
  const types = settings.noteTypes || defaultNoteTypes
  const key = eventOrKey?.detail?.key || eventOrKey
  const scope = key ? types.filter(item => item.key === key) : types

  return scope.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `note.type.${item.key}`,
      {
        ns: 'ticket',
        _key: `note.type.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }))
}

export default curry(listNoteTypes)
