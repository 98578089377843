/* global G */
import getFavorite from '@app/_shared/events/favorite/get'
import { curry } from 'lib/util'
import { openModalWith } from 'app/_shared/events/modal/open'
import { submitModalWith } from 'app/_shared/events/modal/submit'

/**
 * Injects {@param payload} and {@param options} into {@param event} {@code details}
 * property. The injected data takes precedence over existing data.
 *
 * @param {string} payload      the ID of the resource that needs to be favored/unfavored
 * @param {Object} options      options to inject into the event, like {@code type}
 * @param {PlatformEvent} event the event to mutate
 * @returns {*&{detail: {payload, options}}}
 * @private
 */
const _getEventData = ({ payload, options }, event) => ({
  ...event,
  detail: {
    ...event?.detail || {},
    payload,
    options: {
      ...event?.detail?.options || {},
      ...options,
    },
  },
})

/**
 * Toggle Favorite Event Handler
 *
 * Looks up whether the current resource is a favorite by either looking at
 * {@param event.detail.options.delete} or by calling {@link getFavorite}
 * with the current {@param event.detail.payload}.
 *
 * Calls {@link openModalWith} if the resource is not a favorite and
 * {@link submitModalWith} if it is.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 */
const toggle = (module, component, event) => {
  const { payload, options = {} } = event?.detail || event || {}

  const isFavorite = options?.delete
    ? true
    : getFavorite(module, component, { detail: { value: payload } })

  const newEvent = _getEventData({ payload, options: { delete: isFavorite } }, event)

  !isFavorite
    ? openModalWith('favoriteModal', module, component, newEvent)
    : submitModalWith('submitFavorite', module, component, newEvent)
}

/**
 * Toggle Favorite From Model Event Handler
 *
 * Wraps {@link toggle} by giving it the {@code G.REF} as the {@code payload} and
 * {@code _name} as the {@code type} from the current model.
 *
 * @type {(function(...[*]): (*))|*}
 */
export const toggleFavoriteFromModel = curry((module, component, event) => {
  const payload = module[G.MODEL][G.STATE][G.REF]
  const type = module[G.MODEL]._name

  const newEvent = _getEventData({ payload, options: { type } }, event)

  toggle(module, component, newEvent)
})

/**
 * Toggle Favorite From Attribute Event Handler
 *
 * Wraps {@link toggle} by giving it the {@code G.REF} as the {@code payload} and
 * {@code _name} as the {@code type} of the {@param attribute} sub model.
 *
 * @type {(function(...[*]): (*))|*}
 */
export const toggleFavoriteFromAttribute = curry((attribute, module, component, event) => {
  const target = module[G.MODEL][G.CHILDREN][attribute]
  const payload = target[G.STATE][G.REF]
  const type = target._name

  const newEvent = _getEventData({ payload, options: { type } }, event)

  toggle(module, component, newEvent)
})

export default curry(toggle)
