/* global G */
import { curry } from 'lib/util'
import redirectSequence from '@gaia/sequence/module/adapter/router/redirect'
import routeComposition from '@gaia/trait/composition/route'

/**
 * List of triggers that are considered to be 'important' for the next redirect.
 *
 * @type {string[]}
 */
const triggers = [
  'destroy',
]

/**
 * This is a wrapper around {@link redirect}, which sets the next route dynamically based on the
 * {@code trigger} (what we did to arrive here) and the history stack. There are 4 possible
 * scenarios:
 *
 * - 1: We only have one item in the stack, we come from a details view, and we deleted something
 *   => perform normal redirect, meaning use the route defined in the component
 *
 * - 2: We only have one item in the stack, we do not come from a details view, and we deleted
 *   something => perform {@link back}.
 *
 * - 3: We only have more than one item in the stack, we come from a details view, and we deleted
 *   something => perform normal redirect, but replace the component route with the route before
 *   the last route
 *
 * - 4: We only have more than one item in the stack, we do not come from a details view, and we
 *   deleted something => perform {@link back}.
 *
 * @type {(function(...[*]): (*))|*}
 */
const dynamicRedirect = async (trigger, module, component, event) => {
  try {
    const history = module[G.ADAPTER][G.SESSION][G.STATE][G.PREV]
    const lastRoute = history[history.length - 1]

    if (history.length === 1) {
      // if we only have on history entry, we come from a details view and just deleted something
      // just redirect to the component route.
      if (lastRoute[G.ACTION] === 'detail' && triggers.includes(trigger)) {
        const { detail } = event
        const moduleState = module[G.STATE]
        const { route } = component[G.CONFIGURATION]
        moduleState[G.ROUTE] = routeComposition(route.module, route.action)
        await redirectSequence(module)({ detail })
      }

      // If we deleted something but don't come from a details view, go back to the last entry
      lastRoute[G.ACTION] !== 'detail'
            && triggers.includes(trigger)
            && await module[G.ADAPTER][G.ROUTER].back()
    }

    if (history.length > 1) {
      const baseRoute = history[history.length - 2]
      const baseKey = baseRoute?.[G.REF] || null
      const nextRoute = {
        action: baseRoute[G.ACTION],
        module: baseRoute?.[G.MODULE],
      }

      // if we have more than one history entry, and we come from a details view and just deleted
      // something replace the next route with the one before the previous route.
      if (lastRoute[G.ACTION] === 'detail' && triggers.includes(trigger)) {
        const moduleState = module[G.STATE]
        moduleState[G.ROUTE] = routeComposition(nextRoute.module, nextRoute.action)
        // What we actually want to do here is a 'back' twice, but since we can't do that,
        // we need to do a redirect to the route at {@code history.length - 2}, but because
        // this is a step forward, we also need to remove {@code history.length - 1} from
        // the history. If we don't do this and we were to use this function again for the next
        // redirect, {@code history.length - 2} would be the document we deleted before,
        // resulting in a 404.

        // Example:
        // Instance Admin goes to org details -> contacts tab -> contact details -> edit -> delete
        // After deletion, we redirect to {@code history.length - 2}, which is org details.
        // All fine.

        // Now in org details we do -> edit -> delete
        // After deletion, we redirect to {@code history.length - 2}, which is person details.

        // NOT GOOD, because we just deleted that person. Therefore, whenever we arrive here,
        // we need to remove the route we want to skip from the history in order to make sure
        // the history doesn't contain residual routes
        history.pop()
        await redirectSequence(module)({ key: baseKey })
      }

      // If we deleted something but don't come from a details view, go back to the last entry
      lastRoute[G.ACTION] !== 'detail'
            && triggers.includes(trigger)
            && await module[G.ADAPTER][G.ROUTER].back()
    }
  } catch (e) {
    console.error(e)
  }
}

export default curry(dynamicRedirect)
