/**
 * Request Status Collection.
 *
 * Contains static list of available ticket's message types, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 */
export default [
  {
    key: 0,
    value: 'Draft',
    icon: 'fiber_manual_record',
    color: 'active',
  },
  {
    key: 2,
    value: 'Outbox',
    icon: 'fiber_manual_record',
    color: 'active',
  },
  {
    key: 10,
    value: 'Logged',
    icon: 'fiber_manual_record',
    color: 'pending',
  },
  {
    key: 50,
    value: 'Further inquiry',
    icon: 'fiber_manual_record',
    color: 'active',
  },
  {
    key: 60,
    value: 'In progress',
    icon: 'fiber_manual_record',
    color: 'pending',
  },
  {
    key: 75,
    value: 'Evaluate solution',
    icon: 'fiber_manual_record',
    color: 'active',
  },
  {
    key: 80,
    value: 'Closed',
    icon: 'fiber_manual_record',
    color: 'closed',
  },
]
