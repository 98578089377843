/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import toBoolean from 'model/_shared/transformer/toBoolean'

const descriptor = 'model::ticket::description'

export default pipe(
  modelComposition(descriptor),
  usesGetterSetter(G.TRANSFORMER, {
    deviceMalfunction: toBoolean('deviceMalfunction'),
  }),
)
