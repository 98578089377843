/* global G */
import { curry } from 'lib/util'
import fetchTree from 'app/_shared/events/file/tree/fetchTree'

/**
 * Get From Module Or Fetch Tree Event Handler
 *
 * Grabs the tree from the module state or executes {@link fetchTree}.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 * @returns {Promise<{tree: (*|(function(...[*]): (*)))}|*>}
 */
const getFromModuleOrFetchTree = async (module, component, event) => {
  const moduleState = module[G.STATE]
  const cachedTree = moduleState[[component._name]]

  if (cachedTree) {
    return cachedTree
  }

  const eventBus = module[G.ADAPTER][G.EVENTS]
  eventBus.dispatch(eventBus.type(G.LOAD, G.INIT))
  const newTree = await fetchTree(module, component, event)
  eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))

  return { tree: newTree }
}

export default curry(getFromModuleOrFetchTree)
