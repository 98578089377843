import { curry } from 'lib/util'
import countServiceItems from 'app/_shared/events/search/count'
import searchServiceItems from 'app/_shared/events/search'
import searchWith from 'app/_shared/events/search/with'

/**
 * Get the count of viewable devices and the device itself
 * in case the count is 1.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 *
 * @return {Object}                     the count and optionally a device
 */
const countDevicesFn = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const additionalParameters = await searchWith(['filter'], module, component, event)

  const count = await countServiceItems(null, module, component, additionalParameters)
  if (count.total.count !== 1) return { total: { count: count.total.count } }

  const item = await searchServiceItems(null, module, component, additionalParameters)

  return {
    total: { count: count.total.count },
    item: {
      title: item[0]?.value?.name,
      body: item[0]?.value?.serial,
      key: item[0]?.key,
    },
  }
}

export default curry(countDevicesFn)
