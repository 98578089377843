/* eslint-disable no-unused-vars */
/* global G */
import { curry, getFirstItem } from 'lib/util'

/**
 * Retrieve the tree from the {@param module}'s action component's props.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component      the current action's main component
 * @param {Event} event                     the event object that triggered this handler
 * @returns {Promise<*>}
 */
const getTree = async (module, component, event) => {
  const tree = getFirstItem(module[G.STATE]?.[component._name]) || {}

  return tree?.value ? tree.value : tree
}

export default curry(getTree)
