/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from '@gaia/trait/uses'
import toBoolean from 'model/_shared/transformer/toBoolean'
import dataToExistingRef from 'model/_shared/transformer/dataToExistingRef'

const descriptor = 'model::team::initialAssignment'

export default pipe(
  modelComposition(descriptor),
  usesGetterSetter(G.TRANSFORMER, {
    fallback: toBoolean('fallback', true),
    product: dataToExistingRef('product'),
    ticketType: dataToExistingRef('ticketType'),
  }),
)
