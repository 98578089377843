/* global G */
import { back, persistChange } from '@app/_shared/events'
import actionByKey from '@app/_shared/events/action/byKey'
import { curry, setKey } from 'lib/util'
import chain from 'app/_shared/events/util/chain'
import confirmChange from './event/confirmChange'
import validateLogin from './event/validateLogin'

export default {
  onClick: {
    validateRedirect: actionByKey('validateRedirect'),
    validateLogin,
    forgotPassword: actionByKey('forgotPassword'),
    resetPassword: actionByKey('resetPassword'),
    support: actionByKey('support'),
    back,
  },
  onChange: {
    persistChange,
    persistChangeAndUpdateSession: chain(
      persistChange,
      curry(async (module, component, event) => setKey(
        event.detail.value,
        'username',
        module[G.ADAPTER][G.SESSION][G.STATE],
      )),
    ),
  },
  onConfirmChange: {
    confirmChange,
  },
}
