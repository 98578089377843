/* eslint-disable object-curly-newline, no-alert, no-restricted-globals */
// /* global G */
import { back, persistChange, persistOptions, redirect, submit } from '@app/_shared/events'
import search from 'app/_shared/events/search'
import searchAndRecreate from 'app/_shared/events/appbar/searchAndRecreate'
import createRedirect from 'app/_shared/events/appbar/createRedirect'
import detail from 'app/_shared/events/action/detail'
import list from 'app/_shared/events/list'
import menu from 'app/_shared/events/contextmenu'
import link from 'app/_shared/events/link'
import infinite from 'app/_shared/events/search/infinite'
import listMessageTypes from 'app/_shared/events/collection/listMessageTypes'
import remember from 'app/_shared/events/remember'
// import { reference } from 'app/_shared/events/pubsub'

export default {
  onOpen: {
    search: search('verbose'),
    listing: list(null),
    listMessageTypes: listMessageTypes('ticket'),
    infinite: infinite('verbose'),
    // reference,
    link,
  },
  onSearch: {
    searchAndRecreate,
  },
  onCreate: {
    createRedirect,
  },
  onClose: {
    back,
  },
  onClick: {
    submit,
    back,
    detail,
    redirect,
  },
  onChange: {
    persistOptions,
    persistChange,
  },
  onContextMenu: {
    menu: menu(null),
  },
  onPage: {
    remember,
  },
}
