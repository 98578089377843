/* global G */
import { set as setToken } from 'app/_shared/component/token'

/**
 *
 * @param obj
 * @param status
 * @returns {*}
 */
const translateStatus = (obj, status) => {
  const defaultStatuses = {
    10: 'New',
    30: 'Open',
    50: 'In progress',
    60: 'Pending',
    75: 'Solution proposed',
    80: 'Closed',
  }

  // We use the statuses declared in ticket:ticket.status!
  const _key = 'ticket.status'
  const translatedStatuses = obj[G.ADAPTER][G.INTL]._t(_key, {
    ns: 'ticket',
    _key,
    defaultStatuses,
    returnObjects: true,
    skipInterpolation: true,
  })

  return translatedStatuses[status]
}

/**
 * Assemble the title of the ticket detail action by
 * either getting the ticket number or translating
 * its status if there is no number and add the
 * result to the component's tokens.
 * @param obj
 * @returns {function(...[*]): *[]}
 */
const uiTitle = obj => (...args) => {
  const { number = null, status } = obj[G.MODEL][G.CACHE].value
  const numberOrStatus = number || `(${translateStatus(obj, status)})`
  setToken(obj, { numberOrStatus })

  return args
}

export default uiTitle
