/* eslint-disable no-param-reassign,no-return-assign,no-unused-vars */
/* global G */
import { curry, isBool } from 'lib/util'

/**
 * Permission acl event handler
 *
 * Checks if the user has {@param permissions} for the current entity
 * and returns {@code true} if so.
 *
 * Entity meaning the current module (ticket, request, ...)
 * rather than the current document.
 *
 * This is the event handler equivalent of the {@link create} hook.
 *
 * @param {Gaia.AppModule.Spec} module    application module
 * @param {Gaia.Component} component      parent component
 * @param {Number[]|Boolean} permissions  list of permissions to check against
 *                                        if {@code true}, acl check will be skipped
 * @return {Boolean}
 */
const permission = (module, component, permissions) => {
  try {
    if (isBool(permissions) && permissions === true) return true

    const fn = module[G.ADAPTER][G.ACL].create

    /**
     * If we feed it a nil {@param permissions} it won't get
     * executed due to currying.
     */
    permissions
      ? fn(permissions)
      : fn()

    return true
  } catch (e) {
    console.error(e)
  }

  return false
}

export default curry(permission)
