import noop from '@gaia/util/noop/action'
import index from './action/index'
import access from './action/access'
import validateLogin from './action/validateLogin'
import validateRedirect from './action/validateRedirect'
import logout from './action/logout'
import loginLink from './action/loginLink'

export default {
  index,
  context: noop,
  access,
  logout,
  loginLink,
  validateLogin,
  validateRedirect,
}
