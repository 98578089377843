/* global G */
import { pipe } from 'lib/util'
import { modelComposition } from '@gaia/trait/composition'
import { usesGetterSetter } from '@gaia/trait/uses'
import concat from 'model/_shared/transformer/concat'
import withGetterSetter from 'trait/with/GetterSetter'
import toContactChannels from 'model/_shared/transformer/toContactChannels'
import fromContactChannels from 'model/_shared/decorator/fromContactChannels'
import fromSingleRef from 'model/_shared/decorator/fromSingleRef'
import toRef from 'model/_shared/transformer/toRef'
import toInvite from 'model/_shared/transformer/toInvite'
import toToBeValidated from 'model/_shared/transformer/toToBeValidated'
import toStatus from 'model/_shared/transformer/toStatus'
import validator from './validator'

const descriptor = 'model::person::contact'
export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    name: concat('name', ['firstName', 'lastName']),
    contactChannels: toContactChannels('contactChannels'),
    organisation: toRef('organisation'),
    invite: toInvite('invite'),
    toBeValidated: toToBeValidated('toBeValidated'),
    status: toStatus('status'),
    // team: toRef('team'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    contactChannels: fromContactChannels('contactChannels'),
    organisation: fromSingleRef('organisation', 'organisation'),
    // team: fromSingleRef('team', 'team'),
  }),
)
