import set from './set'
import reload from './reload'

const tab = {
  set,
  reload,
}

export {
  tab as default,
  set,
  reload,
}
