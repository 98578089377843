/* global G */
/* eslint-disable no-shadow,no-unused-vars */
import { curry } from 'lib/util'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import { acl, cell, permission } from 'app/_shared/events/acl'
import infinite from 'app/_shared/events/search/infinite'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'
import listTicketStatus from 'app/_shared/events/collection/listTicketStatus'
import listStatusReasons from 'app/_shared/events/collection/listStatusReasons'
import { back, detail, persistChange, persistCheck, persistOption, persistOptions, redirect } from 'app/_shared/events'
import menu from 'app/_shared/events/contextmenu'
import { count, item, reference, user } from 'app/_shared/events/pubsub'
import updateTicketList from 'app/ticket/event/updateTicketList'
import updateUnreadMessagesCount from 'app/ticket/event/updateUnreadMessagesCount'
import listTicketFilterStatus from 'app/_shared/events/collection/listTicketFilterStatus'
import persistChangeAndAction from 'app/_shared/events/combined/persistChangeAndAction'
import link from 'app/_shared/events/link'
import linkWithRef from 'app/_shared/events/link/withRef'
import updateRequestList from 'app/request/event/updateRequestList'
import listRequestStatus from 'app/_shared/events/collection/listRequestStatus'
import getUserRole from 'app/_shared/events/user/role'
import listUserRoles from 'app/_shared/events/collection/listUserRoles'
import userHasIdAndRoleAtOrg from 'app/_shared/events/search/query/userHasIdAndRoleAtOrg'
import createRedirect from 'app/_shared/events/appbar/createRedirect'
import persistOptionsAndAction from 'app/_shared/events/combined/persistOptionsAndAction'
import search from 'app/_shared/events/search'
import listStatus from 'app/_shared/events/collection/listStatus'
import itemLink from 'app/_shared/events/link/item'
import conflict from 'app/_shared/events/pubsub/conflict'
import chain from 'app/_shared/events/util/chain'
import searchWith from 'app/_shared/events/search/with'
import listOrganisationTypes from 'app/_shared/events/collection/listOrganisationTypes'
import session from 'app/_shared/session'
import persistStore from 'app/_shared/events/combined/persistStore'
import { bulkByKey } from 'app/_shared/events/action/bulk'
import infinitePersonsWithUsers from 'app/person/events/infinitePersonsWithUsers'
import { personIsInvited } from 'app/_shared/events/search/query'
import listInvitationStatuses from 'app/_shared/events/collection/listInvitationStatuses'
import component from 'app/_shared/events/acl/component'
import listGenericOptions from 'app/_shared/events/collection/listGenericOptions'
import listTimezones from 'app/_shared/events/collection/listTimezones'
import byKey from 'app/_shared/events/action/byKey'
import withRef from 'app/_shared/events/redirect/withRef'
import toAction from 'app/_shared/events/redirect/toAction'
import searchUnsortedAndRecreate from 'app/_shared/events/appbar/searchUnsortedAndRecreate'
import remember from 'app/_shared/events/remember'
import action from 'app/_shared/events/action'
import impersonate from 'app/admin/event/impersonate'
import sendPasswordRecovery from 'app/admin/event/sendPasswordRecovery'
import newPartialOption from 'app/admin/event/newPartialOption'
import listDecoratedUserRoles from 'app/admin/event/listDecoratedUserRoles'
import getAtOrganisation from 'app/admin/event/getAtOrganisation'
import partialOption from 'app/admin/event/partialOption'
import deleteRole from 'app/admin/event/deleteRole'
import persistOptionsOrGeneric from 'app/admin/event/persistOptionsOrGeneric'
import checkValidation from 'app/admin/event/checkValidation'
import destroyIfNoSubItems from 'app/admin/event/destroyIfNoSubItems'
import checkNotification from 'app/admin/event/checkNotification'
import changeAtOrgsAndPersistOptions from 'app/admin/event/changeAtOrgsAndPersistOptions'
import getDeletionStatus from 'app/admin/event/getDeletionStatus'
import toggleEmailValidation from 'app/admin/event/toggleEmailValidation'

export default {
  acl: {
    acl,
    cell,
    component,
    permission,
  },
  onSearch: {
    searchUnsortedAndRecreate,
  },
  onCreate: {
    createRedirect,
  },
  onOpen: {
    // search
    search: search(null),
    searchVerbose: search('verbose'),
    searchWithFilter: chain(
      searchWith(['filter']),
      search(null),
    ),
    searchVerboseWithFilter: chain(
      searchWith(['filter']),
      search('verbose'),
    ),
    searchValidatedWithFilter: chain(
      searchWith(['filter']),
      curry(async (module, component, event) => {
        const result = await search('default', module, component, event)
        return result.filter(x => !x?.value?.toBeValidated)
      }),
    ),
    // infinite
    infinite: infinite(null),
    infiniteVerbose: infinite('verbose'),
    infiniteUsers: curry(async (module, component, event) => {
      const result = await infinite('default', module, component, event)
      const data = result.data.map(row => ({ ...row, value: { ...row.value, loginName: row.key } }))

      return { ...result, data }
    }),
    infinitePersonsWithQuery: chain(
      searchWith(['query']),
      infinitePersonsWithUsers,
    ),
    // listing
    listOrganisationsWithFilter: chain(
      searchWith(['filter']),
      curry(async (module, component, event) => await search(
        null, module, component, { ...event?.detail?.term && { term: event.detail.term } },
      )),
    ),
    listTicketTypes: curry((module, component, event) => listTicketTypes(module, component, event)
      .filter(type => !type.hidden)),
    listDecoratedUserRoles,
    listUserRoles,
    listTimezones,
    listStatus,
    link,
    linkToPerson: linkWithRef('person'),
    item: itemLink,
    reference, // edit form
  },

  onRoleOpen: {
    listUserRoles,
  },
  onAtOrgOpen: {
    listOrganisationsWithFilter: chain(
      searchWith(['filter']),
      curry(async (module, component, event) => await search(
        null, module, component, { ...event?.detail?.term && { term: event.detail.term } },
      )),
    ),
  },
  onAtOrgCreate: {
    getAtOrganisation,
  },
  onRoleChange: {
    persistRole: partialOption('roles', 'role'),
  },
  onAtOrgChange: {
    persistAtOrg: partialOption('roles', 'atOrg'),
  },
  onRoleDelete: {
    deleteRole: deleteRole('roles'),
  },
  onRoleCreate: {
    getDeletionStatus,
  },
  onRoleDeleteOpen: {
    getDeletionStatus,
  },
  onClick: {
    back,
    detail,
    detailWithPerson: withRef(detail, 'person'),
    redirect,
    redirectWithPerson: curry(async (module, component, event) => {
      const { detail: { item: person } } = event
      const moduleState = module[G.STATE]
      const { route } = component[G.CONFIGURATION]
      moduleState[G.ROUTE] = routeComposition(route.module, route.action)
      await redirectSequence(module)(person)
    }),

    impersonate,
    userBulk: bulkByKey('userBulk'),
    teamEdit: toAction('teamEdit'),
    teamSubmit: byKey('teamSubmit'),
    sendPasswordRecovery,
    destroyIfNoSubItems,
  },
  onChange: {
    persistCheck,
    persistStore,
    persistChange,
    toLowerCaseAndPersistChange: curry((module, component, event) => {
      persistChange(
        module,
        component,
        { ...event, detail: { value: event.detail.value.toLowerCase() } },
      )
    }),
    persistChangeAndAction,
    persistChangeAndCheckValidation: chain(
      persistChange,
      checkValidation,
    ),
    persistCheckAndToggleEmail: chain(
      persistCheck,
      toggleEmailValidation,
    ),
    persistChangeAndCheckNotificationAndToggleEmail: chain(
      persistChange,
      checkNotification,
      toggleEmailValidation,
    ),
    persistOption,
    persistOptions,
    persistOptionsAndAction,
    persistOptionsOrGeneric,
    persistOptionsAndCheckValidation: chain(
      persistOptions,
      checkValidation,
    ),
    changeAtOrgsAndPersistOptions,
    persistNewRole: newPartialOption('roles', 'role'),
    persistNewAtOrg: newPartialOption('roles', 'atOrg'),
    persistOptionsRememberAndAction: chain(
      persistOptions,
      remember,
      action,
    ),
    persistCheckRememberAndAction: chain(
      persistCheck,
      remember,
      action,
    ),
  },
  onClose: {
    back,
  },
  onContextMenu: {
    menu: menu(null),
  },
  onPage: {
    remember,
  },
  onPublication: {
    conflict,
    updateTicketList,
    updateRequestList,
  },
  onLinkChange: {
    setLink: link,
  },
  getTicketType: {
    listTicketTypes,
  },
  getStatus: {
    listStatus,
    listTicketStatus,
    listRequestStatus,
    listInvitationStatuses,
  },
  getStatusReason: {
    listStatusReasons,
  },
  getSelection: {
    listGenericOptions,
    listTicketFilterStatus,
  },
  getRole: {
    getUserRole: curry((module, component, roles) => getUserRole(roles, module, component, null)),
  },
  getLabel: {
    listUserRoles,
    listOrganisationTypes,
  },
  count: {
    sub: count,
  },
  item: {
    sub: item,
    user,
  },
  list: {
    // sub: filteredOption('team'),
    sub: reference,
    user,
  },
  cell: {
    sub: updateUnreadMessagesCount,
  },
  filter: {
    isNotInactive: curry((module, component, event) => ({
      status: { max: 79 },
    })),
    isActive: curry((module, component, event) => ({
      status: { max: 79 },
    })),
    isActiveAndNotMyself: curry((module, component, event) => ({
      status: 50,
      id: `!${session(module).person.key()}`,
    })),
    hasPersonRef: curry((module, component, event) => ({
      refexists: 'person',
    })),
  },
  query: {
    userHasIdAndRoleAtOrg,
    personIsInvited,
  },
}
