/**
 * System Message Types Collection.
 *
 * Contains static list of available system's message types, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 *
 */
export default [
  {
    key: 'system_resolved',
    value: 'Request resolved',
    icon: 'check',
    color: 'success',
  },
  {
    key: 'system_expired',
    value: 'Request expired',
    icon: 'watch_later',
    color: 'border',
  },
  {
    key: 'system_cancelled',
    value: 'Request cancelled',
    icon: 'close',
    color: 'error',
  },
  {
    key: 'system_reopened',
    value: 'Request reopened',
    icon: 'replay',
    color: 'primary',
  },
  {
    key: 'system_yanked',
    value: 'Request yanked',
    icon: 'airline_stops',
    color: 'primary',
  },
  {
    key: 'system_sorry_closed',
    value: 'Sorry, the request has been terminally closed and cannot be reopened. In case of need, submit a new request.',
    icon: 'sentiment_dissatisfied',
    color: 'warning',
  },
]
