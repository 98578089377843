/* eslint-disable no-unused-vars */
/* global PLATFORM, G */
import { hide, show } from 'lib/sequence/component/state/hidden'
import getFavorite from '@app/_shared/events/favorite/get'
import getQuickAction from 'app/_shared/events/appbar/quickActions/get'
import updateQuickActions from 'app/_shared/events/file/tree/updateQuickActions'

/**
 * Display Favorite Active In Tree Handler
 *
 * Hides / Shows the {@code active} state of the favorite quick action in a tree depending on
 * whether the node currently displayed has been favored or not. Notice that this handler does
 * not control whether the quick action should be displayed in the first place. It only
 * toggles its {@code active} state when we (re)execute the action logic. This only happens if
 * we favor/un-favor a node in the tree.
 *
 * The overall visibility of the favorite button, as well as its initial active state when navi
 * gating through the tree is handled by {@link updateQuickActions}.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
export const displayFavoriteActiveInTree = module => component => async (components, ...args) => {
  const favoriteAction = getQuickAction('favorite', module, component, null)
  const targetComponent = favoriteAction?.component || null

  if (targetComponent) {
    const { [targetComponent]: finalComponent } = components
    const { node = {}, tree = {} } = module[G.STATE]?.[finalComponent._name] || {}
    const payload = node?.documentationId || node?.attachmentId

    if (node && payload) {
      const isFavorite = getFavorite(module, component, { detail: { value: payload } })

      favoriteAction && (favoriteAction.active = isFavorite)
    }
  }

  return [components, ...args]
}

/**
 * Display Favorite Buttons In Action Handler
 *
 * Hides / shows the {@code btnFavorite} and {@code btnNoFavorite} buttons depending on whether the
 * ID of the current model (either {@param attribute} sub model or {@code module[G.MODEL]}) is
 * favored or not.
 *
 * @param {string|null} attribute  sub-model to get payload from
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
const displayFavoriteButtonsInAction = attribute => module => async (components, ...args) => {
  const model = module[G.MODEL]
  const payload = attribute
    ? model[G.CHILDREN][attribute][G.STATE][G.REF]
    : model[G.STATE][G.REF]

  const action = module[G.STATE][G.ACTION]
  const actionComponent = action[G.COMPONENT]
  const isFavorite = getFavorite(module, actionComponent, { detail: { value: payload } })

  if (PLATFORM === 'web') {
    const { btnFavorite, btnNoFavorite } = components

    isFavorite
      ? hide(btnNoFavorite) && show(btnFavorite)
      : show(btnNoFavorite) && hide(btnFavorite)
  } else if (PLATFORM === 'mobile') {
    const { quickActions = [] } = action[G.UI]
    const favoriteAction = quickActions.find(quickAction => quickAction.key === 'favorite')

    favoriteAction && (favoriteAction.active = isFavorite)
  }

  return args
}

/**
 * Wraps {@link displayFavoriteButtonsInAction} with the current module's {@code G.MODEL}.
 *
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
export const displayFavoriteButtonsFromModel = displayFavoriteButtonsInAction(null)

/**
 * Wraps {@link displayFavoriteButtonsInAction} with {@param attribute} as the current sub-model.
 *
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
export const displayFavoriteButtonsFromAttribute = attribute => displayFavoriteButtonsInAction(attribute)

export default displayFavoriteButtonsInAction(null)
