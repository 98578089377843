/* eslint-disable no-unused-expressions */
/* global G */
import { curry } from '@gaia/util'

const descriptor = 'model::transformer::toExplicitBoolean'

/**
 * To Explicit Boolean transformer
 *
 * Similar to {@link toBoolean}, but explicitly sets data to {@code true} or {@code false}.
 * Conditions:
 * value === '1' || 1 || true => true
 * value === '0' || 0 || undefined || null || false => false
 *
 * @param {String} key  identifier
 * @return {*}
 */
const fn = key => curry((obj, data) => {
  console.warn(descriptor)

  const _data = data
  try {
    const item = obj[G.CHILDREN][key]
    const { type } = item[G.PROPS]
    const value = obj[G.STATE]?.[G.DATA]?.value[key]

    _data[type][key] = !!(value === true || value === 1 || value === '1')
  } catch (e) {
    console.error(descriptor, e)
  }

  return _data
})

export default fn
