/* eslint-disable max-len */
/* global G */
import { curry, setKey } from 'lib/util'

/**
 * Event Handler Search and Recreate
 *
 * Sets the event detail's value as current search term and recreates the module, so that it is
 * taken into account the next time the list is rendered.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @param {Boolean} keepSorting         whether or not the sort criteria should be kept. If false,
 *                                      the criteria will be dropped if {@param event.detail.term}
 *                                      is non-nil
 * @return {Promise<void>}
 */
const searchAndRecreate = async (module, component, event, keepSorting = true) => {
  try {
    const action = module[G.STATE][G.ACTION]
    const actionData = action[G.DATA]
    const actionComponent = action[G.COMPONENT]
    const state = component[G.STATE]
    const { term, transform, reload = true } = event.detail
    const sort = !term || keepSorting

    // setting search field's value
    state.value = term
    term // storing state in action data so that the field's values are restored when going back
      ? actionData.search = { _cache: { term, transform } }
      : delete actionData.search

    /**
     * Get every component from our actionComponent's children that either
     * - has a {@code key} of {@code list} (default case)
     * - or a property {@code list = true} and is not hidden
     * This makes searching work with multiple lists on the same level
     * (and therefore with non-unique keys) due to acl config
     */
    const lists = actionComponent[G.CHILDREN].filter(
      list => list._name === 'list'
        || (!list[G.STATE].hidden && list[G.PROPS].list),
    )

    lists.forEach((list) => {
      setKey(!sort, 'unsorted', list[G.PROPS])
      setKey({ ...list[G.STATE][G.META], term, transform }, G.META, list[G.STATE])
      // clearing possible cached values so that the list attempts to obtain its data again
      setKey(null, 'data', list[G.STATE])
      setKey(null, 'page', list[G.STATE])
      setKey(null, 'unsegmented', list[G.STATE])

      term
        ? setKey(true, 'fromSearch', list[G.PROPS])
        : setKey(false, 'fromSearch', list[G.PROPS])
    })

    // temporary solution for suspended memoized list component
    // recreate ui, instead of update ui
    reload && module[G.ADAPTER][G.UI].create(module)
  } catch (e) {
    console.error(e)
  }
}

export default curry(searchAndRecreate)
