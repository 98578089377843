/* global G */
/**
 * Apply InstanceAdmin specific person filters.
 * TODO: to be able to chain this function, it should probably return [components, ...args]
 *
 * @param {string} key  key of the list component
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
const applyAdminFilters = key => module => async (components, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const { [key]: list } = components

  const meta = list[G.STATE][G.META] || {}
  const currentSearchTerm = list[G.STATE]?.term || null

  const { query: queryHandler } = actionComponent[G.EVENTS]
  const query = queryHandler({ detail: { value: currentSearchTerm } })

  list[G.STATE][G.META] = { ...meta, query }

  // temporary solution for suspended memoized list component
  // recreate ui, instead of update ui
  await module[G.ADAPTER][G.UI].create(module)

  return args
}

export default applyAdminFilters
