/* global G */
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import validate from 'lib/sequence/model/validate'
import validationResult from '../validateRedirect'

/**
 * Attempts to send a support request according to the current model values.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {Promise<void>}             a pending promise object waiting for the request's result
 * @private
 */
const _submit = async (module) => {
  const model = module[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const { version, api } = model[G.PROPS]
  const url = `/api/v${version}/${api}/support`

  const params = {
    email: model[G.CHILDREN].username[G.DATA].value,
    subject: 'Guest issue', // @todo: move value to configuration
    message: model[G.CHILDREN].description[G.DATA].value,
  }

  try {
    await httpAdapter.post({ url, params })
  } catch {
    const moduleState = module[G.STATE]
    moduleState[G.ROUTE] = {
      [G.ACTION]: 'changeError',
    }
  }
}

/**
 * Validates the current model data and, if there is no error, attempts to send a support's message.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(): function(...[*]): Promise<*>}  the call's arguments
 */
export default module => () => async (...args) => {
  const model = module[G.MODEL]

  const { form } = sequenceComponentFindProxy(module[G.STATE][G.ACTION][G.COMPONENT])
  await validate(model)(form)

  const result = validationResult(module)()(...args)
  !model[G.STATE][G.ERROR] && await _submit(module)
  return result
}
