/**
 * Ticket Status Collection.
 *
 * Contains static list of available ticket's message types, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 */
export default [
  {
    key: 0,
    value: '',
    icon: 'fiber_manual_record',
    color: 'warning',
  },
  {
    key: 10,
    value: 'New',
    icon: 'fiber_manual_record',
    color: 'active',
  },
  {
    key: 30,
    value: 'Open',
    icon: 'fiber_manual_record',
    color: 'active',
  },
  {
    key: 50,
    value: 'In progress',
    icon: 'fiber_manual_record',
    color: 'active',
  },
  {
    key: 60,
    value: 'Pending',
    icon: 'fiber_manual_record',
    color: 'pending',
  },
  {
    key: 75,
    value: 'Solution proposed',
    icon: 'fiber_manual_record',
    color: 'success',
  },
  {
    key: 80,
    value: 'Closed',
    icon: 'fiber_manual_record',
    color: 'success',
  },
  {
    key: 90,
    value: '',
    icon: 'fiber_manual_record',
    color: 'error',
  },
]
