/* eslint-disable no-unused-vars */
import { curry } from 'lib/util'
import timezones from 'model/_shared/collection/timezone'

/**
 * Returns the list of timezones with their respective {@code offset} as {@code infoText}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Gaia.Component} component    the object composition of the component that triggered the
 *                                      event
 * @param {Gaia.PlatformEvent} event    the event object
 * @return {Timezone[]} timezones
 */
const listTimezones = (module, component, event) => timezones

export default curry(listTimezones)
