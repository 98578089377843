/* global G */
import sequenceModuleLogin from 'lib/sequence/module/login'
import transform from 'lib/sequence/model/transformer'
import create from 'lib/sequence/model/api/create'

/**
 * Invite action
 *
 * Finishing the invite by activating the user account
 * and redirecting to the default route after
 * successful login.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => {
  const model = module[G.MODEL]

  /**
   * Correctly set {@code [G.STATE][G.DATA]} in model.
   */
  create(model)
  transform(model)

  const modelData = model[G.STATE][G.DATA]
  const personData = model[G.CHILDREN].person[G.STATE][G.DATA]

  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const { version } = model[G.PROPS]
  const { id, token } = module[G.STATE]

  const registerUrl = `/api/v${version}/public/register/${id}`
  const params = {
    token,
    password: modelData.value.password,
    person: personData,
  }
  try {
    await httpAdapter.put({ url: registerUrl, params })

    /**
     * Log the user in. Redirect is handled by hook.
     */
    await sequenceModuleLogin(module)()
  } catch (e) {
    console.error(e)
  }
  return args
}
