/* eslint-disable no-unused-vars */

/**
 * Finish step action.
 *
 * Handles the display and status of the step's elements.
 *
 * Allows to finally save the new service item or to discard the changes.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => args
