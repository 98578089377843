/* global G */
import { setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { get } from 'lib/sequence/component/state/value'
import { settings } from 'app/_shared/session'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'

/**
 * Alters the display of elements according to the selected request type.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {boolean} [registration]      whether this function is executed from the pre-login's
 *                                      register section
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const processRequestType = (module, registration) => async (components, ...args) => {
  const { requestForm, deviceForm, requestTypeForm } = components
  const { requestType: requestTypeRadio, requestTypeSelect, serial } = components
  const requestType = requestTypeSelect && settings.registerRequestTypesDropdown
    ? requestTypeSelect
    : requestTypeRadio
  const { type: requestTypeField } = find(requestType)

  requestTypeSelect && (settings.registerRequestTypesDropdown
    ? show(requestTypeSelect) && hide(requestTypeRadio)
    : show(requestTypeRadio) && hide(requestTypeSelect))

  if (!hidden(requestTypeForm)) {
    const selectionKey = get(requestTypeField)
    const [type] = listTicketTypes(module, null, selectionKey || -1)

    const deviceInput = (registration && type?.deviceInputRegister) ?? type?.deviceInput
    const deviceIdentify = (registration && type?.deviceIdentifyRegister) ?? type?.deviceIdentify

    selectionKey ? show(requestForm) : hide(requestForm)
    deviceInput ? show(deviceForm) : hide(deviceForm)

    setKey(deviceIdentify, G.VALIDATOR, serial)
  }

  return [components, ...args]
}

export default processRequestType
