/**
 * Generic Option type definition
 *
 * @typedef {Object} GenericOption
 * @property {String} key           key of the generic option
 * @property {String} value         value of the generic option
 */

/**
 * Generic Options Collection.
 *
 * Contains static list of available generic options that should be concatenated to another list
 * of options. E.g. ticket types, teams, ...
 *
 * @returns GenericOption[]
 */
export default [
  {
    key: '-',
    value: 'None',
  },
  {
    key: '*',
    value: 'All',
  },
]
