/* eslint-disable object-curly-newline */
export default [
  {
    key: 0,
    value: 'Any',
  },
  {
    key: 10,
    value: 'Active',
  },
  {
    key: 20,
    value: 'On Hold',
  },
  {
    key: 30,
    value: 'Done',
  },
]
