/* global G */
import { curry, isArr, setKey } from 'lib/util'
import listGenericOptions from 'app/_shared/events/collection/listGenericOptions'

/**
 * Persists the selected options into the component's state with generic {@link options} taking
 * precedence, meaning if a generic option is selected, it will erase all previously selected
 * options. If a non-generic (specific) option is selected, it will clear all generic options
 * if one is present.
 *
 * Incoming value will be converted into Array
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent} event - event
 * @return {Gaia.PlatformEvent} event
 */
const persistOptionsOrGeneric = (module, component, event) => {
  const state = component[G.STATE]
  const { item } = event.detail

  // The option the user just selected
  const newOption = item.filter(newOptions => (state?.value && isArr(state.value)
    ? !state.value.find(selectedOption => selectedOption.key === newOptions.key)
    : true))

  const genericOptions = listGenericOptions(module, component, null)

  // The option the user just selected if it's a generic one
  const newGenericOption = genericOptions.find(option => newOption.map(i => i.key).includes(option.key))

  // The array of selected options excluding generic ones
  const selectedSpecificOptions = item.filter(i => !genericOptions.map(x => x.key).includes(i.key))

  newGenericOption
    ? state.value = [newGenericOption]
    : state.value = item ? (isArr(item) && selectedSpecificOptions) || [item] : []

  setKey(true, G.UNDO, module[G.STATE][G.ACTION][G.STATE])

  // Need to update the UI in order to reflect the new, filtered options
  module[G.ADAPTER][G.UI].update(module)

  return event
}

export default curry(persistOptionsOrGeneric)
