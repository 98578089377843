/* global G */
import { curry } from 'lib/util'
import purge from 'app/_shared/events/util/purge'
import restoreInitialState from 'app/ticket/event/restoreInitialState'

/**
 * Event Handler Cancel
 *
 * Redirects to the previous section and, if the user accepts the confirmation dialog, removes any
 * possible module error and restores the initial state of the store element.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @return {Promise<void>}
 */
const cancel = async (module, component, event) => {
  const router = module[G.ADAPTER][G.ROUTER]
  const confirmedBack = await router.back()

  if (confirmedBack) {
    await purge(module, component, event)
    await restoreInitialState('store', true, module, component, event)
  } else {
    throw Error('User cancelled confirmation dialog.')
  }
}

export default curry(cancel)
