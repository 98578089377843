/* global G */
import { curry } from 'lib/util'
import search from 'app/_shared/events/search'

/**
 * Event Handler Search All Or My Teams
 *
 * Either retrieves a list of all teams using the FTS or simply the logged-in users teams
 * depending on the action.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @return {Promise<void>}
 */
const searchAllOrMyTeams = async (module, component, event) => {
  const action = module[G.STATE][G.ACTION]
  const userSession = module[G.ADAPTER][G.SESSION][G.STATE]

  if (action._name === 'index') {
    // eslint-disable-next-line no-param-reassign
    event?.detail?.term === '-' && delete event.detail.term
    return await search(null, module, component, event)
  }

  return userSession[G.META].refs?.team || []
}

export default curry(searchAllOrMyTeams)
