/* global G */
import { modelComposition } from 'trait/composition'
import { usesGetterSetter } from 'trait/uses'
import validator from 'model/feedback/validator/documentation'
import { pipe } from 'lib/util'
import { withGetterSetter } from 'trait/with'
import toBoolean from 'model/_shared/transformer/toBoolean'
import toGeneric from 'model/feedback/transformer/toGeneric'
import toExplicitBoolean from 'model/_shared/transformer/toExplicitBoolean'
import toRef from 'model/_shared/transformer/toRef'

const descriptor = 'model::feedback::documentation'

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    // value
    docHelpful: toExplicitBoolean('docHelpful'),
    problemUnderstandability: toBoolean('problemUnderstandability'),
    problemMissingSolution: toBoolean('problemMissingSolution'),
    problemContent: toBoolean('problemContent'),
    problemLanguage: toBoolean('problemLanguage'),
    // refs
    document: toRef('document'),
    // sub-model
    generic: toGeneric('generic'),
  }),
)
