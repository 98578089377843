/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'

const unknownLabel = async module => await module[G.ADAPTER][G.INTL]._t(
  'label.unknown',
  {
    ns: 'common',
    _key: 'label.unknown',
    defaultValue: 'Unknown',
  },
)

/**
 * Event Handler New Device
 *
 * Returns a row to be displayed when no device is found for a serial, which would allow a user to
 * add a new device.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @return {Promise<object[]>}
 */
const newDevice = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const { device } = asObject(stepper[G.CHILDREN])
  const { search: searchTab } = asObject(device[G.CHILDREN])
  const { searchField } = asObject(searchTab[G.CHILDREN])
  const serial = searchField[G.STATE].value
  return [{ value: { serial, name: await unknownLabel(module) } }]
}

export default curry(newDevice)
