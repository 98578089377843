/* global G */
import { pipe } from 'lib/util'
import ui from 'lib/hook/ui/update'
import noop from '@gaia/util/noop/action'
import appModuleComposition from 'trait/composition/appmodule'
import { usesGetterSetter } from 'trait/uses'
import { canHookInto } from 'trait/can'

const descriptor = 'module::error'
const actions = {
  index: noop,
  acl: noop,
}
const _baseHooks = {
  before: [],
  after: [ui],
}
const hooks = {
  [G.ACTIONS]: {
    index: _baseHooks,
    acl: _baseHooks,
  },
}
const events = {

}

/**
 * Module Error
 *
 * used for errors, ie navigation, acl
 *
 * @param {Gaia.AppModule.Spec} obj - configuration object
 * @return {Gaia.AppModule.Spec} obj - object composition
 */
const composition = obj => pipe(
  appModuleComposition(descriptor),
  usesGetterSetter(G.ACTIONS, actions),
  usesGetterSetter(G.EVENTS, events),
  canHookInto(G.ACTIONS, hooks),
)(obj)

export default composition
