/* eslint-disable no-unused-vars,no-param-reassign */
/* global G */
import find from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'

/**
 * Created action.
 *
 * Shows a successful message with the created ticket number.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const { info } = find(component)

  map(model)(info)

  return args
}
