/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import validate from 'lib/sequence/model/validate'
import find from 'lib/sequence/component/children/find'

/**
 * Validate Account Event Handler
 *
 * Validates the account field of the user section
 * @type {Gaia.AppModule.EventHandler}.
 */
export default curry(async (module, component, event) => {
  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { user } = find(actionComponent)
  await validate(model)(user)
  module[G.ADAPTER][G.UI].update(module)
})
