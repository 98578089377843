/* eslint-disable no-nested-ternary */
/* global G */
import { curry, getFirstItem, isArr, isStr } from 'lib/util'
import redirectSequence from '@gaia/sequence/module/adapter/router/redirect'
import routeComposition from '@gaia/trait/composition/route'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'

/**
 * Redirect To Part Or Equipment Event Handler
 *
 * Redirect upon clicking on an item in the {@link AutoComplete} or hitting enter based on what
 * happened:
 *  - Redirect to equipment details if item is an equipment
 *  - Redirect to part details if item is a part
 *  - Redirect to pdf model if item is a docChunk
 *  - Redirect to results page if item is a string
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.AppModule.Action} component action component
 * @param {PlatformEvent} event             the event that triggered this handler
 * @param {Object} event.detail             custom event details
 * @param {string} event.detail.key         a model key to pass to {@link redirectSequence}
 * @return {Promise<Event | undefined>} void
 */
const redirectToSearchOrDetail = async (module, component, event) => {
  const moduleState = module[G.STATE]

  try {
    const { item, options, term } = event.detail || {}
    const pressedEnterKey = event.key === 'Enter'
    const pressedSearchIcon = !pressedEnterKey && (!item || isStr(item) || isArr(item))
    // If we come here from the results page, we need to grab the initial search term from the
    // search field and pass it to the pdf action in case we clicked on a docChunk
    const { searchField } = sequenceComponentFindProxy(module[G.STATE][G.ACTION][G.COMPONENT])

    // If item is null, the user deleted (backspace) the search term, we need to
    // exit early in this case.
    if (item === null || (isArr(item) && pressedEnterKey)) {
      return
    }

    const soleCandidate = options?.length === 1 && options?.[0]?.key !== -1

    if ((item && isStr(item) && !soleCandidate) || pressedSearchIcon) {
      const { route: { results } } = component[G.CONFIGURATION]

      moduleState[G.ROUTE] = routeComposition(results.module, results.action)

      // Redirect to results page
      await redirectSequence(module)(item ? { term: item.trim?.() || item } : [])
      return
    }

    const candidate = soleCandidate ? getFirstItem(options) : item

    const { key, value } = candidate || {}
    const { value: { type = 'equipment' } = {} } = candidate || {}
    const { route: { equipment, part, attachment, action = null } } = component[G.CONFIGURATION]

    moduleState[G.ROUTE] = action
      ? routeComposition(null, action)
      : (type === 'article' && (moduleState[G.ROUTE] = routeComposition(part.module, part.action)))
        || (type === 'equipment' && (moduleState[G.ROUTE] = routeComposition(equipment.module, equipment.action)))
        || (type === 'docChunk' && (moduleState[G.ROUTE] = routeComposition(attachment?.module, attachment.action)))
        || (type === 'documentation' && (moduleState[G.ROUTE] = routeComposition(attachment?.module, attachment.action)))

    const currentAttachment = value?.attachment
      ? {
        key: value?.attachment?.id,
        ...value?.attachment.attachment,
      }
      : null

    const docChunkArgs = {
      value: {
        ...term && { term },
        ...value?.page && { page: value.page },
        ...value?.label && { label: value.label },
        ...searchField && { term: get(searchField) },
        ...value?.attachment && { attachment: currentAttachment },
        ...value?.documentationId && { documentationId: value.documentationId },
      },
    }

    const documentationArgs = {
      value: {
        ...value?.label && { label: value.label },
        ...value?.url && { attachment: { value: { url: value.url, name: value.label } } },
        ...value?.attachment && { attachment: currentAttachment },
        ...value?.documentationId && { documentationId: value.documentationId },
      },
    }

    const args = (type === 'docChunk' && docChunkArgs)
      || (type === 'documentation' && documentationArgs)
      || { key }

    await redirectSequence(module)(args)
  } catch (e) {
    console.error(e)
  }
}

export default curry(redirectToSearchOrDetail)
