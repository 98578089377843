/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import withGetterSetter from 'trait/with/GetterSetter'
import concat from 'model/_shared/decorator/concat'
import drop from 'model/_shared/transformer/drop'
import toContactChannels from 'model/_shared/transformer/toContactChannels'
import fromContactChannels from 'model/_shared/decorator/fromContactChannels'
import fromSingleRef from 'model/_shared/decorator/fromSingleRef'
import validator from 'model/ticket/validator'
import toFirstKey from 'model/_shared/transformer/toFirstKey'
import fromCollection from 'model/_shared/decorator/fromCollection'
import prefChannel from 'model/_shared/collection/ticket/prefChannel'
import fromCollectionValue from 'model/_shared/decorator/fromCollectionValue'

const descriptor = 'model::ticket::requesterContact'

const tPrefChannel = { ns: 'ticket', _key: 'prefChannel' }

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    name: drop('name'),
    contactChannels: toContactChannels('contactChannels'),
    prefChannel: toFirstKey('prefChannel'),
    prefChannelName: drop('prefChannelName'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    name: concat('name', ['firstName', 'lastName']),
    contactChannels: fromContactChannels('contactChannels'),
    organisation: fromSingleRef('organisation', 'organisation'),
    prefChannel: fromCollection('prefChannel', prefChannel, tPrefChannel),
    prefChannelName: fromCollectionValue('prefChannel', 'prefChannelName', prefChannel, tPrefChannel),
  }),
)
