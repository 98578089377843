/* global G */
import map from 'lib/sequence/model/api/map'

/**
 * Map Attribute Hook
 *
 * Maps an attribute from the module's main model to the current action component.
 *
 * @param {string} name             the name of the model's attribute to map
 * @return {function(...[*]): *[]}
 */
const mapAttribute = name => obj => (...args) => {
  const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]
  const model = obj[G.MODEL]
  const attribute = model[G.CHILDREN][name]
  map(attribute)(actionComponent)
  return args
}

export default mapAttribute
