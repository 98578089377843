/* eslint-disable no-unused-expressions */
/* global G */
import { curry } from '@gaia/util'

const descriptor = 'model::transformer::toNotify'

/**
 * To Notify Transformer
 *
 * deletes or nulls property, identified by key, from payload
 *
 * @type {(function(...[*]): (*))|*}
 */
const fn = curry((key, obj, data) => {
  console.warn(descriptor)

  const _data = data

  try {
    const item = obj[G.CHILDREN][key]
    const { type } = item[G.PROPS]
    const value = obj[G.STATE][G.DATA].value[key]

    value === null || value === true || value === 'on'
      ? _data[type][key] = true
      : _data[type][key] = false
  } catch (e) {
    console.error(descriptor, e)
  }

  return _data
})

export default fn
