/* global G */
/* eslint-disable no-param-reassign */
import { curry } from 'lib/util'

/**
 * Callback for adding the updated {@param} unreadMessages count.
 *
 * @param prevState
 * @param unreadMessages
 * @returns {*&{unreadMessages}}
 */
const addData = (prevState, unreadMessages) => ({ ...prevState, unreadMessages })

/**
 * Fetched the new {@code unreadMessages} count for the
 * given ticket.
 *
 * @param module
 * @param component
 * @param event
 * @returns {Promise<*>}
 */
// eslint-disable-next-line no-unused-vars
const updateUnreadMessagesCount = async (module, component, event) => {
  const obj = module[G.MODEL]
  const { api } = obj[G.PROPS]
  const { version } = obj[G.PROPS]
  const ticketId = event.detail.key
  const url = `/api/v${version}/${api}/${ticketId}/unreadMessages`

  try {
    const fetchedTicket = await obj[G.ADAPTER][G.HTTP].get({ url })
    return { data: fetchedTicket.value.unreadMessages, addData }
  } catch (e) {
    console.error(e)
  }

  return {}
}
export default curry(updateUnreadMessagesCount)
