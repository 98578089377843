/* eslint-disable no-unused-vars */
/* global G */
import { curry, getFirstItem } from 'lib/util'
import listUserRoles from 'app/_shared/events/collection/listUserRoles'

/**
 * Lists the users translated roles
 *
 * Takes the decorated user roles (meaning split into {@code role} and {@code atOrg}) and replaces
 * {@code role} with the translated user role. Also adds the current {@code index} in the array
 * to the item so that we know what role we are dealing with later.
 *
 * @param {Gaia.AppModule.Spec} module  app module
 * @param {Gaia.Component} component    ui component
 * @param {Event} event                 event
 * @return {Promise<void>}              void
 */
const listDecoratedUserRoles = async (module, component, event) => {
  const model = module[G.MODEL]
  const { roles: rolesModel } = model[G.CHILDREN]
  const roles = rolesModel[G.CACHE]

  try {
    return roles.reduce((acc, key) => {
      const userRole = getFirstItem(listUserRoles(module, component, key.role))

      return userRole
        ? [
          ...acc,
          {
            key: key.index.toString(),
            value: {
              /**
               * After we created a new user (this needs to be fixed server side) it won't have
               * any technical roles (like {@code system.active}), therefore the role we added
               * in creation will be the only item in the array (index 0). If we pass this as
               * an int here, it will get swallowed in the process because 0 is falsy and the
               * cell component will get passed {@code undefined}. So let's make sure it's a
               * string and convert it back in the cell components.
               */
              index: key.index.toString(),
              role: userRole,
              atOrg: key.atOrg,
            },
          },
        ]
        : acc
    }, [])
  } catch (e) {
    console.error(e)
  }

  return [{}]
}

export default curry(listDecoratedUserRoles)
