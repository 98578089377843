/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import withGetterSetter from 'trait/with/GetterSetter'
import { usesGetterSetter } from 'trait/uses'
import toAddress from 'model/_shared/transformer/toAddress'
import fromAddress from 'model/_shared/decorator/fromAddress'

const descriptor = 'model::ticket::requester'

export default pipe(
  modelComposition(descriptor),
  usesGetterSetter(G.TRANSFORMER, {
    address: toAddress('address'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    address: fromAddress('address'),
  }),
)
