/* eslint-disable no-unused-vars */
/* global G */
import { curry, setKey } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import sequenceComponentState from 'lib/sequence/component/state'

const {
  get: confirmed,
  set: setConfirmed,
} = sequenceComponentState('confirmed')

/**
 * Event Handler Step Confirm.
 *
 * Sets the currently active step as confirmed.
 *
 * @param {boolean} value                 either to set the step state as confirmed or not confirmed
 * @return {Gaia.AppModule.EventHandler}  the confirm event handler.
 */
const confirmStep = (value = true) => curry(async (module, component, event) => {
  try {
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { stepper } = asObject(actionComponent[G.CHILDREN])
    const stepperState = stepper[G.STATE]
    const { currentStep } = stepperState
    const { [currentStep]: step } = asObject(stepper[G.CHILDREN])
    const changed = confirmed(step) !== value
    setConfirmed(step, value)
    changed && setKey(true, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
  } catch (e) {
    console.error(e)
  }
})

const confirm = confirmStep(true)
const deconfirm = confirmStep(false)

export {
  confirm,
  deconfirm,
}
