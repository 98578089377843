import { curry } from 'lib/util'
import { settings } from 'app/_shared/session'

/**
 * Is Doc Type Event Handler
 *
 * This event handler is a predicate, and should be called by a {@code filter} event
 * handler executed by {@link Explorer}.
 *
 * It checks whether the current node's {@code nodeType} is equal to the nodeType defined
 * in the tenant settings at {@code documentationFilter.[type]}.
 *
 * Additionally, if the node at hand fulfills the check, it will set the {@code initialNode}
 * to {@code true} on the node, marking it as the node to be expanded once the {@link Tree}
 * component renders. There should only be one {@code initialNode}, therefore it
 * resets the property (if set) on every node it encounters (before checking).
 *
 * @param {string} target the specific type to check against, defined in the settings
 * @returns {boolean}
 */
const isDocType = target => (module, component, eventOrOptions) => {
  const node = eventOrOptions || {}

  // In case this node has been mark as the initialNode by another predicate, let's
  // reset it here. Only the last predicate's decision on this matter should count.
  node?.initialNode && (node.initialNode = undefined)

  if (node?.nodeType !== target?.nodeType) return true

  const isMatch = node?.nodeType === target.nodeType && node?.name === target?.name

  // Mark this node as the initial node if it matches.
  isMatch && (node.initialNode = true)

  return isMatch
}

/**
 * Higher order function that takes {@param type} and determines the applicable filters from the
 * tenant config. If we curry the whole thing, like {@code export default curry(type, m, c, e)},
 * we need to look into the tenant settings for each node we call this filter with later. That's
 * unnecessary. We only need to have this information once for all nodes.
 *
 * This function will return a curried function with the relevant filters already present.
 *
 * @param {string} type the doc type to check against
 * @returns {(function(...[*]): (*))|*}
 */
export default (type) => {
  const {
    [type]: target = null,
  } = settings?.documentationTreeFilter?.docType?.find(docType => docType[type]) || {}

  if (!target) {
    console.warn(
      `Documentation tree doc type "${type}" specified in business logic, \
         but not present in tenant settings. Will skip.`,
    )

    // If the filter is not present in the tenant config, just return a noop function
    // that returns {@code true}
    return () => true
  }

  return curry(isDocType(target))
}
