/* global G */
import { curry } from 'lib/util'
import getQuickAction from '@app/_shared/events/appbar/quickActions/get'
import getFavorite from 'app/_shared/events/favorite/get'

/**
 * Update Quick Actions Event Handler
 *
 * Toggles the visibility of the {@code download}, {@code favorite} and {@code feedback}
 * quick actions display in the {@link AppBar} depending on the type of node that is
 * currently being displayed in the tree. That being said, this event handler only
 * makes sense when displaying a documentation tree.
 *
 * This handler applied some special logic depending on the type of quick action
 * - {@code download}: Disables the quick action by default, so that the user can't click on it.
 *   It should get enabled (once ready) by the respective component loading it and executing
 *   its {@code onState} handler will the appropriate attachment data like src and name.
 * - {@code favorite}: Sets the {@code active} state of the favorite quick action depending
 *   on whether the documentation/attachment at the current node has been favored or not.
 *   To avoid confusion: This handler is executed on the tree's {@code onChange} handler.
 *   This means it will get triggered every time we navigate through the tree. So in terms
 *   of the favorite's active state, this handler is responsible for setting it correctly
 *   once we display a new node. The {@link displayFavoriteActiveInTree} handler is responsible
 *   for setting the correct state once the current node CHANGES (we favor/un-favor it).
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component      the current action's main component
 * @param {PlatformEvent} event                     the event object that triggered this handler
 */
const updateQuickActions = (module, component, event) => {
  const { node } = event?.detail?.value || event || {}
  const downloadAction = getQuickAction('download', module, component, event)
  const favoriteAction = getQuickAction('favorite', module, component, event)
  const feedbackAction = getQuickAction('feedback', module, component, event)

  const payload = node?.documentationId || node?.attachmentId

  const isLeaf = node?.type === 'File' || node.type === 'Url' || false
  const isFavorite = getFavorite(module, component, {
    ...event, detail: { value: payload },
  })

  if (favoriteAction) {
    favoriteAction.active = isFavorite
    favoriteAction.hidden = !isLeaf
  }

  if (feedbackAction) {
    feedbackAction.hidden = !isLeaf
  }

  if (downloadAction) {
    downloadAction.disabled = true
    downloadAction.hidden = !isLeaf
  }

  if (downloadAction || favoriteAction || feedbackAction) {
    module[G.ADAPTER][G.UI].update(module)
  }
}

export default curry(updateQuickActions)
