/* eslint-disable no-unused-expressions */
/* global G */
import { get, reset } from 'lib/sequence/component/state/value'
import validate from 'lib/sequence/model/validate'
import { add as addNote } from 'app/_shared/events/note/index'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { fromTimeEntry } from 'app/ticket/event/note/time'
import {setKey} from "lib/util";
import {sortNotesByDate} from "app/ticket/hook/note";

/**
 * Returns the payload used to set the ticket as closed.
 *
 * @private
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @returns {object}
 */
export default async (module, component) => {
  const model = module[G.MODEL]
  const { content, statusReason, note, spentTime, startDate } = sequenceComponentFind(component)

  await validate(model)(content)
  model[G.STATE][G.ERROR] && throw Error('Model validation error')

  const statusReasonValue = parseInt(get(statusReason), 10)
  const noteText = get(note)
  const time = get(spentTime)
  const spentSeconds = time
    ? fromTimeEntry(module, component, { value: time })
    : null

  reset(note)

  noteText && noteText.length && addNote(module, component, {
    text: noteText,
    type: statusReasonValue === 80 ? 'close_resolve' : 'close_cancel',
    ...spentSeconds && { spentTime: spentSeconds },
  }, (item) => {
    const { value } = item
    const timeNotes = module[G.STATE]?.timeNotes
    // So that the new time note is available without fetching them again
    value?.spentTime && setKey(sortNotesByDate([item, ...timeNotes]), 'timeNotes', module[G.STATE])
  })

  return {
    refs: {},
    value: {
      status: 80,
      statusReason: statusReasonValue,
      postponeDate: null,
    },
  }
}
