/* global G */
import { curry } from 'lib/util'
import { empty } from 'lib/util/object'

/**
 * To Data transformer
 *
 * Adds all children from target attribute's value to the payload, then, adds all children from
 * source attribute's value that can also be found inside target.
 *
 * Results in adding {@param targetKey} to the payload's value as a new object containing all
 * properties from target's value overwritten by all properties from source's value that can also be
 * found inside target.
 *
 * If {@param targetKey} is omitted, {@param sourceKey} will be used also for the target's children.
 *
 * @param {string} sourceKey              the name of the child attribute to overwrite target's
 *                                        properties with
 * @param {string} [targetKey=sourceKey]  the name of the child attribute to transform
 * @param {boolean} [nullable]            whether to set data to null instead of removing it if
 *                                        there is no data
 * @return {function}                     resulting payload
 */
const fn = (sourceKey, targetKey = sourceKey, nullable = false) => curry((obj, data) => {
  const _data = data

  const source = obj[G.CHILDREN][sourceKey]
  const target = obj[G.CHILDREN][targetKey]
  const { type } = target[G.PROPS]

  try {
    const attributes = target[G.CHILDREN]
    const attributeNames = Object.keys(attributes)
    // By default we just assign target's value to data
    _data[type][targetKey] = target[G.STATE][G.DATA]?.value
    // if source and target are different...
    if (sourceKey !== targetKey) {
      // ...we assign target's value to data and then override the result with all iterable
      // properties from source that can be found in target
      _data[type][targetKey] = attributeNames.reduce((acc, name) => {
        const value = source[G.STATE][G.DATA]?.value[name]
        value && (acc[name] = value)
        return acc
      }, _data[type][targetKey] || {}) // we keep original target's values as default
    }
    // if final object ended up with no iterable properties, we remove it from data
    if (empty(_data[type][targetKey])) {
      _data[type][targetKey] = null
    }
  } catch (e) {
    _data[type][targetKey] = null
  }

  if (_data[type][targetKey] === null && !nullable) {
    delete _data[type][targetKey]
  }

  return _data
})

export default fn
