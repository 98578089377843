import noop from '@gaia/util/noop/action'
import equipment from 'app/equipmentInformation/actions/equipment'
import equipmentDetails from 'app/equipmentInformation/actions/equipmentDetails'
import part from 'app/equipmentInformation/actions/part'
import results from 'app/equipmentInformation/actions/results'
import documentation from 'app/equipmentInformation/actions/documentation'
import document from 'app/equipmentInformation/actions/document'
import equipmentDocumentation from 'app/equipmentInformation/actions/equipmentDocumentation'
import equipmentErrorTrees from 'app/equipmentInformation/actions/equipmentErrorTrees'
import equipmentBulletin from 'app/equipmentInformation/actions/equipmentBulletin'
import serviceReference from 'app/equipmentInformation/actions/serviceReference'
import attachmentWithFeedback from 'app/equipmentInformation/actions/attachmentWithFeeback'
import attachmentTreeWithFeedback from 'app/equipmentInformation/actions/attachmentTreeWithFeeback'
import submitFeedback from 'app/_shared/action/feedback/submit'
import successFeedback from 'app/_shared/action/feedback/success'
import submitFavorite from 'app/_shared/action/favorite/submit'
import successFavorite from 'app/_shared/action/favorite/success'

export default {
  index: noop,
  document,
  equipment,
  equipmentDetails,
  part,
  explosionDrawing: noop,
  results,
  soon: noop,
  pdf: noop,
  attachmentWithFeedback,
  attachmentTreeWithFeedback,
  submitFeedback,
  successFeedback,
  submitFavorite,
  successFavorite,
  electricDiagram: noop,
  documentation,
  drilldown: noop,
  equipmentDocumentation,
  equipmentErrorTrees,
  equipmentBulletin,
  serviceReference,
}
