/* global G */
import { curry } from 'lib/util'

/**
 * To ToBeValidated Transformer
 *
 * Accesses the {@param obj} state to determine if we should null the {@code toBeValidated}
 * property in the payload.
 *
 * @param key
 * @param obj
 * @param data
 * @returns {*}
 */
const toToBeValidated = (key, obj, data) => {
  const _data = data

  if (!_data.value[key]) return _data

  const { verified = false } = obj[G.STATE]

  if (verified) {
    _data.value[key] = null
    return _data
  }

  _data.value[key] = data.value[key].reduce((acc, item) => ({ ...acc, ...item.value }), {})

  return _data
}

export default curry(toToBeValidated)
