/* eslint-disable no-await-in-loop,no-plusplus,implicit-arrow-linebreak,no-param-reassign,
no-unused-expressions */
/* global G */
import { asyncPipeSpread, def, isArr, isObj } from 'lib/util'
import session from '@app/_shared/session'
import quickActions from '@app/_shared/hook/appbar/quickActions'

/**
 * Returns the id of the user assigned to the current ticket.
 *
 * @param {Gaia.Model.Spec} obj - data model
 * @return {false|String}
 * @private
 */
const _assigneeRef = obj => obj[G.CHILDREN].assignee[G.CACHE]?.[0]?.key

/**
 * Returns status of the current ticket.
 *
 * @param {Gaia.Model.Spec} obj  data model
 * @return {Number}
 * @private
 */
const _status = obj => obj[G.CHILDREN].status[G.CACHE]?.[0]?.key || obj[G.CHILDREN].status[G.CACHE]

/**
 * Checks whether the current ticket model fulfills the assignee condition in the following way:
 * - if assignee is true, returns true if the current user is the current ticket's assignee
 * - if assignee is false, returns true if the current user is not the current ticket's assignee
 * - if assignee is undefined, always returns true
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Boolean} [assignee]          the assignee configuration property
 * @returns {Boolean}                   whether the current ticket is assigned to the current user
 * @private
 */
const _userIsAssignee = (module, assignee) => {
  const assigneeRef = _assigneeRef(module[G.MODEL])
  const currentUserId = session(module).user.key()
  return def(assignee)
    ? (assignee && currentUserId === assigneeRef) || (!assignee && currentUserId !== assigneeRef)
    : true
}

/**
 * Checks whether the user has acl permissions on the current ticket.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Number[]} acl                array of permissions
 * @returns {Boolean}          whether the current the user has 'write' permissions
 *                                      on the ticket
 * @private
 */
const _hasPermissions = (module, acl) => {
  try {
    const model = module[G.MODEL]
    const { acl: modelAcl = acl } = model[G.CACHE]
    return def(acl) ? module[G.ADAPTER][G.ACL].model(modelAcl, acl) : true
  } catch (e) {
    return false
  }
}

/**
 * Checks whether {@code array} contains only negative values.
 *
 * @param {Number[]} array  an array of numbers
 * @returns {Boolean}       true if it only contains negative values
 */
const arrayIsExclusive = array => array.reduce((acc, item) => acc && item < 0, true)

/**
 * Checks whether the current ticket model fulfills the status condition in the following way:
 * - if {@param status} is an array of integers:
 *  - if it contains negative integers, returns true if the ticket does not have any one of those
 *    statuses
 *  - if it contains positive integers, returns true if the ticket has one of those status
 * - if {@param status} is an object, the following conditions have to be meet:
 *  - if it has a min property, ticket status must be greater than it
 *  - if it has a max property, the ticket status must be less than it
 *  - if it has a but property, it must not contain the ticket status
 * - if {@param status} is not defined, always returns true
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {number[]|object} status      the status configuration property
 * @param {number} status[]
 * @param {number} status.min
 * @param {number} status.max
 * @param {number[]} status.but
 * @returns {boolean}
 * @private
 */
const _statusIsValid = (module, status) => {
  const currentStatus = _status(module[G.MODEL])

  if (def(status)) {
    if (isArr(status)) {
      return arrayIsExclusive(status)
        ? !status.some(code => code + currentStatus === 0)
        : status.includes(currentStatus)
    }
    if (isObj(status)) {
      const { min = -Infinity, max = Infinity, but = [] } = status
      return currentStatus >= min && currentStatus <= max && !but.includes(currentStatus)
    }
  }
  return true
}

/**
 * Checks whether a quick action should be displayed according to its configured show conditions and
 * the state of the current ticket model.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @returns {function({team?: *, assignee?: *, status?: *})}
 * @private
 */
const _check = obj => ({ assignee, status, acl }) =>
  _hasPermissions(obj, acl)
  && _userIsAssignee(obj, assignee)
  && _statusIsValid(obj, status)

/**
 * Hides those quick actions whose show condition isn't fulfilled.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @returns {function({team?: *, assignee?: *, status?: *})}
 * @private
 */
const processTicketDisplayConditions = obj => (...args) => {
  const { quickActions: quickActionsList = [] } = obj[G.STATE][G.ACTION][G.UI]

  quickActionsList.forEach((quickAction) => {
    quickAction.hidden = quickAction.show && (isArr(quickAction.show)
      ? !quickAction.show.some(condition => _check(obj)(condition))
      : !_check(obj)(quickAction.show))
  })

  return args
}

/**
 * QuickActions Hook
 *
 * Configures the quick actions to be displayed in ui appbar segment.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): Promise<*[]>}
 */
export default obj => async (...args) => asyncPipeSpread(
  quickActions(obj),
  processTicketDisplayConditions(obj),
)(...args)
