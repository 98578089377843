/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import toContactChannels from 'model/_shared/transformer/toContactChannels'
import toRef from 'model/_shared/transformer/toRef'
import { usesGetterSetter } from '@gaia/trait/uses'
import withGetterSetter from 'trait/with/GetterSetter'
import toInt from 'model/_shared/transformer/toInt'
import fromContactChannels from 'model/_shared/decorator/fromContactChannels'
import toArray from 'model/_shared/transformer/toArray'
import toData from 'model/_shared/transformer/toData'
import toFollowTheSun from 'model/team/transformer/toFollowTheSun'
import toStatus from 'model/_shared/transformer/toStatus'
import validator from './validator'

const descriptor = 'model::team'

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    contactChannels: toContactChannels('contactChannels', true),
    organisation: toRef('organisation'),
    sortOrder: toInt('sortOrder'),
    status: toStatus('status'),
    defaultRole: toArray('defaultRole', false),
    notify: toData('notify', 'notify', false),
    forward: toData('forward', 'forward', false),
    initialAssignment: toData('initialAssignment', 'initialAssignment', false),
    followTheSun: toFollowTheSun('followTheSun'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    contactChannels: fromContactChannels('contactChannels'),
  }),
)
