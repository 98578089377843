/* global G */
import { curry } from 'lib/util'
import created from 'model/ticket/collection/filter/created'

/**
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent} event - event
 * @return {Array} countries
 */
// eslint-disable-next-line no-unused-vars
const listTicketFilterStatus = (module, component, event) => {
  return created.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `filter.created.${item.key}`,
      { ns: 'ticket', _key: `filter.created.${item.key}`, defaultValue: item.value },
    ),
  }))
}

export default curry(listTicketFilterStatus)
