/* global G */
import { curry, setKey } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { back } from 'app/_shared/events'

/**
 * Event Handler Save Message And Back
 *
 * Save's the message texts in the module state and redirects back.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 information about the event that triggered this handler
 * @return {Promise<void>}
 */
const saveAndBackFromMessage = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { messageField } = sequenceComponentFind(actionComponent)
  const text = get(messageField)

  setKey(text, 'text', module[G.STATE])

  await back(module, component, event)
}

export default curry(saveAndBackFromMessage)
