/* global G */
import settings from '@tenant/settings'
import { pipe } from 'lib/util'
import { modelComposition } from 'trait/composition'
import { usesGetterSetter } from 'trait/uses'
import { withGetterSetter } from 'trait/with'
import toRef from 'model/_shared/transformer/toRef'
import toData from 'model/_shared/transformer/toData'
import fromCollection from 'model/_shared/decorator/fromCollection'
import fromCollectionValue from 'model/_shared/decorator/fromCollectionValue'
import defaultTypes from 'model/_shared/collection/ticket/type'
import drop from 'model/_shared/transformer/drop'
import toFirstKey from 'model/_shared/transformer/toFirstKey'
import validator from './validator'
import ticketStatus from './collection/status'

const descriptor = 'model::request'

const types = settings?.ticketTypes || defaultTypes
const tType = { _key: 'ticket.type', ns: 'custom' }
const tStatus = { _key: 'status.request', ns: 'ticket' }

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    // value
    type: toFirstKey('type'),
    typeName: drop('typeName'),
    itemData: toData('item', 'itemData'),
    description: toData('description'),
    status: toFirstKey('status'),
    statusName: drop('statusName'),
    // refs
    submitter: toRef('submitter'),
    requesterContact: toRef('requesterContact'),
    requesterContactOrg: toRef('requesterContactOrg'),
    additionalParty: toRef('additionalParty'),
    item: toRef('item'),
    itemInstalledAt: toRef('itemInstalledAt'),
    itemServiceBy: toRef('itemServiceBy'),
    product: toRef('product'),
    equipment: toRef('equipment'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    // value
    type: fromCollection('type', types, tType),
    typeName: fromCollectionValue('type', 'typeName', types, tType),
    status: fromCollection('status', ticketStatus, tStatus),
    statusName: fromCollectionValue('status', 'statusName', ticketStatus, tStatus),
  }),
)
