/* global G */
import { curry } from 'lib/util'

/**
 * Get Support
 *
 * Event Handler, forcing redirect to preconfigured route.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 */
// eslint-disable-next-line no-unused-vars
const ui = async (module, component, event) => {
  module[G.ADAPTER][G.UI].update(module)
}

export default curry(ui)
