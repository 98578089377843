/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import search from 'app/_shared/events/search'
import destroy from 'app/_shared/events/destroy'
import dialog from 'app/_shared/events/dialog'

/**
 * Returns translations for the organisations delete error dialog.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {Promise<{children: {cancel: {transform: string, value: *, key: string}}, text: *, title: *}>}
 * @private
 */
const _errorDialog = async module => ({
  title: await module[G.ADAPTER][G.INTL]._t(
    'dialog.deleteError.title',
    {
      ns: 'device',
      _key: 'dialog.deleteError.title',
      defaultValue: 'Deletion not possible',
    },
  ),
  text: await module[G.ADAPTER][G.INTL]._t(
    'dialog.deleteError.text',
    {
      ns: 'device',
      _key: 'dialog.deleteError.text',
      defaultValue: 'Sorry, device is related to request(s). Please unrelate item from all requests then delete. Alternatively, set item status to obsolete or archived.',
    },
  ),
  children: {
    cancel: {
      key: 'cancel',
      value: await module[G.ADAPTER][G.INTL]._t(
        'button.cancel',
        {
          ns: 'common',
          _key: 'button.cancel',
          defaultValue: 'Cancel',
        },
      ),
    },
  },
})

/**
 * Delete If No SubItems Event Handler
 *
 * Attempts to delete the current service item. It checks whether there are ticket associated  with
 * the service item and shows {@link _errorDialog} if so. If not, it deletes the service item
 * using the {@link destroy} event handler.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<void>}
 */
const destroyIfNoSubItems = async (module, component, event) => {
  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  try {
    const subTickets = await search(null, module, actionComponent, {
      type: 'ticket',
      filter: { item: model[G.STATE][G.REF] },
    })

    if (subTickets?.length) {
      await dialog(module, component, {
        cancel: false,
        title: {
          ns: 'device',
          key: 'dialog.deleteError.title',
          defaultValue: 'Deletion not possible',
        },
        text: {
          ns: 'device',
          key: 'dialog.deleteError.text',
          defaultValue: 'Sorry, device is related to request(s). Please unrelate item from all requests then delete. Alternatively, set item status to obsolete or archived.',
        },
      })
    } else {
      await destroy(module, component, event)
    }
  } catch (e) {
    console.error(e)
  }
}

export default curry(destroyIfNoSubItems)
