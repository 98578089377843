/* global G */
import { curry } from 'lib/util'
import countries from 'model/address/collection/country'

/**
 * Returns the translated list of countries.
 *
 * @param {Gaia.AppModule.Spec} module            the current module object composition
 * @param {Gaia.Component} component              the object composition of the component that
 *                                                triggered the event handler
 * @param {Gaia.PlatformEvent|string} eventOrKey  the event or the key of the country to obtain
 * @return {object[]} countries
 */
// eslint-disable-next-line no-unused-vars
const listCountries = (module, component, eventOrKey) => {
  const key = eventOrKey?.detail?.key || eventOrKey
  const scope = key ? countries.filter(item => item.key === key) : countries
  const list = scope.map(item => ({
    key: item.key,
    value: module[G.ADAPTER][G.INTL]._t(
      `country.${item.key}`,
      { ns: 'generic', _key: `country.${item.key}`, defaultValue: item.value },
    ),
  }))
  return list.sort((a, b) => a.value.localeCompare(b.value))
}

export default curry(listCountries)
