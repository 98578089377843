import chain from 'app/_shared/events/util/chain'
import check from 'app/_shared/events/util/check'
import clean from 'app/_shared/events/util/clean'
import debounce from 'app/_shared/events/util/debounce'
import onKey from 'app/_shared/events/util/onKey'
import platform from 'app/_shared/events/util/platform'
import purge from 'app/_shared/events/util/purge'
import sublist from 'app/_shared/events/util/sublist'

const utilEventHandlers = {
  chain,
  check,
  clean,
  debounce,
  onKey,
  platform,
  purge,
  sublist,
}

export {
  utilEventHandlers as default,
  chain,
  check,
  clean,
  debounce,
  onKey,
  platform,
  purge,
  sublist,
}
