/* global G */
import { curry } from 'lib/util'
import { getPropertyName } from 'lib/util/object'

const descriptor = 'model::team::followTheSun::decorator::fromTicketType'

/**
 * From Ticket Type Decorator
 *
 * Returns a list of string, in which each string represents the key of the respective object
 * in the original array.
 *
 * @example
 * [{ foo: ...}, { bar: ... }] -> ['foo', 'bar']
 *
 * @param {string} key          the name of the attribute to store the data to
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {object} component    the component that matched the attribute name during the mapping
 */
const fn = (key, obj, component) => {
  console.warn(descriptor, key)
  const attribute = obj[G.CHILDREN]?.[key]

  attribute[G.CACHE] = attribute?.[G.CACHE]
    ? attribute[G.CACHE].map(ticketType => getPropertyName(ticketType))
    : null

  return component
}

export default curry(fn)
