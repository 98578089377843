/* global G */

/**
 * Maps a source to a target for a given message.
 * @param module
 * @returns {*}
 */
const target = (module) => {
  const source = module._name.split(':').at(-1)

  switch (source) {
    case 'ticket':
      return module[G.MODEL][G.CHILDREN].request?.[G.CACHE]
    case 'request':
      return module[G.MODEL][G.CHILDREN].ticket?.[G.CACHE]
    default: return null
  }
}

export default target
