/* eslint-disable no-restricted-exports */
import addPartToCart from 'app/_shared/events/cart/addPart'

const cartEvents = {
  addPartToCart,
}

export {
  cartEvents as default,
  addPartToCart,
}
