/* global G */
import { pipe } from 'lib/util'
import { modelComposition } from 'trait/composition'
import { usesGetterSetter } from 'trait/uses'
import toRef from 'model/_shared/transformer/toRef'
import dataToExistingRef from 'model/_shared/transformer/dataToExistingRef'
import toData from 'model/_shared/transformer/toData'
import withGetterSetter from 'trait/with/GetterSetter'
import fromTimestamp from 'model/_shared/decorator/fromTimestamp'
import toNullable from 'model/_shared/transformer/toNullable'

const descriptor = 'model::cart'

export default pipe(
  modelComposition(descriptor),
  usesGetterSetter(G.TRANSFORMER, {
    // value
    comment: toNullable('comment'),
    recipientData: toData('recipientData', 'recipientData', true),
    purchaserData: toData('purchaserData', 'purchaserData', true),
    // refs
    submitter: toRef('submitter'),
    purchaser: toRef('purchaser'),
    recipient: toRef('recipient'),
    article: dataToExistingRef('article'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    submitTimestamp: fromTimestamp('long', 'submitTimestamp'),
  }),
)
