/* eslint-disable no-unused-vars */
/* global G */
import { curry, getFirstItem } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { actionWithHooks } from 'lib/sequence/module/action'
import { create } from 'app/_shared/events/attachment'

/**
 * Reads the positions from the current cart in {@param module[G.MODEL]}.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 * @returns {Promise<*|*[]>}
 */
const getViewedCartPositions = async (module, component, event) => {
  const model = module[G.MODEL]
  const { positions } = model[G.CHILDREN]
  const positionsCache = positions[G.CACHE]

  return positionsCache?.length
    ? positionsCache.map(x => ({ key: x?.key || x.name, value: { ...x } }))
    : []
}

export default curry(getViewedCartPositions)
