/* global G */
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { disable, enable } from 'lib/sequence/component/state/disabled'

/**
 * Cart Details step action logic
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const { btnCheckout } = sequenceComponentFind(component)

  const { cart } = component[G.STATE]

  const positions = cart?.value?.positions || []

  // Disabling checkout button if we have no positions
  positions?.length
    ? enable(btnCheckout)
    : disable(btnCheckout)

  return args
}
