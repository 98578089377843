/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { undo } from 'app/_shared/events/attachment'
import { setDetailUndoKey } from 'app/ticket/action/detail'
import find from 'lib/sequence/component/children/find'
import { reset } from 'lib/sequence/component/state/value'

/**
 * Event Handler Cancel Note
 *
 * Hides the note form.
 *
 * @param {object} module     the current module composition object
 * @param {object} component  the current action's main component
 * @param {object} event      the event object that triggered this handler
 * @return {Promise<void>}
 */

const cancelNote = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { notes } = asObject(actionComponent[G.CHILDREN])
  const { addNoteBtn, form, spentTime } = find(notes)

  show(addNoteBtn)
  hide(form)
  reset(spentTime)

  undo(module, component, event)
  setDetailUndoKey(module, 'note', false)

  await module[G.ADAPTER][G.UI].update(module)
}

export default curry(cancelNote)
