/* eslint-disable no-unused-vars */
/* global G */
import { asyncpipe, curry, deleteKey, setKey } from 'lib/util'
import search from 'app/_shared/events/search'
import sequenceComponentState from 'lib/sequence/component/state'
import { action, toggleSections, translator } from 'app/ticket/event/form/checkAccount'

const {
  set: setError,
  unset: unsetError,
} = sequenceComponentState('error')

const {
  set: setHelperText,
  unset: unsetHelperText,
} = sequenceComponentState('helperText')

/**
 * Updates {@param component}'s state to display a text if {@param requestNumber} and
 * {@param foundRequest} are truthy.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Gaia.Component} component    the component that triggered the event
 * @param {string} requestNumber        the number the user has searched by
 * @param {object} foundTicket          the found ticket object
 */
const setFieldState = curry(async (module, component, [requestNumber, foundTicket]) => {
  const translate = translator(module, 'ticket')
  unsetHelperText(component)
  unsetError(component)

  if (requestNumber) {
    foundTicket
      ? setHelperText(component, await translate({
        _key: 'label.requestFound',
        defaultValue: 'Request found',
      }))
      : setHelperText(component, await translate({
        _key: 'label.requestNotFound',
        defaultValue: 'Request not found',
      }))
      && setError(component)
  }

  return [requestNumber, foundTicket]
})

/**
 * Stores the possible {@param foundTicket} key as the model ref and copies its
 * requesterContactData.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Gaia.Component} component    the component that triggered the event
 * @param {string} requestNumber        the number the user has searched by
 * @param {object} foundTicket          the found ticket object
 */
const setRequest = curry(async (module, component, [requestNumber, foundTicket]) => {
  const model = module[G.MODEL]
  const modelState = model[G.STATE]

  deleteKey(G.ERROR, modelState)
  deleteKey(G.REF, modelState)
  deleteKey(G.NOTE, modelState)

  foundTicket
    ? setKey(foundTicket.key, G.REF, modelState)
      && setKey(true, G.NOTE, modelState)
    : requestNumber
      && setKey(true, G.ERROR, modelState)

  return [requestNumber, foundTicket]
})

/**
 * Attempts to obtain the request whose number is to be found in {@param event.detail.value}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Gaia.Component} component    the component that triggered the event
 * @param {Gaia.PlatformEvent} event    the event object
 */
const searchRequest = curry(async (module, component, event) => {
  const number = event.detail.value
  const filter = { number }
  const options = { filter, type: 'ticket' }
  const result = number ? await search(null, module, component, options) : []
  const [foundTicket] = result
  return [number, foundTicket]
})

/**
 * Check Request Number Event Handler
 *
 * Checks whether {@param event.detail.value} contains the number of an existing request. If a
 * request is found, stores its key in the model's state, hides the request section, shows the note
 * section and displays a message below the request number field. Otherwise, shows the note section
 * and hides the request section and the message below the request field.
 *.
 * @type {Gaia.AppModule.EventHandler}.
 */
export default curry(async (module, component, event) => asyncpipe(
  searchRequest(module, component),
  setRequest(module, component),
  setFieldState(module, component),
  toggleSections(module, component),
  action(module, component),
)(event))
