/* global G */
import { curry, setKey } from 'lib/util'

/**
 * Persists value in corresponding component's state, based on checked attribute
 *
 * Since there is no need to refresh the ui, it is handled locally.
 *
 * ATTENTION: the event cannot be stopped nor prevented, due to animation sequences in MUI(web)
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent} event - event
 */
const persistCheck = async (module, component, event) => {
  // cannot prevent nor stop, due to mui animation
  // event.preventDefault()
  // event.stopPropagation()
  const state = component[G.STATE]
  const { checked, value } = event.detail || {}
  state.value = checked && value
  state.checked = checked

  setKey(true, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
}

export default curry(persistCheck)
