/* global G */

import { asyncPipeSpread, isNum } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { isAction } from 'app/_shared/action/util'

/**
 * Sets the module state based on errors in the model. It will pass the result as
 * {@code validationError} down the chain so that following function can skip their functionality
 * if an error is present.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setModuleState = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const validationError = model[G.STATE][G.ERROR]
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = !!validationError

  return [validationError, children, ...args]
}

/**
 * Sets a default status in case there isn't one set already (by IA for example) when we are
 * creating a person.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {(function(*, *, ...[*]): Promise<*[]>)|*}
 */
const setStatusOnCreate = module => async (validationError, children, ...args) => {
  // Skip this function if we have a validation error
  if (validationError) return args

  // model
  const model = module[G.MODEL]

  if (isAction(module, 'newContact')) {
    // Defaulting to 50 if no status is set (0 is a valid status as well!)
    const statusData = model[G.DATA]?.status
    model[G.DATA].status = !isNum(statusData) ? 50 : statusData
  }

  return args
}

// export default submit
export default module => component => async (...args) => asyncPipeSpread(
  setModuleState(module),
  setStatusOnCreate(module),
)(sequenceComponentFind(component), ...args)
