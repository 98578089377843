/* eslint-disable no-unused-expressions */
/* global G */
import { curry } from '@gaia/util'

const descriptor = 'account::transformer::toPasswordGenerated'

/**
 * To Password Generated Transformer
 *
 * sets {@code passwordGenerated} to {@code false} if:
 * - a password has been set
 * - passwordGenerated's {@code G.DATA} has been set to {@code false} by action logic
 * otherwise deletes the property
 *
 * @type {(function(...[*]): (*))|*}
 */
const fn = curry((key, obj, data) => {
  console.warn(descriptor)

  const _data = data

  try {
    const item = obj[G.CHILDREN][key]
    const { password } = obj[G.CHILDREN]
    const itemCache = item[G.CACHE]
    const { type } = item[G.PROPS]

    if (password[G.DATA].value && itemCache === true) {
      _data[type][key] = false
    } else {
      delete _data[type][key]
    }
  } catch (e) {
    console.error(descriptor, e)
  }

  return _data
})

export default fn
