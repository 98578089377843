import { curry, debounce } from 'lib/util'

/**
 * Debounce event handler.
 *
 * Executes {@param eventHandler} after a {@param delay} time has passed. If the function is
 * executed again before {@param delay} expires, the timer is restarted.
 *
 * @param {function} eventHandler         the function to call after the {@param delay} time expires
 * @param {number} delay                  the number of milliseconds to wait before
 *                                        {@param eventHandler} is executed, if the function isn't
 *                                        called again
 * @return {Gaia.AppModule.EventHandler}
 */
export default (eventHandler, delay) => curry((module, component) => {
  let action
  return async (event) => {
    action = action || debounce(async e => eventHandler(module, component, e), delay)
    action(event)
  }
})
