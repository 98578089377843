/* global G */
import { curry } from 'lib/util'

/**
 * Close Dialog Event Handler
 *
 * It executes existing dialog action, in order to update ui
 * It redirects to specific action, in order to set session state.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component} component - ui component
 * @param {Event} event - event
 * @return {Promise<void>} void
 */
const closeDialog = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()
  // todo: prolly should refactor, if this closeDialog() should be reusable,
  //  ideally use session's state G.PREV values
  await module[G.ADAPTER][G.ROUTER].action(module[G.ACTIONS].profile._name)
}

export default curry(closeDialog)
