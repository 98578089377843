/* eslint-disable no-param-reassign */
/* global G */
import { curry } from 'lib/util'
import session from 'app/_shared/session'

/**
 * Callback for adding the message to the state of the list.
 *
 * @callback addItem
 * @param {Object[]} prevState
 * @param {Object} fetchedMessage
 */

/** @type {addItem} */
const addItem = (prevState, fetchedMessage) => {
  const newState = [...prevState]
  const existingMessage = prevState.find(
    message => message.refs.thread[0] === fetchedMessage.refs.thread[0],
  )
  if (existingMessage) {
    newState.splice(newState.indexOf(existingMessage), 1)
    newState.unshift({
      ...fetchedMessage,
      value: {
        ...fetchedMessage.value,
        unreadMessages: existingMessage.value.unreadMessages + 1,
      },
    })

    return newState
  }

  newState.unshift({
    ...fetchedMessage,
    value: { ...fetchedMessage.value, unreadMessages: 0 },
  })

  return newState
}

/**
 * Get the new message and return it alongside a callback handling the state change.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event that triggered this handler
 * @returns {Promise<{addItem: addItem, item: *}|{addItem: addItem, item: null}|{}>}
 */
const addOrUpdateMessage = async (module, component, event) => {
  const obj = module[G.MODEL]
  const api = 'message'
  const { version } = obj[G.PROPS]
  const messageId = event.id
  const url = `/api/v${version}/${api}/${messageId}`
  const user = session(module).user.key({ wrap: false })

  try {
    const newMessage = await obj[G.ADAPTER][G.HTTP].get({ url })

    // Only return the item, if it's not our own message.
    return newMessage.refs?.submitter?.[0]?.key !== user
      ? { item: newMessage, addItem }
      : { item: null, addItem }
  } catch (e) {
    console.error(e)
  }

  return {}
}
export default curry(addOrUpdateMessage)
