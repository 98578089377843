/* global G */
import { curry } from 'lib/util'
import { redirect } from 'app/_shared/events'
import { jump } from 'app/_shared/events/step'

/**
 * Redirects to the component's route and jumps to the step with index {@param index}.
 *
 * @param {number} index                the index of the step to jump to
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const redirectAndJumpTo = async (index, module, component, event) => {
  await redirect(module, component, event)
  await jump(module, component, { index })
  module[G.ADAPTER][G.UI].update(module)
}

export default curry(redirectAndJumpTo)
