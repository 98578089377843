import { curry } from 'lib/util'
import search from 'app/_shared/events/search'
import searchWith from 'app/_shared/events/search/with'

/**
 * Fetching users that can be mentioned.
 *
 * @param {Gaia.AppModule.Spec} module    app module
 * @param {Gaia.Component.Spec} component action component
 * @param {Event} event                   event
 * @return {Promise<*>}
 */
const user = async (module, component, event) => {
  const query = await searchWith(['query'], module, component, event)
  const result = await search(null, module, component, query)

  return result
    .map(mentionableUser => mentionableUser.refs.person[0])
    .map((person) => {
      const email = person.value.contactChannels.find(channel => channel.type === 'email')?.value

      return {
        key: person.key,
        value: email,
        display: person.value?.name || email,
        // TODO: Implement handling for disabled property
        disabled: false,
      }
    })
}

export default curry(user)
