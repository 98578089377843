/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import map from 'lib/sequence/model/api/map'
import { show, hide, hidden } from 'lib/sequence/component/state/hidden'
import { get } from 'lib/sequence/component/state/value'
import { asyncPipeSpread, asyncPipeSpreadIf, bulk } from 'lib/util'
import { CONFIRM } from 'app/_shared/events/setStepTab'
import find from 'lib/sequence/component/children/find'
import sequenceComponentState from 'lib/sequence/component/state'

const {
  get: activeTab,
} = sequenceComponentState('activeTab')

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Returns the IDs of the organisations that have to be offered to the user as suggestion, according
 * to the previously selected items.
 *
 * @param {Gaia.AppModule.Spec} module  the current ticket module
 * @param {Gaia.Model.Spec} model       the current ticket model
 * @returns {string[]}        an array containing the IDs of the objects to suggest
 */
const getSuggestionIds = (module, model) => {
  const { requesterContact, additionalParty, item } = model[G.CHILDREN]
  const { organisation } = requesterContact[G.CHILDREN]
  const { supportedBy } = additionalParty[G.CHILDREN]
  const { installedAt, serviceBy } = item[G.CHILDREN]

  const additionalPartyId = additionalParty[G.STATE][G.REF] // TODO: we need to solve #GAIA-911
  const supportedById = supportedBy[G.STATE]?.[G.REF]
  const installedAtId = installedAt[G.STATE]?.[G.REF]
  const serviceById = serviceBy[G.STATE]?.[G.REF]
  const organisationId = organisation[G.STATE]?.[G.REF]

  return [supportedById, installedAtId, serviceById, organisationId].reduce((acc, id) => {
    id && !acc.includes(id) && id !== additionalPartyId && acc.push(id)
    return acc
  }, [])
}

/**
 * Displays informative labels according to some model values.
 *
 * @param {Gaia.Model.Spec} model     the current ticket model
 * @param {Gaia.Model.Spec} attribute the current ticket's submodel
 * @param {Gaia.Component} labels     the labels component where the state labels are to be found
 */
export const displayLabels = (model, attribute, labels) => {
  const { addToMasterData, updateMasterData, notInMasterData } = asObject(labels[G.CHILDREN])

  // if the model has an id (we are editing it) and its attribute doesn't, we inform the user
  model[G.STATE][G.REF] && !attribute[G.STATE][G.REF]
    ? show(notInMasterData)
    : hide(notInMasterData)
  // if the attribute is to be stored in the database, we show a label to inform the user about the
  // pending (insert or update) operation
  if (attribute[G.STATE][G.BULK]) {
    // As, in some cases, we are setting ids client-side, checking whether the attribute has an id
    // doesn't mean it already exists in the database (so that we can display the corresponding
    // label), but we can use _rev for that
    attribute[G.CACHE]._rev
      ? hide(addToMasterData) && show(updateMasterData)
      : hide(updateMasterData) && show(addToMasterData)
  } else {
    hide(addToMasterData)
    hide(updateMasterData)
  }
}

/**
 * Toggles the display of the (organisations, persons and devices) lists of search results or the
 * list of suggestions depending on whether the search field has any value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayLists = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { searchField, organisations, persons, devices, /* tickets, */suggestions } = components

  if (get(searchField)) {
    hide(suggestions)
    bulkShow(organisations, persons, devices /* , tickets */)
  } else {
    const ids = getSuggestionIds(module, model)
    suggestions[G.STATE][G.REF] = ids
    ids.length ? show(suggestions) : hide(suggestions)
    bulkHide(organisations, persons, devices /* , tickets */)
  }

  return [components, ...args]
}

/**
 * If the model's requesterOrg attribute has a cache value, marks the step as completed and sets the
 * attribute's name and address to be shown as the step title and subtitle, otherwise sets the step
 * as non-completed and non-confirmed and clears its title and subtitle.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setStepState = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg } = model[G.CHILDREN]
  const { component: step } = components
  const stepState = step[G.STATE]

  if (requesterOrg[G.CACHE]) {
    // setting step as complete
    stepState.completed = true
    // filling step's title and subtitle with model data
    stepState.title = requesterOrg[G.CHILDREN].name[G.CACHE]
    stepState.subtitle = requesterOrg[G.CHILDREN].address[G.CHILDREN].summary[G.CACHE]
  } else {
    // setting step as incomplete and unconfirmed
    stepState.completed = false
    stepState.confirmed = false
    // clearing step's title and subtitle
    stepState.title = ''
    stepState.subtitle = ''
  }

  return [components, ...args]
}

/**
 * Toggles the display of informative labels according to the current model values.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayOrganisationLabels = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg } = model[G.CHILDREN]
  const { labels } = components
  const { waitingForApproval, notInMasterData, blocked } = asObject(labels[G.CHILDREN])

  displayLabels(model, requesterOrg, labels);

  (!hidden(waitingForApproval)
    || !hidden(blocked))
  && hide(notInMasterData)

  return [components, ...args]
}

/**
 * Maps the current model's requesterOrg attribute to the step's confirm view.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapRequesterOrg = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg } = model[G.CHILDREN]
  const { confirm } = components
  const { organisation } = find(confirm)

  // mapping selected model to the view
  map(requesterOrg)(organisation)

  return [components, ...args]
}

/**
 * Organisation identification step.
 *
 * If no organisation is selected, displays a list to search among them and a button to add a new
 * one. Otherwise, sets the step as complete and displays the selected organisation's data together
 * with buttons to edit it, to clear the confirm view and to go to the next step.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => await asyncPipeSpread(
  asyncPipeSpreadIf(() => activeTab(component) === CONFIRM)(
    mapRequesterOrg(module),
    displayOrganisationLabels(module),
  ),
  asyncPipeSpreadIf(() => activeTab(component) !== CONFIRM)(
    displayLists(module),
  ),
  setStepState(module),
)(find(component), ...args)
