/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread, getFirstItem } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import sequenceComponentState from 'lib/sequence/component/state'
import { set } from 'lib/sequence/component/state/value'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import listStatus from 'app/_shared/events/collection/listStatus'

const {
  unset: clearError,
} = sequenceComponentState('error')

const {
  unset: clearHelperText,
} = sequenceComponentState('helperText')

const clear = component => clearError(component) && clearHelperText(component)

/**
 * Presets the {@code installedAt} field
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const presetInstalledAt = module => async (children, ...args) => {
  const { organisation } = args[0] || {}
  const { installedAt: installedAtField } = children

  organisation && set(installedAtField, [organisation[G.CACHE]])

  return [children, ...args]
}

/**
 * Presets the {@code name} field
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const presetName = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const { name } = model[G.CACHE]?.value || {}

  // component
  const { name: nameField } = children

  clear(nameField)
  if (name) {
    disable(nameField)
    set(nameField, name)
  } else {
    set(nameField, null)
    enable(nameField)
  }

  return [children, ...args]
}

/**
 * Presets the {@code status} field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const presetStatus = module => component => async (children, ...args) => {
  const { status } = children

  const activeStatus = getFirstItem(listStatus(module, component, { detail: { key: 50 } }))

  set(status, activeStatus)

  return [children, ...args]
}
/**
 * ServiceItem action new
 *
 * Assigns the equipment found by searchSerial (if any) to the current model and the name of its
 * product as the Service Item's name.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  presetInstalledAt(module),
  presetName(module),
  presetStatus(module)(component),
)(sequenceComponentFind(component), ...args)
