import account from 'model/_shared/validator/userExists'

export const validators = {
  account,
}

export {
  validators as default,
  account,
}
