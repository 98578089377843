/* global G */
import { curry } from 'lib/util'
import prefChannels from 'model/_shared/collection/ticket/prefChannel'

/**
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent|string} eventOrKey
 * @return {Array} countries
 */
const listTicketTypes = (module, component, eventOrKey) => {
  const key = eventOrKey?.detail?.key || eventOrKey
  const scope = key ? prefChannels.filter(item => item.key === key) : prefChannels

  return scope.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `prefChannel.${item.key}`,
      {
        ns: 'ticket',
        _key: `prefChannel.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }))
}

export default curry(listTicketTypes)
