/* global G */
/* eslint-disable object-curly-newline,arrow-body-style,no-unused-vars */
import stepper from 'app/_shared/action/stepper'
import { asyncPipeSpread, setKey } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import seqModelRead from 'lib/sequence/model/api/read'
import sequenceComponentState from 'lib/sequence/component/state'
import { CONFIRM } from 'app/_shared/events/setStepTab'
import device from './create/device'
import location from './create/location'
import information from './create/information'
import partner from './create/partner'
import finish from './create/finish'

const {
  set: setTab,
} = sequenceComponentState('activeTab')

const setConfirmTab = step => setTab(step, CONFIRM)

/**
 * Presets the item step if available.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export const presetLocation = module => component => async (children, ...args) => {
  const { organisation } = args[0] || {}

  if (organisation) {
    const model = module[G.MODEL]
    model[G.CHILDREN].installedAt[G.CACHE] = organisation[G.CACHE]
    model[G.CHILDREN].installedAt[G.STATE][G.REF] = organisation[G.STATE][G.REF]
    await seqModelRead(model[G.CHILDREN].installedAt)(component)

    setKey(organisation, 'installedAt', model[G.STATE])

    const { location: locationStep } = children
    setConfirmTab(locationStep)
  }

  return [children, ...args]
}
/**
 * Gathers the actions for the stepper.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
const initActions = module => component => async (...args) => [
  { device, location, information, partner, finish }, args,
]

/**
 * Initializes the stepper.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
const initStepper = module => component => async (actions, ...args) => {
  return await stepper(module)(component)({ actions, ...args })
}

export default module => component => async (...args) => asyncPipeSpread(
  presetLocation(module)(component),
  initActions(module)(component),
  initStepper(module)(component),
)(sequenceComponentFind(component), ...args)
