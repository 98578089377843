/* global G */
import { curry } from 'lib/util'
import { disable, enable } from 'lib/sequence/component/state/disabled'

/**
 * Toggles the {@code isEmpty} validator of the {@code email} field depending
 * on whether it has a value or not.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<void>}
 */
const toggleEmailValidation = async (module, component, event) => {
  const { checked } = event?.detail || event || {}
  const { email } = module[G.MODEL][G.CHILDREN].contactChannels[G.CHILDREN]
  const notEmptyValidator = email[G.VALIDATOR].find(validator => validator._name === 'notEmpty')

  checked
    ? enable(notEmptyValidator)
    : disable(notEmptyValidator)

  await module[G.ADAPTER][G.UI].update(module)
}

export default curry(toggleEmailValidation)
