/* global G */
import { curry } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'

/**
 * Email Link Event Handler
 *
 * This function handles an event related to an email link interaction.
 *
 * @param {Gaia.AppModule.Spec} module - The current module composition object.
 * @param {Gaia.Component.Spec} component - The main component associated with the current action.
 * @param {Event} event - The event object that triggered this handler.
 *
 * @returns {Object} An object containing extracted data:
 *   - recipient: The recipient email address.
 *   - mailSubject: The email subject.
 *   - mailBody: The email body content.
 */

const emailLink = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { recipient, subject, body } = find(actionComponent)
  const recipientValue = get(recipient)
  const subjectValue = get(subject)
  const bodyValue = get(body)
  return {
    recipient: recipientValue,
    mailSubject: subjectValue,
    mailBody: bodyValue,
  }
}

export default curry(emailLink)
