/* eslint-disable no-unused-vars */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Issue description step.
 *
 * Sets the step as completed if the description field contains text.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const stepState = component[G.STATE]
  const { description } = asObject(component[G.CHILDREN])
  const { text } = asObject(description[G.CHILDREN])
  const textState = text[G.STATE]
  // setting step as confirmed according to the value of the description field
  stepState.completed = !!textState.value
  stepState.confirmed = stepState.completed
  stepState.title = textState.value
  return args
}
