/* global G */
import { curry } from 'lib/util'
import { actionFn } from 'lib/sequence/module/action'
import { persistChange, persistCheck, persistOption, persistOptions } from 'app/_shared/events'

/**
 * Event Handler Persist Store
 *
 * It executes persistCheck event handler,
 * followed by executing the logic of the current action
 * again (index, edit, create, ...) and finally
 * updates the UI
 *
 * It used in cases, where you want to reapply changes to UI, based on checkbox state.
 *
 * Can it replace {@link persistChangeAndAction} ?
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<void>}
 */
const persistStore = curry(async (handler, module, component, event) => {
  try {
    await handler(module, component, event)
    await actionFn(module[G.STATE][G.ACTION])()
    module[G.ADAPTER][G.UI].update(module)
  } catch (e) {
    console.error(e)
  }
})

/**
 * Executes {@link persistStore} with {@link persistOptions} as a handler
 *
 * @type {*|(function(...[*]): (*))}
 */
export const persistOptionsStore = persistStore(persistOptions)

/**
 * Executes {@link persistStore} with {@link persistOption} as a handler
 *
 * @type {*|(function(...[*]): (*))}
 */
export const persistOptionStore = persistStore(persistOption)

/**
 * Executes {@link persistStore} with {@link persistChange} as a handler
 *
 * @type {*|(function(...[*]): (*))}
 */
export const persistChangeStore = persistStore(persistChange)

export default persistStore(persistCheck)
