/* global G */
/* eslint-disable */
import { curry } from 'lib/util'

/**
 * Callback for removing the item from the state of the list.
 *
 * @callback removeItem
 * @param {Object[]} prevState
 * @param {Object} fetchedTicket
 */

/** @type {removeItem} */
export const removeItem = (prevState, removedItem) => {
  const newState = [...prevState]
  const existingRequest = prevState.find(request => request.key === removedItem.key)

  if (existingRequest) {
    newState.splice(newState.indexOf(existingRequest), 1);
  }

  return newState
}

/**
 * Callback for adding the ticket to the state
 * of the list.
 *
 * @callback addItem
 * @param {object[]} prevState
 * @param {object} fetchedTicket
 */

/** @type {addItem} */
const addItem = (prevState, fetchedRequest) => {
  const newState = removeItem(prevState, fetchedRequest)

  newState.unshift(fetchedRequest)

  return newState
};

/**
 * Get the new request and return it alongside
 * a callback handling the state change.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param event                         event containing the new request id
 * @returns {Promise<*>}
 */
// eslint-disable-next-line no-unused-vars
const updateRequestList = async (module, component, event) => {
  const obj = module[G.MODEL]
  const { api, version } = obj[G.PROPS]
  const requestId = event.id
  const url = `/api/v${version}/${api}/${requestId}/verbose`

  try {
    const fetchedRequest = await obj[G.ADAPTER][G.HTTP].get({ url })

    return { item: fetchedRequest, addItem }

  } catch (e) {
    console.error(e)
  }
  return {}
}
export default curry(updateRequestList)
