/* eslint-disable no-unused-vars */
import { curry } from 'lib/util'
import toggleEmailValidator from 'app/_shared/hook/model/toggleEmailValidator'

/**
 * Checks whether the currently introduced email corresponds to the user of the person being edited.
 * If it is, disables the accountExists validator, otherwise enables it.
 *
 * @param modelName
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
const toggleContactEmailValidator = curry(async (module, component, event) => {
  await toggleEmailValidator('requesterContact')(module)({})
})

export default toggleContactEmailValidator
