/* eslint-disable no-fallthrough */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import validate from 'lib/sequence/model/validate'
import validateStepper from 'app/_shared/component/stepper/validate'
import session from 'app/_shared/session'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { checked } from 'lib/sequence/component/state/checked'

/**
 * Sets type, parent and status for new locations.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 */
const composeLocation = (module) => {
  const model = module[G.MODEL]
  const { installedAt } = model[G.CHILDREN]
  if (!installedAt[G.CACHE]._rev) { // Only if installedAt doesn't already exist
    const { organisation } = session(module)
    installedAt[G.DATA].status = 50
    installedAt[G.DATA].parent = organisation.key({ wrap: true })
    installedAt[G.CHILDREN].type[G.DATA].value = 'customer'
  }
}

/**
 * Sets the name and status of the service item.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 */
const composeServiceItem = (module) => {
  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { maintainedBy } = model[G.CHILDREN]
  model[G.DATA].status = 50

  const { person } = session(module)
  const { addToMaintainedDevices } = sequenceComponentFind(actionComponent)
  maintainedBy[G.STATE] = {}
  checked(addToMaintainedDevices) && (maintainedBy[G.STATE][G.REF] = person.key())
}

/**
 * Validates all the stepper (except for the sub-models) with the ticket model. Validate
 * means also that all data will be saved inside the model, in case it is valid.
 *
 * @param {Gaia.Component.Spec} module  the current module composition object
 * @returns {Promise<boolean>}          {@code true} if all data is valid or {@code false} otherwise
 */
const validateServiceItem = async (module) => {
  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const { information } = asObject(stepper[G.CHILDREN])
  const { section } = asObject(information[G.CHILDREN])
  const { form } = asObject(section[G.CHILDREN])
  const { additionalInformation } = asObject(form[G.CHILDREN])

  await validate(model)(additionalInformation)
  return !model[G.STATE][G.ERROR]
}

/**
 * ServiceItem wizard submit
 *
 * Checks whether the currently inserted service item data is valid and all required steps are
 * completed. If that's the case, finishes filling the service item with data that can be deduced.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
const submitWizard = async (module) => {
  const model = module[G.MODEL]
  const moduleState = module[G.STATE]

  const serviceItemValid = await validateServiceItem(module)
  const stepsValid = validateStepper(module)
  const valid = serviceItemValid && stepsValid

  model[G.STATE][G.ERROR] = !valid
  moduleState[G.ERROR] = !valid

  valid && composeServiceItem(module)
  valid && composeLocation(module)
}

/**
 * ServiceItem action submit
 *
 * For each service item created/edited, assigns it active (50) status and the product contained
 * inside its equipment. If it has no equipment assigned, it attempts to obtain a product with the
 * same name as the service item and assigns it.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
const action = module => () => async (...args) => {
  const model = module[G.MODEL]
  const moduleState = module[G.STATE]
  const validationError = model[G.STATE][G.ERROR]
  moduleState[G.ERROR] = !!validationError

  !validationError && await submitWizard(module)

  return args
}

export default action
