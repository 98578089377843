/* eslint-disable no-unused-expressions */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import { reset, set } from 'lib/sequence/component/state/value'
import { CONFIRM, SEARCH } from 'app/_shared/events/setStepTab'
import map from 'lib/sequence/model/api/map'

/**
 * Device location step action.
 *
 * Handles the display and status of the step's elements.
 *
 * It lets the user select a location from a list or create a new one by filling a form.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const { itemInstalledAt } = model[G.CHILDREN]
  const stepState = component[G.STATE]
  const { search, confirm } = asObject(component[G.CHILDREN])
  const { searchField, list } = asObject(search[G.CHILDREN])
  const { location } = asObject(confirm[G.CHILDREN])
  // if there is a location selected
  if (itemInstalledAt[G.CACHE]) {
    const { name, address } = itemInstalledAt[G.CHILDREN]
    // clearing search field
    reset(searchField)
    // mapping selected model to the view
    map(itemInstalledAt)(location)
    // set step as complete
    stepState.completed = true
    // fill step's title and subtitle with model data
    stepState.title = name[G.CACHE]
    stepState.subtitle = address[G.CHILDREN].summary[G.CACHE]
    stepState.activeTab = CONFIRM
  } else {
    // set step as incomplete and unconfirmed
    stepState.completed = false
    stepState.confirmed = false
    // clear step's title and subtitle
    stepState.title = ''
    stepState.subtitle = ''
    // hiding or showing the list depending on whether the search field has any value
    // get(searchField) ? show(list) : hide(list)
    stepState.activeTab = SEARCH
  }

  return args
}
