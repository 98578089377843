/**
 * Ticket Status Reason Collection.
 *
 * Contains static list of available ticket's message types, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 */
export default [
  {
    key: 0,
    value: '',
    color: 'signal',
  },
  {
    key: 10,
    value: 'Submitted',
    color: 'signal',
  },
  {
    key: 12,
    value: 'To be qualified',
    color: 'signal',
  },
  {
    key: 31,
    value: 'Forwarded',
    color: 'signal',
  },
  {
    key: 32,
    value: 'Assigned',
    color: '',
  },
  {
    key: 33,
    value: 'Reassigned',
    color: '',
  },
  {
    key: 35,
    value: 'Postpone date exceeded',
    color: 'signal',
  },
  {
    key: 36,
    value: 'Customer reply',
    color: 'signal',
  },
  {
    key: 37,
    value: 'Third-Party reply',
    color: 'signal',
  },
  {
    key: 38,
    value: 'Solution rejected',
    color: 'signal',
  },
  {
    key: 39,
    value: 'Request reopened',
    color: 'signal',
  },
  {
    key: 50,
    value: 'In Progress',
    color: '',
  },
  {
    key: 61,
    value: 'Postponed',
    color: 'inactive',
  },
  {
    key: 62,
    value: 'Waiting for customer',
    color: 'inactive',
  },
  {
    key: 63,
    value: 'Waiting for third-party',
    color: 'inactive',
  },
  {
    key: 70,
    value: 'Solution proposed',
    color: '',
  },
  {
    key: 80,
    value: 'Resolved',
    color: 'success',
  },
  {
    key: 85,
    value: 'Expired',
    color: 'inactive',
  },
  {
    key: 89,
    value: 'Cancelled',
    color: 'error',
  },
]
