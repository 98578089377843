/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import session from 'app/_shared/session'

/**
 * Reads the users salesOrganisations and returns them.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 * @returns {Promise<*|*[]>}
 */
const getUserSalesOrganisations = async (module, component, event) => {
  const { user } = session(module)
  // TODO: This is specific to RATIONAL. We need to implement a coherent interface between server
  //  and client and abstract it
  const salesOrganisations = user.ref()?.value?.rationalUser.user.combinations || {}

  return salesOrganisations.map((salesOrganisation, i) => ({
    key: i,
    value: Object.values(salesOrganisation).join(', '),
  }))
}

export default curry(getUserSalesOrganisations)
