import noop from '@gaia/util/noop/action'
import submitContact from '@app/team/action/submitContact'
import submit from '@app/team/action/submit'
import detail from '@app/team/action/detail'
import colleague from '@app/_shared/action/invite/colleague'

export default {
  index: noop,
  new: noop,
  edit: noop,
  detail,
  persons: noop,
  submit,
  inviteContact: colleague,
  submitContact,
  remove: noop,
}
