/* eslint-disable no-bitwise */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'

const ERROR = 'Some error'

/**
 * Generate event.
 *
 * Randomly assigns error or disabled states to the fields inside the container component and
 * refreshes the view.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - event
 * @returns {Promise<void>}
 */
const modify = async (module, component, event) => {
  const processStartTime = performance.now()
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { container } = asObject(actionComponent[G.CHILDREN])
  const count = container[G.CHILDREN].length

  console.log(`Setting random states to ${count} fields:`)
  container[G.CHILDREN].forEach((field) => {
    const fieldState = field[G.STATE]

    fieldState.error = false
    fieldState.disabled = false
    fieldState.helperText = ''
    switch (3 * Math.random() | 0) {
      case 0:
        fieldState.error = true
        fieldState.helperText = ERROR
        break
      case 1:
        fieldState.disabled = true
        break
      default:
    }
  })

  console.log(`· Processing time: ${performance.now() - processStartTime}ms`)
  const renderStartTime = performance.now()
  module[G.ADAPTER][G.UI].update(module)
  console.log(`· Rendering time: ${performance.now() - renderStartTime}ms`)
}

export default curry(modify)
