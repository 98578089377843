import settings from '@tenant/settings'

/**
 * See documentation in {@link settings.drilldown.skippableDepths}
 * @type {[]|*[]}
 */
const skippableDepths = settings?.drilldown?.skippableDepths || []

/**
 * Helper function to find {@param node}'s highest parent.
 *
 * @param {Object} node         node to check
 * @param {string} nodeType     type of the target parent
 * @param {string} productType  product type of the target parent
 * @returns {*|null}
 * @private
 */
const _findParent = (node, { nodeType, productType }) => {
  if (node?.nodeType === nodeType && node?.productType === productType) {
    return node
  }

  if (node?.parent) {
    return _findParent(node.parent, { nodeType, productType })
  }

  return null
}

/**
 * Checks whether {@param node} has the correct depth compared to its parent defined in
 * {@link settings.drilldown.skippableDepths} and returns {@param node} if so. If not,
 * it returns {@code null}.
 *
 * @param {Object} node the node to check
 * @returns {*|null}
 */
export default (node) => {
  const hasCorrectDepth = skippableDepths.reduce((acc, key) => {
    const targetParent = _findParent(node, {
      nodeType: key.nodeType,
      productType: key.productType,
    })
    return targetParent ? key.depth : acc
  }, null)

  return node?.depth === hasCorrectDepth
    ? node
    : null
}
