/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from '@gaia/trait/uses'
import toNotify from 'model/team/transformer/toNotify'

const descriptor = 'model::team::notify'

export default pipe(
  modelComposition(descriptor),
  usesGetterSetter(G.TRANSFORMER, {
    team: toNotify('team'),
    members: toNotify('members'),
  }),
)
