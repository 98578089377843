/* global G */
import ui from 'lib/hook/ui/update'
import check from 'app/_shared/hook/check'
import action, { currentAction } from 'lib/hook/module/action'
import recall from 'app/_shared/hook/component/recall'
import recreate from 'app/_shared/hook/ui/recreate'
import search from 'app/_shared/hook/appbar/search'
import { confirmation } from 'lib/hook/transaction'

const _listHooks = {
  before: [
    recall,
    search,
  ],
  after: [
    ui,
  ],
}

const _submitFavoriteHooks = {
  before: [],
  after: [
    ui,
    check,
    confirmation('favorite', 'success'),
    action('successFavorite'),
  ],
}

const _successHooks = {
  before: [],
  after: [
    currentAction,
    recreate, // to update tab labels count
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _listHooks,
    submitFavorite: _submitFavoriteHooks,
    successFavorite: _successHooks,
  },
}

export default hooks
