/* eslint-disable no-unused-vars,no-param-reassign */
/* global G */
import { curry } from 'lib/util'
import { settings } from 'app/_shared/session'
import sublist from 'app/_shared/events/util/sublist'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'

/**
 * List Register Request Types event handler.
 *
 * Returns the list of ticket types to be used for the register section.
 * @type {Gaia.AppModule.EventHandler}.
 */
export default curry((module, component, event) => {
  const keys = settings.registerRequestTypes?.length ? settings.registerRequestTypes : null
  const includeBlank = settings.registerRequestTypesDropdown

  return sublist(keys, listTicketTypes, includeBlank)(module, component, event).map((type) => {
    delete type.icon
    return type
  })
})
