/* global G */
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

const descriptor = Symbol('hook::module::back').toString()

/**
 * Back Hook
 *
 * Configures the {@link AppBar}s onBack handler. {@code actionUi.back.component} is the
 * name of the component to call the handler with. If it's not given through the config,
 * the action component will be used.
 *
 * NOTE: This hook only makes sense on mobile platform atm.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): Promise<*[]>}
 */
const back = obj => async (...args) => {
  try {
    const actionUi = obj[G.STATE][G.ACTION][G.UI]
    if (actionUi?.back) {
      const handlerName = actionUi?.back?.events?.onClick || null
      const backEvents = obj[G.EVENTS].onBack
      const onBack = backEvents[Object.keys(backEvents)[handlerName || 0]] // Using first event
      const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]
      const { [actionUi?.back?.component]: targetComponent } = sequenceComponentFindProxy(actionComponent)

      if (!onBack) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(`${obj._name} ${descriptor} - Back handler for action ${actionUi.title} defined in config but non given in events`)
      }

      const onBackPartial = onBack(obj, targetComponent || actionComponent)
      actionUi.back = { ...actionUi.back, onBack: onBackPartial }
    }
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }

  return args
}

export default back
