/* global G */
import { curry } from 'lib/util'

/**
 * @typedef {Object} DialogOptions
 *
 * @property {Object} title                 the title object
 * @property {string} title.ns              the title namespace
 * @property {string} title.key             the title key
 * @property {string} title.defaultValue    the title default value
 * @property {boolean} [title.md]           whether to parse Markdown syntax
 * @property {Object} text                  the text object
 * @property {string} text.ns               the text namespace
 * @property {string} text.key              the text key
 * @property {string} text.defaultValue     the text default value
 * @property {boolean} [text.md]            whether to parse Markdown syntax
 * @property {boolean|Object} [ok]          whether to show the 'Ok' action
 * @property {string} [ok.ns]               the ok text namespace
 * @property {string} [ok.key]              the ok text key
 * @property {string} [ok.defaultValue]     the ok text defaultValue
 * @property {boolean|Object} [cancel]      whether to show the 'Cancel' action
 * @property {string} [cancel.ns]           the cancel text namespace
 * @property {string} [cancel.key]          the cancel text key
 * @property {string} [cancel.defaultValue] the cancel text defaultValue
 *
 */

/**
 * Default dialog options
 *
 * Will be used if the provided configuration doesn't have {@code title} and {@code text} set.
 *
 * @param {Gaia.Component.Spec} component   action component
 * @returns {DialogOptions}
 * @private
 */
const _defaultDialogConfig = component => ({
  title: {
    ns: 'common',
    key: 'dialog.error.generic.title',
    defaultValue: component[G.PROPS]?.label?.dialog?.title
        || 'Error',
  },
  text: {
    ns: 'common',
    key: 'dialog.error.generic.text',
    defaultValue: component[G.PROPS]?.label?.dialog?.title
        || 'An error has occurred, please try again later.',
  },
  ok: true,
  cancel: false,
})

/**
 * Default configuration for the dialog.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {DialogOptions} options           configuration used
 * @returns {Promise<{children: {}, text: *, title: *}>}
 * @private
 */
const _dialogConfig = async (module, component, options) => {
  const { title, text, ok = true, cancel = true } = options

  return {
    title: await module[G.ADAPTER][G.INTL].markdown(
      title.key,
      {
        ...title,
        ns: title.ns,
        _key: title.key,
        defaultValue: title.defaultValue,
        md: title.md,
      },
    ),
    text: await module[G.ADAPTER][G.INTL].markdown(
      text?.key,
      {
        ...text,
        ns: text.ns,
        _key: text.key,
        defaultValue: text.defaultValue,
        md: text.md,
      },
    ),
    children: {
      ...ok && {
        ok: {
          key: ok.key || 'ok',
          value: await module[G.ADAPTER][G.INTL]._t(
            ok.key || 'button.ok',
            {
              ...ok,
              ns: ok.ns || 'common',
              _key: ok.key || 'button.ok',
              defaultValue: ok.defaultValue || 'Ok',
            },
          ),
        },
      },
      ...cancel && {
        cancel: {
          key: cancel.key || 'cancel',
          value: await module[G.ADAPTER][G.INTL]._t(
            cancel.key || 'button.cancel',
            {
              ...cancel,
              ns: cancel.ns || 'common',
              _key: cancel.key || 'button.cancel',
              defaultValue: cancel.defaultValue || 'Cancel',
            },
          ),
        },
      },
    },
  }
}

/**
 * Returns a function that should be used to trigger the event showing the dialog. Will also check
 * if at least one option ('Ok or 'Cancel') is being present. If not, it will throw an {@link Error}
 * since this would mean the dialog is not dismissible.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {DialogOptions} options           configuration for the dialog
 * @returns {Promise<(function(*): void)|*>}
 * @private
 */
const _createDialog = async (module, component, options) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]
  const { ok = true, cancel = true } = options

  if (!ok && !cancel) throw new Error('The dialog needs at least one option')

  const dialogConfig = await _dialogConfig(module, component, options)

  return (actions) => {
    eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
      ...dialogConfig,
      ...actions,
    })
  }
}

/**
 * Non-Blocking Dialog Event Handler
 *
 * Will display a non-blocking dialog (meaning code execution will continue). It will display an
 * 'Ok' option only since having a choice doesn't make sense for a non-blocking dialog. It should
 * purely be used to inform the user about something. It can be configured through {@param event}
 * like this:
 *
 * @example
 * {
 *     title: {
 *         ns: 'ticket' (will default to common)
 *         key: 'path.to.translation' (will default to dialog.generic.error.title)
 *         defaultValue: '...', (optional)
 *     }
 *     text: {
 *         ns: 'ticket' (will default to common)
 *         key: 'path.to.translation' (will default to dialog.generic.error.title)
 *         defaultValue: '...', (optional)
 *     }
 * }
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {DialogOptions|Event} event       event or configuration
 * @param {Object} [event.detail]           specific configuration for the dialog
 * @returns {Promise<void>}
 */
const showDialog = async (module, component, event) => {
  const config = event?.detail || event || {}
  const options = (config?.text && config?.title && config)
      || _defaultDialogConfig(component)

  try {
    (await _createDialog(module, component, { ...options, cancel: false }))({ cancel: false })
  } catch (e) {
    console.error(e)
  }
}

/**
 * Blocking Dialog Event Handler
 *
 * Will display a blocking dialog (meaning code execution is halted until the user chooses an
 * option). By default, it will display a {@code Ok} and {@code Cancel} option, returning
 * {@code true} and {@code false} respectively. It can be configured through {@param event}
 * like this:
 *
 * @example
 * {
 *     ok: true|false,
 *     cancel: true|false,
 *     title: {
 *         ns: 'ticket' (will default to common)
 *         key: 'path.to.translation' (will default to dialog.generic.error.title)
 *         defaultValue: '...', (optional)
 *     }
 *     text: {
 *         ns: 'ticket' (will default to common)
 *         key: 'path.to.translation' (will default to dialog.generic.error.title)
 *         defaultValue: '...', (optional)
 *     }
 * }
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {DialogOptions|Event} event        event or configuration
 * @param {Object} [event.detail]           specific configuration for the dialog
 * @returns {Promise<unknown>}
 */
export const showBlockingDialog = async (module, component, event) => {
  const config = event?.detail || event || {}
  const options = (config?.text && config?.title && config)
      || _defaultDialogConfig(component)

  const { ok = true, cancel = true } = options

  try {
    const dialogEvent = await _createDialog(module, component, options)

    return await new Promise((resolve) => {
      dialogEvent({
        /**
           * We could allow for configurable custom event handlers, like
           * @example
           * {
           *     events: {
           *         onDialogConfirm: '...',
           *         onDialogCancel: '...'
           *     }
           * }
           *
           * But I don't think it's really necessary, because we can always check the return value
           * of this handler and then execute some logic accordingly.
           */
        ...ok && { okHandler: () => { resolve(true) } },
        ...cancel && { cancelHandler: () => { resolve(false) } },
      })
    })
  } catch (e) {
    console.error(e)
  }

  return undefined
}

export default curry(showDialog)
