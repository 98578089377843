/* global G */
import sequenceModuleLogin from 'lib/sequence/module/login'

/**
 * User Verify Action
 *
 * Verifies the validity of the token ({@code key})
 * and logs the user in.
 *
 * @param {Gaia.AppModule.Spec} module - application module
 * @return {function(*): function(...[*]=): *[]}
 */
const verify = module => () => async (...args) => {
  const model = module[G.MODEL]

  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const { version } = model[G.PROPS]
  const key = module[G.ADAPTER][G.SESSION][G.STATE][G.REF]

  const loginUrl = `/api/v${version}/public/tokenlogin/${key}`

  try {
    await httpAdapter.get({ url: loginUrl })
    await sequenceModuleLogin(module)()
  } catch (e) {
    console.error(e)
  }
  return args
}

export default verify
