/* global G */

import find from 'lib/sequence/component/children/find'

/**
 * Saves the initial value of the first element found with key {@param key} inside the current
 * action's state.
 *
 * @param {string} key        the key of the element from which to save its initial value
 * @param {boolean} isAction  whether the component is to be found in component actions
 * @returns {function(*): function(...[*]): *[]}
 */
const saveInitialState = (key, isAction) => obj => (...args) => {
  const action = obj[G.STATE][G.ACTION]
  const actionState = action[G.STATE]
  const actionComponent = action[G.COMPONENT]
  const target = isAction ? actionComponent[G.ACTIONS][0] : actionComponent
  const { [key]: element } = find(target)

  actionState[G.DATA] = {
    ...actionState[G.DATA],
    [key]: {
      ...element[G.PROPS],
      ...element[G.STATE],
    },
  }

  return args
}

export default saveInitialState
