import request from './request'
import ticket from './ticket'
import system from './system'

const all = [
  ...request,
  ...ticket,
  ...system,
]

const messageTypes = {
  request,
  ticket,
  system,
  all,
}

export {
  messageTypes as default,
  request,
  ticket,
  system,
  all,
}
