/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Validate Redirect Event Handler
 *
 * It executes module action validateLogin()
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - event
 * @return {Promise<void>} void
 */
const validateLogin = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()
  try {
    actionWithHooks(module[G.ACTIONS].validateLogin)(component[G.CONFIGURATION]?.route || {})
  } catch (e) {
    console.error(e)
  }
}

export default curry(validateLogin)
