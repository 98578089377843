/**
 * Internal Organisation Collection.
 *
 * Contains a list of internal organisations to be checked against in various parts of the
 * application. The list contains the actual organisation IDs. They will never change, and,
 * at least at the moment, there is no other way of checking against them (like a flag or
 * something similar).
 */
// TODO: Refactor this after we added a flag server side instead of needing to check of ID
export default [
  {
    key: 'ORGANISATION:RA', // parent for all unapproved orgs
    value: 'Requester Administration',
  },
  {
    key: 'ORGANISATION:BA', // parent for all blocked users
    value: 'Blocked Administration',
  },
  {
    key: 'ORGANISATION:IA', // parent for all internal users
    value: 'Internal Administration',
  },
]
