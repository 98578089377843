/* global G */
import { curry } from 'lib/util'
import { settings } from 'app/_shared/session'
import defaultSoftwarePrograms from 'model/_shared/collection/ticket/software'

/**
 * Get a list of available software programs.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component      the current action's main component
 * @param {CustomEvent} eventOrKey          event that triggered this handler
 * @return {Array} softwarePrograms
 */
// eslint-disable-next-line no-unused-vars
const listSoftwarePrograms = (module, component, eventOrKey) => {
  const key = eventOrKey?.detail?.key || eventOrKey
  const softwarePrograms = settings.softwarePrograms || defaultSoftwarePrograms
  const scope = key ? softwarePrograms.filter(item => item.key === key) : softwarePrograms

  return scope.map(item => (
    item.key !== 'other' ? item
      : {
        ...item,
        value: module[G.ADAPTER][G.INTL]._t(
          `ticket.type.${item.key}`,
          {
            ns: 'custom',
            _key: `ticket.type.${item.key}`,
            defaultValue: item.value,
          },
        ),
      }))
}

export default curry(listSoftwarePrograms)
