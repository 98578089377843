import noop from '@gaia/util/noop/action'

export default {
  home: noop,
  textInput: noop,
  buttons: noop,
  checkbox: noop,
  radioGroup: noop,
  select: noop,
  datePicker: noop,
  groupCheck: noop,
  textElements: noop,
  tabs: noop,
  list: noop,
  subscribedList: noop,
  widgets: noop,
  chip: noop,
  autocomplete: noop,
  qrCamera: noop,
  email: noop,
  bottomSheet: noop,
  bottomSheetContent: noop,
  stepper: noop,
}
