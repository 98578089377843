/* eslint-disable no-unused-vars */
/* global G */
import { curry, getFirstItem } from 'lib/util'

/**
 * Adds a part to the current cart.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component      the current action's main component
 * @param {PlatformEvent} event                     the event object that triggered this handler
 * @returns {Promise<*>}
 */
const addPartToCart = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const partCache = getFirstItem(module[G.MODEL][G.CHILDREN].article[G.CACHE])

  const { [G.EVENTS]: eventBus } = module[G.ADAPTER]

  // Part could either be inserted via the event (in case of selecting one from the hotspot
  // component) or the current model cache (in case of parts detail page)
  const { node: part = null } = event?.detail || event || {}

  const key = part?.articleId || partCache?.key || null

  const newPart = {
    key,
    amount: 1,
    name: part?.name || partCache?.value?.name || null,
    articleNumber: part?.orderNumber || partCache?.value?.orderNumber || null,
  }

  return await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.CART, G.DONE), ({ detail }) => {
      const { [G.DATA]: currentCart } = detail
      resolve(currentCart)
    }, { once: true })

    eventBus.dispatch(eventBus.type(G.CART, G.ADD), { [G.DATA]: {
      key,
      data: newPart,
    } })
  })
}

export default curry(addPartToCart)
