/* global G */
import ui from 'lib/hook/ui/update'
import validate from 'lib/hook/model/validate'
import read from 'lib/hook/model/read'
import submit from 'lib/hook/model/submit'
import clear from 'app/_shared/hook/model/clear'
import search from 'app/_shared/hook/appbar/search'
import create from 'app/_shared/hook/appbar/create'
import reset from 'app/_shared/hook/component/reset';
import confirm from 'lib/hook/router/confirm';
import map from 'lib/sequence/model/api/map'
import asObject from 'lib/sequence/component/children/asObject'
import back from 'app/_shared/hook/redirect/back'
import check from 'app/_shared/hook/check'
import recall from 'app/_shared/hook/component/recall'
import updateCache from 'app/_shared/hook/model/updateCache'

const _newHooks = {
  before: [
    reset,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _editHooks = {
  before: [
    clear,
    reset,
  ],
  after: [
    read,
    ui,
    confirm,
  ],
}

// const _baseHooks = {
//   after: [
//     ui,
//   ],
// }

const _listHooks = {
  // before: [
  //   // search,
  //   // create,
  // ],
  after: [
    create,
    search,
    recall,
    ui,
  ],
}

const _detailHooks = {
  before: [
    clear,
  ],
  after: [
    read,
    obj => (...args) => {
      const model = obj[G.MODEL]
      const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]
      const { info } = asObject(actionComponent[G.CHILDREN])
      map(model)(info)
      return args
    },
    ui,
  ],
}

const _submitHooks = {
  before: [
    validate,
  ],
  after: [
    ui,
    check,
    submit,
    updateCache,
    back,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _listHooks,
    new: _newHooks,
    edit: _editHooks,
    detail: _detailHooks,
    submit: _submitHooks,
    // remove: _baseHooks,
  },
}

export default hooks
