/* global G */
import { curry } from 'lib/util'

/**
 * Persists incoming value in corresponding component's state.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {boolean} match
 * @return {Promise<void>} void
 */
const confirmChange = (module, component, match) => {
  const state = component[G.STATE]
  state.confirmed = match
}

export default curry(confirmChange)
