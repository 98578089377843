/* global G */
import { curry } from 'lib/util'
import soon from 'app/_shared/events/soon'

/**
 * Shows a dialog informing the user that a device was found.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {function} okHandler          a function to be executed if the user clicks 'ok'
 */
const showSerialRequiredDialog = (module, okHandler) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: module[G.ADAPTER][G.INTL]._t(
      'dialog.serialRequired.title',
      {
        ns: 'device',
        _key: 'dialog.serialRequired.title',
        defaultValue: 'Sorry',
      },
    ),
    text: module[G.ADAPTER][G.INTL]._t(
      'dialog.serialRequired.text',
      {
        ns: 'device',
        _key: 'dialog.serialRequired.text',
        defaultValue: 'Device registration requires a serial number. In case of problems, please'
                      + ' send us a message, we will try to help',
      },
    ),
    children: {
      cancel: {
        key: 'cancel',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
      ok: {
        key: 'ok',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.needHelp',
          {
            ns: 'common',
            _key: 'button.needHelp',
            defaultValue: 'I need help',
          },
        ),
      },
    },
    okHandler,
  })
}

/**
 * Toggles the step1 component's state mode between list and page.
 *
 * @type {Gaia.AppModule.EventHandler}
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const missingSerial = async (module, component, event) => {
  showSerialRequiredDialog(module, async () => {
    await soon(module, component, event)
  })
}
export default curry(missingSerial)
