/* eslint-disable no-param-reassign */
/* global G */

/**
 * cacheAttributeTitle hook
 *
 * Looks for {@param props} keys in the sub-model's children, concatenates them as a string and
 * saves the result as title in the current action's data.
 *
 * @param {string} attribute the submodel to get the title from
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
const cacheAttributeTitle = attribute => (...props) => obj => async (...args) => {
  const model = obj[G.MODEL][G.CHILDREN][attribute]
  const actionData = obj[G.STATE][G.ACTION][G.DATA]

  const title = props.reduce((acc, key) => {
    const child = model[G.CHILDREN][key]
    child && acc.push(child[G.CACHE])
    return acc
  }, []).join(' ')

  actionData.title = { _cache: title }

  return args
}

export default cacheAttributeTitle
