/* global G */
import { curry, setKey } from 'lib/util'

/**
 * Model State Save Event Handler
 *
 * Stores {@param name} sub model in {@param module[G.STATE]}.
 *
 * @param {string} name       the name of the child model
 * @param {Gaia.AppModule.Spec} module     the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event      the event object that triggered this handler
 * @return {Promise<void>}
 */
const stateSave = curry(async (name, module, component, event) => {
  const model = module[G.MODEL]
  const child = model[G.CHILDREN][name]

  setKey(child, name, model[G.STATE])
})

export default stateSave
