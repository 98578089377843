/* global G */
import { curry } from 'lib/util'

/**
 * Returns the translated label to be used as the name of the devices without equipment.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {Promise<string>}           the translated label
 */
const undefinedModelLabel = async (module) => {
  const _key = 'label.undefinedModel'
  return await module[G.ADAPTER][G.INTL]._t(_key, {
    ns: 'device',
    _key,
    defaultValue: 'Undefined model',
  })
}

export default curry(undefinedModelLabel)
