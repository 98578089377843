/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { set } from 'lib/sequence/component/state/value'
import { check } from 'lib/sequence/component/state/checked'
import { disable } from 'lib/sequence/component/state/disabled'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Presets the {@code notify} checkboxes and disables the team checkbox because the user hasn't
 * typed in an email yet.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 *
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetNotify = module => async (components, ...args) => {
  const { notify } = components
  const { team, members } = asObject(notify[G.CHILDREN])

  check(team) && set(team, 'on')
  check(members) && set(members, 'on')

  disable(team)

  return [components, ...args]
}

/**
 * Ticket Admin Create Action
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 *
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  presetNotify(module),
)(find(component), ...args)
