/* global G */
import { curry } from '@gaia/util'

const descriptor = 'model::transformer::concat'

/**
 * Transformer Concat
 *
 * concatenates values into a single string
 *
 * @param ref - attribute key
 * @param {array} keys - an array of keys to be concatenated
 * @param obj - data model
 * @param data - data aggregator, expected obj[G.STATE][G.DATA]
 * @return {*}
 */
const concat = (ref, keys, obj, data) => {
  const _data = data
  try {
    const value = keys.reduce((acc, key) => {
      let _acc = acc
      const item = obj[G.CHILDREN][key]
      _acc += item[G.DATA].value ? `${item[G.DATA].value} ` : ''
      return _acc
    }, '')
    const item = obj[G.CHILDREN][ref]
    const { type } = item[G.PROPS]
    _data[type][ref] = value.slice(0, -1)
  } catch (e) {
    console.error(descriptor, e)
  }
  return _data
}

export default curry(concat)
