/* eslint-disable no-unused-vars */
/* global G */
import { set } from 'lib/sequence/component/state/value'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

/**
 * Support Contact Action
 *
 * Sets the incoming username.
 *
 * @return {function(*): function(...[*]): Promise<*[]>}
 */
export default () => component => async (...args) => {
  const { username: usernameData } = args[0]
  const { username, form } = sequenceComponentFindProxy(component)

  set(username, usernameData || null)

  return args
}
