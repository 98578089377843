/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import { uncheck } from 'lib/sequence/component/state/checked'

/**
 * Checks if the incoming value is non-nil and enables the {@code notify}'s {@code team} checkbox
 * if it is. Otherwise, disables it.
 *
 * @param {Gaia.AppModule.Spec} module  app module
 * @param {Gaia.Component.Spec} component  action component
 * @param {Gaia.PlatformEvent} event    event
 * @return {Gaia.PlatformEvent} event
 */
const checkNotification = (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const { value } = event?.detail || event
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { notify } = sequenceComponentFindProxy(actionComponent)
  const { team } = asObject(notify[G.CHILDREN])

  value && value.length
    ? enable(team)
    : disable(team) && uncheck(team)

  module[G.ADAPTER][G.UI].update(module)
}

export default curry(checkNotification)
