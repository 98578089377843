/* global G */
import { asyncpipe } from 'lib/util'
import seqUpdate from 'lib/sequence/model/api/update'
import seqTransform from 'lib/sequence/model/transformer'
import { updateData } from 'lib/sequence/model/api/submit'

/**
 * User Action Submit Language
 *
 * Due to the special UI updates to be made by the application when the user changes their language,
 * the process has this dedicated submit action.
 *
 * So that the SideBar an AppBar components change their language after the user submits it, this
 * action must make use of the provide hook after this action has been called.
 *
 * So that the current module changes their language after the user submits it, this action must
 * make use of the redirect hook with a configured route that includes both module and action
 * instead of the usual back hook, so that the module gets recreated.
 *
 * As it should not use the back hook, the previous entry will not be removed from the stack before
 * it is displayed, and due to that, the call to the provide hook can cause problems with path-
 * dependent components. If we don't manually remove the entry from the stack, for instance, the
 * ActionBreadcrumbs component momentarily flickers after submitting the language.
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
const submitLanguage = module => () => async (...args) => {
  const model = module[G.MODEL]
  const { person } = model[G.CHILDREN]
  const validationError = model[G.STATE][G.ERROR]
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = !!validationError
  const stack = module[G.ADAPTER][G.SESSION][G.STATE][G.PREV]
  const [lastEntry = {}] = stack

  // manually removing previous profile action from the history stack
  lastEntry[G.ACTION] === module[G.ACTIONS].profile._name && stack.pop()

  !validationError && await asyncpipe(
    seqUpdate,
    seqTransform,
    updateData,
  )(person)
  // updating application's language
  !validationError && await module[G.ADAPTER][G.INTL].set(person[G.DATA].lang)

  return args
}

export default submitLanguage
