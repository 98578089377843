/* eslint-disable no-unused-expressions */
/* global G */
import { curry } from '@gaia/util'

const descriptor = 'model::organisation::support::transformer::toProvides'

/**
 * To Provides Transformer
 *
 * Simply propagates the value of {@param key} to the payload and deletes {@param target} if
 * {@param key} is falsy.
 *
 * @param {string} key    the key of the attribute
 * @param {string} target target attribute to delete if key attribute is null
 * @returns {(function(...[*]): (*))|*}
 */
const toProvides = (key, target) => curry((obj, data) => {
  console.warn(descriptor)

  const _data = data
  try {
    const item = obj[G.CHILDREN][key]
    const targetItem = obj[G.CHILDREN][target]

    const { type } = item[G.PROPS]
    const { value } = item[G.DATA]

    _data[type][key] = value ?? null

    !value && (targetItem[G.DATA].value = [])
  } catch (e) {
    console.error(descriptor, e)
  }

  return _data
})

export default toProvides
