import noop from '@gaia/util/noop/action'
import validateRedirect from '@app/registration/action/validateRedirect'
import confirm from '@app/registration/action/confirm'
import bulk from '@app/registration/action/bulk'
import verify from '@app/registration/action/verify'
import validateLogin from '@app/registration/action/validateLogin'
import register from 'app/registration/action/register'
import invite from '@app/registration/action/invite'
import contact from '@app/registration/action/invite/contact'
import submitInvitation from '@app/registration/action/invite/submit'

export default {
  index: noop,
  register,
  bulk,
  confirm,
  validateRedirect,
  validateLogin,
  registerActivate: noop,
  registerError: noop,
  verify,
  // invite actions
  invite, // first screen
  contact, // second screen
  submit: submitInvitation,
  // external registration
  external: noop,
}
