/* global G */
import session from 'app/_shared/session'
/**
 * User Action Submit
 *
 *
 * @param {Gaia.Component.Spec} module
 * @returns {function(): function(...[*]): Promise<*[]>}
 */

const _new = (module) => {
  const model = module[G.MODEL]
  const organisation = session(module).organisation.ref({ wrap: true })
  model[G.DATA].status = 50
  model[G.CHILDREN].organisation[G.DATA].value = organisation
}

const submit = module => () => async (...args) => {
  const model = module[G.MODEL]
  const validationError = model[G.STATE][G.ERROR]
  const moduleState = module[G.STATE]
  console.log('model state', model[G.DATA], moduleState)
  moduleState[G.ERROR] = !!validationError

  switch (module[G.STATE][G.ACTION][G.COMPONENT]._name) {
    case module[G.ACTIONS].new._name:
      !validationError && _new(module)
      break
      // case module[G.ACTIONS].edit._name:
      //   !validationError && await asyncpipe(
      //     seqUpdate,
      //     seqTransform,
      //     updateData,
      //   )(model[G.CHILDREN].person)
      //   break
    default:
  }

  return args
}

export default submit
