/* global G */
import { set } from 'lib/sequence/component/state/value'
import sequenceComponentFind from 'lib/sequence/component/children/find'

/**
 * Message Attachments action.
 *
 * Save the details of the event (including message type and newStatus) in the state of the
 * messageField, so that it can be reused for setting the message type and changing the
 * ticket status.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const { detail } = (args && args[0]) || {}
  const { messageField } = sequenceComponentFind(component)
  set(messageField, detail?.text || '')
  messageField[G.STATE].detail = detail
  return args
}
