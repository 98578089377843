/* global G */
import { curry } from 'lib/util'

const descriptor = 'model::organisation::support::transformer::toSupport'
/**
 * To Support transformer
 *
 * @param key - identifier
 * @param obj - data model
 * @param data - data aggregator, expected obj[G.STATE][G.DATA]
 * @returns {*}
 */
const toSupport = (key, obj, data) => {
  console.warn(descriptor)
  const _data = data

  try {
    const item = obj[G.CHILDREN][key]
    const { type } = item[G.PROPS]
    const { value } = item[G.STATE][G.DATA]

    _data[type][key] = value
  } catch (e) {
    console.error(e)
  }
  return _data
}

export default curry(toSupport)
