/* eslint-disable no-unused-expressions */
/* global G */
import { curry } from '@gaia/util'

const descriptor = 'model::transformer::toAddress'

/**
 * To Address (Model) Transformer
 *
 * transforms input into an address reference object
 *
 * @param key
 * @param obj
 * @param data
 * @return {*}
 */
const fn = (key, obj, data) => {
  const _data = data
  try {
    const attribute = obj[G.CHILDREN][key]
    const { value } = attribute[G.STATE][G.DATA]
    Object.keys(value).length ? _data.value[key] = value : delete _data.value[key]
  } catch (e) {
    console.error(descriptor, e)
  }
  return _data
}

export default curry(fn)
