/* global G */
import { curry, getFirstItem, setKey } from 'lib/util'
import session from 'app/_shared/session'
import soon from 'app/_shared/events/soon'
import { redirect } from 'app/_shared/events'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import reset from 'app/_shared/component/reset'

/**
 * Send To WebShop Event Handler
 *
 * If tenant specific webshop setting is enabled, it will show a confirm dialog if the user has
 * transferred their cart already, and will execute {@param webshop} action afterward.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 * @returns {Promise<void>}
 */
const sendToWebshop = async (module, component, event) => {
  // model
  const model = module[G.MODEL]
  const currentCart = getFirstItem(model[G.CACHE])

  const { settings } = session(module)
  const webShopEnabled = settings?.webShop?.enabled

  if (webShopEnabled) {
    let confirmedWarning = true
    if (currentCart.value?.webshopHistory?.length) {
      confirmedWarning = await showBlockingDialog(module, component, {
        title: {
          ns: 'cart',
          key: 'dialog.transferWarning.title',
          defaultValue: 'Transfer to online shop',
        },
        text: {
          ns: 'cart',
          key: 'dialog.transferWarning.text',
          defaultValue: 'You already transferred this order to the online shop.\nAre you sure you want to transfer it again?',
        },
      })
    }

    if (confirmedWarning) {
      const { route } = component[G.CONFIGURATION]
      const action = module[G.ACTIONS][route.action]
      const actionComponent = action[G.COMPONENT]

      reset(actionComponent)
      setKey(null, G.STATE, module)

      await redirect(module, component, event)
    } else {
      await module[G.ADAPTER][G.UI].update(module)
    }
  } else {
    soon(module, component, event)
  }
}

export default curry(sendToWebshop)
