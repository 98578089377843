/* eslint-disable no-param-reassign */
/* global G */
import redirect from 'lib/hook/router/redirect'
import confirm from 'lib/hook/router/confirm'
import validate from 'lib/hook/model/validate'
import ui from 'lib/hook/ui/update'
import bulk from 'lib/hook/model/bulk'
import routeComposition from 'trait/composition/route'
import { settings } from 'app/_shared/session'
import check from 'app/_shared/hook/check'
import provide from 'lib/hook/ui/provide'

const _baseHooks = {
  after: [
    ui,
  ],
}

const _submitHooks = {
  after: [
    // Due to non-standard payload, model gets
    // validated and submitted in action logic!
    ui,
    provide,
    redirect,
  ],
}

const _registerHooks = {
  before: [
    obj => async (...args) => {
      if (settings.externalPortalUrl) {
        obj[G.STATE][G.ROUTE] = routeComposition(null, 'external')
        await redirect(obj)(...args)
      }
      return args
    },
  ],
  after: [
    ui,
    confirm,
  ],
}

const _validateLoginHooks = {
  before: [
    validate,
  ],
  after: [
    ui,
    provide,
    redirect,
  ],
}

const _validateRedirectHooks = {
  before: [
    validate,
  ],
  after: [
    ui,
    redirect,
  ],
}

const _bulkHooks = {
  after: [
    ui,
    check,
    bulk,
    redirect,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    confirm: _baseHooks,
    registerActivate: _baseHooks,
    registerError: _baseHooks,
    verify: _baseHooks,
    register: _registerHooks,
    validateLogin: _validateLoginHooks,
    validateRedirect: _validateRedirectHooks,
    bulk: _bulkHooks,
    // invite actions
    invite: _baseHooks,
    contact: _baseHooks,
    submit: _submitHooks,
    // external registration
    external: _baseHooks,
  },
}

export default hooks
