/* eslint-disable arrow-body-style,implicit-arrow-linebreak,no-shadow,no-use-before-define,
object-curly-newline */
/* global G */
import { asyncpipe, curry } from 'lib/util'
import searchDevice from 'app/_shared/events/search/device'

/**
 * Returns the first {@param ref} of {@param model}.
 * @param {object} model  the model object
 * @param {string} ref    the name of th ref
 * @return {*}
 */
const _firstRef = (model, ref) => model?.refs?.[ref]?.[0]

/**
 * Returns the translation of the text to show when a service item already exists.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {string} organisation         the name of the organisation the existing service item is
 *                                      installed at
 * @returns {string}                    the translated string
 */
const deviceFoundText = (module, organisation) => {
  return module[G.ADAPTER][G.INTL]._t(
    'serialField.deviceFound',
    {
      ns: 'device',
      _key: 'serialField.deviceFound',
      defaultValue: 'Device is already assigned to {{organisation}}',
      organisation,
    },
  )
}

/**
 * Returns the translation of the text to show when no service item or equipment is found.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {string}                    the translated string
 */
const deviceNotFoundText = (module) => {
  return module[G.ADAPTER][G.INTL]._t(
    'serialField.deviceNotFound',
    {
      ns: 'device',
      _key: 'serialField.deviceNotFound',
      defaultValue: 'Device cannot be identified from production data',
    },
  )
}

/**
 * Obtains the serial from {@param eventOrSerial}, accepting that it can be found inside an event
 * object triggered by an input or directly the value.
 *
 * @param {Gaia.PlatformEvent|number} eventOrSerial information about the event that triggered this
 *                                                  handler or a serial number
 * @param {object} [eventOrSerial.detail]           relevant information about the event
 * @param {number} [eventOrSerial.detail.value]     a serial number
 * @returns {Gaia.PlatformEvent|number}
 */
const serial = eventOrSerial => eventOrSerial.detail?.value ?? eventOrSerial

/**
 * Attempts to obtain a device by its {@param serial} by calling {@link searchDevice}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {number} serial               a serial number
 * @returns {Promise<{serviceItem: *, product: *, serial, installedAt: *, equipment: (*)}>}
 */
const search = curry(async (module, component, serial) => {
  const data = await searchDevice('verbose', module, component, serial)
  const [serviceItem] = data || []
  const name = serviceItem?.value?.name
  const installedAt = _firstRef(serviceItem, 'installedAt')
  const equipment = _firstRef(serviceItem, 'equipment')
  const product = _firstRef(serviceItem, 'product')

  return {
    serial,
    name,
    serviceItem,
    installedAt,
    equipment,
    product,
  }
})

/**
 * Updates the field's helperText and error state properties according to the result of the device's
 * {@link search}.
 *
 * TODO: move elsewhere
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {object} result               the result of the {@link search} function
 */
const setFieldState = curry((module, component, result) => {
  const componentState = component[G.STATE]
  const { serial, serviceItem, installedAt } = result
  const serviceItemExists = !!serviceItem?.key

  if (serial && serviceItemExists) {
    const organisation = installedAt?.value?.name
    componentState.helperText = deviceFoundText(module, organisation)
    componentState.error = true
  } else if (serial && !serviceItem) {
    componentState.helperText = deviceNotFoundText(module)
    componentState.error = true
  } else {
    componentState.helperText = ''
    componentState.error = false
  }
  return result
})

/**
 * Event Handler Find Device
 *
 * Attempts to find a device by its serial value.
 *
 * Attempts to find a service item with the same serial in the first place to inform the user that
 * such ServiceItem already exists and, in that case, its equipment is used. If no ServiceItem is
 * found, proceeds with the attempt to obtain the equipment itself.
 *
 * @type {Gaia.AppModule.EventHandler}
 * @param {Gaia.PlatformEvent|number} eventOrSerial information about the event that triggered this
 *                                                  handler or a serial number
 * @param {object} [eventOrSerial.target]           information about the target of the event
 * @param {number} [eventOrSerial.target.value]     a serial number
 * @return {Promise<object>}
 */
export default curry((module, component, eventOrSerial) => asyncpipe(
  serial,
  search(module, component),
  setFieldState(module, component),
)(eventOrSerial))
