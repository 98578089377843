import { curry } from 'lib/util'
import settings from '@tenant/settings'

/**
 * See documentation in {@link settings.drilldown.minStepsDepth}
 * @type {number}
 */
const minStepsDepth = settings?.drilldown?.minStepsDepth || 0

/**
 * Get Tree Steps Event Handler
 *
 * Calculated the list of the next possible steps based on {@param eventOrOptions.detail.dynamic} as
 * well as {@link minStepsDepth}.
 *
 * If {@code dynamic} is {@code false}, it means we want a static list of steps (the first child of
 * every node) until we reach {@link minStepsDepth}.
 *
 * If {@code dynamic} is {@code true}, it means we want a dynamic list, based on the clicked node at
 * {@param eventOrOptions.detail.node} down to the deepest node. In any case, we use the first
 * child when traversing the tree down, because the name of each child will always be the same.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} eventOrOptions  information about the event that triggered this handler
 * @returns {*[]}
 */
const getTreeSteps = (module, component, eventOrOptions) => {
  const { value: node, dynamic = false, steps = [] } = eventOrOptions?.detail || eventOrOptions || {}

  node.type === 'Directory'
   && (dynamic ? node.depth > minStepsDepth : node.depth < minStepsDepth)
   && steps.push({ name: node.name })

  if (node?.$children) {
    getTreeSteps(module, component, { value: node.$children[0], minStepsDepth, dynamic, steps })
  }

  return steps
}

export default curry(getTreeSteps)
