/**
 * Request Message Types Collection.
 *
 * Contains static list of available request's message types, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 *
 * @example <caption>Overriding properties</caption>
 * {
 *     // ...
 *     overrides: [
 *      {
 *        parentType: 'device_breakdown',
 *        labelText: 'test text',
 *      }
 *     ]
 * }
 */
export default [
  {
    key: 'more_information',
    value: 'More information',
    default: true,
    priorSelection: false,
    icon: 'message',
    color: 'primary',
    labelText: 'I\'ve got some new information',
    backgroundText: 'I\'ve got some new information',
    status: [10, 60, 80],
    newStatus: {
      80: 60,
    },
  },
  {
    key: 'any_updates',
    value: 'Any updates',
    default: false,
    priorSelection: false,
    icon: 'question_mark',
    color: 'warning',
    labelText: 'Any updates?',
    backgroundText: 'Any updates?',
    proposedText: 'Can you update me on the issue\'s progress?',
    status: [
      60,
    ],
  },
  {
    key: 'requested_information',
    value: 'Requested information',
    default: true,
    priorSelection: false,
    icon: 'info',
    color: 'primary',
    labelText: 'Here\'s the requested information',
    backgroundText: 'Here\'s the requested information',
    status: [
      50,
    ],
    newStatus: 60,
  },
  {
    key: 'questions',
    value: 'Questions',
    action: 'questions',
    default: false,
    priorSelection: false,
    icon: 'question_mark',
    color: 'warning',
    labelText: 'I got questions',
    backgroundText: 'I got questions',
    status: [
      50,
    ],
    newStatus: 60,
  },
  {
    key: 'cancel_request',
    value: 'Cancel request',
    default: false,
    priorSelection: false,
    icon: 'lock',
    color: 'error',
    labelText: 'Please close this request',
    backgroundText: 'Please close this request',
    proposedText: 'Please close this request, the issue is obsolete',
    status: [
      50,
      60,
    ],
    newStatus: 80,
  },
  {
    key: 'solution_confirmed',
    value: 'Solution confirmed',
    default: false,
    priorSelection: true,
    icon: 'sentiment_satisfied_alt',
    color: 'success',
    labelText: 'Works, thank you',
    backgroundText: 'Works, thank you',
    proposedText: 'Works, thank you',
    status: [
      75,
    ],
    newStatus: 80,
  },
  {
    key: 'solution_questioned',
    value: 'Solution questioned',
    default: false,
    priorSelection: true,
    icon: 'sentiment_dissatisfied',
    color: 'warning',
    labelText: 'I have still got questions',
    backgroundText: 'I have still got questions',
    status: [
      75,
    ],
    newStatus: 60,
  },
]
