/* global G */
import ui from 'lib/hook/ui/update'
import provide from 'lib/hook/ui/provide'
import redirect from 'lib/hook/router/redirect'
import recreate from 'app/_shared/hook/ui/recreate'
import { setKey } from 'lib/util'
import platform from 'app/_shared/hook/platform'
import { settings } from 'app/_shared/session'

const _baseHooks = {
  after: [
    ui,
  ],
}

const _indexHooks = {
  after: [
    // so that the browser's password manager sets the password corresponding to the email written
    // in the first step, we have to recreate the ui
    platform({
      web: obj => async (...args) => (!settings.singleStepLogin
        ? await recreate(obj)(...args)
        : args),
    }),
    // if we don't also update the ui, the toolbar doesn't get updated
    ui,
  ],
}

const _redirectHooks = {
  after: [
    redirect,
    provide,
    obj => (...args) => {
      // prevent user by going back to login by clicking back by clearing the previous route after
      // access action redirect hook are executed
      setKey([], G.PREV, obj[G.ADAPTER][G.SESSION][G.STATE])
      return args
    },
  ],
}

const _validateRedirectHooks = {
  before: [], // validation in action logic
  after: [
    ui,
    obj => (...args) => {
      const model = obj[G.MODEL]
      const { username } = model[G.DATA]

      /**
       * If we redirect to the support module, meaning the user clicked
       * on 'Get help', we need to pass the username because we
       * display it in a text.
       */
      return obj[G.STATE][G.ROUTE]?.[G.MODULE] === 'support'
        ? [{ username }, ...args]
        : args
    },
    redirect,
  ],
}

const _logoutHooks = {
  before: [],
  after: [
    redirect,
    provide,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _indexHooks,
    context: _baseHooks,
    access: _redirectHooks,
    loginLink: _baseHooks,
    validateLogin: _validateRedirectHooks,
    validateRedirect: _validateRedirectHooks,
    logout: _logoutHooks,
  },
}

export default hooks
