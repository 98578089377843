/* global G */
import { curry, deleteKey } from 'lib/util'

/**
 * Instructs the server to mark all messages as read for
 * the current ticket/request.
 *
 * After the request is successful, we delete the flag
 * for unread messages. We initially set it in
 * {@code module/_shared/events/pubsub/messages.js:28}
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<void>}
 */
// eslint-disable-next-line no-unused-vars
const setAsRead = async (module, component, event) => {
  const model = module[G.MODEL]

  /**
   * We only want to fire the request if either
   * the flag for unread messages ({@code model[G.STATE]?.[G.MESSAGE]})
   * exists or the incoming request's {@code unreadMessages}
   * property is not 0.
   */
  if (
    module[G.STATE][G.ACTION]
      && (model[G.STATE]?.[G.MESSAGE] || model?.[G.CACHE]?.value.unreadMessages !== 0)
  ) {
    const httpAdapter = model[G.ADAPTER][G.HTTP]
    const ressourceId = model[G.STATE][G.REF]
    const { version } = model[G.PROPS]
    const url = `/api/v${version}/setRead/messages`

    await httpAdapter.put({ url, params: { target: ressourceId } })
      // Clearing unread messages flag
      .then(deleteKey(G.MESSAGE, model[G.DATA]))
      .then(() => { model[G.CACHE].value.unreadMessages = 0 })
      .catch(r => console.error(r))
  }
}

export default curry(setAsRead)
