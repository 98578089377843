/* global G */
import { pipe } from 'lib/util'
import { usesGetterSetter, usesScopedNamespace } from 'trait/uses'
import { canHookInto } from 'trait/can'
import appModuleComposition from 'trait/composition/appmodule'
import actions from './actions'
import events from './events'
import hooks from './hooks'
import api from './api'

const { API, ACTIONS, EVENTS } = G

const descriptor = 'module::registration'

/**
 * Guest Registration Module
 *
 * @param {Gaia.AppModule.Spec} obj - configuration object
 * @return {Gaia.AppModule.Spec} obj - object composition
 */
const composition = pipe(
  appModuleComposition(descriptor),
  usesGetterSetter(ACTIONS, actions),
  usesGetterSetter(EVENTS, events),
  canHookInto(ACTIONS, hooks),
  usesScopedNamespace(API, api),
)

export default composition
