/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from '@gaia/trait/uses'
import toBoolean from 'model/_shared/transformer/toBoolean'
import dataToExistingRef from 'model/_shared/transformer/dataToExistingRef'

const descriptor = 'model::team::forward'

export default pipe(
  modelComposition(descriptor),
  usesGetterSetter(G.TRANSFORMER, {
    toAll: toBoolean('toAll', true),
    from: dataToExistingRef('from'),
    product: dataToExistingRef('product'),
    ticketType: dataToExistingRef('ticketType'),
  }),
)
