/* global G */
import { pipe } from 'lib/util'
import appModuleComposition from 'trait/composition/appmodule'
import { canHookInto } from 'trait/can'
import { usesGetterSetter } from 'trait/uses'
import actions from './actions'
import events from './events'
import hooks from './hooks'

const descriptor = 'module::organisation'

/**
 * Organisation Module Composition
 *
 * the module displays a list of subsidiary companies
 * allows adding new organisation
 * allows editing existing organisation
 * allows displaying organisational information overview - t.b.d.
 *
 * @param {Gaia.AppModule.Spec} obj - configuration object
 * @return {Gaia.Module} obj - object composition
 */
const composition = pipe(
  appModuleComposition(descriptor),
  canHookInto(G.ACTIONS, hooks),
  usesGetterSetter(G.ACTIONS, actions),
  usesGetterSetter(G.EVENTS, events),
)

export default composition
