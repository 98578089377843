/* global G */

import { set as setToken } from 'app/_shared/component/token';
import { set, get } from 'lib/sequence/component/state/value';
import asObject from 'lib/sequence/component/children/asObject';
import format from 'lib/util/string';

const updateStdOutput = (module, component, event) => {
  console.log('obj:', event)
  console.log('updateStd args:', event)
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { textStandardOutput } = asObject(actionComponent[G.CHILDREN])

  console.log('textStandardOutput:', textStandardOutput)
  console.log('textStandardOutput keys:', Object.keys(textStandardOutput))
  // setToken(textStandardOutput, { standardInput: 'test' })
  let input

  // Probably better to use model in order not to handle event specifics
  if (typeof event.target !== 'undefined') {
    input = event.target.value
  } else {
    input = event
  }
  const template = get(textStandardOutput)
  console.log('template', template, textStandardOutput[G.STATE])
  // const value = format(template, { standardInput: input })
  // console.log('value', value)
  set(textStandardOutput, `Input: ${input}`)

  return module[G.ADAPTER][G.UI].update(module)
}

export default updateStdOutput
