/* global G */
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import validationResult from 'app/support/action/validateRedirect'

/**
 * Attempts to send a "password reset" request according to the current model values.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {Promise<void>} a pending promise object waiting for the request's result
 * @private
 */
const _submit = async (module) => {
  const model = module[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]

  // const token = module[G.ADAPTER][G.SESSION][G.STATE][G.REF]
  const { version, api } = model[G.PROPS]
  const url = `/api/v${version}/${api}/password/reset`

  const params = {
    token: module[G.ADAPTER][G.SESSION][G.STATE][G.REF],
    password: model[G.CHILDREN].change[G.CHILDREN].password[G.DATA].value,
  }

  try {
    // todo: restore
    const result = await httpAdapter.post({ url, params })
    const moduleState = module[G.STATE]
    moduleState[G.ROUTE] = {
      [G.ACTION]: 'changeSuccess',
    }
  } catch {
    const moduleState = module[G.STATE]
    moduleState[G.ROUTE] = {
      [G.ACTION]: 'changeError',
    }
  } finally {
    await redirectSequence(module)({})
  }
}

/**
 * Validates the current model's data and, if there is no error, attempts to send a reset request.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(): function(...[*]): Promise<*>}  the call's arguments
 */
export default module => () => async (...args) => {
  // 2 - model validator
  const model = module[G.MODEL]
  const result = validationResult(module)()(...args)
  !model[G.STATE][G.ERROR] && await _submit(module)

  return result
}
