/* eslint-disable no-param-reassign,no-unused-vars */
/* global G */
import { curry, setKey } from 'lib/util'
import validate from 'lib/sequence/model/validate'
import asObject from 'lib/sequence/component/children/asObject'
import { checked } from 'lib/sequence/component/state/checked'
import refresh from 'lib/sequence/model/api/refresh'
import { v4 as uuidV4 } from 'uuid'
import find from 'lib/sequence/component/children/find'

/**
 * Event Handler Child Add.
 *
 * Validates the current form and uses its data to add or modify a new or existing sub-model to the
 * base model.
 *
 * It results in the following changes to the model depending on whether we have already selected an
 * item and whether the changes should be persisted in the original object or just saved for the
 * ticket:
 *
 * Child already has a ref (we are editing an existing object):
 *  Changes should be just saved for the ticket:
 *    child ref        = submodel ref
 *    child values     = submodel values (either the original ones or previous form values)
 *    dataChild ref    = null
 *  Changes should be persisted to master data:
 *    child ref        = submodel ref
 *    child values     = form values
 *    dataChild ref    = fake ref
 * Child doesn't have a ref (we are creating a new object):
 *  Changes should be just saved for the ticket:
 *    child ref        = null
 *    child values     = form values
 *    dataChild ref    = null
 *  Changes should be persisted to master data:
 *    child ref        = null
 *    child values     = form values
 *    dataChild ref    = fake ref
 *
 * create should happen when: item ref == null && itemData ref != null
 *
 * update should happen when: item ref != null && itemData ref != null
 *
 * * child represents a reference to another existing object.
 * * dataChild is a subset of the child's properties, embedded in the model.
 * * dataChild values are always equal to child values, as dataChild is a subset of child, child
 *    should be used to map the values to the view
 *
 * @param {string} name                 the name of the child model
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 information about the event that triggered this handler
 * @return {Promise<void>}
 */
export default curry(async (name, module, component, event) => {
  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const child = model[G.CHILDREN][name]
  const { store } = find(actionComponent[G.ACTIONS][0])
  const childExists = !!child[G.CACHE]?._rev
  const storeChecked = checked(store)
  // obtaining child's current ref (if any)
  const ref = child[G.STATE][G.REF]
  // validating current form component against child
  await validate(child)(actionComponent)
  // setting module error from model
  module[G.STATE][G.ERROR] = child[G.STATE][G.ERROR]
  // checking validation error
  if (!child[G.STATE][G.ERROR]) {
    // If the child model doesn't exist, we toggle both the creation of its id and whether we are
    // adding the record with the bulk flag. Otherwise, if the child model exists, we always keep
    // its ID, the only difference is whether we are updating the original record or not
    if (!childExists) {
      storeChecked
        ? setKey(ref || uuidV4(), G.REF, child[G.STATE])
          && (child[G.DATA].key = child[G.STATE][G.REF])
        : setKey(null, G.REF, child[G.STATE])
          && delete child[G.DATA].key
          && delete child[G.STATE]?.[G.DATA]?.key
    }

    await refresh(child)
    child[G.DATA].status = 50
    child[G.STATE][G.BULK] = storeChecked
    // setting action's dirty flag
    setKey(false, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
  } else {
    module[G.ADAPTER][G.UI].update(module)
  }
})
