/* global G */
/* eslint-disable no-unused-vars */
import { curry } from 'lib/util'
import session from 'app/_shared/session'

/**
 * Return a search query that filters the search to only contain results that have a
 * {@code ref.submitter} equal to the current user, or a
 * {@code ref.recipient} equal to the current user.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event|object} event              information about the event that triggered this handler
 * @returns {`submitter:("${*}") OR recipient:("${*}")`}
 * @constructor
 */
const IAmSubmitterOrRecipient = (module, component, event) => {
  const user = session(module).user.key({ wrap: false })

  return `submitter:("${user}") OR recipient:("${user}")`
}

export default curry(IAmSubmitterOrRecipient)
