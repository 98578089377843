/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Retrieve the tree from the {@param module}'s action component's props.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component      the current action's main component
 * @param {Event} event                     the event object that triggered this handler
 * @returns {string[]}
 */
const getHierarchy = (module, component, eventOrOptions) => {
  const { node, hierarchy = [] } = eventOrOptions.detail || eventOrOptions || {}

  hierarchy.unshift(node.id)

  node.parent && getHierarchy(module, component, { node: node.parent, hierarchy })

  return hierarchy
}

export default curry(getHierarchy)
