/* global G */
import { set } from 'lib/sequence/component/state/value'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'

/**
 * Support Reset Action
 *
 * @return {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]
  const [data] = args

  if (!data) {
    return args
  }

  const { form, titlePasswordless, titlePassword } = sequenceComponentFindProxy(component)

  sessionState.passwordGenerated
    ? show(titlePasswordless) && hide(titlePassword)
    : show(titlePassword) && hide(titlePasswordless)

  form[G.CHILDREN].reduce((acc, item) => {
    const { key } = item[G.PROPS]
    Object.keys(data).includes(key) && set(item, data[key] || null)
    return acc
  }, null)

  return args
}
