/* eslint-disable no-unused-vars */
/* global G, PLATFORM */
import { asyncPipeSpread, bulk, getFirstItem, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'
import { get, set } from 'lib/sequence/component/state/value'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { empty } from 'lib/util/object'
import { displayFavoriteButtonsFromAttribute } from 'app/_shared/action/partial/favorite/display'
import settings from '@tenant/settings'
import asObject from 'lib/sequence/component/children/asObject'

const display = x => (get(x) ? show(x) : hide(x))
const bulkDisplay = bulk(display)

const _setPropertyField = (field) => {
  const fieldValue = get(field)
  setKey(fieldValue.name, 'label', field[G.STATE])
  set(field, fieldValue.value)
}

/**
 * Maps various properties inside the property box.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapProperties = module => async (components, ...args) => {
  const model = module[G.MODEL][G.CHILDREN]?.equipment || {}
  const { properties } = model[G.CHILDREN]
  const { title, detailsAsLabels, detailsAsProperties, additionalProperties } = components

  const bulkMapModel = bulk(map(model))
  bulkMapModel(title, additionalProperties)

  if (PLATFORM === 'web') {
    const {
      energy: energyLabel,
      voltage: voltageLabel,
      size: sizeLabel,
      type: typeLabel,
    } = asObject(detailsAsLabels[G.CHILDREN])

    map(properties)(detailsAsLabels)

    get(energyLabel) && _setPropertyField(energyLabel)
    get(voltageLabel) && _setPropertyField(voltageLabel)
    get(sizeLabel) && _setPropertyField(sizeLabel)
    get(typeLabel) && _setPropertyField(typeLabel)

    bulkDisplay(energyLabel, voltageLabel, sizeLabel, typeLabel)

    hidden(energyLabel)
    && hidden(voltageLabel)
    && hidden(sizeLabel)
    && hidden(typeLabel)
    && hide(detailsAsLabels)
  }

  if (PLATFORM === 'mobile') {
    const {
      energy: energyProperty,
      voltage: voltageProperty,
      size: sizeProperty,
    } = asObject(detailsAsProperties[G.CHILDREN])

    map(properties)(detailsAsProperties)

    get(energyProperty) && _setPropertyField(energyProperty)
    get(voltageProperty) && _setPropertyField(voltageProperty)
    get(sizeProperty) && _setPropertyField(sizeProperty)

    bulkDisplay(energyProperty, voltageProperty, sizeProperty)

    hidden(energyProperty)
    && hidden(voltageProperty)
    && hidden(sizeProperty)
    && hide(detailsAsProperties)
  }

  return [components, ...args]
}

/**
 * Sets the image of the equipment.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setImage = module => async (components, ...args) => {
  const model = module[G.MODEL][G.CHILDREN]?.equipment || {}
  const imageCache = model?.[G.CACHE]?.image || {}
  const { image, mobileImage } = components

  const equipmentImage = getFirstItem(imageCache)
  const targetComponent = PLATFORM === 'mobile'
    ? mobileImage
    : image

  !empty(equipmentImage)
  && (targetComponent[G.PROPS].attachment = {
    key: equipmentImage?.value?.attachmentId,
    value: equipmentImage?.attachment?.value,
  })

  return [components, ...args]
}

/**
 * Sets the icon of the equipment.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setIcon = module => async (components, ...args) => {
  const model = module[G.MODEL][G.CHILDREN]?.equipment || {}
  const productNumber = model[G.CACHE]?.number || null

  const { icon } = components

  const { documentationTreeFilter = null, documentationTreeNodeIcons = null } = settings

  if (documentationTreeFilter && documentationTreeNodeIcons) {
    const { product: products } = documentationTreeFilter
    const productIcons = documentationTreeNodeIcons.filter(nodeIcon => nodeIcon.nodeType === 'product')
    const targetProduct = products.find(product => product.number === productNumber)
    const targetProductNode = productIcons.find(productIcon => productIcon.name === targetProduct?.name || null)

    const { icon: targetIcon = null } = targetProductNode || {}

    if (targetIcon?.name && targetIcon?.variant) {
      icon[G.STATE].icon = targetIcon.name
      icon[G.STATE].variant = targetIcon.variant
    }
  }

  return [components, ...args]
}

/**
 * Sets the URL for the chat icon
 *
 * @param module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setChatUrl = module => async (components, ...args) => {
  // TODO: On mobile server responds with 400 Invalid data sent.
  //  Investigate
  if (PLATFORM === 'web') {
    const model = module[G.MODEL]
    const { equipment } = model[G.CHILDREN]
    const { serial = null } = equipment?.[G.CACHE] || {}
    const { btnChat } = components

    const { [G.HTTP]: httpAdapter } = model[G.ADAPTER]
    try {
      // We don't want the error middleware to kick in here. The server may return 400
      // if the user doensn't have the role to access the chat. By ommiting the error
      // middleware, we will catch anything that's not 200 and hide the button without
      // showing an error modal.
      const options = { middleware: ({
        loader,
        persistence,
        language,
        impersonation,
      }) => [loader, persistence, language, impersonation] }
      const result = await httpAdapter.post({ url: '/api/v1/chatpdf/jumpurl', params: { serial } }, options)

      if (result?.externalUrl) {
        setKey(result.externalUrl, 'href', btnChat[G.STATE])
      }
    } catch (e) {
      console.error(e)
      hide(btnChat)
    }
  }

  return [components, ...args]
}

/**
 * Determines whether the {@code serialLong} or {@code serial} field should be displayed.
 *
 * If {@code obfuscateSerial}, coming from the args, is {@code true}. It will hide the last
 * 5 digits of the serial number.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displaySerial = module => async (components, ...args) => {
  const moduleState = module[G.STATE]
  const { serial, serialLong } = components
  const [{ detail: { obfuscateSerial = moduleState.obfuscateSerial } = {} } = {}] = args || []
  const serialLongValue = get(serialLong)

  obfuscateSerial && setKey(obfuscateSerial, 'obfuscateSerial', moduleState)
  const shouldObfuscate = obfuscateSerial && obfuscateSerial === module[G.MODEL][G.STATE][G.REF]

  serialLongValue
    ? show(serialLong) && hide(serial)
    : get(serial) && hide(serialLong) && show(serial)

  shouldObfuscate && set(
    serialLongValue?.length ? serialLong : serial,
    serialLongValue?.length
      ? `${get(serialLong).slice(0, -7)}XXXXXXX`
      : 'XXXXX',
  )

  return [components, ...args]
}

/**
 * EquipmentInformation Equipment Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  mapProperties(module),
  setImage(module),
  setIcon(module),
  setChatUrl(module),
  displaySerial(module),
  displayFavoriteButtonsFromAttribute('equipment')(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
