import { isBool, isObj } from 'lib/util'

export const DISABLED = 'disabled'
export const OPTIONAL = 'optional'
export const MANDATORY = 'mandatory'

/**
 * Whether the current attribute is a complex Object.
 *
 * @param {String} attribute  the attribute
 * @return {function(*): *}
 */
export const isAttributeComplex = attribute => note => isObj(note[attribute])

/**
 * Whether the current attribute is required. Meaning either being {@code} true or
 * {@code MANDATORY}.
 *
 * @param {String} attribute  the attribute
 * @return {function(*): *}
 */
export const isAttributeRequired = attribute => note => note[attribute] === true
  || note[attribute] === MANDATORY

/**
 * Whether the current attribute is visible. Meaning either required or optional.
 *
 * @param {String} attribute  the attribute
 * @return {function(*): *}
 */
export const isAttributeVisible = attribute => note => isAttributeRequired(attribute)(note)
    || note[attribute] === OPTIONAL

/**
 * Returns {@param note}'s {@code mandatory} property.
 *
 * @param {Object} note the current node.
 * @return {Object|Boolean}
 */
export const isNoteRequired = note => isBool(note.mandatory) && note.mandatory

/**
 * Return whether time tracking of {@param note} is required.
 * If the condition is a complex object, return it and let
 * the action logic handle it.
 *
 * @param {Object} note the current node.
 * @return {Object|Boolean}
 */
export const isTimeTrackingRequired = note => note.timeTracking === MANDATORY

/**
 * Return whether time tracking of {@param note} is optional.
 * If the condition is a complex object, return it and let
 * the action logic handle it.
 *
 * @param {Object} note the current node.
 * @return {Object|Boolean}
 */
export const isTimeTrackingOptional = note => note.timeTracking === OPTIONAL

/**
 * Return whether time tracking of {@param note} is visible.
 *
 * @param {Object} note the current node.
 * @return {Object|Boolean}
 */
export const isTimeTrackingVisible = note => isTimeTrackingRequired(note)
    || isTimeTrackingOptional(note)

/**
 * Ticket Note Types Collection.
 *
 * Contains static list of available ticket's message types, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 *
 * Please note: Every note type has a {@code mandatory} and {@code timeTracking} property
 * set up to configure their respective feature. However, they are not used with every
 * note type at the moment. For completeness’s sake (and possible future usage) however,
 * they are still present.
 *
 * mandatory:
 * - {@code true}: Note will be mandatory
 * - {@code false}: Note will be optional
 * - {@code Object}: Will return {@code onSuccess} property of the object if all properties inside
 * {@code rules} (each being a predicate) return true. Otherwise, will return {@code onFail}.
 * See ticket's {@link note} hook for more.
 *   @example
      mandatory: {
      onSuccess: OPTIONAL,
      onFail: DISABLED,
      rules: {
        status: [50],
        assignee: true,
      }
      },
 *
 * timeTracking:
 * - {@code DISABLED}: Will not be displayed
 * - {@code OPTIONAL}: Will be displayed, but not validated
 * - {@code MANDATORY}: Will be displayed and validated (with {@link isTimeEntry})
 * - {@code Object}: Same as above.
 */
export default [
  // normal types
  {
    key: 'blank',
    value: 'Blank note',
    color: '',
    selectable: true,
    mandatory: false,
    timeTracking: OPTIONAL,
  },
  {
    key: 'processingStep',
    value: 'Processing Step',
    color: '',
    selectable: true,
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: DISABLED,
  },
  {
    key: 'birth',
    value: 'Birth note',
    color: 'primary',
    selectable: false,
    mandatory: false,
    timeTracking: DISABLED,
    startDate: DISABLED,
  },
  {
    key: 'hotline',
    value: 'Hotline',
    color: 'warning',
    mandatory: false,
    timeTracking: DISABLED,
    startDate: DISABLED,
  },
  // synthetic types
  {
    key: 'timeEntry',
    value: 'Time entry',
    color: '',
    selectable: false,
    mandatory: true,
    timeTracking: MANDATORY,
    startDate: MANDATORY,
  },
  // quick action types
  {
    key: 'forward',
    value: 'Forwarded to {team}',
    color: 'primary',
    mandatory: false,
    timeTracking: {
      onFail: DISABLED,
      onSuccess: OPTIONAL,
      rules: {
        status: [50],
        assignee: true, // I am assignee
      },
    },
    startDate: DISABLED,
  },
  {
    key: 'assign',
    value: 'Assigned to {assignee}',
    color: 'primary',
    mandatory: false,
    timeTracking: {
      onFail: DISABLED,
      onSuccess: OPTIONAL,
      rules: {
        status: [50],
        assignee: true, // I am assignee
      },
    },
    startDate: DISABLED,
  },
  {
    key: 'unassign',
    value: 'Unassigned from {assignee}',
    color: 'primary',
    mandatory: false,
    timeTracking: {
      onFail: DISABLED,
      onSuccess: OPTIONAL,
      rules: {
        status: [50],
        assignee: true, // I am assignee
      },
    },
    startDate: DISABLED,
  },
  {
    key: 'postpone',
    value: 'Postponed until {date}',
    color: 'warning',
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: DISABLED,
  },
  {
    key: 'postpone_customer',
    value: 'Waiting for customer until {date}',
    color: 'warning',
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: DISABLED,
  },
  {
    key: 'postpone_third_party',
    value: 'Waiting for third party until {date}',
    color: 'warning',
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: DISABLED,
  },
  {
    key: 'close_resolve',
    value: 'Issue resolved',
    color: 'success',
    mandatory: true,
    timeTracking: MANDATORY,
    startDate: DISABLED,
  },
  {
    key: 'close_cancel',
    value: 'Issue cancelled',
    color: 'success',
    mandatory: true,
    timeTracking: MANDATORY,
    startDate: DISABLED,
  },
  {
    key: 'reopen',
    value: 'Issue reopened',
    color: 'primary',
    mandatory: false,
    timeTracking: DISABLED,
    startDate: DISABLED,
  },
  {
    key: 'stop',
    value: 'Stop',
    color: 'disabled',
    mandatory: false,
    timeTracking: OPTIONAL,
    startDate: DISABLED,
  },
]
