import noop from '@gaia/util/noop/action'
import index from './action/index'
import validateRedirect from './action/validateRedirect'
import contact from './action/contact'
import reset from './action/reset'
import confirm from './action/password/confirm'
import forgotPassword from './action/password/forgot'
import resetPassword from './action/password/reset'
import trouble from './action/trouble'
import support from './action/contact/support'
import validateLogin from './action/validateLogin'

export default {
  index,
  contact, // renders the initial ui
  contactSuccess: noop,
  support, // makes the contact call
  validateRedirect,
  validateLogin,
  reset,
  forgotPassword,
  confirm,
  resetPassword,
  change: noop, // renders the initial ui
  changeSuccess: noop,
  changeError: noop,
  trouble,
}
