/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Opens the current models {@code key} as a PDF in a new tab.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 * @returns {Promise<void>}
 */
const pdf = async (module, component, event) => {
  const model = module[G.MODEL]
  const { version, api } = model[G.PROPS]
  const key = model[G.STATE][G.REF]

  const url = `/api/v${version}/${api}/${key}/pdf`

  module[G.ADAPTER][G.ROUTER].newTab(url)
}

export default curry(pdf)
