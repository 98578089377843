/* global G */

import { setKey } from 'lib/util'

/**
 * Clear Model Hook
 *
 * Clears the model's cached data (e.g. so that hook/model/read fetches it again).
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): *[]}
 */
const clear = obj => (...args) => {
  const model = obj[G.MODEL]
  model[G.CACHE] && setKey(null, G.CACHE, model)
  return args
}

export default clear
