/* eslint-disable object-curly-newline */
/* global G */
import { curry, getKey, pipe, setKey } from 'lib/util'
import { withDependencyCheck } from 'lib/trait/with'
import redirectSequence from '@gaia/sequence/module/adapter/router/redirect'
import routeComposition from '@gaia/trait/composition/route'

const descriptor = 'forceRouteAction'

const _purgeError = (payload) => {
  setKey(false, G.ERROR, payload)
  return payload
}

const _setRoute = curry(
  // eslint-disable-next-line no-undefined
  (value, payload) => setKey(routeComposition(undefined, value), G.ROUTE, payload),
)

/**
 * Force Redirect Event Handler
 *
 * nullifies current module's error
 * executes router redirect sequence
 * atm the key should be provided via options.next || options.prev value
 *
 * @param {String || Symbol} key - key string or symbol identifier
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - event
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const forceRouteAction = async (key, module, component, event) => {
  try {
    // console.log('have route config', component[G.CONFIGURATION].route)
    // console.log('have key', key)
    withDependencyCheck(descriptor, [G.PROPS], component)
    withDependencyCheck(descriptor, [key], component[G.PROPS])

    pipe(
      _purgeError,
      _setRoute(component[G.PROPS][key]),
      redirectSequence(module),
    )(getKey(G.STATE, module))
  } catch (e) {
    console.error(e)
  }
}

export default curry(forceRouteAction)
