/* global G */
import { curry, isArr, setKey } from 'lib/util'

/**
 * Persists incoming value in corresponding component's state.
 *
 * Since there is no need to refresh the ui, it is handled on component level only.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event that triggered this handler
 * @return {Promise<object>}
 */
const persistNameOption = async (module, component, event) => {
  const state = component[G.STATE]
  const value = event.detail.item
  const item = value !== null && isArr(value) ? value[0] : value
  // state.value = item ? [{ value: item.key ? item.value.name : item }] : item
  state.value = item && item.key ? item.value.name : item
}

export default curry(persistNameOption)
