/* global G */
import { modelComposition } from '@gaia/trait/composition'
import { usesGetterSetter } from '@gaia/trait/uses'
import withGetterSetter from '@gaia/trait/with/GetterSetter'
import { pipe } from 'lib/util'
import concat from 'model/_shared/decorator/concat'
import drop from 'model/_shared/transformer/drop'
import fromCountry from 'model/_shared/decorator/fromCountry'
import toCountry from 'model/_shared/transformer/toCountry'

const descriptor = 'model::address'

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    country: fromCountry('country'),
    // TODO: Add decorator that uses intl.address() to get localized address instead
    summary: concat('summary', ['number', 'street', 'state', 'zip', 'city', 'country']),
    summary1: concat('summary1', ['number', 'street', 'state']),
    summary2: concat('summary2', ['zip', 'city', 'country']),
  }),
  usesGetterSetter(G.TRANSFORMER, {
    country: toCountry('country'),
    summary: drop('summary'),
    summary1: drop('summary1'),
    summary2: drop('summary2'),
  }),
)
