import jump from './jump'
import next from './next'
import { confirm, deconfirm } from './confirm'
import { protect, unprotect } from './protect'

const step = {
  jump,
  next,
  confirm,
  deconfirm,
  protect,
  unprotect,
}

export {
  step as default,
  jump,
  next,
  confirm,
  deconfirm,
  protect,
  unprotect,
}
