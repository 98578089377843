import { curry } from 'lib/util'
import { remove as deleteNote } from 'app/_shared/events/note'

/**
 * Event Handler Search Step and Recreate
 *
 * Sets the event detail's value as current search term for the list of the current step and
 * recreates the module, so that it is taken into account the next time the list is rendered.
 *
 * @param {Gaia.Module} module     the current module composition object
 * @param {Gaia.Component} component  the current action's main component
 * @param {object} event      the event object that triggered this handler
 * @return {Promise<void>}
 */
const del = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  try {
    const { key } = event.detail
    await deleteNote(module, component, { key })
  } catch (e) {
    console.error(e)
  }
}

export default curry(del)
