/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

const getAttachments = async (module, component, event) => {
  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { version } = model[G.PROPS]

  const { api: { type: api } } = component[G.PROPS]
  const documentId = module[G.STATE]?.[actionComponent[G.PROPS].tree].key

  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const attUrl = `/api/v${version}/${api}/${documentId}/listing/attachment`

  try {
    const result = await httpAdapter.get({ url: attUrl, params: { limit: 200 } })

    return result.value
  } catch (e) {
    console.error(e)
  }

  return []
}

export default curry(getAttachments)
