/* global G */
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import { withGetterSetterFn } from 'trait/with/GetterSetter'
import canStep from 'trait/can/Step'
import { canHookInto } from 'trait/can'
import appModuleComposition from 'trait/composition/appmodule'
import actions from './actions'
import events from './events'
import hooks from './hooks'

const { ACTIONS, EVENTS } = G

const descriptor = 'module::person'

/**
 * Wizard Module
 *
 * identifies actions as steps via options.step configuration
 * manages step states - active, complete, (todo: optional step)
 * keeps action order
 * todo: devise mechanism to test for step completion,
 *  so we can automate easily identifiable cases, such as has value
 *
 * @param {Gaia.AppModule.Spec} obj - configuration object
 * @return {Gaia.AppModule.Spec} obj - object composition
 */
const composition = obj => pipe(
  appModuleComposition(descriptor),
  usesGetterSetter(ACTIONS, actions),
  usesGetterSetter(EVENTS, events),
  canHookInto(ACTIONS, hooks),
  // usesScopedNamespace(API, api),
  withGetterSetterFn(G.PREV), // string -  prev step descriptor
  withGetterSetterFn(G.NEXT), // string - next step descriptor
  withGetterSetterFn(G.CURRENT), // integer - active step in ordered steps
  // todo: moved to canStep
  // withGetterSetterFn(G.DATA), // [] ordered steps
  // usesGetterSetter(G.DATA, []),
  canStep,
)(obj)

export default composition
