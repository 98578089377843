/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import showDialog from 'app/_shared/events/dialog'

/**
 * Checks if the user currently being edited is active, and if so, requests a password reset
 * and displays a dialog.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 *
 * @return {Promise<*|[{}]>}
 */
const sendPasswordRecovery = async (module, component, event) => {
  // model
  const model = module[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]

  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { username } = sequenceComponentFindProxy(actionComponent)

  try {
  // Checks to see if the new email just entered is a user with a password
    const { version } = model[G.PROPS]

    const url = `/api/v${version}/public/password/forgotten/${get(username)}`
    // disable loader middleware because it makes dialog disappear immediately
    const options = {
      middleware: ({ persistence, error, impersonation }) => [persistence, error, impersonation],
    }
    const result = await httpAdapter.put({ url }, options)

    if (result.status === 'inactive') {
      await showDialog(module, component, {
        title: {
          ns: 'admin',
          key: 'dialog.passwordResetError.title',
          defaultValue: 'User inactive',
        },
        text: {
          ns: 'admin',
          key: 'dialog.passwordResetError.text',
          defaultValue: 'An inactive user cannot reset password',
        },
      })
    } else {
      await showDialog(module, component, {
        title: {
          ns: 'admin',
          key: 'dialog.passwordResetSuccess.title',
          defaultValue: 'Email sent',
        },
        text: {
          ns: 'admin',
          key: 'dialog.passwordResetSuccess.text',
          defaultValue: 'Password recovery email has been sent to user',
        },
      })
    }
  } catch (e) {
    console.error(e)
  }
}

export default curry(sendPasswordRecovery)
