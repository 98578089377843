/**
 * Checks whether the {@param term} is included in any of {@param node.description}s elements.
 *
 * @param {Object} node the current node
 * @param {String} term search term
 * @returns {boolean}
 */
const isInDescriptionArray = (node, term) => (!node?.description
  ? false
  : node.description.some(
    description => description.toLowerCase().indexOf(term.toLowerCase()) !== -1,
  ))

export default isInDescriptionArray
