/* global G */
import { curry } from 'lib/util'
import forceRouteAction from 'app/_shared/events/redirect/force'

/**
 * Event Handler to be used for Data Model - Edit
 *
 * It requires explicit key propagation via UI Component.
 * Sets G.REF in model's state, which will be used by model specific sequences.
 *
 * @type {Gaia.AppModule.EventHandler}
 * @param {object} event.detail - custom event details
 * @param {string} event.detail.key - a key to set to the model's state ref
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const editFn = async (module, component, event) => {
  try {
    const { key } = event.detail
    const model = module[G.MODEL]
    // todo model[G.DATA] NS may be dirty, if previous interactions transpired with the model
    //  shouldn't have any influence? test
    model[G.CACHE] = null
    const state = model[G.STATE]
    state[G.REF] = key
    forceRouteAction('edit')(module, component)({})
  } catch (e) {
    console.error(e)
  }
}

// on edit click, pass get param -> edit: true

export default curry(editFn)
