/* global G */
/* eslint-disable no-unused-vars */
import { curry } from 'lib/util'

/**
 * Return a search query that filters the search to only contain results that have a
 * {@code ref.teamHistory} containing one or more of the user's teams and a
 * {@code ref.team} NOT containing one of them.
 *
 * Semantically speaking, these would be tickets that have been forwarded by the user,
 * but are currently not assigned to one of the user's teams.
 * .
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event|object} event              information about the event that triggered this handler
 * @returns {`team:(${*})`}
 */
const forwardedByOneOfMyTeams = (module, component, event) => {
  const userSession = module[G.ADAPTER][G.SESSION][G.STATE]
  // Lucene requires us to wrap each team in "
  const userTeams = userSession[G.META].refs.team.map(currentTeam => `"${currentTeam.key}"`)

  return `teamHistory:(${userTeams}) AND NOT team:(${userTeams})`
}

export default curry(forwardedByOneOfMyTeams)
