import username from 'model/_shared/validator/userExists'
import password from './password'

export const validators = {
  username,
  password,
}

export {
  validators as default,
  username,
  password,
}
