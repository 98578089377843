/* eslint-disable no-unused-expressions */
/* global G */
import { curry } from '@gaia/util'

const descriptor = 'model::transformer::toBoolean'

/**
 * To Boolean transformer
 *
 * deletes or nulls property, identified by key, from payload
 *
 * @param {String} key                identifier
 * @param {Boolean} [nullable=false]  whether to null the attribute if its value is not set
 * @return {*}
 */
const fn = (key, nullable = false) => curry((obj, data) => {
  console.warn(descriptor)

  const _data = data
  try {
    const item = obj[G.CHILDREN][key]
    const { type } = item[G.PROPS]
    const value = obj[G.STATE]?.[G.DATA]?.value[key]

    value
      ? _data[type][key] = true
      : (!nullable && delete _data[type][key]) || (_data[type][key] = null)
  } catch (e) {
    console.error(descriptor, e)
  }

  return _data
})

export default fn
