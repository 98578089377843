/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import languages from 'model/person/collection/language'
import sublist from 'app/_shared/events/util/sublist'
import { settings } from 'app/_shared/session'

const list = (module, component, event) => languages

/**
 * Translates and returns the list of {@link languages}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Gaia.Component} component    the object composition of the component that triggered the
 *                                      event
 * @param {Gaia.PlatformEvent} event    the event object
 * @return {Array} countries
 */
// eslint-disable-next-line no-unused-vars
const listLanguages = (module, component, event) => {
  const keys = settings.languages?.length ? settings.languages : null

  return sublist(keys, list)(module, component, event).map(item => ({
    key: item.key,
    value: module[G.ADAPTER][G.INTL]._t(
      `language.${item.key}`,
      {
        ns: 'generic',
        _key: `language.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }))
}

export default curry(listLanguages)
