/* global G */
import find from 'lib/sequence/component/children/find'
import { set } from 'lib/sequence/component/state/value'

/**
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
const text = module => component => async (...args) => {
  const ns = module[G.MODEL][G.STATE][G.REF]
  const { content } = find(component)

  await module[G.ADAPTER][G.INTL].add([ns])

  const value = module[G.ADAPTER][G.INTL].markdown('text', {
    ns,
    _key: 'text',
    joinArrays: '\n',
    md: true,
  })

  set(content, value)

  return args
}

export default text
