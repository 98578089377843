/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread, bulk, def, getFirstItem, setKey } from 'lib/util'
import { empty } from 'lib/util/object'
import { actionFn } from 'lib/sequence/module/action'
import map from 'lib/sequence/model/api/map'
import asObject from 'lib/sequence/component/children/asObject'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { checked } from 'lib/sequence/component/state/checked'
import { get, set } from 'lib/sequence/component/state/value'
import { disabled } from 'lib/sequence/component/state/disabled'
import listStatus from 'app/_shared/events/collection/listStatus'
import listInvitationStatuses from 'app/_shared/events/collection/listInvitationStatuses'
import listInvitationRoles from 'app/_shared/events/collection/listInvitationRoles'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Determines whether the {@code toBeValidated} section should be shown based on its data.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {(function(*, *, ...[*]): Promise<*[]>)|*}
 */
const displayToBeValidated = module => component => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const { value: userData } = model[G.CACHE]
  const { toBeValidated: toBeValidatedData } = userData

  // component
  const { toBeValidated } = children

  const { verify } = sequenceComponentFind(component[G.ACTIONS][0])
  console.log(verify)

  // Only showing toBeValidated section if it has content
  def(toBeValidatedData) && !empty(toBeValidatedData)
    ? bulkShow(toBeValidated, verify)
    : bulkHide(toBeValidated, verify)

  return [children, ...args]
}

/**
 * Persists the {@code verify} value into the module state for later usage.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const setVerify = module => component => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const modelState = model[G.STATE]

  // component
  const { verify } = sequenceComponentFind(component[G.ACTIONS][0])

  // Saving the checkbox value into the model state so that it can be picked up be the transformer
  setKey(checked(verify), 'verified', modelState)

  return [children, ...args]
}

/**
 * Determines what parts of the invitation section should be shown based on its {@code status} and
 * {@code role}.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(*, ...[*]): Promise<*[]|*>}
 */
const displayInvite = module => component => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const { value: userData } = model[G.CACHE]
  const { invite: inviteModel } = model[G.CHILDREN]
  const { contactChannels } = userData
  const email = contactChannels?.find(channel => channel.type === 'email')

  /**
   * Hide invite section per default, only show it if
   * two things are true:
   * 1) person has no user
   * 2) person has no pending invite
   */

  // User has no email, early return.
  if (!email) return [children, ...args]

  // component
  const {
    role,
    invite,
    invitedBy,
    inviteInfo,
    inviteForm,
    expiration,
    // status: inviteStatusField,
  } = children

  const { toBeInvited } = sequenceComponentFind(component[G.ACTIONS][0])

  const { status: inviteStatusField } = asObject(inviteInfo[G.CHILDREN])

  map(inviteModel)(inviteInfo)

  /**
   * Hide invite section per default, only show it if
   * two things are true:
   * 1) person has no user
   * 2) person has no pending invite
   */
  bulkHide(invite, inviteInfo, inviteForm, toBeInvited)

  // Getting the users invitation status
  const inviteStatus = userData?.invite?.status
    ? getFirstItem(listInvitationStatuses(module, component, userData.invite.status))
    : {}

  try {
    const url = `/api/v1/public/accountExists/${email.value}`
    await model[G.ADAPTER][G.HTTP].get({ url })
  } catch (e) {
    /**
     * If an exception is thrown, it means that there is no user for the given email. If so, also
     * make sure the person doesn't have a pending invite, then show the checkbox.
     */
    // user has been invited before
    !empty(inviteStatus)
      ? hide(toBeInvited)
        && bulkShow(invite, inviteInfo)
      : show(toBeInvited)
        && bulkHide(invite, inviteInfo)
        && await actionFn(module[G.ACTIONS].newContact)()
  }

  if (!hidden(inviteInfo)) {
    const invitationRole = getFirstItem(listInvitationRoles(module, component, get(role)))?.value
    const { inviterFirstname, inviterLastname } = userData?.invite || {}
    const inviterName = inviterFirstname && inviterLastname
      ? `${inviterFirstname} ${inviterLastname}`
      : '-'

    set(role, invitationRole || get(role))
    set(inviteStatusField, inviteStatus?.value || get(inviteStatusField))
    set(expiration, model[G.ADAPTER][G.INTL].getLocaleDate(get(expiration)))
    set(invitedBy, inviterName)
  }

  return [children, ...args]
}

/**
 * Preset the {@code status} field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetStatus = module => async (children, ...args) => {
  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { status: statusField } = children

  const status = getFirstItem(listStatus(module, actionComponent, get(statusField)))

  // Only set the field if it's editable
  status
  && !hidden(statusField)
  && !disabled(statusField)
  && set(statusField, status)

  return args
}

/**
 * Person edit action
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  displayToBeValidated(module)(component),
  displayInvite(module)(component),
  setVerify(module)(component),
  presetStatus(module),
)(sequenceComponentFind(component), ...args)
