/* global G */
import { curry, setKey } from 'lib/util'
import setStepTab, { CONFIRM } from 'app/_shared/events/setStepTab'
import refresh from 'lib/sequence/model/api/refresh'

/**
 * Attempts to obtain the organisation with key {@param event.detail.key} and sets it as the
 * installedAt property of the current model.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @param {object} event.detail         custom event details
 * @param {string} event.detail.key     the key of the organisation to set
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const setLocation = async (module, component, event) => {
  const { item } = event.detail
  const model = module[G.MODEL]
  const { installedAt } = model[G.CHILDREN]
  // refreshing model's cache with data
  await refresh(installedAt, item)
  model[G.DATA].installedAt = [installedAt[G.CACHE]]
  // setting action's dirty flag
  setKey(true, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
  // executing current action again
  setStepTab(CONFIRM)(module, component, event)
}
export default curry(setLocation)
