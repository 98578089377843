/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import { get, set } from 'lib/sequence/component/state/value'
import session from 'app/_shared/session'
import listTicketFilterAssignee from 'app/_shared/events/collection/listTicketFilterAssignee'
import index from 'app/ticket/action/list/index'

/**
 * ServiceItem editing and creation.
 *
 * Toggles the visibility and mapping of fields depending on whether the service item should just be
 * saved for a ticket or as an individual entity in the database and whether there is already an
 * entity selected.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const { filter } = asObject(component[G.CHILDREN])
  const {
    status: statusField,
    team: teamField,
    assignee: assigneeField,
  } = asObject(filter[G.CHILDREN])

  // assignee defaults to "not assigned"
  const assigneeSelection = listTicketFilterAssignee(module, component, null)
  const option = assigneeSelection[0]
  !get(assigneeField) && set(assigneeField, [option])

  // team
  const userTeam = session(module).team.ref({ wrap: true })
  !get(teamField) && set(teamField, userTeam)

  // status
  !get(statusField) && set(statusField, 1)

  await index(module)(component)(args)

  return args
}
