/* global G */
import { curry } from '@gaia/util'
import persistOptions from 'app/_shared/events/persist/options'
import { actionWithHooks } from 'lib/sequence/module/action'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Event Handler Select and Action
 *
 * It executes persistOptions event handler,
 * followed by module action sequence actionWithHooks()
 *
 * It used in cases, where you want to reapply changes to UI, based on provided selection
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>}
 */
const resetAndAction = async (module, component, event) => {
  try {
    const { ref } = component[G.PROPS]
    const { [ref]: componentToReset } = asObject(module[G.STATE][G.ACTION][G.COMPONENT][G.CHILDREN])
    persistOptions(module, componentToReset, event, [])
    // call same action, see side effects
    await actionWithHooks(module[G.STATE][G.ACTION])([])
  } catch (e) {
    console.error(e)
  }
}

export default curry(resetAndAction)
