/* global G */
import { PlatformEvent } from 'lib/util'

const descriptor = Symbol('hook::module::search').toString()

/**
 * Search Hook
 *
 * Configures the {@link SearchField}, which should be available in the AppBar component, by
 * attaching the onSearch event handler as a new property of the action.ui.search object.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): Promise<*[]>}
 */
const search = obj => async (...args) => {
  try {
    const userContext = obj[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT]
    const action = obj[G.STATE][G.ACTION]
    const actionUI = obj[G.STATE][G.ACTION][G.UI]
    const givenContexts = actionUI.search?.contexts
    const cache = action[G.CACHE].search?._cache
    const { term, transform } = cache || {}
    const value = term || null

    if (givenContexts && !givenContexts.includes(userContext)) {
      delete actionUI.search
    } else {
      const searchEvents = obj[G.EVENTS].onSearch
      const onSearch = searchEvents[Object.keys(searchEvents)[0]] // Using first event handler
      const component = obj[G.STATE][G.ACTION][G.COMPONENT]
      const onSearchPartial = onSearch(obj, component)
      actionUI.search = { ...actionUI.search, onSearch: onSearchPartial, value, transform }
      // if the action's search has data cached (e.g. through the remember event handler), we need
      // to trigger a search event with it, so that the application updates its content accordingly
      cache && onSearchPartial(new PlatformEvent('search', { term: value, transform, reload: false }))
    }
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }
  return args
}

export default search
