/* global G */
import { curry } from '@gaia/util'

/**
 * refresh current set of messages
 */
const messagesFn = async (module, component, event) => {
  const obj = module[G.MODEL]

  // Do we need to hardcode this? It's different from the model's api!
  const api = 'message'
  const { version } = obj[G.PROPS]
  const messageId = event.id
  const url = `/api/v${version}/${api}/${messageId}`

  try {
    await obj[G.ADAPTER][G.HTTP].get({ url }).then((message) => {
      module[G.ADAPTER][G.EVENTS].dispatch(
        module[G.ADAPTER][G.EVENTS].type(G.MESSAGE, G.ADD),
        { [G.DATA]: message },
      )

      /**
       * Setting a temporary flag that there are unread messages
       * only if we receive a new message and the document
       * doesn't have focus.
       */
      !module[G.ADAPTER][G.UI].hasFocus() && (obj[G.STATE][G.MESSAGE] = true)
    })
  } catch (e) {
    console.error(e)
  }
}

export default curry(messagesFn)
