/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import routeComposition from '@gaia/trait/composition/route'

/**
 * Bulk Event Handler
 *
 * It executes module action bulk() by "Fire-N-Forget"
 *
 * @param {String} key                      name of the action to call
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.AppModule.Action} component action component
 * @param {Event} event                     event
 * @return {Promise<void>} void
 */
const bulk = async (key, module, component, event) => {
  event.preventDefault()
  event.stopPropagation()
  const moduleState = module[G.STATE]
  const currentRoute = moduleState[G.ROUTE]
  try {
    const { route } = component[G.CONFIGURATION]
    const { api: { url: endpoint } = {} } = component[G.PROPS]

    // eslint-disable-next-line no-unused-expressions
    !route && throw ReferenceError(`No route defined in component configuration ${component._name}`)

    moduleState[G.ROUTE] = routeComposition(route.module, route.action)
    await actionWithHooks(module[G.ACTIONS][key])(endpoint ? { endpoint } : {})
  } catch (e) {
    console.error(e)
    moduleState[G.ROUTE] = currentRoute
  }
}

/**
 * Admin Bulk Event Handler
 *
 * It executes module action admBulk() by "Fire-N-Forget"
 *
 * @type {(function(...[*]): (*))|*}
 */
export const bulkByKey = curry(
  (key, module, component, event) => bulk(key, module, component, event),
)

export default curry((module, component, event) => bulk('bulk', module, component, event))
