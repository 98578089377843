/* global G */
import { curry } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { setDetailUndoKey } from 'app/ticket/action/detail'

/**
 * Event Handler New Note
 *
 * Shows the new note form.
 *
 * @param {object} module     the current module composition object
 * @param {object} component  the current action's main component
 * @param {object} event      the event object that triggered this handler
 * @return {Promise<void>}
 */

const newNote = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()
  // const type = event.detail.value
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { notes } = asObject(actionComponent[G.CHILDREN])
  const { addNoteBtn, form } = find(notes)
  // const formState = form[G.STATE]
  //
  // formState.type = type
  hide(addNoteBtn)
  show(form)

  setDetailUndoKey(module, 'note')

  await module[G.ADAPTER][G.UI].update(module)
}

export default curry(newNote)
