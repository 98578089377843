/* eslint-disable no-unused-vars */
/* global G */
import { curry, def } from 'lib/util'
import find from 'lib/sequence/component/children/find'

/**
 * Sets the initial value of the first element found with key {@param key} (previously stored, e.g.
 * with the saveInitialState hook) as its current value.
 *
 * @param {string} key                  the key of the element to which to restore its initial value
 * @param {boolean} isAction            whether the component is to be found in component actions
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component} component    the component that invoked the event
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>} void
 */
const restoreInitialState = async (key, isAction, module, component, event) => {
  const action = module[G.STATE][G.ACTION]
  const actionComponent = action[G.COMPONENT]
  const actionData = action[G.STATE][G.DATA]

  if (actionData) {
    const target = isAction ? actionComponent[G.ACTIONS][0] : actionComponent
    const { [key]: element } = find(target)
    Object.keys(element[G.STATE]).forEach((propName) => {
      element[G.STATE][propName] = actionData[key][propName]
    })
    delete actionData[key]
  }
}

export default curry(restoreInitialState)
