import notEmptyOneOrTarget from './notEmptyOneOrTarget'

export const validators = {
  parent: notEmptyOneOrTarget,
  supportedBy: notEmptyOneOrTarget,
}

export {
  validators as default,
  notEmptyOneOrTarget,
}
