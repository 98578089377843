/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Event Handler Step Next.
 *
 * Adds one to the value of the activeStep property of the stepper used as child of the current
 * action component, then calls the current module's action.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>}
 */
export default curry(async (module, component, event) => {
  try {
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { stepper } = asObject(actionComponent[G.CHILDREN])
    const stepperState = stepper[G.STATE]
    const { activeStep = 0 } = stepperState
    const nextStep = activeStep + 1

    const steps = stepper[G.CHILDREN]
    const visibleSteps = steps.filter((step) => {
      const { hidden } = { ...step[G.PROPS], ...step[G.STATE] }
      return !hidden
    })

    const stepsCount = visibleSteps.slice(nextStep).findIndex((step) => {
      const stepState = step[G.STATE]
      return !stepState.confirmed
    })

    // go directly to first next uncompleted step (or to the last one)
    stepperState.activeStep = stepsCount > -1 ? nextStep + stepsCount : visibleSteps.length - 1
    // call same action, see side effects
    await actionWithHooks(module[G.STATE][G.ACTION])([])
  } catch (e) {
    console.error(e)
  }
})
