/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import cancelOnError from 'lib/sequence/model/cancelOnError'

const descriptor = 'check'

/**
 * Purge Util Event Handler
 *
 * Clears the current module's error state.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event that triggered this handler
 * @return {Promise<object>}
 */
export default curry(async (module, component, event) => cancelOnError(descriptor, module))
