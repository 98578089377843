/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import map from 'lib/sequence/model/api/map'
import { bulk } from 'lib/util'
import { get } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'

/**
 * Request description modal section.
 *
 * @param module
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const { issue } = asObject(component[G.CHILDREN])
  const { deviceInfo } = asObject(issue[G.CHILDREN])
  const bulkMapModel = bulk(map(model))

  // Mapping description.text -> issue.description.text
  bulkMapModel(issue)

  // Mapping description.device* -> issue.deviceInfo.device*
  const { description } = model[G.CHILDREN]
  map(description)(deviceInfo)

  // Mapping deadOnArrival checkbox
  const { deviceMalfunction } = description[G.CHILDREN]
  const {
    deviceMalfunction: deviceMalfunctionField,
    deviceFirmwareVersion: deviceFirmwareVersionField,
    pcSoftwareProgram: pcSoftwareProgramField,
    pcSoftwareVersion: pcSoftwareVersionField,
  } = asObject(deviceInfo[G.CHILDREN])

  deviceMalfunctionField[G.STATE].checked = deviceMalfunction[G.CACHE]

  const toggle = field => (!get(field) ? hide(field) : show(field))
  const bulkToggle = bulk(toggle)

  bulkToggle(
    deviceMalfunctionField,
    deviceFirmwareVersionField,
    pcSoftwareProgramField,
    pcSoftwareVersionField,
  )

  !get(deviceMalfunctionField)
    && !get(deviceFirmwareVersionField)
    && !get(pcSoftwareVersionField)
    && !get(pcSoftwareProgramField)
    && hide(deviceInfo)

  return args
}
