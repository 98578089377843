/* eslint-disable no-unused-vars */
/* global G */
import { curry, getFirstItem } from 'lib/util'
import listContactChannelTypes from 'app/_shared/events/collection/listContactChannelTypes'

/**
 * Event Handler Get Request Information
 *
 * Gets information about the corresponding request for the current ticket alongside the
 * {@code requesterContact}'s contact channels.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 */
const getRequestInformation = (module, component, event) => {
  const model = module[G.MODEL]
  const { request, requesterContactData: requesterContact } = model[G.CHILDREN]
  const contactChannelTypes = listContactChannelTypes(module, component, null)
  const requestData = request[G.CACHE]
  const requesterContactData = requesterContact[G.CACHE]

  return {
    request: getFirstItem(requestData),
    requesterContact: requesterContactData,
    channelLabels: contactChannelTypes,
  }
}

export default curry(getRequestInformation)
