import regExp from './regExp'

/**
 * PPT Validator
 * @src apps/puppeteer/src/modules/fields/validators.js
 * @type {RegExp}
 */
// eslint-disable-next-line max-len
const re = /^$|^undefined$|^null$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 *
 * Validator - Is Email
 *
 * @param {string} value - value to validate
 * @param {object} options - validator options
 * @return {boolean}
 */
export default regExp(re)
