/* eslint-disable no-unused-expressions */
/* global G */

import asObject from 'lib/sequence/component/children/asObject'
import { reset } from 'lib/sequence/component/state/value'
import map from 'lib/sequence/model/api/map'
import { CONFIRM, SEARCH } from 'app/_shared/events/setStepTab'

/**
 * Device location step action.
 *
 * Handles the display and status of the step's elements.
 *
 * It lets the user select a location from a list or create a new one by filling a form.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const { installedAt } = model[G.CHILDREN]
  const stepState = component[G.STATE]
  const { search, confirm } = asObject(component[G.CHILDREN])
  const { searchField } = asObject(search[G.CHILDREN])
  const { location } = asObject(confirm[G.CHILDREN])

  if (installedAt[G.CACHE]) {
    const { name, address } = installedAt[G.CHILDREN]
    // clearing search field
    reset(searchField)
    // mapping selected model to the view
    map(installedAt)(location)
    stepState.title = name[G.CACHE]
    stepState.subtitle = Object.values(address[G.CACHE]).join(' ')
    stepState.completed = true
    stepState.activeTab = CONFIRM
  } else {
    stepState.title = ''
    stepState.subtitle = ''
    stepState.completed = false
    stepState.confirmed = false
    stepState.activeTab = SEARCH
  }

  return args
}
