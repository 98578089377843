/* eslint-disable no-unused-expressions,no-unused-vars,no-nested-ternary */
/* global G */
import { asyncPipeSpread, bulk, getFirstItem } from 'lib/util'
import { empty } from 'lib/util/object'
import { getReadableDate } from 'lib/util/date'
import asObject from 'lib/sequence/component/children/asObject'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'
import { get, set } from 'lib/sequence/component/state/value'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import getUserRole from 'app/_shared/events/user/role'
import listInvitationStatuses from 'app/_shared/events/collection/listInvitationStatuses'
import listInvitationRoles from 'app/_shared/events/collection/listInvitationRoles'
// TODO: move to _shared:
import { presetApprovalStatus } from 'app/organisation/action/detail'

const display = x => (get(x) ? show(x) : hide(x))
const bulkDisplay = bulk(display)

/**
 * Get the roles for a given person.
 *
 * @param {String} key                      key of the person
 * @param {Gaia.AppModule.Spec} module         the current module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event} event
 * @returns {Promise<*|*[]>}
 * @private
 */
export const _getUserRoles = async (key, module, component, event) => {
  const model = module[G.MODEL]
  const { version, api } = model[G.PROPS]
  const httpAdapter = model[G.ADAPTER][G.HTTP]

  // Using count route, because it returns the item in case count === 1, and in this case it can
  // either be 0 or 1.
  const url = `/api/v${version}/${api}/${key}/count/user`
  const response = await httpAdapter.get({ url })

  return response?.person?.data?.roles || []
}

/**
 * Maps certain properties.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const mapProperties = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const bulkMap = bulk(map(model))

  // component
  const { personHeader, personInfo, personDetails, info } = children

  bulkMap(personHeader, personInfo, personDetails, info)

  return [children, ...args]
}

/**
 * Presets the user role.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const presetRole = module => component => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const userRoles = model[G.CACHE].value?.count?.user?.person?.data?.roles
      || await _getUserRoles(model[G.STATE][G.REF], module, component, null)

  const userRoleObject = getUserRole(userRoles, module, component, null)
  const userRole = userRoleObject?.value

  // component
  const { personInfo } = children

  const { role: userRoleField } = asObject(personInfo[G.CHILDREN])
  userRole && show(userRoleField) && set(userRoleField, userRole)
  userRoleObject?.key === 'Requester' && hide(userRoleField)

  return [children, ...args]
}

/**
 * Presets and displays certain props regarding invitation status.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const presetInvite = module => component => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const inviteStatusCache = model[G.CHILDREN].invite?.[G.CACHE]?.status

  // component
  const { status, invite, expiration, role } = children

  // Getting the users invitation status
  const inviteStatus = inviteStatusCache
    ? getFirstItem(listInvitationStatuses(module, component, inviteStatusCache))
    : {}

  // Getting the users invitation role
  const invitationRole = getFirstItem(
    await listInvitationRoles(module, component, get(role)),
  )?.value

  // Determine whether we show the validation description & status
  const showInvitationStatus = inviteStatus && inviteStatus.showStatus
  const showInvitationDescription = invitationRole && inviteStatus && inviteStatus.showDescription

  // Displaying invitation status (badge)
  showInvitationStatus ? show(status) : hide(status)

  // Displaying invitation description
  showInvitationDescription
    ? show(invite)
      && set(expiration, getReadableDate(get(expiration), true))
      && set(role, invitationRole)
    : hide(invite)

  return [children, ...args]
}

/**
 * Determines whether certain properties in the property box should be displayed or not and hides
 * the box altogether if there are no properties to display.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayProperties = module => async (children, ...args) => {
  // component
  const {
    businessRole,
    phone,
    officePhone,
    email,
    fax,
    website,
    personDetails,
    detailsContainer,
  } = children

  bulkDisplay(businessRole, phone, officePhone, email, fax, website)

  // If the user has no information to be shown in the box, hide it
  const hasNoInformation = personDetails[G.CHILDREN].every(field => !field[G.STATE].value)
  hasNoInformation && hide(detailsContainer)

  return [children, ...args]
}

/**
 * Determines whether the validation status should be displayed or not.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayToBeValidated = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const validationCache = model[G.CHILDREN].toBeValidated?.[G.CACHE]
  const { reason = null } = validationCache || {}

  // component
  const { toBeValidated, validation, invite, info, status, avatarInfo, approval } = children

  // Hide toBeValidated section if no reason
  empty(reason) && hide(toBeValidated)

  // Hide toBeValidated status badge if no validation
  empty(validationCache) && hide(validation)

  // If both invite and toBeValidated are hidden, hide the whole section
  hidden(toBeValidated) && hidden(invite) && hide(info)

  // If all badges are hidden, display the avatar, otherwise hide it
  hidden(validation) && hidden(status) && hidden(approval)
    ? show(avatarInfo)
    : hide(avatarInfo)

  return [children, ...args]
}

/**
 * Person Action Detail
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  mapProperties(module),
  presetRole(module)(component),
  presetInvite(module)(component),
  presetApprovalStatus(module)(component)('organisation'),
  displayProperties(module),
  displayToBeValidated(module),
)(sequenceComponentFind(component), ...args)
