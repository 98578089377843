/* global G */
import { curry, isObj } from 'lib/util'
import { list } from 'app/_shared/events'

/**
 * Calls {@link list} and filters its result according to the status property declared in the
 * {@param component} options, which can be a single value or an object containing a min and a max
 * properties.
 *
 * @returns {Gaia.AppModule.EventHandler}
 */
const listWithStatus = async (type, module, component, event) => {
  const items = await list(type, module, component, event)
  const { status } = component[G.PROPS]
  const min = isObj(status) ? status.min || 0 : status
  const max = isObj(status) ? status.max || 100 : status
  const [firstItem] = items

  return !firstItem || (firstItem.key === -1)
    ? items
    : items.filter(item => item.value.status >= min && item.value.status <= max)
}

export default curry(listWithStatus)
