/* eslint-disable no-restricted-globals,no-alert,object-curly-newline */
/* global G */
import { curry } from 'lib/util'
import routeComposition from 'trait/composition/route'
import persistCheckAndAction from 'app/_shared/events/combined/persistCheckAndAction'

/**
 * Shows a dialog informing the user that a device can be modified somewhere else.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {function} okHandler          a function to be executed if the user clicks 'ok'
 */
const showUpdateDeviceDialog = async (module, okHandler) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: await module[G.ADAPTER][G.INTL]._t(
      'dialog.updateDevice.title',
      {
        ns: 'device',
        _key: 'dialog.updateDevice.title',
        defaultValue: 'Update device',
      },
    ),
    text: await module[G.ADAPTER][G.INTL]._t(
      'dialog.updateDevice.text',
      {
        ns: 'device',
        _key: 'dialog.updateDevice.text',
        defaultValue: 'Updating device information might imply assignment and visibility issues. If '
                      + 'you want to do that, please open the device in a new tab and follow the '
                      + 'instructions. Then reselect the device in the request',
      },
    ),
    children: {
      cancel: {
        key: 'cancel',
        value: await module[G.ADAPTER][G.INTL]._t(
          'button.cancel',
          {
            ns: 'common',
            _key: 'button.cancel',
            defaultValue: 'cancel',
          },
        ),
      },
      ok: {
        key: 'ok',
        value: await module[G.ADAPTER][G.INTL]._t(
          'button.openInNewTab',
          {
            ns: 'device',
            _key: 'button.openInNewTab',
            defaultValue: 'Open device in new tab',
          },
        ),
      },
    },
    okHandler,
  })
}

/**
 * Event Handler Register At Requester Org
 *
 * Displays a dialog whenever the register as installed at [requesterOrg] is checked and the current
 * item exists.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @return {Promise<void>}
 */
const registerAsInstalledAt = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const { checked } = event.detail
  const model = module[G.MODEL]
  const { item } = model[G.CHILDREN]

  checked && item[G.STATE][G.REF] && await showUpdateDeviceDialog(module, () => {
    const route = routeComposition('serviceItem', 'detail', item[G.STATE][G.REF])
    module[G.ADAPTER][G.ROUTER].newTab(route)
  })

  await persistCheckAndAction(module, component, event)
}

export default curry(registerAsInstalledAt)
