/**
 * Decision Option type definition
 *
 * @typedef {Object} DecisionOption
 * @property {String} key           key of the generic option
 * @property {String} value         value of the generic option
 */

/**
 * Decision Options Collection.
 *
 * Contains static list of available generic options that should be concatenated to another list
 * of options. E.g. ticket types, teams, ...
 *
 * @returns DecisionOption[]
 */
export default [
  {
    key: 0,
    value: 'No',
  },
  {
    key: 1,
    value: 'Yes',
  },
]
