/* global G */
import { curry } from '@gaia/util'

const descriptor = 'model::transformer::extractRef'

/**
 * Transformer Extract Ref
 *
 * Extracts Ref from Referenced Model Attribute
 *
 * it is expected, that the referenced sub-model will have the same attribute
 * as the data model. ie organisation.address - person.address
 *
 * @param ref - data model ref, where the extracted data should be copied to
 * @param key - data model ref, that contains the data to be copied
 * @param obj - data model
 * @param data - data aggregator, expected obj[G.STATE][G.DATA]
 * @return {*}
 */
const extractRef = (ref, key, obj, data) => {
  const _data = data
  try {
    const referencedModelAttribute = obj[G.CHILDREN][key][G.CHILDREN][ref]
    const item = obj[G.CHILDREN][ref]
    const { type } = item[G.PROPS]
    _data[type][ref] = referencedModelAttribute[G.STATE][G.REF]
      // this is good, if it's selected, if created this BLOWS UP
      ? [referencedModelAttribute[G.STATE][G.REF]]
      : []
  } catch (e) {
    console.error(descriptor, e)
  }
  return _data
}

export default curry(extractRef)
