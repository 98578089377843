/* global G */
import { curry, isArr } from 'lib/util'
import refToExistingRef from 'model/_shared/transformer/refToExistingRef'

const descriptor = 'model::serviceitem::transformer::toMaintainedBy'

/**
 * ToMaintainedBy Transformer
 *
 * it first executes {@link refToExistingRef} for the given {@param key}. However, if the user id
 * is already present in the ref, the transformer won't touch the property in the payload. But
 * because we have no form field for {@code maintainedBy}, we don't decorate it, and the default
 * payload in {@code _data} is {@code [{ key: "..." }]} instead of {@code ["..."]}. So we need to
 * sanitize is here again to account for that.
 *
 * @param {string} key - key, expected to be provisioned in composition
 * @param {Gaia.Model.Spec} obj - model, provided by sequence call
 * @param {object} data - data, provided by sequence call
 * @return {*}
 */
const fn = (key, obj, data) => {
  console.warn(descriptor, key)

  const _data = data
  const item = obj[G.CHILDREN][key]
  const { type } = item[G.PROPS]

  try {
    const _transformed = refToExistingRef(key, obj, data)
    const transformedValue = _transformed[type][key]

    if (transformedValue === null) {
      delete _data[type][key]
    } else {
      _data[type][key] = isArr(transformedValue)
        ? transformedValue.map(ref => ref?.key ?? ref)
        : transformedValue
    }
  } catch (e) {
    console.warn(descriptor, obj._name, key, e.message)
  }

  return _data
}

export default curry(fn)
