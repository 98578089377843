/* global G */
import validateRedirect from 'app/registration/action/validateRedirect'
import sequenceModuleLogin from 'lib/sequence/module/login'

/**
 * Register Login Action
 *
 * This is a pure business logic action, it has no UI
 *
 * Uses {@link sequenceModuleLogin} to log in the user
 * and set the appropriate data on the client.
 *
 * After having stored the user's data, checks whether they have more than one context available.
 * Then, either redirects them to the context selection action or grants them access with their
 * unique context.
 *
 * @param {Gaia.AppModule.Spec} module - application module
 * @return {function(*=): function(...[*]): Promise<boolean|*[]>}
 */
const action = module => component => async (...args) => {
  try {
    await sequenceModuleLogin(module)()
  } catch (e) {
    console.warn(e)
    const moduleState = module[G.STATE]
    const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]
    sessionState[G.ERROR] = true
    moduleState[G.ERROR] = true
  }

  return validateRedirect(module)(component)(...args)
}

export default action
