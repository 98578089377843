/* global G */
import { curry, deleteKey } from '@gaia/util'
import { actionWithHooks } from 'lib/sequence/module/action';

/**
 * refresh current data set
 */
export default curry((module, component) => async () => {
  const currentAction = module[G.ADAPTER][G.SESSION][G.STATE][G.MODULE][G.STATE][G.ACTION]._name
  // do not auto reload, if component is out of focus
  if (component._name !== currentAction || module[G.STATE][G.UPDATE]) {
    return
  }

  // do not auto reload, if we ourselves changed the document
  if (module[G.MODEL][G.STATE]?.[G.UPDATE]) {
    deleteKey(G.UPDATE, module[G.MODEL][G.STATE])
    return
  }

  /**
   * Don't redirect using the router. This causes
   * all kinds of problems (SP-487, SP-478).
   * Just reload the action instead.
   */
  await actionWithHooks(module[G.STATE][G.ACTION])({})

  // Disabled for now (GAIA-988)
  // module[G.ADAPTER][G.EVENTS].dispatch(
  //   module[G.ADAPTER][G.EVENTS].type(G.DATA, G.UPDATE),
  //   {
  //     message: module[G.ADAPTER][G.INTL]._t(
  //       'notification.updated',
  //       { ns: 'common', _key: 'data.update', defaultValue: 'Document was updated remotely' },
  //     ),
  //     severity: 'info',
  //   },
  // )
})
