/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Event Handler Action
 *
 * Calls the current action again.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event that triggered this handler
 * @return {Promise<object>}
 */
const index = async (module, component, event) => {
  try {
    await actionWithHooks(module[G.STATE][G.ACTION])([])
  } catch (e) {
    console.error(e)
  }
}

export default curry(index)
