/* global G */
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import validateRedirect from './validateRedirect'

/**
 * verify Action
 *
 * Parses the id and token from the email link
 * and creates the corresponding GET and PUT requests to the server,
 * depending on the response, redirects to the appropriate action
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {Promise<void>}
 */
const _verify = async (module) => {
  const moduleState = module[G.STATE]
  const model = module[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const { version } = model[G.PROPS]
  const [id, token] = module[G.ADAPTER][G.SESSION][G.STATE][G.REF].split('/')

  const url = `/api/v${version}/public/register/${id}`
  const params = {
    token,
  }

  try {
    await httpAdapter.get({ url, params })
    await httpAdapter.put({ url, params })
    moduleState[G.ROUTE] = {
      [G.ACTION]: 'registerActivate',
    }
  } catch (e) {
    moduleState[G.ROUTE] = {
      [G.ACTION]: 'registerError',
    }
  } finally {
    await redirectSequence(module)({})
  }
}

/**
  * Completes the registration verification
  *
  * @param {Gaia.AppModule.Spec} module  the current module composition object
  * @returns {function(): function(...[*]): Promise<*>}  the call's arguments
  */
export default module => () => async (...args) => {
  const result = validateRedirect(module)()(...args)
  const modelStateError = module[G.MODEL][G.STATE][G.ERROR]
  !modelStateError && await _verify(module)

  return result
}
