/* global G */
import { pipe } from 'lib/util'
import { usesGetterSetter, usesScopedNamespace } from 'trait/uses'
import { canHookInto } from 'trait/can'
import appModuleComposition from 'trait/composition/appmodule'
import actions from './actions'
import events from './events'
import hooks from './hooks'
import api from './api'

const { API, ACTIONS, EVENTS } = G

const descriptor = 'module::guest'

/**
 * Guest Authorization Module
 *
 * Authorizes potentially registered guest against '/auth' api end point.
 * It uses native adapter via model api in 2-step verification actions.
 *
 * @param {Gaia.AppModule.Spec} obj - configuration object
 * @return {Gaia.AppModule.Spec} obj - object composition
 */
const composition = obj => pipe(
  appModuleComposition(descriptor),
  usesGetterSetter(ACTIONS, actions),
  usesGetterSetter(EVENTS, events),
  canHookInto(ACTIONS, hooks),
  usesScopedNamespace(API, api),
)(obj)

export default composition
