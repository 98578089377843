/* eslint-disable import/no-duplicates,no-duplicate-imports,no-unused-vars */
/* global G */
import { curry } from '@gaia/util'
import createRedirect from 'app/_shared/events/appbar/createRedirect'
import acl from 'app/_shared/events/acl/component'
import { resolve } from '@gaia/manager/acl/helper'
import session from '@app/_shared/session'
import chain from 'app/_shared/events/util/chain'
import sublist from 'app/_shared/events/util/sublist'
import { back, redirect } from '@app/_shared/events'
import { persistChange, persistCheck, persistOptions } from '@app/_shared/events'
import toRef from 'app/_shared/events/redirect/toRef'
import listOrganisationTypes from 'app/storybook/event/listOrganisationTypes'
import listStatusReasons from 'app/_shared/events/collection/listStatusReasons'
import infinite from 'app/_shared/events/search/infinite'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'
import listTicketStatus from 'app/_shared/events/collection/listTicketStatus'
import listInvitationStatuses from 'app/_shared/events/collection/listInvitationStatuses'
import listLanguages from 'app/_shared/events/collection/listLanguages'
import listCountries from 'app/_shared/events/collection/listCountries'
import search from 'app/_shared/events/search'
import searchWith from 'app/_shared/events/search/with'
import searchStepAndRecreate from 'app/_shared/events/searchStepAndRecreate'
import setCountedLabel from 'app/_shared/events/tabs/setCountedLabel'
import countActiveDevices from '@app/_shared/events/widget/count/activeDevices'
import countOpenRequests from '@app/_shared/events/widget/count/openRequests'
import toDetailsOrIndex from '@app/_shared/events/redirect/toDetailsOrIndex'
import countLocations from '@app/_shared/events/widget/count'
import countColleagues from '@app/_shared/events/widget/count/contacts'
import { item, own } from 'app/_shared/events/pubsub'
import logClick from 'app/storybook/event/logClick'
import emailLink from 'app/storybook/event/emailLink'
import updateList from 'app/storybook/event/updateList'
import setStepTab, { CONFIRM, LIST, SEARCH, SELECT } from 'app/_shared/events/setStepTab'
import { confirm, jump, next } from 'app/_shared/events/step'
import updateStdOutput from 'app/storybook/event/updateStdOutput'
import onScan from 'app/storybook/event/onScan'
import emptyList from 'app/storybook/event/emptyList'
import updateUnreadMessagesCount from 'app/ticket/event/updateUnreadMessagesCount'

const self = curry((module, component, event) => {
  const _acl = resolve(component)
  let result
  try {
    result = module[G.ADAPTER][G.ACL].check(
      module[G.ADAPTER][G.ACL].get(acl[G.META]),
      _acl[G.ACL],
    )
  } catch (e) {
    result = _acl[G.PROPS]
  }
  return result
})

export default {
  acl: {
    acl,
    self,
  },
  onOpen: {
    infinite: infinite(null),
    search: search(null),
    emptyList,
    setTabSearch: searchWith(['query', 'filter']),
    listLanguages,
    listCountries,
    own,
    searchVerboseWithFilter: chain(
      searchWith(['filter']),
      search('verbose'),
    ),
    toOrganisation: toRef('organisation'),
  },
  onClose: {
    back,
  },
  onClick: {
    redirect,
    logClick,
    toDetailsOrIndex,
    emailLink,
    jump,
    confirmStep: chain(
      confirm,
      next,
    ),
    toSelect: setStepTab(SELECT),
    toSearch: setStepTab(SEARCH),
  },
  onCreate: {
    createRedirect,
  },
  onScan: {
    onScan,
  },
  onChange: {
    persistChangeAndUpdate: chain(
      persistChange,
      updateStdOutput,
    ),
    persistChange,
    persistCheck,
    persistOptions,
    persistCountry: persistOptions,
    logClick,
  },
  onPublication: {
    countActiveDevices,
    countOpenRequests,
    countLocations,
    countColleagues,
    noop: curry((module, component, event) => ({ total: { count: null } })),
    updateList,
  },
  onSearch: {
    searchStepAndRecreate,
  },
  filter: {
    isActive: curry((module, component, event) => ({
      status: { max: 79 },
    })),
    isOpen: curry((module, component, event) => ({
      status: { min: 10, max: 79 },
    })),
    colleagues: curry((module, component, event) => ({
      id: `!${session(module).person.key({ wrap: true })[0]}`,
    })),
  },
  getSelection: {
    listOrganisationTypes: sublist(
      ['customer',
        'independent_contractor',
        'internal',
        'partner',
        'third_party',
        'fourth_party',
        'fifth_party',
        'sixth_party',
        'seventh_party',
        'eigth_party',
        'ninth_party',
        'tenth_party'],
      listOrganisationTypes,
    ),
    listPostponedReasonTypes: sublist([60, 61, 62, 63], listStatusReasons, true),
  },
  getLabel: {
    setOwnLabel: setCountedLabel({ ns: 'ticket', key: 'myRequests' }),
  },
  getTicketType: {
    listTicketTypes,
  },
  getStatus: {
    listTicketStatus,
    listInvitationStatuses,
  },
  getStatusReason: {
    listStatusReasons,
  },
  item: {
    sub: item,
  },
  list: {
    sub: own,
  },
  cell: {
    sub: updateUnreadMessagesCount,
  },
}
