/* global G */
/* eslint-disable no-unused-vars */
import { asyncPipeSpread, bulk, getFirstItem, isArr } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { get, set } from 'lib/sequence/component/state/value'
import listStatus from 'app/_shared/events/collection/listStatus'
import roles from 'model/account/collection/roles'
import { enable } from 'lib/sequence/component/state/disabled'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Presets the {@code status} field.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @return {function(*): function(*, ...[*]): Promise<*>}
 */
const presetStatus = module => component => async (components, ...args) => {
  const { status } = components

  const activeStatus = getFirstItem(listStatus(module, component, { detail: { key: 50 } }))

  set(status, activeStatus)

  return [components, ...args]
}

/**
 * Hides the users current roles in case of creating a user
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @return {function(*, *, ...[*]): Promise<*[]>}
 */
const hideRoles = module => async (components, ...args) => {
  const { rolesList } = components

  hide(rolesList)

  return [components, ...args]
}

/**
 * Determines whether the teams section should be shown.
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @return {function(*, *, ...[*]): Promise<*[]>}
 */
const displayTeams = module => async (components, userRole, ...args) => {
  const { team, teamDivider, roles: rolesField } = components

  const rolesValue = get(rolesField)

  if (!rolesValue) {
    bulkHide(team, teamDivider)
    return args
  }

  const currentRoles = isArr(rolesValue)
    ? rolesValue
    : Object.values(rolesValue)

  /**
   * Check all user roles to see if one of them has {@code hasTeams} set to {@code true}. This will
   * include roles the user already has when opening the drawer as well as newly added/customized
   * roles.
   */
  const hasTeamRoles = currentRoles.reduce(
    (acc, key) => roles.find(x => x.key === key.role)?.hasTeams || acc, false,
  )

  hasTeamRoles
    ? bulkShow(team, teamDivider)
    : bulkHide(team, teamDivider)

  return [components, ...args]
}

/**
 * Enables the username validator
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @return {function(*, *, ...[*]): Promise<*[]>}
 */
const enableUsernameValidation = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { username = {} } = model[G.VALIDATOR] || {}

  enable(username)

  return args
}

/**
 * User Admin Action Create
 *
 * @param {Gaia.AppModule.Spec} module the current module
 * @return {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  presetStatus(module)(component),
  hideRoles(module),
  displayTeams(module),
  enableUsernameValidation(module),
)(find(component), ...args)
