/* global G */
import { curry } from 'lib/util'
import session from 'app/_shared/session'

/**
 * Get Favorite event handler
 *
 * Returns whether the resource at hand is favorited by the user
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 * @returns {boolean}
 */
const getFavorite = (module, component, event) => {
  const { value: favoriteId } = event?.detail || event || {}
  const settingsAdapter = module[G.ADAPTER][G.SETTINGS]

  return settingsAdapter[G.API].getFavorite({ key: favoriteId })
}

export default curry(getFavorite)
