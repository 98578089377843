/* global G */
import cancelOnError from 'lib/sequence/model/cancelOnError'
import { apply } from 'app/_shared/events/attachment'

const descriptor = 'module::ticket::hook::submitAttachments'

/**
 * Submit Attachments Hook
 *
 * Submits the attachments uploaded in the current action. The action's root component must have a
 * 'group' prop with the name of the attachment's group to perform the action with.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]=)}
 */
const submitAttachments = obj => (...args) => {
  const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]

  cancelOnError(descriptor, obj)
  apply(obj, actionComponent, null)

  return args
}

export default submitAttachments
