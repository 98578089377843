/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import sequenceComponentFind from 'lib/sequence/component/children/find'

/**
 * Step actions executor.
 *
 * Executes every action from actions whose name can be found as key among the stepper's
 * children (steps) components.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*): function({actions: *, [p: string]: *}): Promise<*>}
 */
export default module => component => async ({ actions, ...args }) => {
  const { stepper } = sequenceComponentFind(component)
  const steps = asObject(stepper[G.CHILDREN])
  // grouping all step actions and obtaining a list with their names
  const actionKeys = Object.keys(actions)
  // looping steps
  return await actionKeys.reduce(async (acc, key) => {
    const step = steps[key]
    // calling step action
    return step ? await actions[key](module)(step)(await acc) : acc
  }, Promise.resolve(args))
}
