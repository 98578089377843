/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Adds additional search parameters to the current list.
 *
 * {@param properties} is a list of strings, each being an event handler representing one additional
 * search property. Each event handler will be executed (if present) and its result will be added to
 * the {@code additionalProperties} object, which will be appended to the already existing options
 * in {@code list[G.STATE][G.META]} possibly overwriting them.
 *
 * @param {String[]} properties           list of event handler names to execute and add to the
 *                                        search
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Event} eventOrOptions          filter options for the current tab. This should be
 *                                        provided by {@code filter} event handler
 * @return {Promise<Object>}              accumulated additional search options
 */
const searchWithFn = async (properties, module, component, eventOrOptions) => {
  const additionalProperties = properties.reduce((acc, key) => (
    component[G.EVENTS]?.[key]
      ? { ...acc, [key]: component[G.EVENTS][key]?.(eventOrOptions || null) }
      : acc), {})

  const { list = component } = asObject(component[G.CHILDREN])

  if (list) {
    const listOptions = list[G.STATE][G.META]
    list[G.STATE][G.META] = {
      ...listOptions,
      ...additionalProperties,
    }
  }

  return additionalProperties
}

export default curry(searchWithFn)
