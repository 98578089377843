/* eslint-disable no-unused-vars */
/* global G */
import { curry, setKey } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { _getCurrentTab } from 'app/registration/action/invite'
import validate from 'lib/sequence/model/validate'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Validates the contact tab.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>} void
 */
const validateAndSubmit = async (module, component, event) => {
  // model
  const model = module[G.MODEL]
  const { person } = model[G.CHILDREN]
  const { contactChannels } = person[G.CHILDREN]

  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const currentTab = _getCurrentTab(actionComponent)('contact')
  const { contactInformation } = asObject(currentTab[G.CHILDREN])

  // validate
  await validate(model)(contactInformation)
  await validate(person)(contactInformation)
  await validate(contactChannels)(contactInformation)

  /**
   * Set possible validation errors
   */
  !person[G.STATE][G.ERROR] && !model[G.STATE][G.ERROR] && !contactChannels[G.STATE][G.ERROR]
    ? setKey(false, G.ERROR, module[G.STATE]) && await actionWithHooks(module[G.ACTIONS].submit)()
    : setKey(true, G.ERROR, module[G.STATE]) && await module[G.ADAPTER][G.UI].update(module)
}

export default curry(validateAndSubmit)
