import { curry, getFirstItem } from 'lib/util'
import infinite from 'app/_shared/events/search/infinite'
import getUserRole from 'app/_shared/events/user/role'
import listInvitationRoles from 'app/_shared/events/collection/listInvitationRoles'

/**
 * Get all viewable PERSON's for the current user alongside their user information.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event} event
 * @returns {Promise<*|(function(...[*]): (*))>}
 */
const infinitePersonsWithUsers = async (module, component, event) => {
  const persons = await infinite('verbose', module, component, event)

  // eslint-disable-next-line no-restricted-syntax
  for (let x = 0; x < persons.data.length; x++) {
    const person = persons.data[x]
    const user = person.value.count.user.person
    const userRoles = user.count === 1
      ? user.data.roles
      : []
    person.value.role = getUserRole(userRoles, module, component, null)?.value

    /**
     * Settings {@code value.invite.role} for invitees
     */
    person.value.invite = !person.value.role && person.value?.invite?.role
      ? {
        role: getFirstItem(
          await listInvitationRoles(module, component, person.value.invite.role),
        )?.value,
        status: person.value.invite?.status || null,
      }
      : null
  }

  return persons
}

export default curry(infinitePersonsWithUsers)
