/* eslint-disable import/no-duplicates,no-duplicate-imports,no-unused-vars */
/* global G */
import acl from 'app/_shared/events/acl/component'
import { resolve } from '@gaia/manager/acl/helper'
import { curry } from '@gaia/util'
import session from '@app/_shared/session'
import { redirect } from '@app/_shared/events'
import own from '@app/_shared/events/pubsub/own'
import toDetailsOrIndex from '@app/_shared/events/redirect/toDetailsOrIndex'
import countActiveDevices from '@app/_shared/events/widget/count/activeDevices'
import countOpenRequests from '@app/_shared/events/widget/count/openRequests'
import countLocations from '@app/_shared/events/widget/count'
import countColleagues from '@app/_shared/events/widget/count/contacts'
import soon from '@app/_shared/events/soon'

const self = curry((module, component, event) => {
  // eslint-disable-next-line no-shadow
  const acl = resolve(component)
  let result
  try {
    result = module[G.ADAPTER][G.ACL].check(
      module[G.ADAPTER][G.ACL].get(acl[G.META]),
      acl[G.ACL],
    )
  } catch (e) {
    result = acl[G.PROPS]
  }
  return result
})

export default {
  acl: {
    acl,
    self,
  },
  onClick: {
    toDetailsOrIndex,
    redirect,
    soon,
  },
  onOpen: {
    own,
  },
  onPublication: {
    countActiveDevices,
    countOpenRequests,
    countLocations,
    countColleagues,
    noop: curry((module, component, event) => ({ total: { count: null } })),
  },
  filter: {
    isActive: curry((module, component, event) => ({
      status: { max: 79 },
    })),
    isOpen: curry((module, component, event) => ({
      status: { min: 10, max: 79 },
    })),
    submitter: curry((module, component, event) => ({
      submitter: session(module).user.key({ wrap: true })[0],
    })),
    colleagues: curry((module, component, event) => ({
      id: `!${session(module).person.key({ wrap: true })[0]}`,
    })),
    isActiveAndMaintainedByMe: curry((module, component, event) => ({
      status: { min: 50 },
      maintainedBy: session(module).person.key({ wrap: true })[0],
    })),
    isOpenAndSubmittedByMe: curry((module, component, event) => ({
      status: { min: 10, max: 79 },
      submitter: session(module).user.key({ wrap: true })[0],
    })),
  },
}
