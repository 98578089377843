import password from './password'
import confirmation from './confirmation'
import requirements from './requirements'

export const validators = {
  password,
  confirmation,
  requirements,
}

export {
  validators as default,
  password,
  confirmation,
  requirements,
}
