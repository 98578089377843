/**
 * Cart Positions step action logic
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  // Nothing to do here yet.

  return args
}
