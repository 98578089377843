/* global G */
import { curry } from 'lib/util'
import { capitalizeFirstLetter } from 'lib/util/string'
import roles from 'model/account/collection/roles'

/**
 * Translates and returns the list of invitable {@link roles}.
 *
 * @param {Gaia.AppModule.Spec} module            the current module object composition
 * @param {Gaia.Component} component              the object composition of the component that
 *                                                triggered the event
 * @param {Gaia.PlatformEvent|string} eventOrKey  the event or the key of the role to obtain
 * @return {Array} countries
 */
const listInvitationRoles = (module, component, eventOrKey) => {
  const key = eventOrKey?.detail?.key || eventOrKey
  // Server saves invite role in lowercase, but our keys are capitalized.
  const scope = key ? roles.filter(item => item.key === capitalizeFirstLetter(key)) : roles

  return scope.filter(role => role?.canBeInvited).map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `roles.${item.key}`,
      {
        ns: 'custom',
        _key: `roles.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }))
}

export default curry(listInvitationRoles)
