/**
 * Preferred Channels Collection.
 *
 * Contains static list of available ticket's preferred channels, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 */
export default [
  {
    key: 'email',
    value: 'E-Mail',
    icon: 'email',
  },
  {
    key: 'phone',
    value: 'Phone',
    icon: 'phone_android',
  },
  {
    key: 'portal',
    value: 'Portal',
    icon: 'language',
  },
  {
    key: 'interface',
    value: 'Interface',
    icon: 'web',
  },
  {
    key: 'other',
    value: 'Other',
    icon: 'devices_other',
  },
]
