/**
 * Simple search predicate.
 *
 * @param {Object} node the current node
 * @param {String} term search term
 * @returns {boolean}
 */
const isInOrderNumber = (node, term) => (node?.orderNumber
  ? node.orderNumber.toLowerCase().indexOf(term.toLowerCase()) !== -1
  : false)

export default isInOrderNumber
