import { curry } from 'lib/util'
import { setDetailUndoKey } from 'app/ticket/action/detail'

/**
 * Event Handler Clear Message Undo
 *
 * Sets the 'message' key of the detail action's undo state as false.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
const clearMessageUndo = async (module, component, event) => setDetailUndoKey(module, 'message', false)

export default curry(clearMessageUndo)
