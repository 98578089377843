/* eslint-disable no-restricted-globals,no-alert,object-curly-newline */
/* global G */
import { curry, setKey } from 'lib/util'
import { actionFn } from 'lib/sequence/module/action'
import reset from 'lib/sequence/model/api/reset'
import findDevice from 'app/_shared/events/device/find'
import detail from 'app/_shared/events/action/detail'
import sequenceComponentState from 'lib/sequence/component/state'
import { persistChange } from 'app/_shared/events'
import refresh from 'lib/sequence/model/api/refresh'
import PlatformEvent from 'lib/util/event'

const {
  unset: clearError,
} = sequenceComponentState('error')

const {
  unset: clearHelperText,
} = sequenceComponentState('helperText')

const clear = component => clearError(component) && clearHelperText(component)

/**
 * Shows a dialog informing the user that a device was found.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {string} organisation         the name of the organisation the found device is
 *                                      installed at
 * @param {function} okHandler          a function to be executed if the user clicks 'ok'
 */
const showDeviceFoundDialog = async (module, organisation, okHandler) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: await module[G.ADAPTER][G.INTL]._t(
      'dialog.deviceFound.title',
      {
        ns: 'device',
        _key: 'dialog.deviceFound.title',
        defaultValue: 'Device already assigned',
      },
    ),
    text: await module[G.ADAPTER][G.INTL]._t(
      'dialog.deviceFound.text',
      {
        ns: 'device',
        _key: 'dialog.deviceFound.text',
        defaultValue: 'Device is already assigned to "{{organisation}}". Please, make sure you'
                      + ' want to re-assign it to another customer.\n\nIn case the device has only'
                      + ' changed the location, not the ownership, press Ok to open the device'
                      + ' and change the installation location.',
        organisation,
      },
    ),
    children: {
      cancel: {
        key: 'cancel',
        value: await module[G.ADAPTER][G.INTL]._t(
          'button.cancel',
          {
            ns: 'common',
            _key: 'button.cancel',
            defaultValue: 'cancel',
          },
        ),
      },
      ok: {
        key: 'ok',
        value: await module[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'ok',
          },
        ),
      },
    },
    okHandler,
  })
}

/**
 * Event Handler Search Device
 *
 * Attempts to find a service item or equipment by its serial and, depending on what is found, sets
 * the current model's item, equipment and product attributes with their information. Otherwise, if
 * nothing is found, those model attributes are cleared.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    the event object that triggered this handler
 * @returns {Promise<void>}
 */
const searchDevice = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()
  const model = module[G.MODEL]
  const serialValue = event.detail.value

  persistChange(module, component, event)
  // clearing field state
  clear(component)
  // clearing currently stored data
  reset(model)
  setKey(false, G.ERROR, model[G.STATE])

  if (serialValue) {
    const result = await findDevice(module, component, event)

    if (result.serviceItem) {
      if (result.serviceItem.key) {
        const organisation = result.installedAt?.value.name
        await showDeviceFoundDialog(module, organisation, async () => {
          const customEvent = new CustomEvent('click', { detail: result.serviceItem })
          await detail(module, component, new PlatformEvent(customEvent))
        })
      }
      await refresh(model, result.serviceItem)
    } else {
      await refresh(model, { serial: serialValue })
    }

    const newSerial = !model[G.CHILDREN].serial[G.CACHE] && serialValue
    setKey(newSerial ? true : null, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
  }

  await actionFn(module[G.STATE][G.ACTION])()
  await module[G.ADAPTER][G.UI].update(module)
}

export default curry(searchDevice)
