/* eslint-disable no-unused-vars,no-param-reassign */
/* global G */
import { curry } from 'lib/util'
import sublist from 'app/_shared/events/util/sublist'
import listOrganisationTypes from 'app/_shared/events/collection/listOrganisationTypes'

/**
 * List Register Organisation Types event handler.
 *
 * Returns the list of organisation types to be used for the register section.
 * @type {Gaia.AppModule.EventHandler}.
 */
export default curry(async (module, component, event) => sublist(
  ['customer', 'partner', 'third_party'],
  listOrganisationTypes,
)(module, component, event).map((type) => {
  delete type.icon
  return type
}))
