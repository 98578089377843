/* global G */
import { curry } from 'lib/util'
import redirectSequence from '@gaia/sequence/module/adapter/router/redirect'
import routeComposition from '@gaia/trait/composition/route'

/**
 * Please, make sure that the component configuration contains route
 * @example
 * {
 *   config: 'ui/button/dialog',
 *   options: {
 *     key: 'btnRedirect',
 *     value: 'CONTACT SUPPORT',
 *     events: {
 *       onClick: 'redirect',
 *     }
 *   },
 *   route: {
 *     module: 'support',
 *     action: 'contact',
 *   }
 * }
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - event
 * @return {Promise<void>} void
 */
const redirect = async (module, component, event) => {
  try {
    const { detail } = event
    const moduleState = module[G.STATE]
    const { route } = component[G.CONFIGURATION]
    moduleState[G.ROUTE] = routeComposition(route.module, route.action)
    await redirectSequence(module)({ detail })
  } catch (e) {
    console.error(e)
  }
}

export default curry(redirect)
