/* global G */

import { setKey } from 'lib/util'

/**
 * Set Model Hook
 *
 * Sets the {@code model} configured in the action configuration as the module's current
 * {@code G.MODEL}. Does so by copying it from {@code module[G.PARENT][G.CHILDREN][model]}
 * into {@code module[G.MODEL]}.
 *
 * Requires {@code withGetterSetter(G.PARENT)} in module configuration.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): *[]}
 */
const set = obj => (...args) => {
  const subModel = obj[G.STATE][G.ACTION]?.[G.MODEL] || null

  if (!subModel && !obj[G.PARENT]) {
    return args
  }

  // If we come back to an action without {@code model: '...'} in the configuration
  // but we came from an action with a sub-model configured, we need to reset
  // the model to the original one (G.PARENT)
  if (!subModel) {
    setKey(obj[G.PARENT], G.MODEL, obj)
    return args
  }

  const parentRef = obj[G.PARENT]?.[G.STATE]?.[G.REF]

  // Swapping current model with configured sub-model
  setKey(obj[G.PARENT]?.[G.CHILDREN]?.[subModel], G.MODEL, obj)

  /**
   * If we're redirecting here from another module and with a model, like calling
   * {@code await redirectSequence(module)({ key: 'foo' })}, the {@code G.REF} of the parent
   * model will be set. Let's transfer it to the sub-model in case it has no ref so that the
   * information doesn't get lost.
   */
  if (parentRef && !obj[G.MODEL][G.STATE]?.[G.REF]) {
    setKey(parentRef, G.REF, obj[G.MODEL][G.STATE])
  }

  return args
}

export default set
