/* global G */
import { curry } from 'lib/util'

const descriptor = 'model::decorator::fromMultipleRef'

/**
 * Sets {@param obj}[G.CHILDREN]{@param key}'s {@code G.CACHE} to {@param obj} {@code G.CACHE}.
 *
 * Is meant to be used if a ref has multiple (resolved) items, and we want to use all of them.
 * If only the first ref is of importance, use {@link fromSingleRef}.
 *
 * @param {String} key          name of the source sub model
 * @param {String} target       name of the target sub model
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {object} component    the component that matched the attribute name during the mapping
 * @return {*}
 */
const fn = (key, target, obj, component) => {
  console.warn(descriptor, key, target)

  const source = obj[G.CHILDREN][key]

  const item = obj[G.CHILDREN][target]
  item[G.CACHE] = source[G.CACHE]

  return component
}

export default curry(fn)
