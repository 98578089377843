/* global G */
import { curry, setKey } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action';

/**
 * Changes the status of the current ticket/request to {@code newStatus}.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component    the current action's main component
 * @param {number} newStatus              the new status to be set.
 * @param {number} newStatusReason        the new statusReason to be set.
 * @param {boolean} modal                 whether this event handler is called from a modals
 * @returns {Promise<void>}
 */
export const changeStatus = async (module, component, newStatus, newStatusReason = 0, modal = false) => {
  try {
    const model = module[G.MODEL]
    const { api, version } = model[G.PROPS]
    const { key: id, _rev } = model[G.CACHE]
    const url = `/api/v${version}/${api}/${id}`

    const params = {
      value: {
        status: newStatus,
        statusReason: newStatusReason,
        postponeDate: null,
      },
      _rev,
    }

    await model[G.ADAPTER][G.HTTP].patch({ url, params })
    // forcing read call
    model[G.CACHE] = null
    // clearing dirty state
    modal && setKey(null, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
    modal && await module[G.ADAPTER][G.ROUTER].back()
    await actionWithHooks(module[G.ACTIONS].detail)({})
  } catch (e) {
    await module[G.ADAPTER][G.UI].update(module)
    console.error(e)
  }
}

export default curry(changeStatus)
