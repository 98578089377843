/* eslint-disable no-param-reassign */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'

const persistentProps = ['data', 'page', 'unsegmented']

/**
 * Returns the number of minutes passed between {@param timestamp} and now.
 *
 * @param {Date} timestamp  a previous timestamp
 * @returns {number}        the number of minutes passed since {@param timestamp}
 */
const getMinutesPassed = (timestamp) => {
  const diff = Math.abs(new Date() - timestamp) // in ms
  return Math.floor((diff / 1000) / 60)
}

/**
 * Clears the scroll position from {@param rootCache} and the list data from {@param listCache}.
 *
 * @param {Object} rootCache  action's root cache object
 * @param {Object} listCache  data cached from/for a list component
 */
const clearCache = (rootCache, listCache) => {
  delete rootCache.scroll
  delete listCache.timestamp
  listCache.props = {
    data: null,
    page: null,
    unsegmented: null,
    reload: false,
  }
}

/**
 * Returns whether {@param cache} has expired. A {@param cache} object is considered to be expired
 * if its data meets the following conditions:
 *  - its props property has a defined data property (which should mean its cached list data)
 *  - its timestamp property is more than 15 minutes old
 * @param cache           data stored in an action
 * @param cache.props     cached data from a component
 * @param cache.timestamp the time at which the cache was saved
 * @returns {*|boolean}
 */
const hasCacheExpired = (cache) => {
  const { props, timestamp } = cache
  const hasListData = props.data
  // we need to check nothing else if it doesn't have list data
  const minutes = hasListData ? getMinutesPassed(timestamp) : 0
  return hasListData && minutes > 15
}

/**
 * Adds the properties of the {@param state} object to the {@param component}' state.
 *
 * @param {Gaia.Component} component  a component to alter the state of
 * @param {object} props              an object to merge with the {@param component}'s state
 */
const mergeState = (component, props) => {
  const componentState = component[G.STATE]
  Object.keys(props).forEach((key) => {
    componentState[key] = props[key]
    // The UI adapter doesn't clear properties that were previously defined. That's ok for almost
    // every case, but not for the lists. So that the lists can update themselves after having
    // set an initial state for them, we have to nullify it, otherwise, they "think" they always
    // have initial data and won't fetch anything anew.
    persistentProps.includes(key)
      ? props[key] = null
      : delete props[key]
  })
}

/**
 * Distributes the data stored in the {@param cache} object among the corresponding children of
 * {@param component}, at any depth.
 *
 * @param {Gaia.Component} component  the component to spread the cache in
 * @param {object} cache              a cache object representing the path to each component that
 *                                    got its state saved in a previous action
 * @param {object} [rootCache]        the root of the current cache object
 */
const spread = (component, cache, rootCache = cache) => {
  const children = asObject(component[G.CHILDREN])
  cache && Object.keys(cache).filter(key => !!children[key]).forEach((key) => {
    const { _cache, ...item } = cache[key]
    const child = children[key]
    _cache && (hasCacheExpired(_cache)
      ? clearCache(rootCache, _cache)
      : mergeState(child, _cache.props))
    child[G.CHILDREN]
      && spread(child, item, rootCache)
  })
}

/**
 * Recall Component Hook
 *
 * Moves stored action cache values to corresponding components.
 *
 * Traverses the current action's cache object until it founds {@code props} properties, then merges
 * the properties of {@code props} with the components' state. It deletes
 * the used cache so that it is used only the first time.
 *
 * Intended to restore the state of components that got it saved with the {@link remember} event
 * handler.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): *[]}
 */
const recall = obj => (...args) => {
  const action = obj[G.STATE][G.ACTION]
  const actionComponent = action[G.COMPONENT]
  const { key } = actionComponent[G.PROPS]
  const actionCache = action[G.CACHE]
  const cache = actionCache[key]

  spread(actionComponent, cache, actionCache)

  return args
}

export default recall
