/* eslint-disable no-unused-expressions */
import setAsRead from 'app/_shared/events/message/setAsRead'

/**
 * Returns the payload used to assign the ticket to the current user.
 *
 * @type QuickPayload
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @returns {object}
 */
export default async (module, component) => {
  await setAsRead(module, component, null)

  return {
    value: {
      statusReason: 0,
    },
  }
}
