/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread, getFirstItem } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import { get, reset, set } from 'lib/sequence/component/state/value'
import { check, uncheck } from 'lib/sequence/component/state/checked'
import asObject from 'lib/sequence/component/children/asObject'
import bulk from 'app/_shared/events/search/bulk'
import sublist from 'app/_shared/events/util/sublist'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'
import listUserRoles from 'app/_shared/events/collection/listUserRoles'
import listGenericOptions from 'app/_shared/events/collection/listGenericOptions'
import listStatus from 'app/_shared/events/collection/listStatus'

/**
 *
 * Presets the {@code defaultRole} field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 *
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetDefaultRole = module => async (components, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const { defaultRole } = components
  const defaultRoleValue = getFirstItem(get(defaultRole))
  const selectedRole = listUserRoles(module, actionComponent, { detail: { key: defaultRoleValue } })

  set(defaultRole, selectedRole)

  return [components, ...args]
}

/**
 * Presets the teams' status.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*, *, ...[*]): Promise<*[]>}
 */
const presetStatus = module => async (components, userRole, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { status: statusField } = components
  const status = getFirstItem(listStatus(module, actionComponent, get(statusField)))

  status && set(statusField, status)
  return [components, ...args]
}

/**
 *
 * Presets the {@code notify}'s checkboxes and disables the {@code team} checkbox if the team
 * has no valid email.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 *
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetNotify = module => async (components, ...args) => {
  const { notify, email } = components
  const { team, members } = asObject(notify[G.CHILDREN])

  const emailValue = get(email)

  // Inverse logic, checkbox should be checked if value is true OR non-existent
  // Because null is a valid value, we need to manually set the value to 'on' in that case.
  // otherwise MUI will complain about value being null.
  const shouldNotify = x => x === undefined || x === null || x === true
  shouldNotify(get(team))
    ? emailValue && check(team) && set(team, 'on')
    : uncheck(team) && reset(team)
  shouldNotify(get(members))
    ? check(members) && set(members, 'on')
    : uncheck(members) && reset(members)

  !emailValue
    && disable(team)
    && uncheck(team)
    && reset(team)

  return [components, ...args]
}

/**
 *
 * Presets the {@code forwards}'s {@code toAll} field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 *
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetForwardToAll = module => async (components, ...args) => {
  const { forward } = components
  const { toAll } = asObject(forward[G.CHILDREN])

  !get(toAll) ? uncheck(toAll) && reset(toAll) : check(toAll)

  return [components, ...args]
}

/**
 * Presets the {@code initialAssigment}'s {@code fallback} field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 *
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetInitialAssigmentFallback = module => async (components, ...args) => {
  const { initialAssignment } = components
  const { fallback } = asObject(initialAssignment[G.CHILDREN])

  !get(fallback) ? uncheck(fallback) && reset(fallback) : check(fallback)

  return [components, ...args]
}

/**
 *
 * Presets the {@code forwards}'s {@code from} field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 *
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetForwardTeams = module => async (components, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { forward } = components
  const { from } = asObject(forward[G.CHILDREN])
  const teams = get(from)

  const genericOption = teams?.length === 1
    ? getFirstItem(listGenericOptions(module, actionComponent, { detail: { key: teams[0] } }))
    : null

  try {
    const resolvedTeams = !genericOption
      ? await bulk(module, actionComponent, { ids: teams })
      : (genericOption && [genericOption]) || []
    set(from, resolvedTeams)
  } catch (e) {
    console.error(e)
  }

  return [components, ...args]
}

/**
 * Presets the {@code product} field of {@param section}.
 *
 * @param {String} section  the section which ticket types to preset.
 *
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const presetProducts = section => module => async (components, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { [section]: type } = components
  const { product } = asObject(type[G.CHILDREN])
  const products = get(product)

  const genericOption = products?.length === 1
    ? getFirstItem(
      listGenericOptions(module, actionComponent, { detail: { key: products[0] } }),
    )
    : null

  try {
    const resolvedProducts = !genericOption
      ? await bulk(module, actionComponent, { ids: products })
      : (genericOption && [genericOption]) || []
    set(product, resolvedProducts)
  } catch (e) {
    console.error(e)
  }

  return [components, ...args]
}

/**
 * Presets the {@code ticketType} field of {@param section}.
 *
 * @param {String} section  the section which ticket types to preset.
 *
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const presetTicketTypes = section => module => async (components, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { [section]: type } = components
  const { ticketType } = asObject(type[G.CHILDREN])
  const ticketTypes = get(ticketType)

  const genericOption = ticketTypes?.length === 1
    ? getFirstItem(listGenericOptions(module, actionComponent, { detail: { key: ticketTypes[0] } }))
    : null

  try {
    const resolvedTicketTypes = !genericOption
      ? (ticketTypes?.length && sublist(ticketTypes, listTicketTypes)(module, actionComponent, null))
        || []
      : (genericOption && [genericOption]) || []
    set(ticketType, resolvedTicketTypes)
  } catch (e) {
    console.error(e)
  }

  return [components, ...args]
}

/**
 * Presets the {@code timezone} field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetTimezone = module => async (components, ...args) => {
  const { timezone } = components

  const timezoneValue = get(timezone)
  timezoneValue && set(timezone, { key: timezoneValue, value: timezoneValue })

  return [components, ...args]
}

/**
 * Toggles the {@code isEmpty} validator of the {@code email} field depending
 * on whether it has a value or not.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const toggleEmailValidation = module => async (components, ...args) => {
  // Disabling contactChannels accountExists validator
  const model = module[G.MODEL]
  const { contactChannels = {} } = model[G.CHILDREN] || {}
  disable(contactChannels[G.VALIDATOR].email, true)

  const { email } = components
  const { email: emailModel } = module[G.MODEL][G.CHILDREN].contactChannels[G.CHILDREN]
  const notEmptyValidator = emailModel[G.VALIDATOR].find(validator => validator._name === 'notEmpty')

  get(email)
    ? enable(notEmptyValidator)
    : disable(notEmptyValidator)

  return [components, ...args]
}

/**
 * Toggles the validation of the "Follow-The-Sun" fields depending on whether at least
 * one of them has a value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetFollowTheSunValidation = module => async (components, ...args) => {
  // model
  const model = module[G.MODEL]
  const { followTheSun: followTheSunModel } = model[G.CHILDREN]

  // component
  const { followTheSun } = components
  const { ticketType, timezone, fromTime, toTime } = asObject(followTheSun[G.CHILDREN])

  const shouldEnable = get(ticketType)?.length
      || get(timezone)?.length
      || get(fromTime)
      || get(toTime)

  Object.values(followTheSunModel[G.VALIDATOR]).forEach((validator) => {
    disable(validator, !shouldEnable)
  })

  return [components, ...args]
}

/**
 * Team Admin Edit Action
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 *
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  presetDefaultRole(module),
  presetStatus(module),
  presetNotify(module),
  toggleEmailValidation(module),
  // forward
  presetForwardToAll(module),
  presetForwardTeams(module),
  presetProducts('forward')(module),
  presetTicketTypes('forward')(module),
  // initialAssigment
  presetInitialAssigmentFallback(module),
  presetProducts('initialAssignment')(module),
  presetTicketTypes('initialAssignment')(module),
  // followTheSun
  presetFollowTheSunValidation(module),
  presetTicketTypes('followTheSun')(module),
  presetTimezone(module),
)(find(component), ...args)
