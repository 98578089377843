/* global G */
import { pipe } from 'lib/util'
import { confirmation, requirements } from 'model/support/validator'
import { usesGetterSetter } from '@gaia/trait/uses'
import { withGetterSetter } from '@gaia/trait/with'
import { modelComposition } from '@gaia/trait/composition'
import fromData from '@model/_shared/decorator/fromData'
import drop from '@model/_shared/transformer/drop'
import requirementsDecorator from './decorator/requirements'


const descriptor = 'model::account::password'

const Model = pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, { confirmation, requirements }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    requirements: requirementsDecorator,
    password: fromData('password'),
    confirmation: fromData('confirmation'),
  }),
  usesGetterSetter(G.TRANSFORMER, {
    requirements: drop('requirements'),
    confirmation: drop('confirmation'),
  }),
)

// refactor usage in support module, based on this implementation

export default Model
