/* global G */
import { curry } from 'lib/util'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'

/**
 * Redirects to a new route with the current organisation model as 'organisation' argument.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const redirectWithStep = async (module, component, event) => {
  const moduleState = module[G.STATE]
  const { route, options: { step } = {} } = component[G.CONFIGURATION]
  moduleState[G.ROUTE] = routeComposition(route.module, route.action)
  await redirectSequence(module)({ step })
}

export default curry(redirectWithStep)
