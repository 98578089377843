/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * List by IDs
 *
 * used for fetching data for rendered iterable ui components
 *
 * executes remote api call with given parameters
 *
 * @param {string} type                 the type of server's data model definition to return
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>} void
 */
const listByIds = async (type, module, component, event) => {
  const model = module[G.MODEL]
  try {
    // refs can be injected via an action, ie request step1..
    const ids = component[G.STATE][G.REF]
    const { version } = model[G.PROPS]

    const url = `/api/v${version}/bulk/fetch${type ? `/${type}` : ''}`
    const params = { ids }

    try {
      const remoteAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]
      const result = await remoteAdapter.post({ url, params })
      return result.value
    } catch {
      console.error('listByIds:: tried and failed getting remote resources', url)
    }
  } catch (e) {
    console.error(e)
  }
  return [
    { key: -1, value: 'Not Found' },
  ]
}

export default curry(listByIds)
