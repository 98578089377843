/**
 * Cart Status Collection.
 *
 * Contains static list of available cart statuses.
 */
export default [
  {
    key: 0,
    value: 'Draft',
    color: 'primary',
  },
  {
    key: 50,
    value: 'Active',
    color: 'primary',
  },
  {
    key: 80,
    value: 'Done',
    color: 'textPrimary',
  },
]
