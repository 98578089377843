/* eslint-disable no-unused-vars */

/**
 * Assign service partner step action.
 *
 * Handles the display and status of the step's elements.
 *
 * Allows looking for a service partner to assign it to the new service item.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => args
