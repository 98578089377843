/* global G */
import { curry, getKey, isArr } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { resolve } from 'lib/manager/acl/helper'

/**
 * model acl getter
 *
 * This getter compares the acl's of the current document
 * with the acls defined in the module config.
 *
 * @example config
 * { ..., options: { ..., events: { ..., acl: 'model', ... }}}
 * @example module/[name]/events.js
 * import acl from 'app/_shared/acl/model'
 * export default { ..., acl: { model }, ...}
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {String} key                  the component's {@code key}
 * @returns {boolean|*}
 */
const acl = (module, component, { key }) => {
  const model = module[G.MODEL]
  const documentAcl = model[G.CACHE].acl
  const item = getKey(key, asObject(component[G.CHILDREN]))
  const componentAcl = !item[G.ACL] ? null : resolve(item)

  if (!isArr(componentAcl?.[G.ACL])) return true

  try {
    return module[G.ADAPTER][G.ACL].model(componentAcl[G.ACL], documentAcl)
  } catch (e) {
    return componentAcl[G.PROPS]
  }
}

export default curry(acl)
