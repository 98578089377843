/* global G */
import { curry } from 'lib/util'
import listInvitationStatuses from 'app/_shared/events/collection/listInvitationStatuses'

/**
 * Returns the possible invite statuses.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const getInviteStatus = (module, component, event) => {
  const model = module[G.MODEL]
  const { invite } = model[G.CHILDREN]
  const inviteCache = invite[G.CACHE] || {}
  const { status: userStatus = null } = inviteCache

  return listInvitationStatuses(module, component, userStatus)
}

export default curry(getInviteStatus)
