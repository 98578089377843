/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from '@gaia/trait/uses'
import withGetterSetter from 'trait/with/GetterSetter'
import fromTicketType from 'model/team/decorator/fromTicketType'
import fromTimezone from 'model/team/decorator/fromTimezone'
import fromTime from 'model/team/decorator/fromTime'
import drop from 'model/_shared/transformer/drop'
import validator from 'model/team/validator'

const descriptor = 'model::team::followTheSun'

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    ticketType: drop('ticketType'),
    timezone: drop('timezone'),
    fromTime: drop('fromTime'),
    toTime: drop('toTime'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    ticketType: fromTicketType('ticketType'),
    timezone: fromTimezone('timezone', 'ticketType'),
    fromTime: fromTime('fromTime', 'ticketType', true),
    toTime: fromTime('toTime', 'ticketType', false),
  }),
)
