/* global G */
import session from 'app/_shared/session';
import asObject from 'lib/sequence/component/children/asObject';
import { show, hide } from 'lib/sequence/component/state/hidden'
import { bulk, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Ticket attachment action
 *
 * Show/hide certain fields depending on whether the user
 * is assignee of the ticket and its status is 50.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const ticket = model[G.CACHE]
  const { attachmentList, dropzone } = asObject(component[G.CHILDREN])
  const { attachment } = asObject(attachmentList[G.CHILDREN])
  const { btnConfirm: confirmButton } = find(component[G.ACTIONS][0])

  const user = session(module).user.key()
  const userIsAssignee = !!(ticket.refs.assignee?.length && ticket.refs.assignee?.[0].key === user)

  // eslint-disable-next-line no-unused-expressions
  userIsAssignee
    ? bulkShow(dropzone, confirmButton) && setKey(false, 'readOnly', attachment[G.STATE])
    : bulkHide(dropzone, confirmButton) && setKey(true, 'readOnly', attachment[G.STATE])

  return args
}
