/* global G */
import cancelOnError from 'lib/sequence/model/cancelOnError'
import { add as addNote } from 'app/_shared/events/note'

const descriptor = 'module::ticket::hook::submitNote'

export const translator = (module, ns) => async (options) => {
  return await module[G.ADAPTER][G.INTL]._t(options._key, { ...options, ns })
}

/**
 * Submit Birth Note Hook
 *
 * Submits a birth note with basic information if the ticket's createRequest property isn't set.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]=)}
 */
const submitBirthNote = obj => async (...args) => {
  cancelOnError(descriptor, obj)
  const translate = translator(obj, 'ticket')
  const model = obj[G.MODEL]
  const modelState = model[G.STATE]
  const modelStateData = modelState[G.DATA]
  const { requesterOrgData, requesterContactData, createRequest } = modelStateData.value
  const { description: { text: description } } = modelStateData.value

  // Requesting birth note creation only if the ticket won't yet have a paired request
  if (!createRequest) {
    const { name: organisationName } = requesterOrgData
    const { firstName, lastName } = requesterContactData
    const { name: contactName = `${firstName} ${lastName}` } = requesterContactData

    const type = 'birth'
    const text = await translate({
      _key: 'label.birthNoteText',
      defaultValue: 'Request created on behalf of {{contactName}} / {{organisationName}}, yet not'
        + ' published:\n{{description}}',
      contactName,
      organisationName,
      description,
    })

    addNote(obj, null, {
      type,
      text,
    })
  }

  return args
}

export default submitBirthNote
