/* global G */

import cancelOnError from 'lib/sequence/model/cancelOnError'
import asObject from 'lib/sequence/component/children/asObject'
import { add as addNote } from 'app/_shared/events/note'
import { get } from 'lib/sequence/component/state/value'

const descriptor = 'module::ticket::hook::submitNote'

const types = {
  61: 'postpone',
  62: 'postpone_customer',
  63: 'postpone_third_party',
  80: 'close_resolve',
  89: 'close_cancel',
}

/**
 * Submit Note Hook
 *
 * Submits the note set during the creation of a ticket.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]=)}
 */
const submitNote = obj => (...args) => {
  cancelOnError(descriptor, obj)

  const model = obj[G.MODEL]
  const modelState = model[G.STATE]
  const modelStateData = modelState[G.DATA]
  const { value: { postponeDate: date, statusReason }, refs: { team, assignee } } = modelStateData

  const type = types[statusReason] || (assignee && 'assign') || (team && 'forward')

  const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const { finish } = asObject(stepper[G.CHILDREN])
  const { note } = asObject(finish[G.CHILDREN])

  const text = get(note)

  text && addNote(obj, null, {
    text,
    team,
    assignee,
    date,
    type,
  })

  return args
}

export default submitNote
