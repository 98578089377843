/* global G */
import { curry, setKey } from 'lib/util'

/**
 * Decorator to retrieve valid G.DATA value and set it in G.CACHE
 *
 * G.DATA will always contain VALID User interaction results
 * G.DATA will probably be non existent, if hasn't been validated
 *
 * we get the value from the component G.STATE, if G.DATA is empty (invalid)
 *
 * @param key
 * @param obj
 * @param component
 */
const fn = (key, obj, component) => setKey(
  obj[G.DATA][key] || component[G.STATE].value,
  G.CACHE,
  obj[G.CHILDREN][key],
)

export default curry(fn)
