/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import map from 'lib/sequence/model/api/map'
import { hide, show, hidden } from 'lib/sequence/component/state/hidden'
import { get, reset, set } from 'lib/sequence/component/state/value'
import { check, uncheck } from 'lib/sequence/component/state/checked'
import { asyncPipeSpread, asyncPipeSpreadIf, getFirstItem } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { CONFIRM } from 'app/_shared/events/setStepTab'
import sequenceComponentState from 'lib/sequence/component/state'
import { displayLabels } from './organisation'

const {
  get: activeTab,
} = sequenceComponentState('activeTab')

/**
 * Resets the prefChannel field if the model's requesterContactData attribute doesn't have any value
 * for its prefChannel property.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const resetPrefChannelField = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContactData } = model[G.CHILDREN]
  const { prefChannel } = requesterContactData[G.CHILDREN]
  const { prefChannel: prefChannelField } = components

  !prefChannel[G.CACHE] && reset(prefChannelField)

  return [components, ...args]
}

/**
 * Toggles the display of the list of search results or the list of suggestions depending on whether
 * the search field has any value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayLists = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg } = model[G.CHILDREN]
  const { searchField, list, suggestions } = components

  if (!get(searchField)) {
    if (requesterOrg?.[G.STATE][G.REF]) {
      suggestions[G.STATE][G.REF] = 'requesterOrg'
      show(suggestions)
    } else {
      hide(suggestions)
    }
    hide(list)
  } else {
    hide(suggestions)
    show(list)
  }
  return [components, ...args]
}

/**
 * If the model's requesterOrg attribute has a cache value, marks the step as completed and sets the
 * attribute's name and businessRole to be shown as the step title and subtitle, otherwise sets the
 * step as non-completed and non-confirmed and clears its title and subtitle.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setStepState = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact } = model[G.CHILDREN]
  const { firstName, lastName, businessRole } = requesterContact[G.CHILDREN]
  const { component: step } = components
  const stepState = step[G.STATE]

  if (requesterContact[G.CACHE]) {
    stepState.completed = true
    // filling step's title and subtitle with model data
    stepState.title = `${firstName[G.CACHE]} ${lastName[G.CACHE]}`
    stepState.subtitle = businessRole[G.CACHE]
  } else {
    // setting step as incomplete and unconfirmed
    stepState.completed = false
    stepState.confirmed = false
    // clearing step's title and subtitle
    stepState.title = ''
    stepState.subtitle = ''
  }

  return [components, ...args]
}

/**
 * Toggles the display of the "Pending invitation" and "Not in master data" labels according to the
 * {@param requesterContact}'s state.
 *
 * @param {Gaia.AppModule.Spec} module  the current ticket model
 */
const displayContactLabels = module => (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact } = model[G.CHILDREN]
  const { labels } = components
  const contactLabels = asObject(labels[G.CHILDREN])
  const { waitingForApproval, pendingInvitation, notInMasterData, blocked } = contactLabels

  const pendingInvite = requesterContact[G.CACHE]?.value?.invite

  displayLabels(model, requesterContact, labels)

  model[G.STATE][G.REF] && pendingInvite && hidden(blocked)
    ? show(pendingInvitation)
    : hide(pendingInvitation);

  (!hidden(waitingForApproval)
  || !hidden(blocked))
  && hide(notInMasterData)

  return [components, ...args]
}

/**
 * Toggles the display of the clear button depending on whether the ticket has a paired request.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displaySearchButton = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { request } = model[G.CHILDREN]
  const requestExists = !!request[G.STATE][G.REF]
  const { btnSearchCurrentContact: btnSearch } = components

  // hiding search button if the ticket already has a paired request
  requestExists && hide(btnSearch)

  return [components, ...args]
}

/**
 * Toggles the display of the createRequest field depending on whether the ticket already has a
 * paired request and whether the current user has a person or is invited.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayCreateRequestField = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact, request } = model[G.CHILDREN]
  const { component: step, createRequest: createRequestField } = components
  const { completed: alreadyCompleted } = step[G.STATE]

  const invite = getFirstItem(requesterContact[G.CACHE].value?.invite)
  const isInvited = invite?.value || invite

  const ticketExists = !!model[G.STATE][G.REF]
  const requestExists = !!request[G.STATE][G.REF]
  const requesterContactUser = requesterContact[G.CACHE].value?.count?.user?.person?.data
  const isDirty = !!createRequestField[G.STATE].value
  // Previously, the requesterContact had to have canBePublishedTo in order for the createRequest
  // checkbox to be shown. Now they just need to have a related user
  // const availableUserRoles = requesterContactUser?.roles || []
  // const canBePublishedTo = getUserRole(
  //   availableUserRoles, module, component, null,
  // )?.canBePublishedTo

  // Controlling visibility of createRequest checkbox
  if (ticketExists) {
    !requestExists && (/* canBePublishedTo || */ requesterContactUser || isInvited)
      ? show(createRequestField)
      && !isDirty
      && uncheck(createRequestField)
      && reset(createRequestField)
      : hide(createRequestField)
  } else {
    !requestExists && (/* canBePublishedTo || */ requesterContactUser || isInvited)
      ? show(createRequestField)
      && !alreadyCompleted
      && check(createRequestField)
      && set(createRequestField)
      : hide(createRequestField)
      && !alreadyCompleted
      && reset(createRequestField)
  }

  return [components, ...args]
}

/**
 * Maps the current model's requesterContact attribute to the step's confirm view.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapRequesterContact = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact, requesterContactData } = model[G.CHILDREN]
  const { organisation } = requesterContact[G.CHILDREN]
  const { prefChannel } = requesterContactData[G.CHILDREN]
  const { confirm, prefChannel: prefChannelField } = components
  const { contact, contactOrganisation } = find(confirm)

  map(requesterContact)(contact)
  map(organisation)(contactOrganisation)

  !get(prefChannelField)
    && prefChannel[G.CACHE]
    && set(prefChannelField, prefChannel[G.CACHE])

  return [components, ...args]
}

/**
 * Displays information labels about the contact's organisation only if it exists and differs from
 * the ticket's requesterOrg.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayRequesterContactOrganisation = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact, requesterOrg } = model[G.CHILDREN]
  const { organisation } = requesterContact[G.CHILDREN]
  const { contactOrganisation } = components

  organisation[G.STATE][G.REF]
  && organisation[G.STATE][G.REF] !== requesterOrg[G.STATE][G.REF]
    ? show(contactOrganisation)
    : hide(contactOrganisation)

  return [components, ...args]
}

/**
 * Toggles the display of the prefChannel field only if the current user has a person or is invited.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayPrefChannelField = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact } = model[G.CHILDREN]
  const { component: step, prefChannel: prefChannelField } = components
  const { completed: alreadyCompleted } = step[G.STATE]

  const invite = getFirstItem(requesterContact[G.CACHE].value?.invite)
  const isInvited = invite?.value || invite

  const requesterContactUser = requesterContact[G.CACHE].value?.count?.user?.person?.data
  // Controlling visibility of prefChannel checkbox
  !(requesterContactUser || isInvited)
    ? show(prefChannelField)
    : hide(prefChannelField)
    && !alreadyCompleted
    && reset(prefChannelField)

  return [components, ...args]
}
/**
 * Sets the current selected contacts G.REF as the value for the avatar component so it can
 * correctly fetch their avatar.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setPersonRefForAvatar = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact } = model[G.CHILDREN]
  const personKey = requesterContact[G.STATE][G.REF]

  const { avatar } = components

  set(avatar, personKey)

  return [components, ...args]
}
/**
 * Contact identification step.
 *
 * If no contact is selected, displays a list to search among them and a button to add a new
 * one. Otherwise, sets the step as complete and displays the selected contact's data together
 * with buttons to edit it, to clear the confirm view and to go to the next step.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => await asyncPipeSpread(
  asyncPipeSpreadIf(() => activeTab(component) === CONFIRM)(
    displayPrefChannelField(module),
    setPersonRefForAvatar(module),
    displayRequesterContactOrganisation(module),
    mapRequesterContact(module),
    displayCreateRequestField(module),
    displaySearchButton(module),
    displayContactLabels(module),
  ),
  asyncPipeSpreadIf(() => activeTab(component) !== CONFIRM)(
    displayLists(module),
    resetPrefChannelField(module),
  ),
  setStepState(module),
)(find(component), ...args)
