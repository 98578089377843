/* eslint-disable no-unused-expressions */
/* global G */
import { isArr, isStr } from 'lib/util'

/**
 * Checks whether an attribute of the current model, which should contain another model (i.e. a
 * child model) is empty.
 *
 * @param {Gaia.Model} obj                  the model to validate
 * @returns {function(*, *): Promise<*[]>}
 */
export default obj => async (uiState, key) => {
  const { options } = obj[G.CONFIGURATION].validator[key]
  const { error } = options
  const { value } = uiState;
  // if uiState value is undefined (but not 0) or is an empty array (the field was cleared),
  // field is invalid
  (value === undefined || ((isArr(value) || isStr(value)) && !value.length))
    && throw TypeError(error)
  // otherwise, field is valid
  return [uiState, key]
}
