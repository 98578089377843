/**
 * Simple search predicate.
 *
 * @param {Object} node the current node
 * @param {String} term search term
 * @returns {boolean}
 */
const isInName = (node, term) => node.name.toLowerCase().indexOf(term.toLowerCase()) !== -1

export default isInName
