/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import search from 'app/_shared/events/search'
import destroy from 'app/_shared/events/destroy'
import dialog from 'app/_shared/events/dialog'

/**
 * Asks the server for tickets where {@code ref} has {@code value}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {string} type                 type to check
 * @returns {function(*, *): Promise<*|(function(...[*]): (*))>}
 * @private
 */
const _checkTickets = (module, type) => async (ref, value) => await search(
  null, module, module[G.STATE][G.ACTION][G.COMPONENT], {
    type,
    filter: { [ref]: value },
  },
)

/**
 * Delete If No SubItems Event Handler
 *
 * Attempts to delete the current person. Checks if there are tickets or requests where
 * {@code refs.requesterContact} equals the person. If so, shows an error dialog. If not,
 * it attempts to delete the person.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<void>}
 */
const destroyIfNoSubItems = async (module, component, event) => {
  try {
    const tickets = await _checkTickets(module, 'ticket')('requesterContact', module[G.MODEL][G.STATE][G.REF])
    const requests = await _checkTickets(module, 'request')('requesterContact', module[G.MODEL][G.STATE][G.REF])

    if (tickets?.length || requests?.length) {
      await dialog(module, component, {
        cancel: false,
        title: {
          ns: 'admin',
          key: 'dialog.deleteContactError.title',
          defaultValue: 'Deletion not possible',
        },
        text: {
          ns: 'admin',
          key: 'dialog.deleteContactError.text',
          defaultValue: 'User is related to request(s). Please unrelate user from all requests then delete. Alternatively, set user status to obsolete or archived.',
        },
      })
      return
    }

    await destroy(module, component, event)
  } catch (e) {
    console.error(e)
  }
}

export default curry(destroyIfNoSubItems)
