/* global G */
import { curry } from 'lib/util'
import { persistOptions } from 'app/_shared/events'
import { isAction } from 'app/_shared/action/util'

/**
 * Event Handler Show Dialog And Persist Options
 *
 * Executes {@param component[G.EVENTS].dialog} that may or may not show a confirmation dialog
 * based on the logic in the event handler. If it returns {@code true}, it will execute
 * {@link persistOptions}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>}
 */
const showDialogAndPersistOptions = async (module, component, event) => {
  try {
    if (isAction(module, 'edit')) {
      const confirmed = await component[G.EVENTS].dialog?.(event)
      confirmed && persistOptions(module, component, event)

      module[G.ADAPTER][G.UI].create(module)
    } else {
      persistOptions(module, component, event)
    }
  } catch (e) {
    console.error(e)
  }
}

export default curry(showDialogAndPersistOptions)
