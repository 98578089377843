/* global G */
import { curry } from 'lib/util'
// import fromComponent from 'model/_shared/transformer/fromComponent'

// const descriptor = 'model::transformer::documentCopy'

/**
 * Document Copy Transformer
 *
 * It copies document payload contents of model attribute, identified by key parameter,
 * into model attribute, identified by target parameter
 *
 * It imperatively executes fromComponent() transformer
 *
 * @param {string} key - identifier of model attribute, a sub-model, containing the document payload
 * @param {string} target - identifier of model attribute, where the payload should be copied to
 * @param {Gaia.Model.Spec} obj - data model, having key and target model attributes as children
 * @param {object} data - obj[G.STATE][G.DATA], see sequence model transform()
 * @return {*}
 */
const fn = (key, target, obj, data) => {
  // console.warn(descriptor, key)
  const _data = data
  // console.log('before fromComponent', obj[G.CHILDREN][key][G.STATE].value)
  // console.log('before fromComponent', obj[G.CHILDREN][key][G.CACHE])
  // console.log('before fromComponent', obj[G.CHILDREN][key][G.DATA].value)

  // we use existing transformer fromComponent in order to populate the ref
  // todo: we should prolly resolve to using G.TRANSFORMER set in place on the data model
  //  consider the fact, that atm we cannot really set the order to transformers iteration.
  // fromComponent(key, obj, data)

  // we copy the data into the target attribute
  const item = obj[G.CHILDREN][target]
  const { type } = item[G.PROPS]

  try {
    // obj[G.CHILDREN][key][G.STATE].value will be empty, if attribute is a model
    // obj[G.CHILDREN][key][G.DATA].value will be set,
    // via validate() whether attribute is a model or not ( or read()?)
    const { value: [value] } = obj[G.CHILDREN][key][G.DATA]
    _data[type][target] = value
    // todo: double checky checky, what the hecky!!!
    // todo: create new, delete, select -> wtf in refs
    // todo: check reset of sub models
  } catch (e) {
    // the only possible error to arise here, would be destructuring of a non existing value array
    // ie, the key attribute wasn't set
    _data[type][target] = null
  }

  return _data
}

export default curry(fn)
