/* eslint-disable no-fallthrough */
/* global G */
import search from 'app/_shared/events/search'
import { asyncPipeSpread, isNum, setKey } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { isAction } from 'app/_shared/action/util'

/**
 * Attempts to obtain a product by its exact name.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @param {string} name
 * @returns {Promise<*|null>}
 * @private
 */
const _findProductByName = async (module, name) => {
  const options = { term: `"${name}"`, type: 'product' }
  const products = await search(null, module, module[G.STATE][G.ACTION][G.COMPONENT], options)
  return products.find(product => product.value.name === name) || null
}

/**
 * Sets the module state based on errors in the model. It will pass the result as
 * {@code validationError} down the chain so that following function can skip their functionality
 * if an error is present.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setModuleState = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const validationError = model[G.STATE][G.ERROR]
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = !!validationError

  return [validationError, children, ...args]
}

/**
 * Sets the correct {@code product} for the service item.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {(function(*, *, ...[*]): Promise<*>)|*}
 */
const setProduct = module => async (validationError, children, ...args) => {
  // Skip this function if we have a validation error
  if (validationError) return [validationError, children, ...args]

  // model
  const model = module[G.MODEL]
  const { name } = model[G.DATA]
  const { equipment, product } = model[G.CHILDREN]

  if (!equipment[G.CACHE]?.length) {
    const foundProduct = await _findProductByName(module, name)
    model[G.DATA].product = foundProduct ? [foundProduct] : []
    setKey(foundProduct, G.CACHE, product)
    setKey(foundProduct?.key || null, G.REF, product[G.STATE])
  }

  return [validationError, children, ...args]
}

/**
 * Sets a default status in case there isn't one set already (by IA for example) when we are
 * creating and service item.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {(function(*, *, ...[*]): Promise<*[]>)|*}
 */
const setStatusOnCreate = module => async (validationError, children, ...args) => {
  // Skip this function if we have a validation error
  if (validationError) return args

  // model
  const model = module[G.MODEL]

  if (isAction(module, 'new')) {
    // Defaulting to 50 if no status is set (0 is a valid status as well!)
    const statusData = model[G.DATA]?.status
    model[G.DATA].status = !isNum(statusData) ? 50 : statusData
  }

  return args
}

/**
 * ServiceItem action submit
 *
 * For each service item created/edited, assigns it active (50) status and the product contained
 * inside its equipment. If it has no equipment assigned, it attempts to obtain a product with the
 * same name as the service item and assigns it.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  setModuleState(module),
  setProduct(module),
  setStatusOnCreate(module),
)(sequenceComponentFind(component), ...args)
