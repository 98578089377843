/* eslint-disable no-unused-vars */
/* global G */
import list from 'app/_shared/events/breadcrumbs/list'
import jump from 'app/_shared/events/breadcrumbs/jump'

const breadcrumbsEventHandlers = {
  list,
  jump,
}

export {
  breadcrumbsEventHandlers as default,
  list,
  jump,
}
