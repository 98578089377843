/* global G */
import { setKey } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import refresh from 'lib/sequence/model/api/refresh'
import decorate from 'lib/sequence/model/decorator'
import map from 'lib/sequence/model/api/map'

export const _getCurrentTab = component => (tab) => {
  const { tabs } = asObject(component[G.CHILDREN])
  const { [tab]: currentTab } = asObject(tabs[G.CHILDREN])

  return currentTab
}

/**
 * Invite action
 *
 * Every screen of the invite flow has its own action. This action only sets the {@code key} and
 * {@code token} for the other actions to use.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => {
  // model
  const model = module[G.MODEL]
  const { person: personModel } = model[G.CHILDREN]
  const { contactChannels } = personModel[G.CHILDREN]

  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const { version } = model[G.PROPS]
  const [id, token] = module[G.ADAPTER][G.SESSION][G.STATE][G.REF].split('/')

  // Temporarily saving the id and token so that subsequent actions can access it.
  setKey(id, 'id', module[G.STATE])
  setKey(token, 'token', module[G.STATE])

  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const currentTab = _getCurrentTab(actionComponent)('contact')
  const { contactInformation } = asObject(currentTab[G.CHILDREN])

  const welcomeTab = _getCurrentTab(actionComponent)('welcome')
  const { welcomeSection } = asObject(welcomeTab[G.CHILDREN])

  try {
    const registerUrl = `/api/v${version}/public/register/${id}`
    const params = { token }

    const middleware = ({ loader, error, invite }) => [loader, error, invite]
    const response = await httpAdapter.get({ url: registerUrl, params }, { middleware })
    const person = response?.person

    // Setting inviter information in translation
    const inviteInformation = person.value?.invite
    const inviter = `${inviteInformation?.inviterFirstname} ${inviteInformation?.inviterLastname}`
    setKey({ inviter }, 'token', welcomeSection[G.STATE])
    setKey(inviteInformation.role, 'inviteeRole', module[G.STATE])

    // Fill the model's G.CACHE from the response.
    await refresh(personModel, person)

    /**
     * We need to execute {@code fromContactChannels} decorator to map the individual channels to
     * each {@code G.CHILDREN}'s {@code G.STATE.value}. Otherwise email and password won't show up
     * in the form.
     */
    decorate(personModel)

    /**
     * We have saturated the model with response we got from the server. It now has {@code G.CACHE}
     * set properly. Let's map stuff.
     */
    map(personModel)(contactInformation)
    map(contactChannels)(contactInformation)
  } catch (e) {
    console.error(e)
  }

  return args
}
