/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Alters the current action's UI flags by shallow-merging {@param flags} into it.
 *
 * It is important to note that this method alters the cached configuration for the duration of the
 * application's execution.
 *
 * @param {Object} flags                  the UI flags to set for the current action
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Event} event                   the event object that triggered this handler
 * @returns {Promise<*>}
 */
const flag = async (flags, module, component, event) => {
  const action = module[G.STATE][G.ACTION]
  const ui = action[G.UI]

  Object.keys(flags).forEach((key) => {
    ui[key] = flags[key]
  })
}

export default curry(flag)
