/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import { asyncPipeSpread, bulk, getFirstItem, isArr, isObj, setKey } from 'lib/util'
import { empty } from 'lib/util/object'
import { get, set } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'
import asObject from 'lib/sequence/component/children/asObject'
import swapListSerialAndName from 'app/_shared/action/partial/swapListSerialAndName'
import listOrganisationTypes from 'app/_shared/events/collection/listOrganisationTypes'
import internalOrganisations from 'model/organisation/collection/internal'

const display = x => (get(x) ? show(x) : hide(x))
const bulkDisplay = bulk(display)

/**
 * Checks if {@param model} has a ref {@code parent} that is an {@link internalOrganisations}.
 *
 * @param {Gaia.Model.Spec} model current module composition
 * @returns {*|boolean}
 */
// TODO: Refactor this after we added a flag server side instead of needing to check of ID
export const hasInternalParent = (model) => {
  const parentCache = model[G.CHILDREN]?.parent
    ? getFirstItem(model[G.CHILDREN]?.parent?.[G.CACHE])
    : getFirstItem(model[G.CACHE])?.refs?.parent
      || getFirstItem(model?.refs?.parent)
      || {}
  const parentKey = parentCache?.key || parentCache

  return parentKey?.length
    ? internalOrganisations.find(x => x.key === getFirstItem(parentKey)) || false
    : false
}

/**
 * Maps various properties inside the property box.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapProperties = module => async (children, ...args) => {
  const model = module[G.MODEL]

  const {
    header,
    contactChannels,
    organisationInfo,
    organisationHeader,
    organisationStatus,
  } = children

  const bulkMap = bulk(map(model))
  bulkMap(header, contactChannels, organisationInfo, organisationHeader, organisationStatus)

  return [children, ...args]
}

/**
 * Presets {@code type}.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetType = module => component => async (children, ...args) => {
  const model = module[G.MODEL]

  const { type } = children

  const organisationType = getFirstItem(
    listOrganisationTypes(module, component, model[G.CHILDREN].type[G.CACHE]),
  )?.value

  set(type, organisationType)

  return [children, ...args]
}

/**
 * Presets {@code supportedBy}.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetSupportedBy = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const supportedByOrg = getFirstItem(model[G.CHILDREN].supportedBy?.[G.CACHE])?.value?.name

  // component
  const { supportedBy } = children

  supportedByOrg && set(supportedBy, supportedByOrg)

  return [children, ...args]
}

/**
 * Presets {@code summary}.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetSummary = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const addressCache = model[G.CHILDREN].address[G.CACHE]
  const localizedAddress = addressCache ? module[G.ADAPTER][G.INTL].address(addressCache) : null

  // component
  const { summary } = children

  set(summary, localizedAddress)

  return [children, ...args]
}

/**
 * Presets {@code checkForDuplicates}.
 *
 * Determines if the user should check the org for duplicates. They should if the organisations
 * {@code duplicateOf} ref is empty or non-existent and the {@code toBeValidated} property is set.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const presetDuplicateStatus = module => component => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const parent = model[G.CHILDREN]?.parent || {}
  const modelCache = getFirstItem(model?.[G.CACHE]) || {}
  const parentCache = getFirstItem(parent[G.CACHE]) || {}

  const { duplicateOf = [] } = modelCache?.refs || {}
  const { toBeValidated = {} } = modelCache?.value || {}

  const isDuplicate = duplicateOf && isArr(duplicateOf) && duplicateOf.length
  const isDueValidation = toBeValidated && isObj(toBeValidated) && !empty(toBeValidated)
  const showDuplicateStatus = parentCache
      && (hasInternalParent(model) || hasInternalParent(parent))

  // component
  const { checkForDuplicates } = children

  // eslint-disable-next-line no-nested-ternary
  const status = isDuplicate
    ? [{
      key: 'duplicate',
      icon: 'fiber_manual_record',
      color: 'warning',
      value: module[G.ADAPTER][G.INTL]._t(
        'label.duplicate',
        {
          ns: 'common',
          _key: 'label.duplicate',
          defaultValue: 'Duplicate',
        },
      ),
    }]
    : !isDuplicate && isDueValidation && showDuplicateStatus
      ? [{
        key: 'check_for_duplicates',
        icon: 'fiber_manual_record',
        color: 'warning',
        value: module[G.ADAPTER][G.INTL]._t(
          'label.checkForDuplicates',
          {
            ns: 'common',
            _key: 'label.checkForDuplicates',
            defaultValue: component[G.STATE].value,
          },
        ),
      }] : [{}]

  getFirstItem(status)?.key ? set(checkForDuplicates, status) : hide(checkForDuplicates)

  return [children, ...args]
}

/**
 * Presets {@code checkForDuplicates}.
 *
 * Determines if the user should check the org for duplicates. They should if the organisations
 * {@code duplicateOf} ref is empty or non-existent and the {@code toBeValidated} property is set.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(*): function(*, ...[*]): Promise<[{color: string, icon: string, value: *, key: string}]|[{}]|*>}
 */
export const presetApprovalStatus = module => component => parent => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const isServiceItem = model[G.CONFIGURATION].module === 'serviceItem'
  const parentModel = model[G.CHILDREN]?.[parent] || {}
  const duplicateOf = getFirstItem(model[G.CHILDREN]?.duplicateOf?.[G.CACHE])

  // In a first step, we check both parent and grandparent for key ORGANISATION:RA
  const parentCache = getFirstItem(parentModel?.[G.CACHE])

  // Instead of accessing parentModel[G.CHILDREN] to get the grandparent, lets access its G.CACHE
  // directly, that way it'll also work if the model hasn't been resolved by the server
  const grandparentCache = getFirstItem(parentCache?.refs?.parent)
  const grandparentKey = grandparentCache && isObj(grandparentCache)
    ? grandparentCache?.key
    : grandparentCache

  const hasInternalParents = hasInternalParent(model) || hasInternalParent(parentModel)

  // If the desired key is not present in both parent and grandparent AND the entity is a
  // service item, we also check the grand grandparent for it
  let hasInternalBaseParent = false
  if (!hasInternalParents && grandparentKey && isServiceItem) {
    const httpAdapter = model[G.ADAPTER][G.HTTP]
    const { version, api } = model[G.CONFIGURATION].options
    const url = `/api/v${version}/${api}/${grandparentKey}`

    const result = await httpAdapter.get({ url })
    const baseParent = getFirstItem(result)
    hasInternalBaseParent = hasInternalParent(baseParent)
  }

  // component
  const { approval } = children

  const status = (hasInternalParents || hasInternalBaseParent) && !duplicateOf
    ? [{
      key: 'to_be_approved',
      icon: 'fiber_manual_record',
      color: 'signal',
      value: module[G.ADAPTER][G.INTL]._t(
        'label.toBeApproved',
        {
          ns: 'common',
          _key: 'label.toBeApproved',
          defaultValue: component[G.STATE].value,
        },
      ),
    }]
    : [{}]

  getFirstItem(status)?.key ? set(approval, status) : hide(approval)

  return [children, ...args]
}

/**
 * Presets {@code parent} and {@code name}.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetParent = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const parentCache = model[G.CHILDREN].parent?.[G.CACHE]

  // component
  const { parent, parentProperty } = children
  const parentOrg = getFirstItem(parentCache) || {}

  // only do this, if the parent is not ORGANISATION:RA
  if (parentCache && !hasInternalParent(model)) {
    const { name } = asObject(parent[G.CHILDREN])
    setKey(parentOrg?.key, 'key', parent[G.STATE])
    set(name, parentOrg?.value?.name)
  } else {
    hide(parentProperty)
  }

  return [children, ...args]
}

/**
 * Determines if {@code toBeValidated} and {@code validation} should be shown.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayToBeValidated = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const validationCache = model[G.CHILDREN].toBeValidated?.[G.CACHE]
  const { reason = null } = validationCache || {}

  // component
  const { toBeValidated, validation } = children

  // If org has no data that needs to be validated, hide the status and text
  if (empty(reason)) hide(toBeValidated)
  if (empty(validationCache)) hide(validation)

  return [children, ...args]
}

/**
 * Determines if {@code supportUnit} should be shown.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displaySupportUnit = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const isSupportUnit = model[G.CACHE]?.value?.support?.provides

  // component
  const { supportUnit } = children

  // Only show support unit batch if applicable
  isSupportUnit ? show(supportUnit) : hide(supportUnit)

  return [children, ...args]
}

/**
 * Determines if various properties inside the property box should be shown or not.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayProperties = module => async (children, ...args) => {
  // component
  const { number, phone, fax, email, website, supportedBy, summary } = children

  bulkDisplay(number, phone, fax, email, website, supportedBy, summary)

  return [children, ...args]
}

/**
 * Organisation Action Detail
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  mapProperties(module),
  presetType(module)(component),
  presetSupportedBy(module),
  presetSummary(module),
  presetParent(module),
  presetDuplicateStatus(module)(component),
  presetApprovalStatus(module)(component)('parent'),
  displayToBeValidated(module),
  displaySupportUnit(module),
  displayProperties(module),
  swapListSerialAndName(module, 'ticket', 'list'),
)(sequenceComponentFind(component), ...args)
