/* eslint-disable no-unused-vars */
/* global G */
import { curry, setKey } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { uncheck } from 'lib/sequence/component/state/checked'
import { get, set, reset } from 'lib/sequence/component/state/value'
import sequenceComponentState from 'lib/sequence/component/state'
import modelReset from 'lib/sequence/model/api/reset'
import find from 'lib/sequence/component/children/find'

const { clear } = sequenceComponentState('value')
const { unset: deconfirm } = sequenceComponentState('confirmed')

/**
 * Returns whether requesterOrg already exists or is marked to be stored.
 *
 * @param {Gaia.AppModule.Spec} module   the current module object composition
 * @returns {boolean}                 whether requesterOrg does not exist and won't be stored
 */
const storedRequesterOrg = (module) => {
  const { requesterOrg } = module[G.MODEL][G.CHILDREN]

  const requesterOrgCache = requesterOrg[G.CACHE]
  const requesterOrgExists = !!requesterOrgCache?._rev
  const storeRequesterOrg = requesterOrg[G.STATE][G.BULK]

  return requesterOrgCache && (requesterOrgExists || storeRequesterOrg)
}

/**
 * Clear's the field with name {@param fieldName} from the model's attribute with name
 * {@param attributeName} and resets the field {@param fieldName} from {@param actionName} if
 * the value of component's {@param fieldName} is requesterOrg.
 *
 * @param {Gaia.AppModule.Spec} module   the current module object composition
 * @param {string} attributeName      the name of the model's attribute where {@param fieldName}
 *                                    is to be found
 * @param {string} actionName         the name of the action where {@param fieldName} is to be found
 * @param {string} fieldName          the name of te field to clear and reset
 * @return {boolean}                  always {@code true}
 */
const unsetParentRequesterOrg = (module, attributeName, actionName, fieldName) => {
  const { requesterOrg, [attributeName]: attribute } = module[G.MODEL][G.CHILDREN]
  const { [fieldName]: organisation } = attribute[G.CHILDREN]

  const createComponent = module[G.ACTIONS].create[G.COMPONENT]
  const component = module[G.ACTIONS][actionName][G.COMPONENT]
  const { stepper } = asObject(createComponent[G.CHILDREN])
  const { [actionName]: step } = asObject(stepper[G.CHILDREN])
  // Check whether the step exists in order to also modify the model
  if (step) {
    const { [fieldName]: field, buttons } = asObject(component[G.CHILDREN])
    const { store: storeField } = find(component[G.ACTIONS][0])

    const requesterOrgCache = requesterOrg[G.CACHE]
    const selectedOrganisation = get(field)?.[0]
    const organisationIsRequesterOrg = selectedOrganisation?.key === requesterOrgCache?.key

    organisationIsRequesterOrg
    && deconfirm(step)
    && uncheck(storeField)
    && reset(storeField)
    && clear(field)
    && modelReset(organisation)
  }

  return true
}

/**
 * Updates the value of {@param fieldName} from the model's attribute with name
 * {@param attributeName} and the value of the field {@param fieldName} from {@param actionName} if
 * the value of component's {@param fieldName} is requesterOrg.
 *
 * @param {Gaia.AppModule.Spec} module   the current module object composition
 * @param {string} attributeName      the name of the model's attribute where {@param fieldName}
 *                                    is to be found
 * @param {string} actionName         the name of the action where {@param fieldName} is to be found
 * @param {string} fieldName          the name of te field to update
 * @return {boolean}                  always {@code true}
 * @returns {Promise<boolean>}
 */
const updateParentRequesterOrg = async (module, attributeName, actionName, fieldName) => {
  const { requesterOrg, [attributeName]: attribute } = module[G.MODEL][G.CHILDREN]
  const { [fieldName]: organisation } = attribute[G.CHILDREN]

  const component = module[G.ACTIONS][actionName][G.COMPONENT]
  const { [fieldName]: field } = asObject(component[G.CHILDREN])

  const requesterOrgCache = requesterOrg[G.CACHE]
  const selectedOrganisation = get(field)?.[0]
  const organisationIsRequesterOrg = selectedOrganisation?.key === requesterOrgCache?.key;

  (!selectedOrganisation || organisationIsRequesterOrg)
    && set(field, [requesterOrgCache])
    && setKey([requesterOrgCache], G.CACHE, organisation)

  return true
}

/**
 * Modifies model's attributes and form actions by updating or removing organisation from
 * requesterContact and parent from additionalParty if those organisations are requesterOrg. If
 * {@param forceRemove} is {@code true}, it will remove them, if it is false, it will do so only if
 * requesterOrg does not yet exist and is not marked to be stored, otherwise they will be updated.
 *
 * @type {Gaia.AppModule.EventHandler}
 * @param {boolean} forceRemove                       whether to just perform the removal operation
 */
export default curry(async (forceRemove, module, component, event) => {
  forceRemove || !storedRequesterOrg(module)
    ? unsetParentRequesterOrg(module, 'requesterContact', 'contact', 'organisation')
      && unsetParentRequesterOrg(module, 'additionalParty', 'party', 'parent')
    : await updateParentRequesterOrg(module, 'requesterContact', 'contact', 'organisation')
      && await updateParentRequesterOrg(module, 'additionalParty', 'party', 'parent')
})
