/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import reset from 'app/_shared/component/reset'
import { uncheck } from 'lib/sequence/component/state/checked'
import { actionWithHooks } from 'lib/sequence/module/action'

// /**
//  * Event Modal Close
//  * @param module
//  * @param component
//  * @param event
//  * @return {Promise<void>}
//  */
// // eslint-disable-next-line no-unused-vars
// const close = (module, component, event) => module[G.ADAPTER][G.ROUTER].restore()
//
//
// export default curry(close)

/**
 * Resets {@param component} recursively.
 *
 * @param {Gaia.Component.Spec} component   the current action's main component
 */
const bulkReset = (component) => {
  reset(component)
  component[G.STATE]?.checked && uncheck(component)
  component[G.CHILDREN]?.length && component[G.CHILDREN].forEach((child) => { bulkReset(child) })
}

/**
 * Close Modal Event Handler
 *
 * Resets the moduleState's {@code G.ERROR} prop and {@param target} component.
 * Used for when an user started the feedback validation, but then aborted it.
 *
 * @param {string} target                   key of the modal component
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 */
const close = async (target, module, component, event) => {
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = null

  const { [target]: targetComponent } = sequenceComponentFindProxy(module[G.STATE][G.ACTION][G.COMPONENT])
  targetComponent && bulkReset(targetComponent)

  await actionWithHooks(module[G.STATE][G.ACTION])([])
}

export const closeModalWith = curry(close)

export default curry((module, component, event) => close('modal', module, component, event))
