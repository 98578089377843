/* eslint-disable no-new */
import { curry, isObj, isStr, PlatformEvent } from 'lib/util'
import search from 'app/_shared/events/search'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import { detail } from 'app/_shared/events'
import settings from '@tenant/settings'

/**
 * Shows a dialog informing the user that the scanned code is a URL and asks them whether they want
 * to open it.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {Promise<boolean>}
 */
const showUrlDialog = async (module, url) => await showBlockingDialog(module, null, {
  ...(url.includes('tel') && {
    title: {
      ns: 'common',
      key: 'dialog.urlScan.title.phone',
      defaultValue: 'Phone number',
    },
  }) || (url.includes('mailto') && {
    title: {
      ns: 'common',
      key: 'dialog.urlScan.title.mail',
      defaultValue: 'Email Address',
    },
  }) || {
    title: {
      ns: 'common',
      key: 'dialog.urlScan.title.website',
      defaultValue: 'Website Address',
    },
  },
  text: {
    ns: 'common',
    key: 'dialog.urlScan.textMD',
    defaultValue: 'Scanned **{{url}}**.',
    md: true,
    url: url.replace(/^(tel|mailto):/g, ''),
  },
  ok: {
    ns: 'common',
    key: 'button.open',
    defaultValue: 'Open',
  },
})

/**
 * Shows a dialog informing the user that the scanned code should be handled by the Pin Creator app
 * and asks them whether they want to open it.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {Promise<boolean>}
 */
const showPinCreatorDialog = async module => await showBlockingDialog(module, null, {
  title: {
    ns: 'common',
    key: 'dialog.pinCreatorScan.title',
    defaultValue: 'Pin Creator',
  },
  text: {
    ns: 'common',
    key: 'dialog.pinCreatorScan.textMD',
    defaultValue: 'Please, scan with **Pin Creator App**.',
    md: true,
  },
  ok: {
    ns: 'common',
    key: 'button.open',
    defaultValue: 'Open',
  },
})

/**
 * Shows a dialog informing the user that the scanned code should be handled by the Option
 * Management functionality, which is still in development.
 *
 * TODO: replace when Option Management is done.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {Promise<boolean>}
 */
const showOptionsManagementDialog = async module => await showBlockingDialog(module, null, {
  title: {
    ns: 'common',
    key: 'dialog.optionManagementScan.title',
    defaultValue: 'Option Management',
  },
  text: {
    ns: 'common',
    key: 'dialog.optionManagementScan.text',
    defaultValue: 'Feature coming soon.',
  },
  cancel: false,
})

/**
 * Shows a dialog informing the user that {@param code} cannot be recognized.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {string} code                 a non-recognizable code
 * @return {Promise<boolean>}
 */
const showUnknownCodeDialog = async (module, code) => await showBlockingDialog(module, null, {
  title: {
    ns: 'common',
    key: 'dialog.unknownCodeScan.title',
    defaultValue: 'Unknown code',
  },
  text: {
    ns: 'common',
    key: 'dialog.unknownCodeScan.textMD',
    defaultValue: 'The code **{{code}}** cannot be recognised.',
    md: true,
    code,
  },
  ok: {
    ns: 'common',
    key: 'button.copy',
    defaultValue: 'Copy',
  },
})

/**
 * Attempts to obtain an article whose key contains the string passed in {@param event.detail.value}
 * and, if found, returns its complete key.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             information about the event that triggered this handler
 * @param {object} event.detail             event details
 * @param {string} event.detail.value       string used to search for the ID of an article
 * @return {Promise<*>}
 */
const getArticleKey = async (module, component, event) => {
  const { value } = event.detail || {}
  const id = value.replace(/[A-Za-z|%]/g, '')

  const searchEvent = new PlatformEvent('search', { term: id, type: 'article' })
  const result = await search(null, module, component, searchEvent)

  const article = result.find(item => isStr(item.key) && item.key.endsWith(id))

  return article?.key
}

/**
 * Process Scanned Code Event Handler
 *
 * Attempts to recognize a scanned code and display a dialog accordingly:
 * - If code is a URL, the dialog asks the user whether they want to open it.
 * - If code is a QR code, it attempts to parse the code string as JSON:
 *  - If code can be parsed:
 *    - If code is an object with payload and signature properties, it redirects to OpenManagement.
 *    - Otherwise, it asks the user whether they want to open the Pin Creator application.
 *  - If code cannot be parsed, it shows a dialog informing that the code cannot be recognized.
 * - If code isn't a QR code, attempts to find an article whose key contains the code string:
 *  - If an article is found, it redirects the user to it.
 *  - Otherwise, it shows a dialog informing that the code cannot be recognized.
 *
 * If the result of displaying a dialog is {@code true} (i.e. the user has clicked the 'ok' option),
 * in most cases, this event handler returns an object to be handled by the component that called
 * it.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             information about the event that triggered this handler
 * @param {Object} event.detail             event details
 * @param {string} event.detail.type        the type of scanned code
 * @param {string} event.detail.value       the scanned code
 * @return {Promise<Object|boolean>}
 */
const processScannedCode = async (module, component, event) => {
  const { type = 'qr', value } = event.detail || {}

  try {
    new URL(value)
    return await showUrlDialog(module, value) && { link: value }
  } catch (ignored) {}

  if (type === 'qr') {
    try {
      const valueObj = JSON.parse(value.replace(/\n/g, ''))

      return isObj(valueObj) && valueObj.payload && valueObj.signature
        ? await showOptionsManagementDialog(module) && false
        : await showUnknownCodeDialog(module, value) && { clipboard: value }
    } catch (ignored) {}

    return await showPinCreatorDialog(module) && {
      link: settings.pinCreatorLink,
      store: {
        android: settings.pinCreatorAndroidStoreLink,
        ios: settings.pinCreatorIOSStoreLink,
      },
    }
  }

  const key = await getArticleKey(module, component, event)

  return key
    ? await detail(module, component, new PlatformEvent(event, { key })) && false
    : await showUnknownCodeDialog(module, value) && { clipboard: value }
}

export default curry(processScannedCode)
