/* global G */
import { curry } from 'lib/util'

/**
 * data entry acl getter
 * this is a mandatory event handler, used in all ACL UI components
 * it allows working with protected acl property of the component
 * which is obfuscated from the UI layer
 *
 * it is strongly encouraged to use it ONLY in UI iterators such as AclList
 *
 * @example config
 * { ..., options: { ..., events: { ..., acl: 'data', ... }}}
 * @example module/[name]/events.js
 * import data from 'app/_shared/acl/data'
 * export default { ..., acl: { data }, ...}
 *
 * @param module - application module
 * @param component - parent component
 * @param acl - data entry acl
 * @return {null || {G.META: string, G.ACL: array, G.PROPS: object}}
 */
const data = (module, component, { acl }) => module[G.ADAPTER][G.ACL].data(component, acl)

export default curry(data)
