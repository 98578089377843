/* global G */
import ui from 'lib/hook/ui/update'

const hooks = {
  [G.ACTIONS]: {
    index: {
      before: [
        ui,
      ],
    },
  },
}

export default hooks
