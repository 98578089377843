/* global G */
import { curry } from '@gaia/util'

/**
 * refresh current data set
 */
// eslint-disable-next-line no-unused-vars
export default curry((module, _) => () => module[G.STATE][G.UPDATE])
// Toast disabled for now, see GAIA-989
//   || module[G.ADAPTER][G.EVENTS].dispatch(
//   module[G.ADAPTER][G.EVENTS].type(G.DATA, G.UPDATE),
//   {
//     message: module[G.ADAPTER][G.INTL]._t(
//       'notification.remoteConflict',
//       { ns: 'common', _key: 'data.conflict', defaultValue: 'Remote conflict, document will not be saved!' },
//     ),
//     severity: 'error',
//     variant: 'filled',
//     duration: 60000,
//     close: true,
//     action: {
//       title: module[G.ADAPTER][G.INTL]._t(
//         'button.reload',
//         { ns: 'common', _key: 'button.reload', defaultValue: 'Reload' },
//       ),
//       handler: () => module[G.ADAPTER][G.ROUTER].action(
//         module[G.ADAPTER][G.SESSION][G.STATE][G.MODULE][G.STATE][G.ACTION]._name,
//       ),
//     },
//   },
// ))
