import { curry } from 'lib/util'
import nodeTypes from 'model/_shared/collection/documentTree/nodeTypes'

/**
 * Returns the corresponding {@param nodeType} in {@link nodeTypes}.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component      the current action's main component
 * @param {String} nodeType                 the node type to get.
 * @returns {Promise<*|undefined>}
 */
const getNodeType = (module, component, nodeType) => nodeTypes.find(type => type.key === nodeType)?.value || 'Root'
export default curry(getNodeType)
