/* global G */
import ui from 'lib/hook/ui/update'
import validate from 'lib/hook/model/validate'
import submit from 'lib/hook/model/submit'
import confirm from 'lib/hook/router/confirm'
import read from 'lib/hook/model/read'
import search from 'app/_shared/hook/appbar/search'
import create from 'app/_shared/hook/appbar/create'
import clear from 'app/_shared/hook/model/clear'
import reset from 'app/_shared/hook/component/reset'
import { begin, confirmation, end } from 'lib/hook/transaction'
import acl from 'lib/hook/acl'
import check from 'app/_shared/hook/check'
import back from 'app/_shared/hook/redirect/back'
import recall from 'app/_shared/hook/component/recall'
import cacheTitle from 'app/_shared/hook/model/cacheTitle'
import updateCache from 'app/_shared/hook/model/updateCache'

const _baseHooks = {
  before: [
    reset,
  ],
  after: [
    ui,
  ],
}
const _newHooks = {
  before: [
    reset,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _editHooks = {
  before: [
    clear,
    reset,
    read,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _detailHooks = {
  before: [
    acl,
    clear,
    read,
    cacheTitle('name'),
    recall,
  ],
  after: [
    ui,
  ],
}

const _searchAndCreateHooks = {
  before: [
    create, // configuring AppBar CreateButton
    search, // configuring AppBar SearchField
    recall,
  ],
  after: [
    ui,
  ],
}

const _submitHooks = {
  before: [
    validate,
  ],
  after: [
    ui, // display any validation errors
    check,
    begin,
    submit,
    updateCache,
    // confirmation('success'),
    back,
    end,
  ],
}

// Its empty as it just shows a dialog box
const _confirmHooks = {
  before: [],
  after: [],
}

const hooks = {
  [G.ACTIONS]: {
    index: _searchAndCreateHooks,
    edit: _editHooks,
    new: _newHooks,
    detail: _detailHooks,
    tickets: _baseHooks,
    requests: _baseHooks,
    persons: _baseHooks,
    serviceItems: _baseHooks,
    submit: _submitHooks,
    organisations: _baseHooks,
    confirm: _confirmHooks,
  },
}

export default hooks
