import { curry } from 'lib/util'
import session from 'app/_shared/session'

/**
 * Returns an array containing an object for each one of the contexts available to the user.
 *
 * @param {Gaia.AppModule.Spec} obj - app module
 * @return {{value: {name: string}, key: string}[]}
 * @private
 */
const _contextList = obj => session(obj).contexts().map(key => ({ key, value: { name: key } }))

/**
 * Context Event Handler
 *
 * Resolves a promise to return the list of contexts available to the user. To be used with listing.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component} component - ui component
 * @param {Event} event - event
 * @return {Promise<object[]>} void
 */
const context = async (module, component, event) => {
  try {
    return Promise.resolve(_contextList(module))
  } catch (e) {
    return Promise.resolve([])
  }
}

export default curry(context)
