import userExists from 'model/_shared/validator/userExists'

export const validators = {
  email: userExists,
}

export {
  validators as default,
  userExists,
}
