/* global G */
import ui from 'lib/hook/ui/update'

const _baseHooks = {
  before: [],
  after: [
    ui,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _baseHooks,
  },
}

export default hooks
