/* eslint-disable no-param-reassign */
/* global G */
import cancelOnError from 'lib/sequence/model/cancelOnError'
import create from 'lib/sequence/model/api/create'

const descriptor = 'hook::updateCache'

/**
 * Executes transformer function for attributes with {@param attributeName} found in {@param model},
 * at any depth.
 *
 * @param {Gaia.Model.Spec} model the current model composition
 * @param {string} attributeName
 */
const transformAttribute = (model, attributeName) => {
  const attributes = model[G.CHILDREN]

  Object.keys(attributes)
    .filter(key => attributes[key][G.CHILDREN])
    .forEach(key => transformAttribute(attributes[key], attributeName))

  const data = model[G.STATE][G.DATA]
  const transformer = model[G.TRANSFORMER]?.[attributeName]
  transformer && data && transformer(model, data)
}

/**
 * Returns an array containing the cache of every list stored in {@param cache}.
 *
 * @param {object} cache  action's cache
 * @returns {*[]}
 */
const gatherListsCache = cache => Object.keys(cache).reduce((agg, key) => {
  const { _cache, ...item } = cache[key]
  _cache?.props?.data && agg.push(_cache)
  return agg.concat(gatherListsCache(item))
}, [])

/**
 * Returns an array containing the cached objects of the previous {@param count} routes.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @param {number} count             the number of routes from which to collect their cache
 * @returns {*}
 */
const gatherRoutesCache = (obj, count = 1) => {
  const stack = obj[G.ADAPTER][G.SESSION][G.STATE][G.PREV]
  return stack.slice(0, count).map(route => route[G.CACHE])
}

/**
 * Update Cache Model Hook
 *
 * If the current model has a key (which should mean it isn't new), it looks for it among the cached
 * list data and, if found, replaces it with the current data.
 *
 * If the hook is executed after a submit or a bulk hook, the condition where the presence of a key
 * means that the model isn't new (has been updated) should be fulfilled.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): *[]}
 */
const updateCache = obj => (...args) => {
  cancelOnError(descriptor, obj)
  const model = obj[G.MODEL]
  const modelKey = model[G.STATE][G.REF]
  const cache = gatherRoutesCache(obj, 2)

  modelKey && cache.forEach(routeCache => gatherListsCache(routeCache).forEach((listCache) => {
    const listData = listCache.props.data
    const index = listData.findIndex(item => item.key === modelKey)

    if (index !== -1) {
      create(model) // we need state data not to be transformed in order to keep submodels
      transformAttribute(model, 'contactChannels')
      transformAttribute(model, 'address')
      listData.splice(index, 1, model[G.STATE][G.DATA])
    }
  }))

  return args
}

export default updateCache
