/* eslint-disable no-unused-vars */
/* global G */
import { curry, getFirstItem } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { actionWithHooks } from 'lib/sequence/module/action'
import { create } from 'app/_shared/events/attachment'

/**
 * Reads the current carts positions from the cart adapter.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 * @returns {Promise<*|*[]>}
 */
const getCartPositions = async (module, component, event) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  const data = await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.CART, G.DONE), ({ detail }) => {
      const { [G.DATA]: cart } = detail
      resolve(cart)
    }, { once: true })
    eventBus.dispatch(eventBus.type(G.CART, G.READ))
  })

  return data?.value?.positions?.length
    ? data.value.positions.map(x => ({ key: x?.key || x.name, value: { ...x } }))
    : []
}

export default curry(getCartPositions)
