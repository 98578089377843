/* eslint-disable no-unused-expressions,no-unused-vars */
/* global G */
import { hide, show } from 'lib/sequence/component/state/hidden'
import map from 'lib/sequence/model/api/map'
import { asyncPipeSpread, bulk, getFirstItem, setKey } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { empty } from 'lib/util/object'
import { presetApprovalStatus } from 'app/organisation/action/detail'
import { get, set } from 'lib/sequence/component/state/value'
import { settings } from 'app/_shared/session'

const display = x => (get(x) ? show(x) : hide(x))
const bulkDisplay = bulk(display)

/**
 * Map various serviceItem fields.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 *
 * @return {function(*, ...[*]): Promise<*[]>}
 */
export const mapServiceItemData = module => async (children, ...args) => {
  const model = module[G.MODEL]
  const { serial } = model[G.CHILDREN]
  const serialValue = serial[G.CACHE]

  const {
    deviceStatuses,
    deviceHeader,
    deviceInfo,
    installedAtProperty,
    serviceByProperty,
  } = children || {}

  const { name } = sequenceComponentFind(deviceHeader)

  const bulkMap = bulk(map(model))
  bulkMap(deviceHeader, deviceStatuses, deviceInfo, installedAtProperty, serviceByProperty)

  settings.swapDeviceSerialAndName && set(name, serialValue)

  return [children, ...args]
}

/**
 * Map toBeValidated data
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 *
 * @return {function(*, ...[*]): Promise<*[]>}
 */
export const mapToBeValidatedData = module => async (children, ...args) => {
  const model = module[G.MODEL]
  const validationCache = model[G.CHILDREN].toBeValidated?.[G.CACHE]
  const { reason = null } = validationCache || {}
  const { header, validation, toBeValidated } = children || {}

  // mapping data
  map(model)(header)

  // conditionally hiding toBeValidated data + checkbox if model has no data
  if (empty(reason)) hide(toBeValidated)
  if (empty(validationCache)) hide(validation)

  return [children, ...args]
}

/**
 * Set the equipment options if available and show/hide the button accordingly.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 *
 * @return {function(*, *, ...[*]): Promise<*[]>}
 */
export const presetEquipmentOpts = module => async (children, equipmentKey, ...args) => {
  const model = module[G.MODEL]
  const { btnEquipmentOpts } = children || {}

  if (!settings.suppressEquipmentOptions) {
    const equipmentData = model[G.CHILDREN].equipment[G.CACHE]

    const [
      { value: { custom: { options: equipmentOpts = [] } = {} } = {} },
    ] = equipmentData || [{}]

    equipmentOpts?.length
      ? setKey(equipmentOpts, 'equipmentOptions', module[G.STATE]) && show(btnEquipmentOpts)
      : hide(btnEquipmentOpts)
  } else {
    hide(btnEquipmentOpts)
  }

  return [children, model, equipmentKey, ...args]
}

/**
 * Fetch and set the equipment documentation according to data.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 *
 * @return {function(*, ...[*]): Promise<*[]>}
 */
export const presetEquipmentDoc = module => async (children, ...args) => {
  const model = module[G.MODEL]
  const { btnEquipmentDoc, noEquipment } = children || {}
  const { version } = model[G.PROPS]
  const httpAdapter = model[G.ADAPTER][G.HTTP]

  const equipmentData = model[G.CHILDREN].equipment[G.CACHE]

  const [{ key: equipmentKey }] = equipmentData || [{}]

  equipmentData ? hide(noEquipment) : show(noEquipment)

  if (!settings.suppressProductDocumentation) {
    const { api: { type: equipmentApi } } = btnEquipmentDoc[G.PROPS]
    const url = equipmentKey ? `/api/v${version}/docs/${equipmentApi}/${equipmentKey}` : null
    const { value: equipmentDoc } = equipmentKey ? await httpAdapter.get({ url }) : {}

    equipmentDoc?.length
      ? setKey(equipmentDoc, 'serviceItemDocumentTree', module[G.STATE]) && show(btnEquipmentDoc)
      : hide(btnEquipmentDoc)
  } else {
    hide(btnEquipmentDoc)
  }

  return [children, equipmentKey, ...args]
}

/**
 * Fetch and set the product documentation according to data.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 *
 * @return {function(*, *, ...[*]): Promise<*[]>}
 */
export const presetProductDoc = module => async (children, equipmentKey, ...args) => {
  const model = module[G.MODEL]
  const { btnProductDoc, noProduct } = children || {}

  const { version } = model[G.PROPS]
  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const productData = model[G.CHILDREN].product[G.CACHE]

  const { api: { type: productApi } } = btnProductDoc[G.PROPS]
  const [{ key: productKey }] = productData || [{}]

  productData ? hide(noProduct) : show(noProduct)

  if (!settings.suppressProductDocumentation) {
    const url = productKey ? `/api/v${version}/docs/${productApi}/${productKey}` : null
    const { value: productDoc } = equipmentKey && productKey ? await httpAdapter.get({ url }) : {}

    productDoc?.length
      ? setKey(productDoc, 'serviceItemProductTree', module[G.STATE]) && show(btnProductDoc)
      : hide(btnProductDoc)
  } else {
    hide(btnProductDoc)
  }

  return [children, productKey, productApi, ...args]
}

/**
 * Shows the {@code partStatus} section depending on whether the setting is enabled and
 * the equipment has {@code partsAvailable} set to {@code false}
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 *
 * @return {function(*, *, *, ...[*]): Promise<*[]>}
 */
export const displayEquipmentPartsAvailable = module => async (children, productKey, productApi, ...args) => {
  const model = module[G.MODEL]
  const { equipment } = model[G.CHILDREN]
  const { partsAvailable } = getFirstItem(equipment[G.CACHE])?.value || {}

  const { partStatus } = children || {}

  partsAvailable === false
    && settings.showEquipmentPartsAvailable
    && show(partStatus)

  return [children, productKey, productApi, ...args]
}

/**
 * Fetch and set the product image.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 *
 * @return {function(*, *, *, ...[*]): Promise<*[]>}
 */
export const presetProductImage = module => async (children, productKey, productApi, ...args) => {
  const model = module[G.MODEL]
  const { product } = children || {}

  const { version } = model[G.PROPS]
  const httpAdapter = model[G.ADAPTER][G.HTTP]

  const productImageUrl = productKey
    ? `/api/v${version}/${productApi}/${productKey}/listing/attachment`
    : null
  const { value: productAttachments } = productKey
    ? await httpAdapter.get({ url: productImageUrl })
    : {}

  product[G.PROPS].attachment = getFirstItem(productAttachments)

  return [children, ...args]
}

/**
 * Show or hide the serviceBy section according to available data.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 *
 * @return {function(*, ...[*]): Promise<*[]>}
 */
export const initServiceBy = module => async (children, ...args) => {
  const model = module[G.MODEL]
  const { serviceByProperty } = children || {}
  const serviceByData = model[G.CHILDREN].serviceBy[G.CACHE]

  serviceByData ? show(serviceByProperty) : hide(serviceByProperty)

  return [children, ...args]
}

/**
 * Hides several properties in the property box if they have no value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
export const displayAdditionalInformation = module => async (children, ...args) => {
  const { locationExtension, placement, comment } = children || {}

  bulkDisplay(locationExtension, placement, comment)

  return [children, ...args]
}

/**
 * ServiceItem Detail Action
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 *
 * @return {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  mapServiceItemData(module),
  mapToBeValidatedData(module),
  displayEquipmentPartsAvailable(module),
  presetEquipmentDoc(module),
  presetEquipmentOpts(module),
  presetProductDoc(module),
  presetProductImage(module),
  // TODO: The next call makes serviceItem depend on organisation. Move its definition to shared.
  presetApprovalStatus(module)(component)('installedAt'),
  initServiceBy(module),
  displayAdditionalInformation(module),
)(sequenceComponentFind(component), ...args)
