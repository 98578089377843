/* eslint-disable no-param-reassign */
/* global G */
import { curry, isObj } from 'lib/util'

const descriptor = 'model::decorator::fromValue'

const _mapAttribute = (attribute, value) => {
  value && (attribute[G.CACHE] = value)
  isObj(value) && Object.keys(attribute[G.CHILDREN]).map((childKey) => {
    const innerAttribute = attribute[G.CHILDREN][childKey]
    const innerValue = value[childKey]
    innerValue && _mapAttribute(innerAttribute, innerValue)
    return childKey
  })
}

/**
 * key attribute should be a single ref model
 *
 * @param key
 * @param target
 * @param obj
 * @param component
 * @return {*}
 */
const fn = (key, target, obj, component) => {
  console.warn(descriptor, key, target)
  const sourceAttribute = obj[G.CHILDREN][key]
  const targetAttribute = obj[G.CHILDREN][key]
  const value = obj[G.CACHE].value[key]
  const ref = sourceAttribute[G.CACHE] && sourceAttribute[G.CACHE][0]

  value && _mapAttribute(targetAttribute, { key: ref, ...value })
  return component
}

export default curry(fn)
