/* eslint-disable default-param-last,implicit-arrow-linebreak */
import { curry } from 'lib/util'

/**
 * Determine whether {@param node} has folder as children.
 * @param {Object} node
 * @returns {*}
 */
const hasFolderChildren = node => node?.$children && node?.$children?.some(child => child.type === 'Directory')

/**
 * Applies the given search predicate to the current node. Returns {@code true}
 * if the predicate is true, otherwise recursively class itself with the
 * {@param node}'s children.
 *
 * @param {Object} node             the current node
 * @param {String} term             search term
 * @param {Function[]} predicates   list of predicates to check against
 * @param {boolean} directoriesOnly whether to only look at directories
 * @returns {boolean|0|*}
 * @private
 */
const _findMatchingNode = (node, term, predicates, directoriesOnly) => {
  const checkNode = directoriesOnly ? hasFolderChildren(node) : true

  // This node is a match
  return predicates.some(predicate => predicate(node, term))
  // If not, look into my children
  || (node.$children?.length && checkNode && !!node.$children.find(
    child => _findMatchingNode(child, term, predicates, directoriesOnly),
  ))
}

/**
 * Tree Search Event Handler
 *
 * Traverses the given tree ({@param eventOrOptions.node}) and checks every node against a list of
 * {@param predicates} with {@param eventOrOptions.value}. If the check succeeds it will add the\
 * node to the {@param eventOrOptions.result}.
 *
 * If also fills up {@param eventOrOptions.shouldExpand}. It will contain the hierarchy of nodes
 * for all the nodes in {@param eventOrOptions.result}, this information can be used to expand
 * all relevant nodes to show the paths to the search results.
 *
 * The {@param predicates} will be chained in logical OR, meaning only one of them needs to return
 * {@code true} for the node to be considered a result
 *
 * @param {Function[]} predicates       list of predicates to check each node against. Will be
 *                                      chained with logical OR.
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event|Object} eventOrOptions filter options for the current tab. This should be
 *                                      provided by {@code filter} event handler
 * @return {Object}                     accumulated additional search options
 */
const searchTree = async (predicates, module, component, eventOrOptions) => {
  const {
    node = {},
    value = null,
    result = [],
    shouldExpand = [],
    directoriesOnly = false,
  } = eventOrOptions?.detail || eventOrOptions || {}

  if (predicates.some(predicate => predicate(node, value)) || !node.$children) {
    const { $children, ...pureNode } = node
    result.push(pureNode)
  }

  const filtered = (node?.$children || [])
    .filter(child => _findMatchingNode(child, value, predicates, directoriesOnly))
    .map((child) => {
      shouldExpand.push(child.id)
      searchTree(predicates, module, component, {
        node: child,
        value,
        result,
        shouldExpand,
        directoriesOnly,
      })

      return child
    })

  return { node: { ...node, $children: filtered }, shouldExpand, result, directoriesOnly }
}

export default curry(searchTree)
