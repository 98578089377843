/* eslint-disable no-unused-vars */
import { get, reset } from 'lib/sequence/component/state/value'
import { bulk } from 'lib/util'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { checked, uncheck } from 'lib/sequence/component/state/checked'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Hides/shows relevant fields based on checkbox values.
 *
 * @param module
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
const initDocumentation = module => component => async (components, ...args) => {
  const {
    docHelpful,
    problem,
    problemUnderstandability,
    problemContent,
    problemLanguage,
    problemMissingSolution,
  } = components

  // Resetting the checkbox value if unchecked
  !checked(docHelpful) && uncheck(docHelpful) && reset(docHelpful)

  const docHelpfulValue = get(docHelpful)
  // Value will either be "1" or "0"
  docHelpfulValue !== undefined && !Number.parseInt(docHelpfulValue, 10)
    ? bulkShow(
      problem,
      problemMissingSolution,
      problemUnderstandability,
      problemLanguage,
      problemContent,
    )
    : bulkHide(
      problem,
      problemMissingSolution,
      problemUnderstandability,
      problemLanguage,
      problemContent,
    )

  return args
}

export default initDocumentation
