/* eslint-disable no-unused-vars */
/* global G */
import { bulk, curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)
/**
 * Event Handler Step Protect.
 *
 * Protects the {@code step} by hiding elements that would allow
 * to edit / delete the model associated with it.
 *
 * @param {Boolean} value                 either to set the step state as confirmed or not
 *                                        confirmed
 * @return {Gaia.AppModule.EventHandler}  the confirm event handler.
 */
const protectStep = value => curry(async (step, module, component, event) => {
  try {
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { stepper } = asObject(actionComponent[G.CHILDREN])
    const { [step]: targetStep } = asObject(stepper[G.CHILDREN])
    const { btnEdit, btnClear } = sequenceComponentFind(targetStep)

    value
      ? bulkHide(btnEdit, btnClear)
      : bulkShow(btnEdit, btnClear)
  } catch (e) {
    console.error(e)
  }
})

const protect = protectStep(true)
const unprotect = protectStep(false)

export {
  protect,
  unprotect,
}
