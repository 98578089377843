/* global G */
import { curry, getFirstItem } from 'lib/util'

const descriptor = 'model::team::transformer::followTheSun'

/**
 * Return the digits with leading zeros as a string.
 * @param {Number} x    the digits to parse
 *
 * @returns {String}    the parsed digits
 * @private
 */
const _parseDigits = x => (x > 9 ? `${x}` : `0${x}`)

/**
 * Return an array with the desired time range, taking into account if the range overlaps into the
 * next day.
 *
 * @param {Date} fromTime star time
 * @param {Date} toTime   end time
 *
 * @returns {String[]}    array of start and end time
 * @private
 */
const _calculateTimes = (fromTime, toTime) => {
  const from = new Date(fromTime)
  const to = new Date(toTime)

  const overNight = to.getTime() < from.getTime()

  const fromString = `${_parseDigits(from.getHours())}:${_parseDigits(from.getMinutes())}`
  const toString = `${_parseDigits(to.getHours())}:${_parseDigits(to.getMinutes())}`

  return overNight
    ? [`${fromString}-23:59`, `00:00-${toString}`]
    : [`${fromString}-${toString}`]
}

/**
 * To FollowTheSun Transformer
 *
 * transforms input data into the following structure:
 *
 * @example
 * followTheSun: {
 *  ticketType1: { timezone: [ fromTime, toTime ] }
 *  ticketType2: { timezone: [ fromTime, toTime ] }
 *  ...
 * }
 *
 * @param key
 * @param obj
 * @param data
 * @return {*}
 */
const toFollowTheSun = (key, obj, data) => {
  console.warn(descriptor)

  const _data = data
  const target = obj[G.CHILDREN]?.[key]
  const { type } = target[G.PROPS]

  try {
    const ticketTypeModel = target[G.CHILDREN]?.ticketType
    const timezoneModel = target[G.CHILDREN]?.timezone
    const fromTimeModel = target[G.CHILDREN]?.fromTime
    const toTimeModel = target[G.CHILDREN]?.toTime

    const ticketType = ticketTypeModel?.[G.DATA]?.value || ticketTypeModel?.[G.CACHE]
    const timezone = timezoneModel?.[G.DATA]?.value || timezoneModel?.[G.CACHE]
    const fromTime = fromTimeModel?.[G.DATA]?.value || fromTimeModel?.[G.CACHE]
    const toTime = toTimeModel?.[G.DATA]?.value || toTimeModel?.[G.CACHE]

    const attributeData = ticketType.map(x => ({ [x.key]: { [getFirstItem(timezone).key]: _calculateTimes(fromTime, toTime) } }))

    _data[type][key] = {}
    _data[type][key].ticketType = attributeData
  } catch (e) {
    console.error(descriptor, e)
  }

  return _data
}

export default curry(toFollowTheSun)
