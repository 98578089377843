/* eslint-disable no-param-reassign,no-unused-vars */
/* global G */
import { setKey } from 'lib/util'
import map from 'lib/sequence/model/api/map'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import ui from 'lib/hook/ui/update'
import redirect from 'lib/hook/router/redirect'
import confirm from 'lib/hook/router/confirm'
import bulk from 'lib/hook/model/bulk'
import read from 'lib/hook/model/read'
import search from 'app/_shared/hook/appbar/search'
import create from 'app/_shared/hook/appbar/create'
import clear from 'app/_shared/hook/model/clear'
import stepper from 'app/_shared/hook/stepper'
import reset from 'app/_shared/hook/component/reset'
import mapAttribute from 'app/_shared/hook/model/mapAttribute'
import check from 'app/_shared/hook/check'
import recall from 'app/_shared/hook/component/recall'
import submitAttachments from 'app/ticket/hook/submitAttachments'
import note from 'app/ticket/hook/note'
import quickActions from 'app/ticket/hook/quickActions'
import uiTitle from 'app/ticket/hook/uiTitle'
import submitNote from 'app/ticket/hook/submitNote'
import submitBirthNote from 'app/ticket/hook/submitBirthNote'
import saveInitialState from 'app/ticket/hook/saveInitialState'
import back from 'app/_shared/hook/redirect/back'
import noHistory from 'app/_shared/hook/action/noHistory'

const _baseHooks = {
  before: [],
  after: [
    ui,
  ],
}

const _messageAttachmentsHooks = {
  before: [],
  after: [
    ui,
  ],
}

const _quickActionHooks = {
  before: [
    reset,
    obj => (...args) => {
      const ticket = obj[G.MODEL]
      const { content } = sequenceComponentFind(obj[G.STATE][G.ACTION][G.COMPONENT])
      map(ticket)(content)

      return args
    },
    // Configures note's and its time-tracking visibility
    // If type is {@code null}, action name will be used.
    note(null),
  ],
  after: [
    ui,
  ],
}

const _quickActionCloseHooks = {
  before: [
    ..._quickActionHooks.before,
    /**
     * We need to overwrite the note hook here to give a concrete type. It doesn't matter which type
     * (close_cancel, close_third_party, ...) as they all have the same permissions. If, one day,
     * we want to distinguish between them, we have to do it in statusReason's onChange handler.
     *
     * We need to give the note hook a concrete type because otherwise the action name would be
     * used ({@code close}). But there is no {@code close} node type. We would need to add a
     * synthetic type to {@link nodeTypes}. Not good. So let's just overwrite the hook.
     *
     * TODO: correct; this doesn't actually overwrite the note hook, resulting in both being called
     */
    note('close_cancel'),
  ],
  after: [
    ..._quickActionHooks.after,
  ],
}

const _timeTrackingHooks = {
  before: [
    ..._quickActionHooks.before,
    note('timeEntry'),
  ],
  after: [
    ..._quickActionHooks.after,
  ],
}

const _readHooks = {
  before: [
    read,
  ],
  after: [
    ui,
  ],
}

const _confirmHooks = {
  after: [
    ui,
    confirm,
  ],
}

const _createHooks = {
  before: [
    // Clears the module error, so that we can still use redirect to navigate the drawer actions.
    // Should be replaced by using force redirect
    obj => async (...args) => {
      setKey(null, G.ERROR, obj[G.STATE])
      return args
    },
    // Make the note when creating a ticket optional.
    note('forward'),
  ],
  after: [
    stepper,
    ui,
    confirm,
  ],
}

const _editHooks = {
  before: [
    // Clears the module error, so that we can still use redirect to navigate the drawer actions.
    // Should be replaced by using force redirect
    obj => async (...args) => {
      setKey(null, G.ERROR, obj[G.STATE])
      return args
    },
  ],
  after: [
    stepper,
    uiTitle,
    ui,
    confirm,
  ],
}

const _listHooks = {
  before: [
    search,
    create,
    recall,
  ],
  after: [
    ui,
  ],
}

const _simpleHooks = {
  before: [
    create,
    recall,
  ],
  after: [
    ui,
  ],
}

const _bulkHooks = {
  before: [
    // validate, // seq validate
  ],
  after: [
    ui, // show validation errors, if exist
    bulk,
    submitAttachments,
    // submitFirstMessage,
    submitBirthNote,
    submitNote,
    noHistory,
    redirect,
  ],
}

const _sendNoteHooks = {
  after: [
    ui,
    check,
    reset,
    redirect,
  ],
}

const _bulkFormHooks = {
  before: [
    // validate, // seq validate
  ],
  after: [
    ui, // show validation errors, if exist
    check,
    bulk,
    submitAttachments,
    submitBirthNote,
    reset,
    redirect,
  ],
}

const _bulkEditHooks = {
  before: [],
  after: [
    ui, // show validation errors, if exist
    bulk,
    check,
    obj => (...args) => {
      // clearing dirty flag after successful bulk
      setKey(false, G.UNDO, obj[G.STATE][G.ACTION][G.STATE])
      return args
    },
    back,
  ],
}

const _detailHooks = {
  before: [
    clear,
    read,
  ],
  after: [
    quickActions,
    uiTitle,
    ui,
    confirm,
  ],
}

const _organisationHooks = {
  before: [
    mapAttribute('requesterOrg'),
  ],
  after: [
    ui,
    confirm,
    saveInitialState('store', true),
  ],
}

const _contactHooks = {
  before: [
    mapAttribute('requesterContact'),
  ],
  after: [
    ui,
    confirm,
    saveInitialState('store', true),
  ],
}

const _partyHooks = {
  before: [
    mapAttribute('additionalParty'),
  ],
  after: [
    ui,
    confirm,
    saveInitialState('store', true),
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _listHooks,
    own: _listHooks,
    simple: _simpleHooks,
    unfiltered: _listHooks,
    team: _listHooks,
    form: _confirmHooks,
    create: _createHooks,
    edit: _editHooks,
    organisation: _organisationHooks,
    contact: _contactHooks,
    party: _partyHooks,
    bulk: _bulkHooks,
    bulkForm: _bulkFormHooks,
    bulkEdit: _bulkEditHooks,
    sendNote: _sendNoteHooks,
    created: _readHooks,
    confirm: _baseHooks,
    detail: _detailHooks,
    requester: _readHooks,
    device: _readHooks,
    additionalParty: _readHooks,
    description: _readHooks,
    attachments: _baseHooks,
    noteAttachments: _baseHooks,
    messageAttachments: _messageAttachmentsHooks,
    showAttachments: _baseHooks,
    // quick actions
    postpone: _quickActionHooks,
    reopen: _quickActionHooks,
    stop: _quickActionHooks,
    forward: _quickActionHooks,
    close: _quickActionCloseHooks,
    timeTracking: _timeTrackingHooks,
  },
}

export default hooks
