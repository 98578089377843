/* eslint-disable no-unused-vars */
import sequenceComponentState from 'lib/sequence/component/state'
import { settings } from 'app/_shared/session'
import { show } from 'lib/sequence/component/state/hidden'

const {
  set: setFilename,
} = sequenceComponentState('filename')

/**
 * Initializes the deviceSerialImage element if the serialImage setting is truthy.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const initSerialField = module => async (components, ...args) => {
  const { deviceSerialImage } = components

  settings.serialImage
    && setFilename(deviceSerialImage, settings.serialImage)
    && show(deviceSerialImage)

  return [components, ...args]
}

export default initSerialField
