/* eslint-disable import/no-duplicates */
/* global G */
import { getFirstItem, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'
import {
  DISABLED,
  isAttributeComplex,
  isAttributeRequired,
  isAttributeVisible,
  MANDATORY,
  OPTIONAL,
} from 'model/ticket/collection/note/type'
import session from 'app/_shared/session'
import listNoteTypes from 'app/_shared/events/collection/listNoteTypes'
import { disable } from 'lib/sequence/component/state/disabled'

/**
 * Validates the {@code nodeType}'s visibility based on {@param attribute}.
 *
 * @param {Boolean} [required=false]  Whether it should be checked if the attribute is required or
 *                                    visible
 * @param {String} attribute          the attribute containing either a predicate or a complex
 *                                    object.
 * @return {(function(*, *): (*))|*}
 */
const isComplexAttributeDisplayed = (attribute, required = false) => (module, noteType) => {
  const ticket = module[G.MODEL]

  if (!isAttributeComplex(attribute)(noteType)) {
    return !required
      ? isAttributeVisible(attribute)(noteType)
      : isAttributeRequired(attribute)(noteType)
  }

  const attributeVisibility = noteType[attribute]

  const result = Object.keys(attributeVisibility.rules).every((cond) => {
    if (cond === 'assignee') {
      const { user } = session(module)
      const assignee = getFirstItem(ticket[G.CHILDREN].assignee[G.CACHE])

      return attributeVisibility.rules[cond]
        ? assignee.key === user.key()
        : assignee.key !== user.key()
    }

    if (cond === 'status') {
      const status = getFirstItem(ticket[G.CHILDREN].status[G.CACHE])

      return status
        ? attributeVisibility.rules[cond].some(x => x === status.key)
        : false
    }

    return false
  })

  const returnValue = result ? 'onSuccess' : 'onFail'

  return (attributeVisibility[returnValue] === OPTIONAL && !required)
        || (attributeVisibility[returnValue] === DISABLED && false)
        || (attributeVisibility[returnValue] === MANDATORY && true)
}

/**
 * Validate the current node's {@code timeTracking} visibility.
 *
 * @type {(function(*, *): *)|*}
 */
export const isComplexTimeTrackingVisible = isComplexAttributeDisplayed('timeTracking')

/**
 * Validate the current node's {@code timeTracking} visibility.
 *
 * @type {(function(*, *): *)|*}
 */
export const isComplexStartDateVisible = isComplexAttributeDisplayed('startDate')

/**
 * Validate the current node's {@code timeTracking} is required.
 *
 * @type {(function(*, *): *)|*}
 */
export const isComplexTimeTrackingRequired = isComplexAttributeDisplayed('timeTracking', true)

/**
 * Validate the current node's {@code timeTracking} is required.
 *
 * @type {(function(*, *): *)|*}
 */
export const isComplexStartDateRequired = isComplexAttributeDisplayed('startDate', true)

/**
 * Validate the current node's {@code mandatory} visibility.
 *
 * @type {(function(*, *): *)|*}
 */
const isComplexNoteRequired = isComplexAttributeDisplayed('mandatory', true)

/**
 * Sort the notes by submitTimestamp and startDate.
 *
 * @param {Object[]} notes
 * @return {*}
 */
export const sortNotesByDate = notes => notes.sort(
  (a, b) => new Date(b.value.startDate || b.value.submitTimestamp)
        - new Date(a.value.startDate || a.value.submitTimestamp),
)

/**
 * Note Hook
 *
 * Configures the visibility of the {@param type} note and its time-tracking
 * options based on its configuration.
 *
 * @param {String} type node type to configure
 * @return {function(...[*]=)}
 */
const note = type => obj => (...args) => {
  const ticket = obj[G.MODEL]
  const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]
  const noteType = type || obj[G.STATE][G.ACTION]._name

  const { spentTime, startDate } = find(actionComponent)
  // Getting the current note type
  const [currentNoteType] = listNoteTypes(obj, actionComponent, noteType)

  // In case we can't find the note type for some reason
  // avoid follow-up errors and just continue
  if (!currentNoteType) {
    spentTime && hide(spentTime)
    return args
  }

  // Showing / hiding time tracking based on note type
  if (spentTime) {
    isComplexTimeTrackingVisible(obj, currentNoteType)
      ? show(spentTime)
      : hide(spentTime)
  }

  // Showing / hiding startDate field based on note type
  if (startDate) {
    isComplexStartDateVisible(obj, currentNoteType)
      ? show(startDate)
      : hide(startDate)
  }

  // Activating validators based on note type
  const {
    note: noteValidator,
    startDate: startDateValidator,
    spentTime: spentTimeValidator,
  } = ticket[G.VALIDATOR]

  disable(noteValidator, !isComplexNoteRequired(obj, currentNoteType))
  disable(startDateValidator, !isComplexStartDateRequired(obj, currentNoteType))
  disable(spentTimeValidator, !isComplexTimeTrackingRequired(obj, currentNoteType))

  return args
}

export default note
