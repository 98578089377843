/* global G */

/**
 * User Action Submit
 *
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
const submit = module => () => async (...args) => {
  const model = module[G.MODEL]
  const validationError = model[G.STATE][G.ERROR]
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = !!validationError

  switch (module[G.STATE][G.ACTION][G.COMPONENT]._name) {
    case module[G.ACTIONS].new._name:
      !validationError && (model[G.DATA].status = 50)
      break
      // case module[G.ACTIONS].edit._name:
      //   !validationError && await asyncpipe(
      //     seqUpdate,
      //     seqTransform,
      //     updateData,
      //   )(model[G.CHILDREN].person)
      //   break
    default:
  }

  return args
}

export default submit
