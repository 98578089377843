import { asyncPipeSpread } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import saveCart from './save'

/**
 * Cart Submit Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  saveCart(module)({}),
)(sequenceComponentFind(component), ...args)
