/* eslint-disable no-unused-vars */
import sequenceComponentState from 'lib/sequence/component/state'
import { settings } from 'app/_shared/session'

const {
  set: setFieldsConfig,
} = sequenceComponentState('fields')

/**
 * If the serialFieldConfig setting is set, initializes the serial field component with it.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const initSerialField = module => async (components, ...args) => {
  const { serial } = components

  settings.serialFieldConfig && setFieldsConfig(serial, settings.serialFieldConfig)

  return [components, ...args]
}

export default initSerialField
