/* global G */
import { _getCurrentTab } from 'app/registration/action/invite'
import asObject from 'lib/sequence/component/children/asObject'
import { hide, show } from 'lib/sequence/component/state/hidden'

/**
 * Invite action
 *
 * Second invite flow screen. Showing/hiding the password field
 * according to checkbox state.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => {
  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const currentTab = _getCurrentTab(actionComponent)('contact')
  const { contactInformation } = asObject(currentTab[G.CHILDREN])
  const { password } = asObject(contactInformation[G.CHILDREN])

  module[G.STATE].inviteeRole !== 'customer' && module[G.STATE].inviteeRole !== 'requester'
    ? show(password)
    : hide(password)

  return args
}
