/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Reload List Event Handler
 *
 * Instructs the next ui update to reload the list.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 */
const updateList = (module, component, event) => {
  const { options: { type } } = event?.detail || event || {}
  const { [type]: parent } = sequenceComponentFindProxy(module[G.STATE][G.ACTION][G.COMPONENT])
  const { list } = asObject(parent[G.CHILDREN])
  list[G.STATE].update = {}
}

export default curry(updateList)
