/**
 * Rational application settings
 */
export default {
  languages: ['en', 'de', 'fr', 'es', 'pt', 'tr', 'it', 'pl', 'ru', 'zh', 'ja', 'cs', 'sv'],
  singleStepLogin: true,
  allowNonEmailUsernames: true,
  suppressUserExistenceCheck: true,
  suppressProfileRole: true,
  externalPortalUrl: 'https://portal.rational-online.com/fs4p/{lang}/startseite/homepage_eingeloggt.php',
  staticActions: [
    { name: 'marine', icon: 'anchor' },
    { name: 'imprint', icon: 'imprint' },
  ],
  // This is an object because there will be more props in the near future
  webShop: {
    enabled: true,
  },
  drilldown: {
    minStepsDepth: 1,
    skippableDepths: [
      { depth: 7, nodeType: 'product', productType: 'SCC' },
      { depth: 7, nodeType: 'product', productType: 'iCombi' },
      { depth: 6, nodeType: 'product', productType: 'VCC' },
      { depth: 6, nodeType: 'product', productType: 'iVario' },
    ],
  },
  documentationTreeFilter: {
    product: [
      { number: 'RTS-GMNA1', nodeType: 'product', name: 'SCC' },
      { number: 'RTS-GMNB1', nodeType: 'product', name: 'SCC' },
      { number: 'RTS-GMNC1', nodeType: 'product', name: 'iCombi' },
      { number: 'SAV-TDAA1', nodeType: 'product', name: 'VCC' },
      { number: 'SAV-TDB1', nodeType: 'product', name: 'iVario' },
    ],
    docType: [
      { errorTree: { nodeType: 'documentation', name: 'ET' } },
      { bulletin: { nodeType: 'documentation', name: 'BU' } },
      { serviceReference: { nodeType: 'documentation', name: 'SR' } },
    ],
    filter: [
      { energy: { /* more detailed filter may come here */ } },
      { size: { /* more detailed filter may come here */ } },
      { type: { /* more detailed filter may come here */ } },
    ],
  },
  documentationTreeNodeIcons: [
    { nodeType: 'product', name: 'SCC', icon: { variant: 'product', name: 'scc' } },
    { nodeType: 'product', name: 'VCC', icon: { variant: 'product', name: 'vcc' } },
    { nodeType: 'product', name: 'iVario', icon: { variant: 'product', name: 'ivario' } },
    { nodeType: 'product', name: 'iCombi', icon: { variant: 'product', name: 'icombi' } },
    { nodeType: 'product', name: 'iHexagon', icon: { variant: 'product', name: 'icombi' } },

    { nodeType: 'documentation', name: 'BU', icon: { variant: 'documentation', name: 'bu' } },
    { nodeType: 'documentation', name: 'CL', icon: { variant: 'documentation', name: 'cl' } },
    { nodeType: 'documentation', name: 'ET', icon: { variant: 'documentation', name: 'et' } },
    { nodeType: 'documentation', name: 'FAQ', icon: { variant: 'documentation', name: 'faq' } },
    { nodeType: 'documentation', name: 'IM', icon: { variant: 'documentation', name: 'im' } },
    { nodeType: 'documentation', name: 'MI', icon: { variant: 'documentation', name: 'mi' } },
    { nodeType: 'documentation', name: 'OM', icon: { variant: 'documentation', name: 'om' } },
    { nodeType: 'documentation', name: 'SI', icon: { variant: 'documentation', name: 'si' } },
    { nodeType: 'documentation', name: 'SN', icon: { variant: 'documentation', name: 'sn' } },
    { nodeType: 'documentation', name: 'SR', icon: { variant: 'documentation', name: 'sr' } },
    { nodeType: 'documentation', name: 'TI', icon: { variant: 'documentation', name: 'ti' } },
    { nodeType: 'documentation', name: 'TM', icon: { variant: 'documentation', name: 'tm' } },
    { nodeType: 'documentation', name: 'TV', icon: { variant: 'documentation', name: 'tv' } },
  ],
  plugins: ['userlane'],
  pinCreatorLink: 'apprologic-rational-pincreator://',
  pinCreatorAndroidStoreLink: 'market://details?id=com.apprologic.pincreator',
  pinCreatorIOSStoreLink: 'itms-apps://apps.apple.com/us/app/pin-creator/id6483857823',
}
