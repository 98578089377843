/* global G */
import { curry } from 'lib/util'
import messageType, { all as allMessageTypes } from 'model/_shared/collection/messageType'

/**
 * Translates a property of {@param item}.
 *
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {object} t            an object specifying the items' translation path
 * @param item
 * @param name
 * @returns {*}
 */
export const translateItemProp = (obj, t, item, name) => {
  const _key = `${t._key}.${item.key}.${name}`
  const defaultValue = item[name]
  return t && defaultValue
    ? obj[G.ADAPTER][G.INTL]._t(_key, { ns: t.ns, _key, defaultValue })
    : defaultValue
}

/**
 *
 * @param {string} type                 ressource type (request, ticket)
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Gaia.Component} component    the object composition of the component that triggered
 *                                      the event
 * @param {Object} info                 the event or the message info
 * @param {string} info.type            type of the current ticket/request
 * @param {number} info.status          status of the current ticket/request
 * @return {Array} messageTypes
 */
const listMessageTypes = (type, module, component, info) => {
  const messageTypes = type ? messageType[type] : allMessageTypes

  const scope = info
    ? messageTypes
    /**
     * Allowing to override certain props in regard
     * to a ticket/request type in a {@code overrides}
     * array.
     */
      .map(item => (item.overrides
        ? {
          ...item,
          ...item.overrides.find(
            child => child.parentType === info.type,
          ),
        }
        : item))
      .filter(item => item.status.includes(info.status))
    : messageTypes

  const t = { _key: 'message.type', ns: 'ticket' }

  return scope.map(item => ({
    ...item,
    value: translateItemProp(module, t, item, 'value'),
    labelText: translateItemProp(module, t, item, 'labelText'),
    backgroundText: translateItemProp(module, t, item, 'backgroundText'),
    proposedText: translateItemProp(module, t, item, 'proposedText'),
  }))
}

export const listAllMessageTypes = curry(
  (module, component, event) => listMessageTypes(null, module, component, event),
)

export default curry(listMessageTypes)
