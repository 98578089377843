/* global G */
const key = 'token'

/**
 * Adds tokens to the token state of obj.
 * @param {object} obj  ui component
 * @param {*} tokens     the tokens to set
 * @returns {object}    the passed obj
 * @private
 */
const set = (obj, tokens) => {
  const objState = obj[G.STATE]
  objState[key] = { ...objState[key], ...tokens }
  return obj
}

/**
 * Returns the value of the token state of obj.
 * @param {object} obj  ui component
 * @returns {*}   the value of the value state of obj.
 */
const get = obj => obj[G.STATE][key]

export {
  get,
  set,
}
