import cancelOnError from 'lib/sequence/model/cancelOnError'

const descriptor = 'hook::check'

/**
 * Check Hook
 *
 * Throws an error if the current module is in error state, preventing any subsequent hooks from
 * being executed.
 *
 * @param {object} obj  the current module composition object
 * @return {function(...[*]): Promise<*[]>}
 */
const check = obj => async (...args) => {
  cancelOnError(descriptor, obj)
  return args
}

export default check
