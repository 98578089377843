/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { check, uncheck } from 'lib/sequence/component/state/checked'
import { set, reset } from 'lib/sequence/component/state/value'

/**
 * Checks or unchecks the store field form of an action according to {@param value}.
 *
 * @param {'organisation'|'contact'|'party'} actionName   the name of the action
 * @param {boolean} value                                 whether to set the store field as checked
 * @returns {Gaia.AppModule.EventHandler}
 */
export default (actionName, value) => curry(async (module, component, event) => {
  const actionComponent = module[G.ACTIONS][actionName][G.COMPONENT]
  const { store } = find(actionComponent[G.ACTIONS][0])

  value
    ? check(store) && set(store)
    : uncheck(store) && reset(store)
})
