import noop from '@gaia/util/noop/action'
import detail from 'app/cart/actions/detail'
import cart from './actions/cart'
import checkout from './actions/checkout'
import submit from './actions/submit'
import webshop from './actions/webshop'

export default {
  index: noop,
  cart,
  checkout,
  detail,
  submit,
  webshop,
  pdf: noop,
}
