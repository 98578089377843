/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import invitationStatuses from 'model/person/collection/invitation/status'

/**
 * Translated and returns the list of {@link invitationStatuses}.
 *
 * @param {Gaia.AppModule.Spec} module            the current module object composition
 * @param {Gaia.Component} component              the object composition of the component that
 *                                                triggered the event
 * @param {Gaia.PlatformEvent|string} eventOrKey  the event or the key of the status to obtain
 * @return {Array} countries
 */
const listInvitationActions = (module, component, eventOrKey) => {
  const model = module[G.MODEL]
  const invitationCache = model[G.CACHE]?.value?.invite
  const { status = 'to_be_invited' } = invitationCache || {}

  const currentInvitationStatus = invitationStatuses.find(x => x.key === status)
  const possibleActions = currentInvitationStatus?.actions || []

  return possibleActions.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `invitation.actions.${item.key}`,
      {
        ns: 'person',
        _key: `invitation.actions.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }))
}

export default curry(listInvitationActions)
