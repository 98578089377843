/**
 * Ticket Message Types Collection.
 *
 * Contains static list of available ticket's message types, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 *
 * @example <caption>Overriding properties</caption>
 * {
 *     // ...
 *     overrides: [
 *      {
 *        parentType: 'device_breakdown',
 *        labelText: 'test text',
 *      }
 *     ]
 * }
 */
export default [
  {
    key: 'message',
    value: 'Message',
    default: true,
    priorSelection: false,
    icon: 'message',
    color: 'primary',
    labelText: 'Reply to customer',
    backgroundText: 'Reply to customer',
    status: [50],
  },
  {
    key: 'status_update',
    value: 'Status update',
    default: false,
    priorSelection: false,
    icon: 'info',
    color: 'primary',
    labelText: 'We have got a status update',
    backgroundText: 'We have got a status update',
    status: [50],
  },
  {
    key: 'further_inquiry',
    value: 'Further inquiry',
    default: false,
    priorSelection: false,
    icon: 'question_mark',
    color: 'warning',
    labelText: 'We need more information',
    backgroundText: 'We need more information',
    status: [50],
    newStatus: 60,
    newStatusReason: 62,
  },
  {
    key: 'solution_proposal',
    value: 'Solution proposal',
    default: false,
    priorSelection: false,
    icon: 'lightbulb',
    color: 'success',
    labelText: 'Here\'s the solution',
    backgroundText: 'Here\'s the solution',
    status: [50],
    newStatus: 75,
  },
]
