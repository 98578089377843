/* eslint-disable no-unused-expressions,no-unused-vars,no-nested-ternary */
/* global G */
import { asyncPipeSpread, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import applyAdminFilters from 'app/_shared/action/partial/applyAdminFilters'

/**
 * Remove {@code G.PREV} if action is applicable.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const removePrev = module => async (components, ...args) => {
  /**
   * Force fetching of new data if we just invited a colleague
   */
  module[G.STATE][G.PREV]?.[G.ACTION] === 'newColleague'
  && setKey([], G.PREV, module[G.STATE])

  return [components, ...args]
}

/**
 * Person Action Index
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  removePrev(module),
  applyAdminFilters('list')(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
