/* global G */

import cancelOnError from 'lib/sequence/model/cancelOnError'
import { send as sendMessage } from 'app/_shared/events/message'

const descriptor = 'module::ticket::hook::submitFirstMessage'

/**
 * Submit First Message Hook
 *
 * Submits a message containing the current ticket's description.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]=)}
 */
const submitFirstMessage = obj => (...args) => {
  cancelOnError(descriptor, obj)
  const model = obj[G.MODEL]
  const { description } = model[G.CHILDREN]
  const { text } = description[G.CHILDREN]

  sendMessage(obj, null, { text: text[G.DATA].value })

  return args
}

export default submitFirstMessage
