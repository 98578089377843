/* eslint-disable no-unused-vars,no-param-reassign */
/* global G */
import validate from 'lib/sequence/model/validate'
import { add as addNote } from 'app/_shared/events/note'
import { asyncPipeSpread, setKey } from 'lib/util'
import { apply as applyAttachments } from 'app/_shared/events/attachment'
import find from 'lib/sequence/component/children/find'
import {
  checkAccountOrPhone,
  translator,
  updateModuleState,
  validateAccount,
  validateRequesterContact,
} from 'app/ticket/action/bulkForm'

/**
 * If the module isn't in error state, gathers all needed form data and uses it to request the
 * creation of a new note.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
const sendNote = module => () => async (components, ...args) => {
  const translate = translator(module, 'user')
  const model = module[G.MODEL]
  const modelState = model[G.STATE]
  const { requesterContact, description } = model[G.CHILDREN]
  const { contactChannels } = requesterContact[G.CHILDREN]
  const { attachment } = components

  if (!module[G.STATE][G.ERROR]) {
    const type = 'hotline'
    const { text: descriptionText } = description[G.DATA]
    const { firstName, lastName } = requesterContact[G.DATA]
    const { phone, email } = contactChannels[G.DATA]
    const parent = [modelState[G.REF]]

    const text = await translate({
      _key: 'label.hotlineNoteText',
      defaultValue: 'Call by {{firstName}} {{lastName}} ({{email}})\n{{description}}',
      firstName,
      lastName,
      email: email || phone,
      description: descriptionText,
    })

    await new Promise((resolve) => {
      addNote(module, attachment, { parent, text, type }, async (item) => {
        const { key } = item
        setKey(key, G.REF, attachment[G.STATE]) // required for applyAttachments
        applyAttachments(module, attachment, null)
        resolve()
      })
    })
  }
  return args
}

/**
 * Validates the request section against the ticket model.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function}
 */
const validateDescription = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { description } = model[G.CHILDREN]
  const { description: descriptionForm } = components

  await validate(description)(descriptionForm)

  return [components, ...args]
}

/**
 * Note action.
 *
 * Validates the current form fields and requests the creation of a new note with their data.
 *
 * This is a pure business logic action, it has no UI.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function}
 */
export default module => () => async (...args) => asyncPipeSpread(
  validateAccount(module)(),
  validateRequesterContact(module)(),
  validateDescription(module)(),
  checkAccountOrPhone(module)(),
  updateModuleState(module)(),
  sendNote(module)(),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
