/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import validate from 'lib/sequence/model/validate'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { get } from 'lib/sequence/component/state/value'

/**
 * Validate Redirect Event Handler
 *
 * It executes module action validateLogin()
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - event
 * @return {Promise<void>} void
 */
/**
 * Validate Account Event Handler
 *
 * Validates the account field of the user section
 * @type {Gaia.AppModule.EventHandler}.
 */
export default curry(async (module, component, event) => {
  const { route } = component[G.CONFIGURATION]
  const { account } = module[G.MODEL][G.DATA]
  const moduleState = module[G.STATE]

  moduleState[G.ROUTE] = routeComposition(route.module && route.module, route.action)

  await redirectSequence(module)({ username: account })
})
