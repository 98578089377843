import { curry } from 'lib/util'
import infinite from 'app/_shared/events/search/infinite'

/**
 * infiniteUsers Event Handler
 *
 * Calls {@link infinite} and modifies the returned list by adding a {@code loginName} property to
 * each one of its items with the item's key as its value.
 *
 * @param {Gaia.AppModule.Spec} module  app module
 * @param {Gaia.Component} component    ui component
 * @param {Gaia.PlatformEvent} event    event
 * @return {Promise<{data: *}>} void
 */
const infiniteUsers = async (module, component, event) => {
  const result = await infinite('default', module, component, event)
  const data = result.data.map(row => ({ ...row, value: { ...row.value, loginName: row.key } }))
  return { ...result, data }
}

export default curry(infiniteUsers)
