import noop from '@gaia/util/noop/action'
import submit from './action/submit'
import profile from './action/profile'
import verify from './action/verify'
import submitLanguage from './action/submitLanguage'

export default {
  profile,
  avatar: noop,
  edit: noop,
  teams: noop,
  change: noop,
  language: noop,
  menu: noop,
  submit,
  submitLanguage,
  verify,
  confirmRemove: noop,
}
