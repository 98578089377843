/* global G */
import { curry } from 'lib/util'
import { addItem } from 'app/ticket/event/updateTicketList'

/**
 * Get the new note and return it alongside a callback handling the state change.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param event                         event containing the new ticket id
 * @returns {Promise<*>}
 */
// eslint-disable-next-line no-unused-vars
const updateTimeNotesList = async (module, component, event) => {
  const obj = module[G.MODEL]
  const { version } = obj[G.PROPS]
  const { api: { type } } = component[G.PROPS]
  const noteId = event.id
  const url = `/api/v${version}/${type}/${noteId}`

  try {
    const fetchedNote = await obj[G.ADAPTER][G.HTTP].get({ url })

    return fetchedNote?.value?.spentTime
      ? { item: fetchedNote, addItem }
      : { item: null, addItem }
  } catch (e) {
    console.error(e)
  }
  return {}
}

export default curry(updateTimeNotesList)
