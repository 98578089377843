/* eslint-disable no-param-reassign,no-unused-vars */
/* global G */
import ui from 'lib/hook/ui/update'
import read from 'lib/hook/model/read'
import check from 'app/_shared/hook/check'
import redirect from 'lib/hook/router/redirect'
import back from 'app/_shared/hook/redirect/back'
import recall from 'app/_shared/hook/component/recall'
import updateCache from 'app/_shared/hook/model/updateCache'

const _indexHooks = {
  before: [
    recall,
  ],
  after: [
    ui,
  ],
}

const _cartHooks = {
  before: [],
  after: [
    ui,
  ],
}

const _webshopHooks = {
  before: [],
  after: [
    ui,
  ],
}

const _detailHooks = {
  before: [
    read,
  ],
  after: [
    ui,
  ],
}

const _checkoutHooks = {
  before: [
    // validation takes place in action logic
  ],
  after: [
    ui,
    check,
    redirect,
  ],
}

const _submitHooks = {
  before: [
    // validation takes place in action logic
  ],
  after: [
    ui,
    check,
    updateCache,
    back,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _indexHooks,
    cart: _cartHooks,
    checkout: _checkoutHooks,
    detail: _detailHooks,
    submit: _submitHooks,
    webshop: _webshopHooks,
    pdf: _cartHooks,
  },
}

export default hooks
