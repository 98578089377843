/* global G */
import { asyncPipeSpread, asyncPipeSpreadIf, setKey } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import find from 'lib/sequence/component/children/find'
import session from 'app/_shared/session'

/**
 * Executes {@param action}.
 *
 * @param {string} action the action to execute
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
const execAction = action => module => async (children, ...args) => {
  const targetAction = module[G.ACTIONS][action]

  // Swapping the current hollow action with the target one
  setKey(targetAction, G.ACTION, module[G.STATE])

  // Execute invite action to set up validation for invite fields.
  await actionWithHooks(targetAction)(args)

  return args
}

/**
 * Person Create Action
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  asyncPipeSpreadIf(() => session(module).isContext('req'))(
    execAction('newColleague')(module),
  ),
  asyncPipeSpreadIf(() => !session(module).isContext('req'))(
    execAction('newContact')(module),
  ),
)(find(component), ...args)
