/* global G */
import { pipe } from 'lib/util'
import { modelComposition } from 'trait/composition'
import { usesGetterSetter } from 'trait/uses'
import toAddress from 'model/_shared/transformer/toAddress'

const descriptor = 'model::recipientData'

export default pipe(
  modelComposition(descriptor),
  usesGetterSetter(G.TRANSFORMER, {
    address: toAddress('address'),
  }),
)
