/* eslint-disable no-unused-expressions,no-nested-ternary */
/* global G */
import { get, reset } from 'lib/sequence/component/state/value'
import validate from 'lib/sequence/model/validate'
import { add as addNote } from 'app/_shared/events/note/index'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { fromTimeEntry } from 'app/ticket/event/note/time'
import {setKey} from "lib/util";
import {sortNotesByDate} from "app/ticket/hook/note";

/**
 * Returns the payload used to assign the ticket to a team and a user, whose key is obtained from
 * the team and assignee root components of the view.
 *
 * @private
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @returns {object}
 */
export default async (module, component) => {
  const model = module[G.MODEL]
  const { content, team, assignee, note, spentTime } = sequenceComponentFind(component)

  await validate(model)(content)
  model[G.STATE][G.ERROR] && throw Error('Model validation error')

  const teamValue = get(team)
  const assigneeValue = get(assignee)
  const noteText = get(note)
  const time = get(spentTime)
  const spentSeconds = time
    ? fromTimeEntry(module, component, { value: time })
    : null

  reset(team)
  reset(assignee)
  reset(note)

  const refs = {}
  const teamRef = teamValue?.[0]?.key || null
  const assigneeRef = assigneeValue?.length ? assigneeValue[0].key : null
  const status = 30
  const statusReason = assigneeRef ? 32 : 31
  refs.assignee = assigneeRef ? [assigneeRef] : []
  refs.team = teamRef ? [teamRef] : []

  if (noteText && noteText.length) {
    const previousTeamRef = model[G.CHILDREN].team[G.CACHE].key
    const assigneeCache = model[G.CHILDREN].assignee[G.CACHE]
    const previousAssigneeRef = assigneeCache?.key || null

    const sameTeam = teamRef === previousTeamRef

    addNote(module, component, {
      text: noteText,
      type: assigneeRef ? 'assign' : sameTeam && previousAssigneeRef ? 'unassign' : 'forward',
      team: assigneeRef ? [] : [teamRef],
      assignee: assigneeRef ? [assigneeRef] : previousAssigneeRef ? [previousAssigneeRef] : [],
      ...spentSeconds && { spentTime: spentSeconds },
    }, (item) => {
      const { value } = item
      const timeNotes = module[G.STATE]?.timeNotes
      // So that the new time note is available without fetching them again
      value?.spentTime && setKey(sortNotesByDate([item, ...timeNotes]), 'timeNotes', module[G.STATE])
    })
  }

  return {
    refs,
    value: {
      status,
      statusReason,
      postponeDate: null,
    },
  }
}
