/* global G */
/* eslint-disable no-unused-vars */

import { curry } from 'lib/util'

/**
 * Return a search query that filters the search to only contain results that have a
 * {@code ref.team} containing one or more of the user's teams.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event|object} event              information about the event that triggered this handler
 * @return {`${string|string}`}
 */
const belongsToOneOfMyTeams = (module, component, event) => {
  const userSession = module[G.ADAPTER][G.SESSION][G.STATE]
  // Lucene requires us to wrap each team in "
  const userTeams = userSession[G.META].refs.team.map(currentTeam => `"${currentTeam.key}"`)

  return `${userTeams.length ? `team:(${userTeams})` : ''}`
}

export default curry(belongsToOneOfMyTeams)
