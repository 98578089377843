/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

/**
 * Marks the role at {@param index} of the roles array as deleted.
 *
 * A transformer should take care about filtering out the roles that should not be propagated to
 * the server.
 *
 * @param {String} [source]             the element to mutate the state of
 * @param {Gaia.AppModule.Spec} module  app module
 * @param {Gaia.Component} component    ui component
 * @param {Number} index                the index of the current role in the array
 * @param {Event} event                 event
 * @return {Promise<void>}              void
 */
const deleteRole = async (source, module, component, index, event) => {
  event.preventDefault()
  event.stopPropagation()

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { [source]: element = component, rolesList } = sequenceComponentFindProxy(actionComponent)

  const state = element[G.STATE]

  state.value = {
    ...state.value,
    [index]: {
      ...state.value?.[index] || {},
      delete: event?.detail?.checked || false,
    },
  }

  rolesList[G.STATE].update = {}

  module[G.ADAPTER][G.UI].update(module)
}

export default curry(deleteRole)
