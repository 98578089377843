/* global G */
import { curry, setKey } from 'lib/util'
import { back } from 'app/_shared/events'
import { send, target as getTarget } from 'app/_shared/events/message'
import { get } from 'lib/sequence/component/state/value'
import { apply as applyAttachments, get as getAttachments } from 'app/_shared/events/attachment'
import { changeStatus as changeTicketStatus } from 'app/_shared/events/status'
import { setDetailUndoKey } from 'app/ticket/action/detail'
import activateDraft from 'app/_shared/events/message/activateDraft'
import sequenceComponentFind from 'lib/sequence/component/children/find'

/**
 * Event Handler Send Attachments Message And Redirect
 *
 * Checks whether the messageField has any value or whether there are attachments uploaded for the
 * attachment group defined by the {@param component}'s group prop, and if that's the case, creates
 * a new message and applies the changes for the attachment group.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 information about the event that triggered this handler
 * @return {Promise<void>}
 */
const sendAndBackFromMessage = async (module, component, event) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  event.preventDefault()
  event.stopPropagation()

  setKey(null, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
  setDetailUndoKey(module, 'message', false)

  try {
    const model = module[G.MODEL]
    const { status } = model[G.CHILDREN]
    const currentStatus = status[G.CACHE]
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { messageField } = sequenceComponentFind(actionComponent)
    const attachments = await getAttachments(module, component, event)
    const attachmentsCount = attachments.length
    const text = get(messageField)
    const target = getTarget(module)
    const { number: title } = model[G.CACHE].value
    const { detail: messageDetails } = messageField[G.STATE]
    const { type, newStatus, newStatusReason } = messageDetails;

    (typeof newStatus === 'object' && (currentStatus in newStatus))
      && await changeTicketStatus(module, component, newStatus[currentStatus], newStatusReason);
    (typeof newStatus === 'number')
      && await changeTicketStatus(module, component, newStatus, newStatusReason)

    // Inits the loader if the message has attachments. We will close it again in
    // {@link activateDraft}
    attachmentsCount && eventBus.dispatch(eventBus.type(G.LOAD, G.INIT));

    (attachmentsCount || text) && await send(module, component, {
      text, target, type, title,
    }, async (item) => {
      const { key, value } = item
      setKey(key, G.REF, component[G.STATE])
      setKey(true, G.DRAFT, component[G.STATE])

      applyAttachments(module, component, event)
      value.attachments = attachments

      /**
       * We want to return immediately after the user confirms the dialog. We will display
       * 'Sending...' in the message footer until the attachments have successfully been uploaded.
       */
      await back(module, component, event)

      activateDraft(module, component, item, attachments, null)
    }, true)
  } catch (e) {
    console.error(e)
  }
}

export default curry(sendAndBackFromMessage)
