/* global G */

/**
 * No History Action Hook
 *
 * Removes the history flag from the current action's ui state, so that the action isn't added to
 * the history stack when leaving it.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): Promise<*[]>}
 */
export default obj => async (...args) => {
  const uiState = obj[G.STATE][G.ACTION][G.UI]
  delete uiState.history
  return args
}
