/* global G */

import seqMap from 'lib/sequence/model/api/map'
import asObject from 'lib/sequence/component/children/asObject'
import validate from 'lib/sequence/model/validate'

/**
 *
 * @param module
 * @returns {Promise<void>}
 */
const submitFeedback = async (module) => {
  const model = module[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const { version, api } = model[G.PROPS]
  const url = `/api/v${version}/${api}/support`

  const params = {
    email: model[G.CHILDREN].username[G.DATA].value,
    subject: 'Guest issue', // @todo: move value to configuration
    message: model[G.CHILDREN].description[G.DATA].value,
  }

  try {
    await httpAdapter.post({ url, params })
  } catch {
    const moduleState = module[G.STATE]
    moduleState[G.ROUTE] = {
      [G.ACTION]: 'changeError',
    }
  }
}

/**
 * User Action Submit
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
const submit = module => () => async (...args) => {
  const model = module[G.MODEL]
  const { feedback: feedbackUI } = asObject(module[G.STATE][G.ACTION][G.COMPONENT][G.CHILDREN])
  // Validating root model against a view placed one level deeper
  await validate(model)(feedbackUI)
  const validationError = model[G.STATE][G.ERROR]
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = !!validationError

  validationError && seqMap(model)(module[G.STATE][G.ACTION][G.COMPONENT])

  // !moduleState[G.ERROR] && await submitFeedback(module)

  // Clearing UI state values after feedback has been sent
  !validationError && Object.keys(feedbackUI[G.CHILDREN]).map((key) => {
    feedbackUI[G.CHILDREN][key][G.STATE].value = ''
    return key
  })

  return args
}

export default submit
