/* global G */
import { curry, setKey } from 'lib/util'
import redirect from 'app/_shared/events/redirect'

/**
 * Helper function to get the equipment node.
 *
 * @param {Object} node   the node to check
 * @returns {*|undefined}
 * @private
 */
const _getEquipment = (node) => {
  if (node.type === 'File') {
    return node
  }

  if (node?.$children?.length) {
    return _getEquipment(node.$children[node.$children.length - 1])
  }

  return undefined
}

/**
 * Get Equipment And Redirect Event Handler
 *
 * Gets the target equipment based on the current node and redirects to its
 * details page. Also sets the {@code G.REF} of the {@code G.MODEL} to
 * the equipment key.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 * @returns {Promise<void>}
 */
const getEquipmentAndRedirect = async (module, component, event) => {
  const { node } = event?.detail || event || {}

  const { key = null } = _getEquipment(node)

  if (key) {
    setKey(key, G.REF, module[G.MODEL][G.STATE])
    await redirect(module, component, { ...event, detail: { key, obfuscateSerial: key } })
  }
}

export default curry(getEquipmentAndRedirect)
