/* eslint-disable no-restricted-globals,no-alert,object-curly-newline */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import deviceSearch from 'app/_shared/events/search/device'
import { hide, show } from 'lib/sequence/component/state/hidden'

/**
 * Event Handler Search Device
 *
 * Attempts to find a service item or equipment by its serial and, depending on what is found, sets
 * the current model's item, equipment and product attributes with their information. Otherwise, if
 * nothing is found, those model attributes are cleared.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @return {Promise<void>}
 */
const searchDevice = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const { device } = asObject(stepper[G.CHILDREN])
  const { search: searchTab } = asObject(device[G.CHILDREN])
  const tabState = searchTab[G.STATE]
  const { deviceIdentify } = tabState
  const searchTabChildren = asObject(searchTab[G.CHILDREN])
  const { list: resultsList, suggestions, newItemList, noItemList } = searchTabChildren
  const result = await deviceSearch('defaultWithTicketCount', module, component, event)
  const itemList = deviceIdentify ? noItemList : newItemList

  result[0]
    ? show(resultsList)
      && hide(suggestions)
      && hide(itemList)
    : show(itemList)
      && hide(resultsList)
      && hide(suggestions)

  module[G.ADAPTER][G.UI].update(module)
  return result
}

export default curry(searchDevice)
