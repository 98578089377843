/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import search from 'app/_shared/events/search'
import { destroyWith } from 'app/_shared/events/destroy'
import dialog from 'app/_shared/events/dialog'

/**
 * Asks the server for tickets where {@code ref} has {@code value}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, *): Promise<*|(function(...[*]): (*))>}
 * @private
 */
const _checkTickets = module => async (ref, value) => await search(
  null, module, module[G.STATE][G.ACTION][G.COMPONENT], {
    type: 'ticket',
    filter: { [ref]: value },
  },
)

/**
 * Delete If No SubItems Event Handler
 *
 * Attempts to delete the current user along with its perosn. Checks if there are tickets
 * where {@code refs.submitter} equals the user. If so, shows an error dialog. If not, checks
 * if there are tickets where {@code refs.requesterContact} equals the personId associated to
 * the user. If so, shows another error dialog.
 *
 * If both checks are not true, attempts to delete by executing {@link destroyWith}.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<void>}
 */
const destroyIfNoSubItems = async (module, component, event) => {
  const model = module[G.MODEL]
  const { person } = model[G.CHILDREN]

  try {
    const ticketsAsSubmitter = await _checkTickets(module)('submitter', module[G.MODEL][G.STATE][G.REF])
    if (ticketsAsSubmitter?.length) {
      await dialog(module, component, {
        cancel: false,
        title: {
          ns: 'admin',
          key: 'dialog.deleteSubmitterError.title',
          defaultValue: 'Deletion not possible',
        },
        text: {
          ns: 'admin',
          key: 'dialog.deleteSubmitterError.text',
          defaultValue: 'User has request(s) and cannot be deleted. Alternatively, set user status to obsolete or archived.',
        },
      })
      return
    }

    const ticketsAsContact = await _checkTickets(module)('requesterContact', person[G.STATE][G.REF])
    if (ticketsAsContact?.length) {
      await dialog(module, component, {
        cancel: false,
        title: {
          ns: 'admin',
          key: 'dialog.deleteContactError.title',
          defaultValue: 'Deletion not possible',
        },
        text: {
          ns: 'admin',
          key: 'dialog.deleteContactError.text',
          defaultValue: 'User is related to request(s). Please unrelate user from all requests then delete. Alternatively, set user status to obsolete or archived.',
        },
      })
      return
    }

    await destroyWith(['person'], module, component, event)
  } catch (e) {
    console.error(e)
  }
}

export default curry(destroyIfNoSubItems)
