/* eslint-disable object-curly-newline,prefer-destructuring */
import { asyncPipeSpread } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import initOrganisationType from 'app/_shared/action/partial/initOrganisationType'
import processRequestType from 'app/_shared/action/partial/processRequestType'
import toggleDeviceInstalledAt from 'app/_shared/action/partial/toggleDeviceInstalledAt'
import initSerialImage from 'app/_shared/action/partial/initSerialImage'
import initSerialField from 'app/_shared/action/partial/initSerialField'

/**
 * Request action
 *
 * Initializes and controls the action's business logic.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  initOrganisationType,
  processRequestType(module),
  initSerialField(module),
  initSerialImage(module),
  toggleDeviceInstalledAt,
)(find(component), ...args)
