import { curry } from 'lib/util'

/**
 * Trim Attachment Name Event Handler
 *
 * Removes the {@code =utf8-''} prefix from the attachment name.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {PlatformEvent} event         information about the event that triggered this handler
 * @returns {*&{value: (*&{name: *})}}
 */
const trimAttachmentName = (module, component, event) => {
  const { value: attachment } = event?.detail || event || {}

  // In case we don't have a name, just return what we got.
  if (!attachment?.value?.name) return attachment

  return {
    ...attachment,
    value: {
      ...attachment.value,
      name: attachment.value.name.replace('=utf-8\'\'', ''),
    },
  }
}

export default curry(trimAttachmentName)
