/* eslint-disable no-restricted-globals,no-alert,object-curly-newline */
/* global G */
import { curry, setKey } from 'lib/util'
import { setData } from 'lib/sequence/model/api/set'
import sequenceComponentState from 'lib/sequence/component/state'
import searchDevice from 'app/_shared/events/search/device'

const {
  set: setError,
  unset: clearError,
} = sequenceComponentState('error')

const {
  set: setHelperText,
  unset: clearHelperText,
} = sequenceComponentState('helperText')

const clear = component => clearError(component) && clearHelperText(component)
const error = (component, text) => setError(component) && setHelperText(component, text)

/**
 * Sets error state {@param hasError} for the item property of the request model attribute.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {boolean} hasError
 */
const setItemModelError = (module, hasError) => {
  const model = module[G.MODEL]
  const { item } = model[G.CHILDREN]
  item[G.STATE][G.ERROR] = hasError
}

/**
 * Returns the translated text to be displayed whenever a device is not found.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {*}
 */
const deviceNotFoundText = async (module) => {
  const _key = 'label.deviceNotFound'
  return await module[G.ADAPTER][G.INTL]._t(_key, {
    ns: 'device',
    _key,
    defaultValue: 'Device not found',
  })
}

/**
 * Sets {@param data} as the data of the itemData property of the request model attribute and its
 * keys as its refs keys.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {object} [data]               the data of an equipment object
 */
const setItemData = (module, data = {}) => {
  const model = module[G.MODEL]
  const { item, equipment, product, itemInstalledAt, itemServiceBy } = model[G.CHILDREN]
  const { key: itemKey = null, value = {}, refs = {} } = data
  const { serial, name = 'Unknown' } = value
  const {
    equipment: [{ key: equipmentKey = null } = {}] = [],
    product: [{ key: productKey = null } = {}] = [],
    installedAt: [{ key: installedAtKey = null } = {}] = [],
    serviceBy: [{ key: serviceByKey = null } = {}] = [],
  } = refs

  setData(model, { itemData: serial ? { serial, name } : null })
  setKey(itemKey, G.REF, item[G.STATE])
  setKey(equipmentKey, G.REF, equipment[G.STATE])
  setKey(productKey, G.REF, product[G.STATE])
  setKey(installedAtKey, G.REF, itemInstalledAt[G.STATE])
  setKey(serviceByKey, G.REF, itemServiceBy[G.STATE])

  return true
}

/**
 * Event Handler Search Device
 *
 * Attempts to find a service item or equipment by its serial and, depending on what is found, sets
 * the current model's item, equipment and product attributes with their information. Otherwise, if
 * nothing is found, those model attributes are cleared.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    the event object that triggered this handler
 * @returns {Promise<void>}
 */
const searchEquipment = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()
  const serial = event.detail.value
  // clearing field state
  clear(component)
  setItemModelError(module, false)

  if (serial) {
    const [item] = await searchDevice(null, module, component, serial)
    item
      ? setItemData(module, item)
        && setItemModelError(module, false)
      : setItemData(module)
        && error(component, await deviceNotFoundText(module))
        && setItemModelError(module, true)
  }

  module[G.ADAPTER][G.UI].update(module)
}

export default curry(searchEquipment)
