/* eslint-disable no-restricted-exports */
import getTree from 'app/_shared/events/file/tree/getTree'
import getAttachments from 'app/_shared/events/file/getAttachments'
import getNode from 'app/_shared/events/file/node/getNode'
import getNodeType from 'app/_shared/events/file/node/getNodeType'
import getBreadcrumbs from 'app/_shared/events/file/folder/getBreadcrumbs'
import getMergedTree from 'app/_shared/events/file/tree/getMergedTree'
import setTree from 'app/_shared/events/file/tree/setTree'
import filterTree from 'app/_shared/events/file/tree/filterTree'

const explorerEvents = {
  getTree,
  getMergedTree,
  getAttachments,
  getNode,
  getNodeType,
  getBreadcrumbs,
  setTree,
  filterTree,
}

export {
  explorerEvents as default,
  getTree,
  getMergedTree,
  getAttachments,
  getNode,
  getNodeType,
  getBreadcrumbs,
  setTree,
  filterTree,
}
