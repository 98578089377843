/* global G */
import { curry, getFirstItem } from 'lib/util'
import { getPropertyName, getPropertyValue } from 'lib/util/object'

const descriptor = 'model::team::followTheSun::decorator::fromTimezone'

/**
 * From Timezone Decorator
 *
 * Gets the value of the first property of the first children of {@param sourceKey} and sets its
 * name as {@param key}'s G.CACHE.
 *
 * @example
 * { foo: { bar: "baz" } } -> "bar"
 *
 * @param {String} key        name of the attribute
 * @param {String} sourceKey  name of the source attribute to get the value from
 *
 * @returns {(function(...[*]): (*))|*}
 */
const fn = (key, sourceKey) => curry((obj, component) => {
  console.warn(descriptor, key)

  const attribute = obj[G.CHILDREN]?.[key]
  const source = getFirstItem(obj[G.CACHE]?.[sourceKey])

  const container = getPropertyValue(source)
  attribute[G.CACHE] = getPropertyName(container)

  return component
})

export default fn
