/* global G */

/**
 * Removes a Note by its {@param key}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {string} key                  the key (ID) of the note to remove
 * @returns {Promise<void>}
 */
const remove = async (module, component, { key }) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(
    eventBus.type(G.NOTE, G.DELETE),
    {
      [G.DATA]: {
        key,
      },
    },
  )
}

export default remove
