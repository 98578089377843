/* global G */
import { curry } from 'lib/util'
import { settings } from 'app/_shared/session'
import defaultTicketTypes from 'model/_shared/collection/ticket/type'

/**
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent|string} eventOrKey
 * @return {Array} countries
 */
const listTicketTypes = (module, component, eventOrKey) => {
  const types = settings.ticketTypes || defaultTicketTypes
  const key = eventOrKey?.detail?.key || eventOrKey
  const scope = key ? types.filter(item => item.key === key) : types

  return scope.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `ticket.type.${item.key}`,
      {
        ns: 'custom',
        _key: `ticket.type.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }))
}

export default curry(listTicketTypes)
