/* global G */
import { curry } from 'lib/util'

/**
 * Impersonation confirmation dialog.
 *
 * Displays a dialog asking the user whether they are sure about their intent of impersonating a
 * user.
 *
 * @param {Gaia.AppModule.Spec} module  the application's current module
 * @param {string} username             the name of the user intended to be impersonated
 * @param {function} okHandler          the function to execute when the user confirms the dialog
 */
const showConfirmationDialog = (module, username, okHandler) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: module[G.ADAPTER][G.INTL]._t(
      'dialog.impersonation.title',
      {
        ns: 'admin',
        _key: 'dialog.impersonation.title',
        defaultValue: 'Switch user account',
      },
    ),
    text: module[G.ADAPTER][G.INTL]._t(
      'dialog.impersonation.text',
      {
        ns: 'admin',
        _key: 'dialog.impersonation.text',
        defaultValue: 'Are you sure you want to close your current session and log in as {{username}}?',
        username,
      },
    ),
    children: {
      ok: {
        key: 'yes',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.yes',
          {
            ns: 'common',
            _key: 'button.yes',
            defaultValue: 'Yes',
          },
        ),
      },
      cancel: {
        key: 'cancel',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.cancel',
          {
            ns: 'common',
            _key: 'button.cancel',
            defaultValue: 'Cancel',
          },
        ),
      },
    },
    okHandler,
  })
}

/**
 * Starts the impersonation of a another user by the current user.
 *
 * @param {Gaia.AppModule.Spec} module  the application's current module
 * @param {Gaia.Component} component    the current action's root component
 * @param {Gaia.PlatformEvent} event    information about the triggered event
 */
const impersonate = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const { item } = event.detail
  const { loginName } = item.value
  const username = loginName.split(':')[1]

  showConfirmationDialog(module, username, () => {
    module[G.ADAPTER][G.STORAGE].set('imp', username)
  })
}

export default curry(impersonate)
