/* global G */
import { bulk, curry, setKey } from 'lib/util'
import validate from 'lib/sequence/model/validate'
import findDevice from 'app/_shared/events/device/find'
import { get } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { detail } from 'app/_shared/events'
import { actionWithHooks } from 'lib/sequence/module/action'
import reset from 'lib/sequence/model/api/reset'
import setStepTab, { CONFIRM } from 'app/_shared/events/setStepTab'
import refresh from 'lib/sequence/model/api/refresh'
import PlatformEvent from 'lib/util/event'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import undefinedModelLabel from 'app/_shared/events/device/undefinedModelLabel'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Shows a dialog informing the user that a device was found.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {function} okHandler          a function to be executed if the user clicks 'ok'
 */
const showDeviceAlreadyExistsDialog = (module, okHandler) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: module[G.ADAPTER][G.INTL]._t(
      'dialog.deviceAlreadyExists.title',
      {
        ns: 'device',
        _key: 'dialog.deviceAlreadyExists.title',
        defaultValue: 'Device already registered',
      },
    ),
    text: module[G.ADAPTER][G.INTL]._t(
      'dialog.deviceAlreadyExists.text',
      {
        ns: 'device',
        _key: 'dialog.deviceAlreadyExists.text',
        defaultValue: 'You have already registered a device with this serial number. Please check '
                      + 'the entered numbers and try again or proceed to view the registered '
                      + 'device.',
      },
    ),
    children: {
      cancel: {
        key: 'cancel',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.cancel',
          {
            ns: 'common',
            _key: 'button.cancel',
            defaultValue: 'Cancel',
          },
        ),
      },
      ok: {
        key: 'ok',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.goToDevice',
          {
            ns: 'device',
            _key: 'button.goToDevice',
            defaultValue: 'Go to device',
          },
        ),
      },
    },
    okHandler,
  })
}

/**
 * Validates the serial field and uses {@link findDevice} to attempt to find a product, while
 * handling the different possibilities:
 *  * If a device is found it means that the product we need is attached to it, so we show a
 * warning
 *  * If no product is found, we show an info message
 *  * If an available product is found, we go to the next step
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    the event object that triggered this handler
 * @return {Promise<void>} void
 */
const searchProduct = async (module, component, event) => {
  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const {
    form,
    serial,
    btnMore,
    btnNoSerial,
    existingHelp,
  } = sequenceComponentFind(actionComponent)

  // hiding help buttons
  bulkHide(btnNoSerial, existingHelp, btnMore)
  // clearing currently stored data
  reset(model)
  // clearing model's error state
  setKey(null, G.ERROR, model[G.STATE])
  // validating model against form
  await validate(model)(form)

  if (!model[G.STATE][G.ERROR]) {
    const serialValue = get(serial)
    const result = await findDevice(module, serial, serialValue)

    if (result.serviceItem?.key) { // serviceItem exists
      // if a serviceItem is found, we show an error dialog and leave the models empty
      showDeviceAlreadyExistsDialog(module, async () => {
        const customEvent = new CustomEvent('click', { detail: result.serviceItem })
        await detail(module, component, new PlatformEvent(customEvent))
      })
    } else {
      // saving found serviceItem
      result.serviceItem
        ? await refresh(model, result.serviceItem)
        // if we allow devices without product, the user can create the device from nothing
        : await refresh(model, { value: {
          serial: serialValue,
          name: result.name || await undefinedModelLabel(module),
        } })

      result.product && !result.serviceItem?.key
        ? await setStepTab(CONFIRM)(module, component, event) // setting step's 'confirm' tab
        : bulkShow(existingHelp, btnMore) // if no product is found, we show the help buttons
    }
  } else {
    show(btnNoSerial)
  }
  actionWithHooks(module[G.STATE][G.ACTION])([])
}
export default curry(searchProduct)
