/* global G */
import { curry, setKey } from 'lib/util'
import validate from 'lib/sequence/model/validate'
import { actionWithHooks } from 'lib/sequence/module/action'
import { redirect } from 'app/_shared/events'
import { v4 as uuidV4 } from 'uuid'
import refresh from 'lib/sequence/model/api/refresh'

/**
 * Event Handler Model Attribute Add.
 *
 * Adds or modifies a new or existing object sub-model to the base model.
 *
 * @param {string} name       the name of the child model
 * @param {Gaia.AppModule.Spec} module     the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event      the event object that triggered this handler
 * @return {Promise<void>}
 */
const save = curry(async (name, module, component, event) => {
  const model = module[G.MODEL]
  const child = model[G.CHILDREN][name]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const ref = child[G.STATE][G.REF]
  // modelReset(child)
  // validate model against component's fields
  await validate(child)(actionComponent)
  // set submodel error as main model error
  // moduleState[G.ERROR] = child[G.STATE][G.ERROR]

  if (!child[G.STATE][G.ERROR]) {
    // refresh model's cache from its own data
    await refresh(child)
    // set deductible fields
    child[G.STATE][G.REF] = ref || uuidV4()
    child[G.STATE][G.BULK] = true
    child[G.DATA].status = 50
    // removing action's dirty flag
    setKey(false, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
    // redirect to component's route
    await redirect(module, component, event)
  } else {
    module[G.ADAPTER][G.UI].update(module)
    // await actionWithHooks(module[G.STATE][G.ACTION])([])
  }
})

export default save
