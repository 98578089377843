/* global G */
import { curry } from 'lib/util'

/**
 * Save Tree In Module Event Handler
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 */
const saveTreeInModule = (module, component, event) => {
  const moduleState = module[G.STATE]
  moduleState[[component._name]] = event.detail.value
}

export default curry(saveTreeInModule)
