/* eslint-disable no-unused-vars */
/* global G */
import validateRedirect from 'app/support/action/validateRedirect'
import sequenceModuleLogin from 'lib/sequence/module/login'

/**
 * Reset Password Login Action
 *
 * This is a pure business logic action, it has no UI
 * Uses {@link sequenceModuleLogin} to log in the user and set the appropriate data on the client.
 *
 * @param {Gaia.AppModule.Spec} module - application module
 * @return {function(*=): function(...[*]): Promise<boolean|*[]>}
 */
const action = module => component => async (...args) => {
  try {
    await sequenceModuleLogin(module)()
    await module[G.ADAPTER][G.UI].provide(module)
  } catch (e) {
    console.warn(e)
    const moduleState = module[G.STATE]
    const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]
    sessionState[G.ERROR] = true
    moduleState[G.ERROR] = true
  }

  const defaultRoute = module[G.ADAPTER][G.ROUTER].defaultRoute()

  return validateRedirect(module)(component)(defaultRoute)
}

export default action
