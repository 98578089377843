/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { actionWithHooks } from 'lib/sequence/module/action'
import cancelOnError from 'lib/sequence/model/cancelOnError'

/**
 * Sets tabs' activeTab state to {@param tab}.
 * Note: Requires the tab component to have a key of {@code tabs}!
 *
 * @param {string} tab                 the mode to set to the current step's state
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>} void
 */
const setTab = async (tab, module, component, event) => {
  /**
   * Do not change the tab if the previous one contains an error.
   */
  cancelOnError(tab, module)

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { tabs } = asObject(actionComponent[G.CHILDREN])

  const tabsState = tabs[G.STATE]
  tabsState.activeTab = tab

  /**
   * We need {@code actionWithHooks} here instead of {@actionFn}
   * because we may need to do additional things, like
   * validation per tab.
   */
  const action = module[G.ACTIONS][tab] || module[G.STATE][G.ACTION]
  action && await actionWithHooks(action)([])
}

export default curry(setTab)
