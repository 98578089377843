/* eslint-disable no-restricted-globals,no-alert,object-curly-newline */
/* global G */
import { curry, getFirstItem, setKey } from 'lib/util'
import { setData } from 'lib/sequence/model/api/set'
import sequenceComponentState from 'lib/sequence/component/state'
import search from 'app/_shared/events/search'

const {
  set: setError,
  unset: clearError,
} = sequenceComponentState('error')

const {
  set: setHelperText,
  unset: clearHelperText,
} = sequenceComponentState('helperText')

/**
 * Sets error state {@param hasError} for the item property of the request model attribute.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {boolean} hasError
 */
const setItemModelError = (module, hasError) => {
  const model = module[G.MODEL]
  const { request } = model[G.CHILDREN]
  const { item } = request[G.CHILDREN]
  item[G.STATE][G.ERROR] = hasError
  return true
}

/**
 * Returns the translated text to be displayed whenever a device is not found.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {*}
 */
const deviceNotFoundText = async (module) => {
  const _key = 'label.deviceNotFound'
  return await module[G.ADAPTER][G.INTL]._t(_key, {
    ns: 'device',
    _key,
    defaultValue: 'Device not found',
  })
}

/**
 * Stores the serial of an equipment {@param item} and the name of its product in the model's
 * itemData attribute. It also stores their keys in the corresponding (equipment and product) model
 * refs.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {object} [item]               an equipment object
 */
const setItemData = (module, item = {}) => {
  const model = module[G.MODEL]
  const { request } = model[G.CHILDREN]
  const { equipment, product } = request[G.CHILDREN]
  // obtaining equipment data
  const { key: equipmentKey = null, value = {}, refs: { product: productRef } = {} } = item
  const { serial, name: equipmentName = 'Unknown' } = value
  // obtaining product data
  const currentProduct = getFirstItem(productRef) || {}
  const { key: productKey = null, value: productValue = {} } = currentProduct
  const { name = equipmentName } = productValue
  // setting model data
  setData(model, { request: { itemData: serial ? { serial, name } : null } })
  setKey(equipmentKey, G.REF, equipment[G.STATE])
  currentProduct && setKey(productKey, G.REF, product[G.STATE])

  return true // just so that we can chain it with &&
}

/**
 * Event Handler Search Device
 *
 * Attempts to find equipment by its serial and, depending on what is found, sets the current
 * model's item, equipment and product attributes with their information. Otherwise, if nothing is
 * found, those model attributes are cleared.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    the event object that triggered this handler
 * @returns {Promise<void>}
 */
const searchEquipment = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const serial = event.detail.value?.trim()
  const required = component[G.VALIDATOR]
  // clearing field & model state
  clearError(component)
  clearHelperText(component)
  setItemModelError(module, false)

  if (serial) {
    const [item] = await search(null, module, component, { term: `"${serial}"`, type: 'equipment' })

    item
      ? setItemData(module, item)
      : setItemData(module)
        && setHelperText(component, await deviceNotFoundText(module))
        && required
        && setItemModelError(module, true)
        && setError(component, true)
  }

  module[G.ADAPTER][G.UI].update(module)
}

export default curry(searchEquipment)
