/* global G */
import { curry } from 'lib/util'

/**
 * Search Event Handler
 *
 * used for fetching data for rendered iterable ui components
 *
 * executes remote api call with given parameters via POST
 *
 * requires a predefined set of parameters, where as the filter body should
 * be provided via an action FILTER implementation
 *
 *
 * @param {string} [key='default'] - listing type, ie short, verbose, etc
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 *
 * @param {Event|object} [eventOrOptions]       information about the event that triggered this
 *                                              handler or a search term
 * @param {object} [eventOrOptions.detail]      information about the target of the event
 * @return {Promise<{value: string, key: *}[]>} void
 */
// eslint-disable-next-line no-unused-vars
const searchFn = async (key, module, component, eventOrOptions) => {
  try {
    const options = (eventOrOptions && eventOrOptions.detail) || eventOrOptions || {}
    const { version, api } = module[G.MODEL][G.PROPS]
    const { term: eventTerm, type: eventType, filter: eventFilter, query: eventQuery } = options
    const { api: { public: isPublic, type = eventType } = {} } = component[G.PROPS]
    const { limit, sort, unsorted = false } = component[G.PROPS]
    const { term = eventTerm, filter, transform, query } = component[G.STATE][G.META] || {}

    const filters = filter && Object.keys(filter).reduce((acc, name) => {
      const hasValues = !(filter[name]?.length === 0)
      if (hasValues) {
        acc[name] = filter[name]
      } else if (!hasValues) {
        acc.refexists = `!${name}`
      }
      return acc
    }, {})

    const params = {
      limit: limit || 100,
      sort: !unsorted ? sort || [
        // ['customerData.value.name<string>'], // does not work atm
        // ['dateCreated<string>', 'desc'], // only first level value.* can be respected in sorting
        // only first level value.* can be respected in sorting
        ['name<string>', 'asc'], // only first level value.* can be respected in sorting
      ] : [],
      transform: transform || {
        key: key || 'default',
      },
      query: {
        term,
        ...type !== false && {
          type: type || api, // document type. Prioritizing what is defined in component.
        },
      },
      filter: {
        ...filters,
        ...eventFilter,
      },
      lucene: query || eventQuery,
      // example: {
      //   "dateCreated": {
      //     "minDate": "2020-07-16T13:40:20.233Z"
      //   }
      // }
    }

    const url = isPublic ? `/api/v${version}/public/search` : `/api/v${version}/search`
    try {
      const remoteAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]
      const result = await remoteAdapter.post({ url, params })
      return result.value
    } catch {
      console.error('search:: tried and failed getting remote resources', url)
    }
  } catch (e) {
    console.error(e)
  }
  return [
    { key: -1, value: 'Not Found' },
  ]
}

export default curry(searchFn)
