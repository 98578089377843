/* global G */
import { curry } from '@gaia/util'
import asObject from '@gaia/sequence/component/children/asObject'

/**
 * Contextmenu Event handler
 *
 * @param {String|null} ref               name of a ref to get the key from
 * @param {Gaia.AppModule.Spec} module    app module
 * @param {Gaia.Component.Spec} component action component
 * @param {Object} data                   data to display in the portal
 * @param {Event|object} [event]          information about the event that triggered this
 */
const fn = (ref, module, component, data, event) => {
  event.preventDefault()
  event.stopPropagation()
  const { contextmenu } = asObject(component[G.CHILDREN])

  contextmenu[G.CHILDREN].forEach((item) => {
    const state = item[G.STATE]
    state.key = ref ? data.refs[ref]?.[0].key : data.key
    state.item = ref ? data.refs[ref]?.[0] : data
  })

  module[G.ADAPTER][G.UI].portal([
    {
      item: contextmenu,
      props: {
        open: true,
        hidden: false,
        pos: { left: event.clientX - 2, top: event.clientY - 4 },
        data,
      },
    },
  ])
}

export default curry(fn)
