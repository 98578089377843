/* global G */
import { curry } from 'lib/util'
import setStepTab, { CONFIRM } from 'app/_shared/events/setStepTab'
import modelSet from 'app/_shared/events/model/set'
import asObject from 'lib/sequence/component/children/asObject'
import undefinedModelLabel from 'app/_shared/events/device/undefinedModelLabel'

/**
 * Sets an item from a list as the current request's item.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<*>}
 */
const setItem = curry(async (module, component, event) => {
  const model = module[G.MODEL]
  await modelSet('item', module, component, event)
  const { itemData, item: modelItem } = model[G.CHILDREN]
  const { product: modelProduct, installedAt } = modelItem[G.CHILDREN]
  modelProduct[G.STATE][G.REF]
    && await modelSet('product', module, component, modelProduct[G.STATE][G.REF])

  installedAt[G.STATE][G.REF]
    && await modelSet('itemInstalledAt', module, component, installedAt[G.STATE][G.REF])

  itemData[G.CACHE] = {
    value: {
      serial: modelItem[G.CACHE].value.serial,
      name: modelProduct[G.CACHE]?.[0].value.name || await undefinedModelLabel(module),
    },
  }
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const steps = asObject(stepper[G.CHILDREN])
  steps.location[G.STATE].confirmed = true
  setStepTab(CONFIRM)(module, component, event)
})

export default setItem
