/* global G */
import moduleActionSequence from 'lib/sequence/module/action'
import moduleRouterRedirectSequence from 'lib/sequence/module/adapter/router/redirect'
// import modelToComponentSequence from 'lib/sequence/module/model/toComponent'

const _get = (obj, symbol) => obj && obj[symbol] && obj[symbol]

export const view = obj => () => _get(obj, G.VIEW)
export const action = obj => () => _get(obj, G.ACTIONS)
export const children = obj => () => _get(obj, G.CHILDREN)
export const model = obj => () => _get(obj, G.MODEL)
export const modelApi = obj => () => _get(obj[G.MODEL], G.API)
export const currentAction = obj => () => _get(obj[G.STATE], 'action') // obj[G.STATE].action
export const currentActionComponent = obj => () => _get(obj[G.API].currentAction(), G.COMPONENT)


const api = obj => ({
  ...moduleActionSequence,
  redirect: moduleRouterRedirectSequence(obj),

  model: model(obj),
  modelApi: modelApi(obj),
  view: view(obj),
  action: action(obj),
  // map: modelToComponentSequence(obj),
  currentAction: currentAction(obj),
  currentActionComponent: currentActionComponent(obj),
})

export default api
