/* global G */
import { modelComposition } from '@gaia/trait/composition'
import { pipe } from 'lib/util'
import { withGetterSetter } from 'trait/with'

const descriptor = 'model::contactChannels' // if submodel, this has to be overridden..
// withDescriptor for the rescue.
// i need specific descriptor for models and model attributes

// export default modelComposition(descriptor)
export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
)
