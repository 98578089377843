/* global G */
import { curry } from 'lib/util'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'

/**
 * Event Handler Create Redirect
 *
 * Redirects to the route stated inside action.ui.create.
 *
 * @param {object} module             the current module composition object
 * @param {object} component          the current component
 * @param {object} event              the fired event
 * @param {object} event.detail       the fired event's detail
 * @param {object} event.detail.route the route to redirect to whenever this event is fired
 * @return {Promise<void>}
 */
const createRedirect = async (module, component, event) => {
  try {
    const moduleState = module[G.STATE]
    const { route } = event.detail
    moduleState[G.ROUTE] = routeComposition(route.module, route.action)
    await redirectSequence(module)({})
  } catch (e) {
    console.error(e)
  }
}

export default curry(createRedirect)
