/* eslint-disable no-unused-expressions */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject';
import { send, target as getTarget } from 'app/_shared/events/message'
import { get } from 'lib/sequence/component/state/value'
import { changeStatus as changeTicketStatus } from 'app/_shared/events/status';
import sequenceComponentState from 'lib/sequence/component/state'
import reset from 'app/_shared/component/reset'

const {
  set: error,
} = sequenceComponentState('error')

/**
 * Reopens the request by sending a message with the
 * content of {@code description} and setting
 * the requests' status to 60.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param event
 * @returns {Promise<*>}
 */
const reopenRequest = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { reopenRequest: reopenRequestComponent } = asObject(actionComponent[G.CHILDREN])
  const { description: descriptionComponent } = asObject(reopenRequestComponent[G.CHILDREN])
  const { text: descriptionText } = asObject(descriptionComponent[G.CHILDREN])

  try {
    /**
     * Getting text, title and target
     */
    const text = get(descriptionText)
    error(descriptionText, !text)

    !text && throw Error('Description is mandatory')

    const { number: title } = model[G.CACHE].value;
    const target = getTarget(module)

    /**
     * Changing request status to 60
     * and sending message.
     */
    await changeTicketStatus(module, component, 60, 0, true);
    await send(module, component, { text, target, title })
    reset(descriptionText)
  } catch (e) {
    await module[G.ADAPTER][G.UI].update(module)
    console.error(e)
  }
}

export default curry(reopenRequest)
