/**
 * ContactChannel type definition
 *
 * @typedef {Object} contactChannel
 * @property {String} phone   phone type
 * @property {String} email   email type
 * @property {String} url     url type
 */

/**
 * Contact channel type collection
 *
 * Contains an object housing every possible type.
 *
 * @typedef {contactChannel} contactChannel
 */
export default {
  phone: 'phone',
  email: 'email',
  url: 'url',
}
