/* global G */
import { modelComposition } from '@gaia/trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import toCountry from 'model/_shared/transformer/toCountry'
import fromCountry from 'model/_shared/decorator/fromCountry'
import withGetterSetter from 'trait/with/GetterSetter'
import toNullable from 'model/_shared/transformer/toNullable'

const descriptor = 'model::cart::address'

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    country: fromCountry('country'),
  }),
  usesGetterSetter(G.TRANSFORMER, {
    street: toNullable('street'),
    city: toNullable('city'),
    zip: toNullable('zip'),
    country: toCountry('country'),
  }),
)
