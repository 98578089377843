/**
 * Ticket & Request Types Collection.
 *
 * Contains static list of available ticket and request's types, which can be filled with data for
 * different purposes, like filling lists, allowing options selection, etc.
 *
 * deviceInput:
 * - {@code true}: show device related steps and make them mandatory
 * - {@code false}: hide device related steps
 * - not set: show device related steps, but not mandatory
 *
 * deviceIdentify:
 * - {@code true}: the given device has to be identified
 * - {@code false}: User can still complete step without identification
 *
 * additionalPartyInput:
 * - {@code false}: always hide additional party related steps
 *
 * softwareInput:
 * - {@code true}: Display fields {@ deviceFirmwareVersion}, {@code PCSoftwareProgam} and
 * {@code PCSoftwareVersion} in issue step. Fields are still optional.
 * - {@code false}: Don't display them.
 *
 * fieldServiceInput:
 * - {@code true}: Display fields {@ deviceMalfunction}, {@code serviceCallDesiredDateStart} and
 * {@code serviceCallDesiredDateEnd} in issue step. Fields are still optional.
 * - {@code false}: Don't display them.
 */
export default [
  {
    key: 'device_breakdown',
    value: 'Device breakdown',
    icon: 'crisis_alert',
    deviceInput: true,
    deviceIdentify: true,
  },
  {
    key: 'technical_issue',
    value: 'Technical issue',
    icon: 'on_device_training',
    deviceInput: true,
    softwareInput: true,
  },
  {
    key: 'software_support',
    value: 'Software support',
    icon: 'terminal',
    softwareInput: true,
  },
  {
    key: 'clarification_spare_parts',
    value: 'Spare part',
    icon: 'settings_suggest',
  },
  {
    key: 'general_service_request',
    value: 'General',
    icon: 'help',
  },
  {
    key: 'require_service',
    value: 'Require service',
    icon: 'home_repair_service',
    hidden: true,
  },
  {
    key: 'field_service',
    value: 'Field service',
    icon: 'home_repair_service',
    fieldServiceInput: true,
  },
  {
    key: 'remote_support',
    value: 'Remote support',
    icon: 'settings_remote',
    hidden: true,
  },
  {
    key: 'digital_platform',
    value: 'Digital platform',
    icon: 'webhook',
    hidden: true,
  },
  {
    key: 'order_spare_parts',
    value: 'Order spare parts',
    icon: 'settings_suggest',
    hidden: true,
  },
  {
    key: 'order_consumables',
    value: 'Order consumables',
    icon: 'oil_barrel',
    hidden: true,
  },
  {
    key: 'suggestion',
    value: 'Suggestion',
    icon: 'reviews',
    deviceInput: false,
    hidden: true,
  },
  {
    key: 'feedback_commendation',
    value: 'Feedback/Commendation',
    icon: 'reviews',
    deviceInput: false,
    hidden: true,
  },
  {
    key: 'complaint',
    value: 'Complaint',
    icon: 'sentiment_dissatisfied',
    deviceInput: false,
    hidden: true,
  },
  {
    key: 'other',
    value: 'Other',
    icon: 'more_horiz',
    deviceInput: false,
  },
]
