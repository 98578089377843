/* global G */

import { bulkMatch, rules } from 'lib/validator/isTimeEntry'

/**
 * TimeEntry Model Validator
 *
 * Matches input against {@link rules}.
 *
 * @param {Gaia.Model} obj the model to validate
 * @return {function(*, *): Promise<*[]>}
 */
const fn = obj => async (uiState, key) => {
  const { options } = obj[G.CONFIGURATION].validator[key]
  const { error } = options

  const [match] = bulkMatch(uiState.value)(rules) || []

  if (!match) throw TypeError(error)

  // otherwise, field is valid
  return [uiState, key]
}

export default fn
