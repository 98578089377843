/* global G */
import { curry, getFirstItem, isArr } from 'lib/util'
import { getPropertyValue } from 'lib/util/object'

const descriptor = 'model::team::followTheSun::decorator::fromTimezone'

/**
 * From Time Decorator
 *
 * Takes either an array of time (or timeRange) strings from {@param sourceKey} and converts them
 * to dates.
 *
 * If the given string is not a correct DateTime string (e.g. "18:00"), {@code Date.now()} will be
 * used and the hours and minutes will be set accordingly.
 *
 * @example
 * ['18:00'] -> "2023-07-31T18:00:00.119Z"
 * ['18:00', '19:00'], from: true -> "2023-07-31T18:00:00.119Z"
 * ['18:00', '19:00'], from: false -> "2023-07-31T19:00:00.119Z"
 * ['18:00-18:30', '19:00-19:30'], from: true -> "2023-07-31T18:00:00.119Z"
 * ['18:00-18:30', '19:00-19:30'], from: false -> "2023-07-31T19:30:00.119Z"
 *
 * @param {String} key        name of the attribute
 * @param {String} sourceKey  name of the source attribute to get the value from
 * @param {Boolean} from      whether to get the fromTime or toTime
 * @returns {(function(...[*]): (*))|*}
 */
const fn = (key, sourceKey, from) => curry((obj, component) => {
  console.warn(descriptor, key)
  const attribute = obj[G.CHILDREN]?.[key]
  const source = getFirstItem(obj[G.CACHE]?.[sourceKey])

  const container = getPropertyValue(source)
  const times = getPropertyValue(container)

  const rawTime = times && (isArr(times)
    ? (from && getFirstItem(times).split('-')[0]) || times.pop().split('-')[1]
    : times.split('-')[from ? 0 : 1])

  const isValidTime = !Number.isNaN(Date.parse(rawTime))

  const parsedTime = isValidTime
    ? new Date(rawTime)
    : new Date(Date.now())

  if (rawTime && !isValidTime) {
    const h = rawTime ? parseInt(rawTime.split(':')[0], 10) : 0
    const m = rawTime ? parseInt(rawTime.split(':')[1], 10) : 0

    parsedTime.setHours(h)
    parsedTime.setMinutes(m)
  }

  attribute[G.CACHE] = !rawTime ? '' : parsedTime

  return component
})

export default fn
