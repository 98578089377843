/* eslint-disable no-case-declarations,no-param-reassign */
/* global G */
import { bulk, curry, getKey, setKey } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import validate from 'lib/sequence/model/validate'
import findDevice from 'app/_shared/events/device/find'
import { get } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { actionWithHooks } from 'lib/sequence/module/action'
import setStepTab, { CONFIRM } from 'app/_shared/events/setStepTab'
import refresh from 'lib/sequence/model/api/refresh'
import modelReset from 'lib/sequence/model/api/reset'
import { v4 as uuidV4 } from 'uuid'
import undefinedModelLabel from 'app/_shared/events/device/undefinedModelLabel'
import { settings } from 'app/_shared/session'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)
const bulkModelReset = bulk(modelReset)

/**
 * Validates the serial field and uses {@link findDevice} to attempt to find a product  while
 * handling the different possibilities:
 *  * If a device is found it means that the product we need is attached to it, so we show a warning
 *  * If no product is found, we show an info message
 *  * If an available product is found, we go to the next step
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>} void
 */
const searchProduct = curry(async (module, component, event) => {
  const model = module[G.MODEL]
  const { itemData, item, product, equipment, itemInstalledAt } = model[G.CHILDREN]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const { device } = asObject(stepper[G.CHILDREN])
  const { search } = asObject(device[G.CHILDREN])
  const { actions } = asObject(search[G.CHILDREN])
  const { form } = asObject(actions[G.CHILDREN])
  const { serial: serialField, options } = asObject(form[G.CHILDREN])
  const { existingHelp, btnMore } = asObject(options[G.CHILDREN])

  // clear all item-dependant fields
  bulkModelReset(
    item,
    equipment,
    product,
    itemInstalledAt,
  )
  // hiding help buttons
  bulkHide(existingHelp, btnMore)

  setKey(null, G.ERROR, item[G.STATE])
  // validate serial field
  await validate(itemData)(form)

  // if serial field is valid
  if (!itemData[G.STATE][G.ERROR]) {
    // get its value
    const serial = get(serialField)
    // use its value to look for an item, equipment or product
    const found = await findDevice(module, serialField, serial)

    // store inserted serial and found name
    itemData[G.CACHE] = { value: { serial, name: found.name || await undefinedModelLabel(module) } }

    if (found.serviceItem) {
      // save found service itemCannot convert a Symbol value to a string
      await refresh(item, found.serviceItem)

      found.installedAt
      && await refresh(itemInstalledAt, found.installedAt)

      getKey(G.REF, item[G.STATE])
        ? setKey(false, G.BULK, item[G.STATE])
        : setKey(true, G.BULK, item[G.STATE])
          && setKey(uuidV4(), G.REF, item[G.STATE])
          && setKey(50, 'status', item[G.DATA])

      // save found equipment
      found.equipment
      && await refresh(equipment, found.equipment)
      // save found product
      found.product
      && await refresh(product, found.product)

      setStepTab(CONFIRM)(module, component, event)
    } else if (settings.suppressProductDevices && !serialField[G.STATE].identify) {
      await refresh(item, itemData[G.CACHE])
      bulkShow(existingHelp, btnMore)
    } else {
      bulkShow(existingHelp, btnMore)
    }
  }
  await actionWithHooks(module[G.STATE][G.ACTION])([])
})

export default searchProduct
