/* global G */
import { pipe } from 'lib/util'
import { modelComposition } from '@gaia/trait/composition'
import withGetterSetter from 'trait/with/GetterSetter'
import { usesGetterSetter } from 'trait/uses'
import validator from './validator'

const descriptor = 'model::person::invite'

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
)
