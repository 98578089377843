/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * List Breadcrumbs Event Handler
 *
 * Obtains the names of the previous routes from their cache and the name of the current route from
 * the current action's data and puts them together as an array of objects with a single name
 * property.
 *
 * @type {Gaia.AppModule.EventHandler}  event handler
 * @returns [{},...*,{name}]
 */
const list = (module, component, event) => {
  const session = module[G.ADAPTER][G.SESSION]
  const stack = session[G.STATE][G.PREV]
  return [
    {},
    ...stack.map(route => ({ name: route[G.CACHE].title?._cache })),
    { name: module[G.STATE][G.ACTION][G.DATA].title?._cache },
  ]
}

export default curry(list)
