/* global G */
import { curry, getFirstItem } from 'lib/util'
import persistStore from 'app/_shared/events/combined/persistStore'
import search from 'app/_shared/events/search'
import find from 'lib/sequence/component/children/find'
import { get, reset } from 'lib/sequence/component/state/value'
import { uncheck } from 'lib/sequence/component/state/checked'
import { actionFn } from 'lib/sequence/module/action'
import showDialog, { showBlockingDialog } from 'app/_shared/events/dialog'
import { persistCheck } from 'app/_shared/events'

/**
 * Asks the server to return all items that have {@code source} in {@code ref} and returns their
 * {@code toBeValidated} property.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, *): Promise<*|(function(...[*]): (*))>}
 * @private
 */
const _checkSubItems = module => async (source, ref) => {
  const result = await search(
    null, module, module[G.STATE][G.ACTION][G.COMPONENT], {
      type: source,
      filter: { [ref]: module[G.MODEL][G.STATE][G.REF] },
    },
  )
  const item = getFirstItem(result)

  return item?.value?.toBeValidated
}

/**
 * Resets the {@code mergeToDuplicateTarget} field and reloads the action.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {Promise<void>}
 * @private
 */
const _reset = async (module) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { mergeToDuplicateTarget } = find(actionComponent)

  reset(mergeToDuplicateTarget)
  uncheck(mergeToDuplicateTarget)

  await actionFn(module[G.STATE][G.ACTION])()
  // update won't do since checkbox checked prop never changes in this case
  module[G.ADAPTER][G.UI].create(module)
}

/**
 * Check Merge And Persist Store Event handler
 *
 * Checks whether sub-items of the current organisation (user, sub-locations, serviceItems) can be
 * merged with the {@code duplicateOf} of the current org. If so, it shows a confirm dialog
 * and sets the {@code mergeToDuplicateTarget} prop to {@true}.
 *
 * If not, shows an error dialog informing the user.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<void>}
 */
const checkMergeAndPersistStore = async (module, component, event) => {
  const isChecked = event?.detail?.checked

  if (!isChecked) {
    persistStore(module, component, event)
    return
  }

  // checking sub items
  const subOrganisations = await _checkSubItems(module)('organisation', 'parent')
  const subServiceItems = await _checkSubItems(module)('serviceitem', 'installedAt')
  const subPersons = await _checkSubItems(module)('person', 'organisation')
  const subUsers = await _checkSubItems(module)('user', 'organisation')

  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { duplicateOf } = find(actionComponent)

  if (subOrganisations || subServiceItems || subPersons || subUsers) {
    await showDialog(module, component, {
      title: {
        ns: 'admin',
        key: 'dialog.mergeError.title',
        defaultValue: 'Data transfer not possible',
      },
      text: {
        ns: 'admin',
        key: 'dialog.mergeError.text',
        defaultValue: 'All associated data has to be verified before it can be transferred to the target',
      },
    })

    await _reset(module)

    return
  }

  try {
    const model = module[G.MODEL]
    const targetKey = getFirstItem(get(duplicateOf))?.key || null
    const { version, api } = model[G.PROPS]
    const url = `/api/v${version}/${api}/${targetKey}`
    const target = await model[G.ADAPTER][G.HTTP].get({ url })

    if (target?.value?.toBeValidated || target?.refs?.duplicateOf || target?.value?.status !== 50) {
      await showDialog(module, component, {
        title: {
          ns: 'admin',
          key: 'dialog.mergeTargetError.title',
          defaultValue: 'Data transfer not possible',
        },
        text: {
          ns: 'admin',
          key: 'dialog.mergeTargetError.text',
          defaultValue: 'Data can\'t be transferred to this target. It has to be verified, active and not a duplicate itself',
        },
      })

      await _reset(module)

      return
    }
  } catch (e) {
    console.error(e)
  }

  const duplicateName = getFirstItem(get(duplicateOf))?.value?.name
  const confirmed = await showBlockingDialog(module, component, {
    title: {
      ns: 'admin',
      key: 'dialog.mergeConfirm.title',
      defaultValue: 'Data transfer to target',
    },
    text: {
      ns: 'admin',
      key: 'dialog.mergeConfirm.text',
      defaultValue: 'Transfer all associated data to target?',
      ...duplicateName && { type: duplicateName },
    },
  })

  confirmed
    ? persistCheck(module, component, event)
    : await _reset(module)
}

export default curry(checkMergeAndPersistStore)
