/* eslint-disable no-unused-expressions,no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import resetComponent from 'app/_shared/component/reset'

/**
 * Event Handler Reset Action Component
 *
 * Resets the state of the component tree of the current module's action named {@param actionName}
 * and recreates its view.
 *
 * @param {string} actionName           the name of the action to reset the component to
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 information about the event that triggered this handler
 * @return {Promise<void>}
 */
const resetActionComponent = curry(async (actionName, module, component, event) => {
  const targetAction = module[G.ACTIONS][actionName]
  if (targetAction) {
    resetComponent(targetAction[G.COMPONENT])
  } else {
    console.warn(`resetActionComponent: No action '${actionName}' found for current module`)
  }
})

export default resetActionComponent
