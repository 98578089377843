/* global G */

/**
 * Recreate Hook
 *
 * Forces the current module's ui to be created again.
 *
 * @param {object} obj  the current module composition object
 * @return {function(...[*]): Promise<*[]>}
 */
const recreate = obj => async (...args) => {
  obj[G.ADAPTER][G.UI].create(obj)
  return args
}

export default recreate
