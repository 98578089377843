/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { check, uncheck } from 'lib/sequence/component/state/checked'
import { set, reset } from 'lib/sequence/component/state/value'

/**
 * If the model's request attribute has a key stored, shows a dialog informing the user that the
 * current requesterContact cannot be deleted and throws an error to break the eventual event chain.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
export default curry((module, component, event) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]
  const { request } = module[G.MODEL][G.CHILDREN]
  const requestExists = !!request[G.STATE][G.REF]

  if (requestExists) {
    eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
      title: module[G.ADAPTER][G.INTL]._t(
        'dialog.error.requestExists.title',
        {
          ns: 'ticket',
          _key: 'dialog.error.requestExists.title',
          defaultValue: 'Sorry',
        },
      ),
      text: module[G.ADAPTER][G.INTL]._t(
        'dialog.error.requestExists.text',
        {
          ns: 'ticket',
          _key: 'dialog.error.requestExists.text',
          defaultValue: 'The requester contact cannot be removed due to the existing conversation.',
        },
      ),
      children: {
        cancel: {
          key: 'cancel',
          value: module[G.ADAPTER][G.INTL]._t(
            'button.cancel',
            {
              ns: 'common',
              _key: 'button.cancel',
              defaultValue: 'Cancel',
            },
          ),
        },
      },
    })
    throw new Error('Prevented due to the existence of a paired request.')
  }
})
