/* global G */
import { curry, setKey } from 'lib/util'

/**
 * Persists incoming value in corresponding component's state.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent} event - eventOrValue
 */
const persist = (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()
  const state = component[G.STATE]
  state.value = event.detail.value
}

/**
 * Persists incoming value in corresponding component's state.
 *
 * Set's the {@code G.UNDO} flag so that going back without saving will trigger a dialog.
 * Since there is no need to refresh the ui, it is handled on component level only.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent} event - eventOrValue
 */
const persistChange = (module, component, event) => {
  persist(module, component, event)

  setKey(true, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
}

/**
 * Persists incoming value in corresponding component's state.
 *
 * G.UNDO flag will not be set. Going back will not trigger a warning dialog.
 * Since there is no need to refresh the ui, it is handled on component level only.
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent} event - eventOrValue
 */
export const persistUnsaveChange = curry((module, component, event) => {
  persist(module, component, event)
})

export default curry(persistChange)
