import isInteger from './isInteger'
import isTimeEntry from './isTimeEntry'
import notEmptyAttribute from './notEmptyAttribute'
import notEmpty from './notEmpty'
import minLength from './minLength'
import isEqual from './isEqual'
import regExp from './regExp'
import isEmail from './isEmail'
import isPhoneNumber from './isPhoneNumber'
import specialCharacter from './specialCharacter'

/**
 * Shared Model Attribute Validators
 * to use in attribute composition
 * @example
 * {
      options: {
        key: 'password',
        type: 'value',
      },
      validator: [
        {
          type: 'notEmpty',
          options: {
            error: 'Password is required'
          },
        },
        {
          type: 'minLength',
          options: {
            min: 8,
            error: 'Minimum number of characters:'
          }
        },
        {
          type: 'specialCharacter',
          options: {
            error: 'Must have at least 1 special character (!?/.@#$%^&*|-_)',
          }
        }
      ],
    },
 *
 * @type {{minLength: ((function(string, Object): string)|*), isEmail: ((function(*=, *): boolean)|*), notEmpty: ((function(string, Object): string)|*), specialCharacter: ((function(*=, *): boolean)|*)}}
 */
const validator = {
  notEmpty,
  notEmptyAttribute,
  minLength,
  isEqual,
  isInteger,
  regExp,
  isEmail,
  isPhoneNumber,
  isTimeEntry,
  specialCharacter,
}

export {
  notEmpty,
  notEmptyAttribute,
  minLength,
  isEqual,
  isInteger,
  regExp,
  isEmail,
  isPhoneNumber,
  isTimeEntry,
  specialCharacter,
  validator as default,
}
