/* global G */
import { curry, getFirstItem, getKey } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Links to the current serviceItem.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<*>}
 */
// eslint-disable-next-line no-unused-vars
const toRef = curry((ref, module, component, event) => {
  const model = module[G.MODEL]
  const context = getKey(G.CONTEXT, module[G.ADAPTER][G.SESSION][G.STATE])
  const { module: targetModule, action } = component[G.CONFIGURATION].route

  const { [ref]: item } = asObject(model[G.CHILDREN])
  const itemCache = getFirstItem(item[G.CACHE])
  const key = item[G.STATE]?.[G.REF] || itemCache?.key || itemCache

  return key
    ? `#/${context}/${targetModule}/${action}/${key}`
    : null
})

export default toRef
