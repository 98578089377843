/* global G */
import { curry } from 'lib/util'

const descriptor = 'model::decorator::fromSingleRef'

/**
 * key attribute should be a single ref model
 *
 * @param key
 * @param target
 * @param obj
 * @param component
 * @return {*}
 */
const fn = (key, target, obj, component) => {
  console.warn(descriptor, key, target)

  const source = obj[G.CHILDREN][key]
  // console.log(source._name)
  // console.log(source[G.CACHE])
  // console.log(!!source[G.DATA], source[G.DATA])
  // console.log(source[G.STATE])

  // todo: fix the shittouttame after lunch
  // const value = source[G.DATA] ? source[G.DATA].value : source[G.CACHE] // whats this?! usable?
  // doesnt seem like it at first glance...
  //  things to consider:
  //   * is the initial usage of the decorator correct? mapping of person to person in our case
  //   * doing mapping all the time is unnecessary atm,
  //     as only password has requirement to delegate error messages somewhere else.
  //   * action can/should decide if it should map or not,
  //     as we are tackling multiple submit cases anyways
  //   * if we remove the condition of [0], then why would this still be called fromSingleRef() ????
  //     points to initial misuse of the decorator
  const value = source[G.CACHE] ? source[G.CACHE][0] : source[G.CACHE]

  // copy the data into the target attribute
  const item = obj[G.CHILDREN][target]
  // item[G.DATA].value = value
  item[G.CACHE] = value ? [value] : value // moving to cache, so map() can do it's thing

  // todo: create new, delete, select -> wtf in refs
  //  wtf is this "to do"...

  return component
}

export default curry(fn)
