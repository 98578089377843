import { curry } from 'lib/util'
import { detail } from 'app/_shared/events'

/**
 * toPerson event handler
 *
 * Calls {@link detail} event handler with correct person ID.
 *
 * @param {Gaia.AppModule.Spec} module  app module
 * @param {Gaia.Component} component    ui component
 * @param {Gaia.PlatformEvent} event    event
 *
 * @return {Promise<void>} void
 */
const toPerson = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const { key } = event.detail.item.refs.person[0]

  await detail(module, component, { ...event, detail: { key } })
}

export default curry(toPerson)
