import add from './add'
import edit from './edit'
import remove from './remove'

const note = {
  add,
  edit,
  remove,
}

export {
  note as default,
  add,
  edit,
  remove,
}
