/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { set } from 'lib/sequence/component/state/value'

/**
 * Search Mixed And Recreate Event Handler
 *
 * Grabs the current search term and adds it to the lists G.STATE G.META. Recreates the view
 * afterwards to trigger a new search
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {PlatformEvent} event         information about the event that triggered this handler
 * @return {Promise<void>}
 */
const setSearchTerm = async (module, component, event) => {
  const { term } = event?.detail || event || {}

  if (term) {
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { searchField } = sequenceComponentFindProxy(actionComponent)

    set(searchField, term)

    await actionWithHooks(module[G.STATE][G.ACTION])([])
    await module[G.ADAPTER][G.UI].create(module)
  }
}

export default curry(setSearchTerm)
