/* global G */
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import { canHookInto } from 'trait/can'
import appModuleComposition from 'trait/composition/appmodule'
import actions from '@app/favorite/actions'
import events from '@app/favorite/events'
import hooks from '@app/favorite/hooks'

const { ACTIONS, EVENTS } = G

const descriptor = 'module::favorite'

/**
 * Favorite Module
 *
 *
 * @param {Gaia.AppModule.Spec} obj - configuration object
 * @return {Gaia.AppModule.Spec} obj - object composition
 */
const composition = pipe(
  appModuleComposition(descriptor),
  usesGetterSetter(ACTIONS, actions),
  usesGetterSetter(EVENTS, events),
  canHookInto(ACTIONS, hooks),
)

export default composition
