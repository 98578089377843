/* eslint-disable no-unused-expressions */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import { get, reset, set } from 'lib/sequence/component/state/value'
import sequenceComponentFind from 'lib/sequence/component/children/find'

/**
 * Ticket forward action.
 *
 * Toggles the visibility and mapping of fields depending on whether the service item should just be
 * saved for a ticket or as an individual entity in the database and whether there is already an
 * entity selected.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const { team } = model[G.CHILDREN]
  const { content } = sequenceComponentFind(component)
  const { team: teamField, assignee: assigneeField } = asObject(content[G.CHILDREN])

  !get(teamField) && set(teamField, team[G.CACHE] || [])

  const teamState = teamField[G.STATE]
  const assigneeState = assigneeField[G.STATE]
  // checking whether a team is selected
  const teamKey = teamState.value?.[0]?.key
  // configuring assignee field
  assigneeState[G.REF] = teamKey && 'team'
  team[G.STATE][G.REF] = teamKey
  teamKey ? enable(assigneeField) : reset(assigneeField) && disable(assigneeField)

  return args
}
