/* eslint-disable no-unused-vars */
/* global G */
import { curry, setKey } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Init Transfer And Action Event Handler
 *
 * Sets the {@param module[G.STATE]} {@code init} value to true to signal the action that the
 * webshop transfer should be initialized. Will reload the action afterward.
 *
 * @param {Gaia.AppModule.Spec} module - module composition
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - triggered event
 * @returns {Promise<*|*[]>}
 */
const initTransferAndAction = async (module, component, event) => {
  setKey(true, 'init', module[G.STATE])

  await actionWithHooks(module[G.STATE][G.ACTION])([])
}

export default curry(initTransferAndAction)
