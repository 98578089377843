/* global G */
import { curry, setKey } from 'lib/util'
import routeComposition from 'trait/composition/route'
import { actionWithHooks } from 'lib/sequence/module/action'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

// /**
//  * Event Modal Open
//  *
//  * @param module
//  * @param component
//  * @param event
//  * @return {Promise<void>}
//  */
// // eslint-disable-next-line no-unused-vars
// const open = async (module, component, event) => {
//   console.warn('called MODAL::OPEN event', event.detail)
//   const moduleState = module[G.STATE]
//   const { route } = component[G.CONFIGURATION]
//   moduleState[G.ROUTE] = routeComposition(route.module, route.action)
//   await module[G.ADAPTER][G.ROUTER].modal(moduleState[G.ROUTE])
// }
//
// export default curry(open)

/**
 * Open Modal Event Handler
 *
 * Sets {@code open} to {@code true} for the {@param target} component and
 * reloads the current action.
 *
 * @param {string} target                   key of the modal component
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 */
const open = async (target, module, component, event) => {
  const { payload = null, options = null } = event?.detail || event || {}
  const { [target]: targetComponent } = sequenceComponentFindProxy(module[G.STATE][G.ACTION][G.COMPONENT])

  setKey(true, 'open', targetComponent[G.STATE])
  payload && setKey(payload, 'payload', targetComponent[G.STATE])
  options && setKey(options, 'options', targetComponent[G.STATE])

  await actionWithHooks(module[G.STATE][G.ACTION])([])
}

export const openModalWith = curry(open)

export default curry((module, component, event) => open('modal', module, component, event))
