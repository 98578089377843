/* eslint-disable no-param-reassign,no-return-assign */
/* global G */
import { isArr, isObj } from 'lib/util'
import settings from '@tenant/settings'

const _meta = obj => obj[G.ADAPTER][G.SESSION][G.STATE][G.META] || {}

const _ref = (obj, key) => _meta(obj).refs[key] || null

const _id = obj => obj && (isArr(obj) && obj[0] && (obj[0].key || obj[0]._id || obj[0]))

const _obj = obj => obj && isArr(obj) && isObj(obj[0]) && obj[0]

const _wrap = (obj, enable) => (enable && (obj ? [obj] : [])) || obj || null

const _refObj = (obj, key, options = { wrap: false }) => {
  const ref = _ref(obj, key)
  const refObj = _obj(ref)
  return _wrap(refObj, options.wrap)
}

const _refId = (obj, key, options = { wrap: false }) => {
  const ref = _ref(obj, key)
  const refId = _id(ref)
  return _wrap(refId, options.wrap)
}

/**
 * Session helper
 *
 * Temporarily used to put together all checks related to the existence of session data.
 *
 * Returns an object that contains a property for each item in {@link refs}. Each of those
 * properties offer a key and a ref functions that respectively return the id and the object
 * referenced by the property of the same name inside the current session data.
 *
 * In principle, we should obtain also refs that are not set as an empty array, so no call to
 * obtain values of ref should throw an exception.
 *
 * The current session (login) refs are: user, person, team and organisation.
 *
 * TODO: remove and implement a helper/manager at client/src/lib/manager/session/api/index.js. This
 *        would allow us also to use it directly in platform.
 *
 * @param {object} module module composition object
 * @returns {{}}
 */
export default (module) => {
  const helper = Object.keys(_meta(module).refs || {}).reduce((acc, refName) => {
    acc[refName] = {

      /**
       * Returns the first ID of the ref.
       * @param {object} [options]        an object containing additional options
       * @param {boolean} [options.wrap]  whether the key should be returned inside an array
       * @return {string|string[]}
       */
      key: options => _refId(module, refName, options),

      /**
       * Returns the object of the ref.
       * @param {object} [options]        an object containing additional options
       * @param {boolean} [options.wrap]  whether the object should be returned inside an array
       * @return {object|object[]}
       */
      ref: options => _refObj(module, refName, options),
    }

    return acc
  }, {})

  /**
   * Returns the user's settings.
   * TODO: in the near future, this could be obtained from within the login payload
   * @returns {object}
   */
  helper.settings = settings || {}

  /**
   * Returns the names of the contexts available to the current session.
   * @return {string[]} an array of context names
   */
  helper.contexts = () => Object.keys(_meta(module).context)

  /**
   * Sets the current session's ACLs and Roles according to the passed {@param contextName}.
   * @param {string} contextName the name of the context from which to assign the ACLs
   */
  helper.context = (contextName) => {
    const meta = _meta(module)
    if (contextName) {
      const context = meta.context[contextName]
      meta.acl = context.acl
      meta.roles = context.roles
    }
    return meta
  }

  /**
   * Compares the current context with {@param contextName}.
   *
   * @param {string} contextName
   * @returns {boolean}
   */
  helper.isContext = contextName => module[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT] === contextName

  return helper
}

export {
  settings,
}
