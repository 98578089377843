/* eslint-disable no-unused-vars */
/* global G */
/**
 * Rerenders {@param target} by adding an {@code update} prop to its state.
 *
 * @param {string} target  component to update
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
const updateComponent = target => module => async (components, ...args) => {
  const { [target]: targetComponent } = components

  targetComponent && (targetComponent[G.STATE].update = {})

  return args
}

export default updateComponent
