/* eslint-disable no-unused-expressions */
/* global G */
import { curry, setKey } from 'lib/util'
import { add as addNote } from 'app/_shared/events/note'
import { apply as applyAttachments, get as getAttachments } from 'app/_shared/events/attachment'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import validate from 'lib/sequence/model/validate'
import { get } from 'lib/sequence/component/state/value'
import { fromTimeEntry } from 'app/ticket/event/note/time'
import { actionWithHooks } from 'lib/sequence/module/action'
import { sortNotesByDate } from 'app/ticket/hook/note'
import cancelNote from './cancel'
import { setDetailUndoKey } from '../../action/detail'

/**
 * Event Handler Add Note
 *
 * Asks the note adapter to create a new note and applies the changes to the attachment group whose
 * name is defined by the {@param component}'s group prop.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>}
 */
const add = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const ticket = module[G.MODEL]
  const { spentTime } = sequenceComponentFind(component)

  try {
    const { text, type, mentions } = event.detail
    const time = get(spentTime)
    const attachments = await getAttachments(module, component, event)

    // Validation of spentTime
    await validate(ticket)(component)
    ticket[G.STATE][G.ERROR]
    && setKey(true, 'error', component[G.STATE]) // this will trigger a rerender
    && throw Error('Model validation error')

    const spentSeconds = time
      ? fromTimeEntry(module, component, { value: time })
      : null

    addNote(module, component, {
      text,
      type,
      mentions,
      ...spentSeconds && { spentTime: spentSeconds },
    }, async (item) => {
      const { key, value } = item
      const timeNotes = module[G.STATE]?.timeNotes

      setKey(key, G.REF, component[G.STATE]) // required for applyAttachments
      // So that the new time note is available without fetching them again
      value?.spentTime && setKey([item, ...timeNotes], 'timeNotes', module[G.STATE])
      applyAttachments(module, component, event)
      value.attachments = attachments

      // hide note form
      await cancelNote(module, component, event)
    })

    setDetailUndoKey(module, 'note', false)
  } catch (e) {
    console.error(e)

    await module[G.ADAPTER][G.UI].update(module)
  }
}

/**
 * Event Handler Add Time Entry
 *
 * Asks the note adapter to create a new note and applies the changes to the attachment group whose
 * name is defined by the {@param component}'s group prop.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>}
 */
export const addTimeEntry = curry(async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const ticket = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { spentTime, startDate, content, note } = sequenceComponentFind(actionComponent)

  try {
    const time = get(spentTime)
    const text = get(note)
    const start = get(startDate)

    // Validation of spentTime
    await validate(ticket)(content)
    ticket[G.STATE][G.ERROR]
    && setKey(true, 'error', actionComponent[G.STATE]) // this will trigger a rerender
    && throw Error('Model validation error')

    const spentSeconds = time
      ? fromTimeEntry(module, component, { value: time })
      : null

    addNote(module, component, {
      text,
      type: 'blank',
      spentTime: spentSeconds,
      startDate: start,
    }, async (item) => {
      const timeNotes = module[G.STATE]?.timeNotes
      // So that the new time note is available without fetching them again
      setKey(sortNotesByDate([item, ...timeNotes]), 'timeNotes', module[G.STATE])

      // Needed to update time summary
      await actionWithHooks(module[G.STATE][G.ACTION])([])
      await module[G.ADAPTER][G.UI].create(module)
    })
  } catch (e) {
    console.error(e)

    await module[G.ADAPTER][G.UI].update(module)
  }
})

export default curry(add)
