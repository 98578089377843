import { curry } from 'lib/util'

/**
 * Get the {@code breadcrumbs} for a given {@code node}.
 *
 * @param {Gaia.AppModule.Spec} module              the current module composition object
 * @param {Gaia.Component.Spec} component              the current action's main component
 * @param {PlatformEvent|Object} eventOrOptions     the event object that triggered this handler
 * @param {Object} [eventOrOptions.node]            the current node
 * @param {String[]} [eventOrOptions.breadcrumbs]   the id of the note to find.
 * @param {Object} [eventOrOptions.detail]        additional data transferred on the event
 * @returns {*[]}
 */
const getBreadcrumbs = (module, component, eventOrOptions) => {
  const { node, breadcrumbs = [] } = eventOrOptions.detail || eventOrOptions

  if (!node) return []

  breadcrumbs.unshift(node)
  node.parent && getBreadcrumbs(module, component, { node: node.parent, breadcrumbs })

  return breadcrumbs
}
export default curry(getBreadcrumbs)
