import notEmptyAttribute from '@model/_shared/validator/notEmptyAttribute'

export const validators = {
  docHelpful: notEmptyAttribute,
}

export {
  validators as default,
  notEmptyAttribute,
}
