/* eslint-disable object-curly-newline,no-undefined */
/* eslint-disable no-unused-expressions,object-curly-newline */
/* global G */
import { getFirstItem } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import find from 'lib/sequence/component/children/find'
import { get, set } from 'lib/sequence/component/state/value'
import session from 'app/_shared/session'
import swapListSerialAndName from 'app/_shared/action/partial/swapListSerialAndName'
import listTicketFilterStatus from 'app/_shared/events/collection/listTicketFilterStatus'

/**
 * Returns the minimum date value according to the selected option.
 *
 * @param {string} value        the selected option's value
 * @returns {{minDate: string}} an object containing the minimum date
 */
const toDateString = (value) => {
  const date = new Date()

  switch (value) {
    // case '5m':
    //   date.setMinutes(date.getMinutes() - 5)
    //   break
    case '1h':
      date.setHours(date.getHours() - 1)
      break
    case '24h':
      date.setDate(date.getDate() - 1) // today ???
      break
    case '48h':
      date.setDate(date.getDate() - 2) // yesterday
      break
    case '7d':
      date.setDate(date.getDate() - 7)
      break
    default:
    // current timestamp
  }
  return { minDate: date.toISOString() }
}

/**
 * Returns the min / max filter.
 *
 * @param {number} min  the minimum value
 * @param {number} max  the maximum value
 * @returns {object}
 */
const toMinMax = ({ min, max }) => {
  const result = {}
  min && Object.assign(result, { min })
  max && Object.assign(result, { max })
  return result
}

/**
 * Returns the ref of {@code item}, if it has one.
 *
 * @param {object} item     the item to return the ref from
 * @returns {*|undefined}   either the ref of the item or undefined
 */
const ref = item => item?.[0]?.key || undefined

/**
 * Returns either the key of {@param item} or the values that should correspond to the currently
 * selected static filters.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @param {object} item     the item to return the ref from
 * @returns {*|undefined}   either the ref of the item or undefined
 */
const filterRef = (module, item) => {
  const key = ref(item)
  const meKey = key === 'me' ? session(module).user.key() : key
  const noneMeKey = key === 'none' ? [] : meKey
  return !key || key === 'any' ? undefined : noneMeKey
}

/**
 * Contact editing and creation.
 *
 * Toggles the visibility and mapping of fields depending on whether the contact should just be
 * saved for a ticket or as an individual entity in the database and whether there is already an
 * entity selected.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const { filter: filterSection, list } = asObject(component[G.CHILDREN])
  const { team, assignee, status, dateCreated } = asObject(filterSection[G.CHILDREN])

  await swapListSerialAndName(module)(find(component), ...args)
  const meta = list[G.STATE][G.META] || {}
  const filter = {}

  // status filter
  const selectedStatus = parseInt(get(status), 10)
  const statusSelection = listTicketFilterStatus(module, component, null)
  const statusOption = statusSelection.find(option => option.key === selectedStatus)
  statusOption?.key && (filter.workState = statusOption.key)

  // team filter
  const selectedTeam = get(team)
  const selectedAssignee = get(assignee)
  const teamFilter = filterRef(module, selectedTeam)

  filter.team = teamFilter

  // assigned filter enable/disable
  assignee[G.STATE][G.REF] = teamFilter && 'team'
  model[G.CHILDREN].team[G.STATE][G.REF] = teamFilter

  !get(assignee) && set(assignee, [])

  // assignee filter
  const noAssignee = getFirstItem(selectedAssignee)?.key === 'none'
  noAssignee
    ? filter.refexists = '!assignee'
    : filter.assignee = filterRef(module, selectedAssignee)

  // created date filter
  const created = get(dateCreated)
  created && created !== '0' && (filter.submit_timestamp = toDateString(created))
  // Reset pagination
  list[G.STATE][G.NEXT] = undefined
  list[G.STATE][G.META] = { ...meta, filter }
  // temporary solution for suspended memoized list component
  // recreate ui, instead of update ui
  await module[G.ADAPTER][G.UI].create(module)
  return args
}
