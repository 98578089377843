/* global G */
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import { canHookInto } from 'trait/can'
import appModuleComposition from 'trait/composition/appmodule'
import actions from './actions'
import events from './events'
import hooks from './hooks'

const { ACTIONS, EVENTS } = G

const descriptor = 'module::textTemplate'

/**
 * TextTemplate Module
 *
 * @param {Gaia.AppModule.Spec} obj - configuration object
 * @return {Gaia.AppModule.Spec} obj - object composition
 */
const composition = pipe(
  appModuleComposition(descriptor), // basic module composition
  usesGetterSetter(ACTIONS, actions), // adds action object as G.ACTIONS namespace's value
  usesGetterSetter(EVENTS, events), // adds events object as G.EVENTS namespace's value
  canHookInto(ACTIONS, hooks), // binds hooks object to action
)

export default composition
