import noop from '@gaia/util/noop/action'
import messageAttachments from 'app/_shared/action/messageAttachments'
import index from 'app/request/action/index'
import showAttachments from 'app/_shared/action/showAttachments'
import created from 'app/request/action/created'
import create from 'app/request/action/create'
import bulk from 'app/request/action/bulk'
import detail from 'app/request/action/detail'
import description from 'app/request/action/detail/description'

export default {
  index,
  create,
  location: noop,
  bulk,
  created,
  detail,
  description,
  attachments: noop,
  messageAttachments,
  reopenRequest: noop,
  showAttachments,
}
