/* eslint-disable prefer-destructuring */
/* global G */
import find from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { checked } from 'lib/sequence/component/state/checked'
import { disable, enable } from 'lib/sequence/component/state/disabled'

/**
 * Returns the first user related to {@param person}
 *
 * @param {Gaia.Model} person the person from which to obtain its first related user
 * @returns {Promise<object|null>}
 */
const getPersonUser = async (person) => {
  const { version } = person[G.PROPS]
  const key = person[G.STATE][G.REF]
  const url = `/api/v${version}/person/${key}/listing/user/short`
  try {
    const response = await person[G.ADAPTER][G.HTTP].get({ url })
    return response.value[0]
  } catch (ignored) {}
  return null
}

/**
 * Checks whether the currently introduced email corresponds to the user of the person being edited.
 * If it is, disables the accountExists validator, otherwise enables it.
 *
 * @param modelName
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
const toggleEmailValidator = (modelName = null) => obj => async (...args) => {
  const model = modelName ? obj[G.MODEL][G.CHILDREN][modelName] : obj[G.MODEL]
  const components = find(obj[G.STATE][G.ACTION][G.COMPONENT])
  const { store } = components
  const { contactChannels } = model[G.CHILDREN]
  const validator = contactChannels[G.VALIDATOR].email
  const storeChecked = store ? checked(store) : true

  if (storeChecked) {
    const { email } = components

    const newEmail = get(email)
    try {
      const user = await getPersonUser(model)
      const userIdEmail = user?.key?.split(':')[1] || null
      // If the inserted email belongs to the person's user we disable the validator.
      disable(validator, newEmail === userIdEmail)
    } catch (ignored) {
      enable(validator)
    }
  } else {
    disable(validator)
  }

  return args
}

export default toggleEmailValidator
