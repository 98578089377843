/* global G */
import ui from 'lib/hook/ui/update'
import validate from 'lib/hook/model/validate'
import read from 'lib/hook/model/read'
import submit from 'lib/hook/model/submit'
import clear from 'app/_shared/hook/model/clear'
import search from 'app/_shared/hook/appbar/search'
import create from 'app/_shared/hook/appbar/create'
import reset from 'app/_shared/hook/component/reset'
import confirm from 'lib/hook/router/confirm'
import check from 'app/_shared/hook/check'
import back from 'app/_shared/hook/redirect/back'
import { confirmation, end } from 'lib/hook/transaction'
import recall from 'app/_shared/hook/component/recall'
import cacheTitle from 'app/_shared/hook/model/cacheTitle'
import updateCache from 'app/_shared/hook/model/updateCache'

const _newHooks = {
  before: [
    reset,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _editHooks = {
  before: [
    clear,
    reset,
  ],
  after: [
    read,
    ui,
    confirm,
  ],
}

const _baseHooks = {
  after: [
    ui,
  ],
}

const _listHooks = {
  // before: [
  //   // search,
  //   // create,
  // ],
  after: [
    create,
    search,
    recall,
    ui,
  ],
}

const _detailHooks = {
  before: [
    clear,
    read,
    cacheTitle('name'),
    recall,
  ],
  after: [
    ui,
  ],
}

const _submitHooks = {
  before: [
    validate,
  ],
  after: [
    ui,
    check,
    submit,
    updateCache,
    // confirmation('success'),
    back,
    end,
  ],
}

const _submitContactHooks = {
  before: [
    validate,
  ],
  after: [
    ui,
    check,
    /**
     * We perform submit inside action logic because
     * we don't work with team model here.
     */
    back,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _listHooks,
    new: _newHooks,
    edit: _editHooks,
    detail: _detailHooks,
    submit: _submitHooks,
    remove: _baseHooks,
    inviteContact: _newHooks,
    submitContact: _submitContactHooks,
    persons: _baseHooks,
  },
}

export default hooks
