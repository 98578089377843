/* eslint-disable no-return-assign */
/* global G, PLATFORM */
import { hide, show } from 'lib/sequence/component/state/hidden'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { setSessionStateData } from 'app/guest/action'

/**
 * Assistance Action.
 *
 * the action reacts specifically to possible model errors
 * based on the error, it will toggle elements
 *
 * case 1: coming from login screen
 *  401 -> redirect to different action
 *  403 -> show create new account(btn 1)
 *  200 -> authenticationType = email -> btnResend visible
 *  200 -> authenticationType = pwd -> btnReset visible
 * case 2: coming from pwd screen
 *  tbd
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @return {function(Gaia.Component): function(...[*]): *[]}
 */
const action = module => component => (...args) => {
  const { username, error, passwordGenerated } = args[0]
  const errorRef = error?.[G.REF]
  const userError = errorRef?.username
  username && setSessionStateData(module, { username, error, passwordGenerated })
  const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]

  const { btnRegister, btnReset, btnLoginLink } = sequenceComponentFindProxy(component)

  hide(btnRegister)
  hide(btnLoginLink)
  hide(btnReset)

  if (passwordGenerated || sessionState.passwordGenerated) {
    show(btnLoginLink)

    return args
  }

  if (username) {
    userError === 404 && PLATFORM !== 'mobile' && show(btnRegister)
    userError !== 404 && show(btnReset)
  }

  return args
}

export default action
