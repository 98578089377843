import find from 'lib/sequence/component/children/find'
import { check } from 'lib/sequence/component/state/checked'
import { set } from 'lib/sequence/component/state/value'
import { hide } from 'lib/sequence/component/state/hidden'

/**
 * Initializes the organisation type field.
 *
 * @param {object} components the find component sequence initialized with the action's component
 * @param {object[]} args     the action's arguments
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const initOrganisationType = async (components, ...args) => {
  const { organisationType } = components
  const { type: organisationTypeField } = find(organisationType)

  check(organisationTypeField)
  && set(organisationTypeField, 'customer')
  && hide(organisationType)

  return [components, ...args]
}

export default initOrganisationType
