/**
 * Organisation Types Collection.
 *
 * Contains static list of available ticket's message types, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 */
export default [
  {
    key: 'customer',
    value: 'Customer',
    icon: 'factory',
  },
  {
    key: 'independent_contractor',
    value: 'Independent contractor',
    icon: 'home_repair_service',
  },
  {
    key: 'internal',
    value: 'Internal',
    icon: 'business',
  },
  {
    key: 'partner',
    value: 'Service partner',
    icon: 'handshake',
  },
  {
    key: 'third_party',
    value: 'Third party',
    icon: 'maps_home_work',
  },
  {
    key: 'fourth_party',
    value: 'Fourth party',
    icon: 'maps_home_work',
  },
  {
    key: 'fifth_party',
    value: 'Fifth party',
    icon: 'maps_home_work',
  },
  {
    key: 'sixth_party',
    value: 'Sixth party',
    icon: 'maps_home_work',
  },
  {
    key: 'seventh_party',
    value: 'Seventh party',
    icon: 'maps_home_work',
  },
  {
    key: 'eigth_party',
    value: 'Eigth party',
    icon: 'maps_home_work',
  },
  {
    key: 'ninth_party',
    value: 'Ninth party',
    icon: 'maps_home_work',
  },
  {
    key: 'tenth_party',
    value: 'Tenth party',
    icon: 'maps_home_work',
  },
]
