/* global G */
import ui from 'lib/hook/ui/update'
import validate from 'lib/hook/model/validate'
import submit from 'lib/hook/model/submit'
import redirect from 'lib/hook/router/redirect'
import confirm from 'lib/hook/router/confirm'
import read from 'lib/hook/model/read'
import search from 'app/_shared/hook/appbar/search'
import create from 'app/_shared/hook/appbar/create'
import clear from 'app/_shared/hook/model/clear'
import reset from 'app/_shared/hook/component/reset'
import asObject from 'lib/sequence/component/children/asObject'
import { set } from 'lib/sequence/component/state/value'
import stepper from 'app/_shared/hook/stepper'
import { setKey } from 'lib/util'
import bulk from 'lib/hook/model/bulk'
import mapAttribute from 'app/_shared/hook/model/mapAttribute'
import check from 'app/_shared/hook/check'
import { presetLocation } from 'app/serviceItem/action/create'
import find from 'lib/sequence/component/children/find'
import { begin, confirmation, end } from 'lib/hook/transaction'
import back from 'app/_shared/hook/redirect/back'
import recall from 'app/_shared/hook/component/recall'
import cacheTitle from 'app/_shared/hook/model/cacheTitle'
import updateCache from 'app/_shared/hook/model/updateCache'

const _baseHooks = {
  before: [],
  after: [
    ui,
  ],
}

const _optionsHooks = {
  before: [
    obj => (...args) => {
      /**
       * Normally this would be done in the action logic, but because this
       * is GT-specific better do it here to have to copy fewer files.
       * (We need this hooks.js anyway)
       */
      const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]
      const { equipmentOptions = [] } = obj[G.STATE]
      const { list } = asObject(actionComponent[G.CHILDREN])
      equipmentOptions.length && set(list, equipmentOptions.join('\n'))

      return args
    },
  ],
  after: [
    ui,
  ],
}

const _ownHooks = {
  after: [
    create,
    recall,
    ui,
  ],
}

const _searchAndCreateHooks = {
  before: [
    module => (...args) => {
      // TODO: probably we shouldn't need this here
      // clearing model data that may previously have been set by new action
      const model = module[G.MODEL]
      model[G.DATA].equipment = []
      model[G.DATA].product = []
      return args
    },
    create,
    search,
    recall,
  ],
  after: [
    ui,
  ],
}

const _createHooks = {
  before: [
    // Clears the module error, so that we can still use redirect to navigate the drawer actions.
    // Should be replaced by using force redirect
    obj => async (...args) => {
      setKey(null, G.ERROR, obj[G.STATE])
      return args
    },
    obj => async (...args) => {
      const model = obj[G.MODEL]
      const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]
      model[G.STATE].installedAt && await presetLocation(obj)(actionComponent)(
        find(actionComponent),
        { organisation: model[G.STATE].installedAt },
      )
      return args
    },
  ],
  after: [
    stepper,
    ui,
    confirm,
  ],
}

const _formHooks = {
  before: [
    reset,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _locationHooks = {
  before: [
    mapAttribute('installedAt'),
  ],
  after: [
    ui,
    confirm,
  ],
}

const _editHooks = {
  before: [
    clear,
    reset,
    read,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _detailHooks = {
  before: [
    clear,
    read,
    cacheTitle('name', 'serial'),
    recall,
  ],
  after: [
    ui,
  ],
}

const _submitHooks = {
  before: [
    obj => (...args) => {
      const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]
      const { serialNumber } = asObject(actionComponent[G.CHILDREN])
      const { error } = serialNumber[G.STATE]
      setKey(error, G.ERROR, obj[G.STATE])
      return args
    },
    validate,
  ],
  after: [
    ui,
    check,
    begin,
    submit,
    updateCache,
    // confirmation('success'),
    back,
    end,
  ],
}

const _bulkHooks = {
  before: [
    validate,
  ],
  after: [
    ui,
    bulk,
    redirect,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _searchAndCreateHooks,
    own: _ownHooks,
    new: _formHooks,
    create: _createHooks,
    detail: _detailHooks,
    tickets: _baseHooks,
    location: _locationHooks,
    persons: _baseHooks,
    productDocumentation: _baseHooks,
    equipmentDocumentation: _baseHooks,
    equipmentOptions: _optionsHooks,
    edit: _editHooks,
    requests: _formHooks,
    submit: _submitHooks,
    bulk: _bulkHooks,
  },
}

export default hooks
