/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import serial from 'model/serviceItem/collection/serial'

/**
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Gaia.PlatformEvent|string} eventOrKey
 * @return {Array} countries
 */
const listSerialFieldOptions = (module, component, eventOrKey) => serial?.map(item => ({
  ...item,
  label: item.label ? module[G.ADAPTER][G.INTL]._t(
    `serialField.${item.key}.label`,
    {
      ns: 'custom',
      _key: `serialField.${item.key}.label`,
      defaultValue: item.label,
    },
  ) : '',
  helperText: item.helperText ? module[G.ADAPTER][G.INTL]._t(
    `serialField.${item.key}.helperText`,
    {
      ns: 'custom',
      _key: `serialField.${item.key}.helperText`,
      defaultValue: item.helperText,
    },
  ) : '',
}))
export default curry(listSerialFieldOptions)
