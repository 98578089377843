/* global G */
/* eslint-disable object-curly-newline, no-alert, no-restricted-globals,no-unused-vars */
import { curry, PlatformEvent } from 'lib/util'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import routeComposition from 'trait/composition/route'
import { platform } from 'app/_shared/events/util'
import chain from 'app/_shared/events/util/chain'
import clean from 'app/_shared/events/util/clean'
import {
  back,
  persistChange,
  persistOptions,
  redirect,
  submit,
} from '@app/_shared/events'
import { add, apply, get, remove, undo } from 'app/_shared/events/attachment'
import list from 'app/_shared/events/list'
import listLanguages from 'app/_shared/events/collection/listLanguages'
import action from 'app/_shared/events/action'
import menu from 'app/_shared/events/contextmenu'
import link from 'app/_shared/events/link'
import permission from 'app/_shared/events/acl/permission'
import acl from 'app/_shared/events/acl/component'
import session from 'app/_shared/session'
import checkEmail from 'app/user/event/checkEmail'
import closeDialog from 'app/user/event/closeDialog'
import redirectLocalUser from 'app/user/event/redirectLocalUser'
import reset from 'app/_shared/events/model/reset'
import byKey from 'app/_shared/events/action/byKey'

export default {
  acl: {
    acl,
    permission,
  },
  onOpen: {
    list: list('verbose'),
    listWithRefs: list(null),
    listLanguages,
    listTeams: curry(async (module, component, event) => session(module).team.ref({ wrap: true })),
    link,
  },
  onClose: {
    redirect,
    back,
    backAndUndo: chain(
      back,
      undo,
    ),
    closeDialog,
  },
  onClick: {
    noop: curry((m, c, e) => {}),
    submit,
    submitLanguage: byKey('submitLanguage'),
    checkEmailAndSubmit: chain(
      checkEmail,
      submit,
    ),
    back,
    redirect,
    provideAndRedirect: chain(
      curry(async (module, component, event) => {
        await module[G.ADAPTER][G.UI].provide(module)
      }),
      redirect,
    ),
    remove,
    applyAndBack: chain(
      apply,
      reset(null),
      clean,
      back,
    ),
    backAndUndo: chain(
      back,
      undo,
    ),
    closeDialog,
    redirectLocalUser,
    editAvatar: platform({
      web: redirect,
      mobile: curry((module, component, event) => {
        const eventBus = module[G.ADAPTER][G.EVENTS]
        const addCallback = async (files) => {
          await add(module, component, new PlatformEvent('upload', { files }))
          await apply(module, component, new PlatformEvent('upload'))
          await action(module, null, null)
        }
        const deleteCallback = async (file) => {
          const attachments = await get(module, component, null)
          const [attachment] = attachments || []
          if (attachment) {
            await remove(module, component, 'profile', attachment, null)
            await apply(module, component, new PlatformEvent('upload'))
            await action(module, null, null)
          }
        }
        eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.MENU, 'profile'), {
          addCallback, deleteCallback,
        })
      }),
    }),
  },
  onChange: {
    persistChange,
    persistOptions,
    add,
  },
  onContextMenu: {
    menu: menu(null),
  },
  onSecondaryAction: {
    confirmRemoval: curry(async (module, component, event) => {
      const moduleState = module[G.STATE]
      moduleState[G.ROUTE] = routeComposition(null, 'confirmRemove')
      await redirectSequence(module)({})
    }),
    redirect,
  },
  getSelection: {
    listLanguages,
  },
}
