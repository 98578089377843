/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Step Jump Event Handler
 *
 * Sets the passed index parameter as the current value for the activeStep property of the stepper
 * used as child of the current action component, then calls the current module's action.
 *
 * @param {Gaia.AppModule.Spec} module              the current module composition object
 * @param {Gaia.Component.Spec} component              the current action's main component
 * @param {Gaia.PlatformEvent|number} eventOrIndex  the event object or a step index
 * @param {object} [eventOrIndex.detail]            platform event details
 * @param {number} [eventOrIndex.detail.index]      a step index
 * @return {Promise<void>}
 */
export default curry(async (module, component, eventOrIndex) => {
  try {
    const { index, skipped } = eventOrIndex.detail || eventOrIndex || {}
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { stepper } = asObject(actionComponent[G.CHILDREN])
    const stepperState = stepper[G.STATE]
    const currentStep = stepperState.activeStep
    const steps = stepper[G.CHILDREN]

    if (skipped) {
      const skippedSteps = []
      for (let i = currentStep; i < index; i++) steps[i][G.STATE].skipped = true
      stepperState.skippedSteps = skippedSteps
    }

    stepperState.activeStep = index
    // call same action, see side effects
    await actionWithHooks(module[G.STATE][G.ACTION])([])
  } catch (e) {
    console.error(e)
  }
})
