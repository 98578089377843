export default [
  {
    key: 'currentTeam',
    value: 'Current team',
  },
  {
    key: 'previousTeam',
    value: 'Previous team',
  },
]
