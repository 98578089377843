/* global G */
import formatValue from 'app/_shared/component/formatValue'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

/**
 * Attempts to send the user a new email with a login link
 * and displays a dialog.
 *
 * @param {Gaia.AppModule.Spec} module - application module
 * @returns {function}    a function expecting the index component
 */
const action = module => component => async (...args) => {
  const model = module[G.MODEL]
  const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]

  const { version } = model[G.PROPS]
  const username = model[G.DATA].username || sessionState.username

  /**
   * Replacing user email in translation string.
   */
  const { msgConfirmMD } = sequenceComponentFindProxy(component)
  await formatValue(module, msgConfirmMD, { account: username }, 'text')

  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const tokenUrl = `/api/v${version}/public/gettoken/${username}`

  try {
    await httpAdapter.get({ url: tokenUrl })
  } catch (e) {
    console.error(e)
  }

  return args
}

export default action
