/* eslint-disable no-unused-expressions */
/* global G */
// noinspection BadExpressionStatementJS

import userRoles from 'model/account/collection/roles'

import { isArr } from 'lib/util'

/**
 * Model Roles Validator
 *
 * Makes sure user has at least one user role (this means excluding system roles)
 *
 * @param obj - model composition
 * @return {function(...[*]): *[]}
 */
const fn = obj => (uiState, key) => {
  const { value } = uiState
  const { options } = obj[G.CONFIGURATION].validator[key]
  const { error } = options

  const minLengthError = new TypeError(error.minLength)
  minLengthError.code = 'minLength'

  const incompleteError = new TypeError(error.incomplete)
  incompleteError.code = 'incomplete'

  // In case of create action, if the value is undefined, it means we haven't added a role at all
  if (!value) throw minLengthError

  // If it's still an array, it means we haven't modified it at all
  if (isArr(value)) return [uiState, key]

  const availableRoles = userRoles.map(role => role.key)
  const roles = Object.keys(value)
    .filter(x => value[x]?.atOrg && !value[x]?.delete)
    .map(role => value[role]?.role)

  // Making sure that the new role has both role and atOrg set
  if (value?.new && (!value.new.role || !value.new.atOrg)) throw incompleteError

  /**
   * We need to have at least one role (excluding system roles) for each user. This means the
   * intersection of {@link roles} and {@link availableRoles} needs to be at least one.
   */
  const intersection = availableRoles.filter(x => roles.includes(x));

  (!uiState.value
      || (isArr(uiState.value) && !uiState.value.length)
      || !intersection.length
  ) && throw minLengthError

  return [uiState, key]
}

export default fn
