/* global G */
import { modelComposition } from 'trait/composition'
import { usesGetterSetter } from 'trait/uses'
import validator from 'model/feedback/validator/generic'
import { pipe } from 'lib/util'
import { withGetterSetter } from 'trait/with'
import toBoolean from 'model/_shared/transformer/toBoolean'
import toData from 'model/_shared/transformer/toData'
import toRef from 'model/_shared/transformer/toRef'

const descriptor = 'model::feedback::generic'

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    consentContact: toBoolean('consentContact'),
    statisticalData: toData('statisticalData'),
    submitter: toRef('submitter'),
  }),
)
