/* global G */
import { curry } from '@gaia/util'

/**
 * refresh current data set
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - the event that triggered this handler
 * @param {object} event.detail - custom event details
 * @param {string} event.detail.key - a model key to use for the count api call
 * @return {Promise<{value: {error}}>} void
 */
const item = async (module, component, event) => {
  const { key } = event.detail
  const obj = module[G.MODEL]
  const { api, version, read = 'verbose' } = obj[G.PROPS]
  const url = `/api/v${version}/${api}/${key}${read ? `/${read}` : ''}`
  const params = {
    edit: true,
  }
  try {
    const payload = await obj[G.ADAPTER][G.HTTP].get({ url, params })
    if (payload.error) {
      throw Error(payload)
    }
    return payload
  } catch (e) {
    return { value: { error: e.message } }
  }
}

export default curry(item)
