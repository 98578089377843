/* eslint-disable no-unused-expressions */
/* global G */

/**
 * Additional information step action.
 *
 * Handles the display and status of the step's elements.
 *
 * Allows additional information regarding the new service item to be introduced.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const { installedAt } = model[G.CHILDREN]
  const stepState = component[G.STATE]

  stepState.completed = !!(model[G.CACHE] && installedAt[G.CACHE])
  stepState.confirmed = stepState.completed

  return args
}
