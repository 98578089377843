/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import format from 'lib/util/string'
import { settings } from 'app/_shared/session'

/**
 * Requests the router to open a new tab to the value of {@code settings.externalPortalUrl}.
 *
 * @type {Gaia.AppModule.EventHandler}.
 */
export default curry(async (module, component, event) => {
  const lang = module[G.ADAPTER][G.SESSION][G.STATE][G.LANGUAGE]
  const baseUrl = settings.externalPortalUrl
  if (baseUrl) {
    const url = format(baseUrl, { lang })
    module[G.ADAPTER][G.ROUTER].newTab(url)
  } else {
    console.warn('No externalPortalUrl setting set')
  }
})
