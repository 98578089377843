/* global G */
import { curry } from 'lib/util'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import dynamicRedirect from 'app/_shared/events/redirect/dynamic'

/**
 * Destroy Event Handler
 *
 * Shows a confirmation dialog using {@link showBlockingDialog} to the user and (if accepted)
 * attempts to delete the current model alongside possible {@param subModels} from the server.
 *
 * Executes {@link redirect} afterward, so the components needs the proper configuration for it.
 * After deleting the model, we should redirect to the {@code index} action of the current module
 * since there is no document anymore to show in the details view.
 *
 * @param {string[]} subModels              list of sub models to delete as well
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {PlatformEvent} event             event
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const destroy = async (subModels, module, component, event) => {
  const model = module[G.MODEL] || {}
  const { model: type } = model[G.CONFIGURATION]

  const confirmed = await showBlockingDialog(module, component, {
    title: {
      key: 'dialog.delete.title',
      defaultValue: 'Confirm deletion',
    },
    text: {
      key: 'dialog.delete.text',
      defaultValue: 'Are you sure that you want to delete {{type}}?',
      type: `$t(dialog.delete.types.${type.split('/')[0]})`,
    },
  })

  try {
    if (confirmed) {
      const { api, version } = model[G.PROPS]

      for (let i = 0; i < subModels.length; i++) {
        const subModelName = subModels[i]
        const { [subModelName]: subModel = {} } = model[G.CHILDREN]
        const subModelKey = subModel?.[G.STATE]?.[G.REF] || null

        const {
          api: subModelApi = null,
          version: subModelVersion = null,
        } = subModel?.[G.PROPS] || {}

        const subModelUrl = `/api/v${subModelVersion}/${subModelApi}/${subModelKey}`

        subModelApi
          && subModelKey
          && await subModel[G.ADAPTER][G.HTTP].delete({ url: subModelUrl })
      }

      const key = model[G.STATE][G.REF]
      const url = `/api/v${version}/${api}/${key}`

      key && api && await model[G.ADAPTER][G.HTTP].delete({ url })

      await dynamicRedirect('destroy', module, component, event)
    }
  } catch (e) {
    console.error(e)
  }
}

export const destroyWith = curry(destroy)

export default curry((module, component, event) => destroy([], module, component, event))
