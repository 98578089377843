/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { checked } from 'lib/sequence/component/state/checked'
import { setData } from 'lib/sequence/model/api/set'

/**
 * Sets the role on the invite model to {@param role}.
 *
 * If {@model} is set, it will be used as a parent model of invite.
 * E.g.: {@code module[G.MODEL][G.CHILDREN][model]}.
 * If not set, {@code module[G.MODEL]} will be used.
 *
 * @param {String} [model]              optional model to set the role on
 * @param {String} role                 invitee role to set
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<void>}
 */
const setRole = async (model, role, module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const toBeInvited = sequenceComponentFind(actionComponent[G.ACTIONS][0]).toBeInvited
    || sequenceComponentFind(actionComponent).toBeInvited

  const targetModel = model
    ? module[G.MODEL][G.CHILDREN][model]
    : module[G.MODEL]

  /**
   * If we happen to invite a colleague, their role will be set server-side.
   */
  module[G.STATE][G.ACTION]._name !== 'newColleague'
    && checked(toBeInvited)
    && setData(targetModel, { invite: { role } })
}

export default curry(setRole)
