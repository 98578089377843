import { curry, getFirstItem } from 'lib/util'

/**
 * Calls {@param handler} (usually a redirect handler to {@code detail} or {@code edit}) with the
 * chosen {@param ref} within the incoming {@param event}.
 *
 * @param {Function} handler            event handler to execute with the new ref
 * @param {string} ref                  the ref to send to the next handler in the event
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<*>}
 */
const withRef = async (handler, ref, module, component, event) => {
  const item = event?.detail?.item || event || {}
  const target = getFirstItem(item?.refs?.[ref])

  await handler?.(module, component, { ...event, detail: { ...target, key: target.key } })
}

export default curry(withRef)
