/* eslint-disable no-plusplus,no-param-reassign */
import { curry } from 'lib/util'

/**
 * Filters item out if its key cannot be found in {@param keys}
 *
 * @param {string[]} keys     a list of keys to filter items with
 * @returns {function(*): *}
 */
const filterByKeys = keys => item => keys.includes(item.key)

/**
 * Sorts a and b depending on whether their key is equal to the first key in {@param keys}.
 *
 * @param {string[]} keys             a list of keys from which to order a and b
 * @param {number} [index=0]          the index used to obtain the current key
 * @returns {function(*, *): number}
 */
const sortByKeys = (keys, index = 0) => (a, b) => {
  const key = keys[index++]
  const bKeyCheck = b.key === key ? 1 : 0
  return a.key === key ? -1 : bKeyCheck
}

/**
 * Returns a sublist of {@param list} that contains all elements whose key is found in
 * {@param keys}. The order of the returned elements is defined also by the order of {@param keys}.
 *
 * @param {string[]|number[]} keys  an  array containing the ids of the elements returned from
 *                                  {@param list} to be returned
 * @param {function} list           a function that returns a list of elements
 * @param {boolean} [includeBlank]  whether to add an empty option as the first element
 * @returns {*}
 */
const sublist = (keys, list, includeBlank) => curry((module, component, event) => {
  const items = list(module, component, event)
  const blankOption = includeBlank ? [{ key: '' }] : []
  const filteredItems = !keys ? items : items.filter(filterByKeys(keys)).sort(sortByKeys(keys))

  return blankOption.concat(filteredItems)
})

export default sublist
