/* global G */
import { curry } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import sequenceComponentState from 'lib/sequence/component/state'

const {
  set: enableTorch,
  get: torchEnabled,
} = sequenceComponentState('torch')

const {
  set: activate,
} = sequenceComponentState('active')

/**
 * Toggles the camera's flashlight. It does so by toggling the {@code torch} prop of the first
 * component with key 'camera' found in the component tree. It also toggles the {@code active} prop
 * of the component that triggered the event handler.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             information about the event that triggered this handler
 * @return {Promise<void>} void
 */
const toggleFlashlight = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { camera } = find(actionComponent)
  const isTorchEnabled = torchEnabled(camera)
  enableTorch(camera, !isTorchEnabled)
  activate(component, !isTorchEnabled)
  module[G.ADAPTER][G.UI].update(module)
}

export default curry(toggleFlashlight)
