/**
 * Organisation Types Collection.
 *
 * Contains static list of available ticket's message types, which can be used for different
 * purposes, like filling lists, allowing options selection, etc.
 */
export default [
  {
    key: 'customer',
    value: 'Customer',
    icon: 'factory',
  },
  {
    key: 'independent_contractor',
    value: 'Independent contractor',
    icon: 'home_repair_service',
  },
  {
    key: 'internal',
    value: 'Internal',
    icon: 'business',
  },
  {
    key: 'partner',
    value: 'Service partner',
    icon: 'handshake',
  },
  {
    key: 'third_party',
    value: 'Third party',
    icon: 'maps_home_work',
  },
]
