/* global G */
import find from 'lib/sequence/component/children/find'

/**
 * Checks whether all required steps have completed state and sets them all to visited, so that
 * an error is shown for each required one that is not completed.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {boolean}               {@code true} if all required steps are completed
 */
export default (module) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = find(actionComponent)

  return !stepper[G.CHILDREN] || stepper[G.CHILDREN].reduce((acc, step) => {
    const stepProps = step[G.PROPS]
    const stepState = step[G.STATE]
    const { required, hidden } = { ...stepProps, ...stepState }
    const { completed, confirmed } = stepState
    stepState.visited = true
    // We consider valid non-required steps that are both not completed and not confirmed and also
    // any steps (also non-required ones) that are both completed and confirmed; hidden steps are
    // always valid
    const valid = (!required && (!completed && !confirmed)) || (completed && confirmed)
    return acc && (hidden || valid)
  }, true)
}
