/* global G */
import { curry } from '@gaia/util'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * FilteredOption Getter
 *
 * Returns the {@code key} of the chosen filter.
 * Should be called like so
 *
 * @example
 * list: {
 *   sub: filteredOption('team'),
 * }
 *
 */
// eslint-disable-next-line no-unused-vars
export default curry((chosenFilter, module, _) => () => {
  const { filter: availableFilters } = asObject(module[G.STATE][G.ACTION][G.COMPONENT][G.CHILDREN])
  const { [chosenFilter]: selectedFilter } = asObject(availableFilters[G.CHILDREN])

  return selectedFilter?.[G.STATE]?.value?.[0]?.key
})
