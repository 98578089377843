/* global G */
import { modelComposition } from '@gaia/trait/composition'
import { pipe } from 'lib/util'
import { usesGetterSetter } from '@gaia/trait/uses'
import { withGetterSetter } from '@gaia/trait/with'
import drop from 'model/_shared/transformer/drop'
import toRef from 'model/_shared/transformer/toRef'
import validator from './validator'

const descriptor = 'model::registration'

const Model = pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    termsOfUse: drop('termsOfUse'),
    privacyPolicy: drop('privacyPolicy'),
    person: toRef('person'),
    organisation: toRef('organisation'),
    request: drop('request'),
    attachments: drop('attachments'),
  }),
)

export default Model
