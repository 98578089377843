import { curry } from 'lib/util'

/**
 * To Invite Transformer
 *
 * turns
 * @example
 * [
 *  { value: {"role": 'requester' } },
 *  { value: {"...": '...' } },
 * ]
 *
 * into
 * @example
 * {
 *     "role": "requester",
 *     "...": "...."
 * }
 *
 * @param key
 * @param obj
 * @param data
 * @returns {*}
 */
const toInvite = (key, obj, data) => {
  const _data = data

  if (!_data.value[key]) return _data

  _data.value[key] = data.value[key].reduce((acc, item) => ({ ...acc, ...item.value }), {})

  /**
   * Set status upon first invitation attempt.
   */
  !_data.value[key]?.status && (_data.value[key].status = 'to_be_invited')

  return _data
}

export default curry(toInvite)
