/* global G */
import { persistChange } from 'app/_shared/events'
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { get } from 'lib/sequence/component/state/value'
import { hide } from 'lib/sequence/component/state/hidden'
import sequenceComponentState from 'lib/sequence/component/state'
import { actionWithHooks } from 'lib/sequence/module/action'

const {
  unset: clearError,
} = sequenceComponentState('error')

const {
  unset: clearHelperText,
} = sequenceComponentState('helperText')

const clear = component => clearError(component) && clearHelperText(component)

/**
 * Persist the value of the device registration's serial field and toggles the display of help
 * buttons.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @return {Promise<void>} void
 */
// eslint-disable-next-line no-unused-vars
const persistSerial = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const { currentStep } = stepper[G.STATE]
  const { [currentStep]: step } = asObject(stepper[G.CHILDREN])
  const { search: section } = asObject(step[G.CHILDREN])
  const { actions } = asObject(section[G.CHILDREN])
  const { form } = asObject(actions[G.CHILDREN])
  const { serial: serialField, options } = asObject(form[G.CHILDREN])
  const { btnNoSerial, existingHelp, btnMore } = asObject(options[G.CHILDREN])
  // persist and get serial field value
  persistChange(module, serialField, event)
  const serial = get(serialField)
  // hide button if current serial value is not empty
  hide(btnNoSerial, !!serial)
  hide(existingHelp)
  hide(btnMore)
  // clear serial field's error and helperText states
  clear(serialField)
  // update ui (refresh the view with the new components' states)
  await actionWithHooks(module[G.STATE][G.ACTION])([])
}

export default curry(persistSerial)
