import { curry, isArr } from 'lib/util'

/**
 * Aggregate Documentation Event Handler
 *
 * Iterates over the {@param event.detail.result} and groups entries with identical
 * {@code documentationId} together. It will not alter the order of the items.
 * It will remove all subsequent items with the same {@code documentationId} as the
 * currently looked at one, and will add the number of deleted entries as {@code more}
 * to the current one.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.AppModule.Action} component action component
 * @param {PlatformEvent} event             the event that triggered this handler
 * @returns {Promise<*|{data: *}>}
 */
const aggregateDocumentation = async (module, component, event) => {
  const { result } = event?.detail || event || {}
  const data = isArr(result) ? result : result?.data || []

  const aggregatedData = data.reduce((acc, key) => {
    // If the item has no {@code} documentationId, just skip it
    if (!key?.value?.documentationId) return [...acc, key]

    // if we already have an item with the same {@code documentationId} in the aggregated
    // results, it means we should delete this item from the list
    if (acc.find(x => x?.value?.documentationId === key?.value?.documentationId)) return acc

    // Search through
    const siblings = data.filter(x => x?.value?.documentationId === key?.value?.documentationId && x?.key !== key?.key)

    return siblings.length
      ? [...acc, { ...key, value: { ...key.value, more: siblings.length } }]
      : [...acc, key]
  }, [])

  return isArr(result)
    ? aggregatedData
    : { ...result, data: aggregatedData }
}

export default curry(aggregateDocumentation)
