/* global G */
import { curry } from 'lib/util'

const descriptor = 'model::decorator::fromSingleRef'

/**
 * key attribute should be a single ref model
 *
 * @param sourceKey
 * @param targetKey
 * @param obj
 * @param component
 * @return {*}
 */
const fn = (sourceKey, targetKey, obj, component) => {
  const source = obj[G.CHILDREN][sourceKey]
  const target = obj[G.CHILDREN][targetKey]
  const date = new Date(source[G.CACHE])
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  target[G.CACHE] = `${day}.${month}.${year}`

  return component
}

export default curry(fn)
