/* eslint-disable object-curly-newline */
import { persistCheck, persistChange, redirect, back, submit } from '@app/_shared/events'

export default {
  onClick: {
    submit,
    redirect,
    back,
  },
  onChange: {
    persistCheck,
    persistChange,
  },
}
