/* eslint-disable implicit-arrow-linebreak,indent,no-unused-expressions */
import { curry } from 'lib/util'

/**
 * Chain Util Event Handler.
 *
 * Consecutively calls all {@param eventHandlers} by passing to them all the arguments passed to the
 * returned function.
 *
 * @param {Gaia.AppModule.EventHandler} eventHandlers the event handlers to chain
 * @returns {Gaia.AppModule.EventHandler}
 */
export default (...eventHandlers) => curry(async (module, component, event) => {
  event?.persist?.()
  let result = null

  try {
    result = eventHandlers?.length
      // waiting for last handler
      ? await eventHandlers.reduce(async (acc, eventHandler) => {
        await acc // waiting for the previous promise to finish before the next handler is executed
        return eventHandler(module, component, event)
      }, null)
      : console.warn('ATTENTION!!! No event handlers set to chain event handler')
  } catch (error) { // throwing errors is the way to break the chain
    console.log('chain event handler broken: ', error)
  }
  return result
})
