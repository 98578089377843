/* eslint-disable no-unused-expressions,no-plusplus,prefer-destructuring */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import { hide } from 'lib/sequence/component/state/hidden'
import { CONFIRM, LIST } from 'app/_shared/events/setStepTab'
import { bulk } from 'lib/util'
import { reset, set } from 'lib/sequence/component/state/value'
import sequenceComponentState from 'lib/sequence/component/state'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { check } from 'lib/sequence/component/state/checked'
import session from 'app/_shared/session'
import getUserRole from 'app/_shared/events/user/role'
import initSerialField from 'app/_shared/action/partial/initSerialField'
import initSerialImage from 'app/_shared/action/partial/initSerialImage'

const {
  unset: clearError,
} = sequenceComponentState('error')

const {
  unset: clearHelperText,
} = sequenceComponentState('helperText')

const clear = component => clearError(component) && clearHelperText(component)
const bulkHide = bulk(hide)

/**
 * Device identification step action.
 *
 * Handles the display and status of the step's elements.
 *
 * It lets the user search a device or equipment by a serial number or select a product from a list.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const stepState = component[G.STATE]
  const components = sequenceComponentFind(component)

  const {
    btnBack,
    btnMore,
    existingHelp,
    productName,
    form,
    deviceInfo,
    addToMaintainedDevices,
  } = components

  const { serial: serialField } = asObject(form[G.CHILDREN])
  const { serial: serialText } = asObject(deviceInfo[G.CHILDREN])

  hide(btnBack)

  /**
   * Although we use aclContextRole in the module config, we need to check the user's role here. Because
   * the aclContextRole will only get evaluated when we render the UI, but we need to know their role
   * here already.
   */
  const { user } = session(module)
  const userRoles = user.ref()?.value?.roles || []
  const currentRole = getUserRole(userRoles, module, component, null)

  /**
   * If the user has a role that can maintain devices, and we visit this step for the first time,
   * let's auto check the field.
   */
  currentRole?.canMaintainDevices && !stepState?.visited
    && set(addToMaintainedDevices)
    && check(addToMaintainedDevices)

  if (model[G.CACHE]) {
    const { name, serial } = model[G.CACHE]?.value || {}
    set(serialText, serial)
    set(productName, name)
    stepState.title = name
    stepState.subtitle = serial
    stepState.completed = true
  } else {
    reset(serialText)
    reset(productName)
    stepState.title = ''
    stepState.subtitle = ''
    stepState.completed = false
    stepState.confirmed = false
  }

  // display sub-step according to step's mode
  switch (stepState.activeTab) {
    case LIST:
      bulkHide(existingHelp, btnMore)
      clear(serialField)
      break
    case CONFIRM:
      bulkHide(existingHelp, btnMore)
      clear(serialField)
      break
    default:
  }

  await initSerialField(module)(components, ...args)
  await initSerialImage(module)(components, ...args)

  return args
}
