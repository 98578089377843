/* global G */
import validate, { getMessageFromModelValidator } from 'lib/sequence/model/validate'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { setKey } from 'lib/util'
import routeComposition from 'trait/composition/route'
import validationResult from '../validateRedirect'

/**
 * Attempts to send a "password forgot" request according to the current model values.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {Promise<void>}             a pending promise object waiting for the request's result
 * @private
 */
const _submit = async (module) => {
  const moduleState = module[G.STATE]
  const model = module[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const { version, api } = model[G.PROPS]
  const { username } = model[G.DATA]
  const url = `/api/v${version}/${api}/password/forgotten/${username}`
  try {
    const result = await httpAdapter.put({ url })

    if (result.status === 'inactive') {
      // eslint-disable-next-line no-throw-literal
      throw { code: 403 }
    }
  } catch (error) {
    const { username: field } = sequenceComponentFindProxy(moduleState[G.ACTION][G.COMPONENT])
    const fieldState = field[G.STATE]

    const { code } = error
    fieldState.error = true
    moduleState[G.ERROR] = true
    fieldState.helperText = await getMessageFromModelValidator(model, 'username', code)
  }
}

/**
 * Validates the current model's data and, if there is no error, attempts to send a forgot request.
 *
 * @param {Gaia.AppModule.Spec} module                  the current module composition object
 * @returns {function(): function(...[*]): Promise<*>}  the call's arguments
 */
export default module => () => async (...args) => {
  const model = module[G.MODEL]
  const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]

  const {
    form,
    item1,
    username,
  } = sequenceComponentFindProxy(module[G.STATE][G.ACTION][G.COMPONENT])

  await validate(model)(form || item1)

  try {
    // Checks to see if the new email just entered is a user with a password
    const { version } = model[G.PROPS]
    const url = `/api/v${version}/public/accountExists/${get(username)}`
    const result = await model[G.ADAPTER][G.HTTP].get({ url })

    setKey(result.passwordGenerated, 'passwordGenerated', sessionState)
  } catch (e) {
    console.error(e)
  }

  const result = validationResult(module)()(...args)

  if (!model[G.STATE][G.ERROR]) {
    // If the user has no password, redirect to the correct module/action
    sessionState.passwordGenerated
      ? setKey(routeComposition('guest', 'loginLink'), G.ROUTE, module[G.STATE])
      : await _submit(module)
  }

  return result
}
