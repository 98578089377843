/* global G */
import ui from 'lib/hook/ui/update'
import title from 'app/static/hook/title'

const _baseHooks = {
  after: [
    title,
    ui,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    text: _baseHooks,
  },
}

export default hooks
