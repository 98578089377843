/* global G */
import getQuickAction from 'app/_shared/events/appbar/quickActions/get'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { openModalWith } from 'app/_shared/events/modal/open'
import { curry } from 'lib/util'

/**
 * Toggle Node Feedback handler
 *
 * Wraps {@link toggle} and passes it the current node, extracted from the module state,
 * for the {@code component} passed in the {@code event}. If no component is defined,
 * the action component is used.
 *
 * NOTE: This handler only makes sense on mobile platform, since we trigger the feedback
 * function from the {@link AppBar}, which is outside the doc tree, and therefore need
 * to access the module state to know which node we are working with.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 * @returns {Promise<void>}
 */
const toggleFeedbackQuickAction = async (module, component, event) => {
  const favoriteAction = getQuickAction('feedback', module, component, null)
  const handlerComponent = favoriteAction?.component || null

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { [handlerComponent]: targetComponent } = sequenceComponentFindProxy(actionComponent)
  const finalComponent = targetComponent || actionComponent

  const { node = {} } = module[G.STATE]?.[finalComponent._name] || {}

  const payload = node?.documentationId || node?.attachmentId
  await openModalWith('feedbackModal', module, component, { ...event, detail: { payload } })
}

export default curry(toggleFeedbackQuickAction)
