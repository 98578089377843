/* eslint-disable no-unused-vars */
/* global G */
import { hide, show } from 'lib/sequence/component/state/hidden'
import find from 'lib/sequence/component/children/find'
import { asyncPipeSpread } from 'lib/util'
import session from 'app/_shared/session'

/**
 * Displays or hides the edit buttons depending on the current ticket's status and on whether the
 * current user is the ticket's assignee.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const displayEditButtons = module => component => async (components, ...args) => {
  const model = module[G.MODEL]
  const { status, assignee } = model[G.CHILDREN]
  const { editAdditionalParty } = components

  status[G.CACHE]
  && (status[G.CACHE]?.[0]?.key === 50 || status[G.CACHE] === 50)
  && assignee[G.CACHE]
  && assignee[G.CACHE][0].key === session(module).user.key()
    ? show(editAdditionalParty)
    : hide(editAdditionalParty)

  return [components, ...args]
}

/**
 * Displays or hides information about the requester depending on some of their state values.
 *
 * @param module
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const displayInformationLabels = module => component => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact, additionalParty } = model[G.CHILDREN]
  const additionalPartyRef = additionalParty[G.STATE][G.REF]
  const { waitingForApproval, notInMasterData, blocked } = components
  const {
    waitingForApproval: pendingValidation,
    blocked: isBlocked,
  } = !requesterContact[G.STATE][G.META]

  pendingValidation || additionalPartyRef
    ? hide(notInMasterData)
    : show(notInMasterData)

  pendingValidation && !additionalPartyRef && !isBlocked
    ? show(waitingForApproval)
    : hide(waitingForApproval)

  isBlocked
    ? show(blocked)
    : hide(blocked)

  return [components, ...args]
}

export default module => component => async (...args) => asyncPipeSpread(
  displayInformationLabels(module)(component),
  displayEditButtons(module)(component),
)(find(component), ...args)
