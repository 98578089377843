/* global G */
import { curry } from '@gaia/util'

const descriptor = 'model::transformer::toInt'

/**
 * ToInt Transformer
 *
 * transforms the input to an integer of radix 10.
 *
 * @param {string} key          the name of the attribute to store the data to
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {object} data         the payload data processed until now
 * @return {*}
 */
const toInt = (key, obj, data) => {
  const _data = data
  const item = obj[G.CHILDREN][key]
  const { type } = item[G.PROPS]
  try {
    const value = obj[G.STATE][G.DATA].value[key]

    /**
     * Only add the transformed value if it actually existed in the first place.
     * Otherwise, we will add {@code null} as its value which will cause
     * problems with the jsonschema.
     */
    value && (_data[type][key] = parseInt(value, 10))
  } catch (e) {
    delete _data[type][key]
    console.error(descriptor, e)
  }
  return _data
}

export default curry(toInt)
