/* eslint-disable arrow-body-style */
import { curry } from 'lib/util'
import searchAndRecreate from 'app/_shared/events/appbar/searchAndRecreate'

/**
 * Event Handler Unsorted Search and Recreate
 *
 * Sets the event detail's value as current search term and recreates the module, so that it is
 * taken into account the next time the list is rendered.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 *
 * @return {Promise<void>}
 */
const searchUnsortedAndRecreate = async (module, component, event) => {
  return searchAndRecreate(module, component, event, false)
}

export default curry(searchUnsortedAndRecreate)
