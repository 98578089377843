/* global G */
import cancelOnError from 'lib/sequence/model/cancelOnError'

const descriptor = 'module::request::hook::setMessagesAsRead'

/**
 * Set Messages As Read Hook
 *
 * Instructs the server to set all messages for the current
 * request as read for the logged-in user.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]=)}
 */
const setMessagesAsRead = obj => (...args) => {
  cancelOnError(descriptor, obj)

  const model = obj[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const ressourceId = model[G.STATE][G.REF]
  const { version } = model[G.PROPS]
  const url = `/api/v${version}/setRead/messages`

  httpAdapter.put({ url, params: { target: ressourceId } })
    .catch(r => console.error(r))

  return args
}

export default setMessagesAsRead
