import { curry } from 'lib/util'
import settings from '@tenant/settings'

/**
 * See documentation in {@link settings.drilldown.minStepsDepth}
 * @type {number}
 */
const minStepsDepth = settings?.drilldown?.minStepsDepth || 0

/**
 * Get Drilldown Node Label Handler
 *
 * Returns the correct value of the label of {@param events.node} based on {@link minStepsDepth}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {PlatformEvent} event         information about the event that triggered this handler
 * @returns {*}
 */
const getDrilldownNodeLabel = (module, component, event) => {
  const { node, list = false } = event?.detail || event || {}

  if (list) {
    // In a list, the min depth is 1, if we are displaying children of the root node,
    // We need to use the node.name, otherwise the value of node
    return node.depth === minStepsDepth
      ? node.name
      : node?.value || node.name
  }

  // When displaying the title, the min depth is 0, so if we are displaying the root
  // node title, use node.name, otherwise the name of its first children
  // (all children names are the same)
  return node.depth < minStepsDepth
    ? node.name
    : node?.$children?.[0].name
}

export default curry(getDrilldownNodeLabel)
