/* eslint-disable no-await-in-loop,no-plusplus,implicit-arrow-linebreak,no-param-reassign,
no-unused-expressions */
/* global G */
import { asyncPipeSpread } from 'lib/util'

/**
 * Shallow copies quick actions in action UI so that altering them doesn't affect the cached
 * configuration.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): *[]}
 */
const initialize = obj => (...args) => {
  const actionUi = obj[G.STATE][G.ACTION][G.UI]

  actionUi.quickActions = actionUi.quickActions?.map(quickAction => ({
    ...quickAction,
    events: {
      ...quickAction.events,
    },
  }))

  return args
}

/**
 * Assigns the configured {@quickAction}s event handlers.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): *[]}
 */
const provideEvents = obj => (...args) => {
  const { quickActions = [] } = obj[G.STATE][G.ACTION][G.UI]

  quickActions.forEach((quickAction) => {
    const events = quickAction && quickAction.events
    quickAction.handlerNames = quickAction.handlerNames || { ...events }
    const moduleEvents = obj[G.EVENTS]
    // hacking redirect event
    quickAction[G.CONFIGURATION] = { route: quickAction.route }
    if (events && moduleEvents) {
      Object.keys(quickAction.handlerNames)
        .forEach((name) => {
          const handlerName = quickAction.handlerNames[name]
          const handlerExists = moduleEvents[name]
            && Object.prototype.hasOwnProperty.call(moduleEvents[name], handlerName)
          // todo: turn into pipe(hasNamespace, usesNamespace)
          handlerExists
            ? events[name] = moduleEvents[name][handlerName](obj, quickAction)
            : console.warn(
              'ATTENTION!!! missing required event handler for quick action',
              quickAction.key,
            )
        })
    }
  })

  return args
}

/**
 * Requests the current translation of each visible {@param quickAction}.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): *[]}
 */
const translateTitles = obj => (...args) => {
  const { quickActions = [] } = obj[G.STATE][G.ACTION][G.UI]
  const currentModule = obj[G.CONFIGURATION].module

  quickActions.forEach((quickAction) => {
    quickAction.title = obj[G.ADAPTER][G.INTL]._t(
      `actions.${quickAction.key}`,
      {
        ns: currentModule,
        _key: `actions.${quickAction.key}`,
        defaultValue: quickAction.title,
      },
    )
  })

  return args
}

/**
 * QuickActions Hook
 *
 * Configures the quick actions to be displayed in ui appbar segment.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): Promise<*[]>}
 */
export default obj => async (...args) => asyncPipeSpread(
  initialize(obj),
  provideEvents(obj),
  translateTitles(obj),
)(...args)
